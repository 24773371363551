import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { Form, InputGroup } from 'react-bootstrap';
import { GoMail } from 'react-icons/go';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useState } from 'react';
import { reqData } from '../../../utils/baseUrl';
import toast from 'react-hot-toast';

function Footer() {

    const {isMobile} = useContext(AuthContext);
    const [email,setEmail]= useState('')


    const postLead= async()=>{
        try {
            // console.log(email,'email')
            const res= await reqData("comboLandingPageLead","POST",{
                email: email,
            });
            console.log(res,'lead res');
            if(res && res.error === false){
                setEmail('');
                toast.success(res.messages);
            }else{
                toast.error(res.messages)
            }
        } catch (error) {
            console.log(error,'error')
        }
    }

  return (
    <div className={`theme-dark py-5 text-center pt-${isMobile?'4':''}`}>
         <p
          style={{
            maxWidth: isMobile ? "80%" : "470px",
            margin:"auto",
            color: "white",
            fontSize: "16px",
            textAlign: isMobile ? "center" : "",
          }}
        >
          Want to get rid of addictions and poor habits but nothing works? No
          Worries! The Silva Method has got your back!
        </p>
        <div className='py-3'>
          <InputGroup
            style={{
              borderRadius: "40px",
              backgroundColor: "#000",
              width: isMobile ? "80%" : "500px",
              margin:"auto",
              height: isMobile ?"45px":"60px",
            }}
          >
            <InputGroup.Text
              style={{
                borderTopLeftRadius: "40px",
                borderBottomLeftRadius: "40px",
                backgroundColor: "#000",
                border: "none",
                textAlign: "center",
              }}
            >
              <GoMail style={{ color: "gray" }} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Your Email Address:"
              aria-label="email"
              aria-describedby="email"
              style={{
                backgroundColor: "#000",
                border: "none",
                boxShadow: "none",
                color: "white",
              }}
              type="email"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
            />
            <InputGroup.Text
              style={{
                backgroundColor: "#000",
                border: "none",
                borderTopRightRadius: "40px",
                borderBottomRightRadius: "40px",
              }}
              onClick={postLead}
            >
              <BsFillArrowRightCircleFill
                style={{ color: "#f1c40f" }}
                size={40}
                className="inputiconcombo"
              />
            </InputGroup.Text>
          </InputGroup>
        </div>
        <p className="text-white h6">
          Have any queries?{" "}
          <a href="mailto:digital@silvamethod.com" className="text-primary">
            Get in touch!
          </a>
        </p>
    </div>
  )
}

export default Footer