import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { AiFillCheckCircle } from "react-icons/ai";
import { BsFileEarmarkPlay } from "react-icons/bs";
import { AuthContext } from '../../context/AllContext';

function CourseFeaturesSection({ datanew, isCurrentCoursePurchased }) {

    // console.log(datanew,'datanew');

    const { isMobile, isDesktopOrLaptop, isBigScreen } = useContext(AuthContext);

    const [data2, setData2] = useState([
        {
            key: "Chapters",
            value: 12,
            icon: require("../../images/courselogos/icon-22.png")
        },
        {
            key: "Lessons",
            value: 60,
            icon: require("../../images/courselogos/icon-23.png")
        },
        {
            key: "Exercise",
            value: 80,
            icon: require("../../images/courselogos/icon-23.png")
        },
        {
            key: "Minutes",
            value: 616.3,
            icon: require("../../images/courselogos/icon-24.png")
        },
        {
            key: "Students",
            value: "60K",
            icon: require("../../images/courselogos/icon-22.png")
        }
    ]
    );

    useEffect(() => {
        let allLessonsInCurrentCourse = [];
        datanew?.chapters?.forEach((chapter, i) => {
            const lessionsInSingleChapter = chapter.lession;
            allLessonsInCurrentCourse = [...allLessonsInCurrentCourse, ...lessionsInSingleChapter]
        })
        const totalDurations = allLessonsInCurrentCourse.reduce((acc, curr) => {
            return acc + parseInt(curr.lession_file_duration)
        }, 0)



        const newdata = data2.map((val, i) => {
            if (val["key"] === "Minutes") {
                return {
                    ...val,
                    value: totalDurations
                }
            } else if (val["key"] === "Chapters") {
                return {
                    ...val,
                    value: datanew.total_chapters
                }
            } else if (val["key"] === "Lessons") {
                return {
                    ...val,
                    value: datanew.total_lessons
                }
            } else if (val["key"] === "Exercise") {
                return {
                    ...val,
                    value: datanew.how_many_exercise
                }
            } else if (val["key"] === "Students") {
                return {
                    ...val,
                    value: datanew.how_many_students
                }
            }
            else {
                return val
            }
        })

        setData2(newdata)
    }, [datanew]);

    // console.log(data2,'datanew')


    return (
        <div>
            {
                datanew?.course_id !== "combo-plan" && (
                    <Card className='mt-4' style={{
                        width:
                            ((isDesktopOrLaptop || isBigScreen) && !isCurrentCoursePurchased) ? "95%" : (isCurrentCoursePurchased === false && !isMobile) ? "100%" : isMobile ? "100%" : "55%"
                        , borderRadius: "13px"
                    }}>
                        <Card.Body className='mx-2'>

                            {/* <h4>What Mind Control Exercise that you will Get in this Course</h4> */}



                            <div className='d-flex justify-content-start align-items-center flex-wrap gap-4 mt-4'>


                                {
                                    data2.map((val, i) => (

                                        val.value >0 && <div className='d-flex justify-content-start align-items-center bright gap-3' key={i}>
                                            <span>
                                                <Image src={val.icon} fluid />
                                            </span>
                                            <div className='d-flex justify-content-center align-items-start flex-column'>

                                                <h4 style={{ marginBottom: "0px" }}>{val.value}</h4>
                                                <span className='courseoverviewspans'>{val.key}</span>
                                            </div>
                                        </div>

                                    ))
                                }

                            </div>
                        </Card.Body>
                    </Card>
                )
            }

            {
                ((datanew?.course_benefits) && (datanew?.course_benefits.length>0)) && (
                    <Card className='mb-4 mt-4' style={{
                        width:
                            ((isDesktopOrLaptop || isBigScreen) && !isCurrentCoursePurchased) ? "95%" : (isCurrentCoursePurchased === false && !isMobile) ? "100%" : isMobile ? "100%" : "100%"
                        , borderRadius: "13px"
                    }}>
                        <Card.Body className='mx-3'>

                            <h4 style={{
                                color:"black"
                            }}>Explore The Benefits of {datanew.course_title}. </h4>



                            {
                                datanew?.course_benefits && (
                                    <Row className='justify-content-start mt-4' style={{ marginLeft: "0px" }}>
                                        {
                                            datanew.course_benefits.map((val, i) => (
                                                <Col xs={12} md={6}>

                                                    <div style={{ width: "98%" }}>
                                                        <div className='d-flex justify-content-start gap-2 align-items-start mb-4'>
                                                            <span><AiFillCheckCircle color='#06912e' /></span>
                                                            <span className='courseoverviewspans'>
                                                                {val?.title}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>

                                            ))
                                        }
                                    </Row>
                                )
                            }
                        </Card.Body>
                    </Card>
                )
            }
        </div>
    )
}

export default CourseFeaturesSection