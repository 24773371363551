import React from 'react'
import { Accordion } from 'react-bootstrap';
import AccordianVideoItem from './AccrodianVideoItem';

function PreviousVideos() {

    const videos = [
        // {
        //   id: 1,
        //   videoUrl:
        //     "https://vz-ff89b33a-581.b-cdn.net/a9d25419-0199-4958-9511-fb6f7256a4d0/playlist.m3u8",
        //   title: "Silva Method Live Interactive Program Day 1 Class Recording",
        //   subtitle: "Exclusive for Silva Method Complete Course Users",
        //   text: "Missed the Silva Method Live Interactive Day-1 Class? Access the Live class recording for 7 days to clear your doubts and master Silva techniques. Watch within the timeframe for optimal learning before it expires.",
        //   isLast: false,
        // },
        {
          id: 2,
          videoUrl:
            "https://vz-aa153995-b4c.b-cdn.net/6ffb4194-7d14-4476-8ba7-2a376117e9c3/playlist.m3u8",
          title: "Silva Method Live Interactive Program Day 2 Class Recording",
          subtitle: "Exclusive for Silva Method Complete Course Users",
          text: "Missed the Silva Method Live Interactive Day-2 Class? Access the Live class recording for 7 days to clear your doubts and master Silva techniques. Watch within the timeframe for optimal learning before it expires.",
          isLast: true,
        },
        // {
        //   id: 3,
        //   videoUrl:
        //     "https://vz-a0f3857c-c0d.b-cdn.net/5c3c9ee5-6dbb-4b51-8d5a-22067f347ed0/playlist.m3u8",
        //   title: "Silva Method Live Interactive Program Day 3 Class Recording",
        //   subtitle: "Exclusive for Silva Method Complete Course Users",
        //   text: "Missed the Silva Method Live Interactive Day-3 Class? Access the Live class recording for 7 days to clear your doubts and master Silva techniques. Watch within the timeframe for optimal learning before it expires.",
        //   isLast: true,
        // },
      ];

  return (
    <div className='container'>
         <Accordion defaultActiveKey={2}>
          {videos?.map((video) => (
            <AccordianVideoItem data={video} />
          ))}
        </Accordion>
    </div>
  )
}

export default PreviousVideos