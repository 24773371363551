import React, { useContext } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import HeadingNew from "../AboutUs/HeadingNew";
import { AuthContext } from "../../context/AllContext";

function BonusCard() {
  const {isMobile} = useContext(AuthContext);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h3 className="newpageheader text-center mb-3">
        Bonus included only when you register today
      </h3>
      <Card
        style={{
          alignSelf: "center",
          width: isMobile?"98%":"70%",
          marginTop: "10px",
          borderRadius: "10px",
        }}
      >
        <Row className="p-3">
          <Col
            className="p-3 d-flex flex-column justify-content-center"
            xs={12}
            lg={8}
          >
            <h4 className="py-4">
              Join Now & Get Silva Life System Digital Course Worth $199 For
              Free
            </h4>
            <div className="d-flex align-items-center gap-2">
              <h6>Bonus #1:</h6>
              <p>Silva Life System Digital Course (Valued Worth $199)</p>
            </div>

            <div className="d-flex align-items-center gap-2">
              <h6>Bonus #2:</h6>
              <p>
                Lifetime Access to Silva Life System Live and Digital Course
              </p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <h6>Bonus #3:</h6>
              <p>Live Community Support</p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <h6>Bonus #4:</h6>
              <p>
                Daily Newsletter with Insights, Expert Advice, Seminar Updates,
                and More...
              </p>
            </div>
            <p>
              Enhance your life with Silva Life System, breaking negative
              thought patterns with positivity. Free yourself from headaches,
              migraines, and ingrained habits. Easily conquer complexity and
              explore the depths of your mind with this transformative mind
              control course. Experience the authentic power of Silva Life
              System and discover the new you!
            </p>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={12}
            lg={4}
          >
            <Image
              src="/img/benifit_image.jpg"
              alt=""
              style={{
                width: "80%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default BonusCard;
