import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import ComboPaymentCard from '../ComboCourse/ComboPaymentCard'
import { reqData } from '../../../utils/baseUrl';

function ComboPaymentNew({extraClass,white}) {
    const [loading, setLoading] = useState(false);

    const [courseData, setCourseData] = useState()

    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "combo-plan",
        });

        // console.log(res.data[0].course_description, "Response")


        setLoading(false);
        if (res.error === false) {
            setCourseData(res.data);
        }
    };


    useEffect(() => {
        getCourseData()
    }, [])

    return (
        <div className={` ${extraClass || ''}`}>
       <div className={`container scrollnewsection`}>
         <Row className='justify-content-center align-items-center'>
         <HeadingNew small title={"Ready to Transform Your Life?"} white={white||false} />
         <HeadingNew title={"Join Us and Unlock Your "} colored={"Potential"} white={white||false}/>

            <Col xs={12} md={6}>
                <div className='d-flex justify-content-end align-items-center'>
                    <img
                        src='/img/bg/joebgnew2.png'
                        style={{
                            width: "85%",
                        }}
                    />
                </div>
            </Col>
            <Col xs={12} md={6}>
                {
                    courseData && courseData[0] && (
                        <ComboPaymentCard datanew={courseData[0]} />
                    )
                }
            </Col>
        </Row>
       </div>
        </div>
    )
}

export default ComboPaymentNew