import React, { useContext, useMemo } from "react";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import { FaPlay } from "react-icons/fa";
import SidebarFullMusic from "./SidebarFullMusic";
import { useEffect } from "react";

import MusicPlayerObj from "./MusicPlayerObj";

function TabbarMedi({ data }) {
  const { isMobile, isTablet } = useContext(AuthContext);

  const [activeBtn, setActiveBtn] = useState(1);

  const buttons = [
    {
      index: 1,
      btnText: "Sleep Control",
      text: "The Silva Sleep Control Technique, a form of sleep meditation, promotes natural sleep without reliance on medications. Consistent practice enhances both the quality and duration of sleep. Embrace this method before bedtime to experience organic improvements in your sleep patterns.",
      img: "/img/draganpage/draganpageimgs/sleep-control.png",
    },
    {
      index: 2,
      btnText: "Dream Control",
      img: "/img/draganpage/draganpageimgs/dream-control.png",
      text: "Discover the Silva Dream Control Technique to master the art of dream recall as your personal information hub. This magical technique taps into the depths of your subconscious, providing insights that can shape your waking reality.",
    },
    {
      index: 3,
      btnText: "Clock Exercise",
      img: "/img/draganpage/draganpageimgs/alarm-clock.png",
      text: "Explore the innovative Clock Exercise for seamless morning awakenings. By cultivating mental focus and intention before sleep, this technique empowers you to naturally wake at your chosen time without an alarm clock or the help of others.",
    },
    {
      index: 4,
      btnText: "Headache control",
      img: "/img/draganpage/draganpageimgs/headache-control.png",
      text: "Silva Headache Control Technique, a non-medication approach, empowers individuals to alleviate headaches and migraines through mental techniques. This method harnesses the power of the mind, offering a drug-free alternative for effective relief from head discomfort.",
    },
    {
      index: 5,
      btnText: "Three Fingers Technique",
      img: "/img/draganpage/draganpageimgs/three-finger.jpg",
      text: "The Three Fingers Technique is one of the core techniques of the Silva Life System. This technique can help you to enter the Alpha State of mind. This technique is also a secret weapon against being forgetful. Using it can improve your concentration and help you remember things better.",
    },
    {
      index: 6,
      btnText: "Glass of Water Technique",
      img: "/img/draganpage/draganpageimgs/glass-of-water.jpg",
      text: "The Glass of Water technique is popular for its impressive ability to help with problem-solving and achieving goals. It offers a systematic way to access the potential of your subconscious mind.",
    },
    {
      index: 7,
      btnText: "Mirror of the Mind",
      img: "/img/draganpage/draganpageimgs/mirror-of-the-mind.jpg",
      text: "The mirror of the mind technique helps to enter the Alpha state of mind. In this state, individuals can experience a profound sense of deep relaxation and calmness that helps them transform problems into projects and easily solve them as they desire.",
    },

  ];

  const imagestyles = useMemo(() => {
    if (isTablet) {
      return {
        display: "flex",
        justifyContent: "center",
      };
    } else {
      return {};
    }
  }, [isTablet, isMobile]);




  const objdata = useMemo(() => {
    if (data && data[activeBtn - 1]) {
      return data[activeBtn - 1]
    } else {
      return {

      }
    }
  }, [activeBtn])


  if (!data) {
    return null
  }

  return (
    <>
      <div className="py-4">
        <div
          style={{
            position: "relative",
          }}
        >
          <Card
            style={{
              width: isMobile || isTablet ? "96%" : "60rem",
              padding: isMobile || isTablet ? "30px 30px 0px 30px" : "50px",
              paddingBottom: isMobile || isTablet ? "0px" : "auto",
              border: "none",
              borderRadius: "15px",
              height: isMobile || isTablet ? "auto" : "auto",
              position: "relative",
              margin:"0 auto"
            }}
          >
            <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
              {data.map((val, i) => (
                <button
                  className={`button-29 ${activeBtn === i + 1 ? "button-30" : ""
                    }`}
                  role="button"
                  onClick={() => setActiveBtn(i + 1)}
                >
                  {"" + "Day " + val.precedance + ""}
                </button>
              ))}
            </div>
            <Card.Body
              style={{
                padding: isMobile || isTablet ? "0" : "30px",
                border: "none",
                width:"100%"
              }}
              className="d-flex align-items-center justify-content-center"
            >
              
                 {
                  objdata &&  <MusicPlayerObj datanew={objdata} />
                 }

            </Card.Body>
          
          </Card>
        </div>
      </div>
     

    </>
  );
}

export default TabbarMedi;
