import { Col, Container, Image, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";
import { useContext } from "react";
import { FaCheckCircle } from "react-icons/fa";

function WhereUse({data,title,image}) {
  const { isMobile, isTablet } = useContext(AuthContext);

  const handleScroll = () => {
    const cardscroll = document.querySelector(".paymenttwonew");

    if (cardscroll) {
      cardscroll.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <section className={`hero-sectionone container py-${isMobile ? 3 : 5}`}>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={7} className={`mb-${isMobile || isTablet ? 2 : 0}`}>
            <div
              className="content-container"
              style={{
                width: isMobile || isTablet ? "97%" : "90%",
                marginLeft: isMobile || isTablet ? "0px" : "40px",
                marginTop: isMobile || isTablet ? "30px" : "0px",
              }}
            >
              <h2 className="newpageheader mb-4">{title || "Where Can You Use This Technique?"} </h2>

              {
                data.map((d,i)=>
              <div className="d-flex gap-2" key={i}>
              <FaCheckCircle color="green"/>
                <p>{d.text}</p>
              </div>
                )
              }
              {/* <div className="d-flex gap-2">
              <FaCheckCircle color="green"/>
                <p> </p>
              </div>
              <div className="d-flex gap-2">
              <FaCheckCircle color="green"/>
                <p>  </p>
              </div>
              <div className="d-flex gap-2">
              <FaCheckCircle color="green"/>
                <p> </p>
              </div>
              <div className="d-flex gap-2">
              <FaCheckCircle color="green"/>
                <p></p>
              </div> */}
          
            </div>
          </Col>
          <Col xs={12} lg={5}>
            <div>
              <Image
                src={image || "/img/img_glass_of_water3.jpg"}
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </Col>
          
        </Row>
      </Container>
    </section>
  );
}

export default WhereUse;
