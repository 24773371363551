import React from 'react'
import AlphaState from '../AboutUs/AlphaState'


function AlphaStateOfMind() {
  return (
    <div>
      {/* <HeadingNew title={"Meditating with The Alpha State of Mind for Deep Relaxation"} />
        <ParaComp
        white={false}
        data={[
            "The Alpha state of meditation refers to a purposeful state of relaxation. In this state you encounter a deep feeling of calmness while staying completely attentive and mindful.This state offers a wonderful opportunity to practice focusing your mind on your objectives."
        ]}
        /> */}
      <AlphaState
        titlenew={"Meditating with The Alpha State of Mind for Deep Relaxation"}
        para={[
          "The Alpha state of meditation refers to a purposeful state of relaxation. In this state you encounter a deep feeling of calmness while staying completely attentive and mindful. This state offers a wonderful opportunity to practice focusing your mind on your objectives."
        ]}
        parablack
        btn
      />

    </div>
  )
}

export default AlphaStateOfMind