import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import useAuth from '../../../hooks/useAuth';
import { useForm } from "react-hook-form";
import { Alert, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../utils/axioscall";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../../context/AllContext";
import OtpModal from "../../../components/OtpModal/OtpModal";
import SendOTP from "./SendOTP";
import VerifyOTP from "./VerifyOTP";
import { REACT_BASE_URL, requestData, requestData2 } from "../../../utils/baseUrl";
import { reactLocalStorage } from "reactjs-localstorage";
import "./login2.css";
import Swal from 'sweetalert2';
import { FiSmartphone } from "react-icons/fi";
import { AiOutlineMail, AiFillLock, AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import SocialLoginCommon from "./SocialLoginCommon";
import { allcodes } from "../../../utils/allCountryCodes";


function RegisterForm({ setActiveTab, activeTab }) {
    const coupon = reactLocalStorage.get("coupon")
    const cplan = reactLocalStorage.get("cplan")
    const { search } = useLocation();
    //console.log(search);
    const planId = search?.split("=")[1];
    const [countryName,setCountryName] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [checked, setChecked] = useState(false);


    const [otpsent, setOtpSent] = useState(() => {
        if (localStorage.getItem("otpsent")) {
            return true
        } else {
            return false;
        }
    });
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate()
    // const { registerUser, googleSignIn } = useAuth();
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const { setIsUserLoggedIn } = useContext(AuthContext)

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        // console.log(data)

        if (data.password !== data.confirmpass) {
            Swal.fire({
                icon: 'error',
                text: 'Passwords do not match',
            })
            return
        } else {
            registerFetch(data.firstname, data.lastname, data.email, data.phone, data.password, data.confirmpass)
            // console.log(res)
        }
    };


    const registerFetch = async (firstname, lastname, email, phone, password, confirmpass) => {
        if (!countryCode) {
            toast.error("Please Enter Country Code");
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
            myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");
        myHeaders.append("DeviceId", "{{asdfwedfgdasfds}}");
        // myHeaders.append("Token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzaWx2YW1ldGhvZC5jb20iLCJhdWQiOiJUaGVfQXVkIiwiaWF0IjoxNjgxNDQ0MzI3LCJuYmYiOjE2ODE0NDQzMzcsImV4cCI6NTM1MjE0NDQzMjcsImRhdGEiOiIxIn0.D-p1cFH0rSYBWN1tGnDG1UUvRejaEdwkcoxgcWAlwmo");

        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("first_name", firstname)
        formdata.append("last_name", lastname)
        formdata.append("phone", phone.toString())
        formdata.append("country_code", countryCode)
        formdata.append("country_name",countryName)
        formdata.append("confirm_password", confirmpass)
        formdata.append("password", password);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        setLoading(true)
        const res = await fetch(baseUrl + "register", requestOptions);
        console.log(res,"Response")
        setLoading(false)
        const data = await res.json();


        // console.log(data, "Responsseeee")
        if (data.error === false) {

            if (checked) {
                subscribetonewsletter(email)
            }
            // toast.success(data.data);
            // reset()
            // setActiveTab("link-2")

            localStorage.setItem("token", data.data.student_id)
            localStorage.setItem("google_login", true)
            setIsUserLoggedIn(true)
            toast.success(data.messages)

            const body = {

            }

            if (coupon && cplan == planId) {
                body.coupon_code = coupon
            }
            if (planId && (data.data.enrollment_status !== "Enrolled")) {
                const res = await requestData2(
                    `subscribeNowCourse?plan_id=${planId}`,
                    "POST",
                    body
                );
                // reactLocalStorage.remove("coupon")
                // reactLocalStorage.remove("couponplan")


                if (res?.error === false) {
                    // reactLocalStorage.remove("coupon")
                    // reactLocalStorage.remove("couponplan")


                    window.location.assign(res.data);
                    return;
                } else {
                    return;
                }
            } else if (
                data.data.enrollment_status === "Enrolled"
            ) {
                // reactLocalStorage.remove("coupon")
                // reactLocalStorage.remove("couponplan")


                // console.log("paidrun");
                navigate("/today");
            } else if (
                data.data.enrollment_status !== "Enrolled"
            ) {

                navigate("/");
            } else if (
                (data.data.enrollment_status !== "Enrolled") 
            ) {
              
                navigate("/");
            }
        }
        // else if(data.error===false && planId){
        //    localStorage.setItem("token",data?.studentid)
        //    const res = await requestData2(`subscribeNowCourse?plan_id=${planId}`, "POST");
        //    console.log(res);
        //    if (res && res.error === false) {
        //        window.location.assign(res.data)
        //    }
        // }
        else {
            toast.error(data.messages)
        }
        // console.log(data)

    }


    const subscribetonewsletter = async (email) => {
        const res = await requestData("newsletter", "POST", { email });

        if (res && res.error === false) {
            toast.success(res.messages)
        } else {
            toast.error(res.messages)
        }

    }


    const fetchSocialLogin = async (email, firstname, lastname, image) => {
        var myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
            myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");
        myHeaders.append("DeviceId", "{{asdfwedfgdasfds}}");
        // myHeaders.append("Token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzaWx2YW1ldGhvZC5jb20iLCJhdWQiOiJUaGVfQXVkIiwiaWF0IjoxNjgxNDQ0MzI3LCJuYmYiOjE2ODE0NDQzMzcsImV4cCI6NTM1MjE0NDQzMjcsImRhdGEiOiIxIn0.D-p1cFH0rSYBWN1tGnDG1UUvRejaEdwkcoxgcWAlwmo");

        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("first_name", firstname)
        formdata.append("last_name", lastname)
        formdata.append("profile_image", image)


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        const res = await fetch(baseUrl + "loginWithSocial", requestOptions);
        const data = await res.json();
        if (data.error === false) {
            localStorage.setItem("token", data.data.profile.student_id)
            localStorage.setItem("google_login", true)
            setIsUserLoggedIn(true)
            toast.success(data.messages)

            const body = {

            }

            if (coupon && planId == cplan) {
                body.coupon_code = coupon
            }
            if (planId && (data.data.profile.enrollment_status !== "Enrolled")) {
                const res = await requestData2(
                    `subscribeNowCourse?plan_id=${planId}`,
                    "POST",
                    body
                );
                reactLocalStorage.remove("coupon")
                reactLocalStorage.remove("couponplan")

                if (res?.error === false) {
                    reactLocalStorage.remove("coupon")
                    reactLocalStorage.remove("couponplan")

                    window.location.assign(res.data);
                    return;
                } else {
                    return;
                }
            } else if (
                (data.data.profile.enrollment_status === "Enrolled")
            ) {
               
                // console.log("paidrun");
                navigate("/today");
            } else if (
                (data.data.profile.enrollment_status !== "Enrolled")
            ) {
               

                navigate("/");
            } else if (
                (data.data.profile.enrollment_status !== "Enrolled") 
            ) {

                navigate("/");
            }
        } else {
            toast.error(data.messages)
        }
        // console.log(data)
    }

    const handleCountryChange = (e) => {
        const val = (e.target.value)
        const found = allcodes.find((code,i)=>code.name===val);


        if(found){
            setCountryCode(found.name)
            setCountryName(found.code)
        }else{
            setCountryCode("")
            setCountryName("")
        }
        // if (val !== "Select country code") {
        //     setCountryCode(val)
        // } else {
        //     setCountryCode("")
        // }
    }



    return (
        <>
            <div className='mt-3' style={{ width: "100%" }}>
                <SocialLoginCommon fetchSocialLogin={fetchSocialLogin} handleShow={handleShow} activeTab={activeTab} />
                <span>or</span>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div classNameName="inputField">
                        {/* first name */}
                        <div className="inputArea">

                            <input
                                {...register("firstname", { required: true, minLength: 2 })}
                                type="text"
                                className="padclass"
                                placeholder="Your First Name" />
                        </div>
                        {
                            errors.firstname && (
                                <Alert variant={"danger"}>
                                    Please enter a valid first name
                                </Alert>
                            )
                        }

                        {/* last name */}

                        <div className="inputArea">

                            <input
                                {...register("lastname", { required: true, minLength: 2 })}
                                type="text"
                                className="padclass"
                                placeholder="Your Last Name" />
                        </div>


                        {
                            errors.lastname && (
                                <Alert variant={"danger"}>
                                    Please enter a valid last name
                                </Alert>
                            )
                        }



                        <div className="inputArea">
                            <span>
                                <AiOutlineMail color='#9d9d9d' />
                            </span>
                            <input
                                {...register("email", { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g })}
                                type="email" id="1-email" inputmode="email" name="email" className="auth0-lock-input"
                                placeholder="Your Email" autocomplete="on" autocapitalize="off" aria-label="Email"
                                aria-invalid="false" />
                        </div>

                        {
                            errors.email && (
                                <Alert variant={"danger"}>
                                    Invalid Email ! Please enter a valid email address
                                </Alert>
                            )
                        }


                        <select className="form-select mb-3" aria-label="Default select example" style={{ height: "61px" }} onChange={handleCountryChange}>
                            <option>Select country code</option>
                            {
                                allcodes.map((code, index) => (
                                    <option key={index + 1} value={code.name}>{code.name}{`(${code.dial_code})`}</option>
                                ))
                            }
                        </select>


                        <div className="inputArea">
                            <span>
                                <FiSmartphone color='#9d9d9d' />
                            </span>
                            <input
                                {...register("phone", { required: true, pattern: /^\d*[.]?\d*$/, maxLength: 13 })}
                                type="number"
                                className="padclass"
                                placeholder="Your Phone" />
                        </div>

                        {
                            errors.phone && (
                                <Alert variant={"danger"}>
                                    Invalid Phone number !
                                </Alert>
                            )
                        }

                        <div className="inputArea">
                            <span>
                                <AiFillLock color='#9d9d9d' />
                            </span>
                            <input
                                {...register("password", { required: true, minLength: 3 })}
                                type={showPass ? "text" : "password"}
                                className="padclass"
                                placeholder="Your Password" />
                            <span className="showPass">
                                {
                                    !showPass && (
                                        <AiFillEye onClick={() => {
                                            setShowPass(!showPass)
                                        }} />
                                    )
                                }
                                {
                                    showPass && (
                                        <AiFillEyeInvisible onClick={() => {
                                            setShowPass(!showPass)
                                        }} />
                                    )

                                }
                            </span>
                        </div>



                        {
                            errors.password && (
                                <Alert variant={"danger"}>
                                    Invalid Password ! Please enter a valid password !
                                </Alert>
                            )
                        }




                        <div className="inputArea">
                            <span>
                                <AiFillLock color='#9d9d9d' />
                            </span>
                            <input
                                {...register("confirmpass", { required: true, minLength: 3 })}
                                type={showConfirmPass ? "text" : "password"}
                                className="padclass"
                                placeholder="Confirm Password" />
                            <span className="showPass">
                                {
                                    !showConfirmPass && (
                                        <AiFillEye onClick={() => {
                                            setShowConfirmPass(!showConfirmPass)
                                        }} />
                                    )
                                }
                                {
                                    showConfirmPass && (
                                        <AiFillEyeInvisible onClick={() => {
                                            setShowConfirmPass(!showConfirmPass)
                                        }} />
                                    )

                                }
                            </span>
                        </div>
                        {
                            errors.confirmpass && (
                                <Alert variant={"danger"}>
                                    Invalid Password ! Please enter a valid password !
                                </Alert>
                            )
                        }

                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                        <label className="form-check-label" for="exampleCheck1">
                            Sign me up for The Silva Method's email newsletter with powerful ideas on improving your life.
                        </label>
                    </div>
                    <div className="policy">
                        By signing up, you agree to <a onClick={() => window.open(REACT_BASE_URL + "terms-and-condition")}>our terms of service</a> and <a
                            onClick={() => window.open(REACT_BASE_URL + "privacy-policy")}
                        >privacy
                            policy</a>
                    </div>
                    <button type="submit" className="btnnew">
                        <span className="btnSpan">
                            {
                                loading ? (
                                    <Spinner animation="border" variant="light" />
                                ) : "Register with Silva Method"
                            }
                        </span>
                    </button>
                </form>
            </div>

            <OtpModal show={show} setShow={setShow} handleClose={handleClose}>
                {!otpsent ? (
                    <SendOTP setOtpSent={setOtpSent} />
                ) : (
                    <VerifyOTP
                        setOtpSent={setOtpSent}
                        handleClose={handleClose}
                        setIsUserLoggedIn={setIsUserLoggedIn}
                        planId={planId}
                    />
                )}
            </OtpModal>
        </>
    )
}

export default RegisterForm