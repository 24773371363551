import React from 'react'
import { useContext } from 'react'
import Tilt from 'react-parallax-tilt';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AuthContext } from '../../../../context/AllContext';
import HeadingNew from '../../../AboutUs/HeadingNew';
import TypesMeditateCard from '../BlogLandingPage1/TypesMeditateCard';

function PracticalMindfulness() {
    const { isMobile } = useContext(AuthContext);


    const cardColors3 = [
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)',  // Charcoal to dark grey gradient
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)',   // Charcoal to dark grey gradient
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)'   // Charcoal to dark grey gradient
    ];

    const meditationContent = useMemo(() => {
        return [
            {
                title: "Start Small",
                content: "One of the first steps for beginners is to start small. Don't feel like you need to meditate for an hour or master every mindfulness technique at once. Start with just a few minutes each day. This could be as simple as sitting in silence for five minutes and observing your breath. Over time, gradually increase the duration of your practice. "
            },
            {
                title: "Set an Intrention",
                content: "Before diving into mindfulness, set an intention for your practice. It could be as simple as ‘I want to feel more present today’ or ‘I want to manage my anxiety better’. Having an intention helps you stay focused and gives purpose to your sessions, whether you are following guided mindfulness meditations or doing it solo."
            },
            {
                title: "Follow a mindfulness Guide",
                content: "If you are unsure where to begin, following a mindfulness guide can be incredibly helpful. There are plenty of mindfulness meditation resources available online that offer step-by-step instruction. Many platforms provide mindfulness meditations for beginners  that walk you through each step, helping you build confidence in your practice. "
            },
            {
                title: "Join a Mindfulness Course",
                content: "For a more structured approach, consider enrolling in mindfulness meditation courses. These courses often provide a deeper understanding of mindfulness practice, and many offer tools that you can incorporate into your daily life. Mindfulness meditation courses like the Silva Method Complete program provide mindfulness training online through live interactive sessions every weekend. It’s specifically designed to train individuals from a beginner’s to advanced level, by offering personalised support and guidance as you develop your skills.",
                link:"/store/course/combo-plan",
                linktext:"Try Silva Method Now"
            },
            {
                title: "Try Guided Meditations",
                content: "Guided mindfulness meditations are ideal for those new to mindfulness. In these sessions, a narrator or a meditation teacher will guide you through the process, making it easier to stay focused and program your subconscious. Guided sessions are a great starting point if you find it difficult to meditate on your own or need help staying engaged. "
            },
            {
                title:"Be consistent",
                content:"Like any new habit , consistency is the key. Try to meditate at the same time each day, even if it’s only for a few minutes. Over time, this will make mindfulness feel like a natural part of your routine, rather than something you have to squeeze in. Some people find it helpful to set a reminder or schedule their mindfulness sessions, especially in the early stages. "
            }
           
        ]
    }, [])

    return (
        <div className="container py-5 d-flex justify-content-center align-items-center flex-column">
            {/* <h3 style={{
                color: "black",
                fontSize: isMobile ? "2rem" : "3rem"
            }}
                className='lora-sans'
            >
               Benefits of  {" "}
                <span style={{ color: '#572ac7' }}>
                Regular Night-Time Meditation
                </span>
            </h3> */}
            <HeadingNew title={"How to practice mindfulness for beginners "} />

            <p className='text-dark robotofont mt-3 text-center' style={{
                fontSize: isMobile ? "1rem" : "1.1rem"
            }}>
                Starting a mindfulness practice can feel overwhelming, especially for beginners. With so many techniques and approaches available, where do you begin? Well, the beauty of mindfulness is that it’s not about doing everything perfectly, but showing up and being present and paying attention. Many beginners start with meditation and mindfulness practices to experience increased self-awareness and a calmer, more focused mindset. Whether you are looking to dive into guided mindfulness meditations or explore mindfulness through everyday activities, building a consistent practice with mindful self compassion can provide mental clarity, emotional balance, and a greater sense of peace.
            </p>
            <p className='text-dark robotofont mt-3 text-center' style={{
                fontSize: isMobile ? "1rem" : "1.1rem"
            }}>
                In my own journey, starting with simple mindfulness steps helped me build the foundation for a more structured practice. I have also realised that embracing the principle of matter over mind, beginners in mindfulness can learn to harmonise mental awareness with the body’s natural responses. Today, there are countless mindfulness meditation resources and mindfulness meditation courses available to help you, from mindfulness training online to guided meditations that cater to all experience levels. For those just starting out, here are some essential mindfulness steps for beginners to get you going.
            </p>
            

            <Row className='justify-content-center align-items-center my-5 gy-2'>
                {
                    meditationContent.map((val, index) => (
                        <Col md={6}>

                            <TypesMeditateCard item={val} color={cardColors3[index]} link={val.link || null} linktext={val.linktext || null} />
                        </Col>
                    ))

                }


            </Row>

            <p className='text-dark robotofont  text-center'>
            Now that you have learnt all the steps to start your mindfulness practice, you are all set to begin your journey. But here’s the key: enrolling in mindfulness meditation courses or getting mindfulness training online is not the only solution. You must incorporate mindfulness meditation basics into your daily activities to truly experience a mindful living. 
            </p>



        </div>
    )
}

export default PracticalMindfulness