import React, { useContext } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap'
import Badge from './Badge'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { AuthContext } from '../../../context/AllContext'

function AlphaStateMind() {


    const {isMobile,isTablet,isDesktopOrLaptop,isBigScreen,}= useContext(AuthContext);

  return (
    <div style={{backgroundImage:(isMobile || isTablet)?`url('/img/bg/img-for-mobile-4.webp')`:`url('/img/bg/img-32.webp')`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPositionX:isDesktopOrLaptop?"48%":isMobile?"70%":"",backgroundPositionY:(isMobile || isTablet)?"90%":""}}>
        <div className="container d-flex flex-column justify-content-center align-items-center">
            <div>
                <Row>
                    <Col sm={12} md={12} lg={6} className='d-flex flex-column justify-content-center align-items-start' style={{padding:(isMobile || isTablet)?"50px 0px 150px 0px":"300px 0px"}}>
                    {(isMobile || isTablet) && <div className='mt-3 px-3 d-flex flex-column justify-content-center align-items-start'>
                    <Badge text={"Alpha"} />
                    <div className='my-3'>
                    <HeadingNew title={"State of Mind"} white/>
                    </div>
                    <ParaComp
                        data={[
                            "is a calm and relaxed state of mind. It is the most potent wave produced during a non-arousal state making you feel relaxed but in control. You are fully conscious in this state but also feel the deepest form of relaxation. When you enter the alpha state of mind using the Jose Silva mind control method, you will automatically find your- self in a deeper meditative state, more creative and re- laxed. You can reprogram your mind to shape your neg- ative thoughts into positive ones and achieve several milestones in life."
                        ]}
                        white={true}
                        fw={"500"}
                    />
                    </div>}
                    </Col>
                    <Col sm={12} md={12} lg={6} className='d-flex flex-column justify-content-center align-items-start' style={{padding:"300px 0px"}}>
                   {(!isMobile && !isTablet) && <div className='my-3 d-flex flex-column justify-content-center align-items-start'>
                    <Badge text={"Alpha"} />
                    <div className='my-3'>
                    <HeadingNew title={"State of Mind"} white/>
                    </div>
                    <ParaComp
                        data={[
                            "is a calm and relaxed state of mind. It is the most potent wave produced during a non-arousal state making you feel relaxed but in control. You are fully conscious in this state but also feel the deepest form of relaxation. When you enter the alpha state of mind using the Jose Silva mind control method, you will automatically find your- self in a deeper meditative state, more creative and re- laxed. You can reprogram your mind to shape your neg- ative thoughts into positive ones and achieve several milestones in life."
                        ]}
                        white={true}
                        fw={"500"}
                    />
                    </div>}
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default AlphaStateMind