import React, { useContext } from "react";
import { Card, Container, Row, Col, Image } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import "./BookCard.css"; // Import your custom CSS file
import {AuthContext} from "../../context/AllContext"


const BookCard = ({title,text,img}) => {
  const {isMobile} = useContext(AuthContext);
  const cardStyle = {
    background: "rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)",

    border: "1px solid white",
    color: "white",
    width: isMobile?"21rem":"38rem",
    padding: "10px 12px",
    borderRadius:"8px",
    margin:isMobile?"auto":"0",
    marginTop:isMobile?"20px":"0"
  };

  

  return (
    <Card style={cardStyle}>
      <Card.Body>
        <Container fluid>
          <Row className={isMobile?"gap-2":""}>
            <Col xs={12} md={6}>
              <Image src={img || "/programs/Better-Relationships.jpg"} 
              // width="0"

              // height="0"
              // sizes="100vw"
              // alt=""
              className="mb-3"
              style={
                  { borderRadius: "8px", height: "auto", border:"1px solid #636e72",width:"90%" }
              }
              // width={200}
              // height={200}
              />
            </Col>
            {/* <Col xs={2}>
              <div className="outer-circle">
                <div className="inner-circle">
                  <FaCheck size={25} color="white" />
                </div>
              </div>
            </Col> */}
            <Col xs={12} md={6}>
              <div>
                <h5 className="white-color">
                  {
                    title || "Mid-level Digital Marketing & Performance Marketing Professionals"
                  }
                </h5>
                <p className="white-color">
                  {
                    text || "Who wants to be part of a growing community of some of the best minds in the industry"
                  }
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default BookCard;
