import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { Col, Row } from 'react-bootstrap'
import CustomTimeLineComp from './CustomTimeLineComp';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';

function SilvaExcersises() {

    const {isTablet}= useContext(AuthContext);

    const excersisesData= [
        "Programming your mind",
        "Entering a deeper level of mind",
        "Using Breathing Techniques and sensations",
        "Visualization of desired outcomes",
        "Creative Imagination for problem-solving",
        "Mentally Rehearsing for a memory boost",
        "Developing a positive mindset",
    ];

  return (
    <div>
        <div className="container d-flex flex-column justify-content-center align-items-center py-5">
            <HeadingNew title={"Silva Method "} colored={"Exercises"}/>
            <h5 className='text-black'>Where the Mind Control Method Begins</h5>
            <ParaComp
                data={[
                    "Jose Silva believed that what you want to do in this life and what you want to achieve is always limited by self-doubts and your own beliefs. And Silva method exercises could be your guidebook that you can use to control your mind and the limiting beliefs holding you back from what you want to achieve. Silva's method exercises include:"
                ]}
                white={false}
                fw={"500"}
            />
            <Row className='w-100'>
                <Col lg={6} md={12} sm={12} className='d-flex flex-column justify-content-center'>
                <CustomTimeLineComp data={excersisesData}/>
                <ParaComp
                data={[
                    "The human mind is considered the most powerful gadget. In the world of technology, it is software installed in our bodies, that controls all thoughts, emotions, and feelings. It can either turn out to be the best functioning application or the worst one crashing your whole system.",
                    "In other words, the human mind can either be your greatest friend or your worst enemy, depending upon how you treat it. If you have control over your mind then you can achieve what you want. Similarly, If you lose control, you will eventually become a victim of it. Stress, anxiety, depression, and other negative thoughts are the by-products of an uncontrolled mind.",
                    <p style={{fontSize:isTablet?"1.2rem" :"1rem",fontWeight:"500"}}>This is what gave inspiration to Jose Silva, leading to the inven- tion of the <b>Silva mind control exercises</b>. Through this discovery, Jose Silva wanted to transform lives by harnessing the ultimate power of the human mind. It is to master your thoughts.</p>
                ]}
                white={false}
                fw={"500"}
                />
                </Col>
                <Col lg={6} md={12} sm={12} className='d-flex flex-column justify-content-center'>
                 <img src="/img/bg/img-29.webp" alt="" />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default SilvaExcersises