import React, { useContext } from "react";
import HeadingNew from "../AboutUs/HeadingNew";
import ReactHlsPlayer from "react-hls-player";
import { AuthContext } from "../../context/AllContext";
import { FaWhatsapp } from "react-icons/fa";
import Button from "../LoveMind/One/Button";
import { Accordion } from "react-bootstrap";

function AccordianVideoItem({ data }) {
  const { isMobile} = useContext(AuthContext);

  return (
    <div className={`recordingvideo container my-2`}>
        <Accordion.Item eventKey={data.id}>
          <Accordion.Header>
            <HeadingNew
              title={data?.title || "Silva Method Live Interactive Program Class Recording Day 1 "}
              small
              white
            />
          </Accordion.Header>
          <Accordion.Body>
            <HeadingNew
              small
              title={
                data?.subtitle ||
                "Exclusive for Silva Method Complete Course Users"
              }
            />
            <div className={"py-3 hlsplay"}>
              <div className="container d-flex justify-content-center align-items-center flex-column">
                {/* <HeadingNew title={data?.title || "Silva Method Live Interactive Program"} colored={data?.colored || "Day 1 Class Recording"} /> */}
                <div
                >
                  {/* <HeadingNew small title={data?.subtitle || "Exclusive for Silva Method Complete Course Users"} /> */}
                </div>
                <p
                  style={{
                    color: "black",
                    textAlign: "center",
                    marginTop: "20px",
                    width: isMobile ? "100%" : "70%",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                >
                  {data?.text}
                </p>

                <div
                  style={{
                    marginTop: "30px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ReactHlsPlayer
                    src={data?.videoUrl}
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    style={{
                      borderRadius: "15px",
                    }}
                    height="auto"
                    poster="/img/live_interaction_image_recoding.jpg"
                  />
                </div>
                {data?.isLast && (
                  <div className="py-3">
                    <Button
                      text={"Join the official group"}
                      onClick={() => {
                        const whatsappGroupURL =
                          "https://chat.whatsapp.com/HalehUdcSHRLkBaBuHsT6k";
                        window.open(whatsappGroupURL);
                      }}
                    />
                  </div>
                )}

                {/* <button
                      className="footer-whatapp-Btn"
                      onClick={() => {
                        const whatsappGroupURL =
                          "https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ";
                        window.open(whatsappGroupURL);
                      }}
                      style={{fontSize:'11px', textTransform:'capitalize',paddingRight:10,paddingLeft:10}}
                    >
                      <span>
                        <FaWhatsapp size={20} color="white" />
                      </span>{" "}
                      Join the Silva International Official WhatsApp
                    </button> */}
              </div>
              {data?.isLast && (
                <div className="d-flex justify-content-center px-3">
                  <p
                    style={{
                      color: "black",
                      textAlign: "start",
                      width: isMobile ? "100%" : "70%",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    <span className="fw-bold"> NOTE:</span> Please note that the
                    initial few minutes are unavailable, but the class begins
                    with a brief relaxation exercise. If you missed the Live
                    Class, you can catch up with the recorded session.
                    Additionally, mark your calendars for the next live
                    interactive class on March 2nd. Check your registered email
                    for class details and further updates.
                  </p>
                </div>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>

    </div>
  );
}

export default AccordianVideoItem;