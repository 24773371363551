import React, { useContext, useState } from 'react'
import silvalogo from "../../../images/newimgs/silvamethod-logo.png";
import { useNavigate } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from '../../../context/AllContext';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';

function NewHeader() {
  const [toggled, setToggled] = useState(false)
  const navigate = useNavigate();
  const { isUserLoggedIn, logout } = useContext(AuthContext);


  return (
    <Navbar bg="light"
      expand="lg"

      // fixed='top'
      className='newnavbar header-menu-area sticky_menu' onToggle={(val) => {
        if (val) {
          setToggled(true)
        } else {
          setToggled(false)
        }

      }}

    >
      <Container>
        <Navbar.Brand onClick={() => navigate("/")}>
          <img src={silvalogo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          {
            toggled ? (
              <AiOutlineClose size={25} color='black' />
            ) : (
              <FaBars size={25} color='black' />
            )
          }
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
          <Nav style={{ textDecoration: "none", alignItems: "center" }}>
            <a href="mailto:digital@silvamethod.com" className='styledbtn234' style={{ textDecoration: "none" }}>
              Support
            </a>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NewHeader