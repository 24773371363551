import React from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import ResetPasswordForm from './ResetPasswordForm'
import HeadingNew from '../AboutUs/HeadingNew'

function ResetPassword() {
  return (
    <div>
        <HomeHeader />
        <div className="container my-5">
          <HeadingNew title={"Reset Password"} />
          <p style={{fontSize:"1.2rem",color:"black",textAlign:"center"}}>
            A strong password helps prevent unauthorized access to your account
          </p>
        <ResetPasswordForm />
        </div>
        <CustomFooter />
    </div>
  )
}

export default ResetPassword