import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { Image } from 'react-bootstrap'

function WhoIsThisFor() {
  return (
    <div className='py-5 parallexcontact'>
         <div className='container'>
         <HeadingNew
        title={"Who Is This For?"}
        />
         <HeadingNew
        title={"Surround Yourself with People Who Inspire You"}
        small
        white={false}
        />
        <ParaComp
        data={[
            "This seminar is for people who know they are destined for greatness, but may be struggling to live a life of meaning right now. For those who desire to do amazing things but are caught in a few negative patterns and need that something to help them break out.",
            "Becoming Extraordinary takes you through all the essential Mindvalley philosophies and ties together the best teachings from all the top Mindvalley authors. You’ll learn how to integrate Forgiveness, Compassion, Gratitude, Advanced Goal Setting, Creative Visualization and Intention into your daily life.",
            "It goes into the depths of your conscious and makes you aware of your “brules”, habits, and mindsets that are holding you back in life. It gives you a practical toolkit, backed up by science, for you to rewire your brain and see you life accelerate in the direction of your wildest dreams.",
            "Plus, create beautiful, meaningful friendships with like-minded people at a time when human connection is more important than it has ever been."
        ]}
        center
        white={false}
        />
         <Image src={require("../../images/detailspage/img-2.png")} />

         </div>
     

      
    </div>
  )
}

export default WhoIsThisFor