import React from "react";
import "./wrapper-progress.css";

function CustomTimeLineComp({ data }) {
  return (
    // <div>
    //   <ul className="mindcontroltimeline">
    //     {data?.map((technique, i) => (
    //       <li className="px-3 text-black">{technique}</li>
    //     ))}
    //   </ul>
    // </div>
    <div className="d-flex justify-content-center align-items-center">
       <div class="wrapper-progress">
      <ul class="StepProgress">
        {
          data.map((val, i) => (
            <li class="StepProgress-item is-done" style={{
              lineHeight:"1.2",
              color:"#36454F"
            }}><strong>{val}</strong></li>

          ))

        }


      </ul>
    </div>
    </div>
  );
}

export default CustomTimeLineComp;
