import React from 'react'
import TabsSection from '../../newbooking/TabsSection';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import DayCard from '../../newbooking/DayCard';
import CourseCurriculumCard from './CourseCurriculumCard';
import Button from './Button';

function CourseCurriculum() {

    const {isMobile,isTablet} = useContext(AuthContext);

    const [activeTab, setActiveTab] = useState(() => {
        if (isMobile) {
          return null;
        } else {
          return 0;
        }
      });

    const tabs = ["Day one", "Day two", "Day three", "Day four"];
    const daysContent=[
        {
            id:1,
            day:1,
            parts:[
                {
                    partName:"Part 1",
                    content:[
                        "Introduction to Love Mind",
                        "Causes of stress",
                        "First relaxation exercise",
                        "Worry Transformer" 
                    ],
                },
                {
                    partName:"Part 2",
                    content:[
                        "Second relaxation exercise",
                        "Guilt eliminator",
                        "3 crucial tools for neutralizing stress completed"
                    ]
                },
                {
                    partName:"Part 3",
                    content:[
                        "Mental shower technique",
                        "Key life decision of taking full responsibility for your life",
                        "Four pillars of healthy self-esteem"
                    ]
                }
            ]  
        },
        {
            id:2,
            day:2,
            parts:[
                {
                    partName:"Part 1",
                    content:[
                        "Q&A",
                        "3 Fingers technique"                        
                    ],
                },
                {
                    partName:"Part 2",
                    content:[
                        "Art of communication",
                        "Key principles of successful communication",
                        "Observation technique",
                        "Conflict situation technique",
                        "Communication without critique",
                        "3 greatest communication errors"
                    ]
                },
                {
                    partName:"Part 3",
                    content:[
                        "Mirror of the Mind- goal achievement technique",
                    ]
                },
                {
                    partName:"Part 4",
                    content:[
                        "Three-scene technique- for changing a limiting habit in self-esteem",
                    ]
                }
            ]  
        },
        {
            id:3,
            day:3,
            parts:[
                {
                    partName:"Part 1",
                    content:[
                        "Q & A",
                        "Experiences"                        
                    ],
                },
                {
                    partName:"Part 2",
                    content:[
                        "Emotional partnerships",
                        "3 types of harmony",
                        "Relationship with children",
                        "With parents",
                        "Dealing with departures"
                    ]
                },
                {
                    partName:"Part 3",
                    content:[
                        "Emotional map",
                        "Expressing emotions completely",
                        "Top confidence technique"
                    ]
                },
                {
                    partName:"Part 4",
                    content:[
                        "Subjective communication at the ideal time for most complex relationship problems Transforming from ‘duality consciousness ‘ to ‘unity consciousness'",
                    ]
                }
            ]  
        },
        {
            id:3,
            day:3,
            parts:[
                {
                    partName:"Part 1",
                    content:[
                        "Formula for Successful Functioning in Life",
                    ],
                },
                {
                    partName:"Part 2",
                    content:[
                        "Information gathering- Glass of water technique",
                    ]
                },
                {
                    partName:"Part 3",
                    content:[
                        "Three life purposes; 3 highest laws of life; 3 paths to Unity, the highest law of life",
                    ]
                },
                {
                    partName:"Part 4",
                    content:[
                        "Mental Ad -a special technique for attracting a harmonious emotional partner into your life; Summary",
                    ]
                }
            ]  
        }
    ];

    const handleScroll = () => {
      const cardscroll = document.querySelector(".lovemindpayment");
  
      if (cardscroll) {
        cardscroll.scrollIntoView({
          behavior: "smooth",
        });
      }
    };


  return (
    <div className="pb-5 d-flex justify-content-center align-items-center flex-column">
    
      <div className="container">
        <h3 className={`newpageheader text-center pb-${(isMobile || isTablet)?0:5}`}>
        Course Curriculum
        </h3>

        <TabsSection
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
        {
            
            <div className="d-flex justify-content-center align-items-center flex-column">
            {daysContent[activeTab]?.parts.map((part, i) => (
              <CourseCurriculumCard data={part} index={i + 1} />
            ))}
          </div>
        }
        <div className='d-flex justify-content-center align-items-center mt-3'>
          <Button text={"Reserve your spot now"} onClick={handleScroll}/>
        </div>


        {/* {activeTab === 0 && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            {day1.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )}
        {activeTab === 1 && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            {day2.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )}
        {activeTab === 2 && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            {day3.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )}
        {activeTab === 3 && (
          <div className="d-flex justify-content-center align-items-center flex-column gap-3">
            {day4.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )} */}
      </div>
    </div>
  )
}

export default CourseCurriculum