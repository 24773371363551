import React from 'react'
import {GrFormPrevious,GrFormNext} from "react-icons/gr";
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import img1 from "../../images/websitewithlaptop/payment_screen.png"
import img2 from "../../images/websitewithlaptop/login_screen.png"
import img3 from "../../images/websitewithlaptop/home_screen.png"

function AfterOrderSection2() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1279 })
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 })


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isDesktopOrLaptop?3:isTablet?2:1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };
  return (
    <div className='my-5 py-5' style={{backgroundColor: "#6a3093"}}>
         <h2 className='text-center white-color'>Once You Make the Purchase</h2>
         
       
       <div className="container orderslider">
       <Slider {...settings}>
         <div className='d-flex flex-column justify-content-center align-items-center'>
            <img src={img1}
            alt=""
            />
            <p className='white-color' style={{fontFamily:"Poppins",fontSize:"1.2rem"}}>Secure checkout page</p>
            <p style={{fontSize:isMobile?"1rem":"1.1rem",color:"black",width:isMobile?"100%":"330px",wordBreak:"break-word"}} className='white-color'>
             1.	Once you click on purchase, you will be swiftly moved on to a secure checkout page.
            </p>

         </div>
         <div className='d-flex flex-column justify-content-center align-items-center'>
            <img src={img2}
            alt=""
            />
            <p className='white-color' style={{fontFamily:"Poppins",fontSize:"1.2rem"}}>Login To Your Account</p>
            <p style={{fontSize:isMobile?"1rem":"1.1rem",color:"black",width:isMobile?"100%":"330px",wordBreak:"break-word"}} className='white-color'>
             2. Login with an authentic email account. Use the same for future use.
            </p>

         </div>
         <div className='d-flex flex-column justify-content-center align-items-center'>
            <img src={img3}
            alt=""
            />
            <p className='white-color' style={{fontFamily:"Poppins",fontSize:"1.2rem"}}>Enjoy your new program</p>
            <p style={{fontSize:isMobile?"1rem":"1.1rem",color:"black",width:isMobile?"100%":"330px",wordWrap:"break-word"}} className='white-color'>
             3. After successfully logging in, discover the greatness of Silva Method Courses
            </p>

         </div>
         </Slider>
       </div>
    </div>
  )
}

export default AfterOrderSection2