// import React from 'react'
// import HeadingNew from '../../AboutUs/HeadingNew'
// import { useState } from 'react';
// import { useEffect } from 'react';
// import { useContext } from 'react';
// import { AuthContext } from '../../../context/AllContext';
// import { TypeAnimation } from 'react-type-animation';

// function LoveMindOneHero() {
//     const { isMobile } = useContext(AuthContext);

//     const [imageIndex, setImageIndex] = useState(0);
//     const images = [
//         "/img/lovemind/1.png",
//         "/img/lovemind/2.png",
//         "/img/lovemind/3.png",
//     ];

//     useEffect(() => {
//         const intervalId = setInterval(() => {
//             setImageIndex(prevIndex => (prevIndex + 1) % images.length);
//         }, 5000);

//         return () => clearInterval(intervalId);
//     }, []);

//     return (
//         <div style={{
//             width: "100%",
//             position: "relative",
//             height: isMobile ? "60vh" : "80vh"
//         }}>
//             <div style={{
//                 position: "absolute",
//                 left: isMobile ? "2%" : "10%",
//                 top: isMobile ? "15%" : "30%",
//                 padding: isMobile ? "10px" : "0px",
//                 zIndex: 2,
//                 width: isMobile ? "96%" : "40%"
//             }}>
//                 <HeadingNew title={"A Quest for "} white start />
//                 <TypeAnimation
//                     sequence={[
//                         'Self-Love',
//                         1000,
//                         'Connection', //  Continuing previous Text
//                         1000,
//                         'and Wholeness',
//                         2000,
//                         // 'Fingertips',
//                         // 500,
//                         // 'Fingertips',
//                         // 500,
//                         // '',
//                         // 500,
//                     ]}
//                     wrapper="span"

//                     style={{ fontSize: isMobile ? "2em" : '3.5em', display: 'inline-block', fontWeight: "bold", color: "#FFC815" }}
//                     repeat={Infinity}
//                 />
//                 {
//                     isMobile ? (
//                         <p className='my-3 white-color' style={{
//                             lineHeight: isMobile ? "22px" : "inherit"
//                         }}>
//                            Welcome to "Love Mind" - Your pathway to Universal connections and inner harmony.  It is a live video online course designed for individuals at all stages of their journey. This course empowers you to love yourself, nurture healthy self-esteem, foster meaningful relationships, and find alignment with the universe. Get access to this unique course and elevate your love life and beyond.
//                         </p>
//                     ) : (
//                         <p className='my-3 white-color'>
//                             Welcome to "Love Mind" - Your pathway to Universal connections and inner harmony.  It is a live video online course designed for individuals at all stages of their journey. This course empowers you to love yourself, nurture healthy self-esteem, foster meaningful relationships, and find alignment with the universe. Get access to this unique course and elevate your love life and beyond.
//                         </p>
//                     )
//                 }

//                 <button className='primary_btn3' style={{
//                     backgroundImage: "linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%)",
//                     color: "black",
//                     fontWeight: "600"
//                 }}>
//                     Enroll Now

//                 </button>

//             </div>

//             {images.map((imageUrl, index) => (
//                 <img
//                     key={index}
//                     src={imageUrl}
//                     alt={`Slide ${index + 1}`}
//                     style={{
//                         position: 'absolute',
//                         top: 0,
//                         width: '100%',
//                         height: '100%',
//                         objectFit: 'cover',
//                         zIndex: index === imageIndex ? 1 : 0,
//                         opacity: index === imageIndex ? 1 : 0,
//                         transition: 'opacity 1s ease-in-out',
//                     }}
//                 />
//             ))}

//             <div style={{
//                 position: "absolute",
//                 bottom: "20px",
//                 left: "50%",
//                 transform: "translateX(-50%)",
//                 zIndex: 3,
//             }}>
//                 {images.map((_, index) => (
//                     <span
//                         key={index}
//                         style={{
//                             display: "inline-block",
//                             width: "10px",
//                             height: "10px",
//                             borderRadius: "50%",
//                             backgroundColor: index === imageIndex ? "#fff" : "rgba(255, 255, 255, 0.5)",
//                             margin: "0 5px",
//                             cursor: "pointer"
//                         }}
//                         onClick={() => setImageIndex(index)}
//                     />
//                 ))}
//             </div>
//             <div style={{
//                 position: "absolute",
//                 top: 0,
//                 width: "100%",
//                 height: "100%",
//                 background: isMobile ? "rgba(0,0,0,0.4)" : (imageIndex===1 ? "rgba(0,0,0,0.6)": "rgba(0,0,0,0.4)"),
//                 zIndex: 1,
//                 transition: "background-color 1s ease-in-out",
//             }}>

//             </div>

//         </div>
//     )
// }

// export default LoveMindOneHero

import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import { useContext } from "react";
import ResponsiveEmbed from "react-responsive-embed";
import { AuthContext } from "../../../context/AllContext";
import HeadingNew from "../../AboutUs/HeadingNew";
import "./One.css";

function LoveMindOneHero() {
  const { isMobile } = useContext(AuthContext);

  const handleScroll = () => {
    const cardscroll = document.querySelector(".lovemindpayment");

    if (cardscroll) {
      cardscroll.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#36003e",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <Row className="align-items-center">
        <Col sm={12} md={12} lg={6}>
            <div className="container">
              <HeadingNew title={"Love Mind"} start white/>
              <HeadingNew
                title={"A Quest for Self-Love, Connection, and Wholeness"}
                start
                small
                white
              />
              <div>
                <p
                  style={{
                    fontSize: isMobile ? "0.7rem" : "1rem",
                    textAlign:isMobile?"center": "start",
                    lineHeight: isMobile ? "30px" : "35px",
                    color:'white'
                  }}
                >
                  "Welcome to "Love Mind" - Your pathway to Universal
                  connections and inner harmony. It is a live video online
                  course designed for individuals at all stages of their
                  journey. This course empowers you to love yourself, nurture
                  healthy self-esteem, foster meaningful relationships, and find
                  alignment with the universe. Get access to this unique course
                  and elevate your love life and beyond."
                </p>
                <div>
                  <button
                    className="primary_btn3"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%)",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div className="container">
              <div className="container">
              <ResponsiveEmbed
                ratio="16:9"
                src="https://www.youtube.com/embed/Br5Hqtp03po"
                allowFullScreen
              />
              </div>
            </div>
          </Col>
        
        </Row> */}
      {/* <Row>
          <Col lg={6} sm={12} md={12} className="p-2 d-flex justify-content-center align-items-center">
           <div className="px-5">
           <HeadingNew title={"Love Mind"} start white />
            <HeadingNew
              title={"A Quest for Self-Love, Connection, and Wholeness"}
              start
              small
              white
            />
            <div>
              <p
                style={{
                  fontSize: isMobile ? "0.7rem" : "1rem",
                  textAlign: isMobile ? "center" : "start",
                  lineHeight: isMobile ? "30px" : "35px",
                  color: "white",
                }}
              >
                "Welcome to "Love Mind" - Your pathway to Universal connections
                and inner harmony. It is a live video online course designed for
                individuals at all stages of their journey. This course empowers
                you to love yourself, nurture healthy self-esteem, foster
                meaningful relationships, and find alignment with the universe.
                Get access to this unique course and elevate your love life and
                beyond."
              </p>
              <div>
                <button
                  className="primary_btn3"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%)",
                    color: "black",
                    fontWeight: "600",
                  }}
                  onClick={handleScroll}
                >
                  Enroll Now
                </button>
              </div>
            </div>
           </div>
          </Col>
          <Col lg={6} sm={12} md={12} className="d-flex justify-content-center align-items-center">
            <div>
              <video
                width={"100%"}
                height={"100%"}
                controls
                style={{ borderRadius: "30px",margin:0,padding:0 }}
                controlsList="nodownload"
                autoPlay
                muted
              >
                <source
                  src="https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </Col>
        </Row> */}
      <section class="bannerSection">
        <div class="leftCont">
          <div class="container-fluid">
            <div class={isMobile ? "textCont px-2" : "textCont px-5"}>
              <HeadingNew title={"Love Mind"} start white />
              <HeadingNew
                title={"A Quest for Self-Love, Connection, and Wholeness"}
                start
                small
                white
              />
              <p class="f18 text-white">
                Introducing "Love Mind" – Your pathway to inner harmony, and
                universal connection. Elevate your life journey with this online
                course designed to boost self-esteem, nurture relationships, and
                align with the universe. Join now and elevate your love life and
                beyond.
              </p>
              <div>
                <button
                  className="primary_btn3"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%)",
                    color: "black",
                    fontWeight: "600",
                  }}
                  onClick={handleScroll}
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="bannerVideoArea">
          <video
            width="100%"
            height="100%"
            loop
            controls
            controlsList="nodownload"
            poster="/img/love_mind_video_thumb.webp"
          >
            <source
              src="https://silva-method-official.b-cdn.net/Unstress%20Toolbox/dragan.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </section>
    </div>
  );
}

export default LoveMindOneHero;
