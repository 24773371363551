import { Col, Container, Image, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";
import { useContext } from "react";

function GlassofWaterHero2() {
  const { isMobile, isTablet } = useContext(AuthContext);

  const handleScroll = () => {
    const cardscroll = document.querySelector(".paymenttwonew");

    if (cardscroll) {
      cardscroll.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <section className={`hero-sectionone container py-${isMobile ? 3 : 5}`}>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={5}>
            <div>
              <Image
                src="/img/img_glass_of_water2.jpg"
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </Col>
          <Col xs={12} lg={7} className={`mb-${isMobile || isTablet ? 2 : 0}`}>
            <div
              className="content-container"
              style={{
                width: isMobile || isTablet ? "97%" : "90%",
                marginLeft: isMobile || isTablet ? "0px" : "40px",
                marginTop: isMobile || isTablet ? "30px" : "0px",
              }}
            >
              <h2 className="newpageheader">The Glass Of Water Technique</h2>

              <p
                className="normalpara"
                style={{
                  textAlign: "left",
                }}
              >
                After years of research and scientific experiments, Jose Silva
                brings you over 20 mind control techniques, including the
                intriguing Silva Glass of Water technique. This unique method
                taps into the energy-conducting properties of a simple glass of
                water. By giving it a go, you might just uncover that missing
                puzzle piece for problem-solving, get that final hint for a
                decision, or stumble upon the information or sign you've been
                seeking to crack any challenge.
              </p>
              {/* <button
                className="herodiv-becomememberbtn"
                onClick={handleScroll}
              >
                Find out now
              </button> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default GlassofWaterHero2;
