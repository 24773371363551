import React from 'react'
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMemo } from 'react';
import { AuthContext } from '../../../context/AllContext';
import HlsVideoPlayer from '../../Store/SingleLecturePage/HlsVideoPlayer';
import HeadingNew from '../../AboutUs/HeadingNew';
import ReactPlayer from 'react-player';
import ShineBtn from '../SilvaComboCourse/ShineBtn';
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from 'react';
import Video from "yet-another-react-lightbox/plugins/video";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import { AiOutlineClose } from "react-icons/ai"
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";


function EmotionalWhySection({ }) {
    const [open, setOpen] = useState(false);

    const handleScrollCombo2Curr2 = () => {
        polyfill()

        const doc = document.querySelector(".includelivecheck");
        setTimeout(() => {
            // if (doc) {
            //     doc.scrollIntoView({
            //         behavior: "smooth",
            //         block: "start", // Align the top of the element with the top of the viewport
            //         inline: "nearest", // Scroll until the element is in view
            //     })
            // }
            scrollIntoView(doc, {
                behavior: "smooth",
                block: "end", inline: "nearest"
            });
        }, 500)


    }
    const { isMobile, isTablet } = useContext(AuthContext);


    const data = useMemo(() => {
        return [
            "Understand and connect with others more deeply.",
            "Express and understand yourself better.",
            "Handle disagreements calmly and find solutions.",
            "Deal with pressure and challenges more effectively.",
            "Make more balanced and informed choices.",
            "Develop the ability to bounce back from adversity.",
            "Experience less anxiety and depression, and cope better with stress.",
            "Gain deeper insight into your thoughts, feelings, and behaviors."
        ]

    }, []);

    const videoData = { video_link: "https://silva-method-official.b-cdn.net/RAW-1%20(1).mp4" }


    return (
        <>
            {
                isMobile && (
                    <div className='container d-flex justify-content-start align-items-start' style={{
                        position: "relative",
                        marginBottom: "10px",
                        padding: isMobile ? "10px" : "25px"
                    }}>

                        {/* <ReactPlayer url={"https://silva-method-official.b-cdn.net/RAW-1.mp4"} controls  /> */}
                        {/* <video src="https://res.cloudinary.com/da2cquhhg/video/upload/v1719383259/RAW-1_1_1_zacla2.mp4" style={{
                            width: "100%", height: "100%", borderRadius: "10px",
                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"

                        }} controls poster='/img/Image20240617133336.png' ></video> */}
                        <img src='/img/Image20240617133336.png' style={{
                            borderRadius: "15px",
                            
                        }} />

                        <a class="play-btn444"onClick={() => setOpen(true)} style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)"
                        }}></a>

                    </div>
                )
            }
            <div className='container mb-5 mt-3'>
                <Row className='container justify-content-center align-items-center' style={{
                    position: "relative"
                }}>
                    {
                        !isMobile && (

                            <Col xs={12} lg={6} className={'order-1'}>

                                <div className='container d-flex justify-content-start align-items-start' style={{
                                    marginBottom: "10px",
                                    padding: isMobile ? "0px" : "25px",
                                    position: "relative"
                                }}>


                                    <img src='/img/Image20240617133336.png'  style={{
                                        borderRadius: "15px"
                                    }} />

                                    <a class="play-btn444" onClick={() => setOpen(true)} style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)"
                                    }}></a>


                                    {/* <ReactPlayer url={"https://silva-method-official.b-cdn.net/RAW-1.mp4"} controls  /> */}
                                    {/* <video src="https://res.cloudinary.com/da2cquhhg/video/upload/v1719383259/RAW-1_1_1_zacla2.mp4" style={{
                                        width: "100%", height: "100%", borderRadius: "10px",
                                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"

                                    }} controls poster='/img/Image20240617133336.png' ></video> */}

                                </div>

                            </Col>
                        )
                    }
                    <Col xs={12} lg={6} className={'order-2'}>
                        <h2 style={{
                            fontSize: isMobile ? "1.2rem" : "1.5rem",
                            color: "black",
                            fontWeight: "800"
                        }}>
                            Why You Should Enhance Your
                        </h2>
                        <h2 style={{
                            fontSize: isMobile ? "1.3rem" : "1.6rem",
                            color: "#8019da",
                            fontWeight: "800",
                            display: "block"
                        }}>
                            Emotional Intelligence?
                        </h2>

                        <Row className='my-3'>
                            {
                                data.map((val, i) => (
                                    <Col xs={12} md={6}>
                                        <div className='d-flex gap-2 mb-1' style={{
                                            width: isMobile ? "100%" : "95%",
                                            paddingLeft: 10
                                        }}>

                                            <img src='/img/checkicon2.png' width={25} height={25} />
                                            <p style={{
                                                fontSize: "0.9rem",
                                                color: "black",
                                                fontWeight: "500",
                                                margin: "0px"
                                            }}>
                                                {val}
                                            </p>

                                        </div>
                                    </Col>


                                ))
                            }
                        </Row>
                        {/* <button className='herodiv-becomememberbtnnew' style={{
                        marginLeft: "0",

                    }}
                        onClick={handleScroll}
                    >
                        Enroll now
                    </button> */}
                        <div className='d-flex justify-content-start align-items-center'>
                            <ShineBtn onClick={handleScrollCombo2Curr2} text={"Enroll Now"} />
                            {/* <button className='btnnew22' onClick={handleRefScroll}>
                                <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                        fill="black"
                                    ></path>
                                </svg>
                                <span>Enroll Now</span>
                            </button> */}
                        </div>




                    </Col>

                </Row>

            </div>
            <Lightbox

                plugins={[Video, Fullscreen]}
                open={open}
                close={() => setOpen(false)}
                render={{
                    iconClose: () => <AiOutlineClose size={40} color='#fff' />,
                    iconEnterFullscreen: () => <BsArrowsFullscreen size={40} color='#fff' />,
                    iconExitFullscreen: () => <BsFullscreenExit size={40} color='#fff' />,
                }}
                slides={[
                    {
                        type: "video",
                        width: 1280,
                        height: 720,
                        poster: "/img/Image20240617133336.png",
                        autoPlay: true,
                        sources: [
                            {
                                src: "https://res.cloudinary.com/da2cquhhg/video/upload/v1719383259/RAW-1_1_1_zacla2.mp4",
                                type: "video/mp4"
                            }
                        ]
                    },
                    // ...
                ]}
            // ...
            />
        </>

    )
}

export default EmotionalWhySection