import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { AuthContext } from "../../context/AllContext";

function JoinWhatsAppModal(props) {
  const { isMobile } = useContext(AuthContext);

  useEffect(() => {
    const modalCount = parseInt(Cookies.get("modalCount") || 0);

    if (modalCount < 2) {
      const timer = setTimeout(() => {
        props.handleShow();

        const newCount = modalCount + 1;
        Cookies.set("modalCount", newCount.toString(), { expires: 5 });
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <Modal
      show={props.show}
      size="lg"
      onHide={() => {
        props.onHide();
      }}
      keyboard={false}
      centered
    >
      <Modal.Body
        className="d-flex justify-content-center align-items-center flex-column"
        style={{
          backgroundImage: isMobile
            ? `url("/img/bgbanner2.jpg")`
            : `url("/img/bgbanner1.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "50vh",
          position: "relative",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // backgroundColor: 'rgba(0, 0, 0, 1)', // Adjust opacity as needed
          }}
        />
        <span
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        >
          <AiOutlineClose size={30} color="white" onClick={props.onHide} />
        </span>

        {/* <h6
          style={{
            color: 'white',
            fontSize: '1.1rem',
            marginBottom: '25px',
            lineHeight: '25px',
            textAlign: 'center',
            zIndex: 1, 
          }}
        >
          Explore Silva Life System Masterclass insights! Join the official Silva Method WhatsApp community for detailed
          class information.
        </h6> */}

        <Button
          style={{
            width: "150px",
            zIndex: 1,
            position: "absolute",
            bottom: "60px",
            left: isMobile ? "20px" : "60px",
          }}
          className="col-md-4 button-87"
          onClick={() => {
            const whatsappGroupURL =
              "https://chat.whatsapp.com/Is5alNuz0D12p4srRtFHCV";
            window.open(whatsappGroupURL);
          }}
        >
          <span>
            <FaWhatsapp size={30} color="white" />
          </span>{" "}
          Join Now
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default JoinWhatsAppModal;
