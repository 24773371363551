import React from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import ContactSectionNew from './ContactNewSection'

function ContactNew() {
  return (
    <>
    <HomeHeader />
    <div>
        <ContactSectionNew />

    </div>
    <CustomFooter />
    </>
  )
}

export default ContactNew