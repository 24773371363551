import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../../../components/Shared/Sidebar/Sidebar';
import { AuthContext } from '../../../../context/AllContext';
import Swal from 'sweetalert2';
import { RxHamburgerMenu } from "react-icons/rx"
import logoimg from "../../../../images/newimgs/silvamethod-logo.png"
import { requestData } from '../../../../utils/baseUrl';


const HomeHeader = () => {
   const location = useLocation();
   const navigate = useNavigate();

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const { isUserLoggedIn, userData, logout } = useContext(AuthContext);
   const [listData, setListData] = useState([]);
   const [homeLink, setHomeLink] = useState("/");

   const handleLogout = async () => {
      Swal.fire({
         title: 'Are you sure, you want to logout?',
         showCancelButton: true,
         confirmButtonText: 'Logout',
      }).then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
            logout()
            navigate("/")
         }
      })
   }

   const getAllData = async () => {
      const res = await requestData('latestCourseList', "POST", {
         "start_index": "0",
         "no_of_records": "20"
      })
      //console.log(res);

      if (res && res.error === false) {
         // the below line needed to be updated when the api will be updated. 
         // const sorted = res.data
         // .filter((el,i)=> {
         //    console.log(el.course_id,"element")
         //    return el.course_id !=='silva-method-complete-course-with-live-interactive-program'
         // } )
         // .sort((a, b) => parseInt(b.precedence) - parseInt(a.precedence));

         const filtered= res.data.filter((el,i)=> {
            return el.course_id !=='silva-method-complete-course-with-live-interactive-program'
         } )

         const sortedCourses = filtered.sort((a, b) => {
            const isComboA = a.course_id.toLowerCase().includes('combo');
            const isComboB = b.course_id.toLowerCase().includes('combo');
          
            if (isComboA && !isComboB) return -1;
            if (!isComboA && isComboB) return 1;
          
            // If both or neither are combo courses, sort by precedence
            return Number(a.precedence) - Number(b.precedence);
          });
         setListData(sortedCourses);
      }
   }

   useEffect(() => {
      getAllData();
   }, [])


   // useEffect(() => {
   //    if (isUserLoggedIn) {
   //       getProfile()
   //    }
   // }, [isUserLoggedIn, location.pathname])


   useEffect(() => {
      handleClose();
   }, [location.pathname])


   // useEffect(() => {
   //    if (userData && userData.enrollment_status === "Enrolled") {
   //       setHomeLink("/today")
   //    }
   // }, [userData])

   // console.log(listData,'productListData')

   return (
      <>
         <header style={{ marginBottom: "62px",zIndex:100 }}>

            <div

               // className={stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"}
               className={"header-menu-area sticky_menu"}
               style={{ zIndex: 111 }}>
               <div className="menu_wrapper mx-5">
                  <div className="row align-items-center">
                     <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                        <div>
                           <Link to={homeLink}><img src={"/img/silva-method-official-logo.png"} alt="" /></Link>
                        </div>
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                        <div className="header-right f-right">

                        </div>
                        <div className="header__menu f-right">
                           <nav id="mobile-menu">

                              <ul>





                                 <li><Link to="/about-silva-method">About</Link>
                                    <ul className="submenu">
                                       <li><Link to="/the-silva-mind-control-method">José Silva Mind Control Method</Link></li>
                                       <li><Link to="/jose-silva-method">José Silva</Link></li>
                                       <li><Link to="/what-is-silva-method">What is Silva Method</Link></li>
                                       <li><Link to="/success-stories">Silva Method Success Stories</Link></li>
                                       <li><Link to="/about-silva-method">About us</Link></li>
                                       <li><Link to="/contact">Contact Us</Link></li>
                                       <li><Link to="/research">Research</Link></li>
                                    </ul>
                                 </li>

                                 {/* <Link to="/silva-method-courses" style={{ textDecoration: "none", color: "white", marginLeft: "25px", padding: "0px" }}>
                                    <button className='styledbtn23'>
                                       Enroll Now
                                    </button>
                                 </Link> */}
                                 <li><Link to="/courses/basic-lecture-series">Seminars</Link>
                                    <ul className="submenu">
                                       <li><Link to="/courses/basic-lecture-series">Basic Lecture Series(BLS-4)</Link></li>
                                       <li><Link to="/courses/seminar">Seminars</Link></li>
                                       <li><Link to="/courses/silva-life-system-seminar">Silva Life System</Link></li>
                                       <li><Link to="/courses/silva-intution-system-seminar">Silva Intuition System</Link></li>
                                       <li><Link to="/courses/silva-mastery-system">Silva Mastery System</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/events/live">Events</Link>
                                    <ul className="submenu">
                                       {/* <li><Link to="/shop">Live</Link></li>
                                       <li><Link to="/shopDetails">Online</Link></li> */}
                                       <li><Link to="/events/live">Live In - Person</Link></li>
                                       <li><Link to="/events/online">Live(Online) - Zoom</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/instructor">Instructors</Link>
                                    <ul className="submenu">
                                       <li><Link to="/instructor">Instructors</Link></li>
                                       <li><Link to="/instructor/find-instructor">Find Instructor</Link></li>
                                       <li><Link to="/instructor/become-silva-instructor">Become an Instructor</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/store/course/combo-plan">Products</Link>
                                    <ul className="submenu">
                                       {/* <li>
                                          <Link to={'/store/course/combo-plan'}>{'Complete course with live interaction'}</Link>
                                       </li> */}
                                       {listData && listData.map((listItem) =>
                                          <li key={listItem.course_id}><Link to={`/store/course/${listItem.course_id !== "love-program" ? listItem.course_id : "silva-love-program"}`}>
                                             {/* <span className={listItem.course_id.includes('combo')?'combo-li':''}>{listItem.course_title}</span>  {listItem.course_id.includes("combo") && <span style={{color:"red",fontWeight:"bold",marginLeft:"5px"}}>New</span>} */}
                                             <span>{listItem.course_title}</span>
                                             </Link>
                                          </li>
                                       )}
                                    </ul>
                                 </li>
                                 <li style={{ cursor: "pointer" }}><a onClick={() => window.open("https://silvamethod.com/stores")}>Blogs</a>
                                    <ul className="submenu">

                                       <li><a onClick={() => window.open("https://silvamethod.com/stores")}>English Blogs</a></li>
                                       <li><a onClick={() => window.open("https://silvamethod.com/stores/category/spanish")}>Spanish Blogs</a></li>
                                    </ul>
                                 </li>
                                 {
                                    isUserLoggedIn && (
                                       <li>
                                          <Link to="/store/profile"> <img src={userData?.profile_image || "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"}
                                             style={{ width: "50px", height: "50px", borderRadius: '50%' }}
                                          /></Link>
                                          <ul className='submenu'>
                                             {
                                                userData && userData.enrollment_status == "Enrolled" && (
                                                   <li><Link to="/today">View My Dashboard</Link></li>
                                                )
                                             }
                                             {
                                                userData && userData.enrollment_status == "Enrolled" && (
                                                   <li><Link to="/store/profile/new/settings/myorders">View My Orders</Link></li>
                                                )
                                             }


                                             <li onClick={handleLogout}><span className='logoutli'>Logout</span></li>
                                             <li><Link to="/store/profile">My Profile</Link></li>
                                          </ul>



                                       </li>

                                    )
                                 }

                                 {
                                    !isUserLoggedIn && (
                                       <li><Link to="/login">Login/Register</Link></li>
                                    )
                                 }
                                 <li><a className='silvacases' onClick={() => window.open("https://silvamethod.com/stores/shop/")}>Books</a></li>
                                 <li><a className='silvacases' onClick={() => window.open("https://www.silvacasesonline.com")}>Silva Cases</a></li>
                              </ul>
                           </nav>
                        </div>

                        <div className="side-menu-icon d-lg-none text-end">
                           <button onClick={handleShow} className="side-toggle border-0 bg-transparent">
                              {/* <i className="fas fa-bars"></i>  */}
                              <RxHamburgerMenu color='black' size={30} />
                           </button>
                        </div>
                     </div>
                  </div>
               </div >
            </div >
         </header >

         <Sidebar show={show} handleClose={handleClose} listData={listData} handleShow={handleShow} homeLink={homeLink} />
      </>
   );
};

export default HomeHeader;