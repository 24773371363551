import React, { useMemo } from 'react'
import Slider from 'react-slick';

import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import "./swiperslider.css"
import { useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';

function FifthSlider() {


    const { isDesktopOrLaptop, isMobile } = useContext(AuthContext);
    const [activeSlide, setActiveSlide] = useState(0);


    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows:false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        cssEase: "linear",
        centerMode: isMobile ? false : true,
        centerPadding: "20%",
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };


    const reviews = useMemo(() => {
        return [
            {
                title: "Depression",
                para: "Feelings of sadness, tearfulness, emptiness or hopelessness",
                img: require("../../../images/adimgs/sliderimg1.png"),
                paralist: [
                    "Angry outbursts or frustration",
                    "Loss of interest in normal activities, such as sex, hobbies or sports",
                    "Sleep disturbances",
                    "Tiredness and lack of energy",
                    "Reduced appetite and weight loss or increased cravings for food and weight gain",
                    "Feeling restlessness",
                    "Back pain or headaches"
                ]
            },
            {
                title: "Stress",
                para: "Anxiety or irritability",
                img: require("../../../images/adimgs/sliderimg2.png"),
                paralist: [
                    "Depression",
                    "Panic attacks",
                    "Sadness",
                    "Overeating or developing an eating disorder",
                    "Weak immune system",
                    "Aches and pains",
                    "Chest pain or a feeling like your heart is racing",
                    "Exhaustion or trouble sleeping",
                    // "Headaches, dizziness or shaking"
                ]
            },
            {
                title: "Anxiety",
                para: "Feeling nervous, restless or tense",
                img: require("../../../images/adimgs/sliderimg3.png"),
                paralist: [
                    "Having a sense of impending danger, panic or doom",
                    "Having an increased heart rate",
                    "Breathing rapidly (hyperventilation)",
                    "Sweating",
                    "Trembling",
                    "Feeling weak or tired",
                    "Trouble concentrating or thinking about anything other than the present worry",
                    "Having trouble sleeping",
                    // "Experiencing gastrointestinal (GI) problems",
                    // "Having difficulty controlling worry",
                    // "Having the urge to avoid things that trigger anxiety"
                ]
            }
        ]
    }, [])




    return (
        <div className='pt-4 newadslider'>
            {/* <div className="reviewscontainer">
                <div className='row justify-content-center'>
              
                    <div style={{ width: isDesktopOrLaptop ? "100%" : "95%" }} className='reviewslider newslider'>
                        <Slider {...settings}>
                            {
                                reviews.map((val, i) => (
                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                        <FifthSliderSingle val={val} />
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div> */}

            <Slider {...settings}>
                {
                    reviews.map((val, i) => (
                        <Card className='pb-4 px-4 cardslidernew' key={i}>
                            <Card.Body>
                                <Row className='justify-content-between align-items-center'>
                                    <Col xs={12} sm={12} md={12} lg={5}>
                                        <Image src={val.img} />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={7}>
                                        <div style={{ marginLeft: "20px", marginTop: "30px" }}>
                                            <h3 style={{ color: "#d901fd", fontWeight: "800" }}>
                                                {val.title}
                                            </h3>
                                            <p className='text-dark'>
                                                {val.para}
                                            </p>

                                            {
                                                val.paralist.map((val) => (
                                                    <div className='listdivbox' key={i + 1}>
                                                        <div className='listbox'>

                                                        </div>
                                                        <p className='text-dark listpara'>
                                                            {val}
                                                        </p>
                                                    </div>
                                                ))
                                            }


                                        </div>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ))
                }
            </Slider>


        </div>
    )
}

export default FifthSlider
