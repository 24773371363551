import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";
import ParaComp from "../../pages/SilvaManifestationProgram/ParaComp";
import "./aboutTop.css";
import { Card, Col, Image, Row } from "react-bootstrap";
import { BsBook, BsGlobeAmericas } from 'react-icons/bs';
import { GiTeacher } from 'react-icons/gi';
import CountUp from "react-countup";
import HeadingNew from "../../pages/AboutUs/HeadingNew";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

function AboutTopSection() {
  const {
    isTablet,
    isBigScreen,
    isMobile,
    isDesktopOrLaptop
  } = useContext(AuthContext);

  return (
    <>
      <div className="aboutusmaindiv">
        {/* <div className="d-flex flex-column justify-content-center align-items-left p-5 w-75">
          <p
            style={{
              maxWidth: isTabletOrMobile || isPortrait ? "100%" : "75%",
              fontWeight: "500",
              fontSize: "1.5rem",
              color: "white",
            }}
          >
            Evolve Over Time. Thrive To Transform. Achieve Your Dreams
          </p>
          <div style={{ marginTop: "50px" }}>
            <p style={{ color: "white" }}>
              The Silva Method firmly believes that the untapped potential
              residing within each human mind holds the catalyst for our next
              revolutionary breakthrough.
            </p>
            <p style={{ color: "white" }}>
              We have dedicated ourselves to meticulous research and
              collaboration for over five decades. Guided by scientists and a
              legacy of 50 years of collaboration, we strive to awaken this
              potential for individuals worldwide.
            </p>
          </div>
        </div>
        <div className="w-100">
          <img src={AboutImage} className="abouttopimage"/>
        </div> */}
      </div>
      <div className={`container pt-${(isMobile || isTablet) ? 5 : 0}`} style={{ position: "relative" }}>
        <div className="newdivcontainer">
          <Row className="justify-content-center align-items-start">
            <Col md={6} lg={6}>
              <div style={{ marginTop: isMobile ? "0px" : isTablet?"0px": "100px" }}>

                <h1
                  className='white-color'
                  style={{
                    fontWeight: "700",
                    fontSize: isDesktopOrLaptop || isBigScreen ? "40px" : "25px",
                    textAlign: "start",
                    marginBottom:!isTablet?"20px":"0px"
                  }}
                >
                  <button className="herodiv-becomememberbtn5">
                  About Silva Method
                  </button>
                </h1>

              </div>
             {
              !isTablet && (
                <>
                 <HeadingNew title={"Evolve Over Time."} small start white />
                <HeadingNew title={"Thrive To Transform."} start white small />
                <HeadingNew title={"Achieve Your Dreams."} small start white />
                </>
              )
             }

              <ParaComp
                data={
                  [
                    "The Silva Method firmly believes that the untapped potential residing within each human mind holds the catalyst for our next revolutionary breakthrough.",
                    "We have dedicated ourselves to meticulous research and collaboration for over five decades just to understand how to control your mind and thoughts and how mankind will get benefits out of it. Guided by scientists and a legacy of 50 years of collaboration, we strive to ignite the fire of  The Silva Method Method in every individual worldwide."
                  ]
                }
              />
              {!isMobile && <Link to={'/silva-method-courses'}><button className="herodiv-becomememberbtn2">Ready to Start</button></Link>}
            </Col>
            <Col md={6} lg={6}>
              <div className="aboutImg">
                <Image style={{ borderRadius: "10px" }} src={require("../../images/about 1.webp")} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="mt-5">
        <Row
          className={`justify-content-center align-items-center w-${isBigScreen ? 75 : 100} m-auto`}
        >
          <Col xs={12} sm={12} md={6} lg={4} className="p-2">
            <Card
              style={{
                width: "100%",
                height: "200px",
                border: "none",
                borderRadius: "10px",
                boxShadow: "5px 6px 5px -2px rgba(0,0,0,0.5)",
              }}
            >
              <Card.Body className="cardbdy2">
                {/* <Image
                src={image}
                fluid
              /> */}
                <div
                  className="d-flex justify-content-start align-items-center flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  <BsBook size={50} color="white" />
                  <h2
                    className="text-center"
                    style={{
                      fontSize: "1rem",
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    <CountUp end={12000000} className="iconclass" duration={2.5} suffix="+" />
                  </h2>
                  <h2
                    className="white-color text-center"
                    style={{ fontSize: "25px", fontWeight: "500" }}
                  >
                    Graduates
                  </h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="p-2">
            <Card
              style={{
                width: "100%",
                height: "200px",
                border: "none",
                borderRadius: "10px",
                boxShadow: "5px 6px 5px -2px rgba(0,0,0,0.5)",
              }}
            >
              <Card.Body className="cardbdy2">
                {/* <Image
                src={image}
                fluid
              /> */}
                <div
                  className="d-flex justify-content-start align-items-center flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  <GiTeacher size={50} color="white" />
                  <h2
                    className="text-center"
                    style={{
                      fontSize: "1rem",
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    <CountUp end={500} className="iconclass" duration={5} suffix="+" />
                  </h2>
                  <h2
                    className="white-color text-center"
                    style={{ fontSize: "25px", fontWeight: "500" }}
                  >
                    Instructors
                  </h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="p-2">
            <Card
              style={{
                width: "100%",
                height: "200px",
                border: "none",
                borderRadius: "10px",
                boxShadow: "5px 6px 5px -2px rgba(0,0,0,0.5)",
              }}
            >
              <Card.Body className="cardbdy2">
                {/* <Image
                src={image}
                fluid
              /> */}
                <div
                  className="d-flex justify-content-start align-items-center flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  <BsGlobeAmericas size={50} color="white" />
                  <h2
                    className="text-center"
                    style={{
                      fontSize: "1rem",
                      color: "white",
                      fontWeight: "400",
                    }}
                  >
                    <CountUp end={120} className="iconclass" duration={5} suffix="+" />

                  </h2>
                  <h2
                    className="white-color text-center"
                    style={{ fontSize: "25px", fontWeight: "500" }}
                  >
                    Countries
                  </h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AboutTopSection;
