import React, { useState } from 'react';
import './SlidingAnimation.css'; // Import your CSS file for styling
import HeadingNew from '../AboutUs/HeadingNew';
import RoundIcon from './RoundIcon';
import { Card } from 'react-bootstrap';
import FormExample from './FormSubmit';
import { useContext } from 'react';
import { AuthContext } from '../../context/AllContext';

const SubmitForm = () => {
    const [showContent, setShowContent] = useState(false);
    const [validated, setValidated] = useState(false);
    const {isMobile} = useContext(AuthContext);

    const handleButtonClick = () => {
        setShowContent(!showContent);
    };

    return (
        <>
            {/* <div className="containernewup">
                <div className={`content ${showContent ? 'slide-left' : ''}`}>
                    <p>Demo Text</p>
                </div>
                <div className={`content ${showContent ? '' : 'slide-left'}`}>
                    {showContent && (
                        <div>
                            <h2>New Content</h2>
                            <p>This is the revealed content after button click.</p>
                        </div>
                    )}
                </div>
                <button onClick={handleButtonClick}>Toggle Content</button>
            </div> */}
            <div className='container containernewup d-flex justify-content-center align-items-center flex-column paymenttwonew2' style={{
                paddingTop:"60px",
                paddingBottom:"60px"
            }}>

                <HeadingNew start title={"Get Your Free Masterclass"} white />

                <div style={{
                    position: "relative"

                }}>
                    

                    {/* <HeadingNew center title={"To Join The Free Class"} white /> */}

                    {/* <div style={{
                        position: "absolute",
                        top: isMobile?-95:-110,
                        left: 0
                    }}>
                        <RoundIcon src={"/img/icon/medicon2.png"} />
                    </div>
                    <div style={{
                        position: "absolute",
                        top:isMobile?-80:-100,
                        right: 20
                    }}>
                        <RoundIcon src={"/img/icon/medicon1.png"} size={50} />
                    </div>
                    <div style={{
                        position: "absolute",
                        bottom: -50,
                        right:isMobile?-40:-70
                    }}>
                        <RoundIcon src={"/img/icon/medicon.png"} size={70} />
                    </div>
                    <div style={{
                        position: "absolute",
                        bottom: -50,
                        left: isMobile?-40:-100
                    }}>
                        <RoundIcon src={"/img/icon/medicon4.png"} size={70} />
                    </div> */}
                </div>

                <Card className='my-5 content' style={{
                    // background:"linear-gradient(90deg, rgba(162,59,205,1) 35%, rgba(203,44,188,1) 100%)",
                    background:"rgba(0,0,0,0.5)"
                }}>
                    <Card.Body>

                        <FormExample validated={validated} setValidated={setValidated} />
                    </Card.Body>
                </Card>



            </div>
        </>
    );
};

export default SubmitForm;
