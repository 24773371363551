import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function HealingMeditation() {
  return (
    <div className="about-container">
      <Container>
        <Row className="align-items-center gy-5">
       
          <Col md={6}>
       
            <div className="content">
              <h1 className='lora-sans' style={{
                fontSize:"2.25rem"
              }}>What is Healing Meditation</h1>
              <p className='robotofont'>
              Healing meditation is a powerful practice. It is primarily aimed at restoring balance to the mind and body. It involves entering a state of deep relaxation where the body’s natural healing mechanisms are activated. This form of meditation focuses on releasing emotional and physical blockages, promoting overall well-being. The practice of healing meditation allows you to connect with your inner self, calm the mind, and support the body’s ability to heal. By regularly practicing healing meditation, you can address both emotional wounds and physical discomfort, helping you find peace and balance.


              </p>
            
            </div>
          </Col>
          <Col md={6}>
            <div className="image-container mx-4">
              <img src="/img/bg/bghero/icons/x58oeg8exbllsm2otdhn.jpg" alt="Woman with arms raised in nature"
                className='hero-imgabout'
              />
              <div className="shape-1"></div>
              <div className="shape-2"></div>
              <div className="dots"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HealingMeditation