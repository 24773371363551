import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import HeadingNew from '../../AboutUs/HeadingNew'

function PurpleCta() {



    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymentsection");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };



    return (
        <Card style={{ width: "100%" }} className='fourthcard2'>
            <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                <HeadingNew small title={"Fecha y hora"} white />
                <HeadingNew title={"16 de marzo"} white />
                <button class="btn btn-warning mt-3" onClick={handleScroll}>
                Enlístate ahora
                </button>
                {/* <button className='outlinebtn' onClick={() => navigate("/store/course/combo-plan")}>
                    Únete al Programa
                </button> */}
            </Card.Body>
        </Card>
    )
}

export default PurpleCta