import 'react-vertical-timeline-component/style.min.css';
import Cookies from 'js-cookie';
import loadable from '@loadable/component';
import { useContext, useRef } from 'react';
import { useEffect } from 'react';
import { reqData, requestData2 } from './utils/baseUrl';
import PublicRoutes from './privateRoutes/PublicRoutes';
import PrivateRoute from './privateRoutes/PrivateRoute';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import { AuthContext } from './context/AllContext';
import { reactLocalStorage } from 'reactjs-localstorage';
import 'react-pagination-bar/dist/index.css'
import 'animate.css';
import "./blobz.css"



import WorkBook from "./pages/WorkBook/WorkBook"
import Login from './pages/Login/Login';
import ServicesOne from './pages/Services/ServicesOne/ServicesOne';
import NotFound from './pages/NotFound/NotFound';
import { Toaster } from 'react-hot-toast';
import ResetPassword from './pages/resetpassword/ResetPassword';
import SubscriptionSuccess from './pages/payment/SubscriptionSuccess';
import SubscriptionFailure from './pages/payment/SubscriptionFailure';
import LoadingSpinner from './pages/LoadingSpinner';



import AllCourses from './pages/CourseDetails/Courses';
import SilvaHome from './pages/Home/Home/SilvaHome';
import SilvaHome2 from './pages/Home/Home/SilvaHome2';
import SilvaMemberShip from './pages/silvamembership/SilvaMemberShip';
import WithHeaderFooter from './pages/WithHeaderFooter/WithHeaderFooter';
import CourseDetails from './pages/CourseDetails/CourseDetails';
import PaymentSuccess from './pages/payment/PaymentSuccess';
import PaymentUnSuccess from './pages/payment/PaymentUnsuccess';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import EmailNewsLetterModal from './components/EmailNewsLetterModal/EmailNewsLetterModal';
import HomeStudyCourses from './pages/Home/Home/Homestudycourses/HomeStudyCourses';
// import CoursePage from './pages/CoursePage/CoursePage';
import WithCookiePage from './components/WithCookiePage';
import HomeStudy2 from './pages/Home/Home/Homestudycourses/HomeStudy2';
import SuccessTest from './components/SuccessTest';
import FailureTest from './components/FailureTest';
import Booking from './pages/booking/page';
import Participant from './pages/confirm-participant/page';
import BookingNew from './pages/newbooking';
import LiveClassSuccess from './pages/newbooking/LiveClassSuccess';
import FailedPayment from './pages/newbooking/FailedPayment';
import BookingNewTwo from './pages/newbooking/Booking2';
import LiveEventSuccess from './pages/paypalsuccess/LiveEventSuccess';
import LiveEventFailed from './pages/paypalsuccess/LiveEventFailed';
import ThankYouPage from './pages/booking/ThankYouPage';
import ThankYouPage2 from './pages/booking/ThankYou2';
import ContactNew from './pages/payment/ContactNew';
import LandingPage1 from './pages/Draganpages/LandingPage1';
import GlassofWaterLanding from './pages/LandingPages/GlassofWaterLanding';
import LandingPage2 from './pages/Draganpages/LandingPage2';
import ThankYouPage3 from './pages/booking/ThankYouThree';
import TFTLanding from './pages/LandingPages/ThreeFingerTechnique/TFTLanding';
import VideoCoursePage from './pages/CoursePage/VideoCoursePage';
import Refund from './pages/Refund/Refund';
import NewTest from './pages/booking/NewTest';
import ComboCourse from './pages/LandingPages/ComboCourse/ComboCourse';
import CoursePage2 from './pages/CoursePage/CoursePage2';
import UnstressLanding from './pages/Unstress-landing/UnstressLanding';
import ComboPlusLive from './pages/LandingPages/ComboPlusLive1/ComboPlusLive';
import ComboLiveLandingPageOne from './pages/ComboLiveLanding/ComboLiveLandingPageOne';
import SpansihLanding from './pages/LandingPages/SpanishLanding/SpansihLanding';
import LoveMindOne from './pages/LoveMind/One/LoveMindOne';
import LoveMindTwo from './pages/LoveMind/Two/LoveMindTwo';
import SpanishPaymentPage from './pages/LandingPages/SpanishLanding/SpanishPaymentPage';
import PreviousVideos from './pages/LiveInteraction/PreviousVideos';
import AppointMent from './pages/AppointMent/AppointMent';
import ZoomLive from './pages/LandingPages/ZoomLive/ZoomLive';
import ComboCourse2 from './pages/LandingPages/ComboCourse2/ComboCourse2';
import ZoomMeet from './pages/zoom-meet/ZoomMeet';
import HypnosisPage from './pages/LandingPages/HypnosisPage';
import SecondHypnosis from './pages/LandingPages/hypnosisPage2/SecondHypnosis';
import SleepMeditation from './pages/LandingPages/SleepMeditation/SleepMeditation';
import Testimonials from './pages/LandingPages/ComboCourse2/Testimonials';
import MindControl1 from './pages/LandingPages/MindControl1/MindControl1';
import SilvaMindControlBook from './pages/LandingPages/SilvaMindControlBook/SilvaMindControlBook';
import FreeCourse from './pages/LandingPages/FreeCourse/FreeCourse';
import DashboardFree from './pages/LandingPages/FreeCourse/DashboardFree';
import ThankYouPageForm from './pages/LandingPages/FreeCourse/ThankYouPageForm';
import EmotionalInt from './pages/LandingPages/EmotionalIntelligence/EmotionalInt';
import SilvaComboCourse from './pages/LandingPages/SilvaComboCourse';
import EmotionalInt2 from './pages/LandingPages/EmotionalIntelligence/EmotionalInt2';
import Manifestation from './pages/LandingPages/Manifestation';
import Upgrade from './pages/LandingPages/Upgrade/Upgrade';
import CoursePageNew from './pages/CoursePage2/CoursePage2';
import HabitControl from './pages/LandingPages/HabitControl';
import NewComboLanding from './pages/LandingPages/newComboLanding/NewComboLanding';
import AppoinmentIndex from './pages/LandingPages/AppointmentBook/AppoinmentIndex';
import CoursePage from './pages/CoursePage/CoursePage';
import NewLandingPage from './pages/CoursePage2/NewLandingPage';
import SciencePage from './pages/LandingPages/SciencePage';
import SingleCoursePage from './pages/SingleCoursePage';
import NewComboCourse from './pages/LandingPages/NewComboCourse';
import ThankYouPageForm2 from './pages/LandingPages/FreeCourse/ThankYouPageForm2';
import BlogListing from './pages/LandingPages/BlogPage/BlogListing';
import BlogDetails from './pages/LandingPages/BlogPage/BlogDetails';
import BlogLandingPage1 from './pages/LandingPages/BlogPage/BlogLandingPage1/BlogLandingPage1.js';
import BlogLandingPage2 from './pages/LandingPages/BlogPage/BlogLandingPage2/BlogLandingPage2.js';
import BlogLandingPage3 from './pages/LandingPages/BlogPage/BlogLandingPage3/index.js';
import IntroToMindFulNess from './pages/LandingPages/BlogPage/IntroToMindFulNess/IntroToMindFulNess.js';
import StressReductionTechniques from './pages/StressReductionTechniques/index.js';
import SilvaForAll from './pages/SilvaForAll/SilvaForAll.js';
import BlackFridaySale from './pages/LandingPages/BlackFridaySale/BlackFridaySale.js';






const AboutJoseSilva = loadable(() => import('./pages/About/AboutJoseSilva'), {
  fallback: <LoadingSpinner />
})

const AboutDianaSilva = loadable(() => import('./pages/About/AboutDianaSilva'), {
  fallback: <LoadingSpinner />
})

const FaqPage = loadable(() => import('./pages/About/FaqPage'), {
  fallback: <LoadingSpinner />
})

const SilvaAffiliate = loadable(() => import('./pages/Affiliate/SilvaAffiliate'), {
  fallback: <LoadingSpinner />
})

const SilvaBenefitsPage = loadable(() => import('./pages/SilvaBenefits/SilvaBenefitsPage'), {
  fallback: <LoadingSpinner />
})

const AboutUs = loadable(() => import('./pages/AboutUs/AboutUs'), {
  fallback: <LoadingSpinner />
})
const Contact = loadable(() => import('./pages/Contact/Contact'), {
  fallback: <LoadingSpinner />
})
const Research = loadable(() => import('./pages/Contact/Research'), {
  fallback: <LoadingSpinner />
})
const WhatIsSilvaMethod = loadable(() => import('./pages/AboutUs/WhatIsSilvaMethod'), {
  fallback: <LoadingSpinner />
})
const BasicLecture = loadable(() => import('./pages/seminars/BasicLecture'), {
  fallback: <LoadingSpinner />
})
const Seminars = loadable(() => import('./pages/seminars/Seminars'), {
  fallback: <LoadingSpinner />
})

const LifeSystem = loadable(() => import('./pages/seminars/LifeSystem'), {
  fallback: <LoadingSpinner />
});

const IntuitionSystem = loadable(() => import('./pages/seminars/IntuitionSystem'), {
  fallback: <LoadingSpinner />
});

const MasterySystem = loadable(() => import('./pages/seminars/MasterySystem'), {
  fallback: <LoadingSpinner />
});



const CommentSection = loadable(() => import('./pages/comments/CommentSection'), {
  fallback: <LoadingSpinner />
});

const ProfilePage = loadable(() => import('./pages/ProfilePage/ProfilePage'), {
  fallback: <LoadingSpinner />
});

const MyCoursesPage = loadable(() => import('./pages/ProfilePage/MyCoursesPage'), {
  fallback: <LoadingSpinner />
});

const MyBasicInfo = loadable(() => import('./pages/ProfilePage/MyBasicInfo'), {
  fallback: <LoadingSpinner />
});

const MyPlan = loadable(() => import('./pages/ProfilePage/MyPlan'), {
  fallback: <LoadingSpinner />
});

const ChangePassword = loadable(() => import('./pages/ProfilePage/ChangePassword'), {
  fallback: <LoadingSpinner />
});

const LiveEvents = loadable(() => import('./pages/events/LiveEvents'), {
  fallback: <LoadingSpinner />
});

const OnlineEvents = loadable(() => import('./pages/events/OnlineEvents'), {
  fallback: <LoadingSpinner />
});


const Instructors = loadable(() => import('./pages/Instructors/Instructors'), {
  fallback: <LoadingSpinner />
});

const FindInstructor = loadable(() => import('./pages/Instructors/FindInstructor'), {
  fallback: <LoadingSpinner />
});

const BecomeInstructor = loadable(() => import('./pages/Instructors/BecomeInstructor'), {
  fallback: <LoadingSpinner />
});

const SingleLocationInstructorsCard = loadable(() => import('./pages/Instructors/SingleLocationInstructorsCard'), {
  fallback: <LoadingSpinner />
});

const SingleInstructorDetailsPage = loadable(() => import('./pages/Instructors/SingleInstructorDetailsPage'), {
  fallback: <LoadingSpinner />
});

const StoreProducts = loadable(() => import('./pages/Store/StoreProducts'), {
  fallback: <LoadingSpinner />
});

const EnglishBlog = loadable(() => import('./pages/Blogs/EnglishBlog'), {
  fallback: <LoadingSpinner />
});

const SpanishBlog = loadable(() => import('./pages/Blogs/SpanishBlog'), {
  fallback: <LoadingSpinner />
});

const SingleLecturePage = loadable(() => import('./pages/Store/SingleLecturePage/SingleLecturePage'), {
  fallback: <LoadingSpinner />
});

const Books = loadable(() => import('./pages/Store/Books'), {
  fallback: <LoadingSpinner />
});




const PrivacyPolicy = loadable(() => import('./pages/Store/PrivacyPolicy'), {
  fallback: <LoadingSpinner />
});

const TermsAndConditions = loadable(() => import('./pages/Store/TermsAndConditions'), {
  fallback: <LoadingSpinner />
});

const NewMembership = loadable(() => import('./pages/AdvertisePages/newmembership/NewMembership'), {
  fallback: <LoadingSpinner />
});

const FreeAudio = loadable(() => import('./pages/FreeAudio'), {
  fallback: <LoadingSpinner />
});

const MeditationJourney = loadable(() => import('./pages/AdvertisePages/meditationJourney/MeditationJourney'), {
  fallback: <LoadingSpinner />
});

const YoutubeVideos = loadable(() => import('./pages/Store/YoutubeVideos'), {
  fallback: <LoadingSpinner />
});

const SilvaMethodTechnique = loadable(() => import('./pages/CoursePage/SilvaMethodTechnique'), {
  fallback: <LoadingSpinner />
});

const SilvaSynopsis = loadable(() => import('./pages/seopages/SilvaSynopsis'), {
  fallback: <LoadingSpinner />
});

const MorningMediation = loadable(() => import('./pages/seopages/MorningMediation'), {
  fallback: <LoadingSpinner />
});

const ThreeFingerTechnique = loadable(() => import('./pages/seopages/ThreeFingerTechnique'), {
  fallback: <LoadingSpinner />
});

const SilvaSleepMeditation = loadable(() => import('./pages/seopages/SilvaSleepMeditation'), {
  fallback: <LoadingSpinner />
});

const SilvaEvents = loadable(() => import('./pages/SilvaEvents'), {
  fallback: <LoadingSpinner />
});

const MeditationJourney2 = loadable(() => import('./pages/seopages/MeditationJourney2'), {
  fallback: <LoadingSpinner />
});

const JoseMindControl = loadable(() => import('./pages/JoseMindControl/JoseMindControl'), {
  fallback: <LoadingSpinner />
});



const DeleteAccount = loadable(() => import('./pages/DeleteAccount'), {
  fallback: <LoadingSpinner />
});


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [currency, setCurrency] = useState(localStorage.getItem("currency"))




  const handleClose = () => setShow(false);
  const { setUserData, userData } = useContext(AuthContext);
  const getProfile = async () => {
    const res = await reqData("getStudentProfile", "POST", {});
    if (res && res.error === false) {
      setUserData({
        ...res.data,
        total_course: res.total_course
      });
    }
  }

  useEffect(() => {
    // if (localStorage.getItem("token")) {
    //   // console.log(localStorage.getItem("token"), "randomtoken")

    // }
    getProfile()
  }, [location])






  useEffect(() => {
    reactLocalStorage.remove("coupon")
    reactLocalStorage.remove("couponplan")

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
    getLocation()
    // document.addEventListener('contextmenu',
    // event => event.preventDefault());


    return () => {
      reactLocalStorage.remove("coupon")
      reactLocalStorage.remove("couponplan")
    }

  }, [])


  async function getLocation() {
    fetch("https://ipapi.co/json/").then((response) => response.json())
      .then((data) => {
        localStorage.setItem("countryname", data.country_name)
        localStorage.setItem("currency", data.currency)
        setCurrency(data.currency)
      })
  }




  useEffect(() => {
    if ((
      location.pathname.includes("story-of-jose-silva-the-man-behind-silva-method") ||
      location.pathname.includes("story_of_jose_silva_the_man_behind_silva_method") ||
      location.pathname.includes("/store/jose-silva-method")
    )) {
      navigate("/jose-silva-method")
    } else if (location.pathname.includes("know-how-the-silva-method-works") || location.pathname.includes("what-long-term-benefits-could-one-expect-from-silva-method")) {
      navigate("/what-is-silva-method")
    } else if ((location.pathname.includes("/store/what-is-silva-method") || location.pathname.includes("/store/what_is_silva_method"))) {
      navigate("/what-is-silva-method")
    } else if ((location.pathname.includes("/store/silva-life-system-the-core-of-silva-method-program") || location.pathname.includes("/store/silva_life_system_the_core_of_silva_method_program"))) {
      navigate("/store/course/silva-life-system-digital-audio")
    } else if ((location.pathname.includes("/store/home-study-programs") || location.pathname.includes("/store/home_study_programs"))
      || (location.pathname.includes("/store/digital-courses") || location.pathname.includes("/store/digital_courses"))
      //  || (location.pathname.includes("/store/course-category/silva-home-study-programs") || location.pathname.includes("/store/course_category/silva_home_study-programs"))
      || (location.pathname.includes("/store/course-category/silva-home-study-programs") || location.pathname.includes("/store/course_category/silva_home_study_programs"))
      || (location.pathname.includes("/store/product/silva-long-relax-exercise-by-jose-silva-digital") || location.pathname.includes("/store/product/silva_long_relax_exercise_by_jose_silva_digital"))
      || (location.pathname.includes("/store/product/long-relax-meditation-silva-alpha-sound") || location.pathname.includes("/store/product/long_relax_meditation_silva_alpha_sound"))
      || (location.pathname.includes("/store/dynamic-meditation"))
      || (location.pathname.includes("/store/silva-master-classes"))
    ) {
      navigate("/silva-method-courses")
    } else if ((location.pathname.includes("/store/product/alpha-sound-digital") || location.pathname.includes("/store/product/alpha_sound_digital"))) {
      return navigate("/store/course/silva-alpha-sound")
    } else if ((location.pathname.includes("/store/product/theta-sound-digital") || location.pathname.includes("/store/product/theta_sound_digital"))) {
      navigate("/store/course/silva-theta-sound")
    } else if ((location.pathname.includes("/store/product/silva-alpha-sound-meditation") || location.pathname.includes("/store/product/silva_alpha_sound_meditation"))) {
      navigate("/store/course/silva-alpha-sound")
    } else if (location.pathname.includes("/store/my-profile/courses/purchased")) {
      if (userData) {
        navigate("/")
      } else {
        navigate("/login")
      }
    } else if (location.pathname.includes("/store/jose-silva-intuition")) {
      navigate("/store/course/silva-intuition-system-digital-audio-program")
    } else if (location.pathname.includes("/store/silva-method-a-holistic-approach-to-overcome-anxiety-disorders-and-depression")) {
      window.location.assign("https://silvamethod.com/stores/silva-method-a-holistic-approach-to-overcome-anxiety-disorders-and-depression/")
    } else if (location.pathname.includes("/store/emotion-stress") || location.pathname.includes("/store/how-to-reduce-stress")) {
      window.location.assign("https://silvamethod.com/stores/emotion-stress/")
    } else if (location.pathname.includes("/freeaudio")) {
      navigate("/silva-relaxation-exercise")
    } else if (location.pathname.includes("/silva_membership") || location.pathname.includes("/silva-membership")
      || location.pathname.includes("/enrollment")
    ) {
      navigate("/silva-method-courses")
    } else if (location.pathname.includes("/instructor/find_instructor")) {
      navigate("/instructor/find-instructor")
    } else if (location.pathname.includes("/store/course/silva-method-manifesting-program-complete")) {
      navigate("/store/course/silva-method-manifesting-program")
    } else if (location.pathname.includes("/four-day-immersion-seminar")) {
      navigate("/join-live-class-india")
    } else if (location.pathname.includes("/join-live-two")) {
      navigate("/silva-life-system-with-dragan")
    } else if (location.pathname.includes("/live-Interactive-day-1-class-recording")) {
      navigate("/combo-live-Interactive-class-recordings")
    } else if (location.pathname.includes("/jose-silva-method")) {
      navigate("/about-jose-silva")
    } else if (location.pathname.includes("/testimonials")) {
      navigate("/success-stories")
    }
    // else if(location.pathname.includes("/combo-plan")){
    //   navigate("/store/course/begineer-combo-course")
    // }
  }, [location.pathname])

  useEffect(() => {

    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);






  // if(!currency){
  //   return <LoadingSpinner />
  // }

  return (
    <div>
      {
        location.pathname === "/" ? (
          <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
            <title>Silva Method Meditation for Mind Acceleration</title>
            <meta name="google-site-verification" content="c2u8NPNJuaKPuFBiqK_b_kGKGhJIOMx2yFGtTXRiClM" />
            <meta name="description"
              content="Master Silva Method dynamic meditation for mind empowerment and ultimate growth in each aspect of life including career, health, relationships, and more." />
            <meta name="keywords"
              content="Silva Method, Silva Mind Control Method & training, meditation and mind control training courses, Silva Method Meditation, Silva Mind Control Method, Original Silva Method, Silva masterclass programs, Meditation and mind control Training, Jose Silva courses, The Silva Mind Control Method by José Silva, Advanced dynamic meditation training, Silva Intuition System, Meditation tips and techniques, Silva Life System, Silva masterclass Programs, Silva Intuition System, The Silva Manifesting Course, dynamic meditation courses, Silva Method courses, Silva Master Class programs, Silva Mind control classes near me, Silva Method course online, Silva Membership" />
            <meta name="author" content="The Silva Method International" />
            <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <link rel="canonical" href="https://silvamethod.com/" />


            <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "EducationalOrganization",
            "name": "Silva Method International",
            "alternateName": "The Original Silva Method by Jose Silva",
            "url": "https://silvamethod.com/",
            "logo": "https://silvamethod.com/title-logo.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-800-545-6463",
              "contactType": "technical support",
              "contactOption": "TollFree",
              "areaServed": ["US","GB","CA","AF","VN","AE","SE","ES","ZA","SA","RS","SG","MF","RO","PT","PH","PK","NG","NZ","MA","JE","IT","IL","IE","ID","IN","HK","015","142","145","150","151","154","039","035","143","AU","AR","AT","BD","BR","BN","CL","CR","EG","DE","IS","HU"],
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/SilvaInternationalInc",
              "https://www.instagram.com/silvamethodofficial/",
              "https://twitter.com/SilvaHomeOffice",
              "https://www.youtube.com/SilvaMethodOfficial",
              "https://www.linkedin.com/company/thesilvamethods",
              "https://www.pinterest.com/the_silvamethod/",
              "https://www.tumblr.com/silvamethod1",
              "https://en.wikipedia.org/wiki/Silva_Method",
              "https://silvamethod.com/"
            ]
          }
        `}</script>

            <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org/",
                "@type": "WebSite",
                "name": "Silva Method International",
                "url": "https://silvamethod.com/",
                "potentialAction": {
                  "@type": "SearchAction",
                  "target": "https://silvamethod.com/?s={search_term_string}",
                  "query-input": "required name=search_term_string"
                }
              }
              `}

            </script>

            <script type="application/ld+json">
              {` 
               {
                 "@context": "https://schema.org/",
                 "@type": "Person",
                 "name": "Jose Silva",
                 "url": "https://silvamethod.com/store/jose-silva-method/",
                 "image": "https://silvamethod.com/store/wp-content/uploads/2023/05/Jose-silva-the-founder-of-silva-method.jpg",
                 "sameAs": [
                   "https://silvamethod.com/",
                   "https://www.facebook.com/SilvaInternationalInc",
                   "https://twitter.com/SilvaHomeOffice",
                   "https://www.instagram.com/silvamethodofficial/",
                   "https://www.youtube.com/SilvaMethodOfficial",
                   "https://www.linkedin.com/company/thesilvamethods",
                   "https://www.pinterest.com/the_silvamethod/",
                   "https://www.tumblr.com/silvamethod1",
                   "https://en.wikipedia.org/wiki/Silva_Method"
                 ],
                 "jobTitle": "Founder",
                 "worksFor": {
                   "@type": "Organization",
                   "name": "The Silva Method"
                 }  
               }
              `}
            </script>

            <script type="application/ld+json">
              {` 
               {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "The Original Silva Method Official Website",
                  "item": "https://silvamethod.com/"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "The Silva Method Facebook Page",
                  "item": "https://www.facebook.com/SilvaInternationalInc"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "The Silva Method Instagram Page",
                  "item": "https://www.instagram.com/silvamethodofficial/"  
                },{
                  "@type": "ListItem", 
                  "position": 4, 
                  "name": "The Silva Method Twitter Page",
                  "item": "https://twitter.com/SilvaHomeOffice"  
                },{
                  "@type": "ListItem", 
                  "position": 5, 
                  "name": "The Silva Method LinkedIn Page",
                  "item": "https://www.linkedin.com/company/thesilvamethods"  
                },{
                  "@type": "ListItem", 
                  "position": 6, 
                  "name": "The Silva Method LinkedIn Channel",
                  "item": "https://www.youtube.com/SilvaMethodOfficial"  
                },{
                  "@type": "ListItem", 
                  "position": 7, 
                  "name": "The Silva Method Tumblr Page",
                  "item": "https://www.tumblr.com/silvamethod1"  
                },{
                  "@type": "ListItem", 
                  "position": 8, 
                  "name": "The Silva Method Wikipedia Page",
                  "item": "https://en.wikipedia.org/wiki/Silva_Method"  
                },{
                  "@type": "ListItem", 
                  "position": 9, 
                  "name": "The Silva Method Pinterest Page",
                  "item": "https://www.pinterest.com/the_silvamethod/"  
                },{
                  "@type": "ListItem", 
                  "position": 10, 
                  "name": "The Silva Method reddit Page",
                  "item": "https://www.reddit.com/user/The-Silva-Method"  
                },{
                  "@type": "ListItem", 
                  "position": 11, 
                  "name": "The Silva Method Issuu Page",
                  "item": "https://issuu.com/thesilvamethod/"  
                }]
              }
              `}
            </script>

            <script type="application/ld+json">
              {`
              {
                "@context": "http://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What is Silva Method?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The Silva Method is a self-help program created by José Silva in the 1960s. It is designed to help individuals tap into their mind's potential and enhance various aspects of their lives. The method combines meditation, visualization techniques, and positive affirmations to promote personal growth, self-improvement, and mental empowerment. Here are some core points and detailed explanations about the Silva Method:
              1. Mind Control: The Silva Method emphasizes the power of the mind and teaches techniques to gain better control over one's thoughts and beliefs. Through meditation and visualization exercises, individuals can develop a stronger ability to focus their minds and direct their thoughts towards positive outcomes.
              2. Alpha State: The method introduces the concept of the alpha state, a relaxed and meditative state of mind that is associated with increased creativity, enhanced learning, and improved problem-solving abilities. By learning to access and maintain this state, individuals can tap into their subconscious mind and utilize its vast potential.
              3. Visualization: Visualization is a key aspect of the Silva Method. Participants are taught to create vivid mental images of their desired outcomes, whether it's achieving a goal, improving health, or enhancing relationships. The belief is that by repeatedly visualizing these goals, the subconscious mind will work towards manifesting them in reality.
              4. Positive Affirmations: The use of positive affirmations is another important element of the Silva Method. By repeating positive statements about oneself and one's goals, individuals can reprogram their subconscious mind and replace negative self-talk with empowering beliefs. This practice helps build self-confidence and foster a more optimistic mindset.
              5. Problem Solving and Intuition: The Silva Method teaches techniques to enhance problem-solving abilities and tap into intuition. Through relaxation exercises and mental training, individuals can access their intuitive insights and make better decisions in various aspects of life.
              6. Health and Healing: The Silva Method incorporates techniques to promote physical and emotional well-being. Participants are encouraged to use visualization and relaxation exercises to support healing processes, reduce stress, and improve overall health.
              7. Goal Setting: Setting clear and achievable goals is a fundamental part of the Silva Method. By defining specific objectives and visualizing their attainment, individuals can increase motivation, focus, and determination to reach their desired outcomes.
              8. Continuous Practice: The Silva Method emphasizes the importance of regular practice to achieve lasting results. Daily meditation and visualization exercises are recommended to reinforce positive changes and maintain a strong connection with the subconscious mind.
              It's important to note that while many individuals have reported positive experiences and outcomes with the Silva Method, its effectiveness may vary from person to person. It is always advisable to approach such self-help programs with an open mind and determine if they align with your personal beliefs and goals.
              To know more visit the official Silva Method international website: https://silvamethod.com/what-is-silva-method"
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What are the Basic fundamentals of The Silva Method?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The basic fundamentals of the Silva Method are:
              1. Relaxation: Achieving deep relaxation to access the subconscious mind.
              2. Alpha State: Entering a meditative state for enhanced creativity and receptivity.
              3. Visualization: Creating vivid mental images of desired outcomes.
              4. Positive Affirmations: Using affirmations to reprogram the subconscious mind.
              5. Intuition Development: Cultivating and trusting intuitive insights.
              6. Goal Setting: Setting clear goals and incorporating them into daily practice.
              7. Continuous Practice: Regular and consistent practice for long-term results."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Top 10 Benefits of the Silva Method",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Jose Silva, the visionary behind The Silva Method, is renowned as a trailblazer in the field of mind empowerment research. Devoting his life to unlocking the untapped potential of the human mind, Silva conducted extensive studies for 24 years. In 1966, he unveiled his remarkable discoveries and dedicated the remaining years of his life to refining and sharing The Silva Method. Today, Silva's enduring legacy lives on through Silva International, an organization wholeheartedly committed to carrying forward its mission.
              1. Sound and Restful Sleep
              2. Boost Energy Levels
              3. Reduce Tension and Headaches
              4. Boost Creativity and Inspiration
              5. Build Healthy and Fulfilling Relationships
              6. Enhance Intelligence
              7. Reduce Stress, Anxiety, and Depression
              8. Accelerate Learning and Boost Memory
              9. Boost Self-Esteem and Self-Confidence
              10. Develops Positive Mindset and Overall Wellbeing"
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How to Enter the Alpha State?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Step 1 - Entry
              Step 2 - Deepening
              Step 3 - Programming
              Step 4 - Exit"
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "who created the Silva Method?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Jose Silva - The Pioneer Mind Behind the Revolutionary Discovery of Silva Method.
              Jose Silva, the visionary behind The Silva Method, is renowned as a trailblazer in the field of mind empowerment research. Devoting his life to unlocking the untapped potential of the human mind, Silva conducted extensive studies for 24 years. In 1966, he unveiled his remarkable discoveries and dedicated the remaining years of his life to refining and sharing The Silva Method. Today, Silva's enduring legacy lives on through Silva International, an organization wholeheartedly committed to carrying forward its mission.
              For more than 50 years, the Silva Method has positively influenced countless lives across the globe, touching the hearts of celebrities, CEOs, and everyday individuals seeking personal growth. Thanks to Jose Silva's groundbreaking work, our understanding of the mind has been revolutionized through practical techniques for mental reprogramming. Our mission is to continue spreading these practices, centered around meditation and relaxation, empowering you to take control and harness the full potential of your mind. By embarking on this journey, you can enhance your well-being, sharpen your focus, and unlock the extraordinary capabilities within you."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you practice the Silva Method?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To practice the Silva Method, follow these general steps:
              1. Relaxation: Find a quiet and comfortable space where you won't be disturbed. Close your eyes, take deep breaths, and release any tension in your body. Use progressive muscle relaxation or other relaxation techniques to achieve a deep state of relaxation.
              2. Alpha State Induction: Once relaxed, begin to enter the alpha state. Mentally count down from 100 to 1, with each number representing a deeper level of relaxation. Imagine yourself descending a staircase or floating down into relaxation as you count.
              3. Visualization: In the alpha state, engage your imagination and visualize your desired outcome. Create detailed mental images of achieving your goal or experiencing positive changes. Visualize with all your senses, incorporating sounds, smells, and emotions associated with your desired outcome.
              4. Affirmations: While in the alpha state, repeat positive affirmations related to your goal. Use present tense statements that affirm your success and reinforce your belief in achieving it. Repeat these affirmations with conviction and confidence.
              5. Intuition and Problem Solving: Use the alpha state to access your intuition. Quiet your mind and ask for insights or solutions to any challenges you may be facing. Trust the intuitive responses that arise and take note of any guidance or ideas you receive.
              6. Goal Reinforcement: Regularly revisit your goals during your Silva Method practice sessions. Visualize them, affirm them, and strengthen your belief in their achievement. Keep your goals clear, specific, and achievable.
              7. Practice Regularly: Consistency is key. Practice the Silva Method daily or as frequently as possible. Set aside dedicated time for your practice and make it a habit. The more you practice, the more you will strengthen your connection to your subconscious mind and manifest positive changes in your life.
              Remember that the Silva Method is a personal practice, and you can adapt and modify it to suit your needs and preferences. It's important to approach the practice with an open mind, remain patient with yourself, and trust the process of tapping into your inner potential."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What is the Silva three finger technique?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The Silva Three-Finger Technique is a simple and effective method used in the Silva Method to induce relaxation and enter the alpha state. It involves using three fingers to help facilitate the process. Here's how to practice the Silva Three-Finger Technique:
              1. Find a comfortable position: Sit or lie down in a relaxed position, ensuring you won't be disturbed during the practice.
              2. Join your fingers: Place the tips of your thumb, middle finger, and ring finger together, forming a gentle, light touch.
              3. Focus on your fingers: Bring your attention to the point where your fingers touch. This point serves as an anchor for your concentration throughout the technique.
              4. Enter relaxation: Close your eyes and take a few deep breaths to relax your body. As you exhale, release any tension or stress.
              5. Count down: Mentally count down from 3 to 1, using each number as a cue to deepen your relaxation. With each count, allow yourself to become progressively more relaxed and at ease.
              6. Enter the alpha state: After reaching the count of 1, imagine stepping into a state of deep relaxation and tranquility. Imagine yourself descending into a calm, peaceful mental space.
              7. Repeat affirmations or visualize: While maintaining the touch of your three fingers, you can use this relaxed state to repeat positive affirmations related to your goals or engage in vivid visualizations of your desired outcomes. Immerse yourself in the experience and allow it to feel real and natural.
              8. Practice for a desired duration: Continue the practice for as long as feels comfortable and beneficial to you. It's generally recommended to practice for at least a few minutes to allow the relaxation and visualization to have an impact on your subconscious mind.
              The Silva Three-Finger Technique serves as a focal point to anchor your attention and induce a relaxed state conducive to entering the alpha brainwave state. It can be a helpful tool to enhance your overall Silva Method practice, promoting relaxation, visualization, and positive affirmations."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Silva Method techniques",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The technique aims to reach and sustain a state of mental functioning, called alpha state, where brainwave frequency is seven to fourteen Hz. Daydreaming and the transition to sleeping are alpha states. Silva claimed to have developed a program that trained people to enter certain brain states of enhanced awareness."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "what happened to the Silva Mind Control Method?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "He began experimenting with psychic abilities and brain wave activity in the 1940s, eventually developing The Silva Mind Control Method and numerous instructional courses and programs. The Silva Method continues to be taught in seminars and events by accredited instructors, and in home-study courses. He died in 1999"
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Is the Silva method successful?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "For forty years, the Silva Method™ has earned its reputation for being the most successful method for personal empowerment by helping millions of people achieve greater fulfillment in their lives."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What is the original Silva Mind Control Method system?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "The Silva Method is a self-help and meditation program developed by José Silva. It claims to increase an individual's abilities through relaxation, development of higher brain functions, and psychic abilities such as clairvoyance. It has been variously classified as a self-religion, a new religious movement and a cult."
                    }
                  }
                ]
              }
               `}
            </script>


          </Helmet>
        ) : location.pathname === "/silva-method-courses" ? (
          <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
            <title>Silva Method Courses | All 100% Original Courses</title>
            <meta name="description" content="Get Silva Method Courses: All Original Mind Control Courses for your epic Transformation Journey. Enroll Now and Get Lifetime Access. " />
            <meta name="keywords" content="Silva Method Membership, Subscribe Now To Silva Membership, Silva Method Review, Silva Method courses, complete Silva Method course, Silva Method membership cost, The Original Silva Method Membership Reviews and Pricing 2023, Join Silva Method Membership Now, The best of Silva Method, Jose silva Courses, learn Silva Method, Silva Meditation Membership, Silva Method Membership Review, The Silva Mind Control Method Course, Mind control method - Silva Membership, josé silva complete meditation program, Silva Method exercises, Silva Method online course, The original Silva Method Membership Program, Silva Mind control classes near me, all in one Silva Method courses, world's best education & personal growth program." />
            <meta name="author" content="The Silva Method International" />
            <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <link rel="canonical" href="https://silvamethod.com/silva-method-courses" />
          </Helmet>
        ) :
          location.pathname === "/store/course/silva-intuition-system-digital-audio-program" ? (
            <Helmet>
              <meta charset="utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
              <title>Silva Intuition System | Better Intuition Better Decision Making</title>
              <meta name="description" content="Learn how to develop better natural instincts with Silva Intuition System that will guide you in making better decisions in all realms of life" />
              <meta name="keywords" content="Silva Intuition System, Silva Intuition Training, Silva Method Intuition System, silva intuition system course, jose silva intuition, Learn better intuition with The Original Silva Method, Silva Mind Control Method, Silva Method Meditation, Silva Mind control classes near me, silva method course online." />
              <meta name="author" content="The Silva Method International" />
              <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
              <meta name="robots" content="index, follow" />
              <meta name="language" content="English" />
              <link rel="canonical" href="https://silvamethod.com/store/course/silva-intuition-system-digital-audio-program" />
              <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "EducationalOrganization",
            "name": "Silva Method International",
            "alternateName": "The Original Silva Method by Jose Silva",
            "url": "https://silvamethod.com/",
            "logo": "https://silvamethod.com/assets/images/silvamethod-logo.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-800-545-6463",
              "contactType": "technical support",
              "contactOption": "TollFree",
              "areaServed": ["US","GB","CA","AF","VN","AE","SE","ES","ZA","SA","RS","SG","MF","RO","PT","PH","PK","NG","NZ","MA","JE","IT","IL","IE","ID","IN","HK","015","142","145","150","151","154","039","035","143","AU","AR","AT","BD","BR","BN","CL","CR","EG","DE","IS","HU"],
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/SilvaInternationalInc",
              "https://www.instagram.com/silvamethodofficial/",
              "https://twitter.com/SilvaHomeOffice",
              "https://www.youtube.com/SilvaMethodOfficial",
              "https://www.linkedin.com/company/thesilvamethods",
              "https://www.pinterest.com/the_silvamethod/",
              "https://www.tumblr.com/silvamethod1",
              "https://en.wikipedia.org/wiki/Silva_Method",
              "https://silvamethod.com/"
            ]
          }
        `}</script>
              <script type="application/ld+json">
                {`
               {
                "@context": "http://schema.org/",
                "@type": "Product",
                "name": "Silva Intuition System Course",
                "image": "https://silvamethod.com/store/course/silva-intuition-system-digital-audio-program",
                "description": "Silva Intuition System - Learn to trust your gut feelings. Enroll Now The Original Silva Intuition System Digital Audio Program by Jose Silva",
                "brand": {
                  "@type": "Brand",
                  "name": "The Silva International Inc."
                },
                "offers": {
                  "@type": "Offer",
                  "priceCurrency": "USD",
                  "price": "99",
                  "url": "https://silvamethod.com/store/course/silva-intuition-system-digital-audio-program",
                  "availability": "https://schema.org/WebsitePurchaseOnly",
                  "itemCondition": "https://schema.org/NewCondition",
                  "priceValidUntil": "2024-01-01"
                },
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue" : "5",
                  "ratingCount" : "429853",
                  "reviewCount" : "270661"
                }
              }
              `}

              </script>
            </Helmet>
          ) :
            location.pathname === "/store/course/silva-life-system-digital-audio" ? (
              <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                <title>Silva Life System | Improve your Ability to Focus Better </title>
                <meta name="description" content="Silva Life System 2.0 will transform you into a better-focused individual. Get the silva life system digital audio to begin your transformation journey." />
                <meta name="keywords" content="Silva Life System,  Silva Method Meditation, silva life system download, silva life method, silva life system 2.0, silva life system online course, silva life system digital audio,  Silva Mind control classes near me, Silva Method." />
                <meta name="author" content="The Silva Method International" />
                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="English" />
                <link rel="canonical" href="https://silvamethod.com/store/course/silva-life-system-digital-audio" />
                <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org/",
                  "@type": "Product",
                  "name": "Original Silva Life System Digital Course",
                  "image": "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(1).webp",
                  "description": "Make your life more manageable with Silva Life System. Kick off negative thought patterns with positive and mindful thoughts. Liberate yourself from headaches, migraines, and ingrained habits. Conquer complexity effortlessly and venture into the unexplored territories of your mind. Experience the transformative power of the Silva Life System - a 100% Authentic and Original Mind Control Course. Discover the New You!
                Enroll Today! Get Lifetime Access. (Upto 50% Off) ",
                  "brand": {
                    "@type": "Brand",
                    "name": "The Silva Method International Inc."
                  },
                  "offers": {
                    "@type": "AggregateOffer",
                    "priceCurrency": "USD",
                    "lowPrice": "67.90",
                    "highPrice": "150",
                    "url": "https://www.silvamethod.com/store/course/silva-life-system-digital-audio",
                    "offerCount": "3"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue" : "4.8",
                    "ratingCount" : "14721",
                    "reviewCount" : "43158",
                    "worstRating" : "3.5",
                    "bestRating" : "5"
                  }
                }
                `}</script>
              </Helmet>
            ) :
              location.pathname === "/store/course/silva-method-manifesting-program" ? (
                <Helmet>
                  <meta charset="utf-8" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                  <title>Silva Method Manifesting | Manifest your Dreams into Reality</title>
                  <meta name="description" content="Learn how to manifest with the Silva Method manifesting system bringing you closer to your goals and desires. Manifest Your Dream with the Silva Method." />
                  <meta name="keywords" content="Silva Manifesting, The Silva Manifesting Course, jose silva manifesting, Silva Method manifestation meditation, Manifestation Meditation, Manifest Your Dream with Silva Method, Silva Method to manifest, Silva Method manifestation, Silva Method Manifesting System, learn how to manifest with the Silva Method, The original Silva Manifesting Course." />
                  <meta name="author" content="The Silva Method International" />
                  <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                  <meta name="robots" content="index, follow" />
                  <meta name="language" content="English" />
                  <link rel="canonical" href="https://silvamethod.com/store/course/silva-method-manifesting-program" />
                  <script type="application/ld+json">{`
                  {
                    "@context": "http://schema.org/",
                    "@type": "Product",
                    "name": "Original Silva Manifesting Digital Course",
                    "image": "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(5).webp",
                    "description": "The Silva Method, a renowned system for personal development and mind empowerment, offers a unique perspective on manifestation. With its emphasis on harnessing the power of the mind to manifest desired outcomes, the Silva Method delves into the depths of consciousness to explore the true nature of reality.
                  Enroll Today! Get Lifetime Access. (Upto 45% Off)",
                    "brand": {
                      "@type": "Brand",
                      "name": "The Silva Method International Inc."
                    },
                    "offers": {
                      "@type": "AggregateOffer",
                      "priceCurrency": "USD",
                      "lowPrice": "67.90",
                      "highPrice": "150",
                      "url": "https://www.silvamethod.com/store/course/silva-method-manifesting-program",
                      "offerCount": "2"
                    },
                    "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue" : "4.5",
                      "ratingCount" : "11208",
                      "reviewCount" : "19502",
                      "worstRating" : "3.1",
                      "bestRating" : "5"
                    }
                  }
                  `}</script>
                </Helmet>
              ) :
                location.pathname === "/store/course/silva-mind-body-healing-program" ? (
                  <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                    <title>Silva Mind Body Healing Course | A Natural Healing Mechanism</title>
                    <meta name="description" content="Boost your immunity with the DBE model of the Silva Mind Body Healing Program. Learn mind-body healing techniques to experience the healing miracles" />
                    <meta name="keywords" content="Silva Mind Body Healing Course, Silva Mind Body Technique, Silva Healing Mind Exercise, Silva Mind Body Healing Program,  mind body healing techniques, mind-body healing books, Silva Mind Body Healing Program, Silva Method exercises, Healing Miracles with the Silva Method, Silva Method meditation, Advanced Silva Mind Body Healing course, Silva Mind Control, Silva Method of Mind Training and Stress Control, The Silva Method, Silva Method Courses." />
                    <meta name="author" content="The Silva Method International" />
                    <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                    <meta name="robots" content="index, follow" />
                    <meta name="language" content="English" />
                    <script type="application/ld+json">{`
                    {
                      "@context": "http://schema.org/",
                      "@type": "Product",
                      "name": "Silva Mind Body Healing System (Original Silva Method Digital Course)",
                      "image": "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(6).webp",
                      "description": "Empower your Mind, Heal your Body with Silva’s Natural Healing Mechanism. At the core of the Silva Method, our mission is to transform minds from a trigger of disease to a catalyst for holistic well-being, through Mind-Body Healing Mechanism.
                    Enroll Today! Get Lifetime Access. (Upto 50% Off)",
                      "brand": {
                        "@type": "Brand",
                        "name": "The Silva Method International Inc."
                      },
                      "offers": {
                        "@type": "AggregateOffer",
                        "priceCurrency": "USD",
                        "lowPrice": "67.90",
                        "highPrice": "150",
                        "url": "https://www.silvamethod.com/store/course/silva-mind-body-healing-program",
                        "offerCount": "2"
                      },
                      "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue" : "4.6",
                        "ratingCount" : "5204",
                        "reviewCount" : "7133"
                      }
                    }                    
                    `}</script>
                  </Helmet>
                ) :
                  location.pathname === "/store/course/silva-alpha-sound" ? (
                    <Helmet>
                      <meta charset="utf-8" />
                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                      <title>Silva Alpha Sound | Alpha Meditation Practice & Visualization</title>
                      <meta name="description" content="Enter into an advanced and deeper state of relaxation with Silva Alpha meditation. Explore the Alpha levels of your mind through Advanced Alpha Level Meditation" />
                      <meta name="keywords" content="Silva alpha meditation, Silva Centering Exercise With Alpha Sound, Alpha sound for meditation, Jose Silva Alpha Reinforcement Exercise, Advanced Alpha Level Meditation, Silva Centering Exercise, Jose Silva Guided Meditation, The Silva Alpha Waves Meditation, Alpha Meditation Practice & Visualization,  Silva Method alpha sound download, Silva Method Alpha Waves Meditation, Silva Ultramind Guided Meditation, Silva Method meditation audio, Alpha Sound for Relaxation Meditation, Alpha Sound Silva Method, Silva Method Courses" />
                      <meta name="author" content="The Silva Method International" />
                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                      <meta name="robots" content="index, follow" />
                      <meta name="language" content="English" />
                      <script type="application/ld+json">
                        {`
                        {
                          "@context": "http://schema.org/",
                        "@type": "Product",
                        "name": "Original Silva Alpha Sound Digital",
                        "image": "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(3).webp",
                        "description": "Enter into an advanced and deeper state of relaxation with Silva Alpha meditation. Explore the Alpha levels of your mind through Advanced Alpha Level Meditation.
                        Enroll Today! Get Lifetime Access. (Upto 40% Off)",
                        "brand": {
                          "@type": "Brand",
                        "name": "The Silva Method International Inc."
	},
                        "offers": {
                          "@type": "AggregateOffer",
                        "priceCurrency": "USD",
                        "lowPrice": "12",
                        "highPrice": "20",
                        "url": "https://www.silvamethod.com/store/course/silva-alpha-sound",
                        "offerCount": "1"
	},
                        "aggregateRating": {
                          "@type": "AggregateRating",
                        "ratingValue" : "4.7",
                        "ratingCount" : "2119",
                        "reviewCount" : "5148"
	}
}
                        `}
                      </script>
                    </Helmet>
                  ) :
                    location.pathname === "/store/course/silva-theta-sound" ? (
                      <Helmet>
                        <meta charset="utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                        <title>The Theta Sound by Jose Silva | Enter the Relaxation Mode</title>
                        <meta name="description" content="Silva centering exercise with Theta Sound helps you to quickly transition into a deep meditative state. Explore the various Silva theta sound waves benefits" />
                        <meta name="keywords" content="Silva Centering Exercise with Theta Sound, Jose silva theta frequency, The Theta sound by Jose Silva, Spiritual Healing Meditation with the Silva THETA Sound, Silva theta sound waves benefits, silva method theta meditation, Silva Centering Exercise With Theta Sound, Jose Silva Ultramind ESP System, Silva Method Courses" />
                        <meta name="author" content="The Silva Method International" />
                        <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                        <meta name="robots" content="index, follow" />
                        <meta name="language" content="English" />
                        <link rel="canonical" href="https://silvamethod.com/store/course/silva-theta-sound" />
                        <script type="application/ld+json">
                          {`
                        {
                          "@context": "http://schema.org/",
                          "@type": "Product",
                          "name": "Original Silva Theta Sound Digital",
                          "image": "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(2).webp",
                          "description": "Silva centering exercise with Theta Sound helps you to quickly transition into a deep meditative state. Explore the various Silva theta sound waves benefits.
                        Enroll Today! Get Lifetime Access. (Upto 40% Off)",
                          "brand": {
                            "@type": "Brand",
                            "name": "The Silva Method International Inc."
                          },
                          "offers": {
                            "@type": "AggregateOffer",
                            "priceCurrency": "USD",
                            "lowPrice": "12",
                            "highPrice": "20",
                            "url": "https://www.silvamethod.com/store/course/silva-alpha-sound",
                            "offerCount": "1"
                          },
                          "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue" : "4.8",
                            "ratingCount" : "5089",
                            "reviewCount" : "6241"
                          }
                        }
                        `}
                        </script>
                      </Helmet>
                    ) :
                      location.pathname === "/store/course/combo-plan" ? (
                        <Helmet>
                          <meta charset="utf-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1" />
                          <title>Silva Method Combo Course- All-in-One Plan</title>
                          <meta name="description" content="Enroll in the Silva Method Combo Plan and get Lifetime Access to all Silva Method 100% Original courses. Join weekly Live Interactive sessions, 1M+ thriving community, and many more." />
                          <meta name="keyword" content="Silva Method Complete Course, Silva Method Combo Course, Silva Mind Control Course, Silva Method Digital Program, Silva Method Digital Audio Courses" />
                          <meta name="author" content="The Silva Method Official" />
                          <meta name="copyright" content="Silva Method International Inc." />
                          <meta name="robots" content="index, follow" />

                          <meta property="og:type" content="product" />
                          <meta property="og:title" content="Silva Method Complete Course (Combo Pack)" />
                          <meta property="og:url" content="https://silvamethod.com/store/course/combo-plan" />
                          <meta property="og:image" content="https://silvamethod.com/manage/assets/uploads/Silva%20Combined%20System.webp" />
                          <meta property="og:description" content="Silva Method Combo Course - Master Your Mind with the Comprehensive Silva Mind Control Courses (Combo Pack) Enroll Today - Get Lifetime Access" />
                          <meta property="og:keyword" content="Silva Method Complete Course, Silva Method Combo Course, Silva Mind Control Course, Silva Method Digital Program, Silva Method Digital Audio Courses" />
                          <meta property="product:plural_title" content="Silva Mind Control Courses (Combo Pack)" />
                          <meta property="product:price.amount" content="400" />
                          <meta property="product:price.currency" content="USD" />
                          <link rel="canonical" href="https://silvamethod.com/store/course/combo-plan" />
                          <script type="application/ld+json">
                            {`
                            {
                              "@context": "http://schema.org/",
                              "@type": "Product",
                              "name": "Silva Method Complete Course (Original Silva Mind Control Courses)",
                              "image": "https://silvamethod.com/manage/assets/uploads/Silva%20Combined%20System.webp",
                              "description": "Explore the Original & 100% Authentic Mind Control Courses. Get Full Access to Silva Life System, Silva Intuition System, Silva Method Manifesting, Silva Method Mind Body Healing along with the Original & Authentic ALPHA & THETA Brainwaves for your to go faster into the Levels. This will include all the Silva Method Homestudy Courses. America’s 1st Dynamic Meditation and Personal Growth Program Since 1966: Discover the New You!
                            Enroll Today! Get Lifetime Access. (Upto 50% Off)",
                              "brand": {
                                "@type": "Brand",
                                "name": "The Silva Method International Inc."
                              },
                              "offers": {
                                "@type": "AggregateOffer",
                                "priceCurrency": "USD",
                                "lowPrice": "195.30",
                                "highPrice": "559",
                                "url": "https://www.silvamethod.com/store/course/combo-plan",
                                "offerCount": "4"
                              },
                              "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue" : "4.9",
                                "ratingCount" : "29780",
                                "reviewCount" : "39114",
                                "worstRating" : "3.6",
                                "bestRating" : "5"
                              }
                            }
                            `}

                          </script>
                        </Helmet>

                      ) : location.pathname === "/store/course/silva-love-program" ? (
                        <Helmet>
                          <link rel="canonical" href="https://silvamethod.com/store/course/silva-love-program" />
                          <script type="application/ld+json">
                            {`
                            {
                              "@context": "http://schema.org/",
                              "@type": "Product",
                              "name": "Original Silva Love Program (Digital Audio)",
                              "image": "https://silvamethod.com/manage/assets/uploads/Image20230824185622.png",
                              "description": "Love is in the air but you are not? The Silva Love program guides you on a heartfelt journey toward love, ensuring everyone experiences this profound emotion. Discover the art of attracting, visualizing, and bringing forth the Love you have longed for. Uncover the beauty and goodness in others as you explore this beautiful program- A 100% original and Authentic Silva Mind Control Course.
                            Enroll Today! Get Lifetime Access. (Upto 58% Off)",
                              "brand": {
                                "@type": "Brand",
                                "name": "The Silva Method International Inc."
                              },
                              "offers": {
                                "@type": "AggregateOffer",
                                "priceCurrency": "USD",
                                "lowPrice": "32.90",
                                "highPrice": "79",
                                "url": "https://www.silvamethod.com/store/course/silva-love-program",
                                "offerCount": "2"
                              },
                              "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue" : "4.7",
                                "ratingCount" : "2174",
                                "reviewCount" : "3599"
                              }
                            }
                            `}

                          </script>
                        </Helmet>
                      )
                        :
                        location.pathname === "/become-silva-affiliate" ? (
                          <Helmet>
                            <meta charset="utf-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1" />
                            <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                            <title>The Silva Method Affiliate Program | Become a Silva affiliate</title>
                            <meta name="description" content="The Original Silva Method Affiliate Program is the Best Meditation Affiliate Programs In 2023 (Top Offers) Become a Silva affiliate and Earn with Silva Method." />
                            <meta name="keywords" content="Become a silva affiliate, Silva Method Affiliate Program, Earn with Silva Method, Jose Silva Method affiliate program, how to become a Silva Method affiliate, Become a silva affiliate partner, meditation affiliate program, Silva Method courses affiliate links, What Are The Benefits Of Joining The Silva Method Affiliate Program, Best Affiliate Marketing Sites, mindfulness meditation affiliate program, Best Meditation Affiliate Programs In 2023 (Top Offers), best affiliate programs for health and fitness, highest paying health affiliate programs, Silva free affiliate programs, Best Affiliate Marketing Websites, affiliate marketing sites usa, best affiliate networks to earn in the USA, health related affiliate programs" />
                            <meta name="author" content="The Silva Method International" />
                            <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                            <meta name="robots" content="index, follow" />
                            <meta name="language" content="English" />
                            <link rel="canonical" href="https://silvamethod.com/become-silva-affiliate" />


                          </Helmet>
                        )

                          : location.pathname === "/what-is-silva-method" ? (
                            <Helmet>
                              <meta charset="utf-8" />
                              <meta name="viewport" content="width=device-width, initial-scale=1" />
                              <meta property="og:image" content="https://silvamethod.com/title-logo.png" />

                              <title>What is Silva Method - A Self-Growth Program</title>
                              <meta name="description" content="Silva Method is a self-growth platform helping individuals to take charge of their minds using dynamic meditation and mind control techniques. It’s a holistic approach to well-being, recognizing the connection between mind, body, and soul. " />
                              <meta name="keyword" content="What is Silva Method - A Self-Growth Program" />
                              <meta name="author" content="The Silva Method International " />
                              <meta name="copyright" content="Original Silva Method" />
                              <meta name="robots" content="index, follow" />

                              <meta property="og:type" content="website" />
                              <meta property="og:title" content="Silva Method - Mind Control Solution for All Mental Health Issues" />
                              <meta property="og:url" content="https://silvamethod.com/what-is-silva-method" />
                              <meta property="og:description" content="Harness the power of mind control with Silva Method, an ultimate destination for all mental health issues. Explore Silva Method to Transform your life." />
                              <meta property="og:keyword" content="What is Silva Method, Silva Method, Original Silva Method, The Silva Method, Meditation and Mind Control, Benefits of the Silva Method, Silva Meditation and Relaxation Techniques, Silva Mind Control Method, Silva dynamic meditation courses, jose Silva Method" />
                              <link rel="canonical" href="https://silvamethod.com/what-is-silva-method" />
                              <script type="application/ld+json">
                                {`
                              {
                                "@context": "http://schema.org/",
                              "@type": "FAQPage",
                              "mainEntity": [
                              {
                                "@type": "Question",
                              "name": "What is Silva Method?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "The Silva Method is a self-help program created by José Silva in the 1960s. It is designed to help individuals tap into their mind's potential and enhance various aspects of their lives. The method combines meditation, visualization techniques, and positive affirmations to promote personal growth, self-improvement, and mental empowerment. Here are some core points and detailed explanations about the Silva Method:
                              1. Mind Control: The Silva Method emphasizes the power of the mind and teaches techniques to gain better control over one's thoughts and beliefs. Through meditation and visualization exercises, individuals can develop a stronger ability to focus their minds and direct their thoughts towards positive outcomes.
                              2. Alpha State: The method introduces the concept of the alpha state, a relaxed and meditative state of mind that is associated with increased creativity, enhanced learning, and improved problem-solving abilities. By learning to access and maintain this state, individuals can tap into their subconscious mind and utilize its vast potential.
                              3. Visualization: Visualization is a key aspect of the Silva Method. Participants are taught to create vivid mental images of their desired outcomes, whether it's achieving a goal, improving health, or enhancing relationships. The belief is that by repeatedly visualizing these goals, the subconscious mind will work towards manifesting them in reality.
                              4. Positive Affirmations: The use of positive affirmations is another important element of the Silva Method. By repeating positive statements about oneself and one's goals, individuals can reprogram their subconscious mind and replace negative self-talk with empowering beliefs. This practice helps build self-confidence and foster a more optimistic mindset.
                              5. Problem Solving and Intuition: The Silva Method teaches techniques to enhance problem-solving abilities and tap into intuition. Through relaxation exercises and mental training, individuals can access their intuitive insights and make better decisions in various aspects of life.
                              6. Health and Healing: The Silva Method incorporates techniques to promote physical and emotional well-being. Participants are encouraged to use visualization and relaxation exercises to support healing processes, reduce stress, and improve overall health.
                              7. Goal Setting: Setting clear and achievable goals is a fundamental part of the Silva Method. By defining specific objectives and visualizing their attainment, individuals can increase motivation, focus, and determination to reach their desired outcomes.
                              8. Continuous Practice: The Silva Method emphasizes the importance of regular practice to achieve lasting results. Daily meditation and visualization exercises are recommended to reinforce positive changes and maintain a strong connection with the subconscious mind.
                              It's important to note that while many individuals have reported positive experiences and outcomes with the Silva Method, its effectiveness may vary from person to person. It is always advisable to approach such self-help programs with an open mind and determine if they align with your personal beliefs and goals.
                              To know more visit the official Silva Method international website: https://silvamethod.com/what-is-silva-method"
			}
		},
                              {
                                "@type": "Question",
                              "name": "What are the Basic fundamentals of The Silva Method?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "The basic fundamentals of the Silva Method are:
                              1. Relaxation: Achieving deep relaxation to access the subconscious mind.
                              2. Alpha State: Entering a meditative state for enhanced creativity and receptivity.
                              3. Visualization: Creating vivid mental images of desired outcomes.
                              4. Positive Affirmations: Using affirmations to reprogram the subconscious mind.
                              5. Intuition Development: Cultivating and trusting intuitive insights.
                              6. Goal Setting: Setting clear goals and incorporating them into daily practice.
                              7. Continuous Practice: Regular and consistent practice for long-term results."
			}
		},
                              {
                                "@type": "Question",
                              "name": "Top 10 Benefits of the Silva Method",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "Jose Silva, the visionary behind The Silva Method, is renowned as a trailblazer in the field of mind empowerment research. Devoting his life to unlocking the untapped potential of the human mind, Silva conducted extensive studies for 24 years. In 1966, he unveiled his remarkable discoveries and dedicated the remaining years of his life to refining and sharing The Silva Method. Today, Silva's enduring legacy lives on through Silva International, an organization wholeheartedly committed to carrying forward its mission.
                              1. Sound and Restful Sleep
                              2. Boost Energy Levels
                              3. Reduce Tension and Headaches
                              4. Boost Creativity and Inspiration
                              5. Build Healthy and Fulfilling Relationships
                              6. Enhance Intelligence
                              7. Reduce Stress, Anxiety, and Depression
                              8. Accelerate Learning and Boost Memory
                              9. Boost Self-Esteem and Self-Confidence
                              10. Develops Positive Mindset and Overall Wellbeing"
			}
		},
                              {
                                "@type": "Question",
                              "name": "How to Enter the Alpha State?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "Step 1 - Entry
                              Step 2 - Deepening
                              Step 3 - Programming
                              Step 4 - Exit"
			}
		},
                              {
                                "@type": "Question",
                              "name": "who created the Silva Method?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "Jose Silva - The Pioneer Mind Behind the Revolutionary Discovery of Silva Method.
                              Jose Silva, the visionary behind The Silva Method, is renowned as a trailblazer in the field of mind empowerment research. Devoting his life to unlocking the untapped potential of the human mind, Silva conducted extensive studies for 24 years. In 1966, he unveiled his remarkable discoveries and dedicated the remaining years of his life to refining and sharing The Silva Method. Today, Silva's enduring legacy lives on through Silva International, an organization wholeheartedly committed to carrying forward its mission.
                              For more than 50 years, the Silva Method has positively influenced countless lives across the globe, touching the hearts of celebrities, CEOs, and everyday individuals seeking personal growth. Thanks to Jose Silva's groundbreaking work, our understanding of the mind has been revolutionized through practical techniques for mental reprogramming. Our mission is to continue spreading these practices, centered around meditation and relaxation, empowering you to take control and harness the full potential of your mind. By embarking on this journey, you can enhance your well-being, sharpen your focus, and unlock the extraordinary capabilities within you."
			}
		},
                              {
                                "@type": "Question",
                              "name": "How do you practice the Silva Method?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "To practice the Silva Method, follow these general steps:
                              1. Relaxation: Find a quiet and comfortable space where you won't be disturbed. Close your eyes, take deep breaths, and release any tension in your body. Use progressive muscle relaxation or other relaxation techniques to achieve a deep state of relaxation.
                              2. Alpha State Induction: Once relaxed, begin to enter the alpha state. Mentally count down from 100 to 1, with each number representing a deeper level of relaxation. Imagine yourself descending a staircase or floating down into relaxation as you count.
                              3. Visualization: In the alpha state, engage your imagination and visualize your desired outcome. Create detailed mental images of achieving your goal or experiencing positive changes. Visualize with all your senses, incorporating sounds, smells, and emotions associated with your desired outcome.
                              4. Affirmations: While in the alpha state, repeat positive affirmations related to your goal. Use present tense statements that affirm your success and reinforce your belief in achieving it. Repeat these affirmations with conviction and confidence.
                              5. Intuition and Problem Solving: Use the alpha state to access your intuition. Quiet your mind and ask for insights or solutions to any challenges you may be facing. Trust the intuitive responses that arise and take note of any guidance or ideas you receive.
                              6. Goal Reinforcement: Regularly revisit your goals during your Silva Method practice sessions. Visualize them, affirm them, and strengthen your belief in their achievement. Keep your goals clear, specific, and achievable.
                              7. Practice Regularly: Consistency is key. Practice the Silva Method daily or as frequently as possible. Set aside dedicated time for your practice and make it a habit. The more you practice, the more you will strengthen your connection to your subconscious mind and manifest positive changes in your life.
                              Remember that the Silva Method is a personal practice, and you can adapt and modify it to suit your needs and preferences. It's important to approach the practice with an open mind, remain patient with yourself, and trust the process of tapping into your inner potential."
			}
		},
                              {
                                "@type": "Question",
                              "name": "What is the Silva three finger technique?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "The Silva Three-Finger Technique is a simple and effective method used in the Silva Method to induce relaxation and enter the alpha state. It involves using three fingers to help facilitate the process. Here's how to practice the Silva Three-Finger Technique:
                              1. Find a comfortable position: Sit or lie down in a relaxed position, ensuring you won't be disturbed during the practice.
                              2. Join your fingers: Place the tips of your thumb, middle finger, and ring finger together, forming a gentle, light touch.
                              3. Focus on your fingers: Bring your attention to the point where your fingers touch. This point serves as an anchor for your concentration throughout the technique.
                              4. Enter relaxation: Close your eyes and take a few deep breaths to relax your body. As you exhale, release any tension or stress.
                              5. Count down: Mentally count down from 3 to 1, using each number as a cue to deepen your relaxation. With each count, allow yourself to become progressively more relaxed and at ease.
                              6. Enter the alpha state: After reaching the count of 1, imagine stepping into a state of deep relaxation and tranquility. Imagine yourself descending into a calm, peaceful mental space.
                              7. Repeat affirmations or visualize: While maintaining the touch of your three fingers, you can use this relaxed state to repeat positive affirmations related to your goals or engage in vivid visualizations of your desired outcomes. Immerse yourself in the experience and allow it to feel real and natural.
                              8. Practice for a desired duration: Continue the practice for as long as feels comfortable and beneficial to you. It's generally recommended to practice for at least a few minutes to allow the relaxation and visualization to have an impact on your subconscious mind.
                              The Silva Three-Finger Technique serves as a focal point to anchor your attention and induce a relaxed state conducive to entering the alpha brainwave state. It can be a helpful tool to enhance your overall Silva Method practice, promoting relaxation, visualization, and positive affirmations."
			}
		},
                              {
                                "@type": "Question",
                              "name": "Silva Method techniques",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "The technique aims to reach and sustain a state of mental functioning, called alpha state, where brainwave frequency is seven to fourteen Hz. Daydreaming and the transition to sleeping are alpha states. Silva claimed to have developed a program that trained people to enter certain brain states of enhanced awareness."
			}
		},
                              {
                                "@type": "Question",
                              "name": "what happened to the Silva Mind Control Method?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "He began experimenting with psychic abilities and brain wave activity in the 1940s, eventually developing The Silva Mind Control Method and numerous instructional courses and programs. The Silva Method continues to be taught in seminars and events by accredited instructors, and in home-study courses. He died in 1999"
			}
		},
                              {
                                "@type": "Question",
                              "name": "Is the Silva method successful?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "For forty years, the Silva Method™ has earned its reputation for being the most successful method for personal empowerment by helping millions of people achieve greater fulfillment in their lives."
			}
		},
                              {
                                "@type": "Question",
                              "name": "What is the original Silva Mind Control Method system?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                              "text": "The Silva Method is a self-help and meditation program developed by José Silva. It claims to increase an individual's abilities through relaxation, development of higher brain functions, and psychic abilities such as clairvoyance. It has been variously classified as a self-religion, a new religious movement and a cult."
			}
		}
                              ]
}
                              `}
                              </script>
                            </Helmet>
                          ) : location.pathname === "/courses/basic-lecture-series" ? (
                            <Helmet>
                              <meta charset="utf-8" />
                              <meta name="viewport" content="width=device-width, initial-scale=1" />
                              <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                              <title>Silva Method Original Basic lecture Series| BLS-4 </title>
                              <meta name="description" content="Basic Lecture Series is the foundation course of the Silva Method. BLS is the basis of all other advanced Silva Method courses and offers great value" />
                              <meta name="keyword" content="Silva Basic Lecture Series, Original Silva Method BLS, Silva Method seminars 2023, Original Silva Method Seminar, Silva Method Foundation Course (BLS), Silva Live Events,
                              Silva Method courses, Silva Mind Control Method Courses, Silva Mind control classes near me, Silva Method home study course" />
                              <meta name="author" content="The Silva Method International " />
                              <meta name="copyright" content="Original Silva Method" />
                              <meta name="robots" content="index, follow" />
                              <meta property="og:type" content="website" />
                              <meta property="og:title" content="Silva BLS-4 Seminar | Original Silva Method Lecture Series" />
                              <meta property="og:url" content="https://silvamethod.com/courses/basic-lecture-series" />
                              <link rel="canonical" href="https://silvamethod.com/courses/basic-lecture-series" />

                              <meta property="og:description" content="BLS-4 seminar is the core and foundation for all other seminars of the Silva Method. Join us on a journey of self-discovery to use the power of a focused mind" />
                              <meta property="og:keyword" content="Silva Basic Lecture Series, Original Silva Method BLS, Silva Method seminars 2023, Original Silva Method Seminar, Silva Method Foundation Course (BLS), Silva Live Events, Silva Method courses, Silva Mind Control Method Courses, Silva Mind control classes near me, Silva Method home study course" />
                            </Helmet>
                          ) : location.pathname === "/silva-life-system-with-dragan" ? (
                            <Helmet>
                              <meta charset="utf-8" />
                              <meta name="viewport" content="width=device-width, initial-scale=1" />
                              <title>Silva Life System Live Training | Mind Mastery Live Training Masterclass</title>
                              <meta name="description" content="Transform your life with the Silva Method Mind Mastery Live Training Masterclass. Join Dragan Vujovic for an exclusive 4-day journey to harness the power of your mind. Take control, be extraordinary, and register now for a life-changing experience!" />
                              <meta name="keywords" content="Silva Life System Live Training, Silva Life System, Silva Mind Mastery Live Training Masterclass, Silva Method Live Class, Silva Method Courses, Silva Mind Control Method, Silva Method, Silva Live Training Masterclass with Dragan Vujovic" />
                              <meta name="author" content="Jose Silva (Founder of Silva Method)" />
                              <meta name="copyright" content="Silva Method International Inc." />
                              <meta name="robots" content="index, follow" />

                              <meta property="og:type" content="website" />
                              <meta property="og:title" content="Silva Life System Live Training | Mind Mastery Live Training Masterclass" />
                              <meta property="og:url" content="https://silvamethod.com/silva-life-system-with-dragan" />
                              <meta property="og:image" content="https://silvamethod.com/img/dragan/dragan.jpg" />
                              <meta property="og:description" content="Transform your life with the Silva Method Mind Mastery Live Training Masterclass. Join Dragan Vujovic for an exclusive 4-day journey to harness the power of your mind. Take control, be extraordinary, and register now for a life-changing experience!" />
                              <script type="application/ld+json">
                                {`
                                {
                                  "@context": "http://schema.org/",
                                  "@type": "Product",
                                  "name": "Silva Life System Live Training Program with Dragan Vujovic",
                                  "image": "https://silvamethod.com/img/dragan/dragan.jpg",
                                  "description": "Transform your life with the Silva Method Mind Mastery Live Training Masterclass. Join Dragan Vujovic for an exclusive 4-day journey to harness the power of your mind. Take control, be extraordinary, and register now for a life-changing experience!",
                                  "brand": {
                                    "@type": "Brand",
                                    "name": "Silva Method International Inc."
                                  },
                                  "offers": {
                                    "@type": "AggregateOffer",
                                    "priceCurrency": "USD",
                                    "lowPrice": "700",
                                    "highPrice": "1399",
                                    "url": "https://silvamethod.com/silva-life-system-with-dragan",
                                    "availability": "https://schema.org/LimitedAvailability",
                                    "offerCount": "1"
                                  },
                                  "aggregateRating": {
                                    "@type": "AggregateRating",
                                    "ratingValue" : "4.9",
                                    "ratingCount" : "80000",
                                    "reviewCount" : "38771",
                                    "bestRating" : "5"
                                  }
                                }
                                </script>
                                
                                <script type="application/ld+json">
                                {
                                  "@context": "http://schema.org/",
                                  "@type": "Event",
                                  "name": "Silva Life System Live Training Program with Dragan Vujovic",
                                  "description": "Transform your life with the Silva Method Mind Mastery Live Training Masterclass. Join Dragan Vujovic for an exclusive 4-day journey to harness the power of your mind. Take control, be extraordinary, and register now for a life-changing experience!",
                                  "image": "https://silvamethod.com/img/dragan/dragan.jpg",
                                  "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
                                  "eventStatus": "https://schema.org/EventScheduled",
                                  "startDate": "2023-12-20T12:30-05:00",
                                  "endDate": "2023-12-28T16:30-05:00",
                                  "location": [
                                    {
                                      "@type": "VirtualLocation",
                                      "url": "https://silvamethod.com/silva-life-system-with-dragan"
                                    }
                                  ],
                                  "offers": {
                                    "@type": "Offer",
                                    "url": "https://silvamethod.com/silva-life-system-with-dragan",
                                    "price": "700",
                                    "priceCurrency": "USD",
                                    "availability": "https://schema.org/PreOrder",
                                    "validFrom": "2023-12-19T00:00"
                                  },
                                  "organizer": {
                                    "@type": "Organization",
                                    "name": "Silva Method International Inc.",
                                    "url": "https://silvamethod.com/"
                                  },
                                  "performer": {
                                    "@type": "Person",
                                    "name": "Dragan Vujovic (Certified Silva Method Instructor)"
                                  }
                                }
                                </script><script type="application/ld+json">
                                {
                                  "@context": "http://schema.org/",
                                  "@type": "Event",
                                  "name": "Silva Life System Live Training Program with Dragan Vujovic",
                                  "description": "Transform your life with the Silva Method Mind Mastery Live Training Masterclass. Join Dragan Vujovic for an exclusive 4-day journey to harness the power of your mind. Take control, be extraordinary, and register now for a life-changing experience!",
                                  "image": "https://silvamethod.com/img/dragan/dragan.jpg",
                                  "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
                                  "eventStatus": "https://schema.org/EventScheduled",
                                  "startDate": "2023-12-20T12:30-05:00",
                                  "endDate": "2023-12-28T16:30-05:00",
                                  "location": [
                                    {
                                      "@type": "VirtualLocation",
                                      "url": "https://silvamethod.com/silva-life-system-with-dragan"
                                    }
                                  ],
                                  "offers": {
                                    "@type": "Offer",
                                    "url": "https://silvamethod.com/silva-life-system-with-dragan",
                                    "price": "700",
                                    "priceCurrency": "USD",
                                    "availability": "https://schema.org/PreOrder",
                                    "validFrom": "2023-12-19T00:00"
                                  },
                                  "organizer": {
                                    "@type": "Organization",
                                    "name": "Silva Method International Inc.",
                                    "url": "https://silvamethod.com/"
                                  },
                                  "performer": {
                                    "@type": "Person",
                                    "name": "Dragan Vujovic (Certified Silva Method Instructor)"
                                  }
                                }
                                `}
                              </script>
                            </Helmet>
                          ) : location.pathname === "/the-silva-mind-control-method" ?
                            <Helmet>
                              <meta name="title" content="The Silva Mind Control Method by Jose Silva" />
                              <title>The Silva Mind Control Method by Jose Silva</title>
                              <meta name="description" content="The Silva Mind Control Method by Joses Silva founded in 1962, is a self-transformation practice.Train your mind for success with Jose Silva Mind Control Method. " />
                              <meta name="keywords" content="The Silva Mind Control Method, Jose Silva Method, silva mind method, Silva Mind Control Technique, Jose silva book, silva method book, silva mind control courses, silva method." />
                              <meta name="robots" content="index, follow" />
                              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                              <meta name="language" content="English" />
                              <meta name="author" content="The Silva Method International" />

                              <meta property="og:title" content="The Silva Mind Control Method by Jose Silva" />
                              <meta property="og:site_name" content="Silva Mind Control Method" />
                              <meta property="og:url" content="https://silvamethod.com/the-silva-mind-control-method" />
                              <meta property="og:description" content="The Silva Mind Control Method by Joses Silva founded in 1962, is a self-transformation practice.Train your mind for success with Jose Silva Mind Control Method." />
                              <meta property="og:type" content="article" />
                              <meta property="og:image" content="https://silvamethod.com/img/bg/img-05.webp"></meta>
                              <link rel="canonical" href="https://silvamethod.com/the-silva-mind-control-method" />
                              <script type="application/ld+json">
                                {
                                  `<script type="application/ld+json">
                              {
                                "@context": "https://schema.org/", 
                                "@type": "Product", 
                                "name": "The Silva Mind Control Method",
                                "url": "https://silvamethod.com/the-silva-mind-control-method"
                                "image": "https://silvamethod.com/img/bg/img-05.webp",
                                "description": "Achieve empowerment and success with the Silva Mind Control Method. Explore Jose Silva's proven techniques to master your mind, enhance focus, and manifest your goals effortlessly.",
                                "brand": {
                                  "@type": "Brand",
                                  "name": "Silva Method"
                                },
                                "aggregateRating": {
                                  "@type": "AggregateRating",
                                  "ratingValue": "4.7",
                                  "bestRating": "5",
                                  "worstRating": "1",
                                  "ratingCount": "247075"
                                }
                              }
                              </script>
                              
                              
                              <script type="application/ld+json">
                              {
                                "@context": "https://schema.org/", 
                                "@type": "BreadcrumbList", 
                                "itemListElement": [{
                                  "@type": "ListItem", 
                                  "position": 1, 
                                  "name": "Silva Mind Control Method Official Facebook Page",
                                  "item": "https://www.facebook.com/SilvaInternationalInc"  
                                },{
                                  "@type": "ListItem", 
                                  "position": 2, 
                                  "name": "Silva Mind Control Method Official YouTube Channel",
                                  "item": "https://www.youtube.com/@SilvaMethodOfficial"  
                                },{
                                  "@type": "ListItem", 
                                  "position": 3, 
                                  "name": "Silva Mind Control Method Official Instagram Page",
                                  "item": "https://www.instagram.com/silvamethodofficial/"  
                                },{
                                  "@type": "ListItem", 
                                  "position": 4, 
                                  "name": "Silva Mind Control Method Official LinkedIn Page",
                                  "item": "https://www.linkedin.com/company/thesilvamethods/"  
                                },{
                                  "@type": "ListItem", 
                                  "position": 5, 
                                  "name": "Silva Mind Control Method Official Website",
                                  "item": "https://silvamethod.com/the-silva-mind-control-method"  
                                }]
                              }
                              </script>`
                                }
                              </script>

                            </Helmet>
                            : location.pathname === "/silva-three-fingers-technique" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Three Fingers Techniques: Benefits & Practical Use</title>
                                <meta name="description" content="Use the Silva Three Fingers Techniques to quickly reach deeper mind levels. Learn about its benefits, real-life applications and how to apply." />
                                <meta name="keywords" content="Silva Three Fingers Techniques: Benefits & Practical Use" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/silva-three-fingers-technique" />
                              </Helmet>
                            ) : location.pathname === "/silva-method-sleep-meditation" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Get Restful Sleep with Silva Method Sleep Meditation</title>
                                <meta name="description" content="Achieve restful nights with Silva Method Sleep Meditation. Learn dynamic meditation techniques to improve sleep quality and relaxation. " />
                                <meta name="keywords" content="Get Restful Sleep with Silva Method Sleep Meditation " />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/silva-method-sleep-meditation" />
                              </Helmet>
                            ) : location.pathname === "/silva-method-sleep-meditation2" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Improve sleep quality with Silva Method Sleep Meditation</title>
                                <meta name="description" content="Calm your mind and body with Silva Method Sleep Meditation. Reduce anxiety and reprogram your subconscious for restful sleep." />
                                <meta name="keywords" content="Improve sleep quality with Silva Method Sleep Meditation" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                              </Helmet>
                            ) : location.pathname === "/silva-method-meditation" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Enhance Focus & Relaxation with Silva Method Meditation</title>
                                <meta name="description" content="Experience deep relaxation with Silva Method Meditation. Learn Silva Method Guided Meditation techniques to calm the mind and reduce stress and anxiety" />
                                <meta name="keywords" content="Enhance Focus & Relaxation with Silva Method Meditation" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <link rel="canonical" href="https://silvamethod.com/silva-method-meditation" />
                                <meta name="language" content="English" />
                              </Helmet>
                            ) : location.pathname === "/silva-method-self-hypnosis" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Method Self Hypnosis: Deep Relaxation and Healing</title>
                                <meta name="description" content="Silva Method Self Hypnosis helps relax deeply, slow brain frequencies, and improve self-control while releasing bad habits. Heal & release pain with Silva Method Self-hypnosis." />
                                <meta name="keywords" content="Silva Method Self Hypnosis: Deep Relaxation and Healing" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/silva-method-self-hypnosis" />


                              </Helmet>
                            ) : location.pathname === "/silva-method-finance" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Method Finance: Master Smart Financial Decisions</title>
                                <meta name="description" content="Reprogram your mind with Silva Method Finance to eliminate worries and create long-term financial success. Optimize income and achieve financial freedom." />
                                <meta name="keywords" content="Reprogram your mind with Silva Method Finance to eliminate worries and create long-term financial success. Optimize income and achieve financial freedom." />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/silva-method-finance" />
                              </Helmet>
                            ) : location.pathname === "/silva-glassofwater-technique" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Glass of Water Technique for Problem Solving </title>
                                <meta name="description" content="The Silva Glass of Water Technique helps you solve any problem. Make informed decisions & gain insights to tackle any challenges using Glass of water technique. " />
                                <meta name="keywords" content="Silva Glass of Water Technique for Problem Solving" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/silva-glassofwater-technique" />


                              </Helmet>
                            ) : location.pathname === "/online-manifestation-course" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Method Online Manifestation Course for Success</title>
                                <meta name="description" content="Join the Silva Method Online Manifestation Course to attract abundance and manifest your goals with proven mind control techniques. Learn to manifest anything" />
                                <meta name="keywords" content="Silva Method Online Manifestation Course for Success" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                              </Helmet>
                            ) : location.pathname === "/online-spiritual-healing-course" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Method Spiritual Healing</title>
                                <meta name="description" content="Heal emotionally and spiritually with Silva Method Spiritual Healing, a powerful approach to restoring inner harmony and peace. Get healed with Silva Method Spiritual healing." />
                                <meta name="keywords" content="Silva Method Spiritual Healing" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/online-spiritual-healing-course" />
                              </Helmet>
                            ) : location.pathname === "/store/course/the-silva-harmonious-relationships" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Method Love Mind for Building Stronger, Lasting Love</title>
                                <meta name="description" content="Transform your relationships with Silva Method Love Mind, fostering harmony, love, and better emotional connection in your life.." />
                                <meta name="keywords" content="Silva Method Love Mind for Building Stronger, Lasting Love" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/store/course/the-silva-harmonious-relationships" />
                              </Helmet>
                            ) : location.pathname === "/about-jose-silva" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>All About Jose Silva| Founder of Silva Method</title>
                                <meta name="description" content="Learn everything about Jose Silva: The revolutionary mind scientist discovering America’s 1st Personal Growth Program" />
                                <meta name="keywords" content="All About Jose Silva| Founder of Silva Method" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/about-jose-silva" />
                              </Helmet>
                            ) : location.pathname === "/instructor/diana-silva" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Diana Silva-Instructor and President </title>
                                <meta name="description" content="Instructor Diana Silva is the current president of the Silva Method International and the youngest of Jose’s children. Click to learn more about her!" />
                                <meta name="keywords" content="Diana Silva-Instructor and President " />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/instructor/diana-silva" />
                              </Helmet>
                            ) : location.pathname === "/about-silva-method" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Everything About the Silva Method</title>
                                <meta name="description" content="Learn about the Silva Method, a powerful program to train your mind, sharpen focus, and elevate your life through proven techniques." />
                                <meta name="keywords" content="Everything About the Silva Method" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/about-silva-method" />
                              </Helmet>
                            ) : location.pathname === "/contact" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Contact Silva Method</title>
                                <meta name="description" content="Contact Silva Method to get your Silva queries resolved instantly. Mail us or fill out the contact form with your doubts and queries. " />
                                <meta name="keywords" content="Contact Silva Method" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/contact" />
                              </Helmet>
                            ) : location.pathname === "/courses/seminar" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Method Seminar | Live & In-Person Seminars</title>
                                <meta name="description" content="Get in-depth training at Silva Method seminar. Experience a meditative retreat with Silva Method Live and In-Person Seminars. Enroll Now!" />
                                <meta name="keywords" content="Silva Method Seminar | Live & In-Person Seminars" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/courses/seminar" />
                              </Helmet>
                            ) : location.pathname === "/courses/silva-life-system-seminar" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Life System Seminar (SLS) -Beginner’s Course</title>
                                <meta name="description" content="Silva Life System(SLS) Seminar is the Stepping stone for all the advanced courses of the Silva Method. Enroll now in SLS Live Seminars and start your growth journey" />
                                <meta name="keywords" content="Silva Life System Seminar (SLS) -Beginner’s Course" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/courses/silva-life-system-seminar" />
                              </Helmet>
                            ) : location.pathname === "/courses/silva-intution-system-seminar" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Intuition System Seminar (SIS): Intuitive Skillse</title>
                                <meta name="description" content="Silva Intuition System (SIS) Seminar is an ideal program to regain your intuitive senses. Join SIS Live seminar to enhance human intuitive skills and unlock the key to your success." />
                                <meta name="keywords" content="Silva Intuition System Seminar (SIS): Intuitive Skills" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/courses/silva-intution-system-seminar" />


                              </Helmet>
                            ) : location.pathname === "/events/live" ? (
                              <Helmet>
                                <meta charset="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                <title>Silva Method Live Events Near Me |2024</title>
                                <meta name="description" content="Join Silva Method Live Events and enter the world of transformation. Revisit the profound teaching of Jose Silva with Silva Method Live Events. " />
                                <meta name="keywords" content="Silva Method Live Events Near Me |2024" />
                                <meta name="author" content="The Silva Method International" />
                                <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                <meta name="robots" content="index, follow" />
                                <meta name="language" content="English" />
                                <link rel="canonical" href="https://silvamethod.com/events/live" />
                              </Helmet>
                            ) :
                              location.pathname === "/events/online" ? (
                                <Helmet>
                                  <link rel="canonical" href="https://silvamethod.com/events/online" />
                                </Helmet>

                              ) :
                                location.pathname === "/instructor" ? (
                                  <Helmet>
                                    <link rel="canonical" href="https://silvamethod.com/instructor" />
                                  </Helmet>

                                ) :
                                  location.pathname === "/cure-stress-depression-anxiety" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Silva Method: A Path to Cure Stress, Anxiety, Depression</title>
                                      <meta name="description" content="Discover how Silva Method can help cure stress, depression, and anxiety for a balanced life. Explore Silva Method dynamic meditation techniques for your mental well-being. " />
                                      <meta name="keywords" content="Silva Method: A Path to Cure Stress, Anxiety, Depression" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                    </Helmet>
                                  ) : location.pathname === "/silva-guided-meditation" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Silva Guided Meditation: Elevate Your Mind and Well-being</title>
                                      <meta name="description" content="Silva guided meditation empowers you to relax, focus, and manifest your goals effortlessly. Achieve mental clarity with Silva's dynamic meditation techniques." />
                                      <meta name="keywords" content="Silva Guided Meditation: Elevate Your Mind and Well-being" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                    </Helmet>
                                  ) : location.pathname === "/jose-silva-mind-control-method" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Discover the Power of the Jose Silva Mind Control Method</title>
                                      <meta name="description" content="Tap into the potential of the Jose Silva Mind Control Method for holistic living, success, and mastering mental well-being. Explore Jose Silva Courses for success and self-growth" />
                                      <meta name="keywords" content="Discover the Power of the Jose Silva Mind Control Method" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                    </Helmet>
                                  ) : location.pathname === "/success-stories" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Silva Method Success Stories: Journeys of Success</title>
                                      <meta name="description" content="Read inspiring Silva Method success stories highlighting how people have achieved their goals through Silva Method meditation. Explore real-life Silva Method testimonials." />
                                      <meta name="keywords" content="Silva Method Success Stories: Journeys of Success" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                      <link rel="canonical" href="https://silvamethod.com/success-stories" />


                                    </Helmet>
                                  ) : location.pathname === "/silva-morning-meditation" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Silva Method Morning Meditation: Energize Your Morning</title>
                                      <meta name="description" content="Start your day with Silva Method Morning Meditation and stay focused for a productive day. Experience positivity the whole day with Silva Method Morning Meditation" />
                                      <meta name="keywords" content="Silva Method Morning Meditation: Energize Your Morning" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                      <link rel="canonical" href="https://silvamethod.com/silva-morning-meditation" />
                                    </Helmet>
                                  ) : location.pathname === "/7-day-meditation-masterclass" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Silva Method: 7 Day Free Guided Meditation </title>
                                      <meta name="description" content="Transform yourself with the Silva Method 7-day Free Guided Meditation challenge. Get Silva Method guided meditations for free and start your journey today!" />
                                      <meta name="keywords" content="Silva Method: 7 Day Free Guided Meditation" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                    </Helmet>
                                  ) : location.pathname === "/emotional-intelligence" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Boost Emotional Intelligence with the Silva Method</title>
                                      <meta name="description" content="Learn how the Silva Method Emotional Intelligence techniques improve emotional balance. Master your emotions with the Silva Method Emotional Intelligence techniques." />
                                      <meta name="keywords" content="Boost Emotional Intelligence with the Silva Method" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                      <link rel="canonical" href="https://silvamethod.com/emotional-intelligence" />
                                    </Helmet>
                                  ) : location.pathname === "/silva-combo-course" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Silva Method Combo Course: Get Lifetime Access</title>
                                      <meta name="description" content="Start your day with Silva Method Morning Meditation and stay focused for a productive day. Experience positivity the whole day with Silva Method Morning Meditation" />
                                      <meta name="keywords" content="Silva Method Combo Course: Get Lifetime Access" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                    </Helmet>
                                  ) : location.pathname === "/manifest-like-a-pro" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Become a Pro-Level Manifestor with the Silva Method </title>
                                      <meta name="description" content="Learn how to Manifest like a PRO with The Silva Method. Manifest 10 times faster with Jose Silva Manifestation techniques and manifest anything you want in life" />
                                      <meta name="keywords" content="Become a Pro-Level Manifestor with the Silva Method " />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                      <link rel="canonical" href="https://silvamethod.com/manifest-like-a-pro" />
                                    </Helmet>
                                  ) : location.pathname === "/book-an-appointment" ? (
                                    <Helmet>
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                      <title>Book FREE Appointment and Talk to a Silva Method Expert</title>
                                      <meta name="description" content="Book an appointment to get FREE expert guidance from the Silva Method. Clear any doubts regarding the Silva courses, techniques, online and offline events etc" />
                                      <meta name="keywords" content="Book FREE Appointment and Talk to a Silva Method Expert" />
                                      <meta name="author" content="The Silva Method International" />
                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                      <meta name="robots" content="index, follow" />
                                      <meta name="language" content="English" />
                                      <link rel="canonical" href="https://silvamethod.com/book-an-appointment" />
                                    </Helmet>
                                  ) : location.pathname === "/how-to-meditate-for-beginners" ? (
                                    <Helmet>
                                      <link rel="canonical" href="https://silvamethod.com/how-to-meditate-for-beginners" />
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <title>How to Meditate for Beginners: A Complete Guide to Mindfulness</title>
                                      <meta name="description" content="New to meditation? Explore our complete guide for beginners. Learn essential techniques, overcome challenges, and enjoy the benefits of mindfulness today." />
                                      <meta name="keywords" content="How to meditate for beginners, Learn How to Meditate, complete meditation guide for beginners, Mindfulness, How to Meditate, How To Meditate: A Complete Guide For Beginners, Meditation practice, Meditation tips, Silva method meditation" />
                                      <meta name="author" content="Silva Method Official" />
                                      <meta name="copyright" content="The Silva Method" />
                                      <meta name="robots" content="index, follow" />
                                      <meta property="og:title" content="How to Meditate for Beginners: A Complete Guide to Mindfulness" />
                                      <meta property="og:site_name" content="The Silva Method" />
                                      <meta property="og:url" content="https://silvamethod.com/how-to-meditate-for-beginners" />
                                      <meta property="og:description" content="New to meditation? Explore our complete guide for beginners. Learn essential techniques, overcome challenges, and enjoy the benefits of mindfulness today." />
                                      <meta property="og:keywords" content="How to meditate for beginners, Learn How to Meditate, complete meditation guide for beginners, Mindfulness, How to Meditate, How To Meditate: A Complete Guide For Beginners, Meditation practice, Meditation tips, Silva method meditation." />
                                      <meta property="og:type" content="" />
                                      <meta property="og:image" content="https://silvamethod.com/img/bg/bghero/1%20Oct%20-%20Practrice%20Meditation.jpg" />
                                      <meta name="twitter:card" content="summary" />
                                      <meta name="twitter:title" content="How to Meditate for Beginners: A Complete Guide to Mindfulness" />
                                      <meta name="twitter:site" content="@SilvaHomeOffice" />
                                      <meta name="twitter:description" content="New to meditation? Explore our complete guide for beginners. Learn essential techniques, overcome challenges, and enjoy the benefits of mindfulness today." />
                                      <meta name="twitter:image" content="https://silvamethod.com/img/bg/bghero/1%20Oct%20-%20Practrice%20Meditation.jpg" />
                                      <meta name="twitter:image:alt" content="Meditate-for-beginners-guide" />
                                      <script type="application/ld+json">
                                        {
                                          `{
                                      "@context": "http://schema.org/",
                                      "@type": "Article",
                                      "mainEntityOfPage": {
                                        "@type": "WebPage",
                                        "@id": "https://silvamethod.com/how-to-meditate-for-beginners"
                                      },
                                      "author": {
                                        "@type": "Organization",
                                        "name": "The Silva Method"
                                      },
                                      "publisher": {
                                        "@type": "Organization",
                                        "name": "Silva Method Official",
                                        "logo": {
                                          "@type": "ImageObject",
                                          "url": "hhttps://silvamethod.com/img/silva-method-official-logo.png"
                                        }
                                      },
                                      "headline": "How to Meditate for Beginners: A Complete Guide to Mindfulness",
                                      "image": "https://silvamethod.com/img/bg/bghero/1%20Oct%20-%20Practrice%20Meditation.jpg",
                                      "datePublished": "2024-10-04",
                                      "dateModified": "2024-10-04"
                                    }`
                                        }
                                      </script>
                                    </Helmet>
                                  ) : location.pathname === "/sleep-meditation" ? (
                                    <Helmet>
                                      <link rel="canonical" href="https://silvamethod.com/sleep-meditation" />
                                      <meta charset="utf-8" />
                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                      <title>Best Sleep Meditation | Guided Meditation for Sleep</title>
                                      <meta name="description" content="Struggling with insomnia? Try the best sleep meditation and guided sessions to learn how to effectively use meditation for a peaceful night’s rest." />
                                      <meta name="keywords" content="Sleep Meditation, Best sleep meditation, Guided Meditation for Sleep, guided meditation for sleep, deep sleep meditation, free sleep meditation, night meditation, bedtime meditation, night time meditation, relaxing sleep meditation, free guided meditation for sleep, deep sleep guided meditation, mindfulness meditation for sleep." />
                                      <meta name="author" content="The Silva Method" />
                                      <meta name="copyright" content="Silva Method Official" />
                                      <meta name="robots" content="index, follow" />

                                      <meta property="og:title" content="Best Sleep Meditation | Guided Meditation for Sleep" />
                                      <meta property="og:site_name" content="The Silva Method" />
                                      <meta property="og:url" content="https://silvamethod.com/sleep-meditation" />
                                      <meta property="og:description" content="Struggling with insomnia? Try the best sleep meditation and guided sessions to learn how to effectively use meditation for a peaceful night’s rest." />
                                      <meta property="og:keywords" content="Sleep Meditation, Best sleep meditation, Guided Meditation for Sleep, guided meditation for sleep, deep sleep meditation, free sleep meditation, night meditation, bedtime meditation, night time meditation, relaxing sleep meditation, free guided meditation for sleep, deep sleep guided meditation, mindfulness meditation for sleep." />
                                      <meta property="og:type" content="" />
                                      <link rel="canonical" href="https://silvamethod.com/sleep-meditation" />
                                      <meta property="og:image" content="https://silvamethod.com/img/bg/blogpages/2-What%20is%20Sleep%20Meditation/sleep-meditation.jpg" />
                                      <script type="application/ld+json">
                                        {
                                          `{
                                      "@context": "http://schema.org/",
                                      "@type": "Article",
                                      "mainEntityOfPage": {
                                        "@type": "WebPage",
                                        "@id": "https://silvamethod.com/sleep-meditation"
                                      },
                                      "author": {
                                        "@type": "Organization",
                                        "name": "Silva Method"
                                      },
                                      "publisher": {
                                        "@type": "Organization",
                                        "name": "Silva Method Official",
                                        "logo": {
                                          "@type": "ImageObject",
                                          "url": "https://silvamethod.com/img/silva-method-official-logo.png"
                                        }
                                      },
                                      "headline": "Best Sleep Meditation | Guided Meditation for Sleep",
                                      "image": "https://silvamethod.com/img/bg/blogpages/2-What%20is%20Sleep%20Meditation/sleep-meditation.jpg",
                                      "datePublished": "2024-10-04",
                                      "dateModified": "2024-10-04"
                                    }`
                                        }
                                      </script>
                                    </Helmet>
                                  ) :
                                    location.pathname === "/courses/silva-mastery-system" ? (

                                      <Helmet>
                                        <link rel="canonical" href="https://silvamethod.com/courses/silva-mastery-system" />
                                      </Helmet>
                                    ) :
                                      location.pathname === "/research" ? (
                                        <Helmet>
                                          <meta charset="utf-8" />
                                          <meta name="viewport" content="width=device-width, initial-scale=1" />
                                          <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                          <title>The Science of the Silva Method Mind Control Method</title>
                                          <meta name="description" content=" Know the truth if the Silva Method is scientifically proven. Learn how science discovered the Silva method, explore research projects, scientific studies and more." />
                                          <meta name="keywords" content="The Science of the Silva Method Mind Control Method" />
                                          <meta name="author" content="The Silva Method International" />
                                          <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                          <meta name="robots" content="index, follow" />
                                          <meta name="language" content="English" />
                                          <link rel="canonical" href="https://silvamethod.com/research" />


                                        </Helmet>
                                      ) : location.pathname === "/healing-meditation" ? (
                                        <Helmet>
                                          <link rel="canonical" href="https://silvamethod.com/healing-meditation" />
                                          <meta charSet="utf-8" />
                                          <meta name="viewport" content="width=device-width, initial-scale=1" />
                                          <meta name="author" content="Silva Method Official" />
                                          <meta name="copyright" content="The Silva Method" />
                                          <meta name="robots" content="index, follow" />

                                          {/* Meta Title */}
                                          <title>Guided Healing Meditation for Self-Healing & Relaxation</title>

                                          {/* Meta Keywords */}
                                          <meta name="keywords" content="Guided Meditation for Healing, Self Healing Meditation, Healing Meditation, Meditation for Relaxation and Healing, 15 Minute Healing Meditation, Morning Meditation for Healing, free healing meditation, Mindful Meditation Healing, how to meditate for healing, body healing meditation, Types of Healing Meditation, Benefits of Healing Meditation, Practicing healing meditation" />

                                          {/* Meta Description */}
                                          <meta name="description" content="New to self-healing meditation? Explore our beginner's guide! Learn effective techniques to relax, relieve stress, and enjoy the benefits of mindfulness today." />

                                          {/* Open Graph (OG) Tags */}
                                          <meta property="og:type" content="website" />
                                          <meta property="og:title" content="Guided Healing Meditation for Self-Healing & Relaxation" />
                                          <meta property="og:url" content="https://silvamethod.com/healing-meditation" />
                                          <meta property="og:image" content="https://silvamethod.com/img/bg/bghero/icons/x58oeg8exbllsm2otdhn.jpg" />
                                          <meta property="og:description" content="New to self-healing meditation? Explore our beginner's guide! Learn effective techniques to relax, relieve stress, and enjoy the benefits of mindfulness today." />
                                          <meta property="og:keywords" content="Guided Meditation for Healing, Self Healing Meditation, Healing Meditation, Meditation for Relaxation and Healing, 15 Minute Healing Meditation, Morning Meditation for Healing, Free healing meditation, Mindful Meditation Healing, how to meditate for healing, body healing meditation, Types of Healing Meditation, Benefits of Healing Meditation, Practicing healing meditation" />

                                          {/* Twitter OG Tags */}
                                          <meta name="twitter:card" content="summary" />
                                          <meta name="twitter:title" content="Guided Healing Meditation for Self Healing & Relaxation" />
                                          <meta name="twitter:site" content="@The Silva Method" />
                                          <meta name="twitter:description" content="New to self-healing meditation? Explore our beginner's guide! Learn effective techniques to relax, relieve stress, and enjoy the benefits of mindfulness today." />
                                          <meta name="twitter:image" content="https://silvamethod.com/img/bg/bghero/icons/x58oeg8exbllsm2otdhn.jpg" />
                                          <meta name="twitter:image:alt" content="Guided Meditation for Healing" />

                                          {/* Schema.org JSON-LD */}
                                          <script type="application/ld+json">
                                            {`{
                                  "@context": "http://schema.org/",
                                  "@type": "Article",
                                  "mainEntityOfPage": {
                                    "@type": "WebPage",
                                    "@id": "https://silvamethod.com/healing-meditation"
                                  },
                                  "author": {
                                    "@type": "Organization",
                                    "name": "Silva Method"
                                  },
                                  "publisher": {
                                    "@type": "Organization",
                                    "name": "Silva Method Official",
                                    "logo": {
                                      "@type": "ImageObject",
                                      "url": "https://silvamethod.com/img/silva-method-official-logo.png"
                                    }
                                  },
                                  "headline": "Guided Healing Meditation for Self Healing & Relaxation",
                                  "image": "https://silvamethod.com/img/bg/bghero/icons/x58oeg8exbllsm2otdhn.jpg",
                                  "datePublished": "2024-10-22",
                                  "dateModified": "2024-10-22"
                                }`}
                                          </script>
                                        </Helmet>
                                      ) :
                                        location.pathname === "/instructor/find-instructor" ? (
                                          <Helmet>
                                            <link rel="canonical" href="https://silvamethod.com/instructor/find-instructor" />
                                          </Helmet>
                                        ) : location.pathname === "/instructor/become-silva-instructor" ? (
                                          <Helmet>
                                            <link rel="canonical" href="https://silvamethod.com/instructor/become-silva-instructor" />
                                          </Helmet>
                                        ) :
                                          location.pathname === "/store/course/unstress-toolbox" ? (
                                            <Helmet>
                                              <link rel="canonical" href="https://silvamethod.com/store/course/unstress-toolbox" />
                                            </Helmet>

                                          )
                                            : location.pathname === "/store/course/the-silva-mind-control-sound" ? (

                                              <Helmet>
                                                <link rel="canonical" href="https://silvamethod.com/store/course/the-silva-mind-control-sound" />
                                              </Helmet>
                                            ) : location.pathname === "/store/course/weekly-journal-to-finding-a-soul-mate" ? (

                                              <Helmet>
                                                <link rel="canonical" href="https://silvamethod.com/store/course/weekly-journal-to-finding-a-soul-mate" />
                                              </Helmet>
                                            ) :
                                              location.pathname === "/stores" ? (
                                                <Helmet>
                                                  <link rel="canonical" href="https://silvamethod.com/stores" />
                                                </Helmet>
                                              ) : location.pathname === "/silva-method-faq" ? (
                                                <Helmet>
                                                  <link rel="canonical" href="https://silvamethod.com/silva-method-faq" />
                                                </Helmet>

                                              ) : location.pathname === "/youtube-videos" ? (
                                                <Helmet>
                                                  <link rel="canonical" href="https://silvamethod.com/youtube-videos" />
                                                </Helmet>

                                              ) : location.pathname === "/cure-stress-depression-anxiety" ? (

                                                <Helmet>
                                                  <link rel="canonical" href="https://silvamethod.com/cure-stress-depression-anxiety" />
                                                </Helmet>
                                              ) :
                                                location.pathname === "/silva-guided-meditation" ? (
                                                  <Helmet>
                                                    <link rel="canonical" href="https://silvamethod.com/silva-guided-meditation" />
                                                  </Helmet>
                                                ) : location.pathname === "/silva-method-techniques" ? (
                                                  <Helmet>
                                                    <link rel="canonical" href="https://silvamethod.com/silva-method-techniques" />
                                                  </Helmet>
                                                ) : location.pathname === "/silva-mind-control-synopsis" ? (
                                                  <Helmet>
                                                    <link rel="canonical" href="https://silvamethod.com/silva-mind-control-synopsis" />
                                                  </Helmet>

                                                ) : location.pathname === "/terms-and-condition" ? (

                                                  <Helmet>
                                                    <link rel="canonical" href="https://silvamethod.com/terms-and-condition" />
                                                  </Helmet>
                                                ) : location.pathname === "/privacy-policy" ? (
                                                  <link rel="canonical" href="https://silvamethod.com/privacy-policy" />

                                                ) :
                                                  (
                                                    <Helmet>
                                                      <meta charset="utf-8" />
                                                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                                                      <meta property="og:image" content="https://silvamethod.com/title-logo.png" />
                                                      <title>The Silva Method | Learn How to Have Control over Your Mind</title>
                                                      <meta name="description"
                                                        content="Silva Method Meditation and mind control training offer dynamic meditation courses that will change the way you feel, think, and live." />
                                                      <meta name="keywords"
                                                        content="Silva Method, Silva Mind Control Method & Training, Meditation and Mind Control training courses, Silva Method Meditation, Silva Mind Control Method, Original Silva Method, silva masterclass programs, Meditation and mind control Training, Jose silva courses, The Silva Mind Control Method by José Silva, Advanced dynamic meditation training, Silva Intuition System, Meditation tips and techniques, Silva Life System, silva masterclass Programs, Silva Intuition System, The Silva Manifesting Course, dynamic meditation courses, Silva Method courses, Silva Master Class programs, Silva Mind control classes near me, Silva Method course online, Silva Membership" />
                                                      <meta name="author" content="The Silva Method International" />
                                                      <meta name="copyright" content="Silva Method Official (Original Silva Method)" />
                                                      <meta name="robots" content="index, follow" />
                                                      <meta name="language" content="English" />

                                                    </Helmet>
                                                  )
      }
      <Toaster position="bottom-center" />

      <Routes>
        <Route element={<WithCookiePage />}>

          <Route path="/silva-method-courses" element={<SilvaMemberShip />} />

          <Route element={<ScrollTop />}>
            <Route path="/about-jose-silva" element={<AboutJoseSilva />} />
            <Route path="/instructor/diana-silva" element={<AboutDianaSilva />} />

            <Route path="/loading" element={<LoadingSpinner />} />
            <Route path="/silva-method-faq" element={<FaqPage />} />
            {/* Home Route started*/}
            {/* <Route path="/store/courses" element={<AllCourses />} /> */}
            {/* <Route path="/" element={<HomeStudyCourses />} /> */}
            <Route path="/" element={<NewLandingPage />} />
            <Route path="/newhome" element={<HomeStudy2 />} />
            <Route path="/become-silva-affiliate" element={<SilvaAffiliate />} />
            <Route path="/newpage" element={<HabitControl />} />
            <Route path="/blackfridaysale" element={<BlackFridaySale />} />



            <Route element={<PrivateRoute />}>
              <Route path="/today" element={<SilvaHome2 />} />
            </Route>
            <Route path="/silva-benefits" element={<SilvaBenefitsPage />} />

            {/* <Route path="/home" element={<SilvaHome />} /> */}
            {/* Home Route Ended */}
            {/*About Route started*/}
            <Route path="/about-silva-method" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/research" element={<SciencePage />} />
            {/* <Route path='/member' element={<MemberShippricingPlan2 />} /> */}
            <Route path="/what-is-silva-method" element={<WhatIsSilvaMethod />} />
            <Route path="/silva-for-all/:id" element={<SilvaForAll />} />


            {/* About Route Ended */}
            {/* Seminar Route Started */}
            <Route path="/courses/basic-lecture-series" element={<BasicLecture />} />
            <Route path="/courses/seminar" element={<Seminars />} />
            <Route path="/courses/silva-life-system-seminar" element={<LifeSystem />} />
            <Route path="/courses/silva-intution-system-seminar" element={<IntuitionSystem />} />
            <Route path="/courses/silva-mastery-system" element={<MasterySystem />} />
            <Route path='/comment-section' element={<CommentSection />} />
            <Route path='/reset-password/:token' element={<ResetPassword />} />
            <Route path='/delete-account' element={<DeleteAccount />} />
            {/* <Route path='/newcoursepage/:id' element={<SingleCoursePage />} /> */}



            <Route element={<PrivateRoute />}>
              <Route path="/store/profile" element={<Navigate to={`/store/profile/${userData?.first_name || userData?.phone}/settings/basic-information`} />}>
              </Route>
            </Route>
            <Route element={<PrivateRoute />}>
              <Route element={<ProfilePage />}>
                <Route path='/store/profile/:username/courses' element={<MyCoursesPage />} />
              </Route>
            </Route>

            <Route element={<PrivateRoute />}>
              <Route element={<ProfilePage />}>
                <Route path='/store/profile/:username/settings/basic-information' element={<MyBasicInfo />} />
              </Route>
            </Route>

            <Route element={<PrivateRoute />}>
              <Route element={<ProfilePage />}>
                <Route path='/store/profile/:username/settings/myorders' element={<MyPlan />} />
              </Route>
            </Route>

            <Route element={<PrivateRoute />}>
              <Route element={<ProfilePage />}>
                <Route path='/store/profile/:username/settings/change-password' element={<ChangePassword />} />
              </Route>
            </Route>

            <Route path="/events/live" element={<LiveEvents />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-condition" element={<TermsAndConditions />} />


            <Route path="/events/online" element={<OnlineEvents />} />
            <Route element={<WithHeaderFooter />}>
              <Route path="/events/livedetails/:id" element={<CourseDetails />} />
            </Route>
            {/* Event Route Ended */}
            {/* Instructor Route started */}
            <Route path="/instructor" element={<Instructors />} />
            <Route path="/instructor/find-instructor" element={<FindInstructor />} />
            <Route path="/instructor/become-silva-instructor" element={<BecomeInstructor />} />
            {/* <Route path="/instructor/find-instructor/:city/:id" element={<SingleLocationInstructorsCard />} /> */}
            <Route path="/instructor/instructor_list/:city" element={<SingleLocationInstructorsCard />} />

            <Route path="/instructor/details/:instructor_id" element={<SingleInstructorDetailsPage />} />
            {/* Instructor Route Ended */}
            {/* Blogs Route started */}
            <Route path="/store" element={<StoreProducts />} />
            <Route path="/store/blogs/" element={<EnglishBlog />} />
            <Route path="/cure-stress-depression-anxiety" element={<NewMembership />} />
            <Route path="/silva-guided-meditation" element={<MeditationJourney />} />
            <Route path='/jose-silva-mind-control-method' element={<JoseMindControl />} />
            <Route path="/store/spanish-blogs/" element={<SpanishBlog />} />
            <Route path="/new-landing-page" element={<NewLandingPage />} />

            {/* <Route path="/live-Interactive-day-1-class-recording" element={<LiveInteractive />} /> */}

            <Route path="/zoom-meeting" element={<ZoomMeet />} />


            {/* <Route element={<BottomToTop />} > */}
            {/* <Route path="/store/course/:id" element={<SilvaManifestationProgram />} /> */}
            {/* <Route path='/store/course/silva-method-complete-course' element={<ComboCourse2 />} /> */}
            <Route path='/success-stories' element={<Testimonials />} />
            {/* <Route path='/store/course/combo-plan2' element={<ComboCourse />} /> */}

            <Route path='/store/course/combo-plan' element={<NewComboCourse />} />
            {/* <Route path='/store/course/combo-plan2' element={<NewComboCourse />} /> */}


            {/* <Route path="/store/course/:id" element={<CoursePage />} /> */}

            <Route path="/store/course/:id" element={<SingleCoursePage />} />
            {/* <Route path="/store/course/:id" element={<CoursePageNew />} /> */}

            {/* <Route path="/store/course/videocourse/:id" element={<VideoCoursePage />} /> */}
            <Route path='/store/course/:course_id/:chapter_id/:lession_id' element={<SingleLecturePage />} />
            {/* </Route> */}
            {/* Blogs Route Ended */}
            {/*Auth Route started */}
            <Route element={<PublicRoutes />}>
              <Route path="/login" element={<Login />} />
            </Route>

            {/*Auth Route Ended */}

            {/* product route started */}
            <Route path="/store/books" element={<Books />} />
            {/* product route ended */}
            <Route path="/silva-relaxation-exercise" element={<FreeAudio />} />
            <Route path="/home-study-courses" element={<HomeStudyCourses />} />
            <Route path="/paymentSuccess" element={<PaymentSuccess />} />
            <Route path="/paymentError" element={<PaymentUnSuccess />} />
            <Route path="/book" element={<SilvaMindControlBook />} />

            <Route path='/subscription-success' element={<SubscriptionSuccess />} />
            <Route path='/subscription-failed' element={<SubscriptionFailure />} />
            <Route path='/youtube-videos' element={<YoutubeVideos />} />

            <Route path="/silva-method-techniques" element={<SilvaMethodTechnique />} />
            <Route path="/silva-mind-control-synopsis" element={<SilvaSynopsis />} />
            {/* <Route path="/combo-live-Interactive-class-recordings" element={<LiveInteractive />} /> */}
            <Route path="/combo-live-Interactive-day-2-class-recording" element={<PreviousVideos />} />
            <Route path="/silva-morning-meditation" element={<MorningMediation />} />

            <Route path="/silva-three-fingers-technique" element={<ThreeFingerTechnique />} />
            <Route path="/silva-method-sleep-meditation" element={<SilvaSleepMeditation />} />
            <Route path="/silva-method-sleep-meditation2" element={<SleepMeditation />} />
            <Route path="/silva-method-meditation" element={<MeditationJourney2 />} />
            <Route path="/silva-events/:id" element={<SilvaEvents />} />

            <Route path="/services" element={<ServicesOne />} />

            <Route path='/successtest' element={<SuccessTest />} />
            <Route path='/failuretest' element={<FailureTest />} />
          </Route>
          {/* join-live-class */}
        </Route>
        <Route path="/join-live-class-india" element={<BookingNew />} />
        <Route path="/silva-life-system-with-dragan" element={<BookingNewTwo />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/thank-you2" element={<ThankYouPage2 />} />
        <Route path="/thank-you3" element={<ThankYouPage3 />} />

        <Route path="/silva-method-self-hypnosis" element={<HypnosisPage />} />
        <Route path="/silva-method-finance" element={<SecondHypnosis />} />

        <Route path="/thank-you2" element={<ThankYouPage2 />} />
        <Route path="/thank-you3" element={<ThankYouPage3 />} />
        <Route path="/appointment" element={<AppointMent />} />
        <Route path="/contactnew" element={<ContactNew />} />
        <Route path='/silva-sleep-control-meditation' element={<LandingPage1 />} />
        <Route path='/sls-free-introductory-masterclass' element={<LandingPage2 />} />
        <Route path='/silva-glassofwater-technique' element={<GlassofWaterLanding />} />
        <Route path='/silva-three-finger-technique' element={<TFTLanding />} />
        <Route path='/refund' element={<Refund />} />
        <Route path='/unstress-toolbox-introductory-seminar' element={<UnstressLanding />} />
        <Route path='/online-manifestation-course' element={<ComboPlusLive />} />
        <Route path='/silva-method-spanish' element={<SpansihLanding />} />

        <Route path='/workbook' element={<WorkBook />} />
        <Route path="/confirm-participant" element={<Participant />} />
        <Route path='/live-class-payment-success' element={<LiveClassSuccess />} />
        <Route path='/live-class-payment-failed' element={<FailedPayment />} />
        <Route path='/live-subscription-success' element={<LiveEventSuccess />} />
        <Route path='/live-subscription-failed' element={<LiveEventFailed />} />
        {/* <Route path='/test' element={<NewTest />} /> */}
        {/* <Route path='/store/course/combo-plan' element={<ComboCourse />} /> */}

        <Route path='/online-spiritual-healing-course' element={<ComboLiveLandingPageOne />} />
        {/* <Route path='/landing-page1' element={<ComboLiveLandingPageOne/>}/> */}
        <Route path='/silva-method-love-mind' element={<LoveMindOne />} />
        {/* <Route path='/event-generator' element={<EventGenerator/>}/> */}
        {/* <div className="player-wrapper-bro">
                            <ReactPlayer
                                className="react-player-bro"
                                url={"https://www.youtube.com/watch?v=toTfc1QdC2U&t=52s"}
                                width="100%"
                                height="100%"
                                controls={true}
                                light={true}
                                playing={false}
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 1 }
                                    }
                                }}
                            />
                        </div>
            </div> */}
        <Route path='/love-mind-free-masterclass' element={<LoveMindTwo />} />
        <Route path='/how-to-meditate-for-beginners' element={<BlogLandingPage1 />} />
        <Route path='/sleep-meditation' element={<BlogLandingPage2 />} />
        <Route path='/healing-meditation' element={<BlogLandingPage3 />} />
        <Route path='/mindfulness-meditation' element={<IntroToMindFulNess />} />

        <Route path='/stress-reduction-techniques' element={<StressReductionTechniques />} />


        {/* <Route path='/blogs-new' element={<BlogListing/>}/> */}
        {/* <Route path='/blogs-new/:id' element={<BlogDetails />}/> */}



        <Route path='/silva-method-spanish-class' element={<SpanishPaymentPage />} />
        <Route path='/join-silva-live-class' element={<ZoomLive />} />

        {/* <Route path='/silva-mind-control' element={<MindControl1/>}/> */}
        <Route path='/the-silva-mind-control-method' element={<SilvaMindControlBook />} />
        <Route path="/silvamethod-daily-meditation-practice" element={<FreeCourse />} />
        <Route path="/free-daily-meditation-practice" element={<FreeCourse />} />

        <Route path="/emotional-intelligence" element={<EmotionalInt />} />
        <Route path="/dashboard/:id" element={<DashboardFree />} />
        <Route path='/thank-you-page' element={<ThankYouPageForm />} />
        <Route path='/thank-you-page2' element={<ThankYouPageForm2 />} />

        {/* <Route path='/science-behind-silva-method' element={<SciencePage />}/> */}

        <Route path='/silva-combo-course' element={<SilvaComboCourse />} />
        {/* <Route path='/emotional-intelligence2' element={<EmotionalInt2 />}/> */}
        <Route path='/manifest-like-a-pro' element={<Manifestation />} />
        <Route path='/upgrade-course' element={<Upgrade />} />
        <Route path='/silva-method-combo-courses' element={<NewComboLanding />} />
        <Route path='/book-an-appointment' element={<AppoinmentIndex />} />

        <Route path="/notMatch" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <EmailNewsLetterModal show={show} handleClose={handleClose} />
    </div>
  );
}

export default App;