import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import ListSectionCombo from './ListSectionCombo'
import ScrollList from './ScrollList'

function ComboBenefitsSection() {
    return (
        <div className='bgnewcombobenefits212'>
            <div className='container d-flex justify-content-center align-items-center flex-column'>
                <div className="oneheading">
                    <h1>Benefits of The Silva Method Combo Program</h1>
                </div>

                <div>
                    <ScrollList />
                </div>

            </div>

        </div>
    )
}

export default ComboBenefitsSection