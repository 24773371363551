import React from "react";
import { useContext } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { GiMeditation } from "react-icons/gi";
import { AuthContext } from "../../../context/AllContext";
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'

function OvercomeSection() {
  const { isMobile, isTablet } = useContext(AuthContext);

  const points = [
    {
      id: 1,
      heading: "Habit Control Technique",
      text: "Gently, as you enter the alpha level of your mind, this special technique helps you to reprogram your mind and eliminate any addiction from its roots, stopping it from becoming an addiction again.",
      icon: "/img/icon/newCombo/yoga-position.png",
    },
    {
      id: 2,
      heading: "Mirror of the Mind Technique",
      text: "If you have tried any tricks but still struggling with addiction then you are at the right place. This popular technique of the Silva Method lets you transform any challenging situation and create anything you desire to have, do, or be.",
      icon: "/img/icon/newCombo/yoga (1).png",
    },
    {
      id: 3,
      heading: "Changing Limiting Belief Exercise",
      text: "Overcoming addictions and poor habits like procrastination requires identifying root causes. The Changing Limiting Belief exercise reverses limiting beliefs, helping you break free from what holds you back.",
      icon: "/img/icon/newCombo/yoga.png",
    },
    {
      id: 4,
      heading: "Silva Method Long and Short Relaxation",
      text: "The Silva Method suggests that human evolution advances through the power of a peaceful mind. Silva's relaxation exercises help you achieve and maintain a deep alpha state, promoting extended relaxation and mental tranquility.",
      icon: "/img/icon/newCombo/meditation.png",
    },
  ];


  const handleScroll= ()=>{
    polyfill()
    const item= document.querySelector('.scrollnewsection');

    scrollIntoView(item, {
      behavior: "smooth",
      block: "end", inline: "nearest"
    });
  }

  return (
    <div className={`theme-dark pb-${isMobile ? "0" : "5"}`}>
      <Container>
        <Row
          className={`align-items-center text-${isTablet ? "center" : "start"} flex-${isMobile?'column-reverse':''}`}
        >
          <Col sm={12} lg={4} className="d-flex justify-content-center">
            <img
              src={
                isMobile
                  ? "/img/courseimgs/combo/silva-method-technique for mobile.webp"
                  : "/img/courseimgs/combo/silva-method-technique copy.webp"
              }
              alt=""
            />
          </Col>
          <Col sm={12} lg={8} className="p-3 mb-3">
            <p
              className="h1 text-white fw-bold"
              style={{
                maxWidth: isMobile ? "100%" : "600px",
                fontSize: isMobile ? "23px" : "",
              }}
            >
              Silva Method Techniques to Overcome Any Addictions
            </p>
            <p
              style={{ maxWidth: isMobile ? "100%" : "700px", color: "white" }}
              className="mt-3"
            >
              Alpha Level Reprogramming- The ultimate solution to reverse any
              habit. The uniqueness of the Silva Method program lies in
              accessing deeper and altered states of mind. This is where
              miracles happen, such as quitting smoking or drinking, losing
              weight, and healing from critical diseases, all through the power
              of dynamic meditation.
            </p>
            <Row>
              {points?.map((point, i) => (
                <Col sm={12} md={6} className="p-3">
                  <Card className="w-100 h-100 p-3 overcomecard">
                    <div className="d-flex">
                      <div>
                        <Image
                          src={point?.icon}
                          alt={point.src}
                          style={{ width: "200px" }}
                        />
                      </div>
                      <div className="ps-3">
                        <p className="h6 fw-bold" style={{ color: "#f1c40f" }}>
                          {point.heading}
                        </p>
                        <p className="text-white">{point.text}</p>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
                  
                  <div className="d-flex justify-content-center align-items-center pt-4 ps-4">
                    <button
                      className="herodiv-becomememberbtn3 px-5"
                      onClick={handleScroll}
                    >
                      {"Learn Techniques"}
                    </button>
                  </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OvercomeSection;
