import React from 'react'
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap'
import { FaClock } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { BsCalendar2Date } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";
import { AuthContext } from '../../context/AllContext';
import MyVerticallyCenteredModal from '../Unstress-landing/SubmitModal';

function BannerBooking4() {

    const { isMobile, isTablet } = useContext(AuthContext);

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymenttwonew2");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth"
            })
        }
    }

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <div className="banner2">
                <div className='container'>
                    {
                        (!isMobile && !isTablet) && (
                            <Row className='justify-content-center align-items-center'>
                                <Col>
                                    <div className='d-flex justify-content-center align-items-center flex-column' style={{
                                        borderRight: "1px solid lightgray",

                                    }}>
                                        <div className='d-flex justify-content-center align-items-centers gap-3'>
                                            <span>
                                                <BsCalendar2Date size={40} color='white' />
                                            </span>
                                            <div className='d-flex justify-content-center align-items-center flex-column'>
                                                <h5 style={{
                                                    margin: "0 10px 0 0",
                                                    color: "white",
                                                    padding: "0",
                                                    fontWeight: "500"

                                                }}
                                                >{"17th-25th February"}</h5>
                                            </div>
                                        </div>



                                    </div>
                                </Col>
                                <Col>
                                    <div className='d-flex justify-content-center align-items-center flex-column' style={{
                                        borderRight: "1px solid lightgray"
                                    }}>
                                        <div className='d-flex justify-content-center align-items-centers gap-3'>
                                            <span>

                                                <IoTimeOutline size={25} color='white' />
                                            </span>
                                            <div className='d-flex justify-content-center align-items-center flex-column'>
                                                <h5 style={{
                                                    margin: "0",
                                                    padding: "0",
                                                    color: "white",
                                                    fontWeight: "500"
                                                }}>UK-6.00 P.M</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='d-flex justify-content-center align-items-center flex-column' style={{
                                        borderRight: "1px solid lightgray"
                                    }}>
                                        <div className='d-flex justify-content-center align-items-centers gap-3'>
                                            <span>

                                                <IoTimeOutline size={25} color='white' />
                                            </span>
                                            <div className='d-flex justify-content-center align-items-center flex-column'>
                                                <h5 style={{
                                                    margin: "0",
                                                    padding: "0",
                                                    color: "white",
                                                    fontWeight: "500"

                                                }}>
                                                    US-( New York) 12:00 Noon

                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <button className='herodiv-becomememberbtn' onClick={() => {
                                            if (isMobile) {
                                                setModalShow(true)
                                            } else {
                                                handleScroll()
                                            }
                                        }}>
                                            Join The Program
                                        </button>

                                    </div>
                                </Col>
                            </Row>
                        )
                    }


                    {
                        (isMobile || isTablet) && (
                            <div>
                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                    <span>
                                        <BsCalendar2Date size={30} color='white' />
                                    </span>
                                    <h5 style={{
                                        margin: "0",
                                        padding: "0",
                                        color: "white",
                                        fontWeight: "500"
                                    }}>{"17th February"}</h5>

                                </div>
                                <div className='d-flex justify-content-center align-items-center gap-2 mt-3'>
                                    <span>
                                        <IoTimeOutline size={30} color='white' />
                                    </span>
                                    <h5 style={{
                                        margin: "0",
                                        padding: "0",
                                        color: "white",
                                        fontWeight: "500"
                                    }}>UK-6:00 P.M</h5>
                                </div>
                                <div className='d-flex justify-content-center align-items-center gap-2 mt-3'>
                                    <span>
                                        <IoTimeOutline size={30} color='white' />
                                    </span>
                                    <h5 style={{
                                        margin: "0",
                                        padding: "0",
                                        color: "white",
                                        fontWeight: "500"
                                    }}>
                                        US-( New York) 12:00 Noon
                                    </h5>
                                </div>
                                <button className='herodiv-becomememberbtn mt-3' onClick={() => {
                                    if (isMobile) {
                                        setModalShow(true)
                                    } else {
                                        handleScroll()
                                    }
                                }}>
                                    Join The Program
                                </button>
                            </div>
                        )
                    }

                </div>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default BannerBooking4