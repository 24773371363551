import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { reqData } from '../../utils/baseUrl';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';

function LiveEventSuccess() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [loading,setLoading] =useState(false);
    const orderId = localStorage.getItem("orderid");
    const gentok = localStorage.getItem("gentok");

    useEffect(()=>{
      if(searchParams){
        const token = searchParams.get("q")
        const token2 = searchParams.get("token")
        const PayerID = searchParams.get("PayerID");

        const email = searchParams.get("email")
        const payment_gateway = searchParams.get("payment_gateway")
        const event_id = searchParams.get("event_id");

        apisuccess(token,token2,PayerID,email,payment_gateway,event_id)
      }
    },[searchParams])

    async function apisuccess(token,token2,PayerID,email,payment_gateway,event_id){
        try{
            setLoading(true)
            const res = await reqData("liveClassPaymentSuccess","POST",{
                token,
                token2,
                PayerID,
                email,
                payment_gateway,
                event_id
            })
            setLoading(false)


            if(res && res.error===false){
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Payment Successful',
                    showConfirmButton: true,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/")
                    }
                })
            }else{
                toast.error(res.error)
            }

        }catch(err){
            console.log(err)
        }
    }


    console.log(orderId,gentok,"ordergentok")


    if(loading){
        return <LoadingSpinner />
    }
  return (
    <div>
       
    </div>
  )
}

export default LiveEventSuccess