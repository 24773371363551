import React from "react";
import Badge from "./Badge";
import HeadingNew from "../../AboutUs/HeadingNew";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import { Col, Row } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";

function AboutBook() {
  const {isMobile} = useContext(AuthContext);
  
  const images = [
    {
      id: 1,
      img: "/img/icon/img-22.webp",
    },
    {
      id: 2,
      img: "/img/icon/img-23.webp",
    },
    {
      id: 3,
      img: "/img/icon/img-24.webp",
    },
    {
      id: 4,
      img: "/img/icon/img-25.webp",
    },
    {
      id: 5,
      img: "/img/icon/img-26.webp",
    },
    {
      id: 6,
      img: "/img/icon/img-27.webp",
    },
  ];

  return (
    <div className="mindfaqmain">
      <div className="container py-5 d-flex flex-column justify-content-center align-items-center">
        <Badge text="The Silva" fontWeight="600" />
        <HeadingNew title={"Mind Control Method"} />
        <HeadingNew title={"A Book That Would Alter Your Life"} small />
        <ParaComp
          data={[
            "After the first publication of The Silva Mind Control Method, over 6M individuals have graduated in Silva mind control technique including countless celebrities, businessmen, organizations, universities, and school students. The mind control method book has not only transformed lives but also helped many to overcome negative emotions including stress, tension, anxiety and even deadly diseases.",
          ]}
          white={false}
          fw={"400"}
        />
        <div className="my-3 d-flex flex-column justify-content-center align-items-center text-center">
        <h5 className="text-black mb-4">Books also available at</h5>
        <Row className="align-items-center">
          {images?.map((image, i) => (
            <Col xl={2} lg={5} md={4} xs={6}>
              <img src={image.img} key={i} style={{ width: "80%" }} />
            </Col>
          ))}
        </Row>
        </div>
      </div>
    </div>
  );
}

export default AboutBook;
