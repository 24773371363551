import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import HeadingNew from "../../AboutUs/HeadingNew";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import { GiBookCover, GiMeditation } from "react-icons/gi";
import { FaMicroblog } from "react-icons/fa";
import { BiPodcast, BiWorld, BiSupport } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { handleRefScroll } from "../../../utils/handleScrollEmotions";
import ShineBtn from "../SilvaComboCourse/ShineBtn";
import { polyfill, scrollIntoView } from "seamless-scroll-polyfill";

function LMHeroSection2({
  title,
  image,
  textSecData,
  btnText,
  onclick,
  white = false,
  colored,
}) {
  const { isMobile, isTablet } = useContext(AuthContext);

  const navigate = useNavigate();


  const handleScrollCombo2Curr2 = () => {
    polyfill()

    const doc = document.querySelector(".includelivecheck");
    setTimeout(() => {
        // if (doc) {
        //     doc.scrollIntoView({
        //         behavior: "smooth",
        //         block: "start", // Align the top of the element with the top of the viewport
        //         inline: "nearest", // Scroll until the element is in view
        //     })
        // }
        scrollIntoView(doc, {
            behavior: "smooth",
            block: "end", inline: "nearest"
        });
    }, 500)


}

  const data = useMemo(() => {
    return [
      {
        title: "Harness Mind-Body Connection:",
        text: "The Silva Method focuses on utilizing the mind-body connection to boost emotional well-being.",
      },
      {
        title: "Access Alpha and Theta States: ",
        text: "Achieve alpha and theta brainwave states through relaxation and meditation to foster calmness.",
      },
      {
        title: "Visualization Techniques:",
        text: " Use powerful visualization techniques to manage stress and emotions effectively.",
      },
      {
        title: "Positive Affirmations:",
        text: "Implement positive affirmations to reinforce a positive mindset.",
      },
      {
        text: "Utilize powerful Silva techniques like Mirror of the Mind and Three Fingers Technique to gain emotional clarity, enhance focus, and better control your emotional responses.",
        title: "Unique Techniques:",
      },
      {
        title: "Emotional Awareness:",
        text: "Develop heightened emotional awareness through mindfulness practices.",
      },
      {
        title: "Healing and Forgiveness:",
        text: "Promote emotional healing and forgiveness, leading to better emotional control.",
      },
    ];
  }, []);

  return (
    <div className="container py-5 d-flex justify-content-end align-items-center">
      <Card
        className="w-100 p-4 p-md-5"
        style={{ borderRadius: "15px", background:"#f7f9f9", }}
      >
       <Card.Body>
       <Row>
          <Col sm={12} md={12} lg={12} className="d-flex align-items-center">
            <div>
              <div className="mb-2">
                <h2
                   style={{
                    color:"black",
                    textAlign:isMobile?"start":"center",
                    fontWeight:"700",
                    lineHeight:isMobile?"1.5rem":"2.5rem",
                    fontSize:isMobile?"1.3rem":"2rem"
                }}
                >
                  {title}

                  <span
                    style={{
                      color: "#543db1",
                      display: "block",
                    }}
                  >
                    {colored}
                  </span>
                </h2>
              </div>
              {/* <div className='p-3 px-4'>
                    <ParaComp
                    data={textSecData}
                    white
                    margin={false}
                    />
              
                </div> */}
              <Row className="justify-content-start align-items-center mt-4">
                {data.map((val, i) => (
                  <Col xs={12} md={6}>
                    <div className="d-flex justify-content-start align-items-start gap-3">
                      <span className="mt-1">
                        <FaCheckCircle color="#5cb85c" size={25} />
                      </span>
                      <div className="d-flex flex-column">
                        <HeadingNew start title={val.title} small white={false} />

                        <ParaComp
                          data={[val.text]}
                          margin={false}
                          white={false}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>

              
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center align-items-center">

<ShineBtn onClick={handleScrollCombo2Curr2} text={"Enroll Now"} />
 
</div>
       </Card.Body>
      
      </Card>
    </div>
  );
}

export default LMHeroSection2;
