import React, { useState } from 'react'

function ZoomMeet() {
  const [zoommeet, setZoomMeet] = useState(false)

 
  return (
    <div>
      <button onClick={() => {
        setZoomMeet(true)
      }}>Join Meeting</button>

    </div>
  )
}

export default ZoomMeet