import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Image, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaStripe, FaLock } from "react-icons/fa";

function Upgrade() {

    const [defaultPayment, setDefaultPayment] = useState("Stripe");
    const [loading, setLoading] = useState(false);

  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Card className="p-3" style={{borderRadius:"15px"}}>
          <Link to={"/"}>
            <Image src="/img/logo/silvamethod-logo.png" className="py-3" alt="silva_logo"/>
          </Link>
          <div>
            <Image src="/img/upgrade.jpg" alt="hero_image" style={{borderRadius:"15px"}}/>
          </div>
          <div className="px-2">
          <div className="rounded-5 border-dark mt-3">
            <Form.Group controlId="formName">
              <Form.Label style={{ fontWeight: "bold" }}>
                Email Address
              </Form.Label>
              <Form.Control type="text" placeholder="Type here..." />
            </Form.Group>
          </div>
          <Form className="mt-2">
            <label htmlFor="emailinput" className="couponinputlabel my-3">
              Your Preferred Payment Mode
            </label>

            <div className="formpaymentradio mb-2">
              <div className="">
                <Form.Check
                  type={"checkbox"}
                  id={`check-api-checkbox`}
                  style={{ marginBottom: "0px" }}
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    isValid
                    checked={defaultPayment === "Stripe"}
                    onChange={() => setDefaultPayment("Stripe")}
                  />
                  <Form.Check.Label className="stripelabel">
                    Pay Via{" "}
                    <span style={{ marginLeft: "3px" }}>
                      <FaStripe color="#655fff" size={40} />
                    </span>
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div>
                <Form.Check type={"checkbox"} id={`paypal`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    isValid
                    checked={defaultPayment === "Paypal"}
                    onChange={() => setDefaultPayment("Paypal")}
                  />
                  <Form.Check.Label className="paypalabel">
                    Pay Via{" "}
                    <span>
                      <Image
                        fluid
                        src={require("../../../images/paypal-logo-preview.png")}
                        style={{ width: "auto", height: "18px" }}
                      />
                    </span>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>
            <div className="secureserver mb-3">
            <span>
              <FaLock size={15} />
            </span>

            <p style={{ fontSize: "0.8rem" }}>
              For your security all orders are processed on a secure server
            </p>
          </div>
          </Form>
          <Button
              variant="warning"
              size="sm"
            //   onClick={handleSubscription}
              className="enroll_btn mb-3"
              style={{
                height: "40px",
                fontWeight: "600",
                fontSize: "1rem",
                width:"100%",
              }}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" variant="dark" />
              ) : (
                "Enroll Now"
              )}
            </Button>
          </div>
        </Card>
      </div>
    </Container>
  );
}

export default Upgrade;
