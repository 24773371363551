import React from 'react';
import "./gallery.css"
import HeadingNew from '../AboutUs/HeadingNew';

const GallerySection = () => {
    return (
        <div>
           <div className='my-5'>
           {/* <HeadingNew
            title={"Image Gallery"}
            
            /> */}
            <h2 className='newpageheader text-center'>Image Gallery</h2>
           </div>
            <div className="grid-container container">
                {doggoData.map((item, index) => (
                    <div key={index}>
                        <img className={`grid-item grid-item-${index + 1}`} src={item.imageUrl} alt="" />
                        <p className='hoverp'>{item.caption}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const doggoData = [
    {
        imageUrl: '/img/galleryimgs/gallery1.jpg',
        caption: 'Dragan After CompanyTraining a Team from JUST of Switzerland.',
    },
    {
        imageUrl: '/img/galleryimgs/gallery2.jpg',
        caption: 'Dragan together with Novak Djokovic Tennis Legend and World no.1 Player in Belgrade 2022, During One Week of Training Of Novak and Jakub Mensik, Dragan’s mental coaching player',
    },
    {
        imageUrl: '/img/galleryimgs/gallery3.jpg',
        caption: 'Dragan before teaching one many Silva Life System classes',
    },
    {
        imageUrl: '/img/galleryimgs/gallery4.jpg',
        caption: 'Dragan together with Jose Silva in Belgrade, 1995, at Jose’s Ultra Course',
    },
    // {
    //     imageUrl: '/img/galleryimgs/gallery5.jpg',
    //     caption: 'Silva Method',
    // },
    {
        imageUrl: '/img/galleryimgs/gallery6.jpg',
        caption: 'Dragan as mental coach and Jakub Mensik after Jakub’s second place in Australian Junior Open Grand Slam as a 16 yr old, 2022',
    },
    {
        imageUrl: '/img/galleryimgs/gallery7.jpg',
        caption: 'Dragan as a mental coach with Ondrej Styler, after Andrew’s winning of Roland Garros Junior Grand Slam in doubles competition, 2019.',
    },
    {
        imageUrl: '/img/galleryimgs/gallery8.jpg',
        caption: 'Dragan teaching one of his numerous company courses',
    },
    {
        imageUrl: '/img/galleryimgs/gallery9.png',
        caption: 'Typical atmosphere at one of Dragan’s public courses',
    },
   
];


export default GallerySection;
