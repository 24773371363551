import React from 'react'
import HabitHeroSection from './HabitHeroSection'

function HabitControl() {
  return (
    <>
       <HabitHeroSection />
    </>
  )
}

export default HabitControl