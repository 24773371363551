import React from 'react'
import logoimg from "../images/newimgs/silvamethod-logo.png"
import { Image } from 'react-bootstrap'
import CircleLoader from "react-spinners/CircleLoader";

function LoadingSpinner() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column spinnerclass" style={{ width: "100%", height: "100vh" }}>
      {/* <h2>Loading...</h2> */}
      <CircleLoader
        color="#6f25af" />

      <Image src={logoimg} className='mt-3' />

    </div>
  )
}

export default LoadingSpinner