import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import HeadingNew from '../../AboutUs/HeadingNew';
import HeadingNew2 from '../../booking/HeadingNew2';
import ParaComp2 from '../../SilvaManifestationProgram/ParaComp2';
import {RiNumber1,RiNumber2,RiNumber3,RiNumber4,} from 'react-icons/ri';

function EnrollmentDetails() {

    const handleScroll = () => {
        const descard = document.querySelector('.includelivecheck');
    
        if (descard) {
          descard.scrollIntoView({
            behavior: "smooth",
          });
        }
      };

    const enrollmentData=[
        {
            id:1,
            title:"Visit Our Website",
            text:"Head to our website to explore our manifestation courses and choose the one that resonates with you.",
            icon: <RiNumber1 color='white' />
        },
        {
            id:2,
            title:"Select Your Course",
            text:"Select the manifestation course that aligns with your goals and aspirations, whether it's manifesting love, money, success, or abundance.",
            icon: <RiNumber2 color='white' />
        },
        {
            id:3,
            title:"Complete Enrollment",
            text:"Complete the enrollment process by providing your details and payment information. Once enrolled, you'll gain immediate access to course materials and live sessions.",
            icon: <RiNumber3 color='white' />
        },
        {
            id:4,
            title:"Start Manifesting",
            text:"Start your manifesting journey today and begin attracting the life of your dreams with Silva Method manifestation courses.",
            icon: <RiNumber4 color='white' />
        }
    ];

  return (
    <div className='parallexabout pt-4'>
         <HeadingNew title={"Enrollment Details"} />
         <VerticalTimeline
        lineColor='rgb(216, 1, 251)'
        >
            {
                enrollmentData?.map((data,i)=>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                iconClassName='circle circle1'
                icon={data?.icon}
                
            >
                <HeadingNew2 title={data?.title} small />
                <ParaComp2

                    data={[
                        data?.text
                    ]}
                    white={false}
                />

            </VerticalTimelineElement>
                )
            }
        </VerticalTimeline>
        <div className='container d-flex justify-content-center align-items-center'>
        <button className='herodiv-becomememberbtn2 my-3 p-3' onClick={handleScroll}>Enroll Now and Unlock Your Manifesting Potential!</button>
        </div>
    </div>
  )
}

export default EnrollmentDetails