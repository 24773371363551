import React, { useContext } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap'
import Badge from './Badge'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { AuthContext } from '../../../context/AllContext'

function ThetaStateMind() {

    const { isDesktopOrLaptop,isBigScreen,isTablet, isMobile,}= useContext(AuthContext);

  return (
    <div style={{backgroundImage:(isMobile || isTablet)?`url('/img/bg/img-for-mobile-5.webp')`:`url('/img/bg/img-33.webp')`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPositionX: isDesktopOrLaptop?"30%":"",backgroundPositionY: (isMobile || isTablet)?"100%":""}}>
        <div className="container d-flex flex-column justify-content-center align-items-center">
            <div>
                <Row>
                    <Col sm={12} md={12} lg={6} className='d-flex flex-column justify-content-center align-items-start' style={{padding:isTablet?"50px 15px 200px":isMobile?"50px 15px 0px":"300px 15px"}}>
                    <div className='my-3 px-3 d-flex flex-column justify-content-center align-items-start'>
                    <Badge text={"Theta"} />
                    <div className='mt-3'>
                    <HeadingNew title={"State of Mind"} white/>
                    </div>
                    <ParaComp
                        data={[
                            "is the complete sleeping and dreaming state. The human brain produces theta waves when we usually drift off to deep sleep. It is also associated with a state of mind after you just wake up from sleep. Surprisingly, using da silva mind control method, you can also enter the theta state even when you are conscious and awake but in a very deeply composed and tranquil state of mind."
                        ]}
                        white={true}
                        fw={"500"}
                    />
                    </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} className='d-flex flex-column justify-content-center align-items-start' style={{padding:"300px 0px"}}>
                   
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default ThetaStateMind