import React, { useContext } from "react";
import { Badge, Button, Card, ProgressBar } from "react-bootstrap";
import { GrFormNext } from "react-icons/gr";
import { BsFillPersonFill, BsFillCalendarCheckFill } from "react-icons/bs";
import moment from "moment";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AllContext";
import CustomCard from "../../../components/SingleShop/Card";

function SingleClassCards({ data, index }) {
  const { isMobile } = useContext(AuthContext);

  //console.log(data);
  return (
    <div className="classescard mt-3">
      <Link to={`/events/livedetails/${data && data.course_id}`}>
       
          <CustomCard
              index={index}
              ins_image={data?.photo || "../images/no-photo-icon-22.png"}
              title={data?.course_name}
              ins_name={data?.instructor_name}
              date={`${moment(data?.start_date).format("Do MMM")}- ${moment(data?.end_date).format("Do MMM YYYY")}`}
              language={data?.language}
              mode={data?.delivery_method}
              borderRadiusAll
              
            />
      </Link>
    </div>
  );
}

export default SingleClassCards;
