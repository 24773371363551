import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../context/AllContext';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import HomeHeading from '../Home/Home/HomeHeading';
import HeadingNew from '../AboutUs/HeadingNew';
import SingleClassCards from '../Home/Home/SingleClassCard';
import Slider from 'react-slick';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";


function HomeEvents({ scrollTo }) {
    const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } = useContext(AuthContext);
    const [classes, setClasses] = useState([]);
    const sliderRef = useRef();


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
        arrows: false
    };



    const getData = async () => {
        const myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");

        const formData = new FormData();
        formData.append("start_index", "0");
        formData.append("no_of_records", "100");
        const country = localStorage.getItem("countryname");
        const currency = localStorage.getItem("currency")




        if (country) {
            myHeaders.append("country", country.toString())
        }

        if (currency) {
            myHeaders.append("currency", currency.toString())
        }
        const options = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        };
        const res = await fetch(
            `https://silvamethod.com/manage/api/home`,
            options
        );
        const data = await res.json();
        // console.log(data.data.classes, "Classess");
        setClasses(data.data.classes);
    };



    useEffect(() => {
        getData();
    }, []);

    return (
        <div className='container homeeventslider'>
            <HeadingNew title={"Upcoming Silva Method"} />
            <div className='mt-2'>
                <HeadingNew colored={"Live & Online Classes"} />
            </div>
            {/* <HomeHeading title="Upcoming Silva Method" colored="Live & Online" title2="Classes" /> */}
            <h5 className="mt-3 robotofont text-center" style={{
                lineHeight: isMobile ? "1.3rem" : "1.5rem",
                fontSize: isMobile ? "0.9rem" : "1rem"
            }}>
                Over 12 million people in 110 countries have experienced what Silva
                Method Meditation can do.
            </h5>
            <div>
                <div className='d-flex justify-content-end align-items-center gap-3' style={{
                    marginRight: "15px"
                }}>
                    <span style={{
                        cursor: "pointer"
                    }}
                        onClick={sliderRef?.current?.slickPrev}
                    >
                        <IoIosArrowBack color='black' size={25} />
                    </span>
                    <span style={{
                        cursor: "pointer"
                    }}
                        onClick={sliderRef?.current?.slickNext}
                    >
                        <IoIosArrowForward color='black' size={25} />
                    </span>



                </div>
                <Slider {...settings} ref={sliderRef}>

                    {classes &&
                        classes.map((cl, i) => (
                            <SingleClassCards key={cl.course_id} data={cl} index={i} />
                        ))}

                </Slider>
            </div>

        </div>
    )
}

export default HomeEvents