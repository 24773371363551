import React, { useMemo } from 'react'
import Slider from 'react-slick';

import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import "./swiperslider.css"
import { useState } from 'react';
import { Card, Col, Image, ProgressBar, Row } from 'react-bootstrap';

function SixthSlider() {


    const { isDesktopOrLaptop, isMobile } = useContext(AuthContext);
    const [activeSlide, setActiveSlide] = useState(0);


    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        arrows:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        cssEase: "linear",
        centerMode: false,
        prevArrow: <GrFormPrevious color='black' />,
        nextArrow: <GrFormNext color='black' />,
    };


    const reviews = useMemo(() => {
        return [
            {
                para: "Complete the form and click 'Submit.'",
                img: require("../../../images/adimgs/sliderimg1.png"),
                progress:30
            },
            {
                para: "Await the confirmation email.",
                img: require("../../../images/adimgs/sliderimg2.png"),
                progress:60
            },
            {
                para: "Access the scheduled call using the provided details in your email.",
                img: require("../../../images/adimgs/sliderimg3.png"),
                progress:100
            }
        ]
    }, [])




    return (
        <div className='pt-4 newadslider container slicker py-5' >
            {/* <div className="reviewscontainer">
                <div className='row justify-content-center'>
              
                    <div style={{ width: isDesktopOrLaptop ? "100%" : "95%" }} className='reviewslider newslider'>
                        <Slider {...settings}>
                            {
                                reviews.map((val, i) => (
                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                        <FifthSliderSingle val={val} />
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div> */}

            <h2 className='text-center my-4'>
            Book Free Consultation in 3 Simple Steps
            </h2>

            <Slider {...settings}>
                {
                    reviews.map((val, i) => (
                       <div>
                         <Card style={{
                            width:isMobile?"90%":"60%",
                            margin:"0 auto",
                            padding:30,
                            borderRadius:"20px"
                        }}>
                              <div className='ribbonnew'>
                                 {i+1}
                                </div>
                           <Card.Body>
                           {/* <Card.Img
                            src='https://images.livspace-cdn.com/w:1920/plain/https://d3ai42rl8fy79o.cloudfront.net/galactus/templates/img_web_book_livspace.jpg.1597906668371'
                            style={{
                                width:"100%",
                                borderRadius:"20px"
                            }}
                             /> */}
                             <Card.Title className='text-dark mt-3' style={{
                                fontWeight:"500",
                                fontSize:"1.5rem"
                             }}>
                                Step {i+1}

                             </Card.Title>
                             <Card.Subtitle className='text-dark'>
                            {val.para}

                             </Card.Subtitle>
                             <ProgressBar style={{
                                height:30,
                                marginTop:"20px",
                                borderRadius:"20px"
                             }} variant='success' now={val.progress} label={`${val.progress}%`} />
                           </Card.Body>
                          
                        </Card>
                       </div>
                     
                    ))
                }
            </Slider>


        </div>
    )
}

export default SixthSlider
