import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Slider from 'react-slick'
import forbes from "../../../images/forbes.png";
import nytimes from "../../../images/nytimes.png";
import usatoday from "../../../images/usatoday.png";
import washington from "../../../images/washingtonpost.png"

function Featured2() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1279 })

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isDesktopOrLaptop?5:isTablet?3:2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };
  return (

     <div className="not-slide-icon my-4">
       <Slider {...settings}>
        <div>
          <img src={forbes} width={80} height={40} style={{objectFit:"contain"}} alt={forbes}/>
        </div>
        <div>
          <img src={nytimes} width={80} height={40} style={{objectFit:"contain"}} alt={nytimes}/>
        </div>
        <div>
          <img src={washington} width={80} height={40} style={{objectFit:"contain"}} alt={washington}/>
        </div>
        <div>
          <img src={usatoday} width={80} height={40} style={{objectFit:"contain"}} alt={usatoday}/>
        </div>
        <div>
          <img src={nytimes} width={80} height={40} style={{objectFit:"contain"}} alt={nytimes}/>
        </div>
        <div>
          <img src={washington} width={80} height={40} style={{objectFit:"contain"}} alt={washington}/>
        </div>
      
      </Slider>
     </div>
    // </div>

  )
}

export default Featured2

{/* <Slider {...settings}> */ }


{/* </Slider> */ }
    // </div>