import React, { useContext, useMemo, useState } from 'react';
import HeadingNew from '../AboutUs/HeadingNew';
import AppointmentFormModal from './DownloadModal';
import { AuthContext } from '../../context/AllContext';
import { FaFileDownload } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function GotoDashboard() {

    const {isMobile} = useContext(AuthContext);

    const navigate = useNavigate()



    const styles = useMemo(()=>{

        return {
            bannerContainer: {
        
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto',
                padding:isMobile?"30px 20px":"40px 20px",
                // backgroundColor: '#4CAF50',
                background: " linear-gradient(to right, rgb(238 237 255), rgb(255 248 249))",
                color: 'white',
        
                textAlign: 'center',
                borderRadius: isMobile?"10px":'15px',
                marginTop: isMobile?"0px":"70px",
            },
            title: {
                fontSize: '36px',
                marginBottom: '20px',
        
            },
            button: {
                fontSize: '18px',
                padding: '10px 20px',
                backgroundColor: '#ffffff',
                color: '#4CAF50',
                border: 'none',
                borderRadius: '5px',
                marginTop: "20px",
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
            }
        }
    },[isMobile]);

    const handleGo = ()=>{
        navigate("/today")
    }

    const token = localStorage.getItem("token");

    if(!token){
        return null
    }

    return (
        <div className='container my-5'>
             <div style={styles.bannerContainer}>
            {/* <h1 style={styles.title}>Your Banner Title</h1> */}
            {/* <img
                  src="/img/bg/Image20240829111956.jpg"
                  alt="Banner"
                  className="img-fluid"
                  style={{
                    width: '96%',
                    borderRadius:"10px",
                    marginBottom:isMobile?"20px":"25px",
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle drop shadow
                  }}
                /> */}
            <HeadingNew title={"Go to dashboard for exclusive "} colored={"Silva Method Content"} small />
            <button onClick={handleGo} className='cssbuttons-io-button3 mt-4'>
         
                Go to dashboard
             
            </button>
            
        </div>
        </div>
    );
}



export default GotoDashboard;
