import React, { useState } from "react";
import { AiFillMail } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive'
import { reqData } from "../../utils/baseUrl";
import { toast } from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import QrCodeSection from "../../pages/Home/Home/Homestudycourses/QrCodeSection";
import WhatsAppQR from "../../pages/Home/Home/Homestudycourses/WhatsAppQR";
import HeadingNew from "../../pages/AboutUs/HeadingNew";


function Newsletter({scrollTo}) {
  const [email, setEmail] = useState("")
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 })
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 })
  const [loading, setLoading] = useState(false);



  const handleSubscribe = async () => {
    const matchedemail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

    if (!matchedemail) {
      toast.error("Please enter a valid email")
      return
    }

    setLoading(true)

    const res = await reqData("newsletter", "POST", {
      email
    })

    setLoading(false)

    if (res && res.error === false) {
      toast.success(res.messages)
      setEmail("")
    } else {
      toast.error(res.messages)
    }


  }

  return (
    <>
     <div className="newsletter-container" style={{ margin: (isMobile) ? "50px 0 50px 0":"50px 0 50px 0" }}>
      {/* <HeadingNew title={"Stay Updated: "} colored={"Explore Our Latest News, Blogs, Podcasts, and Success Stories."} small/> */}
      <div style={{ background:"linear-gradient(to right, #fbc7d4, #9796f0)",display: 'grid', gridTemplateColumns: isDesktopOrLaptop ? '1fr 1fr 1fr' : '1fr',  gap:"10px",padding: '50px 30px', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: (isMobile || isTablet) ? '90%' : '60%', margin: 'auto', backgroundColor: 'white', boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",  borderRadius: '5px', height: (isMobile || isTablet) && 'auto' }}>
        <h3 className="text-start" style={{
          marginRight:"15px"
        }}>Get latest courses updates by signing up</h3>
        <input type="text" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', height: '55px', borderRadius: '25px',padding:"20px", border: '0.5px solid gray' }} />
        <button
        className="cssbuttons-io-button3"
          onClick={handleSubscribe} disabled={loading}
        >
          {
            loading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              <div>
                <AiFillMail style={{ fontSize: '25px', color: 'white' }} /> Subscribe
              </div>
            )
          }
        </button>
      </div>

    </div>
    {/* <QrCodeSection /> */}
    {/* <WhatsAppQR/> */}
    </>
  );
}

export default Newsletter;
