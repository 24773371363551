import React from 'react'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { Image } from 'react-bootstrap'
import HeadingNew from '../AboutUs/HeadingNew'

function AboutUsPionarMind() {
  return (
    <div className='my-5'>
        <HeadingNew title={"Life-Changing Impact:"} colored={"The Silva Method’s Revolutionary Practices"} />
        <h4 className='text-center mt-4' style={{fontWeight:"700",fontFamily:"Poppins"}}>
          Behind the Revolutionary Discovery
        </h4>
         <div className="paradivabout">
         <ParaComp
         white={false}
        data={[
            "As Silva Method continues to inspire and transform over 12 million lives, one captivating narrative emerges from a pharmacy college. Here, a professor imparted The Silva Method techniques to his students, resulting in an extraordinary upswing in their academic performance. Surprisingly, they achieved higher grades across all courses with minimum effort, accompanied by a heightened sense of relaxation."
        ]}
        />
        <div className='d-flex justify-content-center align-items-center'>
        <Image src={require("../../images/detailspage/img-3.png")} />
        </div>
         </div>
      

    </div>
  )
}

export default AboutUsPionarMind