import React from 'react'
import { useContext } from 'react';
import { Card } from 'react-bootstrap'
import { FaCheckCircle } from "react-icons/fa";
import { AuthContext } from '../../context/AllContext';

function LearnTo({ img, title, subtitle, para, list }) {
    const {isMobile} = useContext(AuthContext)
    return (
        <Card style={{
            width:isMobile?"100%":"93%",
            border:"none",
            borderRadius:"10px",
            marginLeft:"auto",
        }}>
            <Card.Img src={img} style={{
                borderTopRightRadius:"10px",
                borderTopLeftRadius:"10px",
                // height:"300px",
                // objectFit:"cover"
            }} />
            <Card.Body>
                <h3 className='text-dark my-2' style={{
                    fontSize:"1.5rem",
                    fontWeight:"bolder"
                }}>{title}</h3>
                <Card.Subtitle className='text-dark fw-bold my-2'>{subtitle}</Card.Subtitle>
                <Card.Text style={{
                    fontSize:"1rem"
                }} className='my-4'>
                    {para}
                </Card.Text>

                <h4 className='text-dark fw-bold'>
                    Learn To :
                </h4>
                {
                    list.map((val, i) => (
                        <div className="d-flex" key={i}>
                            <span className='p-2'><FaCheckCircle color='#510286' size={25} /></span>
                            <p className='text-start p-1' style={{ fontSize: "1rem",fontWeight:"600" }}>
                                {val}
                            </p>
                        </div>
                    ))
                }

            </Card.Body>
        </Card>
    )
}

export default LearnTo