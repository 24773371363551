import React, { useContext } from "react";
import CustomFooter from "../HomeFooter/Footer";
import ThirdHeroSection from "./ThirdHeroSection";
import SecondHeroSection from "./SecondHeroSection";
import FourthHeroSection from "./FourthHeroSection";
import { AuthContext } from "../../../../context/AllContext";
import BreakThroughs from "../../../silvamembership/BreakThroughs";
import AboutSilvaMethod from "../../../AboutUs/AboutSilvaMethod";
import Testimonials from "../../../../components/featured/Reviews";
import HomeClasses from "../HomeClasses";
import ProductSlide from "../../../Store/products/ProductSlide";
import HomeCountrySection from "../../../../components/CountrySection/HomeCountrySection";
import AudioBlogs from "../../../Blogs/AudioBlog";
import BecomeMember from "../BecomeMember";
import Newsletter from "../../../../components/Newsletter/Newsletter";
import HomeHeader from "../HomeHeader/HomeHeader";
import Homeherodiv from "../HomeHeroSection/Homeherodiv";
import Featured from "../../../../components/featured/Featured";
import { useState } from "react";
// import SaleModal from "./SaleModal";
import ComboPromoteBanner from "./ComboPromoteBanner";
import SaleStrip from "../../../Contact/SaleStrip";
import AppointmentSection from "./AppointmentSection";
import DraganBanner2 from "./DraganBanner2";
import FeaturedNew from "../../../../components/featured/FeaturedNew";
import NewComboBanner from "../../../LandingPages/ComboCourse/NewComboBanner";
import ImportantNotice from "./ImportantNotice";


function HomeStudyCourses() {
  const { isMobile } = useContext(AuthContext);
  const [show, setShow] = useState(false);
 
  const onHide = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  };

  // console.log(show,'show')

  return (
    <>
    <div className="heightmeasure">
      <HomeHeader />   
      {/* <ImportantNotice/>    */}
      <Homeherodiv />
      {/* <img src="/img/sale_banner.jpg" style={{width:'100%'}}/> */}

      <Featured />
      {/* <FeaturedNew/> */}
      {/* <Commemoration /> */}

      {/* {isMobile && <DraganBanner />} */}
      {/* {userData && userData?.live_class_purchased ==="Yes"? <LiveClassZoom/>:<ComboPromoteBanner/>} */}
      <ComboPromoteBanner/>
      {/* <NewComboBanner/> */}
      {
        isMobile && <AppointmentSection/>
      }
      {isMobile && <HomeClasses /> }
      {isMobile && <FourthHeroSection />}
      {isMobile && (
        <div className="my-5">
          <SecondHeroSection />
        </div>
      )}
      {!isMobile && <SecondHeroSection />}
      {!isMobile && <AppointmentSection/>}

      {/* {!isMobile && <DraganBanner />} */}

      {!isMobile && <HomeClasses /> }
      {!isMobile && <FourthHeroSection />}

      {/* <DraganBanner /> */}
      <DraganBanner2/>
      {/* <SaleStrip/> */}

      <ThirdHeroSection />

      <ProductSlide />


      <BreakThroughs />
      
      <AboutSilvaMethod />

      

      <Testimonials />


  
      <HomeCountrySection />
      <AudioBlogs />
      <BecomeMember />
      <Newsletter />
      {/* <BlackFridayBanner /> */}
      <CustomFooter />
    </div>
      {/* <SaleModal show={show} onHide={onHide} handleShow={handleShow}/> */}
      {/* <SaleModal2 show={show} onHide={onHide} handleShow={handleShow}/> */}
    </>
  );
}

export default HomeStudyCourses;
