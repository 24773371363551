import React from 'react'
import FancyText from '@carefully-coded/react-text-gradient';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import Button from '../One/Button';

function LoveMindCTA({maintitle,fancytitle,subTitle,btnText,onClick=()=>{}}) {
    const { isMobile } = useContext(AuthContext);
    const handleScroll = ()=>{
        const doc = document.querySelector(".lovemindformscroll");
        if(doc){
            doc.scrollIntoView({
                behavior:"smooth"
            })
        }
    }
    return (
        <div className='container d-flex justify-content-center align-items-center flex-column py-5' style={{
            background: "#eff2ff",
            borderRadius: 20
        }}>
            <HeadingNew title={maintitle} />
            <FancyText gradient={{ from: '#6764ec', to: '#8540e7', type: 'linear' }}
                animate
                animateDuration={1000}
                style={{
                    fontSize: isMobile?25:50,
                    fontWeight: "800",
                    textAlign:"center"
                }}>
               {fancytitle}

            </FancyText>
            <FancyText gradient={{ from: '#8019da', to: '#940658', type: 'linear' }}
                animate
                animateDuration={1000}
                style={{
                    fontSize: isMobile?20:30,
                    fontWeight: "800",
                    textAlign:"center"
                }}>
               {subTitle}

            </FancyText>
             <div className='mt-3'>
            <Button text={btnText} onClick={handleScroll} />
             </div>
            <div className='lovemindformscroll'>

            </div>
         

        </div>
    )
}

export default LoveMindCTA