import React, { useState } from "react";
import TFTHero from "./TFTHero";
import SilvaInformationCards from "../SilvaInformationCards";
import TFTHero2 from "./TFTHero2";
import { BiTargetLock } from "react-icons/bi";
import { GiBrain } from "react-icons/gi";
import { FaInfo } from "react-icons/fa";
import { TbMessages } from "react-icons/tb";
import BenifitsCards from "../GlassofWaterBenifits";
import PaymentSectionTwo from "../../newbooking/PaymentSectionTwo";
import VideoTableSection from "../../newbooking/VideoTableSection";
import BonusCard from "../../Draganpages/BonusCard";
import AboutInstructorSection2 from "../../newbooking/AboutInstructorSection2";
import DraganTestimonials from "../../Draganpages/DraganTestimonials";
import TFTFaqs from "./TFTFaqs";
import BookingFooter from "../../newbooking/BookingFooter";
import JoinWhatsAppModal from "../../Draganpages/ModalPage";

function TFTLanding() {
  const benifitsData = [
    {
      icon: <BiTargetLock color="#6a3093" size={70} />,
      title: "Increase Focus",
      text: "This method helps you concentrate better by making your mind calm, cutting down on distractions, and encouraging mindfulness. Doing it regularly clears your thoughts, develops a focused awareness, and also creates an ideal mental state for staying focused.",
    },
    {
      icon: <GiBrain color="#6a3093" size={70} />,
      title: "Improve memory",
      text: "Silva Three Fingers Technique boosts memory by soothing the mind, minimizing stress, and sharpening concentration. A tranquil mind processes information more efficiently, which leads to improved memory retention and recall.",
    },
    {
      icon: <FaInfo color="#6a3093" size={70} />,
      title: "Retrieve information",
      text: "This easy but powerful technique helps you to remember things better and find lost stuff by making your mind calm and focused. The Three Fingers technique not only boosts recall but also taps into intuition. By connecting with your instincts, you'll find that lost object more quickly.",
    },
    {
      icon: <TbMessages color="#6a3093" size={70} />,
      title: "Better Communication",
      text: "The Three Fingers technique improves communication by sharpening memory. It calms the mind, reduces stress, and enhances focus, helping you remember details during conversations. This leads to clearer and more effective communication.",
    },
  ];

  const faqsData = [
    {
      id: 1,
      heading: "Does the Silva Method actually work?",
      body: "In 1966, Jose Silva unveiled his groundbreaking discoveries through The Silva Method, a culmination of his extensive research. Over more than five decades, this technique has positively impacted over 12 million individuals spanning 110 countries, enabling them to harness their mental capacities and elevate multiple facets of their existence.",
    },
    {
      id: 2,
      heading: "How often should one practice The Three Fingers Technique?",
      body: "For optimal results, practice the technique regularly. Consistent and active utilization is key to improving focus, memory, and overall mental performance",
    },
    {
        id: 3,
        heading: "Who can benefit from the Silva Three Fingers Technique?",
        body: "Whether you're a student, a working professional, a homemaker, a business owner, a corporate executive, or even a stockbroker, the Silva Three Fingers Technique offers a straightforward and impactful approach to enhancing memory, focus, and mental performance.",
      },
      {
        id: 4,
        heading: "What are the benefits of regular meditation?",
        body: "Regular meditation offers a range of benefits, including stress reduction, improved focus, enhanced emotional well-being, better sleep, and increased self-awareness. Scientific studies have also linked meditation to physical health benefits, such as lower blood pressure and improved immune function.",
      },
  ];

  const [show, setShow] = useState(false);
  
  const onHide = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(rgba(226, 234, 255, 0) 0%, rgb(226, 234, 255) 100%)",
      }}
    >
      <TFTHero />
      <SilvaInformationCards />
      <TFTHero2 />
      <BenifitsCards data={benifitsData} title={'Benefits of The Silva Three Fingers Technique'}/>
      <PaymentSectionTwo />
      <VideoTableSection />
      <BonusCard />
      <AboutInstructorSection2 />
      <DraganTestimonials />
      <TFTFaqs data={faqsData}/>
      <BookingFooter />
      <JoinWhatsAppModal show={show} onHide={onHide} handleShow={handleShow}  />
    </div>
  );
}

export default TFTLanding;
