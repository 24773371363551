import React from 'react';
import Masonry from 'react-masonry-css';
import './Testimonial.css';
import TestimonialCard from './TestimonialCard';
import HeadingNew from '../AboutUs/HeadingNew';

const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
};

const testimonials = [
    {
        type: 'text',
        text: '„Dragan is fantastic at what he is doing. Together with Dragan we are engaged in unleashing the human potential throughout the world.“',
        image: 'https://draganv.com/wp-content/uploads/2016/11/jan-muhlfeit2.png',
        name: 'Jan Muelfeit',
        designation: 'Global Strategist/Coach/Mentor/Ret. Chairman Europe at Microsoft Corporation',
    },
    {
        type: "text",
        text: "I have known Dragan Vujovic since 2002 or nearly 15 years. Dragan taught me stress reduction techniques that I can suggest without a doubt, saved or at least extended my life a significant number of years.Cardiovascular disease is the number one cause of death in the United States. And stress is a major contributor to this disease.  Perhaps of equal importance, is Dragan’s ability to provide positivity in some of the more dire circumstances and truly is what makes him so special. And, his ability to extend the most appropriate tools to maintain that positivity separates him from many of the world’s leading mental trainers.To be able to retain and re-apply Dragan’s techniques on demand, and in the now moment has helped me realize many goals. I would wholeheartedly recommend to anyone struggling with self-limiting habits, high stress levels and low self-esteem, the investment of a small amount of personal time and get to know Dragan and his wonderful mental training techniques.",
        image: "https://draganv.com/wp-content/uploads/2016/11/Terry4-orez.jpg",
        name: "Terrence Ellifritz",
        designation: "CEO Ellifritz & Associates LLC, Strategic Marketing Services"
    },
    // Add more testimonials as needed
    {
        type: "text",
        text: "I started a 2 week private course with Dragan as an anxious, negative, and lonely guy with very low self-esteem. The problem I was focused on was that I struggled with relationships and interacting with women I was attracted to. He taught me his mental techniques/tools that totally changed my outlook and perception of my life and led me to take responsibility and gain control over it. He went above and beyond and took me around the beautiful city of Prague, as I had traveled there by myself, and also Skyped me when I got back to Chicago to check in and see how things were going. Just two months after the course, I met the love of my life, and we recently got engaged! Taking this course was truly the best decision I have ever made and I will be forever grateful to Dragan!",
        image: "https://draganv.com/wp-content/uploads/2016/11/Mark-Ninyo.jpg",
        name: "Mark Ninyo",
        designation: "Financial Analyst, Chicago, USA"
    },
    {
        type: "text",
        text: "I would have never imagined how strongly my life could be influenced, stimulated and enlightened. Meeting Dragan that day on the beach during my holiday in Egypt almost 3 years ago has changed everything; the way how I see and how I treat the world, myself and the others. Being equipped with loads of theories is one thing, but having them integrated in our daily lives with the help of Dragan as a personal trainer and friend gives a totally different life perspective.",
        image: "https://draganv.com/wp-content/uploads/2016/11/final-4947web-1.jpg",
        name: "Adam Stastny",
        designation: "Keyence International, Manager"
    },
    {
        type: "text",
        text: "For almost fifteen years when I lived in Prague I was mentored regularly and successfully by Dragan Vujovic, a dedicated professional in human potential development. It is a pleasure to endorse and recommend any and all of Dragan’s education programs.",
        image: "https://draganv.com/wp-content/uploads/2016/11/Peter-Magurean-III.jpg",
        name: "Peter Magurean III",
        designation: "Enterpreneur and Philantropist, Miami Beach, Florida, USA"
    },
    {
        type: 'video',
        video: 'https://vz-47e4ab86-ce6.b-cdn.net/be2a5a1c-4e14-4144-b787-9bf333870074/playlist.m3u8',
        name: 'Nigel Atkins',
        designation: 'Developer, University professor, Paris, France',
    },
    {
        type: "text",
        text: "The training sessions were interactive and very informative. Dragan was easy to  understand and did a great job of conveying the information. He was also easily  accessible via email.",
        name: "Tanya Sims",
        image: "/img/reviews/tanya.png",
        designation: "Literacy Specialist , Klein ISD, Spring, TX, USA "
    },
    {
        type: "text",
        text: "I highly recommend Dragan and his program to all Klein ISD employees. Dragan is very easy to talk to and has a great program to help anybody improve their life and begin to realize a life without stress.",
        name: "Chad Greene",
        image: "/img/reviews/chad.png",
        designation: "Manager – Application Support Services Klein ISD, Information Technology –  Teaching & Learning Team"
    },
    {
        type: "text",
        text: "Dragan's communication throughout our program was extremely reliable and helpful. I knew that if a time was set to meet by Skype that he would be there 5 minutes early every time.  His approach to stress and positive thinking was delivered to us in a way that made all of us realize that our lives can be enjoyed with much less stress.  He taught us how to manage in a way that was easy to understand, beneficial, and enjoyable.  He has a nice mixture of relating to his own personal experiences to the many things he has learned from others in life. ",
        name: "Tony Scherrman",
        image: "/img/reviews/tony.png",
        designation: "Brill Counselor"
    },
    {
        type: "text",
        text: "Mr. Dragan V You gave me a new perspective and some concrete steps to take to change my thinking and response to these issues. I appreciate the time and energy you invested in me and the other group members. You brought a fresh perspective to issues we considered problems and gave us creative ways to deal with these issues. Thank you. ",
        name: "Tammy Koonce",
        image: "/img/reviews/tammy.png",
        designation: "Spring Texas"
    },
    {
        type: "text",
        text: "Dragan’s delivery method of instructional videos and practice session videos is very effective. He uses real-life examples as he teaches his tools and then gives the learner an opportunity for guided practice. Dragan clearly explains how different life will “look” when using his tools, making it easy for the learner to see the result of applying the tools.",
        name: "Annaliese Altendorf ",
        image: "/img/reviews/annalisa.png",
        designation: "P.E. teacher, Kuehnle Elementary, Klein I.S.D."
    },
    {
        type: "text",
        text: "Working with Dragan is enjoyable and entertaining. He has a wealth of knowledge about the mind and more that I wish we had more time to explore. I feel that his explanations  and connections are relevant and help the participants to understand not only how to use these tools, but also the importance of using them. He sincerely wants to improve the quality and quantity of life of his participants. ",
        name: "Colleen Horst",
        image: "/img/reviews/coleen.png",
        designation: "Athens SD, Ohio, USA"
    },

    {
        type: "text",
        text: "Dragan Vujovic ia an amazing human being and I recommend him to everyone as a life coach. He is non-judgemental, caring, helpful, and he is willing to take time to find your stressors and individualize instruction to fit your personal life. ",
        name: "Jane Morris-Maynard ",
        image: "/img/reviews/jane.png",
        designation: "English Language Arts Athens SD, Ohio, USA"
    },
    {
        type: "text",
        text: "Using the techniques provided by Dragan, I am now  able to transform the worry in a positive direction.  I also enjoy waking every morning and  sharing my gratitude for the day.  This very simple gesture puts my day in perspective and allows me to embrace the day in its fullest. I enjoyed Dragan's approach to the sessions.",
        name: "Susan Matters",
        image: "/img/reviews/susan.png",
        designation: "Athens SD, Ohio, USA"
    },
    {
        type: "text",
        text: " “After sharing a substantial amount of professional discourse with Dragan over the last quarter, both telephonically and via video-conferencing, I can state without hesitation that he is 100% committed to mutual cooperation, respect and communication across all aspects of his mentoring and consultation. That being said, as a mental coach, Dragan’s services are superior to any others I have leveraged or engaged with in either my personal or professional life. For those out there looking to better their lives through improved stress management, I highly recommend connecting with Dragan as he will truly make a difference in the quality of your life.”",
        name: "Melissa Bingham",
        image: "/img/reviews/melissa.png",
        designation: "Grade 3, Gladstone Street School Rhode Island "
    },
    // {

    // }
    // Add more testimonials as needed
];
// Testimonials.js


const Testimonials = () => {
    return (
        <div className='container'>
            <h2 className='newpageheader text-center mb-5'>Transforming Doubters Into Believers</h2>
            {/* <HeadingNew title={"Transforming Doubters into"} colored={"Believers"} /> */}
            <div className='my-4'>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard key={index} data={testimonial} />
                    ))}
                </Masonry>

            </div>

        </div>
    );
};

export default Testimonials;
