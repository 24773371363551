import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useContext } from "react";
import { AuthContext } from "../../context/AllContext";

function LandingOneHero() {
  const { isMobile,isTablet } = useContext(AuthContext);

  const handleScroll = () => {
    const cardscroll = document.querySelector(".paymenttwonew");

    if (cardscroll) {
      cardscroll.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <section className={`hero-sectionone container py-${(isMobile)?3:5}`}>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={7} className={`mb-${(isMobile || isTablet)?2:0}`}>
            <div
              className="content-container"
              style={{
                width: (isMobile ||  isTablet) ? "97%" : "90%",
                marginLeft: (isMobile ||  isTablet) ? "0px" : "40px",
                marginTop: (isMobile ||  isTablet) ? "30px" : "0px",
                textAlign:(isMobile ||  isTablet)? "center":"left",
              }}
            >
              <h2 className="newpageheader">
                Silva Life System Live Online Masterclass
              </h2>

              <h4
                style={{
                  fontWeight: "600",
                  fontSize: "25px",
                  color: "black",
                  marginTop: "20px",
          
                }}
              >
                Conquer Insomnia with the Silva Sleep Control Technique
              </h4>

              <p
                className="normalpara"
              >
                In the fast-paced world we live in, where time is precious and
                life keeps us on our toes, there's something extraordinary we
                might not fully appreciate – SLEEP. It's like the best natural
                gift. It is more than just a break for our tired bodies; it's a
                magical journey that does wonders for our well-being. When we
                close our eyes at night, it's not just a pause button; it's a
                chance for our body and mind to recharge. Haunted by restless
                nights? Quit Medicines and choose meditation.
              </p>
              <button
                className="herodiv-becomememberbtn"
                onClick={handleScroll}
              >
                Click to Conquer Sleep Problems
              </button>
            </div>
          </Col>
          <Col xs={12} lg={5}>
            <div className="video-container">
              <ReactPlayer
                url="https://silva-method-official.b-cdn.net/avijit-silva-intl/draganvideo.mp4"
                controls
                width="100%"
                height={600}
                config={{ 
                  file: {
                    attributes: {
                      controlsList: "nodownload", // Hide download button
                    },
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LandingOneHero;
