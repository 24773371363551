import React, { useEffect } from "react";
import { useContext } from "react";
import { Card } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import Slider from "react-slick";
import { useState } from "react";
import HeadingNew from "../../AboutUs/HeadingNew";
import CheckboxComponent from "../../newbooking/CustomCheckbox";
import { useMemo } from "react";
import { BsCaretLeft, BsCaretRight } from "react-icons/bs";
import { useRef } from "react";
import { RiCustomerService2Fill } from "react-icons/ri";
import moment from "moment";
import { Link } from "react-router-dom";
// make sure you include the timeline stylesheet or the timeline will not be styled



function ClassesCard() {
  const { isMobile, isTablet } = useContext(AuthContext);
  const buttons = [
    {
      index: 1,
      btnText: "Class 1",
      lessons: [
        "Transforming Stress",
        "Crafting Your Life Goals",
        "Using More of Your Brain and Mind",
      ],
      practice: [
        "Centering Exercise",
        "Transform Stress Now Exercise",
        "Outcome Exercise",
      ],
      result: [
        "Greater inner self-control",
        "Override fear and anger",
        "Your personal map of life",
      ],
      motivation: "Live call #1",
      time: "2024-02-24",
      img: "/img/unstressimages/1.webp",
    },
    {
      btnText: "Class 2",
      index: 2,

      lessons: [
        "Going beyond your limiting beliefs",
        "Sleep Control",
        "Alarm Clock",
      ],
      practice: [
        "Recognize limiting beliefs",
        "Sleep Control Exercise",
        "Alarm Clock Exercise",
      ],
      result: [
        "Create a new living experience",
        "Enjoy healthy, revitalizing sleep",
        "Develop inner trust and better mind/body management",
        "Have a better understanding on how to manifest goals",
      ],
      motivation: "Live call #2",
      time: "2024-03-02",
      img: "/img/unstressimages/2.webp",
    },
    {
      btnText: "Class 3",
      index: 3,

      lessons: [
        "Remembering Dreams and the Dream Control Technique",
        "Energizing Technique",
        "3 Fingers Technique",
      ],
      practice: [
        "Dream Control Exercise",
        "Energizing Exercise",
        "3 Fingers Technique",
      ],
      result: [
        "Getting help from the other side for more effective problem-solving",
        "Master your mind/manage your body",
        "Generate energy as needed throughout the day",
        "Attune to mind states for greater control of your living experience",
      ],
      motivation: "Live call #3",
      time: "2024-03-09",
      img: "/img/unstressimages/3.webp",
    },
    {
      btnText: "Class 4",
      index: 4,
      lessons: ["Awake Control (Energizing Technique) "],
      practice: ["Awake Control (Energizing Technique)"],
      result: ["Greater inner self-control"],
      motivation: "Live call #4",
      time: "2024-03-16",
      img: "/img/unstressimages/4.webp",
    },
    {
      btnText: "Class 5",
      index: 5,
      lessons: ["3 Finger Technique"],
      practice: ["3 Finger Technique"],
      result: [
        "Attune to mind states for greater control of your living experience",
      ],
      motivation: "Live call #5",
      time: "2024-03-23",
      img: "/img/unstressimages/5.webp",
    },
    {
      btnText: "Class 6",
      index: 6,
      lessons: ["Mirror of the Mind Exercise Part-1"],
      practice: ["Mirror of the Mind Exercise Part-1"],
      result: ["Solving problems in all areas of life"],
      motivation: "Live call #6",
      time: "2024-03-30",
      img: "/img/unstressimages/6.webp",
    },
    {
      btnText: "Class 7",
      index: 7,
      lessons: ["Mirror of the Mind Exercise Part-2"],
      practice: ["Mirror of the Mind Exercise Part-2"],
      result: ["Experience energy-infused meditations"],
      motivation: "Live call #7",
      time: "2024-04-06",
      img: "/img/unstressimages/7.webp",
    },
    {
      btnText: "Class 8",
      index: 8,
      lessons: ["Mirror of the Mind Exercise Part-3"],
      practice: ["Mirror of the Mind Exercise Part-3"],
      result: ["Learn the secret to creating effective mental programs"],
      motivation: "Live call #8",
      time: "2024-04-13",
      img: "/img/unstressimages/8.webp",
    },
    {
      btnText: "Class 9",
      index: 9,

      lessons: ["Energizing Water Exercise"],
      practice: ["Energizing Water Exercise"],
      result: ["Live a healthier life for the long haul"],
      motivation: "Live call #9",
      time: "2024-04-20",
      img: "/img/unstressimages/9.webp",
    },
    {
      btnText: "Class 10",
      index: 10,

      lessons: ["Headache Control Exercise"],
      practice: ["Headache Control Exercise"],
      result: ["Forgiveness Exercise"],
      motivation: "Live call #10",
      time: "2024-04-27",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 11",
      index: 11,

      lessons: ["Forgiveness Exercise"],
      practice: ["Forgiveness Exercise"],
      result: ["Free yourself of traumatic, unbearable past issues"],
      motivation: "Live call #11",
      time: "2024-05-04",
      img: "/img/unstressimages/11.webp",
    },
    {
      btnText: "Class 12",
      index: 12,

      lessons: ["Projection to a Point or Place in Space Exercise"],
      practice: ["Projection to a Point or Place in Space Exercise"],
      result: ["Ongoing development of the subjective senses"],
      motivation: "Live call #12",
      time: "2024-05-11",
      img: "/img/unstressimages/12.webp",
    },
    {
      btnText: "Class 13",
      index: 13,

      lessons: ["Projection into Inanimate Matter Exercise"],
      practice: ["Projection into Inanimate Matter Exercise"],
      result: ["Have access to information of all kinds beyond time and space"],
      motivation: "Live call #13",
      time: "2024-05-18",
      img: "/img/unstressimages/9.webp",
    },
    {
      btnText: "Class 14",
      index: 14,

      lessons: ["Project to Plants and Animals Exercise"],
      practice: ["Project to Plants and Animals Exercise"],
      result: ["Find solutions to solve all kinds of problems"],
      motivation: "Live call #14",
      time: "2024-05-25",
      img: "/img/unstressimages/12.webp",
    },
    {
      btnText: "Class 15",
      index: 15,
      lessons: ["Creating your Laboratory Exercise"],
      practice: ["Creating your Laboratory Exercise"],
      result: ["A robust Silva toolbox to facilitate problem solving"],
      motivation: "Live call #15",
      time: "2024-06-01",
      img: "/img/unstressimages/11.webp",
    },
    {
      btnText: "Class 16",
      index: 16,
      lessons: ["Creating your Counsellors Exercise along with Case working"],
      practice: ["Creating your Counsellors Exercise along with Case working"],
      result: ["The personification of your higher self"],
      motivation: "Live call #16",
      time: "2024-06-08",
      img: "/img/unstressimages/5.webp",
    },
    {
      btnText: "Class 17",
      index: 17,
      lessons: ["Projection into the Human Body Exercise"],
      practice: ["Projection into the Human Body Exercise"],
      result: ["The ultimate technique for evolving your subjective senses"],
      motivation: "Live call #17",
      time: "2024-06-15",
      img: "/img/unstressimages/8.webp",
    },
    {
      btnText: "Class 18",
      index: 18,

      lessons: ["Helping your loved ones through case working"],
      practice: ["Helping your loved ones through case working"],
      result: ["Experience your oneness with all matter"],
      motivation: "Live call #18",
      time: "2024-06-22",
      img: "/img/unstressimages/12.webp",
    },
    {
      btnText: "Class 19",
      index: 19,

      lessons: ["Establishing a multitude of subjective points of reference"],
      practice: ["Establishing a multitude of subjective points of reference"],
      result: ["Live a purpose driven life"],
      motivation: "Live call #19",
      time: "2024-06-29",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 20",
      index: 20,

      lessons: ["Projection to Purpose Exercise"],
      practice: ["Projection to Purpose Exercise"],
      result: ["Develop a clearer vision of your future"],
      motivation: "Live call #20",
      time: "2024-07-06",
      img: "/img/unstressimages/3.webp",
    },
    {
      btnText: "Class 21",
      index: 21,

      lessons: ["Energy Infused Meditations for Success"],
      practice: ["Energy Infused Meditations for Success"],
      result: ["The ability to compose effective mental programs"],
      motivation: "Live call #21",
      time: "2024-07-13",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 22",
      index: 22,

      lessons: ["Zap and Mini-booster Meditations"],
      practice: ["Zap and Mini-booster Meditations"],
      result: ["Ability to strengthen your connection with outcomes"],
      motivation: "Live call #22",
      time: "2024-07-20",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 23",
      index: 23,

      lessons: ["Seeds of Purpose Exercise"],
      practice: ["Seeds of Purpose Exercise"],
      result: ["Give your life direction and keep it in flow"],
      motivation: "Live call #23",
      time: "2024-07-27",
      img: "/img/unstressimages/3.webp",
    },
    {
      btnText: "Class 24",
      index: 24,

      lessons: ["Engaging Your Entire Being Exercise"],
      practice: ["Engaging Your Entire Being Exercise"],
      result: ["A wholistic approach to goal achievement"],
      motivation: "Live call #24",
      time: "2024-08-03",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 25",
      index: 25,

      lessons: ["Overcoming Obstacles Exercise"],
      practice: ["Overcoming Obstacles Exercise"],
      result: [
        "Ability to identify and dissolve blocks and obstacles as they appear",
      ],
      motivation: "Live call #25",
      time: "2024-08-10",
      img: "/img/unstressimages/3.webp",
    },
    {
      btnText: "Class 26",
      index: 26,

      lessons: ["Choice Points Exercise"],
      practice: ["Choice Points Exercise"],
      result: ["Living life knowing what to choose"],
      motivation: "Live call #26",
      time: "2024-08-17",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 27",
      index: 27,

      lessons: ["Law of Allowance Exercise"],
      practice: ["Law of Allowance Exercise"],
      result: ["An open door to what you most desire in life"],
      motivation: "Live call #27",
      time: "2024-08-24",
      img: "/img/unstressimages/3.webp",
    },
    {
      btnText: "Class 28",
      index: 28,

      lessons: ["The Expectation Exercise"],
      practice: ["The Expectation Exercise"],
      result: ["Personal evolution as a lifestyle"],
      motivation: "Live call #28",
      time: "2024-08-31",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 29",
      index: 29,

      lessons: ["Confidence Exercise"],
      practice: ["Confidence Exercise"],
      result: ["All things around you keep getting better and better"],
      motivation: "Live call #29",
      time: "2024-09-07",
      img: "/img/unstressimages/3.webp",
    },
    {
      btnText: "Class 30",
      index: 30,

      lessons: ["Staying Positive in Thoughts, Beliefs, and Behaviours"],
      practice: ["Staying Positive in Thoughts, Beliefs, and Behaviours"],
      result: ["A Life fulfilled"],
      motivation: "Live call #30",
      time: "2024-09-14",
      img: "/img/unstressimages/3.webp",
    },
    {
      btnText: "Class 31",
      index: 31,

      lessons: ["Gratitude and Appreciation Written Exercise"],
      practice: ["Gratitude and Appreciation Written Exercise"],
      result: ["Healed by the energy of gratitude"],
      motivation: "Live call #31",
      time: "2024-09-21",
      img: "/img/unstressimages/10.webp",
    },
    {
      btnText: "Class 32",
      index: 32,

      lessons: ["Money Magnet Exercise"],
      practice: ["Money Magnet Exercise"],
      result: ["You…having it all!"],
      motivation: "Live call #32",
      time: "2024-09-28",
      img: "/img/unstressimages/10.webp",
    },
  ];


  const items = buttons.map(button => ({
    id: button.index,
    group: 'Classes',
    title: button.btnText,
    start_time: new Date(button.time).getTime(),
    end_time: new Date(button.time).getTime(),
  }));

  const groups = [{ id: 'Classes', title: 'Classes' }];

  const currentDate = new Date();

  const isAfter11PM = (date) => {
    return date.getHours() >= 23; // 23 represents 11 PM in 24-hour format
  };
  const upcomingClasses = buttons?.filter((button) => {
    const buttonDate = new Date(button?.time);
    return buttonDate >= currentDate || (buttonDate.toDateString() === currentDate.toDateString() && !isAfter11PM(currentDate));
  });

  const [activeBtn, setActiveBtn] = useState(1);



  let sliderRef = useRef(null);

  const handleScroll = () => {
    const descard = document.querySelector(".belowpayment");

    if (descard) {
      descard.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleBtnClick = () => {
    window.open(" https://chat.whatsapp.com/KwTp0G1dcno4Uq3dTbzcf4");
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 2 : isTablet ? 3 : 5,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    easing: "linear",
    slidesToScroll: isMobile ? 2 : isTablet ? 3 : 5,
    infinite: false,
  };

  const imagestyles = useMemo(() => {
    if (isTablet) {
      return {
        display: "flex",
        justifyContent: "center",
      };
    } else {
      return {};
    }
  }, [isTablet, isMobile]);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };


  useEffect(() => {
    if (upcomingClasses?.length > 0) {
      setActiveBtn(upcomingClasses[0]?.index)
    }
  }, [upcomingClasses])



  return (
    <>
     
      <div className="container classcardnew mt-5">

        <Card
          style={{
            width: isMobile || isTablet ? "90%" : "60rem",
            margin: "auto",
            padding: "15px",
            borderRadius: "15px",
          }}
        >
          <div>
            <div className="pb-3">
              <HeadingNew title={"Upcoming Classes"} small start />
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              <button
                onClick={previous}
                className="herodiv-becomememberbtn3 mb-3"
                style={{ fontWeight: "400" }}
              >
                <span>
                  <BsCaretLeft />
                </span>{" "}
                Scroll back
              </button>
              <button
                onClick={next}
                className="herodiv-becomememberbtn3 mb-3"
                style={{ fontWeight: "400" }}
              >
                Scroll to view more dates{" "}
                <span>
                  <BsCaretRight />
                </span>
              </button>
            </div>
            <Slider
              {...settings}
              ref={(slider) => {
                sliderRef = slider;
              }}
            >
              {upcomingClasses?.map((val, i) => (
                <div>
                  <button
                    className={`button-291 button-301 px-2`}
                    role="button"
                    onClick={() => setActiveBtn(val.index)}
                    style={{ width: "95%" }}
                  >
                    {moment(val?.time).format('Do MMM')}
                  </button>
                </div>
              ))}
            </Slider>
          </div>
          <div style={{ height: isMobile || isTablet ? "15px" : "150px" }}></div>
          <Card.Body
            style={{
              padding: isMobile || isTablet ? "0" : "30px",
              border: "none",
              marginTop: isMobile ? "15px" : "0",
              maxWidth: isMobile ? "100%" : "60%",
            }}
          >
            <HeadingNew title={"Lessons"} small start />
            <div className="my-2">
              {buttons[activeBtn - 1]?.lessons.map((val, i) => (
                <div className="d-flex gap-3 justify-content-start align-items-center">
                  <CheckboxComponent />
                  <p
                    style={{
                      color: "black",
                      lineHeight: "15px",
                      fontWeight: "500",
                    }}
                    className="mt-1"
                  >
                    {val}
                  </p>
                </div>
              ))}
            </div>

            <HeadingNew title={"Practice"} small start />
            <div className="my-2">
              {buttons[activeBtn - 1]?.practice.map((val, i) => (
                <div className="d-flex gap-3 justify-content-start align-items-center">
                  <CheckboxComponent />
                  <p
                    style={{
                      color: "black",
                      lineHeight: "15px",
                      fontWeight: "500",
                    }}
                    className="mt-1"
                  >
                    {val}
                  </p>
                </div>
              ))}
            </div>
            <HeadingNew title={"Result"} small start />
            <div className="my-2">
              {buttons[activeBtn - 1]?.result.map((val, i) => (
                <div className="d-flex gap-3 justify-content-start align-items-center">
                  <CheckboxComponent />
                  <p
                    style={{
                      color: "black",
                      lineHeight: "15px",
                      fontWeight: "500",
                    }}
                    className="mt-1"
                  >
                    {val}
                  </p>
                </div>
              ))}
            </div>
            <HeadingNew title={"Date"} small start />
            <div className="my-2">
              <p
                style={{
                  fontSize: "1.2rem",
                }}
              >
                {moment(buttons[activeBtn - 1].time).format('Do MMM YY')}
              </p>
            </div>
            <div>
              {/* <button className="herodiv-becomememberbtn3 mb-2 mx-3 py-3" onClick={handleScroll}>Enroll Now to Join the Live Class</button> */}
              <Link to={'/appointment'}>
                <button
                  className="footer-whatapp-Btn mb-3 m-0"
                  style={{ fontWeight: "400", color: "#000" }}
                >
                  Still Confused? Consult with us{" "}
                  <span>
                    <RiCustomerService2Fill />
                  </span>
                </button>
              </Link>
            </div>
            <div>
              {/* <button className="herodiv-becomememberbtn3 mb-3" style={{fontWeight:"400"}} onClick={handleScroll}>
            Still Confused? Consult with us
            </button> */}
              <p></p>
            </div>
            {(isMobile || isTablet) && (
              <div style={imagestyles}>
                <img
                  src={buttons[activeBtn - 1].img}
                  style={{
                    right: "50px",
                    objectFit: "cover",
                    width: "auto",
                    borderTopLeftRadius: "40px",
                    borderTopRightRadius: "40px",
                    height: "400px",
                    // marginTop: isMobile ? "400px" : "0",
                    borderImage: "initial",
                    borderBottom: "none",
                    // borderRadius:"10px",
                    borderTop: "8px solid rgb(222, 222, 222)",
                    borderRight: "8px solid rgb(222, 222, 222)",
                    borderLeft: "8px solid rgb(222, 222, 222)",
                  }}
                />
              </div>
            )}
          </Card.Body>
          {!isMobile && !isTablet && (
            <img
              src={buttons[activeBtn - 1].img}
              style={{
                position: "absolute",
                bottom: "0",
                right: "50px",
                objectFit: "cover",
                width: "350px",

                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
                height: "550px",
                marginTop: isMobile ? "400px" : "0",
                borderImage: "initial",
                borderBottom: "none",
                // borderRadius:"10px",
                borderTop: "8px solid rgb(222, 222, 222)",
                borderRight: "8px solid rgb(222, 222, 222)",
                borderLeft: "8px solid rgb(222, 222, 222)",
              }}
            />
          )}
        </Card>
      </div>
    </>

  );
}

export default ClassesCard;
