import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';

function BenefitsPerkHypnosis() {
    const { isMobile } = useContext(AuthContext);

    const masterclassdata = useMemo(() => {
        return [
            "Who is searching for his/her soulmate",
            "Someone desiring long-lasting relationships",
            "Those who are curious about their connection with the Universe",
            "One who can not balance Work and Personal Life",
            "Want to Improve health, relationships, and other areas of life",
            "Who has a Fixed mindset",
            "Who has self-doubts",
            "Who suffers from stress and anxiety",
            "One who can't get out of his guilt feelings",
            "Who endures restless nights due to Worries",
            "One who is seeking inner peace and happiness"
        ]

    }, [])

    const handleScroll = ()=>{
        const card = document.querySelector(".includelivecheck")
        if(card){
            card.scrollIntoView({
                behavior:"smooth"
            })
        }
      }

    return (
        <div className='container my-5'>
            <Row className='container justify-content-center align-items-center' style={{
                position: "relative"
            }}>
                <Col xs={12} lg={6} className={isMobile ? 'order-2' : 'order-1'}>
                    <HeadingNew title={"Hypnotize Yourself With"} colored={"The Silva Method"} start />


                    <ParaComp
                        white={false}
                        data={[
                            "Self-hypnosis -  a powerful therapeutic tool. It can be described as inducing an intentional trance, which is known as a ‘hypnotic state’- where brain frequencies slow down. The Silva Method is the easiest way to learn Self-hypnosis. With this mind empowerment technique, you can quickly enter the Alpha state of mind. That connects the conscious and subconscious minds.",
                            "Practicing self-hypnosis with the Silva Method will bring positive change and make improvements in your life, like eliminating a bad habit or changing your thought patterns. When you master the state of your mind you will discover the core of your problems and make better decisions and solve any problems. Through consistent practice, you will find a more focused and calmer version of yourself."
                        ]}

                    />
                    <button className='herodiv-becomememberbtnnew' style={{
                        marginLeft: "0",

                    }}
                        onClick={handleScroll}
                    >
                        Enroll now
                    </button>




                </Col>
                <Col xs={12} lg={6} className={isMobile ? 'order-1' : 'order-2'} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                }}>


                    <div className='d-flex justify-content-end align-items-end'>
                        <Tilt style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end"
                        }}>

                                <img src='/img/hypnosis/hypnosis.gif' style={{
                                    width: "95%",
                                }} />
                               



                        </Tilt>

                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default BenefitsPerkHypnosis