import React from "react";
import HeadingNew from "../../AboutUs/HeadingNew";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import { Card, Col, Row } from "react-bootstrap";
import Badge from "./Badge";

function StatesMindControl() {
  const cardItems = [
    {
      id: 1,
      title: "STEP 1",
      text: "Gently close your eyes and take slower and deeper breaths",
    },
    {
      id: 2,
      title: "STEP 2",
      text: "Now while exhaling visualize the number 3- three times.",
    },
    {
      id: 3,
      title: "STEP 3",
      text: "Take another deep breath and while exhaling visualize the number 2- three times",
    },
    {
      id: 4,
      title: "STEP 4",
      text: "Take another deep breath and while exhaling visualize the number 1- three times",
    },
  ];

  return (
    <div className="bg-book-1">
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <h5 className="text-black">Silva Guided Meditation to Access an</h5>
        <HeadingNew title={"Altered State of "} colored={"Human Mind"} />
        <div>
          <ParaComp
            data={[
              "Another unique fact about the human brain is that it functions on different levels. Using the Silva guided meditation method you can harness these levels. Each level has its own characteristics, differing from one another. With each level, you can enter into a deeper state of mind, where you can take control of it. The first step to enter into this deep and ultra state of mind using the 3-2-1 method- a silva-guided meditation technique. In simple words, it is more like a warm-up before a workout. This meditative state is also referred to as the Alpha state of mind, one of the different levels of the human brain.",
              "As per Jose Silva mind control method, when you reach the Alpha state of mind you will be able to reprogram your mind. It's like hitting a jackpot- the more you remain in the alpha state, the more richness you will feel. In this subconscious level of mind, you can access the software (mind) and program it to support you in achieving your goals and desires. Let's learn how Jose Silva mind control method trains your mind to enter the Alpha level:",
            ]}
            white={false}
            fw={"500"}
          />
          <div style={{marginTop:"40px",marginBottom:"40px"}}>
          <HeadingNew
            title={"Silva Mind Control Meditation - The 3-2-1 Method"}
            small
            start
          />
          <ParaComp
            data={[
              "Find a quiet and peaceful place where you can remain unbothered. Make sure any distracting elements do not surround you. Silva mind control meditation is a dynamic meditation program that allows you to apply these techniques at any point of time and place. So make yourself as comfortable as you can. You may sit on a chair or on the floor or even lie down. Once you feel relaxed, follow the steps mentioned below:",
            ]}
            white={false}
            fw={"500"}
          />
          </div>
          <Row>
            {cardItems?.map((cardItem) => (
              <Col sm={12} md={6} lg={3} className="px-2 d-flex">
                <Card className="w-100 p-4" style={{borderRadius:"15px"}}>
                  <Badge text={cardItem?.title} backgroundColor="linear-gradient(90deg, #3520e5, #d801fb 50%)" color="#fff"/>
                  <p className="text-black my-2">{cardItem?.text}</p>
                </Card>
              </Col>
            ))}
          </Row>
          <ParaComp
            data={[
                "That's it. You are now in a deeper and more relaxed state of mind. Silva mind control course then teaches you how to enter into an advanced level of the subconscious mind to access the theta and delta state of mind. Using the Silva mind control method, you can then master your thoughts by remaining still in the alpha state. Now this may sound simple but for begin- ners, it could be a little difficult to focus. That's why it's important to remain constant with the process. You may not be able to focus in the first go, but with regular sitting, there is a huge chance to improve. Also, It's okay if you keep losing your focus quite often. All you need to do is gently bring back your focus on where you left off and start again."
            ]}
            white={false}
            fw={"500"}
          />
        </div>
      </div>
    </div>
  );
}

export default StatesMindControl;
