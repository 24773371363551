import React, { useRef, useState } from 'react';
import { Offcanvas, Navbar, Container, Dropdown, Badge } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import { BsList, BsFillPlayCircleFill, BsFillPauseCircleFill } from 'react-icons/bs';
import './SilvaForAll.css';
import { useLocation, useParams } from 'react-router-dom';
import { CgClose } from 'react-icons/cg';
import axios from 'axios';
import { useEffect } from 'react';

function SilvaForAll() {
  const [content,setContents] = useState([])
  const [currentContent,setCurrentContent] = useState(null)
  const videoRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const {id} = useParams()


  const getContents = async()=>{
    try{

      const res =await axios.get("https://silvacasesonline.com:5000/api/v1/admin/private/meditation-content")
      if(res && res.data){
        setContents(res.data)
        const found = res.data.find((val)=>val._id===id)
        setCurrentContent(found)
      }

    }catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    getContents()
  },[])


  // Toggle offcanvas
  const handleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  if(!currentContent){
    return null
  }
  return (
    <div className="sfa_container">
      {/* Header with burger menu */}
      <Navbar className="sfa_navbar">
        <Container>
          <BsList 
            size={35} 
            className="sfa_menu_icon" 
            onClick={handleOffcanvas}
          />
          <h1 className="sfa_title">{currentContent?.title || 'Content Player'}</h1>
        </Container>
      </Navbar>

      {/* Main content area */}
      <div className="sfa_content">
        {/* Video Player */}
        {currentContent?.type === 'video' && (
          <div className="sfa_video_container">
            <video
              ref={videoRef}
              className="sfa_video"
              controls
              controlsList="nodownload"
              playsInline
              onPlay={() => {
                if (videoRef.current) {
                  videoRef.current.playbackRate = playbackRate;
                }
              }}
            >
              <source src={currentContent?.link} />
            </video>
            <div className="sfa_video_controls">
              <Dropdown>
                <Dropdown.Toggle variant="light" id="speed-dropdown">
                  Playback Speed
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed) => (
                    <Dropdown.Item 
                      key={speed} 
                      onClick={() => setPlaybackRate(speed)}
                    >
                      {speed === 1 ? 'Normal' : `${speed}x`}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}

        {/* Audio Player */}
        {currentContent?.type === 'audio' && (
          <div className="sfa_audio_container">
            <AudioPlayer
              autoPlay={false}
              className="sfa_audio_player"
              src={currentContent?.link}
              onPlay={(e) => {
                e.target.playbackRate = playbackRate;
              }}
              customIcons={{
                play: <BsFillPlayCircleFill size={30} />,
                pause: <BsFillPauseCircleFill size={30} />
              }}
              header={<img src={currentContent.thumbnail} style={{
                width:"100%"
              }} />}
              customControlsSection={[
                <div className="sfa_audio_controls" key="speed-control">
                  <label>Playback Speed</label>
                  <select
                    value={playbackRate}
                    onChange={(e) => setPlaybackRate(parseFloat(e.target.value))}
                  >
                    {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed) => (
                      <option key={speed} value={speed}>
                        {speed === 1 ? 'Normal' : `${speed}x`}
                      </option>
                    ))}
                  </select>
                </div>,
                'MAIN_CONTROLS',
                'VOLUME_CONTROLS'
              ]}
            />
          </div>
        )}
      </div>

      {/* Offcanvas for lessons list */}
      <Offcanvas show={showOffcanvas} onHide={handleOffcanvas}>
        <Offcanvas.Header closeButton className='d-flex justify-content-between align-items-center'>
          <Offcanvas.Title className='header-common text-white'>Free Lessons</Offcanvas.Title>
          <CgClose color='white' size={25} onClick={()=>{
            setShowOffcanvas(false)
          }} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sfa_lessons_list">
            {/* Render your lessons list here */}
            {/* Example structure: */}
            {
              content.map((val)=>(
                <div className="sfa_lesson_item">
                <img 
                  src={val?.thumbnail} 
                  alt={val?.title} 
                  className="sfa_lesson_thumb"
                />
                <div className="sfa_lesson_info">

                <div className='d-flex gap-2 mb-3 robotofont flex-wrap' style={{
                borderRadius:"15px",
              }}>
                {
                  val.tags.map((valval) => (
                    <Badge bg="success" className='py-2 px-3'>{valval.name}</Badge>
                  ))
                }
              </div>
                  <h3>{val?.title}</h3>
                  <p>{val?.description}</p>
                  <span className={`sfa_lesson_type sfa_type_${val?.type}`}>
                    {val?.type}
                  </span>
                </div>
              </div>
              ))
            }
           
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default SilvaForAll;