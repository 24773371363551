import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";
import { FaCheckCircle } from "react-icons/fa";

function WhoForCards({ data }) {
  const { isMobile } = useContext(AuthContext);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column gap-3 my-5 text-center">
      <h2 className="newpageheader text-center">Who can Use this Technique?</h2>
      <h5 className="text-center">
        Anyone who wants to take charge of their life, solve a problem, or make
        the right decisions, especially
      </h5>
      <Row className="container justify-content-center align-items-center gy-3">
        {data.map((val, i) => (
          <Card
            style={{
              width: "15rem",
              borderRadius: "12px",
              position: "relative",
            }}
          >
            <Card.Body>
              <div style={{ position: "absolute", left: 0, top: 0 }}>
                <FaCheckCircle color="green" />
              </div>
              <div className="d-flex justify-content-around align-items-center">
                <span>{val.icon}</span>
                <div className="d-flex justify-content-center align-items-center flex-column gap-2">
                  <h4
                    style={{
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    {val.title}
                  </h4>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </Row>
    </div>
  );
}

export default WhoForCards;
