import React, { useContext } from 'react'
import { Card, Image } from 'react-bootstrap'
import { BiPlayCircle } from 'react-icons/bi';
import { AuthContext } from '../../../../context/AllContext';
import { Link } from 'react-router-dom';

function TypesMeditateCard({ item, color,img=true,black=false,link,linktext,fontclass }) {
    const {isMobile} = useContext(AuthContext);

    return (

        <div className={isMobile?"card mb-2 shadow-sm mx-auto":"card mb-4 shadow-sm mx-auto"}
            style={{
                background: "#f5f4ef",
                transition: 'all 0.3s ease',
                border: 'none',
                borderRadius: '25px',
                padding: isMobile?"10px":"20px",
                overflow: 'hidden',
                width: isMobile ?"96%":"95%",
                height: "auto",
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translateY(-5px)';
                e.currentTarget.style.boxShadow = '0 10px 20px rgba(0,0,0,0.1)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)';
            }}>
            <div className="card-body">

              
                <h2 style={{ color: black?"black":'black', textAlign: 'start',fontSize:isMobile?"1.5rem":"2rem" }} className={fontclass?"lora-sans":""}>
                    {item.title}
                </h2>
                <p className={black?"robotofont mt-3 highlight-container":"robotofont mt-3 highlight-container"} style={{ fontSize: '1rem' }}>{item.content}</p>
                {
                    item.buttonText && (
                        <button className='btn btn-warning'>
                            <span>
                                <BiPlayCircle color='black' style={{
                                    marginRight:"5px"
                                }} />
                            </span>
                            {item.buttonText}
                        </button>
                    )
                }

                {
                    link && linktext && (
                        <Link to={link} className='cssbuttons-io-button3'>
                        {linktext}
    
                    </Link>
                    )
                }
            </div>
        </div>



    )
}

export default TypesMeditateCard