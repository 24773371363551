import React from "react";
import { useMediaQuery } from "react-responsive";
import { BsFillPatchCheckFill } from "react-icons/bs";
import HeadingNew from "../AboutUs/HeadingNew";

function LifeSystemBanner({
  img = "",
  title = "",
  hasBadge = false,
  badgeText = "",
  hasButton = false,
  btnText = "",
  text1 = "",
  text2 = "",
  reverse = false,
  list = false,
  hancleClick,
  leftgap = false,
}) {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1280px)" });
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });

  const listyles = {
    fontWeight: "normal",
    color: "#647589",
    marginBottom: "15px",
  };
  return (
    <div
      className="container mb-3"
      style={{ marginTop: reverse ? "20px" : "40px" }}
    >
      <div className="row justify-content-center align-items-center">
        {!reverse && (
          <div className="col-sm-12 col-md-5">
            <img
              src={img}
              style={{
                // height: "500px",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
        {isMobile && reverse && (
          <div className="col-sm-12 col-md-5">
            <img
              src={img}
              style={{

                // width: "100%",
                // height: "500px",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
        { }
        <div
          className={
            isDesktopOrLaptop
              ? "col-sm-12 col-md-5 mt-5 p-2"
              : "col-sm-12 col-md-5 p-2"
          }
        >
          <div className="text-start">
            {/* <h2>
                            {title}
                        </h2> */}
            <HeadingNew title={title} small marginleft={leftgap} start />
            {hasBadge && (
              <span
                className="px-2 py-1 white-color"
                style={{ fontSize: "1.3rem", backgroundColor: "#6f25af" }}
              >
                {badgeText}
              </span>
            )}
            <p
              style={{
                fontSize: "1.1rem",
                marginTop: "10px",
                color: "black",
                marginLeft: (leftgap && !isMobile) ? "20px" : "0",

              }}
            >
              {text1}
            </p>
            <p
              style={{
                fontSize: "1.1rem",
                color: "black",
                marginLeft: (leftgap && !isMobile) ? "20px" : "0",
              }}
            >
              {text2}
            </p>

            {list && (
              <div className="bulletdiv">
                <ul className="text-start bulletli">
                  <li className="one" style={listyles}>
                    <span>
                      <BsFillPatchCheckFill color="#801cb6" />
                    </span>{" "}
                    Access to all Silva Method courses
                  </li>
                  <li className="one" style={listyles}>
                    <span>
                      <BsFillPatchCheckFill color="#801cb6" />
                    </span>{" "}
                    Learn how to control mind from the best teachers cum
                    instructors
                  </li>
                  <li className="one" style={listyles}>
                    <span>
                      <BsFillPatchCheckFill color="#801cb6" />
                    </span>{" "}
                    Exclusive meditation tips and techniques
                  </li>
                  <li className="one" style={listyles}>
                    <span>
                      <BsFillPatchCheckFill color="#801cb6" />
                    </span>{" "}
                    Join the global community of over 12 million unique minds
                  </li>
                  <li className="one" style={listyles}>
                    <span>
                      <BsFillPatchCheckFill color="#801cb6" />
                    </span>{" "}
                    Self-discovery lessons and Mentoring from experts
                  </li>
                  <li className="one" style={listyles}>
                    <span>
                      <BsFillPatchCheckFill color="#801cb6" />
                    </span>{" "}
                    Your one-stop solution for major life crisis
                  </li>
                </ul>
              </div>
            )}

            {hasButton && (
              <button className="primary_btn2" onClick={hancleClick}>
                {btnText}
              </button>
            )}
          </div>
        </div>
        {reverse && !isMobile && (
          <div className="col-sm-12 col-md-5">
            <img
              src={img}
              style={{

                // width: "100%",
                // height: "500px",
                objectFit: "contain",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LifeSystemBanner;
