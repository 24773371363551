import React, { useContext } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomFooter from '../Home/Home/HomeFooter/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import { AuthContext } from '../../context/AllContext';
import useWindowSize from 'react-use/lib/useWindowSize'
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import { FaYoutube, FaWhatsapp , FaFileDownload} from "react-icons/fa";

import Confetti from 'react-confetti'
import HeadingNew from '../AboutUs/HeadingNew';

const ThankYouPage2 = () => {
    const { isMobile } = useContext(AuthContext)
    const { width, height } = useWindowSize()

    return (
      <>
        {/* <HomeHeader /> */}
        <div>
          <div
            style={{
              background: `#510286`,
              border: "1px solid black",
              padding: "50px 50px",
              // height: '100vh',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h6
              style={{
                color: "white",
                fontSize: "1.1rem",
                textAlign: "center",
                marginBottom: "15px",
                lineHeight: "20px",
              }}
            >
              Thank you for choosing us!
            </h6>
            <div className="container">
              <p
                style={{
                  color: "white",
                  fontSize: "1rem",
                  textAlign: "center",
                  marginBottom: "15px",
                  lineHeight: "20px",
                }}
              >
                You will receive one email from digital@silvamethod.com with the
                temporary password and login link on your registered email ID.
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: "1rem",
                  textAlign: "center",
                  marginBottom: "15px",
                  lineHeight: "20px",
                }}
              >
                You can check your SPAM folder if it's not there in the Inbox.
                For any assistance write us at{" "}
                <a
                  href="mailto:digital@silvamethod.com"
                  style={{ color: "white" }}
                >
                  digital@silvamethod.com
                </a>
              </p>
            </div>
            {/* <hr /> */}
            <div className="d-flex justify-content-center align-items-center">
              <Image
                src="img/thankyou/thankyou.gif"
                height={isMobile ? "auto" : 500}
                style={{ borderRadius: "10px" }}
              />
            </div>

            <HeadingNew title={"Join our community"} small white />

            <div className="row mt-4 justify-content-center align-items-center">
              <button
                className="col-md-4 footer-whatapp-Btn"
                onClick={() => {
                  const whatsappGroupURL =
                    "https://chat.whatsapp.com/GBCoRag4mJt3CpZybbfuzA";

                  window.open(whatsappGroupURL);
                }}
              > 
                <span>
                  <FaWhatsapp size={30} color="white" />
                </span>{" "}
                Join Now
              </button>
              <button
                className="col-md-4 button-87"
                onClick={() => {
                  window.open("https://www.facebook.com/SilvaInternationalInc");
                }}
              >
                <span>
                  <FaFacebookSquare size={30} color="white" />
                </span>{" "}
                Follow us
              </button>

              <button
                className="col-md-4 button-87"
                onClick={() => {
                  window.open("https://www.instagram.com/silvamethodofficial/");
                }}
              >
                <span>
                  <IoLogoInstagram size={30} color="white" />
                </span>{" "}
                Follow us
              </button>

              <button
                className="col-md-4 button-87"
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UCaCDqnK1-i0rb9tFPznvI4w"
                  );
                }}
              >
                <span>
                  <FaYoutube size={30} color="white" />
                </span>{" "}
                Subscribe Now
              </button>
              <button className="col-md-4 button-87">
                <a
                  href="/documents/Navigation_pdf _for_user _Phone.pdf"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <span>
                    <FaFileDownload size={20} color="white" />
                  </span>{" "}
                  Download Navigation Pdf(Mobile)
                </a>
              </button>
              <button className="col-md-4 button-87">
                <a
                  href="/documents/Navigation_pdf _for_user _web.pdf"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <span>
                    <FaFileDownload size={20} color="white" />
                  </span>{" "}
                  Download Navigation Pdf(Web)
                </a>
              </button>
            </div>

            <div className="text-center mt-4">
              <Link
                className="primary_btn3"
                to="/"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1.3rem",
                }}
              >
                Back to Home
              </Link>
            </div>
          </div>
        </div>
        {/* <footer className='py-3' style={{ backgroundColor: "#f4f5f9", width: "100%", height: "100%" }}>
                <p className="text-center" style={{ fontSize: "16px" }}>Copyright &copy; silvamethod.com</p>
            </footer> */}
        <Confetti
          width={width}
          gravity={0.2}
          initialVelocityX={10}
          initialVelocityY={10}
          height={height}
        />
      </>
    );
};

export default ThankYouPage2;
