import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import ReactStars from "react-stars";

function SliderCard({ data }) {
  const { isDesktop, isMobile } = useContext(AuthContext);

  return (
    <Card
      className="p-5"
      style={{
        width: isMobile?"90%":"98%",
        height: isDesktop ? "auto" : isMobile ? "auto" : "auto",
        borderRadius: 15,
        margin:"auto",
        marginTop:"20px"
      }}
    >
      {/* <div className='d-flex gap-2'> */}
      <Row className="justify-content-center align-items-start">
        <Col xs={3} md={2}>
         <div style={{
          flex:"none"
         }}>
         <img
            src={data?.image || "/assets/img/no_image.jpg"}
            alt=""
            style={{
              width: !isMobile?"90%":"100%",
              height:  !isMobile?"90%":"100%",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
         </div>
        </Col>
        <Col xs={9}  md={10}>
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <h5
              style={{
                color: "black",
                fontWeight: "bolder",
              }}
              className="m-0 mb-2"
            >
              {data?.name}
            </h5>
            <p
              className="text-muted"
              style={{
                margin:0,
                padding:0
              }}
              // style={{padding:'5px 10px', backgroundColor:'#fff',fontWeight:'bold',borderRadius:'5px',display:'inline-block',color:"black"}}
            >
              {data?.designation}
            </p>
            <div className="d-flex gap-2 align-items-center">
            <h5
              style={{
                color: "black",
                fontWeight: "bolder",
              }}
              className="m-0"
            >
              5.0
            </h5>
            <span style={{
                margin:0,
                padding:0
            }}>
              <ReactStars count={5} size={24} edit={false} value={5} />

            </span>

            </div>
            <p>{data?.comment}</p>
          </div>
        </Col>
      </Row>
    
    </Card>
  );
}

export default SliderCard;
