import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AllContext";
import SingleClassCards from "./SingleClassCard";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from "react-slick";
import HomeHeading from "./HomeHeading";

const HomeClasses = () => {
  const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } = useContext(AuthContext);
  const [classes, setClasses] = useState([]);


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
  };


  const getData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");

    const formData = new FormData();
    formData.append("start_index", "0");
    formData.append("no_of_records", "100");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")




    if (country) {
      myHeaders.append("country", country.toString())
    }

    if (currency) {
      myHeaders.append("currency", currency.toString())
    }
    const options = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };
    const res = await fetch(
      `https://silvamethod.com/manage/api/home`,
      options
    );
    const data = await res.json();
    // console.log(data.data.classes, "Classess");
    setClasses(data.data.classes);
  };



  useEffect(() => {
    getData();
  }, []);

  // console.log(classes);

  return (
    <>
      <section className="servcies-area parallexcontact pt-20 pb-10">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-15">
                {/* <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                        </div> */}
                <div className="section-text pos-rel home_ser_title">
                  {/* <h5>Upcoming Live & Online Classes</h5> */}
                  {/* <h2
                    style={{
                      fontWeight: "800",
                      fontSize: (isDesktopOrLaptop || isBigScreen) ? "60px" : "30px",
                    }}
                  >
                    Upcoming Silva Method <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Live & Online</span> Classes
                  </h2> */}

                  <HomeHeading title="Upcoming Silva Method" colored=" Live & Online" title2="Classes" />

                  <h5 className="mt-3 mb-3">
                    Over 12 million people in 110 countries have experienced what Silva
                    Method Meditation can do.
                  </h5>
                </div>
                {/* <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div> */}
              </div>
            </div>
          </div>
            <div className="row row-cols-1 row-cols-lg-3 cardclasses justify-content-center" style={{
              margin:"auto"
            }}>



              {classes && !isMobile &&
                classes.map((cl,i) => (
                  <SingleClassCards key={cl.course_id} data={cl} index={i}/>
                ))}


              {
                isMobile && classes && (
                  <Slider {...settings}>
                    {
                      classes.map((cl,i) => (
                        <SingleClassCards key={cl.course_id} data={cl} index={i}/>
                      ))
                    }

                  </Slider>
                )
              }
            </div>
        </div>
      </section>
    </>
  );
};

export default HomeClasses;
