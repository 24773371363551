import { Col, Container, Image, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";
import { useContext } from "react";

function GlassofWaterHero() {
  const { isMobile,isTablet } = useContext(AuthContext);

  const handleScroll = () => {
    const cardscroll = document.querySelector(".paymenttwonew");

    if (cardscroll) {
      cardscroll.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <section className={`hero-sectionone container py-${(isMobile)?3:5}`}>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={7} className={`mb-${(isMobile || isTablet)?2:0}`}>
            <div
              className="content-container"
              style={{
                width: (isMobile ||  isTablet) ? "97%" : "90%",
                marginLeft: (isMobile ||  isTablet) ? "0px" : "40px",
                marginTop: (isMobile ||  isTablet) ? "30px" : "0px",
              }}
            >
              <h2 className="newpageheader">
              Stuck at Life's Crossroads?
              </h2>

              <h4
                style={{
                  fontWeight: "600",
                  fontSize: "25px",
                  color: "black",
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                Feeling Lost in Dilemmas?
              </h4>

              <p
                className="normalpara"
                style={{
                  textAlign: "left",
                }}
              >
                Making decisions and solving problems isn't just a job skill – it's a life skill that shapes your future. Think about it: facing an issue, be it with family, work, marriage, or personal matters, resolving it in time brings a sigh of relief, right? Well, the same goes for decisions – the right ones can transform your life. But, how often do we nail it? 
              </p>
              <button
                className="herodiv-becomememberbtn"
                onClick={handleScroll}
              >
                Find out now
              </button>
            </div>
          </Col>
          <Col xs={12} lg={5}>
            <div>
               <Image src="/img/img_glass_of_water1.jpg" style={{width:'100%',borderRadius:'15px',}}/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default GlassofWaterHero;
