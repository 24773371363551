import React from 'react';

const FinalThoughts = () => {
  const links = [
    {
      url: "https://www.mind.org.uk/information-support/drugs-and-treatments/mindfulness/about-mindfulness/",
      text: "Mind.org - About Mindfulness"
    },
    {
      url: "https://www.mindful.org/meditation/mindfulness-getting-started/",
      text: "Mindful.org - Getting Started"
    },
    {
      url: "https://edis.ifas.ufl.edu/publication/FY1381",
      text: "UF/IFAS Extension - Mindfulness"
    },
    {
      url: "https://www.insightmeditationcenter.org/introduction-to-mindfulness-meditation/",
      text: "Insight Meditation Center"
    },
    {
      url: "https://www.betterhelp.com/advice/mindfulness/a-simple-introduction-to-mindfulness/",
      text: "BetterHelp - Introduction to Mindfulness"
    }
  ];

  return (
    <section style={styles.section}>
      <div className='container'>
        <h2 style={styles.heading}>Final Thoughts</h2>
        
        <div style={styles.content}>
          <p className='robotofont'>
            Starting a mindfulness practice as a beginner doesn't have to be complicated. 
            With so many <em style={styles.emphasis}>mindfulness meditation resources</em>, 
            including <em style={styles.emphasis}>guided mindfulness meditations</em>, PDFs, 
            and online courses, there's support every step of the way. By following these 
            <em style={styles.emphasis}> mindfulness steps for beginners</em>, you can 
            gradually develop a practice that helps you manage stress, improve your sleep, 
            and achieve peace and mind in your daily life.
          </p>
          
          <p style={styles.paragraph}>
            Remember, mindfulness is about the journey, not the destination. Whether you're 
            practising for stress relief, better sleep, or to improve your overall well-being, 
            stay patient with yourself and enjoy the process.
          </p>
        </div>

        <div style={styles.resourcesSection}>
          <h3 style={styles.resourcesHeading}>Additional Resources</h3>
          <div style={styles.linksContainer}>
            {links.map((link, index) => (
              <a 
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.link}
                onMouseEnter={(e) => {
                  e.target.style.borderColor = '#4299e1';
                  e.target.style.color = '#4299e1';
                }}
                onMouseLeave={(e) => {
                  e.target.style.borderColor = '#e2e8f0';
                  e.target.style.color = '#4a5568';
                }}
              >
                {link.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: {
    padding: '60px 0',
    background: '#ffffff',
    minHeight: '100vh',
  },
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px',
  },
  heading: {
    fontSize: '36px',
    color: '#1a202c',
    textAlign: 'left',
    fontWeight: '700',
    position: 'relative',
    paddingBottom: '15px',
  },
  content: {
    marginBottom: '40px',
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '1.8',
    color: '#4a5568',
    marginBottom: '24px',
    letterSpacing: '0.1px',
  },
  emphasis: {
    color: '#4299e1',
    fontStyle: 'normal',
    fontWeight: '500',
  },
  resourcesSection: {
    marginTop: '48px',
    borderTop: '1px solid #e2e8f0',
    paddingTop: '40px',
  },
  resourcesHeading: {
    fontSize: '24px',
    color: '#1a202c',
    marginBottom: '24px',
    fontWeight: '600',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  link: {
    padding: '12px 0',
    color: '#4a5568',
    textDecoration: 'none',
    fontSize: '16px',
    transition: 'all 0.2s ease',
    borderBottom: '1px solid #e2e8f0',
    display: 'block',
    lineHeight: '1.5',
  },
};

export default FinalThoughts;