// import React from "react";
// import HeadingNew from "../../../AboutUs/HeadingNew";
// import ParaComp from "../../../SilvaManifestationProgram/ParaComp";
// import { FaArrowRight } from "react-icons/fa";
// import { Link } from "react-router-dom";

// function AppointmentSection() {
//   return (
//     <div className="container appointmentbannermain my-5">
//       <HeadingNew title={"Want to talk to an Instructor?"} small white />
   
//       <div className="my-2">
//         <ParaComp
//           data={[
//             "Click on the below button, fill the form, that's it! Wait for our confirmation mail.",
//           ]}
//           white
//           center
//           margin={false}
//           fw={500}
//         />
//       </div>
//       <div className="d-flex justify-content-center align-items-center">
//         <Link to={"/appointment"}>
//         <button className="appointmentBtn">
//           Book Free Consultation{" "}
//           <span className="p-3 bg-light rounded-circle ms-2">
//             <FaArrowRight color="#173ab7" />
//           </span>
//         </button>
//         </Link>
//       </div>
//     </div>
//   );
// }

// export default AppointmentSection;




import React from "react";
import HeadingNew from "../../../AboutUs/HeadingNew";
import ParaComp from "../../../SilvaManifestationProgram/ParaComp";
import { FaArrowRight } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AllContext";

function AppointmentSection() {

  const {isMobile}= useContext(AuthContext);

  return (
    <div className="container appointmentbannermain" style={{marginTop:"60px",marginBottom:"60px"}}>
      <Row>
        <Col sm={12} md={12} lg={6} className={`order-2 order-lg-1 d-flex justify-content-center align-items-center px-${isMobile?"4":"5"}`}>
          <div>
          <HeadingNew title={"The Silva Mind Control Method by"} colored={"José Silva"} small white start />
          <div className="my-2 mt-3">
            <ParaComp
              data={[
                "Silva Mind Control Method, founded by Joses Silva In 1962, is a self-transformation practice. It is a technique, practicing which, one can train their minds at a subconscious level and help themselves to achieve greater success in their life.",
              ]}
              white
              center={false}
              margin={false}
              fw={500}
            />
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <Link to={"/the-silva-mind-control-method"}>
              <button className="herodiv-becomememberbtn3 px-4">
                View more
                {/* <span className="p-3 bg-light rounded-circle ms-2">
                  <FaArrowRight color="#173ab7" />
                </span> */}
                <span>
                <MdChevronRight />
                </span>
              </button>
            </Link>
          </div>
          </div>
        </Col>
        <Col sm={12} md={12} lg={6} className="order-1 order-lg-2 p-3 d-flex justify-content-center align-items-center">
          <img src="/img/slide-5.webp" alt="" style={{ width: "100%", height: "100%",borderRadius:15 }} />
        </Col>
      </Row>
    </div>
  );
}

export default AppointmentSection;



