import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from 'react-slick';

import alpha from "../../images/about-us-convert/alpha.webp";
import theta from "../../images/about-us-convert/theta sound.webp";
import intuition from "../../images/about-us-convert/silva system.webp";
import life from "../../images/about-us-convert/silva life system.webp";
import manifesting from "../../images/about-us-convert/silva manifesting system.webp";
import mindbody from "../../images/about-us-convert/silva healing.webp";


import { Col, Image, Row } from 'react-bootstrap';
import HeadingNew from '../AboutUs/HeadingNew';
import ParaComp from '../SilvaManifestationProgram/ParaComp';

function MemberHeroSection() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 })
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 })
    const imgstyle = { width: "400px", height: "250px", borderRadius: "10px" }
    const marginstyles = { margin: "0 10px 0 10px" }

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: isDesktopOrLaptop ? 4 : isTablet ? 2 : 1,
        slidesToScroll: isDesktopOrLaptop ? 2 : 1,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 4000,
        cssEase: "linear",
        centerMode: isDesktopOrLaptop ? true : isTablet ? true : false,
        // centerPadding: isDesktopOrLaptop?'10%':"0%",
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };

    return (
        <>
            <div className="container mt-5">
                <div className='row justify-content-center'>
                    <div className="col-sm-12 col-md-8">
                       
                        <HeadingNew title={"Learn How to Gain Control Over Your"} colored={"Mind"} />
                        <p className='mt-4 text-center text-dark' style={{ fontSize: "1.1rem", marginLeft: isMobile ? "10px" : "0" }}>
                            Become a Member Today and Learn The Silva Method Techniques
                        </p>
                        <p className='mb-4 text-center text-dark' style={{ fontSize: "1.1rem", marginLeft: isMobile ? "10px" : "0" }}>
                            Get Access to Never Ending Wisdom of Silva through One Membership Only
                        </p>
                        <p className='my-2 text-center text-dark' style={{ fontSize: "1.1rem", marginLeft: isMobile ? "10px" : "0" }}>
                            Embrace growth and success with Silva method courses, empowering you to stand out. Upgrade your skills,
                            level up your businesses, and foster meaningful relationships. Expand your horizons, achieve your dreams,
                            and live a fulfilling life, using the Silva Technique, in just a few clicks on Silva Method Online Meditation Classes.
                        </p>



                    </div>


                </div>
            </div>
           

           
        </>
    )
}

export default MemberHeroSection