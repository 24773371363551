import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import { AuthContext } from '../../../../context/AllContext';

const HealingMeditationTechniques = () => {
    const {isMobile} = useContext(AuthContext)
    const techniques = [
        {
            title: "Visualization",
            img:"/img/bg/bghero/icons/visualization 4.webp",
            description: "Visualization is one of the most crucial techniques in healing meditation. By mentally creating healing images and envisioning yourself in a state of health and balance, you can direct your body's energy to areas in need of attention. This technique allows you to see the healing process unfolding, which can strengthen the mind-body connection."
        },
        {
            title: "Breath Awareness",
            img:"/img/bg/bghero/icons/Breath Awareness.webp",
            description: "Proper breathing is vital in any form of meditation, especially when it comes to self-healing meditation. Deep, focused breathing helps regulate your energy and calms the nervous system. It also aids in releasing tension from the body, enabling a more profound healing experience."
        },
        {
            title: "Affirmations",
            description: "Using positive affirmations is a simple yet powerful tool in your healing meditation practice. Repeating phrases like 'I am healing' or 'My body is restoring itself' can reprogram your mind to focus on wellness. This approach strengthens your intention to heal and encourages the body to respond accordingly.",
            img:"/img/bg/bghero/icons/Affirmation 2.webp"
        },
        {
            title: "Body Scanning",
            description: "This technique involves mentally scanning your body from head to toe, paying attention to sensations or discomfort in specific areas. By identifying where healing is most needed, you can focus your energy on those areas. Body scanning enhances self-awareness and plays a crucial role in self-healing meditation.",
            img:"/img/bg/bghero/icons/Body Scanning.webp"
        },
        {
            title: "Grounding",
            description: "Staying grounded is essential to ensure that healing energy flows smoothly through the body. Grounding techniques like focusing on the soles of your feet or imagining roots growing from your body into the earth can help stabilize your energy and promote healing. It can also prevent feelings of imbalance after your meditation session.",
            img:"/img/bg/bghero/icons/Grounding 2.webp"
        },
        {
            title: "Mindfulness",
            description: "Mindfulness meditation is all about staying present and observing your thoughts without judgment. This technique is essential for healing because it allows you to become aware of negative thought patterns or emotional blockages that may be hindering your recovery. Cultivating mindfulness can bring clarity and facilitate emotional healing.",
            img:"/img/bg/bghero/icons/Mindfulness 1.webp"
        },
    ];

    return (
        <div className="container my-5">
            <h2 className='lora-sans'>
                Techniques You Must Learn for Healing Meditation
            </h2>
            <p className='robotofont'>
                While the benefits of healing meditation are widely acknowledged, it is essential to master specific techniques to make this practice truly effective. These techniques form the foundation of self-healing meditation, enabling individuals to focus their energy and intentions on the areas that need healing. Whether you're engaging in guided meditation for healing or a more independent practice, learning the right techniques can deepen your connection to your inner self and enhance the process of healing the mind and body. Here are some essential techniques you should learn for healing meditation to work effectively:

            </p>

            <Row className={isMobile?'px-3 justify-content-center align-items-center gy-3 ':'px-3 justify-content-center align-items-center gy-4'}>
                {
                    techniques.map((technique) => (
                        <Col xs={12} md={6} lg={4}>


                            <div className="healing-card">
                                <div className="healing-icon-container">
                                    <img src={technique.img}
                                        style={{
                                            width: "100%",
                                            borderRadius:"10px"
                                        }}
                                    />
                                </div>
                                <div className="healing-card-content">
                                    <h3 className="healing-card-title lora-sans">{technique.title}</h3>
                                    <p className="healing-card-description robotofont">
                                        {technique.description}
                                    </p>
                                </div>
                            </div>

                        </Col>
                    ))
                }

            </Row>

            <p className='robotofont mt-4'>
                These techniques, when practiced consistently, can significantly enhance the effectiveness of your healing meditation sessions and help you in healing the mind and body. Whether you prefer guided meditation for healing or exploring free healing meditation resources, learning these foundational methods will empower your journey toward self-healing.
            </p>


        </div>
    );
};

export default HealingMeditationTechniques;
