import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { requestData } from "../../../utils/baseUrl";
import Swal from "sweetalert2";

function PdfDownloadModal({ show, setShow, handleClose }) {


  const [formData,setFormdata]= useState({
    name:"",
    email:"",
    phone_number:"",
  });



  const handleClick= async()=>{
    if(!formData?.email || !formData?.name){
        return toast.error("Please enter your name and email address",{
          position:"top-center",

        })
    };
    const res= await requestData("mindControlBookSelling","POST",{
      name: formData?.name,
      email: formData?.email,
      phone: formData?.phone_number
    });
    if(res.error===false){
      Swal.fire({
        icon: "success",
        title: "Your form has been submitted successfully.",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open("/documents/The_Silva_Mind_Control_Method_Book.pdf")
          handleClose();
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            Fill this form to access the pdf
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
            <Form.Control
              placeholder="Name"
              aria-label="Name"
              aria-describedby="basic-addon1"
              value={formData?.name}
              onChange={(e)=> setFormdata({...formData,name:e.target.value})}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon2"
              value={formData?.email}
              onChange={(e)=> setFormdata({...formData,email:e.target.value})}
            />
            {/* <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text> */}
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Phone Number"
              aria-label="Phone Number"
              aria-describedby="basic-addon2"
              value={formData?.phone_number}
              onChange={(e)=> setFormdata({...formData,phone_number:e.target.value})}
            />
            {/* <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text> */}
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <button class="herodiv-becomememberbtn4 mt-3" onClick={handleClick}>Want to read</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PdfDownloadModal;
