import React from 'react'

function SuccessTest() {
  return (
    <div>
        <h2>Success Test</h2>
    </div>
  )
}

export default SuccessTest