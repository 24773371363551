import React from 'react';

const StressIconCard = ({ title, description, icon }) => {
  const styles = `
    .icon-card {
      background: linear-gradient(135deg, #e0e7ff, #f0f4ff);
      border-radius: 16px;
      padding: 2rem;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      box-shadow: 0 4px 20px rgba(0,0,0,0.06);
    }

    .icon-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 25px rgba(0,0,0,0.1);
    }

    .icon-wrapper {
      display: inline-flex;
      padding: 16px;
      background: #edf2f7;
      border-radius: 12px;
      margin-bottom: 1.5rem;
    }

    .icon {
      width: 32px;
      height: 32px;
      color: #4a90e2;
    }

    .icon-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: #2d3748;
      margin: 0 0 1rem 0;
    }

    .icon-description {
      color: #718096;
      line-height: 1.6;
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="icon-card">
        <div className="icon-wrapper">
          {icon}
        </div>
        <h3 className="text-start">{title}</h3>
        <p className="robotofont mt-3">{description}</p>
      </div>
    </>
  );
};

export default StressIconCard;
