
import { Col, Row } from 'react-bootstrap';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import './manifest.css';
import { VscDebugBreakpointLog } from "react-icons/vsc";

const ManifestationGuide = () => {
  const steps = [
    {
      icon: (
        <img src='/img/combo/brain_1830979.svg' />
      ),
      title: 'Mental Clarity',
      content: 'It is important to have clarity about what you actually want. This clarity helps focus your intentions.'
    },
    {
      icon: (
        <img src='/img/combo/note_3200650.png' />

      ),
      title: 'Affirmations',
      content: 'Use positive affirmations to boost confidence and belief in achieving your goals. For example:',
      extra: ' "I will live in my dream home within five years."'

    },
    {
      icon: (
        <img src='/img/combo/business_10228982.svg' />
      ),
      title: 'Proper Action',
      content: 'Take consistent actions towards your goal. If seeking a new job, commit to applying for one position each week.'
    },
    {
      icon: (
        <img src='/img/combo/lazy_198149.svg' />
      ),
      title: 'Comfort Zone',
      content: 'Step outside your comfort zone gradually. For example, aspiring singers could start by performing for family, then progress to larger audiences.'
    },
    {
      icon: (
        <img src='/img/combo/heart.svg' />
      ),
      title: 'Gratitude',
      content: 'Maintain a grateful mindset. Appreciate what you have while striving for more.'
    },
    {
      icon: (
        <img src='/img/combo/helping-hand.svg' />

      ),
      title: 'Support',
      content: 'Share your goals and challenges with trusted friends or family for encouragement.'
    },
    {
      icon: (
        <img src='/img/combo/customer-service.svg' />
      ),
      title: 'Professional Help',
      content: 'Consider seeking expert guidance for coping with setbacks and staying motivated.'
    }
  ];
  return (
    <div className='container d-flex justify-content-center align-items-center flex-column my-5'>
      <HeadingNew title={"How to Manifest Your Desires:"} small />
      <HeadingNew title={"Simple Steps for "} colored={"Success"} />

      <div className='my-2'>
        <ParaComp data={[
          "Bringing your manifestations to fruition requires following key steps. These guidelines will help you manifest your desires more effectively."
        ]}
          margin={false}
          white={false}

        />
      </div>

      <Row className='justify-content-center align-items-center gy-4'>
        {
          steps.map((val, i) => (
            <Col xs={12} md={6} lg={4}>
              <div class="cardmanifest sweeperCard o-hidden">
                <div class="containers">
                  <div class="icon">
                    {
                      val.icon
                    }
                  </div>
                  <div class="title my-3">
                    {val.title}
                  </div>
                  <div class="subtitle">
                    {
                      val.content
                    }
                  </div>
                  {
                    val.extra && (
                      <p className='subtitle'>
                        {val.extra}


                      </p>
                    )
                  }

                </div>
              </div>
            </Col>
          ))

        }





      </Row>

    </div>
  );
};



export default ManifestationGuide;
