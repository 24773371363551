import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import HeadingNew from "../../AboutUs/HeadingNew";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import CheckboxComponent from "../../newbooking/CustomCheckbox";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function LMHero2() {
  const { isMobile,isTablet } = useContext(AuthContext);

  const list = [
    "lack of self-esteem",
    "lack of communication skills",
    "lack of emotional expression",
  ];

  return (
    <div className="container mt-3">
      <Card className="w-100 cardbdy2 justify-content-center align-items-center" style={{borderRadius:'15px'}}>
        <div className="pt-3">
        <HeadingNew
          title={"What Makes This Course Worth Your Enrollment"}
          small
          // start={true}
          white
        />
        </div>
      <Row
        className="container justify-content-center align-items-center"
        // style={{
        //   margin: "auto",
        // }}
      >
         <Col sm={12} md={12} lg={6} className="d-flex justify-content-center align-items-center p-3" >
          <div>
          <div>
          <Image src="/img/love_mind/image4.webp" style={{borderRadius:'15px',marginBottom:'20px',height:'auto'}}/>
          </div>
          <div>
              <p className="normalpara text-start text-white">
                Wise people say that nobody regretted on their deathbed that
                they didn’t spend more time at work. In those crucial moments,
                their thoughts are usually directed towards relationships.
                Relationships play a key role in our lives. They have a decisive
                influence on our health, well-being, and happiness.
              </p>
          </div>
          </div>
        </Col>
        <Col sm={12} md={12} lg={6} className="p-3">
          
           <div className="my-3">
              <p className="normalpara text-start text-white">
                Love Mind is a unique course covering all aspects of our
                relationships. Begins with our relationship with ourselves, and
                our self-esteem. Then goes on into the area of our relationships
                with our closest ones( emotional partners, children, parents,
                and friends). In the end, it covers our relationship with the
                ‘whole’ ( universe, nature, God…).
              </p>
              <p className="normalpara text-start text-white">
                In Love Mind you will receive the deepest principles and laws
                which regulate all of our relationships. But, much more than
                that, what’s even a greater value of Love Mind, you will learn
                the ‘how’, that is specialized techniques that will allow you to
                significantly improve and upgrade your relationships to a new
                qualitative level.
              </p>
            </div>
            <p className="normalpara text-start text-white">Problems in relationships have 3 major causes:</p>
          <div className="mt-3">
            {isMobile && (
              <Row className="justify-content-start align-items-center">
                {list.map((val, i) => (
                  <>
                    <Col xs={1} md={2}>
                    <IoMdCheckmarkCircleOutline color="white"/>
                    </Col>
                    <Col xs={11} md={10}>
                      <p
                        className="text-start text-white"
                        style={{
                          fontSize: isMobile ? "0.8rem" : "1rem",
                          textAlign: "center",
                          fontWeight: "500",
                          lineHeight: isMobile ? "26px" : "30px",
                          marginLeft: isMobile ? "10px" : "0px",
                        }}
                      >
                        {val}
                      </p>
                    </Col>
                  </>
                ))}
              </Row>
            )}
            {!isMobile &&
              list.map((val, i) => (
                <div
                  className="d-flex justify-content-start align-items-center gap-3 mt-2"
                  key={i}
                >
                  <IoMdCheckmarkCircleOutline color="white"/>
                  <p
                    className="text-start text-white"
                    style={{
                      fontSize: isMobile ? "0.8rem" : "1rem",
                      textAlign: "center",
                      fontWeight: "500",
                      lineHeight: isMobile ? "26px" : "30px",
                      margin:0
                    }}
                  >
                    {val}
                  </p>
                </div>
              ))}

           
          </div>
        </Col>
       
      </Row>
      </Card>
      {/* <Card>
        <Row>
          <Col sm={12} md={12} lg={6}>
          <Image src="/img/img-for-banner.jpg"/>
          </Col>
          <Col sm={12} md={12} lg={6}>
          <HeadingNew
            title={"What Makes This Course Worth Your Enrollment"}
            small
            start={true}
          />
           <div className="my-3">
              <p className="normalpara text-start">
                Wise people say that nobody regretted on their deathbed that
                they didn’t spend more time at work. In those crucial moments,
                their thoughts are usually directed towards relationships.
                Relationships play a key role in our lives. They have a decisive
                influence on our health, well-being, and happiness.
              </p>
              <p className="normalpara text-start">
                Love Mind is a unique course covering all aspects of our
                relationships. Begins with our relationship with ourselves, and
                our self-esteem. Then goes on into the area of our relationships
                with our closest ones( emotional partners, children, parents,
                and friends). In the end, it covers our relationship with the
                ‘whole’ ( universe, nature, God…).
              </p>
              <p className="normalpara text-start">
                In Love Mind you will receive the deepest principles and laws
                which regulate all of our relationships. But, much more than
                that, what’s even a greater value of Love Mind, you will learn
                the ‘how’, that is specialized techniques that will allow you to
                significantly improve and upgrade your relationships to a new
                qualitative level.
              </p>
            </div>
          </Col>
        </Row>
      </Card> */}
    </div>
  );
}

export default LMHero2;
