import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../context/AllContext';
import { AiFillCheckCircle } from "react-icons/ai";
import { handleRefScroll } from '../../../utils/handleScrollEmotions';


function ScrollList() {



    const { isMobile,isTablet } = useContext(AuthContext);

    const masterclassdata = useMemo(() => {
        return [
            "Who is searching for his/her soulmate",
            "Someone desiring long-lasting relationships",
            "Those who are curious about their connection with the Universe",
            "One who can not balance Work and Personal Life",
            "Want to Improve health, relationships, and other areas of life",
            "Who has a Fixed mindset",
            "Who has self-doubts",
            "Who suffers from stress and anxiety",
            "One who can't get out of his guilt feelings",
            "Who endures restless nights due to Worries",
            "One who is seeking inner peace and happiness"
        ]

    }, [])



    return (
        <div>
            <Row className='container justify-content-center align-items-center' style={{
                position: "relative"
            }}>
                <Col xs={12} md={12} lg={6} className={(isMobile || isTablet) ? 'order-2' : 'order-1'}>

                    <Row className='gy-2'>
                        {
                            masterclassdata.map((val, i) => (
                                <Col xs={12} md={6}>
                                    <div className='d-flex gap-3 mb-1' style={{
                                        width: "97%"
                                    }}>

                                        <span><AiFillCheckCircle color='#2ecc71' size={30} /></span>
                                        <p style={{
                                            fontSize: "0.9rem",
                                            color: "white",
                                            fontWeight: "500",
                                            lineHeight: "1.25rem",
                                            margin: "0px"
                                        }}>
                                            {val}
                                        </p>

                                    </div>
                                </Col>


                            ))
                        }
                    </Row>
                    <button className='btnnew22 mt-3' onClick={handleRefScroll}>
                        <svg
                            height="24"
                            width="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="black"
                            ></path>
                        </svg>
                        <span>Enroll Now</span>
                    </button>




                </Col>
                
                        <Col xs={12} md={6} className={(isMobile || isTablet) ? 'order-1' : 'order-2'}>


                        <div className='d-flex justify-content-end align-items-end'>
                            <Tilt style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end"
                            }}>
    
                                <img src='/img/lovemind/perkimg1.png' style={{
                                    width: "100%",
                                }} />
                            </Tilt>
    
                        </div>
                    </Col>
                   

            </Row>

        </div>
    )
}

export default ScrollList