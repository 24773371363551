

import React, { useState, useEffect } from 'react'
import "./singlecourse.css"
import CustomNavbar from './CustomNavbar'
import NewCourseSectionHero from './NewCourseSectionHero'
import NewCourseDetailsBannerSection from './NewCourseDetailsBannerSection'
import CustomImageGalleryWithSidebar from './CourseCurriculumCard'
import HighlightSection from './SuccessBannerSection'
import ReviewSection from './ReviewSection'
import AboutSilvaMethod from '../CoursePage2/AboutSilvaMethod'
import MobileSection from '../CoursePage2/MobileSection'
import ContactSection from '../CoursePage/ContactSection'
import SilvaLifeFAQ from '../SilvaManifestationProgram/SilvaLifeFAQ'
import HeadingNew from '../AboutUs/HeadingNew'
import ComboTestimonials from '../LandingPages/SilvaComboCourse/ComboTestimonials'
import TestimonialSliderCombo from '../LandingPages/ComboCourse2/TestimonialSliderCombo'
import BenefitsSectionCourse from './BenefitsCourseSection'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import { useCallback } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { reqData } from '../../utils/baseUrl'
import LoadingSpinner from '../LoadingSpinner'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill';
import { useRef } from 'react'
import { Card } from 'react-bootstrap'
import InstructorVideoSection from './InstructorVideoSection'
import { useSearchParam } from 'react-use'

function SingleCoursePage() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(0)
  const [courseStatusData, setCourseStatusData] = useState(null)
  const { userData, isMobile } = useContext(AuthContext);
  const [lastCourse, setLastCourse] = useState()
  const courseDescRef = useRef(null)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const tokenParam = searchParams.get('token');


  const {id} = useParams()




  const [course, setCourse] = useState(null)

  const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);
  const [loading, setLoading] = useState(false);








  const scrollTo = () => {
    polyfill()
    const doc = document.querySelector(".newpaymentcard");
    setTimeout(() => {

      scrollIntoView(doc, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    }, 300)
  }
 

  const scrollToNew = () => {
    polyfill()
    const doc = document.querySelector(".bannerdownloadcurri");
    setTimeout(() => {

      scrollIntoView(doc, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    }, 300)
  }



 





  const getCourses = useCallback(async (udata) => {
    const st_id = localStorage.getItem("token")

    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");

    if (st_id) {
      myHeaders.append("studentid", st_id)
    }


    const formData = new FormData();
    formData.append("course_id", id === "silva-love-program" ? "love-program" : id);

    const options = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };

    setLoading(true)
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}courseDetail`,
      options
    );

    setLoading(false)

    const data = await res.json();

    if ((!data || data.error === true)) {
      // setError(true);
      return
    }

    setCourse(data.data);

    const val = {

    }

    let totalNumberOfLessons = 0;
    let totalLessonsCompleted = 0;
    data.data[0].chapters.forEach(chapter => {
      // Add the number of lessons in this chapter to the total number of lessons
      totalNumberOfLessons += chapter.lession.length;

      // Count the completed lessons in this chapter and add to the total
      totalLessonsCompleted += chapter.lession.filter(lesson => lesson.lesson_activity_status === "Completed").length;
    })
    val.totalNumberOfLessons = totalNumberOfLessons;
    val.totalLessonsCompleted = totalLessonsCompleted;

    setCourseStatusData(val)

    const currCourseId = data.data[0].course_id

    const userenrolledcourses = udata ? udata.enrolled_course.map((val) => val.course_id) : []

    if (udata && udata.enrollment_status === "Enrolled" && (userenrolledcourses.includes(currCourseId)
      || userenrolledcourses.length === Number(userData.total_course))) {
      setIsCurrentCoursePurchased(true)
    } else {
      setIsCurrentCoursePurchased(false)

    }


    if (data.data[0].course_description) {
      courseDescRef.current.innerHTML = data.data[0].course_description
    }
  }, [id]);



  const lastViewedCourse = async () => {
    const res = await reqData("getLastVisitedLesson", "POST", {});
    if (res && res.error === false) {
      setLastCourse(res.data)
    }
  }





  useEffect(() => {

    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    if (userData) {
      getCourses(userData);
      lastViewedCourse()
      // getCourseStatus();
    } else {
      getCourses();
    }
  }, [userData, id]);




  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <div className='bgnewcoursedetails'>
      {/* <div className={`navbar-container ${Number(isNavbarVisible)>150?'navbar-visible':'navbar-hidden'}`}>
        <CustomNavbar />
      </div> */}

      <NewCourseSectionHero coursedata={course ? course[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased} lastCourse={lastCourse} courseStatusData={courseStatusData}
        scrollToNew={scrollToNew}
        scrollTo={scrollTo}
        setIsNavbarVisible={setIsNavbarVisible}

      />

      {
        id === "unstress-toolbox" && (
          <InstructorVideoSection />
        )
      }
      <NewCourseDetailsBannerSection courseData={course ? course[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased}
      />

      {
        !isMobile && (
          <BenefitsSectionCourse courseData={course ? course[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased} />
        )
      }
      <HighlightSection />
      <CustomImageGalleryWithSidebar courseData={course ? course[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased} />
      {
        isMobile && (
          <div className='my-3'>
            <BenefitsSectionCourse courseData={course ? course[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased} />
          </div>
        )
      }
      <div className='container'>
        {course && course?.length > 0 && course[0].course_description && (
          <Card
            style={{ width: "100%", borderRadius: "15px", padding: "20px" }}
            className="coursedescriptioncard"
          >
            <Card.Body style={{ maxWidth: "100%" }}>
              {/* <h5 className="text-dark">Description :</h5> */}
              <HeadingNew small title={"Course Description: "} start />
              <div
                className="course_desc"
                ref={courseDescRef}
                style={{ color: "black", overflow: "none" }}
              ></div>
            </Card.Body>
          </Card>
        )}

      </div>
      {/* <ReviewSection /> */}
      <ComboTestimonials />
      <AboutSilvaMethod />
      <TestimonialSliderCombo />
      <MobileSection />

      {true && (
        <div className="container my-4">
          <HeadingNew title={"Frequently Asked Questions"} small start />
          <SilvaLifeFAQ />
        </div>
      )}


      <ContactSection />
      <CustomFooter />

    </div>
  )
}

export default SingleCoursePage