import { scrollIntoView  } from "seamless-scroll-polyfill";
import { polyfill } from "seamless-scroll-polyfill";

export const handleRefScroll = () => {
    polyfill()

    const cardtobescrolled = document.querySelector(".scrollsection");

    setTimeout(() => {

        scrollIntoView(cardtobescrolled,{
            behavior: "smooth",
            block: "end", inline: "nearest"
        });

        // if (cardtobescrolled) {
        //     cardtobescrolled.scrollIntoView({
        //         behavior: "smooth",
        //         block: "end", inline: "nearest"
        //     });
        // }
    }, 500)
}

export const handleScrollCombo = () => {
    polyfill()

    const doc = document.querySelector(".scrolldivnew");
    setTimeout(() => {
        // if (doc) {
        //     doc.scrollIntoView({
        //         behavior: "smooth",
        //         block: "start", // Align the top of the element with the top of the viewport
        //         inline: "nearest", // Scroll until the element is in view
        //     });
        // }
        scrollIntoView(doc,{
            behavior: "smooth",
            block: "end", inline: "nearest"
        });
    }, 500)
}

export const handleScrollCombo2 = () => {
    polyfill()

    const doc = document.querySelector(".tabbarsection")
    setTimeout(() => {
        // if (doc) {
        //     doc.scrollIntoView({
        //         behavior: "smooth",
        //         block: "start", // Align the top of the element with the top of the viewport
        //         inline: "nearest", // Scroll until the element is in view
        //     });
        // }
        scrollIntoView(doc,{
            behavior: "smooth",
            block: "end", inline: "nearest"
        });
    }, 500)
}




export const handleScrollCard = () => {
    polyfill()

    const doc = document.querySelector(".cardtoscroll");

    setTimeout(() => {
        // if (doc) {
        //     doc.scrollIntoView({
        //         behavior: "smooth",
        //         block: "start", // Align the top of the element with the top of the viewport
        //         inline: "nearest", // Scroll until the element is in view
        //     })
        // }
        scrollIntoView(doc,{
            behavior: "smooth",
            block: "end", inline: "nearest"
        });
    }, 500)

}


export const handleScrollCard2 = () => {
    polyfill()

    const doc = document.querySelector(".livecombo");
    setTimeout(() => {
        // if (doc) {
        //     doc.scrollIntoView({
        //         behavior: "smooth",
        //         block: "start", // Align the top of the element with the top of the viewport
        //         inline: "nearest", // Scroll until the element is in view
        //     })
        // }
        scrollIntoView(doc,{
            behavior: "smooth",
            block: "end", inline: "nearest"
        });
    }, 500)

}


export const handleScrollCombo2Curr = () => {
    polyfill()

    const doc = document.querySelector(".curriculumsection");
    setTimeout(() => {
        // if (doc) {
        //     doc.scrollIntoView({
        //         behavior: "smooth",
        //         block: "start", // Align the top of the element with the top of the viewport
        //         inline: "nearest", // Scroll until the element is in view
        //     })
        // }
        scrollIntoView(doc,{
            behavior: "smooth",
            block: "end", inline: "nearest"
        });
    }, 500)


}
export const handleScrollCombo2Class = () => {
    polyfill()

    const doc = document.querySelector(".classsection");
    setTimeout(() => {
        // if (doc) {
        //     doc.scrollIntoView({
        //         behavior: "smooth",
        //         block: "start", // Align the top of the element with the top of the viewport
        //         inline: "nearest", // Scroll until the element is in view
        //     })
        // }
        scrollIntoView(doc,{
            behavior: "smooth",
            block: "end", inline: "nearest"
        });
    }, 500)


}


