import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import { useState } from "react";

function LeftRightSection2() {
    const { isMobile, isTablet } = useContext(AuthContext);
    const [readMore, setReadMore] = useState(false);


    // const handleScroll = () => {
    //     // const cardscroll = document.querySelector(".paymenttwonew");

    //     // if (cardscroll) {
    //     //     cardscroll.scrollIntoView({
    //     //         behavior: "smooth",
    //     //     });
    //     // }
    // };

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymentsection");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };
    
    return (
        <section className={`hero-sectionone container py-${(isMobile) ? 3 : 5}`}>
            <Container fluid>
                <Row className="justify-content-center align-items-center">
                    <Col xs={12} lg={6}>

                        <img src="/img/bg/joebg.png" style={{
                            borderRadius: 15
                        }} />
                        {/* </div> */}
                    </Col>
                    <Col xs={12} lg={6} className={`mb-${(isMobile || isTablet) ? 2 : 0}`}>
                        <div
                            className="content-container"
                            style={{
                                width: (isMobile || isTablet) ? "97%" : "95%",
                                marginLeft: (isMobile || isTablet) ? "0px" : "40px",
                                marginTop: (isMobile || isTablet) ? "30px" : "0px",
                                textAlign: (isMobile || isTablet) ? "center" : "left",
                            }}
                        >
                            <h2 className="newpageheader mb-4 text-end">
                                Conoce a tu Instructor
                            </h2>



                            <p
                                className="normalpara text-end"
                            >
                                Joe Fernando Bacigalupo es un instructor certificado del Método Silva desde el 2002 con más de 30 años de experiencia en liderazgo en diversas industrias. Joe no es solo un instructor; es un orador multilingüe dinámico, consultor de gestión y coach personal y ejecutivo certificado del International Coaching Federation.
                            </p>
                            <p className="normalpara text-end">
                                Como CEO de AlliancesHub International, Joe ha sido la fuerza impulsora detrás de innumerables líderes y profesionales exitosos. Su trayectoria abarca la gestión de operaciones en software de aplicaciones empresariales, consultoría de TI, telecomunicaciones y tecnología. La influencia de Joe se extiende más allá del ámbito de los negocios: Joe se desempeñó como presidente de la primera Junta Asesora de Directores Ejecutivos Latinos en Vistage Worldwide en Estados Unidos y contribuye con una columna de liderazgo bimensual en la revista Latino Leaders desde el 2019.
                            </p>
                            {
                                readMore && (
                                    <>
                                        <p className="normalpara text-end">
                                            Más allá de su impresionante trayectoria profesional, Joe aporta una mezcla única de experiencias. Desde el comienzo de su carrera en la industria de la música como mánager de artistas y productor de radio en Hollywood hasta ser consultor certificado en optimización de talentos, practicante de psicología positiva aplicada e hipnotizador consultor certificado, el conjunto de diversas habilidades de Joe enriquece la experiencia de la Masterclass del Método Silva.
                                        </p>
                                        <p className="normalpara text-end">
                                            Únete a Joe Fernando Bacigalupo en una sesión interactiva transformadora en vivo de 4 días, donde entrenará tu mente para el empoderamiento, te equipará con herramientas y técnicas prácticas, te guiará a través del Método Silva y te ayudará a embarcarte en un viaje de autodescubrimiento. ¡No te pierdas esta oportunidad de aprender de un experto que se dedica a sacar la mejor versión de ti!
                                        </p>
                                    </>
                                )
                            }

                            <div style={{
                                display:"flex",
                                justifyContent:"flex-end",
                                alignItems:"center",
                                marginBottom:15
                            }}>
                            {
                                readMore ? (
                                    <div>
                                        <a style={{
                                            color: "blue",
                                            cursor: "pointer"
                                        }}
                                            onClick={() => {
                                                setReadMore(false)
                                            }}

                                        >Read Less</a>
                                    </div>
                                ) : (
                                    <div>
                                        <a style={{
                                            color: "blue",
                                            cursor: "pointer"

                                        }}
                                            onClick={() => {
                                                setReadMore(true)
                                            }}

                                        >Read More</a>
                                    </div>
                                )
                            }
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center"
                            }}>
                                <button
                                    className="herodiv-becomememberbtnnew"
                                    onClick={handleScroll}

                                >
                                    Enlístate ahora
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LeftRightSection2;
