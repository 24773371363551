import React from 'react'
import { useMediaQuery } from 'react-responsive'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { useNavigate } from 'react-router-dom'

function SuccessBanner() {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 767 })


    const handleScroll = ()=>{
        document.querySelector(".indivcourses").scrollIntoView({
            behavior:"smooth"
        })
    }
    return (
        <div className='container' style={{marginBottom:"30px"}}>
            <div className='row justify-content-center align-items-center gap-4'>
                {
                    isDesktopOrLaptop && (
                        <div className="col-4">
                            <img src={require("../../images/membership/Collage Left.webp")} alt="" />
                        </div>
                    )
                }
                {
                    (isTablet||isMobile) && (
                        <div className="col-12">
                            <img src={require("../../images/membership/Collage Mobile Tab.webp")} alt="" />
                        </div>
                    )
                }
                <div className="col-sm-12 col-lg-3 p-2">
                    <h1 style={{ color: "#6f25af" }}>From Struggle to Triumph</h1>
                    <h2>Inspiring Case Studies</h2>
                   
                    <ParaComp
                    white={false}
                    data={[
                        "For over five decades, the Silva Method has impacted millions of lives worldwide, including celebrities, CEOs, and everyday individuals seeking personal breakthroughs. More than 12 million people in 110 countries have experienced what Silva Mind control meditation can do. Get inspired by our global community",
                    ]}
                    />
               
                </div>
                {
                    isDesktopOrLaptop && (
                        <div className="col-4">
                            <img src={require("../../images/membership/Collage Right.webp")} alt="" />
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default SuccessBanner