import React, { useState } from 'react'
import { Image, Modal, Spinner } from 'react-bootstrap'
import { AiFillCloseCircle } from "react-icons/ai"
import ParaComp from '../../pages/SilvaManifestationProgram/ParaComp'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import HeadingNew from '../../pages/AboutUs/HeadingNew'
import { requestData } from '../../utils/baseUrl'
import { toast } from 'react-hot-toast'
import HeadingModal from '../../pages/AboutUs/HeadingModal'

function EmailNewsLetterModal({ show, handleClose }) {
  const { isMobile } = useContext(AuthContext);
  const [loading,setLoading] = useState(false)
  const [values,setValues] = useState({
    email:"",
    phone:"",
    name:"",
    source:""
  })

  function isNumeric(value) {
    return /^\d+$/.test(value);
  }
  
  // Check if the pressed key is a number
  function isNumericInput(event) {
    const key = event.key;
    return /^[0-9]+$/.test(key);
  }


  const handleSubmit = async(e)=>{
    e.preventDefault()
    const {email,phone,name,source} = values
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values.email);

    if(!isValidEmail){
      toast.error("Please enter a valid email",{
        position:"top-center"
      })
      return
    }
    const data={}

    if(email){
      data.email=email
    }
    if(phone){
      data.phone = phone
    }
    if(name){
      data.name=name
    }
    if(source){
      data.source=source
    }

    setLoading(true)
    const res = await requestData("welcomeForm","POST",data)
    setLoading(false)
    if(res && res.error===false){
      toast.success("Thank you! Your Relaxation Exercise is on the way. Kindly check your mail and get relaxed with Silva method",{
        position:"top-center",
        duration:4000
      })
      setValues({
        email:"",
        phone:"",
        source:"",
        name:""
      })
      handleClose()
    }else{
      toast.error(res.messages,{
        position:"top-center",
        duration:4000
      })
    }
  }
  return (
    <Modal show={show} onHide={handleClose} size='lg' centered backdrop="static"
      style={{ borderRadius: "30px", height: "100%" }} className='emailnewsmodal'>

      <Modal.Body className='d-flex justify-content-center align-items-start gap-3'>
        {
          !isMobile && (
            <div style={{ width: "100%", height: "100%" }}>
              <Image style={{ height: "100%" }} className='newsletterimg' fluid src={require("../../images/adimgs/Instructors.webp")} />
            </div>
          )
        }

        <div className='d-flex justify-content-between align-items-center flex-column ' style={{padding:"10px 25px 10px 15px" }}>
          <span style={{ alignSelf: "flex-end" }} onClick={handleClose}>
            <AiFillCloseCircle color='#7a12d4' size={30} />
          </span>
          <div className='d-flex justify-content-center align-items-center flex-column'>
            {/* <Modal.Title className='modaltitlenew mb-2'>
               Silva Method
            </Modal.Title> */}
            <div className='mb-2'>
            <HeadingModal small start title={"Hey beautiful soul, Welcome to"} colored={"Silva Method"} />
            </div>
            <ParaComp
              data={[
                "Get the FREE relaxation exercise as a token of love to guide you into a state of deep relaxation"
              ]}
              white={false}
              margin={false}
            />

            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <input type='text' className='modalemailsubs' placeholder='Your Email *'
              onChange={(e)=>setValues({...values,email:e.target.value})}
               />
              <input type='text' className='modalemailsubs mt-3' placeholder='Your Full Name'
              onChange={(e)=>setValues({...values,name:e.target.value})}
              />
              <input type='text' className='modalemailsubs mt-3' placeholder='Your Phone'
                onChange={(e) => {
                  const phoneValue = e.target.value;
                  if(!phoneValue){
                    setValues({ ...values, phone: phoneValue });
                  }
                  if (isNumeric(phoneValue)) {
                    setValues({ ...values, phone: phoneValue });
                  }
                }}
                onKeyPress={(e) => {
                  if (!isNumericInput(e)) {
                    e.preventDefault();
                  }
                }}
              />
              <select className='modalemailsubs mt-3' value={values.source} onChange={(e)=>{
                if(e.target.value!=="Where Did you hear about us"){
                  setValues({...values,source:e.target.value})
                }else{
                  setValues({...values,source:""})
                }
              }}>
                <option defaultChecked>Where Did you hear about us</option>
                <option value="Social Media Ads">Social Media Ads</option>
                <option value="Google Ads">Google Ads</option>
                <option value="Facebook Ads">Facebook Ads</option>
                <option value="Friends and Family">Friends and Family</option>
                <option value="Others">Others</option>
              </select>
              <button className='herodiv-becomememberbtn2 mt-3 text-center'>
                {
                  loading ? (
                    <Spinner animation="border" variant="dark" />
                  ):"Get Now"
                }
              </button>
            </form>
          </div>
        </div>

      </Modal.Body>
    </Modal>
  )
}

export default EmailNewsLetterModal