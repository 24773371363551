import React from "react";

function NewFooter() {
  return (
    <footer
      className="py-3"
      style={{ backgroundColor: "#f4f5f9", width: "100%", height: "100%" }}
    >
      <p className="text-center" style={{ fontSize: "16px" }}>
        Copyright &copy; silvamethod.com
      </p>
    </footer>
  );
}

export default NewFooter;
