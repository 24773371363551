import { Card, Image } from 'react-bootstrap'

function TestimonialCard({ data }) {
    return (
        <Card style={{
            background: "rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)",
            border: "1px solid white",
            color: "white",
            width: "auto",
            padding: "2px 8px",
            borderRadius: "8px",
        }}>
            <Card.Body className='d-flex justify-content-start flex-column gap-2'>
                <div className='d-flex gap-4 justify-content-start align-items-center'>
                    <Image
                        src={data.image}
                        width={80}
                        height={80}
                        style={{
                            borderRadius: "50%"
                        }}
                    />
                    <div className='d-flex flex-column'>
                        <h4 className='white-color'>{data.name}</h4>

                        <p style={{
                            lineHeight: "23px",
                            color:"#dfe6e9"
                        }}>
                            {data.designation}
                        </p>
                    </div>
                </div>
                <div>
                    <p style={{
                        lineHeight: "23px",
                        color:"#dfe6e9"


                    }}>
                        {data.comment}
                    </p>
                </div>

            </Card.Body>


        </Card>
    )
}

export default TestimonialCard