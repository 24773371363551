import React, { useContext, useEffect, useState } from 'react'
import NewHeader from '../../AdvertisePages/newmembership/NewHeader'
import SecondHeroSection from '../../booking/SecondHeroSection'
import SecondHypnosisHero from './SecondHypnosisHero'
import TrustedBy from '../../Draganpages/TrustedBy'
import { AuthContext } from '../../../context/AllContext'
import { reqData } from '../../../utils/baseUrl'
import HypnoisComboCard from '../ComboCourse/HypnosisComboCard'
import HowtoEleminate from './HowtoEleminate'
import Boost from './Boost'
import BenifitSectionHyp from '../HypnosisPage/BenefitsSectionHyp'
import FormSection from '../HypnosisPage/FormSection'
import NewFooter from './NewFooter'
import NewComboBanner from '../ComboCourse/NewComboBanner'

function SecondHypnosis() {

    const [showCard, setShowCard] = useState(false);

    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { userData, isBigScreen, isDesktopOrLaptop, isTablet } = useContext(AuthContext);

    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "combo-plan",
        });

        // console.log(res.data[0].course_description, "Response")


        setLoading(false);
        if (res.error === false) {
            setCourseData(res.data);
        }
    };


    useEffect(() => {
        getCourseData();
    }, [userData]);


  return (
    <div>
      <NewHeader />
      <div className="parallexnew22">
        <SecondHypnosisHero />
        <TrustedBy />
        <HowtoEleminate />
        <BenifitSectionHyp />
        {/* <NewComboBanner/> */}
        <Boost />
        {courseData && courseData[0] && (
          <HypnoisComboCard
            data={courseData}
            showCard={showCard}
            setShowCard={setShowCard}
          />
        )}
        <div className="d-flex justify-content-center align-items-center">
          <img
            src="/img/hypnosis/infographics.png"
            style={{
              borderRadius: 10,
              marginTop: 10,
            }}
          />
        </div>
        <FormSection title={"Submit your details to receive a free financial stress relief meditation."}/>
      </div>
      <NewFooter/>
    </div>
  );
}

export default SecondHypnosis