

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';

function SpanishHeroSection() {
    const { isMobile } = useContext(AuthContext);

    const [imageIndex, setImageIndex] = useState(0);
    const images = [
        "/img/spanish/1.jpg",
        // "/img/lovemind/2.png",
        // "/img/lovemind/3.png",
    ];

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setImageIndex(prevIndex => (prevIndex + 1) % images.length);
    //     }, 5000);

    //     return () => clearInterval(intervalId);
    // }, []);

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymentsection");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };


    return (
        <div style={{
            width: "100%",
            position: "relative",
            height: isMobile ? "60vh" : "80vh"
        }}>
            <div style={{
                position: "absolute",
                left: isMobile ? "2%" : "10%",
                top: isMobile ? "15%" : "30%",
                padding: isMobile ? "10px" : "0px",
                zIndex: 2,
                width: isMobile ? "96%" : "40%"
            }}>
                <HeadingNew small title={"Toma el control total de tu mente con el método Silva"} start white  />

                <HeadingNew title={"Ciclo de Conferencias Básicas del Método Silva"} white start />
                <TypeAnimation
                    sequence={[
                        'Masterclass en Vivo',
                        200,
                        'Masterclass en Vivo', //  Continuing previous Text
                        200,
                        'Masterclass en Vivo',
                        200,
                        'Masterclass en Vivo',
                        200,
                        'Masterclass en Vivo',
                        200,
                        '',
                        200,
                    ]}
                    wrapper="span"

                    style={{ fontSize: isMobile ? "2em" : '3.5em', display: 'inline-block', fontWeight: "bold", color: "#FFC815" }}
                    repeat={Infinity}
                />
                {
                    isMobile ? (
                        <p className='my-3 white-color' style={{
                            lineHeight: isMobile ? "22px" : "inherit"
                        }}>
                            La Masterclass en Vivo del Ciclo de Conferencias Básicas del Método Silva es un Programa de Desarrollo Mental especialmente diseñado para ayudarte a acceder a un estado mental alterado y descubrir la mejor versión de ti.
                        </p>
                    ) : (
                        <p className='my-3 white-color'>
                            La Masterclass en Vivo del Ciclo de Conferencias Básicas del Método Silva es un Programa de Desarrollo Mental especialmente diseñado para ayudarte a acceder a un estado mental alterado y descubrir la mejor versión de ti.
                        </p>
                    )
                }

                <button className='primary_btn3' style={{
                    backgroundImage: "linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%)",
                    color: "black",
                    fontWeight: "600"
                }}
                onClick={handleScroll}
                >
                    Enlístate ahora
                </button>

            </div>

            {images.map((imageUrl, index) => (
                <img
                    key={index}
                    src={imageUrl}
                    alt={`Slide ${index + 1}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: index === imageIndex ? 1 : 0,
                        opacity: index === imageIndex ? 1 : 0,
                        transition: 'opacity 1s ease-in-out',
                    }}
                />
            ))}

            {/* <div style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 3,
            }}>
                {images.map((_, index) => (
                    <span
                        key={index}
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: index === imageIndex ? "#fff" : "rgba(255, 255, 255, 0.5)",
                            margin: "0 5px",
                            cursor: "pointer"
                        }}
                        onClick={() => setImageIndex(index)}
                    />
                ))}
            </div> */}
            <div style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                background: isMobile ? "rgba(0,0,0,0.4)" : (imageIndex === 1 ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.2)"),
                zIndex: 1,
                transition: "background-color 1s ease-in-out",
            }}>

            </div>

        </div>
    )
}

export default SpanishHeroSection