import React, { useContext } from 'react'
import { Card, Col, Form, Image, Row } from 'react-bootstrap'
import HeadingNew from '../AboutUs/HeadingNew'
import { CiLock } from "react-icons/ci";
import { reqData } from '../../utils/baseUrl';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useEffect } from 'react';
import { AuthContext } from '../../context/AllContext';

function PaymentSection({ data }) {
    const [loading, setLoading] = useState(false);
    const [hasEmail, setHasEmail] = useState(false);
    const [userEmail, setUserEmail] = useState("")
    const { userData } = useContext(AuthContext);
    const [liveEventsData, setLiveEventsData] = useState(null);

    // console.log(data, "dataaa")


    const handleClick = async () => {

        if (!userEmail) {
            toast.error("Please enter an email to continue")
            return
        }

        try {
            setLoading(true)
            const res = await reqData("liveClassPayment", "POST", {
                email: userEmail,
                event_id: liveEventsData.event_id,
                payment_gateway: "Stripe"

            });
            setLoading(false)

            if (res && res.error === false) {
                window.location.href = res.data
            } else {
                toast.error(res.error)
            }

        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        if (userData && userData.email) {
            setHasEmail(true)
            setUserEmail(userData.email)
        } else {
            setHasEmail(false)
        }
    }, [userData])

    useEffect(() => {
        internationalEvents()
    }, [])

    const internationalEvents = async () => {
        try {

            const res = await reqData("internationalEventList", "POST", {})

            let found;

            if (res && res.error === false) {
                found = res.data.find((val, i) => val.event_id === "silva-life-system")
            }


            if (found) {
                setLiveEventsData(found)
            }




        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div className='container py-5 paymentsection'>
            <Row className='justify-content-center align-items-center'>
                <Col md={7}>
                    <HeadingNew
                        title={"Say YES To Silva Method Online Live Learning Experience"}
                    />
                    <Image
                        src='/silvapic.png'
                    />
                </Col>
                <Col md={5}>
                    <div className='d-flex justify-content-center align-items-center w-100'>
                        <Card className='paymentcardnewdata mt-4 mt-md-0'>
                            <Card.Body className='paymentcardnewdata2'>
                                <h4 className='text-center pt-3 pb-4 fw-700' style={{
                                    color: "black",
                                    borderBottom: "1px solid lightgray"
                                }}>
                                    Reserve your spot
                                </h4>

                                <h3 className='text-center py-4 fw-700' style={{
                                    color: "black",
                                }}>
                                    Pay once and enjoy the benefits for your entire lifetime
                                </h3>

                                {
                                    data && liveEventsData && (
                                        <h3 className='pricecut'>{liveEventsData.currency === "USD" ? "$" : "₹"} {liveEventsData?.price}</h3>
                                    )
                                }
                                {
                                    data && liveEventsData && (
                                        <h3 className='pricecut2'>{liveEventsData.currency === "USD" ? "$" : "₹"}{" "}
                                             {Number(liveEventsData.price) - (Number(liveEventsData.price) * (Number(liveEventsData.discount_percentage) / 100))}
                                        </h3>
                                    )
                                }
                                <p className='text-center' style={{
                                    lineHeight: "20px"
                                }}>
                                    Lets begin the life transformation journey together

                                </p>

                                {
                                    hasEmail === false && (
                                        <div>
                                            <label htmlFor="emailinput" className='couponinputlabel'>Your Email Id <span style={{ color: "red" }}>*</span></label>
                                            <Form.Control
                                                placeholder='Type Here'
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                id='emailinput'
                                                className='couponinputfield'
                                                value={userEmail}
                                                onChange={(e) => setUserEmail(e.target.value)}
                                            />
                                        </div>
                                    )
                                }
                            </Card.Body>
                            <Card.Footer className='d-flex justify-content-center align-items-center py-4'>
                                <button className='primary_btn' onClick={handleClick} disabled={loading}>
                                    {
                                        loading ? "...please wait" : "Join The Seminar"
                                    }
                                </button>
                            </Card.Footer>
                        </Card>
                    </div>


                    <div className='d-flex justify-content-center align-items-center gap-2'>
                        <span className='mb-3'>
                            <CiLock />
                        </span>
                        <p>
                            For your security, all orders are processed on a secured server.
                        </p>

                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default PaymentSection