import React, { useEffect } from 'react'
import Footer from '../../../components/Shared/Footer'
import NewHeader from '../../AdvertisePages/newmembership/NewHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import VideoBackground from './VideoBackground'
import { Image } from 'react-bootstrap'
import TrustedBy from '../../Draganpages/TrustedBy'
import FormSection from './FormSection'
import "./video-background.css"
import BenefitsFree from './BenefitsFree'
import BenifitSectionHyp from '../HypnosisPage/BenefitsSectionHyp'
import ReviewsContainer from '../../../components/DifferenceSection/ReviewsContainer'
import BreakThroughs2 from '../ComboCourse2/BreakThroughs2'
import ComboContactSection from '../ComboCourse2/ComboContactSection'
import NewFooter from './NewFooter'

function FreeCourse() {


  return (
    <>
        <NewHeader />
        <VideoBackground />
        <TrustedBy />
        <div className='newsec'>

        <FormSection />

        </div>
        <BenefitsFree />
        <BenifitSectionHyp btnlink />
        <div style={{
          paddingTop:"50px",
          paddingBottom:"50px"
        }}>
        <BreakThroughs2 btnbtn />
        </div>

        <ReviewsContainer />
        <div className='py-5'>
          <ComboContactSection />

        </div>
        {/* <CustomFooter /> */}
        <NewFooter />
    </>
  )
}

export default FreeCourse