import React, { useContext, useEffect, useState } from 'react'
import Collapsible from 'react-collapsible';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AllContext';
import { useMediaQuery } from 'react-responsive';
import { BsCheckCircleFill } from "react-icons/bs"
import { HiOutlineArrowRight } from "react-icons/hi"
import { AiFillLock } from "react-icons/ai"
import { Badge } from 'react-bootstrap';

function ComboAccordion({ data, index, isCurrentCoursePurchased }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };


    const [isSubscribed, setIsSubscribed] = useState(false);
    //console.log(data.lession);
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    const { userData, isMobile } = useContext(AuthContext);

    useEffect(() => {
        if (userData) {
            if (userData.enrollment_status === "Enrolled") {
                setIsSubscribed(true)
            }
        }
    }, [userData])






    if(!data){
        return null
    }


    return (

        <Collapsible transitionTime={300} trigger={<Trigger isOpen={isOpen} index={index}
            data={data}
        />} onOpening={handleToggle}

            onClose={handleToggle}
            style={{
                width: "100%",

            }}
            className='listlesson'
        >
            <div style={{
                padding: "20px",
                background: "#f8f4fb",
                width: "100%",
                borderBottomLeftRadius:15,
                borderBottomRightRadius:15,
                // boxShadow:isOpen?"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px":"none"


            }}>
                <li style={{
                    listStyleType:"none"
                }}>
                    {
                        data && data.lession.map((val, idx) => (


                            <ul className={`d-flex justify-content-between mt-3 pb-2`}
                                style={{ borderBottom: "2px solid #826beb" }}>

                                <div className='d-flex justify-content-start align-items-start gap-3 mt-2'>
                                    <p style={{ fontSize: isDesktopOrLaptop ? "1rem" : "0.8rem", fontWeight: "500",lineHeight:"1.1rem" }}>{val.lesson_title}</p>

                                </div>




                             
                                {
                                    (isCurrentCoursePurchased || val.preview_available === "Yes") ? (
                                        <div className={`d-flex justify-content-center align-items-center ${isDesktopOrLaptop ? "gap-4" : "flex-column gap-1"}`}>
                                            <Badge className='badgenew' bg="light" style={{ color: "black" }} onClick={() => navigate(`/store/course/${data.course_id}/${data.chapter_id}/${val.lesson_id}`, { state: val })}>
                                                {
                                                    isSubscribed ? "View" : "Preview"
                                                }
                                            </Badge>


                                            {
                                                val?.lesson_activity_status === "Completed" && (
                                                    <BsCheckCircleFill className="scrollto" size={30} color={"#2ecc71"} />
                                                )
                                            }

                                            {
                                                val?.lesson_activity_status !== "Completed" && (
                                                    <HiOutlineArrowRight size={30} color='#000'
                                                        onClick={() => navigate(`/store/course/${data.course_id}/${data.chapter_id}/${val.lesson_id}`, { state: val })} />
                                                )
                                            }

                                        </div>
                                    ) : (
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiFillLock size={30} color='#000' />
                                        </div>
                                    )
                                }
                            </ul>
                        ))
                    }
                </li>
            </div>
        </Collapsible>


    )
}

export default ComboAccordion



function Trigger({ isOpen, index, data }) {

    const {isDesktopOrLaptop} = useContext(AuthContext);

    return (
        <div className={`d-flex gap-3 justify-content-between align-items-center ${isOpen ? "bordernone" : "hoveracc"}`} style={{
            background: isOpen
                ? 'linear-gradient(90deg, rgba(75, 55, 166, 1) 35%, rgba(129, 90, 238, 1) 100%)'
                : '#f8f4fb',

            padding: "20px",
            borderTopLeftRadius:15,
            borderTopRightRadius:15,
            borderBottomLeftRadius:isOpen?0:15,
            borderBottomRightRadius:isOpen?0:15,
            boxShadow:isOpen?"none":"rgba(0, 0, 0, 0.1) 0px 15px 20px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px"

        }}>
            <div className='d-flex gap-3 align-items-center'>
                <button style={{
                    all: "unset",
                    padding: "4px 8px",
                    color: isOpen ? "black" : "white",
                    borderRadius: 5,
                    background: isOpen ? "white" : "#233645",
                    fontWeight: "600",
                    fontSize: "1.1rem"
                }}
                    className='btncomboaccnew'
                >
                    {index + 1 < 10 ? "0" + (index + 1) : index + 1}
                </button>
                <p style={{
                    color: isOpen ? "white" : "black",
                    padding: 0,
                    margin: 0,
                    fontSize: isDesktopOrLaptop ? "1rem" : "0.9rem", fontWeight: "500",
                    lineHeight:"1.1rem"

                }}>
                    {data && data.chapter_title}
                </p>
            </div>
            <IoIosArrowDown className={`${isOpen ? "openicon" : ""}`} color={isOpen ? "white" : "black"} size={18} />

        </div>
    )
}