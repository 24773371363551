import React, { useContext } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";

function NewComboFaqs({faqs,extraClass}) {
 

  const { isMobile } = useContext(AuthContext);

  return (
    <div className={`theme-dark newcombofaqscont ${extraClass || ""}`}>
      <Container>
        <div className="pb-3">
          <p
            className="h1 text-white text-center fw-bold"
            style={{
              fontSize: isMobile ? "23px" : "",
            }}
          >
            FAQs
          </p>
          {/* <p
            className="h1 text-white text-center fw-bold"
            style={{
              fontSize: isMobile ? "23px" : "",
            }}
          >
            on Silva Method Habit Control Technique
          </p> */}
        </div>
        <Row>
          {faqs.map((faq, i) => (
            <Col sm={12} md={12} lg={6} className="px-3">
              <Accordion className="my-2">
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>{faq.title}</Accordion.Header>
                  <Accordion.Body>{faq.text}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default NewComboFaqs;
