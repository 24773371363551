import React from "react";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HeadingComp from "../SilvaManifestationProgram/HeadingComp";
import BasicFooter from "./BasicFooter";
import FirstContent from "./FirstContent";
import GatwaySection from "./GatwaySection";
import TandC from "./TandC";
import TFTFaqs from "../LandingPages/ThreeFingerTechnique/TFTFaqs";
import RefundBanner from "../CoursePage/RefundBanner";

function Refund() {

  const refundFaqs = [
    {
      heading: "How do I get a refund for a course?",
      body: "Send us an email at digital@silvamethod.com from your valid Email ID, make sure to include details about your problem which will help us understand your situation better, and then send us a refund request.",
    },
    {
      heading: "Can you refund an online course?",
      body: "Yes, you can get a refund for all silva digital courses that you have purchased from Silva Method official Website. Follow our refund policy, and provide the necessary details for a smooth process.",
    },
    {
        heading: "How long should an online refund take?",
        body: "You will get your money back within 30 days of your refund request.",
    },
    {
        heading: "Does everyone get a refund without any valid reason?",
        body: "Your satisfaction is our priority and we believe people will send us genuine feedback.",
    },
  ];

  return (
    <div>
      <HomeHeader />
      <FirstContent />
      <GatwaySection />
      {/* <div className="container">
      <RefundBanner/>
      </div> */}
      <TandC />
      <TFTFaqs data={refundFaqs}/>
      <BasicFooter />
    </div>
  );
}

export default Refund;
