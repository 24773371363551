import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";

function HeadingNewBlackFriday({
  title,
  colored,
  small = false,
  boxed = false,
  boxedtext = "",
  white = false,
  bottomMargin = false,
  start = false,
  marginleft = false,
  underline = false,
  right,
}) {
  const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } =
    useContext(AuthContext);

  // Base heading styles
  const headingStyles = {
    fontWeight: small ? "700" : "800",
    fontSize: isBigScreen
      ? small
        ? "24px"
        : "48px"
      : isDesktopOrLaptop
      ? small
        ? "22px"
        : "42px"
      : isTablet
      ? small
        ? "20px"
        : "32px"
      : isMobile
      ? small
        ? "22px"
        : "28px"
      : "20px",
    textAlign: right ? "right" : start ? "start" : "center",
    color: white ? "white" : "#0F131B",
    marginBottom: bottomMargin ? "25px" : "0",
    marginLeft: marginleft && !isMobile ? "18px" : "0px",
    lineHeight: !small
      ? isBigScreen
        ? "56px"
        : isDesktopOrLaptop
        ? "48px"
        : isTablet
        ? "40px"
        : "36px"
      : isBigScreen
      ? "32px"
      : isDesktopOrLaptop
      ? "30px"
      : isTablet
      ? "28px"
      : "24px",
    letterSpacing: isMobile || isTablet ? "0.1px" : "0.5px",
    textDecoration: isMobile || isTablet ? "none" : underline ? "underline" : "none",
    textDecorationThickness: underline ? "0.2rem" : "0",
    textUnderlineOffset: underline ? "1rem" : "0",
    textDecorationColor: underline ? "rgb(216, 1, 251)" : "none",
    wordBreak: "break-word",
  };

  // Gradient text styles
  const gradientStyles = {
    background: "linear-gradient(117deg, rgba(113,1,0,1) 0%, rgba(196,0,5,1) 49%)",
    backgroundSize: "200% 200%",
    animation: "gradient 5s ease infinite",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "800",
    fontFamily: "Poppins",
    display: "inline-block",
    fontSize: "inherit",
    lineHeight: "inherit",
  };

  // Boxed text styles
  const boxedStyles = {
    padding: isMobile ? "8px 12px" : "12px 20px",
    color: "#fab80a",
    border: "none",
    backgroundColor: "white",
    fontSize: isBigScreen
      ? small
        ? "22px"
        : "38px"
      : isDesktopOrLaptop
      ? small
        ? "20px"
        : "34px"
      : isTablet
      ? small
        ? "18px"
        : "28px"
      : small
      ? "16px"
      : "24px",
    lineHeight: isBigScreen
      ? "46px"
      : isDesktopOrLaptop
      ? "40px"
      : isTablet
      ? "36px"
      : "32px",
    fontWeight: "800",
    borderRadius: "8px",
    display: "inline-block",
    wordBreak: "break-word",
    margin: "8px 0",
    verticalAlign: "middle",
  };

  return (
    <div>
      <h2 style={headingStyles}>
        {title}{" "}
        {colored && <span style={gradientStyles}>{colored}</span>}{" "}
        {boxed && <span style={boxedStyles}>{boxedtext}</span>}
      </h2>
      <style>
        {`
          @keyframes gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}
      </style>
    </div>
  );
}

export default HeadingNewBlackFriday;