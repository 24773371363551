import React from 'react';

const StressResources = () => {
  const stressSignsList = [
    'Persistent worry and anxiety',
    'Feeling constantly overwhelmed',
    'Mood fluctuations',
    'Irritability and a short temper',
    'Disruptions in sleep patterns',
    'Shifts in eating habits',
    'Growing dependency on alcohol, tobacco, or other substances to relax',
    'Muscle tightness or tension',
    'Nausea or dizziness'
  ];

  const styles = `
    .stress-container {
    
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .section {
      background: white;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0 10px 30px rgba(124, 58, 237, 0.1);
      transition: transform 0.3s ease;
    }

    .section:hover {
      transform: translateY(-5px);
    }

    .stress-impact {
      background: linear-gradient(135deg, #7c3aed 0%, #4c1d95 100%);
      padding: 60px;
      color: white;
      position: relative;
    }

    .impact-pattern {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(circle at 2px 2px, rgba(255, 255, 255, 0.1) 1px, transparent 0);
      background-size: 30px 30px;
      opacity: 0.3;
    }

    .section-title {
      font-size: 2.5rem;
      font-weight: 800;
      margin: 0 0 30px 0;
      line-height: 1.2;
      position: relative;
      z-index: 1;
    }

    .section-text {
      font-size: 1.125rem;
      line-height: 1.8;
      margin: 0;
      position: relative;
      z-index: 1;
      color: rgba(255, 255, 255, 0.9);
    }

    .stress-resources {
      padding: 60px;
      background: white;
    }

    .stress-title {
      color: #4c1d95;
      font-size: 2.5rem;
      font-weight: 800;
      margin: 0 0 30px 0;
      line-height: 1.2;
    }

    .resources-text {
      color: #4b5563;
      font-size: 1.125rem;
      line-height: 1.8;
      margin-bottom: 40px;
    }

    .signs-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .sign-item {
      background: linear-gradient(135deg, #f5f3ff 0%, #faf5ff 100%);
      padding: 20px 25px;
      border-radius: 12px;
      color: #4c1d95;
      display: flex;
      align-items: center;
      font-size: 1.1rem;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .sign-item:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(124, 58, 237, 0.1);
    }

    .sign-item::before {
      content: '•';
      color: #7c3aed;
      margin-right: 12px;
      font-size: 1.5rem;
      line-height: 1;
    }

    .reflection-text {
      color: #4b5563;
      font-size: 1.125rem;
      line-height: 1.8;
      margin-top: 40px;
      padding: 30px;
      background: linear-gradient(135deg, #f5f3ff 0%, #faf5ff 100%);
      border-radius: 12px;
    }

    @media (max-width: 768px) {
      .stress-impact,
      .stress-resources {
        padding: 40px 30px;
      }

      .section-title,
      .stress-title {
        font-size: 2rem;
      }

      .signs-list {
        grid-template-columns: 1fr;
      }
    }

    @media (max-width: 480px) {
      .stress-container {
        padding: 20px 15px;
      }

      .stress-impact,
      .stress-resources {
        padding: 30px 20px;
      }

      .section-title,
      .stress-title {
        font-size: 1.75rem;
      }

      .section-text,
      .resources-text,
      .reflection-text {
        font-size: 1rem;
      }

      .sign-item {
        font-size: 1rem;
        padding: 15px 20px;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="stress-container">
        <div className="content-wrapper">
          

          <section className="section">
            <div className="stress-resources">
              <h2 className="stress-title">Identify Your Stress Resources</h2>
              <p className="resources-text">
                Experiencing stress is common but it affects individuals in different ways. Everyone's triggers are different—maybe it's deadlines piling up, financial worries, or tension in relationships. Sometimes, it's the small things, like a messy room or too much time on your phone, that gradually wear you down. Understanding your stress resources is a powerful step toward finding stress support.
              </p>
              
              <ul className="signs-list">
                {stressSignsList.map((sign, index) => (
                  <li key={index} className="sign-item">{sign}</li>
                ))}
              </ul>

              <p className="reflection-text">
                The best way to understand your stress triggers is by paying attention to your everyday experiences. Keep noticing and journaling the situations or events that consistently make you feel anxious or overwhelmed. Try to recognize how these stressors impact your physical, emotional, and mental well-being. Are they disrupting your sleep, causing digestive problems, or affecting your appetite? Do they lead to feelings of sadness, anger, or frustration?
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default StressResources;