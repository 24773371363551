import React from 'react'

import LifeSystemExtraBanners from '../seminars/LifeSystemExtraBanners'
import HeadingNew from '../AboutUs/HeadingNew'

function MemberShipFeatures() {

    return (
        <div className='container'>
            <div className="row justify-content-center p-3">
                <div className="col-sm-12 col-md-10 col-lg-8">
                    {/* <h2 className='text-center' style={{fontSize: "2rem",}}>
                    Unlock the Learning Experiences That Awaits You
                    </h2> */}
                    <HeadingNew
                    title={"Top 3 Life-altering Benefits of"}
                    colored={"Silva Mind Control."}
                    />

                    <p className='text-center mt-3' style={{ fontSize: "1.2rem", fontWeight: "600", color: "black" }}>
                       Why should we learn powerful mind control techniques?
                    </p>
                </div>

                <LifeSystemExtraBanners
                    
                    title1='Level Up Your Personal Growth Strategy'
                    title2='Flourish Existing Relationships and Build New Ones'
                    text1="Silva's mind control techniques empower you to tap into your full potential and experience personal growth. Mindfulness training and meditation techniques of the Jose Silva Method enable you to be self-aware, boost self-confidence, and develop a positive mindset, leading to overall personal development."
                    text3='- The Silva Method meditation app equips you with powerful tools to improve your relationships. By understanding and working with your subconscious mind, you can enhance communication, empathy, and emotional intelligence. The Silva Method app includes daily meditation and relaxation meditation to help you build stronger connections, resolve conflicts, and cultivate healthier and more fulfilling relationships.'
                    img1={require("../../images/membershipimages/level_up_your_personal_goal_1_ (1).webp")}
                    img2={require("../../images/membershipimages/be_a_decision_maker_1_ (1).webp")}
                />

                <LifeSystemExtraBanners
                    
                    title1='Be a Decision Maker Without Depending on Anyone'
                    // title2='Fuel Your Productivity Level'
                    text1="The Silva Method teaches effective meditation techniques through the Silva Method app for making better decisions. By accessing intuitive abilities and developing your intuition through courses like Silva Intuition System, you can tap into your inner wisdom and make decisions aligned with your authentic self. These mediation techniques and mindfulness training enable you to trust your intuition, improve your judgment, and make choices that align with your values and goals."
                    // text3='Silva meditation app includes various meditation and mind control training courses that improve your focusing ability. Through techniques such as goal-setting, visualization, and time management, you can enhance focus, motivation, and efficiency. Silva method membership also introduces advanced dynamic meditation training that teaches you how to avoid distractions. These techniques enable you to prioritize tasks, overcome procrastination, and achieve your goals, leading to a more fulfilling and productive life.'
                    img1={require("../../images/membershipimages/be_a_decision_maker_1_ (1).webp")}
                    // img2={"../../images/membershipimages/be_a_decision_maker_1_ (1).webp"}
                    img2={require("../../images/membershipimages/be_a_decision_maker_1_ (1).webp")}
                    reverse

                />

                <LifeSystemExtraBanners
                    title1='Bid Farewell to the Dark Phases of Life '
                    text1="Silva Method mindfulness meditation provides effective strategies for managing and reducing stress, anxiety, and depression. Through mindfulness training, daily meditation, relaxation meditation, and visualization exercises, you can learn to calm your mind, regulate emotions, and promote inner peace. These meditation tips and techniques offer practical solutions for dealing with daily stressors and promote mental and emotional well-being"
                    img1={require("../../images/membershipimages/bid_farewell_to_dark_past_1_ (1).webp")}
                />
            </div>
        </div>
    )
}

export default MemberShipFeatures