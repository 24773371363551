import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const HealingAboutSection = () => {
  return (
    <div className="about-container">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="image-container mx-4">
              <img src="/img/bg/bghero/icons/ispgsqghtlricvj8sfsm.jpg" alt="Woman with arms raised in nature"
                className='hero-imgabout'
              />
              <div className="shape-1"></div>
              <div className="shape-2"></div>
              <div className="dots"></div>
            </div>
          </Col>
          <Col md={6}>
            <div className="content">
              <h5 className='lora-sans' style={{
                fontSize:"2rem",
                color:"black"
              }}>Healing the Mind and Body: The Meaning</h5>
              <p className='robotofont'>
                For years, numerous studies have demonstrated how meditation can effectively cure psychological distress and regulate our emotional well-being. It’s well established that maintaining a healthy body contributes to mental and emotional health. But did you know that this connection works both ways? Absolutely! You can harness the power of your mind to promote physical health and even combat life-threatening diseases. This concept is what we refer to when we discuss "heal meaning"—the idea of using your mental strength to heal your body. It’s a fascinating approach that opens up new avenues for holistic wellness.


              </p>
              <p className='robotofont'>
                If you're looking for the word heal meaning, Here it is: Healing the mind and body refers to the process of restoring balance and harmony within ourselves, both mentally and physically. It’s about addressing the root causes of our discomfort, whether they arise from emotional challenges or physical ailments, and activating the natural healing mechanisms of our mind and body to recover from them. Healing goes beyond simply treating symptoms; it’s a holistic journey towards true well-being.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HealingAboutSection;
