import React, { useContext, useEffect, useRef, useState } from 'react'
import { Form, Image } from 'react-bootstrap';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { BsFillPauseCircleFill, BsFillPlayCircleFill } from 'react-icons/bs';
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
// import ReactPlayer from 'react-player';
import ReactHlsPlayer from 'react-hls-player';

function MusicPlayerObj({ datanew }) {
  const { isMobile } = useContext(AuthContext)

  const videoRef = useRef();

  const [playbackRate, setPlaybackRate] = useState(1)
  // const audioRef = useRef();
  useEffect(() => {

    if (videoRef?.current) {
      videoRef.current.playbackRate = playbackRate
    }
  }, [videoRef, playbackRate])

  const handlePlaybackRateChange = (event) => {
    setPlaybackRate(event.target.value);
  };

  return (
    <div style={{
      width: isMobile ? "100%" : "80%",
      paddingBottom: isMobile ? "30px" : "0px",
      margin: "auto"
    }}>
      <div className='my-3'>
        <HeadingNew small title={datanew.title} />
      </div>

      {/* <ParaComp data={
        [
          datanew.description
        ]
      }
        margin={false}
        white={false}
        center
      /> */}


      <ReactHlsPlayer
        playerRef={videoRef}
        src={datanew.file}
        controls
        width="100%" // Example width
        height="100%" // Example height
        style={{
          borderRadius: "15px",
        }}
      />
      <div className="my-3" style={{ textAlign: 'center' }}>
        <label htmlFor="playbackRate" style={{ marginRight: '10px',color:"black" }}>Playback Speed: </label>
        <select id="playbackRate" value={playbackRate} onChange={handlePlaybackRateChange}>
          <option value="0.5">0.5x</option>
          <option value="0.75">0.75x</option>
          <option value="1">1x</option>
          <option value="1.25">1.25x</option>
          <option value="1.5">1.5x</option>
          <option value="2">2x</option>
        </select>
      </div>

    </div>
  )
}

export default MusicPlayerObj