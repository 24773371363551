import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaExclamationCircle, FaBrain, FaWind } from 'react-icons/fa';
import { AuthContext } from '../../../../context/AllContext';
import { useNavigate } from 'react-router-dom';

const MeditationMistakesCard = () => {

    const {isMobile} = useContext(AuthContext);
    const navigate = useNavigate()

    const cardStyle = {
        width: '100%',
        margin: '2rem auto 0rem auto',
        backgroundColor: 'trnsparent',
        borderRadius: '12px',

        boxShadow: 'none',
        overflow: 'hidden',
        fontFamily: 'Arial, sans-serif',
    };



    const contentStyle = {
        padding: isMobile?"0rem":'1.5rem',
    };


    const mistakeStyle = {
        backgroundColor: "#f5f4ef",
        padding: "30px",
        borderRadius: "15px",
        width: "97%",
        display: 'flex',
        alignItems: 'flex-start',
    };

    const iconStyle = {
        fontSize: '1.5rem',
        marginRight: '1rem',
        color: '#6a0dad',
    };

    const textStyle = {
        flex: 1,
    };

    const titleStyle = {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginBottom: '0.5rem',
        color: '#6a0dad',
    };

    const descriptionStyle = {
        lineHeight: '1.6',
        color: '#333',
    };

    return (
        <>
            <div style={cardStyle}>

                <div style={contentStyle}>
                    <h2 style={{
                        color: "black",
                        textAlign: "start",
                    }}
                        className='lora-sans'
                    >
                        Common Mistakes When Learning How to
                        <span style={{ color: '#572ac7' }}>
                            {" "} Meditate
                        </span>
                    </h2>


                    <p className='text-dark robotofont mt-3 text-start mb-4' style={{
                        fontSize: "1.1rem"
                    }}>
                        As with any new practice, there are pitfalls that can slow down your progress. If you are still unsure about how you can meditate effectively, here are some common mistakes to avoid:

                    </p>

                    <Row className='justify-content-start align-items-start gy-3'>
                        <Col md={6} lg={4}>
                            <div style={{ ...mistakeStyle, height: isMobile?"auto":"300px" }}>
                                <FaExclamationCircle style={iconStyle} />
                                <div style={textStyle}>
                                    <h4 style={{
                                        color: "black",
                                        textAlign: "start"
                                    }}
                                        className='lora-sans'
                                    >Expecting Instant Results</h4>
                                    <p className='text-dark robotofont mt-3 text-start mb-4' style={{
                                        fontSize: "1.1rem"
                                    }}>
                                        Meditation is not a quick fix. If you are new to meditation, it's important to understand that benefits may take time. Be patient and avoid expecting dramatic changes overnight.
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={4}>
                            <div style={{ ...mistakeStyle, height: isMobile?"auto":"300px" }}>
                                <FaBrain style={iconStyle} />
                                <div style={textStyle}>
                                    <h4 style={{
                                        color: "black",
                                        textAlign: "start"
                                    }}
                                        className='lora-sans'
                                    >Forcing Yourself to Stop Thinking</h4>
                                    <p className='text-dark robotofont mt-3 text-start mb-4' style={{
                                        fontSize: "1.1rem"
                                    }}>
                                        Meditation is not about having a blank mind. Thoughts will inevitably arise, especially as a beginner. Instead of getting frustrated, acknowledge the thoughts and then gently guide your focus back to your breath or object of meditation.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div style={{ ...mistakeStyle, height: isMobile?"auto":"300px" }}>
                                <FaWind style={iconStyle} />
                                <div style={textStyle}>
                                    <h4 style={{
                                        color: "black",
                                        textAlign: "start"
                                    }}
                                        className='lora-sans'
                                    >Skipping the Breath</h4>
                                    <p className='text-dark robotofont mt-3 text-start mb-4' style={{
                                        fontSize: "1.1rem"
                                    }}>
                                        Your breath is your anchor in many meditation techniques. As you learn to meditate, pay close attention to the rhythm of your breathing. If your mind starts to wander, use your breath as a tool to bring yourself back into the present moment.
                                    </p>
                                </div>
                            </div>
                        </Col>

                    </Row>





                </div>
            </div>
            <p className='text-dark robotofont text-center pb-4' style={{
                fontSize: "1.1rem",
                marginBottom:"0px"
            }}>
                Starting your meditation journey doesn't need to be complicated. Remember that learning how to begin meditation is a personal journey, and it's important to find a practice that works for you. Whether you start with just 5 minutes a day or dive into longer sessions, the key is to start —no matter how small. Embrace the process, and over time you will experience the many benefits of this transformative practice,

                By applying these tips and consistently practicing meditation for beginners, you’ll gradually learn how to meditate properly, develop your skills, and cultivate a peaceful state of mind.

            </p>
            <div className='d-flex justify-content-center'>
            <button className='cssbuttons-io-button3 mb-5' onClick={()=>{
                navigate("/store/course/combo-plan")
            }}>
               Begin you Journey

            </button>
            </div>

            


        </>

    );
};

export default MeditationMistakesCard;