import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'

function ManifestDesires2() {

    const data = useMemo(() => {
        return [
            {
                img: "/img/combo/techniques-visualization.webp",
                title: "Visualization",
                para: "Visualization is a cornerstone of the Silva Method. By vividly imagining your desired outcomes as if they have already happened, you align your subconscious mind with your goals. This powerful mental practice creates a clear roadmap for your brain to follow, making your dreams seem more achievable and helping you stay motivated and focused."
            },
            {
                img: "/img/combo/techniques-mirror of the mind.webp",
                title: "Mirror of the Mind",
                para: "The Mirror of the Mind technique is one of the most effective Silva tools for manifestation. After entering the Alpha State, imagine a blue-framed mirror in your mind reflecting your current reality. Then, visualize a white-framed mirror where you see the life you desire. By shifting your focus from the present to the future you want, you program your mind to recognize and create opportunities that align with your goals."
            },
            {
                img: "/img/combo/techniques-dream control.webp",
                title: "Dream Control Technique",
                para: "Our dreams are profound messages from our higher self. They can serve as a gateway to understanding and realizing our deepest aspirations. During manifestation, if you make a mistake your dreams can provide valuable indications to help you stay on the right path. With the Silva Method's Dream Control Technique, you learn to harness and influence your dreams to uncover solutions and gain insights into real-life challenges. By concentrating on specific questions or problems before you sleep, you activate the creative problem-solving abilities of your subconscious mind."
            },
            {
                img: "/img/combo/techniques-glass of water.webp",
                title: "Glass of Water Technique",
                para: "This simple yet powerful technique involves drinking a glass of water while focusing on a specific intention or question. The act of drinking the water while concentrating on your desire reinforces your subconscious mind’s engagement with your goals. This technique is often used to gain clarity or find answers to issues."
            }
        ]

    }, [])
    return (
        <div className='container d-flex justify-content-center align-items-center flex-column'>
            <HeadingNew small title={"Transform Your Dreams into Reality with The Powerful"} />
            <HeadingNew title={"Silva Method "} colored={"Techniques"} />

            <Row className='justify-content-center align-items-start gy-4 py-4'>

                {
                    data.map((val, i) => (
                        <Col xs={12} md={6} xl={4}>
                            <CardMani img={val.img} title={val.title} para={val.para} />
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}


function CardMani({ title, para, img }) {

    const [readMore, setReadMore] = useState(false);

    return (
        <div class="cardmanifest2" style={{
            margin:"0 auto"
        }}>
            <div class="cardmanifest2__image">
                <img className='cardmanifest2__image2' src={img} style={{
                    width: "100%",
                    height: "100%"
                }} />
            </div>
            <div class="cardmanifest2__content">
                <span class="title">
                    {title}
                </span>
                <p style={{
                    margin: "0px",
                    padding: "0px"
                }}>
                    {
                        readMore ? para : para.substring(0, 100) + "..."
                    }
                </p>
                <a className='mt-3' style={{
                    color: "blue",
                    fontSize: "1rem",
                    cursor: "pointer"
                }}
                    onClick={() => {
                        setReadMore(!readMore)
                    }}
                >
                    {
                        readMore ? "Read Less" : "Read More"
                    }

                </a>
            </div>
        </div>
    )

}

export default ManifestDesires2