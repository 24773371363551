import React from 'react'
import NewHeader from '../../AdvertisePages/newmembership/NewHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import DashboardHeroSection from './DashboardHeroSection'

function DashboardFree() {
  return (
    <div>
         <NewHeader />
         <DashboardHeroSection />
         <CustomFooter />
    </div>
  )
}

export default DashboardFree