import React, { useState, useEffect, useContext } from 'react';
import styles from './newHomeHeroSection.module.css';
import { TypeAnimation } from 'react-type-animation';
import { AuthContext } from '../../context/AllContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NewHomeHeroSection = ({ scrollTo }) => {
    const navigation = useNavigate()
    const location = useLocation()
    const { isMobile } = useContext(AuthContext);
    const carouselImages = [
        '/img/bg/Image20240829111956.jpg',
        '/img/Image20241112183728.jpg'
    ];
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                (prevIndex + 1) % carouselImages.length
            );
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);


    
  const handleClick = () => {
    if (location.pathname === "/store/course/combo-plan") {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('token', 'BLACKFRIDAY');
      window.location.search = urlParams;
    } else {
      navigation('/?token=BLACKFRIDAY')
      scrollTo()

    }
  }
    return (
        <div className={styles.heroContainer}>
            <div className={styles.heroOverlay}></div>
            <div className={styles.heroContent}>
                <div className={styles.heroLeft}>
                    <div className={styles.textContent}>
                        <h1 className={styles.heading}>
                            <span className={styles.highlightText}>Silva Method</span> All in One Program with Live Sessions
                        </h1>
                        <h2 className='robotofont'>
                            <TypeAnimation
                                sequence={[
                                    'Boost mental clarity and focus',
                                    500,
                                    'Reduce stress and anxiety',
                                    500,
                                    'Achieve personal growth',
                                    500,
                                    'Enhance creativity and intuition',
                                    500,
                                    'Improve sleep quality'
                                ]}
                                wrapper={isMobile ? "h5" : "h3"}
                                cursor={true}
                                repeat={Infinity}
                                style={{ color: "white" }}
                            />
                        </h2>
                        <h2 className={styles.subheading}>
                            Transform your life with scientifically proven mind-training techniques
                        </h2>
                        <div className={styles.ctaButtons}>
                            <Link to={"/store/course/combo-plan?token=BLACKFRIDAY"} className={styles.ctaPrimary} onClick={scrollTo}>
                                View Course Details
                                <span className={styles.buttonArrow}>→</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.heroRight}>
                    <div className={styles.videoContainer}>
                        <div className={styles.videoWrapper}>
                            {carouselImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    className={`${styles.carouselImage} ${
                                        index === currentImageIndex ? styles.visible : ''
                                    }`}
                                    onClick={()=>{
                                        handleClick()
                                    }}
                                    alt="Carousel Slide"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewHomeHeroSection;
