import React from 'react'
import {FaStar} from "react-icons/fa";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import Featured2 from './Featured2';

function TrustedBy2({text="Silva Method got featured on"}) {
    const {isMobile} = useContext(AuthContext)
    return (
        <div>
            {/* <h2 className='my-5 newpageheader text-center'>
                Trusted by the best in the business
            </h2> */}
            <div className='mt-4 d-flex justify-content-center align-items-center gap-2'>
        <span>
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
        </span>
        <h6 style={{
          color: "black",
          fontSize: isMobile ? "12px" : "20px",
          fontWeight: "600",
          marginTop: isMobile ? "10px" : "10px"
        }}>
          {text}
        </h6>
        <span>
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
        </span>
      </div>
            <Featured2 />
        </div>
    )
}

export default TrustedBy2