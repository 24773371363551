import React, { useContext } from "react";

import { AuthContext } from "../../../../context/AllContext";

import MemberHeroSection from "../../../silvamembership/MemberHeroSection";
import AboutUsPionarMind from "../../../About/AboutUsPionarMind";
import HeadingNew from "../../../AboutUs/HeadingNew";
import TheSilvaMethodBox from "./TheSilvaMethodBox";

const ServicesOneABout = () => {
  const { isDesktopOrLaptop, isBigScreen, isMobile } = useContext(AuthContext);

  return (
    <>
      <section
        className="container pt-20 pb-10 cardbdy2 mt-4 px-4"
        style={{ borderRadius: "20px" }}
      >
        <div className="">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="text-end">
                <div className="section-text section-text-small pos-rel d-flex justify-content-start pt-15 flex-column wrap-wrap">
                  <HeadingNew
                    title={
                      "José Silva Method- A Globally Recognized Brand for Over 50 Years"
                    }
                    small
                    white
                  />
                  {/* <Link to="#"
                              style={{ maxWidth: isMobile ? "100%" : "50%" }}
                              className="primary_btn btn-icon ml-0"><span>+</span>Start learning</Link> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="facalty-text mb-50">
                <p className="white-color">
                  José Silva, the visionary behind the Silva
                  Method, devised techniques and trialed them on his children.
                  Notably, their remarkable academic advancements over three
                  years affirmed his breakthrough. Witnessing significant
                  improvements including improved memory and higher IQ scores,
                  Jose Silva became the first to demonstrate the functionalities
                  of the human mind at Alpha and Theta brain waves with
                  awareness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-20 w-100">
        <MemberHeroSection />
      </div>
      {/* 
      <div className="mb-20">
      <AboutUsPionarMind/>
      </div> */}
      <div className="marginBot">
        <TheSilvaMethodBox />
      </div>
    </>
  );
};

export default ServicesOneABout;
