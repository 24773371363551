"use client";
import React from "react";
import ParaComp from "../SilvaManifestationProgram/ParaComp";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function LearnSection() {
  const navigate = useNavigate();

  const listyles = {
    fontWeight: "600",
    color: "#000",
    marginBottom: "14px",
  };

  const listItem1 = [
    {
      id: 1,
      text: "How to Enhance your Intuitive skill",
    },
    {
      id: 2,
      text: "Learn how to get guidance in your dreams",
    },
    {
      id: 3,
      text: "Set a pathway to achieve goals",
    },
    {
      id: 4,
      text: "Build the concentration power",
    },
    {
      id: 5,
      text: "Learn to use your GUT Feelings",
    },
    {
      id: 6,
      text: "Learn to use mental projection",
    },
    {
      id: 7,
      text: "Use the power of Manifestation",
    },
    {
      id: 8,
      text: "Transform your stress to calmness",
    },
  ];

  const listItem2 = [
    {
      id: 1,
      text: "Get rid of Procrastination",
    },
    {
      id: 2,
      text: "How to set proper goals",
    },
    {
      id: 3,
      text: "Learn to eliminate negative thought patterns",
    },
    {
      id: 4,
      text: "Become more creative",
    },
    {
      id: 5,
      text: "Enhance your learning and problem solving capability",
    },
    {
      id: 6,
      text: "Discover the purpose of your life",
    },
    {
      id: 7,
      text: "Learn to project on Mental screen to solve problems",
    },
    {
      id: 8,
      text: "Learn to relax more quickly",
    },
  ];

  return (
    <div className="container">
      <div className="row justify-content-center p-3">
        <div className="col-sm-12 col-md-8">
          <h2 className="text-center mb-4">What You’ll Learn</h2>
          <h3 className="text-center mb-4">
            The World's Best Education In Personal Transformation Is Now In Your
            Hands. What Will You Do With It?
          </h3>
          {/* <p style={{fontSize:"1.1rem"}}>What’s the best thing that money can buy? For many, it’s self-evolution. When you improve and grow yourself –
                        everything else grows with you. Your career, your life quality, and your ability to shape your destiny.</p>
                    <p style={{fontSize:"1.1rem"}}>
                        This is why personal growth is the best education decision you can make. And in the entire industry,
                        no one matches the quality of The Silva Method. With The Silva Method Membership, you now get every single world-class The Silva Method Quest at your fingertips.
                    </p>
                    <p style={{fontSize:"1.1rem"}}>
                        Individually, each program has an enrollment fee of $199 to $499. With The Silva Method Membership
                        -- EVERYTHING is unlocked for you for less than $0.82 a day.</p> */}
          <ParaComp
            white={false}
            data={[
              "What’s the best thing that money can buy? For many, it’s self-evolution. When you improve and grow yourself –everything else grows with you. Your career, your life quality, and your ability to shape your destiny.",
              "This is why personal growth is the best education decision you can make. And in the entire industry,no one matches the quality of The Silva Method. With The Silva Method Courses, you now get every single world-class The Silva Method Quest at your fingertips.",
              // "Individually, each program has an enrollment fee of $199 to $499. With The Silva Method Membership-- EVERYTHING is unlocked for you for less than $0.82 a day."
            ]}
          />
          <div className="bulletdiv2">
            <ul className="text-start bulletli bulletli2">
              <Row>
                <Col sm={12} md={12} lg={6}>
                  {listItem1.map((items, i) => (
                    <div className="d-flex align-items-center">
                      <p className="text-end">
                        <BsFillPatchCheckFill color="green" />
                      </p>
                      <b className="text-start text-black p-2">{items.text}</b>
                    </div>
                  ))}
                </Col>
                <Col sm={12} md={12} lg={6}>
                {listItem2.map((items, i) => (
                    <div className="d-flex align-items-center">
                      <p className="text-end">
                        <BsFillPatchCheckFill color="green" />
                      </p>
                      <b className="text-start text-black p-2">{items.text}</b>
                    </div>
                  ))}
                </Col>
              </Row>
            </ul>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="primary_btn2"
              onClick={() => navigate("/store/course/combo-plan")}
            >
              Get Access to All Courses
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnSection;
