import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import HeadingNew from '../../AboutUs/HeadingNew'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'

function TextBannerSection() {
    const navigate = useNavigate()
    const {isMobile} = useContext(AuthContext);


    // const handleShow = () => {
    //     navigate("/store/course/combo-plan")
    // }

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymentsection");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    return (
        <Card style={{ width: "100%", padding: isMobile?"0px":"50px" }} className='fourthcard'>
            <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                <HeadingNew title={"Al final de esta Masterclass..."} white />
                <p className='white-color mt-4 text-center' style={{
                    fontWeight: "400"
                }}>
                    Aprenderás a obtener información y nuevas perspectivas de tu mente subconsciente, explorarás la ciencia detrás de ella y descubrirás porque más de un millón de personas en todo el mundo buscan aprovechar este poder único.
                </p>
                <p className='white-color text-center' style={{
                    fontWeight: "400"
                }}>
                    Además, aprenderás a acceder a los estados alterados de conciencia mientras estás completamente consciente y utilizarás este conjunto de habilidades únicas para lograr las hazañas extraordinarias a las que siempre has aspirado, pero nunca has logrado.
                </p>
                <button className='outlinebtn' onClick={handleScroll}>
                Enlístate ahora
                </button>
            </Card.Body>
        </Card>
    )
}

export default TextBannerSection