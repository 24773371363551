import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../context/AllContext';

function LoveMindLearnList() {
    const {isMobile} = useContext(AuthContext);
  
    const learnsectiondata = useMemo(() => {
        return [
            "how to neutralize stress, the greatest enemy of your health",
            "Uncover the 4 key principles of healthy self-esteem",
            "Learn how to express your emotions",
            "Discover the formula for a successful life",
            "Learn how to change your limiting habits in your relationship",
            "know how to resolve any problem",
            "Discover the 3 life purposes and 3 highest laws of life",
            "Learn to show unconditional love to all existence."
        ]
    }, [])


    const handleScroll = ()=>{
        const doc = document.querySelector(".lovemindformscroll");
        if(doc){
            doc.scrollIntoView({
                behavior:"smooth"
            })
        }
    }
    return (
        <div className='container'>
            <Row className='container justify-content-center align-items-center' style={{
                position: "relative"
            }}>

                <Col xs={12} md={6} className={'order-1'}>


                    <div className='d-flex justify-content-start align-items-start'>
                    <Tilt reset={false}>

                    <img src='/img/lovemind/perkimg2.png' style={{
                        width:"90%"
                    }} />
                    </Tilt>

                    </div>
                </Col>
                <Col xs={12} md={6} className={'order-2'}>
                        <HeadingNew title={"What you’ll learn?"} start />

                        <Row className='my-3'>
                            {
                                learnsectiondata.map((val, i) => (
                                    <Col xs={12} md={6}>
                                        <div className='d-flex gap-2 mb-1' style={{
                                            width: "97%"
                                        }}>

                                            <img src='/img/checkicon2.png' width={25} height={25} />
                                            <p style={{
                                                fontSize: "0.9rem",
                                                color: "black",
                                                fontWeight: "500",
                                                margin: "0px"
                                            }}>
                                                {val}
                                            </p>

                                        </div>
                                    </Col>


                                ))
                            }
                        </Row>
                        <button className='herodiv-becomememberbtnnew' style={{
                            marginLeft: "0",

                        }}
                        onClick={handleScroll}
                        >
                            Enroll now for free
                        </button>




                </Col>

            </Row>

        </div>
    )
}

export default LoveMindLearnList