import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'

function ParaComp({data=[],white=true,margin=true,center=false,left=false,fw=600}) {
  const { isTablet } = useContext(AuthContext);
  return (
    <div>
        {
            data.map((val,i)=>(
                <p className={`${white?'white-color':'text-black'}`} style={{ fontSize:isTablet?"1.2rem" :"1rem", marginTop: margin?"30px":"0px",
                textAlign:center?"center":left?"end":"start",fontWeight:fw }} key={i}>
                {val}
            </p>
            ))
        }
    </div>
  )
}

export default ParaComp