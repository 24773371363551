export const MindfulnessCard = ({ title, description, img }) => {

  return (
    <div className="practice-card">
      {
        img && (
          <div className="card-image">
            <img
              src={img}
              alt={title}
            />
          </div>
        )
      }
      <div className="card-content">
        <h3 style={{
          fontFamily: "Poppins"
        }}>{title}</h3>
        <p className="robotofont">{description}</p>
      </div>

      <style>{`
          .practice-card {
            background: rgb(245, 244, 239);
            border-radius: 20px;
            width:95%;
            overflow: hidden;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            position: relative;
            animation: fadeIn 0.6s ease-out forwards;
            opacity: 0;
          }
  
          .practice-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
          }
  
          .card-image {
            position: relative;
            padding-top: 66.67%; /* 3:2 aspect ratio */
            overflow: hidden;
          }
  
          .card-image img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s ease;
          }
  
          .practice-card:hover .card-image img {
            transform: scale(1.05);
          }
  
          .card-content {
            padding: 2rem;
          }
  
          .card-content h3 {
            font-size: 1.5rem;
            color: #2d3748;
            margin-bottom: 1rem;
            font-weight: 600;
          }
  
          .card-content p {
            color: #4a5568;
            line-height: 1.6;
            margin: 0;
          }

          @media (max-width: 768px) {
     .practice-card {
          
            width:100%;
          
          }
  
}
  
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
  
          @media (max-width: 768px) {
            .card-content {
              padding: 1.5rem;
            }
          }
        `}</style>
    </div>
  );
};