import React from "react";
import { useContext } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import HeadingNew from "../../AboutUs/HeadingNew";

function ComBoPlusLiveHero() {
  const { isDesktopOrLaptop, isBigScreen, isTablet, isMobile } =
    useContext(AuthContext);

    const handleScroll = (name) => {
        const descard = document.querySelector(name);
    
        if (descard) {
          descard.scrollIntoView({
            behavior: "smooth",
          });
        }  
      };


  return (
    <div
      className={
        isDesktopOrLaptop || isBigScreen
          ? "plus-combo-hero1 pt-150"
          : "plus-combo-hero1-mobile"
      }
    >
      <Container>
        <Row>
          <Col
            lg={6}
            md={8}
            sm={12}
            className={`p-3  ${isMobile ? "order-2" : ""}`}
          >
            <div>
              {/* <h3 className="text-white"></h3> */}
              <HeadingNew
                title={
                  "Unlock Your Potential with Silva Method Manifestation Courses"
                }
                start
              />
               {/* <HeadingNew
                title={
                  "Master the Art of Manifestation to Attract Love, Money, Success & Abundance"
                }
                white
                start
                small
              /> */}
              {/* <p className="text-white my-3">
                Welcome to Silva Method's manifestation courses, where you'll
                learn the secrets to manifesting your dreams and desires with
                precision and clarity. Our courses are designed to help you
                harness the power of the law of attraction to attract love,
                money, success, and abundance into your life. With our proven
                techniques and expert guidance, you'll discover how to align
                your thoughts, beliefs, and actions with your deepest desires,
                allowing you to create the life you've always dreamed of.
              </p> */}
              <p className="text-black my-3">
              Master the Art of Manifestation to Attract Love, Money, Success & Abundance
              </p>
              <div className="py-3">
                <button className="herodiv-becomememberbtn2 mb-3 p-3" onClick={()=>handleScroll('.includelivecheck')}>
                Start Manifesting Your Dreams Today!
                </button>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            md={4}
            sm={12}
            className={`text-center ${isMobile ? "order-1" : ""}`}
          >
            {(isMobile || isTablet) && (
              <div className="p-3">
                {/* <Image src="/img/bgimage.jpg" style={{width: '100%',borderRadius:'15px'}}/> */}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ComBoPlusLiveHero;
