import React from 'react'
import { Card, Col, Form, Image, InputGroup, Row } from 'react-bootstrap'
import { CiLock } from "react-icons/ci";
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import TimerComp2 from '../../newbooking/TimerCompNew';
import { AuthContext } from '../../../context/AllContext';
import { reqData, requestData2 } from '../../../utils/baseUrl';
import { createOrder2, generateToken2 } from '../../../utils/paypalfuncs2';

function LMPaymentSection() { 

    const {isMobile,isTablet} = useContext(AuthContext)

    const [liveEventsData, setLiveEventsData] = useState(null);

    const [loading, setLoading] = useState(false);

    const { userData } = useContext(AuthContext)

    const [hasEmail, setHasEmail] = useState(false);
    const [userEmail, setUserEmail] = useState("")

    const [defaultPayment, setDefaultPayment] = useState("Stripe");

    useEffect(() => {
        if (userData && userData.email) {
            setHasEmail(true)
            setUserEmail(userData.email)
        } else {
            setHasEmail(false)
        }
    }, [userData])


    const handleSubscriptionPaypal = async () => {
        setLoading(true)

        const data = {
            email: userEmail,
            event_id: liveEventsData.event_id,
            payment_gateway: "PayPal"
        }

        const res = await requestData2("liveClassPayment", "POST", data)

        let token, payment_gateway;
        if (res && res.error === false) {
            token = res.data
            payment_gateway = "PayPal"
        }
        const price = Number(liveEventsData.price) - (Number(liveEventsData.price) * (Number(liveEventsData.discount_percentage) / 100))

        // localStorage.setItem("token",res.student_id)
        const gentok = await generateToken2();
        const order = await createOrder2(
            gentok, token, userEmail, payment_gateway, "USD", price, liveEventsData.event_id
        );


        localStorage.setItem("gentok", gentok);
        localStorage.setItem("orderid", order.id)

        setLoading(false)


        if (!!order?.links) {
            const url = order.links.find(link => link.rel == 'approve');
            window.location.href = url.href
        }
    }

    const handleSubscriptionStripe = async () => {
        const data = {
            email: userEmail,
            event_id: liveEventsData.event_id,
            payment_gateway: "Stripe"
        }


        setLoading(true)
        // console.log(data, "dataa")
        const res = await requestData2("liveClassPayment", "POST", data)
        // localStorage.setItem("token",res.student_id)
        setLoading(false)


        if (res && res.title === "Stripe\Exception\InvalidRequestException") {
            toast.error("You are already subscribed to stripe");
            return
        }

        if (res && res.error === true) {
            toast.error(res.messages);
            return
        }

        if (res && res.error === false) {
            window.location.assign(res.data)
        }
    }


    const handleSubscription = async () => {
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail);
        if (!isValidEmail) {
            toast.error("Please enter a valid email");
            return
        }

        if (defaultPayment === "Stripe") {
            handleSubscriptionStripe()
        } else {
            handleSubscriptionPaypal()
        }
    }


    useEffect(() => {
        internationalEvents()
    }, [])

    const internationalEvents = async () => {
        try {

            const res = await reqData("internationalEventList", "POST", {})

            console.log(res,'result')

            let found;

            if (res && res.error === false) {
                found = res.data.find((val, i) => val.event_id === "silva-method-love-mind")
            }

            if (found) {
                setLiveEventsData(found)
            }

        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div className='container py-5 paymentsection paymenttwonew'>
            <Row className='justify-content-center align-items-center'>
                <Col lg={7} className='order-2 order-lg-1'>
                  
                    <h2 className='fontclass text-center' style={{
                        color: "black"
                    }}>Say YES To Silva Method Online Live Learning Experience</h2>
                    <Image
                        src='img/newpic.png'
                    />
                </Col>
                <Col lg={5} className='order-1 order-lg-2'>
                    <div className='d-flex justify-content-center align-items-center w-100'>
                        <Card className='paymentcardnewdata mt-4 mt-md-0'>
                            <Card.Body className='paymentcardnewdata2'>
                                <h4 className='text-center pt-3 pb-4 fw-700' style={{
                                    color: "black",
                                    borderBottom: "1px solid lightgray"
                                }}>
                                    Reserve your spot
                                </h4>

                                <h3 className='text-center py-4 fw-700' style={{
                                    color: "black",
                                }}>
                                    Pay once and enjoy the benefits for your entire lifetime
                                </h3>

                                <div className='d-flex justify-content-center align-items-center gap-3 flex-wrap'>

                                    {
                                        liveEventsData && (
                                            <h3 className='pricecut2'>{liveEventsData.currency === "USD" ? "$" : "INR"} {Number(liveEventsData.price) - (Number(liveEventsData.price) * (Number(liveEventsData.discount_percentage) / 100))}</h3>
                                        )
                                    }
                                    {
                                        liveEventsData && (
                                            <h3 className='pricecut'>{liveEventsData.currency === "USD" ? "$" : "INR"} {liveEventsData?.price}</h3>
                                        )
                                    }

                                    {
                                        liveEventsData && (
                                            <p style={{
                                                color: "black"
                                            }}>you will get {liveEventsData?.discount_percentage}% off</p>
                                        )
                                    }
                                </div>
                                <p className='text-center' style={{
                                    lineHeight: "20px"
                                }}>
                                    Lets begin the life transformation journey together

                                </p>

                                <TimerComp2 />


                                {/* new forms */}

                                <form onSubmit={(e) => e.preventDefault()}>


                                    {
                                        hasEmail === false && (
                                            <div>
                                                <label htmlFor="emailinput" className='couponinputlabel'>Your Email Id <span style={{ color: "red" }}>*</span></label>
                                                <Form.Control
                                                    placeholder='Type Here'
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    id='emailinput'
                                                    className='couponinputfield'
                                                    value={userEmail}
                                                    onChange={(e) => setUserEmail(e.target.value)}
                                                />
                                            </div>
                                        )
                                    }


                                </form>


                              
                            </Card.Body>
                            <Card.Footer className='d-flex justify-content-center align-items-center py-4 flex-column'>
                                <p style={{
                                    fontWeight:"600"
                                }}><span className='text-danger' style={{
                                    marginRight:"10px"
                                }}>*</span>
                                    Exclusive $700 offer price for the first 20 registrants!
                                </p>
                                <button className='primary_btn' onClick={handleSubscription} disabled={loading}>
                                    {
                                        loading ? "...please wait" : "Join The Seminar"
                                    }
                                </button>
                            </Card.Footer>
                        </Card>
                    </div>





                    <div className='d-flex justify-content-center align-items-center gap-2'>
                        <span className='mb-3'>
                            <CiLock />
                        </span>
                        <p>
                            For your security, all orders are processed on a secured server.
                        </p>

                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default LMPaymentSection