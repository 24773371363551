import Collapsible from 'react-collapsible'
import { AiOutlineUp,AiOutlineDown } from "react-icons/ai";
import "./faq2.css"
import { useState } from 'react';


function FAQ22({heading,body}) {

    const [opened,setOpened] = useState(false);

    return (
        <div className='my-3' style={{
            border:"1px solid #636e72",
            borderRadius:"10px"
        }}>
            <Collapsible trigger={<FAQheader opened={opened} heading={heading} />}
            transitionTime={300}
            transitionCloseTime={300}
            onOpening={()=>setOpened(true)}
            onClose={()=>setOpened(false)}
            openedClassName='openedcollapse'
            easing='ease-out'
            >
                <FAQbody body={body} />
            </Collapsible>
        </div>
    )
}

export default FAQ22


function FAQheader({opened,heading}) {

    return (
        <div className={`faqaccordionheader ${opened?"openheader3":"openheader2"}`}>
            <h4 style={{ margin: "0px", padding: "0px",color:'#fff',fontSize:"1rem",textTransform:"uppercase" }}>
                {heading}
            </h4>
            <span>
                {
                    opened ? (
                        <AiOutlineUp color={opened?'white':'white'} />
                    ):(
                        <AiOutlineDown color={opened?'white':'white'} />
                    )
                }
            </span>
        </div>
    )
}

function FAQbody({body}) {
    return (
        <div className='faqaccordioncontent2'>
            {body}
        </div>
    )
}

