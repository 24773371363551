import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import Countdown from 'react-countdown';

function TimerComp2({ datanew, setPricePlan2 }) {
  const [remaining, setRemaining] = useState(null);
  const [isValidOffer, setIsValidOffer] = useState(false);
  const [countdownKey, setCountdownKey] = useState(0);

  const handleCountdownComplete = () => {
    // Reset the timer to 3 days from the current date
    const currentDate = moment();
    const targetDate = currentDate.clone().add(20, 'hours');
    const diff = targetDate.diff(currentDate);

    setRemaining(currentDate.valueOf() + diff);
    setIsValidOffer(true);
    setCountdownKey(countdownKey + 1);
  };

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className='d-flex justify-content-start align-items-center gap-3 paymentcardsss'>
        <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
          <button className='newbtn2'>{days < 10 ? '0 ' + days : days}</button>
          <h6 className='text-center text-dark' style={{ fontSize: '12px' }}>
            Days
          </h6>
        </div>

        <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
          <button className='newbtn2'>{hours < 10 ? '0 ' + hours : hours}</button>
          <h6 className='text-center text-dark' style={{ fontSize: '12px' }}>
            Hours
          </h6>
        </div>

        <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
          <button className='newbtn2'>{minutes < 10 ? '0 ' + minutes : minutes}</button>
          <h6 className='text-center text-dark' style={{ fontSize: '12px' }}>
            Minutes
          </h6>
        </div>

        <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
          <button className='newbtn2'>{seconds < 10 ? '0 ' + seconds : seconds}</button>
          <h6 className='text-center text-dark' style={{ fontSize: '12px' }}>
            Seconds
          </h6>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const currentDate = moment();
    const targetDate = currentDate.clone().add(20, 'hours');

    const calculateRemaining = () => {
      const diff = targetDate.diff(moment());

      if (diff > 0) {
        setRemaining(moment().valueOf() + diff);
        setIsValidOffer(true);
      } else {
        setRemaining(moment().valueOf() + 98310000);
        setIsValidOffer(false);
      }
    };

    // Initial calculation when the component mounts
    calculateRemaining();

    // Set up interval to update countdown every second
    const intervalId = setInterval(calculateRemaining, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run the effect only once on mount

  if (!isValidOffer) {
    return null;
  }

  return (
    <Alert
      className='d-flex justify-content-center align-items-center flex-column'
      style={{
        background: '#f9f9fb',
        color: 'black',
        border: 'none',
        borderRadius: '15px',
        width: '100%',
        padding: '0.5rem',
      }}
    >
      <h5 className='text-dark' style={{ fontSize: '0.8rem' }}>
        Remaining for this{' '}
        <span style={{ color: 'rgb(128, 28, 182)', fontWeight: '600', fontSize: '0.8rem' }}>Discount </span>
      </h5>
      {remaining && (
        <Countdown
          autoStart
          date={remaining}
          key={countdownKey}
          onComplete={handleCountdownComplete}
          renderer={renderer}
        />
      )}
    </Alert>
  );
}

export default TimerComp2;
