import React, { useContext } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap';
import { CiBandage } from "react-icons/ci";
import { AuthContext } from '../../context/AllContext';
import { FaBrain,FaUserGraduate } from "react-icons/fa";
import { GiNightSleep } from "react-icons/gi";
import { IoIosBody } from "react-icons/io";
import { RiFocus2Line } from "react-icons/ri";
import { IoIosHappy,IoMdGlobe,IoIosPeople } from "react-icons/io";
import {GiTeacher} from "react-icons/gi";


function CalmCardSection() {
    const {isMobile} = useContext(AuthContext)

    const data = [
        {
            icon: <FaBrain color='#6a3093' size={70} />,
            title: "Reduced Stress.",
            text: "Focused breathing and mindfulness techniques create a tranquil mental state, foster restful sleep, and ultimately diminish stress levels for a more rejuvenating and peaceful experience."
        },
        {
            icon: <GiNightSleep color='#6a3093' size={70} />,
            title: "Better Sleep",
            text: "Engaging in sleep meditation before bedtime can promote a state of relaxation, making it easier to fall asleep and enhancing the overall quality of sleep and the duration of sleep."
        },
        {
            icon: <IoIosBody color='#6a3093' size={70} />,
            title: "Good Health",
            text: "Quality sleep is essential for overall physical and mental health. This Sleep meditation promotes relaxation, reduces stress and anxiety, and can positively impact various bodily functions, including the immune system, hormonal balance, and cardiovascular health."
        },
        {
            icon: <RiFocus2Line color='#6a3093' size={70} />,
            title: "More Focus",
            text: "When we sleep, our brains undergo vital processes like memory consolidation and neural rejuvenation. This sets the stage for heightened cognitive function, increased alertness, and sustained attention during waking hours."
        },
        {
            icon: <IoIosHappy color='#6a3093' size={70} />,
            title: "Mindful Living",
            text: "A restful night's sleep is crucial for mindful living, enabling us to stay present in the moment. It rejuvenates the mind, enhances focus, and fosters emotional balance, creating a foundation for staying mindful and fully engaged in our daily experiences."
        }
    ]
    return (
        <div className='d-flex justify-content-center align-items-center flex-column gap-3 my-5 text-center'>

            <div className='d-flex justify-content-center align-items-center flex-wrap'>
        
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        <Image
                                            src='/img/trophy.png'
                                            width={50}
                                            height={50}
                                        />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>80,000+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>SUCCESS STORIES</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                      
                                        <GiTeacher size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={500} duration={2.5} suffix="+" /> */}
                                         <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>500+</h4>

                                        <h6 style={{
                                            color: "black",
                                        }}>Instructors</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-06.png")}
                                            width={50}
                                            height={50}
                                        /> */}

                                        <IoMdGlobe size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={120} duration={2.5} suffix="+" /> */}
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>120+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>Countries</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-07.png")}
                                            width={50}
                                            height={50}
                                        /> */}
                                        <FaUserGraduate size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>12M+</h4>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={12} duration={1.5} suffix="M" /> */}

                                        <h6 style={{
                                            color: "black",
                                        }}>Graduates</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-11.png")}
                                            width={50}
                                            height={50}
                                        /> */}

                                        <IoIosPeople size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={1} duration={1} suffix="M" /> */}
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }}>1M+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>Testimonials</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                   
            </div>
            <h1 className='newpageheader mt-5'>
               Benefits of The Silva Sleep Control Technique
            </h1>

            <Row className='container justify-content-center align-items-center gy-3'>
                {
                    data.map((val, i) => (
                        <Col xs={12} md={6} lg={4}>
                            <Card className='stresscard' style={{
                                width: isMobile?"100%":"95%",
                                height:isMobile?"auto":"400px",
                                borderRadius: "10px"
                            }}>
                                <Card.Body style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}>
                                    <span>{val.icon}</span>
                                    <h3 style={{
                                        color: "black",
                                        fontWeight: "550",
                                        fontSize: "1.5rem",
                                        textAlign: "center"
                                    }}>
                                        {val.title}
                                    </h3>
                                    <p className='normalpara'>
                                        {val.text}
                                    </p>
                                </Card.Body>

                            </Card>

                        </Col>
                    ))
                }

            </Row>
        </div>
    )
}

export default CalmCardSection