import React from 'react'
import { Card } from 'react-bootstrap'
import "./differencecard.css"
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
function DifferenceCards({img,title,text}) {
  const {isMobile} = useContext(AuthContext);

  return (
  //  <div className="d-flex justify-content-center align-items-start flex-column text-start gap-3 p-2">
  //     <img src={img} style={{width:"100%",height:"100%",borderRadius:"10px",objectFit:"cover"}} />
  //     <h3>{title}</h3>
  //     <p style={{color:"black",fontSize:"1.1rem"}}>{text}</p>
  //  </div>
  <Card style={{width:isMobile?"100%":"98%",borderRadius:"15px",height:"450px"}} className='cardhoverabout'>
    <Card.Img src={img} style={{borderRadius:"15px"}} />
    <Card.Body>
       <Card.Title className='aboutcardtitle'>
        {title}
       </Card.Title>
       <Card.Text className='aboutcardpara'>
        {text}
       </Card.Text>
    </Card.Body>
  </Card>
  )
}

export default DifferenceCards