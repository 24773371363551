import React from 'react';
import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../../../context/AllContext';
import { useNavigate } from 'react-router-dom';

const SilvaMethodComponent = () => {
    const navigate = useNavigate()
    const { isMobile } = useContext(AuthContext)
    return (
        <div className="my-5" style={{
            backgroundImage: 'linear-gradient(to right, #5B21B6, #3730A3)', // Equivalent to from-purple-800 to-indigo-800
            paddingTop: '3rem',  // Equivalent to py-12
            paddingBottom: '3rem',
            paddingLeft: '1rem',  // Equivalent to px-4
            paddingRight: '1rem',
            fontFamily: 'sans-serif',  // Equivalent to font-[sans-serif]
        }}
        >
            <div className='container'>
                <Row className=" align-items-center">
                    <Col md={5}>
                        <div className='d-flex justify-content-start'>
                            <img
                                src="https://silvamethod.com/img/bg/bghero/header-slider-COURSES-FOR-MOBILE.webp"
                                alt="Silva Method Meditation"
                                className="img-fluid rounded"
                                style={{
                                    width: "95%"
                                }}
                            />
                        </div>
                    </Col>
                    <Col md={7}>
                        <h3 style={{
                            color: "white",
                            fontSize: isMobile ? "1.5rem" : "2rem"
                        }}
                            className='lora-sans text-start '
                        >
                            The Silva Method: Explore The Best  {" "}
                            <br />
                            <span style={{ color: '#FFEA00' }}>
                                {" "} Sleep Meditation
                            </span>
                            {" "} Techniques
                        </h3>

                        <p className="robotofont mb-2 mt-4 white-color" style={{
                            fontSize: isMobile ? "1rem" : "1.1rem"
                        }}>
                            <span style={{
                                cursor: "pointer",
                                color: "yellow"

                            }}
                                onClick={() => {
                                    navigate("/")
                                }}
                            ><strong> The Silva Method</strong></span> - America's first mind empowerment program, developed by Jose Silva in the 1960’s. This self-help mindfulness method offers the best sleep meditation technique to help you relax and enjoy a restful sleep. You’ll also learn how to wake up feeling refreshed and energized without an alarm clock! Plus, the Silva Method can help you build better daily habits for a healthier, more balanced lifestyle. Whether you're dealing with sleepless nights or just want to improve your routine, these techniques will transform how you sleep and wake up, leaving you recharged and ready for the day.


                        </p>
                        <p className="robotofont mb-4 white-color" style={{
                            fontSize: isMobile ? "1rem" : "1.1rem"
                        }}>

                            Enroll now in the<span style={{
                                cursor: "pointer",
                                color: "yellow"
                            }}
                                onClick={() => {
                                    navigate("/store/course/combo-plan")
                                }}

                            ><strong> Silva Method Complete Program</strong></span>  and enjoy Lifetime Access to all Silva Method courses, along with Weekly Free Live Interactive Classes led by certified instructors, offering ongoing support and real-time guidance!


                            Start living your best life with The Silva Method!

                        </p>

                        <button className='cssbuttons-io-button' onClick={() => {
                            navigate("/store/course/combo-plan")

                        }}>
                            Enroll Now

                        </button>


                        {/* <ul className="list-unstyled white-color robotofont mb-4" style={{
                            fontSize:"1rem"
                        }}>
                            <li>✓ Wake up feeling refreshed without an alarm clock</li>
                            <li>✓ Build better daily habits for a balanced lifestyle</li>
                            <li>✓ Transform how you sleep and wake up</li>
                            <li>✓ Feel recharged and ready for the day</li>
                        </ul>
                        <Button variant="warning" size="lg">
                            Enroll in Silva Method Complete Program
                        </Button> */}
                    </Col>
                </Row>

            </div>
        </div>
    );
};

const styles = `
  .silva-method-section {
  
  }

  .silva-method-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .silva-method-section .lead {
    font-size: 1.2rem;
  }

  .silva-method-section ul li {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }

  .enroll-button {
    background-color: #007bff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    transition: all 0.3s ease;
  }

  .enroll-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const SilvaMethodWithStyles = () => (
    <>
        <style>{styles}</style>
        <SilvaMethodComponent />
    </>
);

export default SilvaMethodWithStyles;