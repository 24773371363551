import React, { useContext } from 'react'
import { AuthContext } from '../../context/AllContext';
import { Card, Image } from 'react-bootstrap';
import ParaComp from '../../pages/SilvaManifestationProgram/ParaComp';
import HeadingNew from '../../pages/AboutUs/HeadingNew';

function CustomerReviews({ data }) {
     
    const { isDesktopOrLaptop,isBigScreen,isTablet,isMobile} = useContext(AuthContext);
    
        // console.log(data,"Dataaaaaa")

    return (
        // <div className="card" style={{width:"90%",borderRadius:"10px",margin:"20px",height:(isTablet || isMobile) ? "520px":"600px"}}>
        //     <img className="card-img-top" style={{width:"100%",height:(isTablet || isMobile)?"240px":"350px",objectFit:"cover"}} src={data.image} alt="Card image cap" />
        //         <div className="card-body text-start">
                  
        //             <p className="card-text" style={{fontWeight:"400",fontSize:(isTablet || isMobile)?"0.9rem":"1rem",lineHeight:"20px",color:"black"}}>
        //                 {
        //                     data.comment.substring(0,135)+"..."
        //                 }
                      
                       
        //             </p>
        //             <span className='cardspan' onClick={() => {
        //                     setData(data)
        //                     setModalShow(true)
        //                 }} style={{color:"#7a12d4",fontSize:"1.1rem",fontWeight:"bold"}}>Read more</span>
        //             <div className='mt-3'>
        //             <h6>
        //                 {data.name}
        //             </h6>
        //             <h6>
        //                 {
        //                     data.designation
        //                 }
        //             </h6>
                  
        //             </div>
        //         </div>
        // </div>
        <Card style={{width:"96%",borderRadius:"15px",height:isMobile?"auto":"auto",marginLeft:isMobile?"5px":"0",padding:"20px"}} className='mt-3'>
            <Card.Body>
                <div className='cardreviewcont'>
                    <div className='imgdiv'>
                        <Image src={data.image} style={{width:isMobile?"160px":"300px",height:isMobile?"160px":"250px",
                        objectFit:"cover",borderRadius:"15px"}} />
                    </div>
                    <div className='customreviewscard'>

                      <ParaComp data={[data.comment]} white={false} />
                      <div className='mt-4'>
                        <HeadingNew title={data.name} small start={true} white={false} />
                        <ParaComp
                        data={[
                            data.designation
                        ]}
                        white={false}
                        margin={false}
                        />
                      </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CustomerReviews