import moment from 'moment'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { Tooltip } from 'react-tooltip'
import { BsFillShareFill } from "react-icons/bs";
import { Link } from 'react-router-dom'
import CustomCard from '../../components/SingleShop/Card'

function SuggestClassCard({ val, handleShow }) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })


    
    return (
        <div>
         
           <Card style={{ width: isDesktopOrLaptop ? "98%" : "97%", height: "auto", marginLeft: isDesktopOrLaptop ? "0px" : "7px",borderRadius:"15px" }}>
                {/* <Card.Img variant="top" style={{ height: "50%" }} src={val.class_image || "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F459404039%2F466606324495%2F1%2Foriginal.20201117-110757?w=512&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C9000%2C4500&s=393721b212a2cb31fbce8119e07b31d5"} /> */}
               <div className='d-flex align-items-center justify-content-center'>
               <CustomCard
                index={Number(val?.course_id)}
                ins_image={val?.photo || require("../../images/no-photo-icon-22.png")}
                title={val?.course_name}
                ins_name={val?.instructor_name}
                date={`${moment(val?.start_date).format("Do MMM")}- ${moment(
                    val?.end_date
                ).format("Do MMM YYYY")}`}
                language={val?.language}
                mode={val?.delivery_method}
                all_instructor={val?.all_instructor}
              />
               </div>
                <Card.Body>
                <Link to={`/events/livedetails/${val.course_id}`}> 
                <Card.Title style={{ fontWeight: "600", fontSize: "1rem", color: "black" }}>{val.course_name}</Card.Title>
                </Link>
                    <Card.Text style={{ color: "#d63031" }}>
                        {
                            moment(val.start_date).format("LL")
                        }
                    </Card.Text>
                    <div className='d-flex flex-wrap justify-content-between'>
                    <b style={{ textTransform: 'capitalize', padding:"5px 20px",backgroundColor: '#801cb6',color:"#fff",borderRadius:"10px"}}>{val.delivery_method}</b>
                    <BsFillShareFill size={30} onClick={handleShow} color='#801cb6' />
                    </div>

                </Card.Body>
                {/* <Card.Footer style={{ border: "none", background: "transparent" }} className='d-flex justify-content-end align-items-center'>
                    <BsFillShareFill size={30} onClick={handleShow} color='#801cb6' />
                </Card.Footer> */}

            </Card>
          
            {/* <Tooltip id="newid"
                style={{ maxWidth: "60vw" }}
                multiline={true}
            /> */}

        </div>
    )
}

export default SuggestClassCard