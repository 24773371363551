import { Button } from 'react-bootstrap';
import { AiFillCheckCircle } from 'react-icons/ai'; // Import the React icon from react-icons

const StickyFooter = ({handleShow}) => {
    const buttonStyle = {
        background: '#f1c40f',
        border: '1px solid black', // Black border
        color: 'black', // Text color,
        padding: "8px 12px",
        fontWeight: "600",
        borderRadius: "10px",
        width:"140px",
        border: '1px solid whitesmoke', // White smoke border


    };

    return (
        <footer className="fixed-bottom text-white p-3" style={{
            background: "black",
            // opacity:"0.9"
        }}>
            <div className='d-flex justify-content-between align-items-center' style={{
                height:"60px"
            }}>
                <div>
                <p className='text-white mt-3 fw-bold' style={{
                    fontSize:"1.1rem"
                }}>Tap into your Mind’s Endless Potential</p>

                </div>
                <div style={{
                    alignSelf: "center",
                    marginTop: "20px",

                }}>
                    <Button style={buttonStyle} variant="primary" size='md'
                    onClick={()=>{
                        handleShow()
                    }}
                    >
                        <AiFillCheckCircle color='black' size={20} /> Sign Up
                    </Button>

                </div>

            </div>

        </footer>
    );
};

export default StickyFooter;