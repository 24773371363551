import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import { useState } from 'react';
import ComboCourseHeader from '../ComboCourse2/ComboCourseHeader';
import HypnoSusHeroSection from './HypnoSusHeroSection';
import TrustedBy from '../../Draganpages/TrustedBy';

import HypnosisReasons from './HypnosisReasons';
import BenifitSectionHyp from './BenefitsSectionHyp';
import LoveMindPerkSectionNew from '../../LoveMind/Two/LoveMindPerkSectionNew';
import BenefitsPerkHypnosis from './BenefitsPerkHypnosis';
import { reqData } from '../../../utils/baseUrl';
import { AuthContext } from '../../../context/AllContext';
import { useContext } from 'react';
import ComboPaymentCard from '../ComboCourse/ComboPaymentCard';
import { useEffect } from 'react';
import ComboCourseHeroSection from '../ComboCourse/ComboCourseHeroSection';
import HypnoisComboCard from '../ComboCourse/HypnosisComboCard';
import WhatsAlpha from './WhatsAlpha';
import FormSection from './FormSection';
import NewHeader from '../../AdvertisePages/newmembership/NewHeader';
import NewComboBanner from '../ComboCourse/NewComboBanner';

function HypnosisPage() {
    const [show, setShow] = useState(false);
    const [showCard, setShowCard] = useState(false);

    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { userData, isBigScreen, isDesktopOrLaptop, isTablet } = useContext(AuthContext);

    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "combo-plan",
        });

        console.log(res.data[0].course_description, "Response")


        setLoading(false);
        if (res.error === false) {
            setCourseData(res.data);
        }
    };


    useEffect(() => {
        getCourseData();
    }, [userData]);

    return (
        <>
            <NewHeader />
            <div className='parallexnew22'>
                <HypnoSusHeroSection />
                <TrustedBy />

                <HypnosisReasons />
                <BenifitSectionHyp />
                {/* <NewComboBanner/> */}
                <BenefitsPerkHypnosis />
                {
                    courseData && courseData[0] && (
                        <HypnoisComboCard data={courseData} showCard={showCard} setShowCard={setShowCard} />
                    )
                }

                <div>
                    <WhatsAlpha />
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src='/img/hypnosis/infographics.png' style={{
                        borderRadius: 10
                    }} />
                </div>

                <FormSection />
            </div>
            <footer
          className="py-3"
          style={{ backgroundColor: "#f4f5f9", width: "100%", height: "100%" }}
        >
          <p className="text-center" style={{ fontSize: "16px" }}>
            Copyright &copy; silvamethod.com
          </p>
        </footer>

        </>

    )
}

export default HypnosisPage