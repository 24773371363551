import React, { useEffect } from 'react'
import SilvaMembershipNavbar from './SilvaMembershipNavbar'
import BecomeMemberSection from './BecomeMemberSection'
import StoriesSlider from './StoriesSlider'
import BreakThroughs from './BreakThroughs'
import BetterSection from './BetterSection'
import MemberShipFooter from './MemberShipFooter'
import { useMediaQuery } from 'react-responsive'
import LifeSystemBanner from '../seminars/LifeSystemBanner'
import TrainerSection from './TrainerSection'
import SuccessBanner from './SuccessBanner'
import Sponsors from './Sponsors'
import MemberShipHowItWorks from './MemberShipHowItWorks'
import StartJourneySection from './StartJourneySection'
import LearnSection from './LearnSection'
import MemberShipFeatures from './MemberShipFeatures'
import OrderMembershipSection from './OrderMembershipSection'
import PaddingComponent from './PaddingComponent'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import SilvaLifeFAQ from '../SilvaManifestationProgram/SilvaLifeFAQ'
import MemberShippricingPlan2 from './MemberShippricingPlan2'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import LoadingSpinner from '../LoadingSpinner'
import { Image } from 'react-bootstrap'
import MemberShipHowWorks from './MemberShipHowWorks'
import SaleStrip from '../Contact/SaleStrip'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'


function SilvaMemberShip() {
    const navigate = useNavigate()
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 })
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 })
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);


    const handleGoTomembership = () => {
        const doc = document.querySelector(".indivcourses");
        if (doc) {
            doc.scrollIntoView({
                behavior: "smooth"
            })
        }
    }


    const handleNavigate = ()=>{
        navigate("/silva-life-system-with-dragan")
    }




    return (
        <>
            <div
                style={{ width: "100vw", minHeight: "100vh", paddingBottom: "20px" }}
            >
                {/* <SilvaMembershipNavbar /> */}
                <HomeHeader />
                <div style={{ marginTop: "70px",marginBottom:"20px" }}>
                {/* <SaleStrip/> */}
                </div>
                <BecomeMemberSection />
                <StoriesSlider />
                <BreakThroughs />
                <BetterSection />
                <SuccessBanner />
                <Sponsors />
                <TrainerSection />
                {/* <MemberShipHowItWorks /> */}
                <MemberShipHowWorks/>
                <MemberShippricingPlan2 />
                {/* <SaleStrip/> */}
                <StartJourneySection />
                <LearnSection />
                <MemberShipFeatures />
                <OrderMembershipSection />
                <div className="container">
                    <h2 className='text-center mb-4'>Frequently Asked Questions</h2>
                    <SilvaLifeFAQ />
                </div>
                <MemberShippricingPlan2 />
                <PaddingComponent />
                {
                    isMobile && (
                        <MemberShipFooter />
                    )
                }
                {
                    (isDesktopOrLaptop || isTablet) && (
                        <CustomFooter />
                    )
                }
            </div >
        </>
    )
}

export default SilvaMemberShip