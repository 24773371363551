import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { useMemo } from 'react'
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from 'react-slick';

function WhatsAlpha() {

    const { isMobile,isTablet,isDesktopOrLaptop,isMediumDesktop } = useContext(AuthContext);

      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: isDesktopOrLaptop ? 3 : isTablet ? 2 : 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows:false
    };


    const data = useMemo(() => {
        return [
            {
                title: "Boosted Creativity",
                text: "Alpha state enhances creative thinking and problem-solving skills.",
                img: "/img/lovemind/icons/20.png"
            },
            {
                title: "Reduced Stress",
                text: "Promotes relaxation, lowers anxiety, and decreases cortisol levels.",
                img: "/img/lovemind/icons/21.png"
            },
            {
                title: "Increased Intuition",
                text: "Heightens intuitive abilities and gut feelings",
                img: "/img/lovemind/icons/22.png"
            },
            {
                title: "Improved Learning",
                text: "Enhances information retention and cognitive performance.",
                img: "/img/lovemind/icons/23.png"
            },
            {
                title: "Enhanced Visualization",
                text: "Heightens visualization abilities to achieve goals.",
                img: "/img/lovemind/icons/24.png"

            },
            {
                title: "Increased Mindfulness",
                text: "Deepens meditation, and fosters self-awareness, and introspection.",
                img: "/img/lovemind/icons/25.png"

            },

        ]

    }, [])
    return (
        <div className='container' style={{
            paddingTop: 80,
            paddingBottom: isMobile?0:100
        }}>
            <Row className='container justify-content-center align-items-center'>
                {
                    !isMobile && !isMediumDesktop && (
                        <Col xs={12} lg={5}>
                        <img style={{
                            width: (isTablet || isMobile) ?"100%":"90%",
                            borderRadius: 15
                        }}
                            src='/img/hypnosis/hypnosistrial.jpg'
                        />
    
                    </Col>
                    )
                }
                <Col xs={12} xl={7}>
                    <div style={{
                       
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column"
                    }}>
                        <div className='my-3'>
                        <HeadingNew title={"What is "} colored={"Alpha State"} start={isMobile?true:false}/>
                        {
                            isMobile && (
                                <img style={{
                                    width: (isTablet || isMobile) ?"100%":"90%",
                                    borderRadius: 15,
                                    marginTop:"15px"
                                }}
                                    src='/img/hypnosis/hypnosistrial.jpg'
                                />
                            )
                        }
                        </div>
                        <ParaComp
                            white={false}
                            margin={false}
                            data={[
                                "Before we fall asleep and right after we wake up from a good sleep, the human brain enters into the alpha state of mind. Where our brain frequencies range from 7 to 14 Hz per second. The Alpha state connects our conscious and subconscious minds. It helps us relax deeply while still being aware of what's happening around us. And the calmness makes our minds more open to learning new things and considering new ideas."
                            ]}
                        />
                        <div className='mt-1 mb-3'>
                        <HeadingNew title={"Benefits"} start small />
                        

                        </div>
                        {!isMobile && (
                            <Row className='justify-content-center align-items-center gy-3'>
                                {
                                    data.map((val, i) => (
                                        <Col xs={12} md={6} lg={4}>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <Card style={{
                                                    width: isMobile ? "90%" : "95%",
                                                    padding: "20px",
                                                    borderRadius: "15px",
                                                    height: isMobile ? "auto" :isDesktopOrLaptop?"330px": "250px",
                                                    margin: "0 auto"
                                                }}
                                                    className='cardhoverhypnosis'
                                                >
                                                    <Card.Body className='d-flex justify-content-center align-items-center gap-3'>
                                                        {/* <img src={val.img} width={100} height={100} /> */}
                                                        <div>
                                                            <span className='spanclass'>{i + 1}</span>
                                                            <h6 style={{
                                                                color: "black",
                                                                fontWeight: "600",
                                                                fontSize: "1.1rem"
                                                            }}>
                                                                {val.title}
                                                            </h6>
                                                            <p style={{
                                                                color: "black"
                                                            }}>
                                                                {val.text}
                                                            </p>
                                                        </div>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Col>
                                    ))
                                }

                            </Row>
                        )}
                    </div>

                </Col>

            </Row>
            <div className="container orderslider">
                        {
                            isMobile && (
                                <Slider {...settings}>
                                    {
                                        data.map((val, i) => (
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <Card style={{
                                                        width: isMobile ? "98%" : "95%",
                                                        padding: "10px",
                                                        borderRadius: "15px",
                                                        height: "250px",
                                                        margin: "0 auto"
                                                    }}
                                                        className='cardhoverhypnosis'
                                                    >
                                                        <Card.Body className='d-flex justify-content-center align-items-center gap-3'>
                                                            {/* <img src={val.img} width={100} height={100} /> */}
                                                            <div>
                                                                <span className='spanclass'>{i + 1}</span>
                                                                <h6 style={{
                                                                    color: "black",
                                                                    fontWeight: "600",
                                                                    fontSize: "1.1rem"
                                                                }}>
                                                                    {val.title}
                                                                </h6>
                                                                <p style={{
                                                                    color: "black"
                                                                }}>
                                                                    {val.text}
                                                                </p>
                                                            </div>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                        ))
                                    }
                                </Slider>
                            )
                        }
</div>


        </div>
    )
}

export default WhatsAlpha