// MyForm.js
import React, { useState } from 'react';
import { Card, Form, Button, Row, Col, Image } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { reqData } from '../../utils/baseUrl';
import { useNavigate } from 'react-router-dom';
import { allcodes } from '../../utils/allCountryCodes';

const MyForm = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        countryCode: {
            name: "United States",
            dial_code: "+1",
            code: "US"
        }, // New field for country code
        whatsapp: '',
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const handleChangeDropDown = (e) => {
        const found = allcodes.find((val) => ValidityState.name === e.target.value);

        if (found) {
            setFormData(prev => {
                return {
                    ...prev,
                    countryCode: found
                }
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form fields
        if (!formData.fullName || !formData.email || !formData.countryCode || !formData.whatsapp || !formData.countryCode) {
            toast.error('Please fill in all fields');
            return;
        };

        if(formData.fullName === "Mohammad Ali Ashraf" || formData.email === 'ashrafm703@gmail.com'){
            toast.error('an error occured!');
            return;
        }

        // Additional validation logic can be added here


        try {
            setLoading(true);
            const res = await reqData('LeadGenerationForm', 'POST', {
                name: formData.fullName,
                email: formData.email,
                whats_app: `${formData.whatsapp}`,
                country_code:formData.countryCode.dial_code // Concatenate country code and WhatsApp number
            });
            setLoading(false);

            if (res && res.error === false) {
                toast.success('Form submitted successfully!');
                setFormData({
                    fullName: '',
                    email: '',
                    countryCode: '',
                    whatsapp: '',
                });
                navigate('/thank-you3');
            } else {
                toast.error(res.error);
            }
        } catch (err) {
            console.log(err);
        }
        // If all validations pass, you can proceed with further actions
    };

    return (
        <>
            <div className='container py-5 paymentsection paymenttwonew'>
                <Row className='justify-content-center align-items-center'>
                    <Col lg={7}>
                        <h2 className='fontclass text-center' style={{ color: 'black' }}>
                            Say YES To Silva Method Online Live Learning Experience
                        </h2>
                        <Image src='img/newpic.png' />
                    </Col>
                    <Col lg={5}>
                        <Card
                            text='white'
                            style={{ width: '100%', margin: 'auto', marginTop: '50px', background: '#6a3093', borderRadius: '10px' }}
                        >
                            <Card.Body>
                                <Card.Title className='text-white my-3 text-center'>
                                    Submit The form below to book your class !
                                </Card.Title>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId='formFullName'>
                                        <Form.Label className='white-color'>Full Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter your full name'
                                            name='fullName'
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            className='mb-3'
                                        />
                                    </Form.Group>

                                    <Form.Group controlId='formEmail'>
                                        <Form.Label className='white-color'>Email address</Form.Label>
                                        <Form.Control
                                            type='email'
                                            placeholder='Enter your email'
                                            name='email'
                                            value={formData.email}
                                            onChange={handleChange}
                                            className='mb-3'
                                        />
                                    </Form.Group>

                                    <Form.Group controlId='countryCode' className='mb-3'>
                                        <Form.Label className='white-color'>Country Code</Form.Label>
                                        <Form.Select
                                            name='countryCode'
                                            value={formData.countryCode.name}
                                            onChange={handleChangeDropDown}
                                        >
                                            {allcodes.map((country, index) => (
                                                <option key={index} value={country.name}>
                                                    {`${country.name} (${country.dial_code})`}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group controlId='wpnumber' className='mb-3'>
                                        <Form.Label className='white-color'>WhatsApp Number</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter your WhatsApp number'
                                            name='whatsapp'
                                            value={formData.whatsapp}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>



                                    <Button variant='light' type='submit' className='mt-2' disabled={loading}>
                                        {loading ? '...loading' : 'Submit'}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default MyForm;
