import React from 'react';
import './customcheck.css'; // Make sure to import the CSS file or copy the styles directly into your component

const CheckboxComponent = ({id}) => {
  return (
    <div className="checkbox-wrapper-19">
      <input type="checkbox" id={`cbtest-${id}`} checked />
      <label htmlFor={`cbtest-${id}`} className="check-box"></label>
    </div>
  );
};

export default CheckboxComponent;
