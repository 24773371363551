import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { AuthContext } from '../../context/AllContext';
import { requestData } from '../../utils/baseUrl';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

function PaymentUnSuccess() {
  const [loading, setLoading] = useState("");

  const location = useLocation()
  const params = useParams();
  const navigate = useNavigate();
  const { isUserLoggedIn, userData } = useContext(AuthContext)




  useEffect(() => {
    const data = location.search.substring(1)?.split("&");
    const planId = data[0]?.split("=")[1]
    const price = data[1]?.split("=")[1]
    const coupon = data[2]?.split("=")[1]
    const token = data[3]?.split("=")[1];


    const dataToSend = {}
    if (planId) {
      dataToSend.course_id = atob(planId)
    }
    if (price) {
      dataToSend.price = atob(price)
    }

    if (coupon) {
      dataToSend.coupon_code = atob(coupon)
    }
    if (token) {
      dataToSend.token = token
    }
    if (isUserLoggedIn && userData) {
      dataToSend.student_id = userData.student_id
    }

    // console.log(dataToSend)
    paypalcancel(dataToSend)

  }, [isUserLoggedIn, userData])

  const paypalcancel = async (data) => {
    setLoading(true)
    const res = await requestData("payPalCancel", "POST", data);
    setLoading(false)

    // console.log(res, "response")
    if (res && res.error === false) {
      swalfire()
    } else {
      swalfire2()
    }
  }


  const swalfire = function () {
    Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: 'Payment Failed',
      showConfirmButton: true,
      showConfirmButton: true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      allowEnterKey:false
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/silva-method-courses")
      }
    })
  }


  const swalfire2 = function () {
    Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: 'Payment Failed',
    })

  }



  return (
    <div className='d-flex justify-content-center align-items-center'>
      {
        loading && (
          <Spinner animation="border" variant="light" />
        )
      }
    </div>
  )
}

export default PaymentUnSuccess