import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaEdit, FaPhoneAlt,} from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import './appointbook.css'
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import HeadingNew22 from "../../AboutUs/HeadingNew22";
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import { RiWhatsappFill } from "react-icons/ri";



function AppointmentOptions() {

    const {isMobile}= useContext(AuthContext);

    const handleScroll= ()=>{
      polyfill()
      const item= document.querySelector('.newappointmentform');
  
      scrollIntoView(item, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    };

    const handleMail = () => {
      window.location.href = "mailto:digital@silvamethod.com?subject=Apply for an appointment&body=I wanted to reach out...";
    };

    const handleDial = () => {
      window.location.href = "tel: +919932600266";
    };


  const options = [
    {
      id: 1,
      text: `Just fill out and submit the form above, and our team will contact you to schedule a time that fits your schedule to resolve any doubts.`,
      cta: "Fill The Form",
      icon: <FaEdit color="#000"/>,
      onclick:()=>handleScroll()
    },
    {
      id: 2,
      text: `Drop us an email at digital@silvamethod.com with your doubts, and our team will reach out to arrange a call at your convenience.`,
      cta: "Mail Us",
      icon: <IoMail color="#000"/>,
      onclick:()=>handleMail()

    },
    {
      id: 3,
      text: `Alternatively, you can give us a WhatsApp voice call at 9932600266 to book your free appointment directly, as per your time. Our team is ready to assist you and ensure you get the support you need.`,
      cta: "Whatsapp Now at  9932600266",
      icon: <RiWhatsappFill color="#000"/>,
      onclick:()=>handleDial()
    },
  ];
  return (
    <div>
      <Container>
      <HeadingNew22 title={"3 booking options, one goal:"}  bottomMargin/>
      <HeadingNew22 title={"your FREE consultation—choose your preferred way!"} small/>
       
        <Row className={`py-${isMobile?"2":"5"} gy-3`}>
          {options?.map((option, i) => (
            <Col sm={12} md={6} lg={4} className="px-3">
              <Card className="w-100 h-100 p-3 cardbdy2" style={{borderRadius:"15px"}}>
                <div className="d-flex flex-column h-100 align-items-center">
                <div>
                    <p className="text-white mt-2 fw-bold text-center">{option.text}</p>
                </div>
                <button className="mb-2 mt-auto newappointbtn p-2 px-3 d-flex justify-content-center align-items-center" onClick={option.onclick}><span className="pe-2">{option.icon}</span>{option.cta}</button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default AppointmentOptions;
