import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';

function Boost() {
    const { isMobile } = useContext(AuthContext);

    const handleScroll = ()=>{
        const card = document.querySelector(".includelivecheck")
        if(card){
            card.scrollIntoView({
                behavior:"smooth"
            })
        }
      }

    return (
        <div className='container my-5'>
            <Row className='container justify-content-center align-items-center' style={{
                position: "relative"
            }}>
                <Col xs={12} lg={6} className={isMobile ? 'order-2' : 'order-1'}>
                    {!isMobile && <HeadingNew title={"Boost Your Financial Well-Being With "} colored={"The Silva Method"} start />}


                    <ParaComp
                        white={false}
                        data={[
                            "The concept of financial aspiration is deeply personal and varies greatly from person to person. It's not just about a number; it's about what that number represents in terms of freedom, security, and fulfillment.At the heart of the Silva Method lies a profound truth that the power to transform your financial reality resides within you. Through proven techniques rooted in mindfulness, visualization, and manifestation, the Silva Method will guide you on a journey of self-discovery and empowerment. It Will help you to cultivate an abundance mindset.",
                            "But the Silva Method goes beyond just mindset—it also provides practical tools and techniques to help you take inspired action towards your financial goals. Whether you want to start a successful business, advance in your career, or increase your income, the Silva Method can help you achieve your dreams effortlessly. Step into a world where your financial reality is sculpted by your inner strength and guided by the Silva Method's wisdom.",
                        ]}
                        fw={"400"}
                    />
                    <button className='herodiv-becomememberbtnnew' style={{
                        marginLeft: "0",

                    }}
                        onClick={handleScroll}
                    >
                        Enroll now
                    </button>




                </Col>
                <Col xs={12} lg={6} className={isMobile ? 'order-1' : 'order-2'} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection:"column"
                }}>


                        {isMobile &&  <HeadingNew title={"Boost Your Financial Well-Being With "} colored={"The Silva Method"} start={isMobile?false:true}/>}
                    <div className='d-flex justify-content-end align-items-end'>
                        <Tilt style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end"
                        }}>

                                <img src='/img/hypnosis/boostimg.png' style={{
                                    width: "95%",
                                }} />
                        </Tilt>
                    
                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default Boost