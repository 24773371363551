// ProductGrid.jsx
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../context/AllContext';
import useCountdown from './CountDown';

export const ProductGrid = () => {
  const { isMobile } = useContext(AuthContext);

  const products = [
    { id: 1, name: 'Silva Life System', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-lifesystem_1.jpg' },
    { id: 2, name: 'Silva Intuition System', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-intuition.jpg' },
    { id: 3, name: 'Silva Manifesting System', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-manifest_1.jpg' },
    { id: 4, name: 'Silva Mind Body Healing', price: '$159', image: 'https://silvamethod.com/manage/assets/uploads/silva-mind-body.jpg' },
    {
      id:5,name:"Alpha Sound",price:"$40",image:"https://silvamethod.com/manage/assets/uploads/silva-alpha-sound.jpg"
    },
    {
      id:6,name:"Theta Sound",price:"$40",image:"https://silvamethod.com/manage/assets/uploads/theta-sound.jpg"
    }
  ];

  return (
    <div className="container py-5">
      <h1 className={isMobile ? "ps_title text-black mb-4 text-start" : "ps_title text-black mb-4 text-center"}>Inside the Silva Method Complete Program</h1>
      <div className="dsc_grid_m4">
        {products.map((product) => (
          <div key={product.id} className="dsc_item_j2">
            <img src={product.image} alt={product.name} className="dsc_img_p5" />
            <h3 className="dsc_name_r8 white-color robotofont mt-3">{product.name}</h3>
            <p className="dsc_price_l3 white-color robotofont">{product.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

// BlackFridayTimer.jsx

export const BlackFridayTimer = () => {
  const [time, setTime] = useState({ hours: '00', minutes: '00', seconds: '00' });
  const { days, hours, minutes, seconds, isActive } = useCountdown("2024-11-30")

  if (!isActive) {
    return null
  }
  return (
    <div className="bf_container_y6">
      <h2 className="bf_special_h4">Once in a Life Time Offer</h2>
      <h1 className="bf_title_w2">BLACK FRIDAY</h1>
      <h2 className="bf_subtitle_q9">UP TO 60% OFF</h2>
      <div className="bf_timer_v8">
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{days}</span>
          <span className="bf_label_s1">DAYS</span>
        </div>
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{hours}</span>
          <span className="bf_label_s1">HOURS</span>
        </div>
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{minutes}</span>
          <span className="bf_label_s1">MINUTES</span>
        </div>
        <div className="bf_box_u3">
          <span className="bf_digits_t7">{seconds}</span>
          <span className="bf_label_s1">SECONDS</span>
        </div>
      </div>
    </div>
  );
};

/* styles.css */
