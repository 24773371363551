import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AllContext'
import Tilt from 'react-parallax-tilt';
import TypesMeditateCard from './TypesMeditateCard';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

function NightMeditationBenefits() {
    const {isMobile} = useContext(AuthContext);


    const cardColors3 = [
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)',  // Charcoal to dark grey gradient
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)',   // Charcoal to dark grey gradient
        'linear-gradient(135deg, #4A235A, #8E44AD)',  // Purple gradient
        'linear-gradient(135deg, #1F618D, #2980B9)',  // Blue gradient
        'linear-gradient(135deg, #117A65, #1ABC9C)',  // Teal gradient
        'linear-gradient(135deg, #2C3E50, #34495E)'   // Charcoal to dark grey gradient
    ];

    const meditationContent = useMemo(()=>{
      return [
        {
            title: "Improved Sleep Quality",
            content: "Mindfulness meditation for sleep helps calm the mind, by reducing racing thoughts, and creates an environment conducive to deeper sleep."
        },
        {
            title: "Stress Reduction",
            content: "Meditation promotes deep relaxation, reducing the stress hormone cortisol. Lower stress levels promote a sense of peace, allowing you to drift into sleep more easily."
        },
        {
            title: "Enhanced Emotional Well-Being",
            content: "Regular meditation cultivates emotional awareness and resilience, helping you process your feelings effectively and reduce nighttime anxiety."
        },
        {
            title: "Better Focus and Concentration",
            content: "Night-time meditation can improve your cognitive functions, leading to enhanced focus and concentration the following day."
        },
        {
            title: "Pain Management",
            content: "Mindfulness practices can reduce perceptions of pain, making it easier to relax and fall asleep, particularly if you struggle with chronic pain."
        },
        {
            title: "Increased Self-Awareness",
            content: "Meditation encourages self-reflection and mindfulness, leading to a better understanding of your thoughts and feelings, which can help in personal growth."
        },
        {
            title: "Regulated Sleep Patterns",
            content: "Establishing a night-time meditation routine can help signal your body when it’s time to sleep, promoting more consistent sleep patterns over time."
        },
        {
            title: "Emotional Balance",
            content: "Mindfulness meditation for sleep enhances your ability to manage emotions, allowing you to approach problems with a clearer mindset rather than reacting impulsively."
        },
        {
            title: "Boosted Immune System",
            content: "Regular meditation has been linked to improved immune response, helping your body recover during the night and preparing you for the day ahead."
        },
        {
            title: "Deeper Connection with Self",
            content: "Night-time meditation fosters a stronger connection to your inner self, providing a sense of peace and clarity that can improve overall life satisfaction."
        }
      ]
    },[])

    return (
        <div className="container py-5 d-flex justify-content-center align-items-center flex-column">
            <h3 style={{
                color: "black",
                fontSize: isMobile ? "2rem" : "3rem"
            }}
                className='lora-sans'
            >
               Benefits of  {" "}
                <span style={{ color: '#572ac7' }}>
                Regular Night-Time Meditation
                </span>
            </h3>

            <p className='text-dark robotofont mt-3 text-center' style={{
                fontSize: isMobile ? "1rem" : "1.1rem"
            }}>
                
                If you can’t get up to practice morning meditation, consider practicing our free guided meditation for sleep. This practice not only helps you unwind but also prepares your mind and body for a restful night’s sleep. Here are ten benefits of regular night-time meditation that can transform your evening routine:

            </p>
            <Tilt>
                <img
                    src='/img/bg/blogpages/Benefits-of-Regular-Night-Time-Meditation.jpg'
                    style={{
                        borderRadius: "15px",
                        boxShadow: "0 4px 10px rgba(128, 0, 128, 0.6)" // Purple box shadow
                    }}
                    className='mt-3'
                />
            </Tilt>

            <Row className='justify-content-center align-items-center my-5 gy-2'>
                {
                    meditationContent.map((val, index) => (
                        <Col md={6}>

                            <TypesMeditateCard item={val} color={cardColors3[index]} />
                        </Col>
                    ))

                }


            </Row>

           
          
        </div>
    )
}

export default NightMeditationBenefits