import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import FAQ2 from '../../CoursePage/FAQ2';

function MindControlFaqs() {

    const faqs=[
        {
            id:1,
            heading:"Which Jose Silva book is the best?",
            body:`Jose Silva authored several books throughout his career, each offering unique insights into his method for self-improvement, intuition development, and manifestation—the bestselling books “The Silva Mind Control Method,"  “Silva Mind Control Methods of Mental Dynamic,"  “Silva Ultramind ESP System,” and many more.`
        },
        {
            id:2,
            heading:"How did science discover the Silva method?",
            body:`The Silva Method was developed by Jose Silva in the 1960s and is verified that people can control their brainwaves. It incorporates various techniques and principles that have been studied and scientifically validated with personal experiences.`
        },
        {
            id:3,
            heading:"What are the benefits of Silva mind control?",
            body:`The Silva Mind Control Method teaches techniques to enhance the ability to focus, reduce stress, develop intuition and creativity, and help you manifest success and goals.`
        },
        {
            id:4,
            heading:"Does Silva technique really work?",
            body:`Absolutely! The Silva Method is a powerful technique that combines visualization, meditation, and a positive mindset. It aims to unlock your full potential and attract positive outcomes in your life.`
        },
        {
            id:5,
            heading:"What celebrities use the Silva method?",
            body:`The Silva Method has been accepted by many individuals and proven to create a positive impact in life. Many celebrities, including Oprah Winfrey, Katy Perry, and Ellen DeGeneres, have experienced positive outcomes from meditation and other practices aligned with the Silva Method.`
        },
        {
            id:6,
            heading:"How do I start with Jose Silva mind control method?",
            body:<div><p className='text-white'>The Silva Method is all about using your mind’s power to achieve your goals. It involves techniques like visualization, meditation, and positive affirmations.</p><p className='text-white'>To know more about how you can practice the Silva Mind Control Method, click on this <span><a href="https://silvamethod.com/the-silva-mind-control-method" className='text-white fw-bold'>link.</a></span> Or go to <span className='text-decoration-underline'>https://silvamethod.com/the-silva-mind-control-method</span></p></div>
        },
        {
            id:7,
            heading:"Does the Silva method really improve your brain functions?",
            body:`Definitely! The Silva Method has been reported to have positive effects on brain function. It incorporates techniques like visualization and meditation, which help enhance focus, creativity, and mental clarity.`
        },
        {
            id:8,
            heading:"What is the Silva Mind Control Method?",
            body:`The Silva Mind Control Method is a self-help program developed by José Silva that aims to tap into the power of the mind to improve various aspects of life, such as health, success, and happiness.`
        },
        {
            id:9,
            heading:"What are the benefits of practicing the Silva Mind Control Method?",
            body:`Benefits may include enhanced mental clarity, improved focus, reduced stress, increased self-confidence, and the ability to manifest desired outcomes.`
        },
        {
            id:10,
            heading:"Is the Silva Mind Control Method scientifically proven?",
            body:`Yes. While some aspects of the method may align with principles of cognitive psychology and neuroplasticity, the Silva Mind Control Method's effectiveness may vary for individuals, and empirical evidence supporting its claims may be limited.`
        },
        {
            id:11,
            heading:"Who founded the Silva Mind Control Method?",
            body:`The Silva Mind Control Method was developed by José Silva, a self-taught parapsychologist and author, in the 1960s.`
        },
        {
            id:12,
            heading:"What are the core principles of the Silva Mind Control Method?",
            body:`Core principles include the belief in the power of the mind, the importance of accessing the subconscious, and the use of techniques like visualization and positive thinking.`
        },
        {
            id:13,
            heading:"Can anyone learn the Silva Mind Control Method?",
            body:`Yes, the method is designed to be accessible to anyone regardless of age, background, or prior experience. anyone can learn silva method and its powerful techniques with jose silva's books or courses.`
        },
        {
            id:14,
            heading:"Are there any courses or programs available for learning the Silva Mind Control Method?",
            body:`Yes, there are various workshops, seminars, and online courses offered by certified Silva Method instructors worldwide.`
        },
        {
            id:15,
            heading:"How long does it take to see results with the Silva Mind Control Method?",
            body:`Results may vary, but many practitioners report experiencing positive changes within a few weeks to months of consistent practice.`
        },
        {
            id:16,
            heading:"What techniques are taught in the Silva Mind Control Method?",
            body:`Techniques may include relaxation exercises, mental visualization, positive affirmations, and meditation practices.`
        },
        {
            id:17,
            heading:"What are the Silva Mind Control Method courses offered on the Silva Method website?",
            body:`The Silva Method website offers a range of courses covering various levels of the Silva Mind Control Method, including introductory courses, advanced modules, and specialized workshops & live sessions.`
        },
       
        {
            id:18,
            heading:"Are these courses accredited by the original Silva Method organization?",
            body:`Yes, the courses offered on the Silva Method official website are not only developed by the original Silva Method organization but also accredited by it, guaranteeing authenticity and quality. It's important to note that courses found on other websites or platforms may not carry the same accreditation or quality assurance. Therefore, we recommend exercising caution and ensuring credibility before purchasing Silva Method courses from alternate sources.`
        },
        {
            id:19,
            heading:"Do the courses on the Silva Method website provide lifetime access to the materials?",
            body:`Yes, participants in the Silva Mind Control Method courses on the Silva Method website typically receive lifetime access to the course materials, allowing them to revisit and review the content at their convenience.`
        },
        {
            id:20,
            heading:"How can I enroll in a Silva Mind Control Method course on the Silva Method website?",
            body:<div><p className='text-white'>Enrollment can be done directly on the Silva Method website or Silva Method App by selecting the desired course, use the coupon code if you have any, then proceed for the payment, and you're done. explore all courses here: <span><a href="https://silvamethod.com/silva-method-courses" className='text-white fw-bold'>https://silvamethod.com/silva-method-courses</a></span></p></div>
        },
        {
            id:21,
            heading:"Do the courses include interactive elements or are they primarily self-paced?",
            body:`The courses often include a combination of self-paced learning modules, interactive exercises, and multimedia content to cater to different learning styles and preferences.`
        },
        {
            id:22,
            heading:"What are the different levels or modules available in the Silva Mind Control Method courses?",
            body:`The courses typically include multiple levels or modules, starting from introductory levels for beginners and progressing to more advanced techniques and topics.`
        },
        {
            id:23,
            heading:"Are there any additional materials or resources provided along with the courses?",
            body:`Yes, participants often receive supplementary materials such as workbooks, audio recordings, and access to online forums or communities to enhance their learning experience.`
        },
        {
            id:24,
            heading:"Can I access customer support if I have questions or issues while taking the Silva Mind Control Method courses?",
            body:`Yes, participants can reach out to customer support via email at digital@silvamethod.com for any queries or assistance they may need during the course.`
        },
        {
            id:25,
            heading:"How to join the Silva Method Community?",
            body:<div><p className='text-white'>You can join the Silva Method Community by accessing the WhatsApp Group: <span><a href="https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ" className='text-white fw-bold'>https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ.</a></span></p><p className='text-white'>In this group, you'll receive daily updates, participate in discussions, access course materials, and stay updated on class schedules and announcements. also stay updated with our official Facebook page: <span><a href="https://www.facebook.com/SilvaInternationalInc" className='text-white fw-bold'>https://www.facebook.com/SilvaInternationalInc</a></span> </p></div>
        },
    ];


  return (
    <div className='mindfaqmain py-3'>
        <HeadingNew title={"Silva Mind Control Method"} small/>
        <HeadingNew title={"Explore"} colored={"FAQs"}/>
        <div className='container'>
            {
                faqs?.map((faq,i) =>
                <FAQ2
                key={i}
                heading={faq?.heading}
                body={<p style={{ color: "white" }}>{faq?.body}</p>}
                offShadow={true}
              />
            )}
        </div>
    </div>
  )
}

export default MindControlFaqs