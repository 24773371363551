import React from 'react'
import { Badge, Card, Col, Container, Image, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew';
import { AuthContext } from '../../../context/AllContext';
import { useContext } from 'react';
import ArrowButton from './ArrowAnimation';
import ComboPaymentCard from './ComboPaymentCard';
import { useState } from 'react';
import ReactStars from 'react-stars';
import { GiStairsGoal } from "react-icons/gi";

function HypnoisComboCard({ data, showCard, setShowCard }) {
    const { isMobile } = useContext(AuthContext);


    const handleScroll = () => {
        const doc = document.querySelector(".cardtoscroll");
        console.log(doc, "document")
        if (doc) {
            doc.scrollIntoView({
                behavior: "smooth"
            })
        }

    }

    const [cardClicked, setCardClicked] = useState(false);

    const handleScroll2 = () => {
        const doc = document.querySelector(".livecombo");
        console.log(doc, "document")
        if (doc) {
            doc.scrollIntoView({
                behavior: "smooth"
            })
        }

    }


    return (
        <div className="herosectioncombo2 cardtoscrollcombo" style={{
            position: "relative",
            paddingTop:80,
            paddingBottom:80
        }}>
            <Container>
                <Row className="justify-content-between align-items-center" >
                    <Col sm={12} md={12} xl={6} className="px-4">

                        {
                            data && data[0].course_title && (
                                <HeadingNew
                                    title={
                                        data[0].course_title
                                    }
                                    start
                                    white
                                />
                            )
                        }

                        <h6 className='white-color mt-3'>
                            <span style={{ marginRight: "10px" }}>
                                <GiStairsGoal color='#fff' size={30} />
                            </span>
                            Beginner-Advanced</h6>
                        <p className="ps-0 mt-3 white-color" style={{
                            fontWeight: "600"
                        }}>

                            The Silva Method -  America’s 1st Dynamic Meditation and Personal Growth Program Since 1966.
                            José Silva devoted his entire life to the betterment of humanity and developed the Silva Method. And we are dedicated to upholding that tradition. Hence, we are pleased to present a new offer for your convenience. Now there is no need to purchase all the courses separately. Purchase the Silva Method Complete Course and get lifetime access to all 8 Silva Method Courses. Furthermore, upon purchasing this combo course, you will receive lifelong complimentary support from the Certified Silva Method Instructors.
                            Buy now to discover the better version of yourself!

                        </p>
                        <div style={{ fontSize: "30px" }} className='ratingbox'>
                            <h5 className='mt-1'>
                                <Badge bg="light" text="dark">{data[0]?.course_id === 'unstress-toolbox' ? "Newly Launched" : "BestSeller"}</Badge>
                            </h5>
                            <h4 style={{ color: "#ffd700" }} className='ratingheading'>{data?.course_rating}</h4>
                            <ReactStars count={5} value={data[0]?.course_rating} edit={false} />
                            <h5 className='white-color mt-1'>Ratings</h5>

                            {Number(data[0]?.how_many_students) > 0 && <p className='ratingnumber'>
                                ({data[0]?.how_many_students} Students Enrolled)
                            </p>}
                        </div>

                        <button className='cssbuttons-io-button' onClick={() => {
                            setShowCard(false)
                            if (isMobile) {
                                handleScroll()
                            }
                            setCardClicked(true)
                        }}>
                            Enroll Now
                        </button>

                    </Col>
                    <Col sm={12} md={12} xl={6} className="d-flex justify-content-center align-items-center mt-3 mt-md-0">
                        {/* <Image src="/img/spanish_mobile_banner.png" className="px-4" /> */}
                        {
                            showCard ? (
                                <div className="cardiv599 mt-sm-5 mt-xl-0">
                                    <Card style={{ width: "95%", padding: 10, borderRadius: 15 }}>
                                        <Card.Body>
                                            <Row className='gap-sm-3 gap-xl-0'>
                                                <Col xs={12} xl={6}>
                                                    <img src='/img/live_interaction_image.png' className='image599banner' style={{

                                                        borderRadius: 15

                                                    }} />
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <div className='d-flex flex-column gap-1'>
                                                        <HeadingNew small title={"Live Interactive Program"} start />
                                                        <p className='text-start'>
                                                            Enjoy the live experience of learning in a transformative setting with cohorts
                                                        </p>
                                                        <div>
                                                            <button className='aboutclassbtn3'>
                                                                Learn More
                                                            </button>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>



                                        </Card.Body>
                                    </Card>
                                    <Card style={{ width: "95%", padding: 10, borderRadius: 15 }}>
                                        <Card.Body className=''>
                                            <Row className='gap-sm-3 gap-xl-0'>
                                                <Col xs={12} xl={6}>
                                                    <img src="/img/combo_course_image.png" className='image599banner' style={{

                                                        borderRadius: 15

                                                    }} />
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <div className='d-flex flex-column gap-1'>
                                                        <HeadingNew small title={"Silva Method Complete Program"} start />
                                                        <p className='text-start'>
                                                            Get access to complete Silva Method Digital course for a comprehensive learning
                                                        </p>
                                                        <div>
                                                            <button className='aboutclassbtn3'>
                                                                Learn More
                                                            </button>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>



                                        </Card.Body>
                                    </Card>

                                </div>
                            ) : data ? (
                                <div className='mt-5 mt-xl-0 cardtoscroll'>
                                    <ComboPaymentCard cardClicked={cardClicked} datanew={data[0]} showShadow={false} />
                                </div>
                            ) : null
                        }

                    </Col>

                </Row>

            </Container>
            <div className='arrowanimatebox'>
                <ArrowButton onclick={handleScroll2} />

            </div>
        </div>
    )
}

export default HypnoisComboCard