import React from 'react'
import HomeHeader from '../../../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../../../Home/Home/HomeFooter/Footer'
import "./mindfulness.css"
import MindFulnessHeroSection from './MindfulnessHeroSection'
import WhatIsMindFulness from './WhatIsMindfulNess'
import MindfulnessMeditation from './MindFulnessMeditation'
import DifferenceSectionMindful from './DifferenceSectionMindful'
import MindFulnessBenefits from './MindfulNessBenefits'
import MindfulnessMeditationTechniques from './MindfulnessMeditationTechniques'
import PracticalMindfulness from './PracticeMindfulness'
import MindFulWays from './MindfulWays'
import BeginnersGuidedMeditation from './BeginnersGuidedMeditation'
import FinalThoughts from './FinalThoughts'
import MindfulnessSignup from '../BlogLandingPage3/MindfulnessForm'
function IntroToMindFulNess() {
  return (
    <div>
        <HomeHeader />
        <MindFulnessHeroSection />
        <WhatIsMindFulness />
        <MindfulnessMeditation />
        <DifferenceSectionMindful />
        <MindfulnessSignup />
        <MindFulnessBenefits />
        <MindfulnessMeditationTechniques />
        <PracticalMindfulness />
        <MindFulWays />
        <BeginnersGuidedMeditation />
        <FinalThoughts />
        <CustomFooter />
    </div>
  )
}

export default IntroToMindFulNess