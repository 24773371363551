import React, { useContext } from 'react'
import { AuthContext } from '../../context/AllContext';
import JoseSilvaImage from '../../images/Silva Banner copy.webp';
import JoseSilvaSignImage from '../../images/Jose Silva Sign.webp';

function ImageBannerSection() {
    const { isBigScreen } =
        useContext(AuthContext);

    return (
        <div>

            {
                isBigScreen ? (
                    <div className="container my-4">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-10" style={{ position: "relative" }}>
                                <div style={{ width: "700px", height: "550px",backgroundColor:"#673bab" }}>

                                </div>
                                <img style={{ position: "absolute", bottom: "50px", left: "40%",width:"800",height:"450px" }} src={JoseSilvaImage} alt="" />
                                <div style={{ width: "50%", position: "absolute", bottom: "130px", right: "40%", zIndex: "10000" }}>
                                    <p className='white-color' style={{
                                        fontWeight: "500", fontSize: "30px", lineHeight: "40px", paddingLeft: "20px",
                                        paddingTop: "20px", paddingBottom: "20px", borderLeft: "5px solid yellow"
                                    }}>
                                        The key to being extraordinary is knowing what rules to follow and what rules to break.
                                    </p>
                                    <img style={{ width: "200px", height: "80px", marginLeft:'15px'}} src={JoseSilvaSignImage} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (

                    <div className="container my-4">
                        <img src={JoseSilvaImage} alt="" />
                        <div className='bg-dark p-4'>
                            <p className='white-color' style={{
                                fontWeight: "500", fontSize: "20px", lineHeight: "30px", paddingLeft: "20px",
                                paddingTop: "20px", paddingBottom: "20px", borderLeft: "5px solid yellow"
                            }}>
                                The key to being extraordinary is knowing what rules to follow and what rules to break.
                            </p>
                            <img style={{ width: "200px", height: "80px",marginLeft:'15px' }} src={JoseSilvaSignImage} alt="" />

                        </div>
                    </div>

                )
            }


        </div>
    )

}

export default ImageBannerSection