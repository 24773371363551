import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { AuthContext } from '../../../context/AllContext';
import CourseAccodions from '../../CoursePage/CourseAccodions';
import { reqData } from '../../../utils/baseUrl';
import CourseAccodions2 from './CourseAcc2';
import { Spinner } from 'react-bootstrap';

function CourseAccordionSectionCombo({ id, tab }) {
    const [loadMore, setLoadMore] = useState(false);
    const { isMobile } = useContext(AuthContext);

    const [error, setError] = useState(false);
    const [course, setCourse] = useState([]);
    const [lastCourse, setLastCourse] = useState()

    const { userData } = useContext(AuthContext);

    const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);
    const [loading, setLoading] = useState(false);
    const st_id = localStorage.getItem("token") || null


    const getCourses = useCallback(async (udata) => {
        const myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");

        if (st_id) {
            myHeaders.append("studentid", st_id)
        }


        const formData = new FormData();
        formData.append("course_id", id === "silva-love-program" ? "love-program" : id);
        // formData.append("no_of_records", "100");

        const options = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        };

        setLoading(true)
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}courseDetail`,
            options
        );
        setLoading(false)

        const data = await res.json();



        if ((!data || data.error === true)) {
            setError(true);
            return
        }


        setCourse(data.data);


        // const currCourseId = data.data[0].course_id


        // const userenrolledcourses = udata ? udata.enrolled_course.map((val) => val.course_id) : []


        // console.log(data,"datacurrent")

        // if (udata && udata.enrollment_status === "Enrolled" && (userenrolledcourses.includes(currCourseId)
        //     || userenrolledcourses.length === Number(userData.total_course))) {
        //     setIsCurrentCoursePurchased(true)
        // } else {
        //     setIsCurrentCoursePurchased(false)

        // }

        if (tab) {
            setIsCurrentCoursePurchased(tab.course_purchased)

        }



    }, [id, tab])


    useEffect(() => {
        getCourses(userData)
    }, [id, userData, tab])

    const lastViewedCourse = async () => {
        const res = await reqData("getLastVisitedLesson", "POST", {});
        if (res && res.error === false) {
            setLastCourse(res.data)
        }
    }


    useEffect(() => {
        if (isCurrentCoursePurchased) {
            lastViewedCourse()
        }
    }, [isCurrentCoursePurchased])




    if (loading) {
        return <div className='d-flex justify-content-center align-items-center h-100'>
            <Spinner animation="border" color='black' />;
        </div>
    }

    return (
        <div className={isMobile ? 'container' : 'container mx-5'}>
            {
                course && course[0] && !loading && (
                    <CourseAccodions2
                        loadMore={loadMore}
                        lastCourse={lastCourse}
                        data={course?.length > 0 && course[0]}
                        isCurrentCoursePurchased={isCurrentCoursePurchased}
                        margin={false}
                    />
                )
            }
            {
                course && course[0] && course[0]?.chapters.length > 5 && (
                    <button className='primary_btn3' onClick={() => {
                        setLoadMore(!loadMore)
                    }}>
                        {
                            loadMore ? "Load Less" : "Load More"
                        }
                    </button>
                )
            }

        </div>
    )
}

export default CourseAccordionSectionCombo