// import React from 'react'
// import HeadingNew from '../AboutUs/HeadingNew'
// import ParaComp from '../SilvaManifestationProgram/ParaComp'
// import { useMediaQuery } from 'react-responsive';
// import mem1 from "../../images/membership/1 Sleep Better.webp";
// import mem2 from "../../images/membership/2 Boost Energy.webp";
// import mem3 from "../../images/membership/3 Enhance Creativity.webp";
// import mem4 from "../../images/membership/4 Eliminate Stress, Anxiety, Depression.webp";
// import mem5 from "../../images/membership/5 Grow in Career.webp";
// import mem6 from "../../images/membership/6 Improve memory and Focus.webp";
// import mem7 from "../../images/membership/7 Create Healthy Relationships.webp";
// import mem8 from "../../images/membership/8 Achieve Inner Peace.webp";
// import mem9 from "../../images/membership/9 Set and focus on Goals.webp";
// import mem10 from "../../images/membership/10 Boost Self-Esteem.webp";
// import mem11 from "../../images/membership/11 Activate Intuition.webp"
// import Slider from 'react-slick';
// import { GrFormPrevious, GrFormNext } from "react-icons/gr";
// import FullAgenda from './FullAgenda';

// function SeminarHeroSection() {
//     const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1440px)' });
//     const isSmallDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 })
//     const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });


//     const settings = {
//         dots: false,
//         infinite: true,
//         slidesToShow: isDesktopOrLaptop ? 4 : isSmallDesktop ? 3 : isTablet ? 3 : 2,
//         slidesToScroll: isDesktopOrLaptop ? 2 : 1,
//         autoplay: true,
//         speed: 300,
//         autoplaySpeed: 4000,
//         cssEase: "linear",
//         centerMode: false,
//         // centerPadding: isDesktopOrLaptop?'10%':"0%",
//         prevArrow: <GrFormPrevious />,
//         nextArrow: <GrFormNext />,
//     };


//     const imgstyle = { width: "98%", borderRadius: "10px", height: "auto" }
//     return (
//         <div className='container py-5 d-flex justify-content-center align-items-center flex-column'>
//             <div className='mb-3'>
//                 <HeadingNew
//                     small
//                     title={"Experience the life changing journey with"}
//                     white={false}
//                 />
//             </div>
//             <div className='mb-3'>
//                 <HeadingNew
//                     title={"Silva Life & Intuition training"}
//                     white={false}
//                 />

//             </div>

//             <div>
//                 <div className='mb-4'> <ParaComp
//                     center
//                     white={false}
//                     margin={false}
//                     data={[
//                         "Envision rewiring yourself without the high cost of a hypnotherapist. Picture effortlessly overcoming habits like smoking or snacking. What if you could boost your IQ, foster a positive wealth mindset, and tap into your mind's innate healing ability—all through rewiring your subconscious?"
//                     ]}
//                 />

//                 </div>

//             </div>
//             <button className='herodiv-becomememberbtn mb-4'>
//                 Ready to take charge of your Mind?
//             </button>
//             <div className='memberslider container my-4'>
//                 <Slider {...settings}>
//                     <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
//                         <img
//                             src={mem1}
//                             alt=""
//                             style={imgstyle}
//                         />
//                         <img
//                             src={mem2}
//                             alt=""
//                             style={imgstyle}
//                         />
//                     </div>
//                     <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
//                         <img
//                             src={mem3}
//                             alt=""
//                             style={imgstyle}
//                         />
//                         <img
//                             src={mem4}
//                             alt=""
//                             style={imgstyle}
//                         />
//                     </div>
//                     <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
//                         <img
//                             src={mem5}
//                             alt=""
//                             style={imgstyle}
//                         />
//                         <img
//                             src={mem6}
//                             alt=""
//                             style={imgstyle}
//                         />
//                     </div>

//                     <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
//                         <img
//                             src={mem7}
//                             alt=""
//                             style={imgstyle}
//                         />
//                         <img
//                             src={mem8}
//                             alt=""
//                             style={imgstyle}
//                         />
//                     </div>

//                     <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
//                         <img
//                             src={mem9}
//                             alt=""
//                             style={imgstyle}
//                         />
//                         <img
//                             src={mem10}
//                             alt=""
//                             style={imgstyle}
//                         />
//                     </div>

//                     <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
//                         <img
//                             src={mem11}
//                             alt=""
//                             style={imgstyle}
//                         />
//                         <img
//                             src={mem1}
//                             alt=""
//                             style={imgstyle}
//                         />
//                     </div>

//                 </Slider>
//             </div>


//         </div>
//     )
// }

// export default SeminarHeroSection

import React from 'react'
import { useContext } from 'react';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AllContext';
import ParaComp from '../SilvaManifestationProgram/ParaComp';
import HeadingNew from '../AboutUs/HeadingNew';
import FullAgenda from './FullAgenda';

function SeminarHeroSection({ }) {
    const navigate = useNavigate();
    const { isMobile, isTablet, isDesktopOrLaptop, isBigScreen } = useContext(AuthContext);

    const handleShow = () => {
        const paymentsection = document.querySelector(".paymentsection");
        if(paymentsection){
            paymentsection.scrollIntoView({
                behavior:"smooth"
            })
        }
    }
    return (
        <div>
            {
                (!isMobile && !isTablet) && (
                    <div className='bgdivnew'>
                        <div className='leftdiv'>
                            <div className='my-3'>
                            <HeadingNew
                                small
                                start
                                title={"Experience the life changing journey with"}
                                white={true}
                            />
                            </div>
                            <div className='my-3'>
                            <HeadingNew
                                start
                                title={"Silva Life & Intuition training"}
                                white={true}
                            />
                            </div>
                            <div className='my-3'>
                            <ParaComp
                                
                                white={true}
                                margin={false}
                                data={[
                                    "Envision rewiring yourself without the high cost of a hypnotherapist. Picture effortlessly overcoming habits like smoking or snacking. What if you could boost your IQ, foster a positive wealth mindset, and tap into your mind's innate healing ability—all through rewiring your subconscious?"
                                ]}
                            />
                            </div>
                         
                        
                            <button className='herodiv-becomememberbtn my-3' onClick={handleShow}>
                                {/* Claim Free Relaxation */}
                                Ready to take charge of your Mind?
                            </button>
                        </div>
                    </div>
                )
            }

            {
                (isMobile || isTablet) && (
                    <div className='bgdivnew2'>



                        <div className='leftdiv2 container'>
                            <div className='my-3'>
                            <HeadingNew
                                small
                                title={"Experience the life changing journey with"}
                                white={false}
                            />
                            </div>
                            <div className='my-3'>
                            <HeadingNew
                                title={"Silva Life & Intuition training"}
                                white={false}
                            />
                            </div>
                            <div className='my-3'>
                            <ParaComp
                                white={false}
                                margin={false}
                                data={[
                                    "Envision rewiring yourself without the high cost of a hypnotherapist. Picture effortlessly overcoming habits like smoking or snacking. What if you could boost your IQ, foster a positive wealth mindset, and tap into your mind's innate healing ability—all through rewiring your subconscious?"
                                ]}
                            />
                            </div>
                            <div className='my-3'>
                            <button className='herodiv-becomememberbtn mb-2' onClick={handleShow}>
                                Ready to take charge of your Mind?
                            </button>
                            </div>
                          
                           
                           
                         
                        </div>
                    </div>
                )
            }
            <FullAgenda />






        </div>
    )
}

export default SeminarHeroSection