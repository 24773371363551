import React from 'react'
import NewHeader from '../../AdvertisePages/newmembership/NewHeader'
import EmotionalWhySection from './EmotionalWhySection'
import LMHeroSection2 from './LMheroSection2'
import { AuthContext } from '../../../context/AllContext'
import { reqData } from '../../../utils/baseUrl'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import WhatsAlpha from '../HypnosisPage/WhatsAlpha'
import ContactSection from '../../CoursePage/ContactSection'
import EqTestComp from './EqTestComp'
import NewFooter2 from '../FreeCourse/NewFooter2'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import HeroSectionEmo2 from './HeroSectionEmo2'
import TrustedBy2 from './TrustedBy2'
import FeaturedSection from '../SilvaComboCourse/FeaturedSection'
import ComparisonTable2 from '../SilvaComboCourse/ComparisonTable2'
import ComboIncluded from '../SilvaComboCourse/ComboIncluded'
import ComboTestimonials from '../SilvaComboCourse/ComboTestimonials'
import ComboPaymentNew from '../SilvaComboCourse/ComboPaymentNew'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import NewComboBanner from '../ComboCourse/NewComboBanner'


function EmotionalInt() {
  const ref = useRef(null)



  function scrollToPayment() {
    polyfill()

    const doc = document.querySelector(".scrollnewsection")
    setTimeout(() => {

      scrollIntoView(doc, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    }, 300)
  }

  const navigate = useNavigate();

  const [showCard, setShowCard] = useState(false);

  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userData, isMobile } = useContext(AuthContext);

  const getCourseData = async () => {
    setLoading(true);
    const res = await reqData("courseDetail", "POST", {
      course_id: "combo-plan",
    });



    setLoading(false);
    if (res.error === false) {
      setCourseData(res.data);
    }
  };


  useEffect(() => {
    getCourseData();
  }, [userData]);

  return (
    <div>
      <NewHeader />
      {/* <HeroSectionEmo /> */}
      <HeroSectionEmo2 />
      <div style={{
      background:"#fcf7fe"

      }}>

     
      <FeaturedSection />

      <div className='py-4'>
        <TrustedBy2 />
        
      </div>
      <EmotionalWhySection />

      <EqTestComp />

      <LMHeroSection2 white title={'Improve Your Emotional Intelligence with'} colored={"The Silva Method"} textSecData={[
        "The Silva Method, developed by José Silva, focuses on harnessing your mind-body connection to enhance emotional well-being. By accessing alpha and theta brainwave states through relaxation and meditation, you can cultivate positive emotions. Techniques like visualization, positive affirmations, and methods such as the Mirror of the Mind and the Three Fingers Technique help you effectively manage stress and emotions. This approach promotes emotional awareness, healing, and forgiveness, giving you better control over your emotional responses and improving your overall well-being. The Silva Method empowers you to manage and enhance your emotional health through these practices"
      ]} btnText={"Join Now"} />
      {/* <BenifitSectionHyp /> */}
      {/* <ComboIncludes /> */}
      {/* 
      {
        courseData && courseData[0] && (
          <EqPaymentCard data={courseData} showCard={showCard} setShowCard={setShowCard} />
        )
      } */}



      {/* {
        courseData && courseData[0] && (
          <ComboCourseHeroSection data={courseData} showCard={showCard} setShowCard={setShowCard} />
        )
      } */}

      {/* <div className='scrollsection'>
      {
        courseData && courseData[0] &&
        <ComboHero2 ref={ref} data={courseData} isCurrentCoursePurchased={false} alterbackground onClick1={()=>navigate("/store/course/combo-plan")} onClick2={()=>navigate("/store/course/combo-plan")}/>
      }

      </div> */}

      {
        isMobile ? (
          <img src='https://www.silvacasesonline.com/assets/img/Image20240613163449.jpg' style={{
            width: "100%",
            objectFit: "cover",
            cursor:"pointer",

          }}
          onClick={()=>{
            window.open("https://silvamethod.com/7-day-meditation-masterclass")
          }}
          />
        ) : (
          <img src='https://www.silvacasesonline.com/assets/img/Image20240613161212.jpg' style={{
            width: "100%",
            objectFit: "cover",
            cursor:"pointer"
          }}
          onClick={()=>{
            window.open("https://silvamethod.com/7-day-meditation-masterclass")
          }}
          />
        )

      }

      <WhatsAlpha />




      <div className='d-flex justify-content-center align-items-center my-5'>
        <img src='/img/hypnosis/Image20240626173802.png' style={{
          borderRadius: 10
        }} />
      </div>
      <ComparisonTable2 func={scrollToPayment} />

      <ComboIncluded func={scrollToPayment} />

        {/* <NewComboBanner/> */}


      <ComboTestimonials />
      <ComboPaymentNew />




      <ContactSection />
      </div>
      <NewFooter2 />
    </div>
  )
}

export default EmotionalInt