import React, { useMemo } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import HeadingNew from '../../AboutUs/HeadingNew';

function AboutInstructorLM() {

    const { isMobile } = useContext(AuthContext);
   

    return (
        <Container className='py-3'>
            <div className='mb-2'>
                <h6 className='newpageheader text-center'>About Your Mentor</h6>
                <HeadingNew title={"DraganV: Handpicked by Jose Silva"} small/>
            </div>

            <Row className='justify-content-center align-items-center my-5' style={{
                margin: "auto"
            }}>
                <Col xs={12} lg={6} className={isMobile ? 'px-2' : 'px-5'}>
                    <Image
                        className='boxshadow'
                        src='/img/draganteacher.jpg'
                        style={{
                            width: isMobile ? "100%" : "90%",
                            border: "1px solid lightgray",
                            boxShadow: "10px 10px 5px -7px rgba(0,0,0,0.23)",
                            borderRadius: "8px",
                            marginBottom: "15px"
                        }}
                    />
                   

                </Col>
                <Col xs={12} lg={6}>
                 
                <p style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                        color: "black"
                    }}>
                        Dragan Vujovic is a Certified Silva Method Instructor. He stands as a towering figure in the realm of personal development and mental coaching. With an illustrious career spanning 36 years, he has established himself as a legendary figure in the field. He and Jose Silva, the founder of the Silva Method, were close friends, and he received direct training from him. Dragan has conducted 500+ live seminars with 50K+ participants and offers numerous online courses for both public and corporate clients. He has authored 63 products on personal development and mental training, including topics like ‘The Unstress Toolbox', 'Wealth is Your Natural Right', and ‘Self-Change as the Key to Success’, etc. He provides group and personal mental training for sales, management, relationships, personal growth, sports, health, communication, learning, and stress management. DraganV has developed 'Top Mental Training', a unique system for maximizing brain usage. As an expert relationship development and self-esteem mentor, He launched the 'Love Mind', a specialized product aimed at discovering the deepest principles and laws that regulate all of our relationships, fostering an understanding of our connection with the universe, and attaining inner peace.
                    </p>
                </Col>

            </Row>
        </Container>
    )
}

export default AboutInstructorLM