import { Stack } from 'react-bootstrap'
import {FaCheck} from "react-icons/fa"
import HeadingNew from '../AboutUs/HeadingNew'


function WhatYouGet() {
    const styles = {
        fontSize:"1.1rem",
        color:"whitesmoke"
    }
    return (
        <div>
            <HeadingNew
                title={"What you get:"}
                white
                start
                small
            />
            <Stack gap={0} className='mt-3'>
                <div className="p-2 d-flex justify-content-center align-items-center gap-3">
                    <div className="outer-circle">
                        <div className="inner-circle">
                            <FaCheck size={25} color="white" />
                        </div>
                    </div>
                    <div style={{
                        maxWidth:"80%",
                        marginRight:"auto"
                    }}>
                        <p className='white-color' style={styles}>
                           Access to a diverse community of people across the world
                        </p>
                    </div>
                </div>
                <div className="p-2 d-flex justify-content-center align-items-center gap-3">
                    <div className="outer-circle">
                        <div className="inner-circle">
                            <FaCheck size={25} color="white" />
                        </div>
                    </div>
                    <div style={{
                        maxWidth:"80%",
                        marginRight:"auto"
                    }}>
                        <p className='white-color' style={styles}>
                          Opportunity to get your doubts cleared & access to healing sessions.
                        </p>
                    </div>
                </div>
                <div className="p-2 d-flex justify-content-center align-items-center gap-3">
                    <div className="outer-circle">
                        <div className="inner-circle">
                            <FaCheck size={25} color="white" />
                        </div>
                    </div>
                    <div style={{
                        maxWidth:"80%",
                        marginRight:"auto"
                    }}>
                        <p className='white-color' style={styles}>
                          Get engaged with Silva graduates in the weekly graduate meet.
                        </p>
                    </div>
                </div>
                <div className="p-2 d-flex justify-content-center align-items-center gap-3">
                    <div className="outer-circle">
                        <div className="inner-circle">
                            <FaCheck size={25} color="white" />
                        </div>
                    </div>
                    <div style={{
                        maxWidth:"80%",
                        marginRight:"auto"
                    }}>
                        <p className='white-color' style={styles}>
                          Connect with certified Silva Method Instructors.
                        </p>
                    </div>
                </div>
            </Stack>
        </div>
    )
}

export default WhatYouGet