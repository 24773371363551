import React, { useContext } from 'react'
import { Col, Form, Image, Row, Spinner } from 'react-bootstrap'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { BsFillFileEarmarkPersonFill, BsPhoneFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { requestData } from '../../utils/baseUrl';
import LastHeading from '../AboutUs/LastHeading';
import { AuthContext } from '../../context/AllContext';
import { Link } from 'react-router-dom';

function ContactSection() {


    const { isMobile } = useContext(AuthContext)

    const [values, setValues] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const [loading, setLoading] = useState(false);


    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleContact = async () => {
        const { name, email, phone, message } = values;

        // const email2 = "Ashrafm703@gmail.com";
        // const regex = /Ashrafm/;
        
        // if (regex.test(email2)) {
        //     toast.error("Error 401")
        //     return
        // } 

        const matchedEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

        if (!matchedEmail) {
            toast.error("Please enter a valid email");
            return
        }
        if (!name || name.length < 3) {
            toast.error("Please enter a valid name");
            return
        }
        if (!phone || !(/^[0-9]+$/.test(phone)) || phone.length > 13) {
            toast.error("Please enter a valid phone");
            return
        }
        if (!message || message.length < 4) {
            toast.error("Please enter a longer message");
            return
        }
        setLoading(true)
        const res = await requestData("ContactUsForm", "POST", values);
        setLoading(false)

        // console.log(res)

        if (res && res.error === false) {
            toast.success(res.messages)
            setValues({
                name: "",
                email: "",
                phone: "",
                message: ""
            })
        } else {
            toast.error(res.messages)
        }
    }
    return (
        <div className='py-5' style={{ backgroundColor: "#f4f5f9" }}>
            <div className="container">
                <Row className='justify-content-center align-items-center'>
                    {
                        !isMobile && (
                            <Col xs={12} sm={12} md={5}>
                                <Image src={"/img/contactimgnew.png"} fluid />
                            </Col>
                        )
                    }
                    <Col xs={12} sm={12} md={7}>

                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <div style={{ padding: "0 15px 0 15px" }}>


                                <h4 style={{ fontFamily: "Poppins", textAlign: "center" }}>
                                    Still have queries?
                                </h4>
                                <LastHeading boxed boxedtext='Contact Us' white />


                                {
                                    isMobile && (
                                        <Image src={"/img/contactimgnew.png"} fluid />
                                    )
                                }

                                <ParaComp
                                    margin={false}
                                    data={[
                                        <p>Please fill in the form and we will respond as quickly as we can. You can also reach us at
                                            <a href="mailto:digital@silvamethod.com" style={{ color: "#8e44ad", fontWeight: "600" }}> digital@silvamethod.com </a>
                                            {/* or <a href='tel:+1-800-545-6463' style={{ color: "#8e44ad", fontWeight: "600" }}>+1-800-545-6463 (Toll free USA)</a> or <a style={{ color: "#8e44ad", fontWeight: "600" }}
                                                href="tel:+1-956-286-3004">+1-956-286-3004</a> */}
                                                </p>
                                    ]}
                                />
                            </div>


                        </div>

                        <div className='d-flex justify-content-center align-items-center flex-wrap' style={{ width: "95%", marginLeft: "15px" }}>
                            <div class="inputArea inp2">
                                <span>
                                    <BsFillFileEarmarkPersonFill color='#9d9d9d' />
                                </span>
                                <input
                                    onChange={handleChange}
                                    value={values.name}
                                    type="text" name="name"
                                    placeholder="Your Name"
                                />
                            </div>
                            <div class="inputArea inp2">
                                <span>
                                    <AiOutlineMail color='#9d9d9d' />
                                </span>
                                <input
                                    onChange={handleChange}
                                    value={values.email}
                                    type="email" id="1-email" inputmode="email" name="email" class="auth0-lock-input"
                                    placeholder="Your Email" autocomplete="off" autocapitalize="off" aria-label="Email"
                                    aria-invalid="false" />
                            </div>
                            <div class="inputArea inp2">
                                <span>
                                    <BsPhoneFill color='#9d9d9d' />
                                </span>
                                <input
                                    onChange={handleChange}
                                    value={values.phone}
                                    type="text" id="1-email" name="phone" class="auth0-lock-input"
                                    placeholder="Your Phone Number" autocomplete="off" autocapitalize="off" aria-label="Phone"
                                    aria-invalid="false" />
                            </div>

                            <div class="inputArea inp2">

                                <Form.Control as="textarea" placeholder='Write a Message' style={{ border: "none" }}
                                    value={values.message}
                                    name='message'
                                    onChange={handleChange}
                                    rows={5} />
                            </div>

                            <div className="d-flex justify-content-center-align-items-center flex-column">
                                <ParaComp
                                    margin={false}
                                    white={false}
                                    data={[
                                        <p>By submitting you agree to our <Link to="/terms-and-condition" style={{color:"#6f25af",fontWeight:"600"}}>Terms and Conditions </Link>
                                         and <Link to={"/privacy-policy"} style={{color:"#6f25af",fontWeight:"600"}}>Privacy Policy</Link></p>
                                    ]}
                                />

                                <div style={{ margin: "0 auto" }}>
                                    <button className='styledbtn23new' disabled={loading} onClick={handleContact}>
                                        {
                                            loading ? (
                                                <Spinner animation="border" variant="light" />
                                            ) : "Submit"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
           
        </div>
    )
}

export default ContactSection