import React, { useEffect } from 'react'
import NewHeader from '../../AdvertisePages/newmembership/NewHeader'
import NewComboHeroSection from './NewComboHeroSection'
import FeaturedSection from './FeaturedSection'
import "./combonew.css"
import TrustedBy2 from '../EmotionalIntelligence/TrustedBy2'
import BetterSection2 from './BetterSection2'
import EleventhSection from '../../AdvertisePages/newmembership/EleventhSection'
import ComparisonTable2 from './ComparisonTable2'
import ComboIncluded from './ComboIncluded'
import ComboTestimonials from './ComboTestimonials'
import ComboPaymentNew from './ComboPaymentNew'
import SilvaComboFAQ from '../ComboCourse/SilvaComboFAQ'
import HeadingNew from '../../AboutUs/HeadingNew'
import ContactSection from '../../CoursePage/ContactSection'
import ComboFooter from '../ComboCourse/ComboFooter'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import Newsletter from '../../../components/Newsletter/Newsletter'
import BecomeMember from '../../Home/Home/BecomeMember'
import { useLocation } from 'react-router-dom'
import { scrollIntoView } from "seamless-scroll-polyfill";
import { polyfill } from "seamless-scroll-polyfill";
import { Card } from 'react-bootstrap'

function SilvaComboCourse() {

  const location = useLocation();








  function scrollToPayment(){
    polyfill()

    const doc = document.querySelector(".scrollnewsection")
    setTimeout(() => {

      scrollIntoView(doc, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    }, 300)
  }

  return (
    <div style={{
      background: "#F9F9F9"
    }}>
      <NewHeader />
      <NewComboHeroSection func={scrollToPayment} />
      <FeaturedSection />

      <div className='py-4'>
        <TrustedBy2 />
        {/* 
      <div className="livecombo container py-5">
        <LiveCourseSection />

      </div> */}
      </div>
      <BetterSection2 func={scrollToPayment} />
      <div className='container my-5'>
      <Card style={{width:"100%"}} className='fourthcard'>
        <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
            <HeadingNew small title={"Start Your Journey to"} white />
            <HeadingNew title={"Inner Peace Now"} white />
            <button className='outlinebtn' onClick={scrollToPayment}>
                Click Here
            </button>
        </Card.Body>
    </Card>
      </div>
      {/* <Top10Benefits2 /> */}
      <div className='py-5'>
        <EleventhSection scrollToPayment={scrollToPayment} />

      </div>
      <ComparisonTable2 func={scrollToPayment} />

      <ComboIncluded func={scrollToPayment} />


     

      <ComboTestimonials />

      <ComboPaymentNew />
      <div className="container mb-5">
        <HeadingNew title={"Frequently Asked Questions About Silva Method Combo Course"} small start />
        <SilvaComboFAQ />
      </div>
      <BecomeMember link linkfunc={scrollToPayment} />
      <Newsletter />
      <ContactSection />

      <CustomFooter />
    </div>
  )
}

export default SilvaComboCourse