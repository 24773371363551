import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { reqData } from '../../utils/baseUrl';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import CustomFooter from '../Home/Home/HomeFooter/Footer';

function Participant() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();




    useEffect(() => {
        const token = searchParams.get("token");
        const type = searchParams.get("type");
        const uid = searchParams.get("uid");

        // if(token,type,uid){
        //     setParams({
        //         token,type,uid
        //     })
        // }
        console.log(token,type,uid,"uiduid")

        if (token || type || uid) {
            handleSubmit(token, type, uid)
        }

    }, [searchParams])


    const handleSubmit = async (token, type, uid) => {


        if (!type || !token || !uid) {
            toast.error("Token is missing");
            return
        }

        try {
            const res = await reqData("confirmParticipation", "POST", {
                token,
                type,
                uid
            })

            if (res && res.status === 200) {
                // console.log(res)
                Swal.fire({
                    title: "Success!",
                    text: res.messages,
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/")
                    }
                });
            } else {
                toast.error(res.messages)

            }

        } catch (err) {
            console.log(err)
            toast.error("Something went wrong please try again later")
        }
    }




    return (
        <>
            <HomeHeader />
            <div className='d-flex justify-content-center align-items-center' style={{
                height: "400px"
            }}>
                {/* <Spinner animation="grow" variant="dark" /> */}

            </div>
            <CustomFooter />
        </>
    )
}

export default Participant