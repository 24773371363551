import React from 'react'
import DifferenceCards from './DifferenceCards'
import img1 from "../../images/1.webp"
import img2 from "../../images/2.webp"
import img3 from "../../images/3.webp"
import CustomerReviews from './CustomerReviews'
import HeadingNew from '../../pages/AboutUs/HeadingNew'

function DifferenceSection() {

  return (
   <div className="container">
     <div className="container text-center">
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-8'>
          {/* <h2>Life-Changing Impact: The Silva Method’s Revolutionary Practices</h2> */}
          <HeadingNew title={"Life-Changing Impact: The Silva Method’s Revolutionary Practices"} />
          {/* <h4 className='mb-4'>5X Better Completion Rates than Traditional Online Learning*</h4> */}
          <p className='aboutpara mt-4'>
          As Silva Method continues to touch and transform over 12 million lives, one captivating narrative emerges from a pharmacy college.
          Here, a professor imparted The Silva Method techniques to his students, resulting in an extraordinary upswing in their academic performance.
          Surprisingly, they achieved higher grades across all courses with minimum effort, accompanied by a heightened sense of relaxation. 
          </p>
          <div className="blockquote-wrapper">
                <div className="blockquote">
                    <h1 style={{color:"black"}}>
                      “Everybody already knows how to use his or her imagination. I just get my students to practice it more.
                      I show them that imagination is valid and that there’s a form of reality in imagination that they can use.”
                    </h1>

                </div>
            </div>
        </div>

        {/* <h2>Master Silva Method Courses with No Limitations</h2> */}
        <HeadingNew title={"Master Silva Method Courses with No Limitations"} />
        <div className="row mt-3 mb-4">
          <div className="col-sm-12 col-md-6 col-lg-4">
          <DifferenceCards img={img1} title={"One Membership- Multiple Courses"}
            text={"Our scientifically-backed tools and technology make complicated topics easy to understand and integrate for results."}
          />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
          <DifferenceCards img={img2} title={"Instructor-led learning- Live Classes"}
            text={"Learn at your own pace with certified Silva method instructors. Dive into Live classes for direct knowledge transfer."}
          />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
          <DifferenceCards img={img3} title={"One-to-One Sessions"}
            text={"Get a personalized learning experience with Silva Method seminars across UK and US,Fostering a multicultural learning environment."}
          />
          </div>
        </div>
        
      </div>
    </div>
   </div>
  )
}

export default DifferenceSection