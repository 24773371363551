import React from 'react'
import NewHeader from '../AdvertisePages/newmembership/NewHeader'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import BookingForm1 from './BookingForm1.js'
import TrustedBy from '../Draganpages/TrustedBy.js'
import SliderSection from './SliderSection.js'
import FifthSlider from '../AdvertisePages/newmembership/FifthSlider.js'
import SixthSlider from '../AdvertisePages/newmembership/SixthSlider.js'

function AppointMent() {
  return (
    <div>
        <NewHeader />
        <BookingForm1 />
        <TrustedBy />

        <SixthSlider />
       
        <CustomFooter />
    </div>
  )
}

export default AppointMent