// import React, { useContext } from 'react'
// import "./pricing.css";
// import { TiTick } from "react-icons/ti";
// import { requestData } from '../../utils/baseUrl';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { Tooltip } from 'react-tooltip'
// import { AuthContext } from '../../context/AllContext';
// import { useNavigate } from 'react-router';
// import { toast } from 'react-hot-toast';
// import ModalPricing from './ModalPricing';
// import { Card } from 'react-bootstrap';
// import moment from 'moment';
// import HeadingNew from '../AboutUs/HeadingNew';
// import { GiPadlock } from "react-icons/gi"
// import Featured from '../../components/featured/Featured';
// function MemberShippricingPlan2({ white = false,alloff=false }) {
  
//     const [coupon2, setCoupon2] = useState("")
//     const [appliedCoupon, setAppliedCoupon] = useState("")
//     const [modalIsOpen, setIsOpen] = useState(false);
//     const [selectedPlan, setSelectedPlan] = useState('');
//     const [priceplan1, setPricePlan1] = useState("");
//     const [priceplan2, setPricePlan2] = useState("");



//     const { isBigScreen, isMobile, isTab, isLaptop } = useContext(AuthContext);

//     function openModal() {
//         setIsOpen(true);
//     }

//     function closeModal() {
//         setIsOpen(false);
//     }
//     const { userData } = useContext(AuthContext)
//     const [plans, setPlans] = useState([]);
//     const [reversePlans, setReversePlans] = useState([]);

//     const [loading, setLoading] = useState({
//         state: false,
//         id: null
//     });

//     const navigate = useNavigate();
//     const fetchPlans = async (coupondata) => {
//         const res = await requestData("subscriptionPlanList", "POST", {});
//         if (res && res.error === false) {
//             setPlans(res.data)
//             setReversePlans([...res.data].reverse())

//             // console.log("responsenew", res.data)
//             if (res.data.length > 0) {
               
//                 const len = res.data.length
//                 res.data.forEach((val, i) => {

//                     if (val.cycle === "year") {
//                         setPricePlan2(val.price)
//                     } else if (val.cycle === "month") {
//                         setPricePlan1(val.price)
//                     }
                
//                 })

//             }
//         }
//     }




//     useEffect(() => {
//         fetchPlans()

//         return () => {
//             setCoupon2("")
//             setAppliedCoupon("")
//         }
//     }, [])


   

//     const handleSubscription = async (id) => {
//         if (userData && !userData.email) {
//             navigate("/store/profile/:username/settings/basic-information", { state: id })
//             return
//         }
     

//         setSelectedPlan(id);
//         openModal()
//         setLoading(false)

//     }




//     const handleSaveCoupon2 = async (val) => {

        

//         const res = await requestData("applyCouponCode", "POST", {
//             coupon_code: coupon2
//         })

//         if (res && res.error === false) {
//             const validCoupon = moment(new Date(Date.now())).isBetween(res.data.start_date, res.data.end_date)
//             const priceplan = Number(val.price).toFixed(2)
//             if (validCoupon) {
              
//                 const newdis = ((priceplan - (priceplan * (Number(res.data.discount_percentage) / 100))))

//                 if (newdis == priceplan2) {
//                     toast.error("Coupon already applied", {
//                         position: "top-center",

//                     });
//                     return
//                 }
//                 setPricePlan2(newdis)
//                 setAppliedCoupon(coupon2)


//                 toast.success("coupon applied", {
//                     position: "top-center",


//                 })
//             } else {
//                 toast.error("Coupon is not valid !!", {
//                     position: "top-center",


//                 })
//             }
//         } else {
//             toast.error(res.messages, {
//                 position: "top-center",
//             })
//         }
//     }





//     if ((userData && userData.enrollment_status === "Enrolled")) {
//         return null
//     }

//     return (
//         <>

//             {
//                 alloff===false && (
//                     <div className="container" style={{ marginTop: isMobile ? "20px" : "80px", zIndex: 100000 }}>
//                 <div className="row justify-content-center p-3">
//                     <div className="col-sm-12 col-md-8">
//                         <HeadingNew title={"What People Worldwide Gained from"} colored={"Silva Method Membership"} white={white} />
//                         <img style={{ objectFit: "cover", marginBottom: "20px" }} src={require("../../images/mainimgs/Last_Image.webp")}
//                             alt="" />
//                         <div>
//                             <h3 className='text-start mb-4' style={{ color: white ? "white" : "black" }}>Begin the Change: Become a Silva Member
//                                 Discover the Perks:
//                             </h3>
//                             <div className='d-flex mb-2'>
//                                 <span><TiTick size={40} color='#6f25af' /></span>
//                                 <p style={{ color: white ? "white" : "black", fontSize: "1.1rem" }}>
//                                     Unlock the Entire Curriculum of online meditation classes for Personal Growth
//                                 </p>
//                             </div>

//                             <div className='d-flex mb-2'>
//                                 <span><TiTick size={40} color='#6f25af' /></span>
//                                 <p style={{ color: white ? "white" : "black", fontSize: "1.1rem" }}>
//                                     Over 50 Silva Method Audiobooks at Your Fingertips. More coming soon.
//                                 </p>
//                             </div>
//                             <div className='d-flex mb-2'>
//                                 <span><TiTick size={40} color='#6f25af' /></span>
//                                 <p style={{ color: white ? "white" : "black", fontSize: "1.1rem" }}>
//                                     Learn and apply Silva mind control wherever and whenever you want
//                                 </p>
//                             </div>
//                             <div className='d-flex mb-2 pricecardsss'>
//                                 <span><TiTick size={40} color='#6f25af' /></span>
//                                 <p style={{ color: white ? "white" : "black", fontSize: "1.1rem" }}>
//                                     Connect, Share and Grow with the Silva Method Global Community
//                                 </p>
//                             </div>

//                             <div className='d-flex mb-2'>
//                                 <span><TiTick size={40} color='#6f25af' /></span>
//                                 <p style={{ color: white ? "white" : "black", fontSize: "1.1rem" }}>
//                                     Access mind control and guided meditation techniques for profound inner transformation
//                                 </p>
//                             </div>

//                             <div className='d-flex mb-2'>
//                                 <span><TiTick size={40} color='#6f25af' /></span>
//                                 <p className='pricingplan' style={{ color: white ? "white" : "black", fontSize: "1.1rem" }}>
//                                     Learn from multiple meditation and mind control training courses including Silva Life System (SLS) & Silva Intuition System (SIS) audios
//                                 </p>
//                             </div>
//                             <div className='d-flex'>
//                                 <span><TiTick size={40} color='#6f25af' /></span>
//                                 <p style={{ color: white ? "white" : "black", fontSize: "1.1rem" }}>
//                                     Round-the-clock support to our students and Silva Method members
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//                 )
//             }

//             {
//                 alloff===false && (
//                     <Featured />
//                 )
//             }

//             <ModalPricing
//                 modalIsOpen={modalIsOpen}
//                 openModal={openModal}
//                 closeModal={closeModal}
//                 selectedPlan={selectedPlan}
//                 priceplan1={priceplan1}
//                 priceplan2={priceplan2}
//                 plans={plans}
//                 cp={appliedCoupon}
//                 setAppliedCoupon={setAppliedCoupon}
//             />

//             <Tooltip id="my-tooltip" />
//             <Tooltip id="my-tooltip2" />
//             <Tooltip id="my-tooltip3" />


//             <>
//                 <div className="newpricingplan container">


                    
//                     <div className='cardmembership mb-5 memberbtn'>

//                         {
//                             (isMobile || isTab) && reversePlans && reversePlans.map((val, i) => {
//                                 return (
//                                     <Card style={{
//                                         height: "auto", width: "fit-content", borderRadius: "15px",
//                                         border: val.cycle === "year" ? "2px solid #6f25af" : "2px solid whitesmoke"
//                                     }}>


//                                         <Card.Body className='d-flex justify-content-center align-items-center'
//                                             style={{
//                                                 width: "100%", padding: val.cycle === "month" ? "30px 40px" : "0",

//                                             }}>

//                                             {
//                                                 val.cycle === "month" && (
//                                                     <div style={{ width: "100%" }}>
//                                                         <h3 className='text-center newcardheading'>Monthly Membership</h3>
//                                                         <hr style={{ width: "100%", color: "#595e67", height: "3px" }} />

//                                                         <div className='d-flex justify-content-center align-items-center gap-2'>
//                                                             <h2 style={{ color: "#595e67" }} className='pricingtitle'>${val.price}</h2>
//                                                             <span style={{ color: "#595e67" }} className='pricingsubtitle'>/month</span>
//                                                         </div>
//                                                         <p className='pricingpara'>Billed monthly , cancel anytime.</p>

//                                                     </div>
//                                                 )
//                                             }

//                                             {
//                                                 val.cycle === "year" && (
//                                                     <div style={{ maxWidth: "100%" }}>
//                                                         <div className='yearlydiv'>
//                                                             <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
//                                                                 <h5 className='text-center white-color'>BEST VALUE</h5>
//                                                                 <h3 className='white-color newcardheading'>Yearly Membership</h3>
//                                                             </div>

//                                                         </div>

//                                                         <div style={{ padding: "60px 80px 30px 80px" }}>

//                                                             <div>
//                                                                 <div className='d-flex justify-content-center align-items-center flex-column'
//                                                                 >
//                                                                     <div className='d-flex justify-content-center align-items-center gap-2'>
//                                                                         <h2 style={{ color: "#595e67" }} className='pricingtitle'>${priceplan2}</h2>
//                                                                         <span style={{ color: "#595e67" }} className='pricingsubtitle'>/year</span>
//                                                                     </div>
//                                                                     <div style={{width:"100%"}}>
//                                                                     <p className='pricingpara'>
//                                                                         That's just $0.6/Day, cancel anytime.
//                                                                     </p>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>

//                                                             <div className='d-flex justify-content-center align-items-center'>
//                                                                 <div style={{ position: "relative" }} >
//                                                                     <input type="text" placeholder='Type Coupon' className='inputpricing'
//                                                                         value={coupon2}
//                                                                         onChange={(e) => setCoupon2(e.target.value)}
//                                                                     />
//                                                                     <button className='styledbtn4' onClick={() => handleSaveCoupon2(val)}>
//                                                                         Apply
//                                                                     </button>
//                                                                 </div>

//                                                             </div>
//                                                         </div>

//                                                     </div>
//                                                 )
//                                             }

//                                         </Card.Body>
//                                         <Card.Footer style={{ width: "100%" }}>
//                                             <div className='joinmemdiv'>
//                                                 {
//                                                     val.cycle == "month" ? (
//                                                         <button className='newmembtn' onClick={() => handleSubscription(val.id, i)}>Enroll Now</button>
//                                                     ) : (
//                                                         <button className='newmembtn2' onClick={() => handleSubscription(val.id, i)}>Enroll Now</button>

//                                                     )
//                                                 }
//                                             </div>
//                                         </Card.Footer>





//                                     </Card>
//                                 )
//                             })
//                         }
//                         {
//                             (!isMobile && !isTab) && plans && plans.map((val, i) => {
//                                 return (
//                                     <Card style={{
//                                         height: "auto", width: "fit-content", borderRadius: "15px",
//                                         border: val.cycle === "year" ? "2px solid #6f25af" : "2px solid whitesmoke"
//                                     }}>


//                                         <Card.Body className='d-flex justify-content-center align-items-center'
//                                             style={{
//                                                 width: "100%", padding: val.cycle === "month" ? "30px 30px" : "0",

//                                             }}>

//                                             {
//                                                 val.cycle === "month" && (
//                                                     <div style={{ width: "100%" }}>
//                                                         <h3 className='text-center newcardheading'>Monthly Membership</h3>
//                                                         <hr style={{ width: "100%", color: "#595e67", height: "3px" }} />

//                                                         <div className='d-flex justify-content-center align-items-center gap-2'>
//                                                             <h2 style={{ color: "#595e67" }} className='pricingtitle'>${val.price}</h2>
//                                                             <span style={{ color: "#595e67" }} className='pricingsubtitle'>/month</span>
//                                                         </div>
//                                                         <p className='pricingpara'>Billed monthly , cancel anytime.</p>

//                                                     </div>
//                                                 )
//                                             }

//                                             {
//                                                 val.cycle === "year" && (
//                                                     <div style={{ maxWidth: "100%" }}>
//                                                         <div className='yearlydiv'>
//                                                             <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
//                                                                 <h5 className='text-center white-color'>BEST VALUE</h5>
//                                                                 <h3 className='white-color newcardheading'>Yearly Membership</h3>
//                                                             </div>

//                                                         </div>

//                                                         <div style={{ padding: "60px 70px 30px 70px" }}>

//                                                             <div>
//                                                                 <div className='d-flex justify-content-center align-items-center flex-column'
//                                                                 >
//                                                                     <div className='d-flex justify-content-center align-items-center gap-2'>
//                                                                         <h2 style={{ color: "#595e67" }} className='pricingtitle'>${priceplan2}</h2>
//                                                                         <span style={{ color: "#595e67" }} className='pricingsubtitle'>/year</span>
//                                                                     </div>
//                                                                     <p className='pricingpara'>
//                                                                         That's just $20/Month, cancel anytime.
//                                                                     </p>
//                                                                 </div>
//                                                             </div>

//                                                             <div className='d-flex justify-content-center align-items-center'>
//                                                                 <div style={{ position: "relative" }} >
//                                                                     <input type="text" placeholder='Type Coupon' className='inputpricing'
//                                                                         value={coupon2}
//                                                                         onChange={(e) => setCoupon2(e.target.value)}
//                                                                     />
//                                                                     <button className='styledbtn4' onClick={() => handleSaveCoupon2(val)}>
//                                                                         Apply
//                                                                     </button>
//                                                                 </div>

//                                                             </div>
//                                                         </div>

//                                                     </div>
//                                                 )
//                                             }

//                                         </Card.Body>
//                                         <Card.Footer style={{ width: "100%" }}>
//                                             <div className='joinmemdiv'>
//                                                 {
//                                                     val.cycle == "month" ? (
//                                                         <button className='newmembtn' onClick={() => handleSubscription(val.id, i)}>Enroll Now</button>
//                                                     ) : (
//                                                         <button className='newmembtn2' onClick={() => handleSubscription(val.id, i)}>Enroll Now</button>

//                                                     )
//                                                 }
//                                             </div>
//                                         </Card.Footer>





//                                     </Card>
//                                 )
//                             })
//                         }





//                     </div>
//                     <div className='d-flex justify-content-center align-items-center flex-column'>
//                         <img src={require("../../images/logos-cc-disclaimer.png")} />
//                         <p className='my-2 text-center' style={{ color: white ? "white" : "inherit" }}>
//                             *For any queries, mail our support team at <a
//                                 style={{ textDecoration: "none", color: "inherit" }}
//                                 href="mailto:digital@silvamethod.com">digital@silvamethod.com</a>.
//                         </p>
//                         <div className='d-flex justify-content-center align-items-center gap-2' style={{ height: "100%" }}>
//                             <span>
//                                 <GiPadlock size={30} color={white ? "white" : "black"} />
//                             </span>
//                             <p className='mt-3 text-center' style={{ color: white ? "white" : "inherit" }}>
//                                 For your security, all orders are processed on a secured server.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </>
//         </>
//     )
// }

// export default MemberShippricingPlan2

import React from 'react'
import FourthHeroSection from '../Home/Home/Homestudycourses/FourthHeroSection'

function MemberShippricingPlan2() {

  return <FourthHeroSection />
}

export default MemberShippricingPlan2