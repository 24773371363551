import React from 'react'
import { Card, Col, ProgressBar, Row } from 'react-bootstrap'
import ParaComp from '../SilvaManifestationProgram/ParaComp'

function BenefitsGrid({data,classname}) {
    return (
        <Row className='mt-5 gy-3 benefitsection'>
            {
                data.map((val, i) => (
                    <Col xs={12} md={6} key={i}>
                        <Card style={{ borderRadius: "6px", width: "97%" }} className={classname || 'cardheightnew'}>
                            <Card.Header style={{ backgroundColor: "transparent", border: "none", padding: "0" }}>
                                <ProgressBar now={val.now} variant={val.class} style={{ borderRadius: "0px", borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }} />
                            </Card.Header>
                            <Card.Body>
                                <div className='d-flex justify-content-center align-items-start flex-column flex-wrap'>
                                    <div className='d-flex justify-content-start align-items-center flex-wrap gap-3'>
                                        <div className={'roundedboi' + ' ' + val.class}>
                                            <span className='mt-1'>
                                                {i + 1}
                                            </span>
                                        </div>
                                        <div>
                                            <h4 style={{ color: "#000", fontSize: "1.1rem" }}>
                                                {val.title}
                                            </h4>
                                        </div>

                                    </div>
                                    <div className='mt-3'>
                                        <ParaComp
                                            data={[
                                                val.para
                                            ]}
                                            white={false}
                                            margin={false}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))
            }
        </Row>
    )
}

export default BenefitsGrid