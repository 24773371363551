import React, { useState } from "react";
import { useEffect } from "react";
import { baseUrl, requestData, requestData2 } from "../../utils/baseUrl";
import { useContext } from "react";
import { AuthContext } from "../../context/AllContext";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";

function SubscriptionSuccess() {
  const { setUserData, isUserLoggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const paramsdata = location.search.split("&");

    // console.log("paramsdata", paramsdata)

    // console.log(atob(paramsdata[0].substring(3)))
    // console.log(atob(paramsdata[1].substring(2)))

    // console.log(atob(paramsdata[0].substring(3)), atob(paramsdata[1].substring(2)),atob(paramsdata[2].substring(3)))

    // return

    guestSuccessStripe(
      atob(paramsdata[0].substring(3)),
      atob(paramsdata[1].substring(2)),
      atob(paramsdata[2].substring(3))
    );

    // console.log(userData,"userData")
    // const token = reactLocalStorage.get("token");
    // console.log(token,"token",isUserLoggedIn)
    // if (userData) {
    //     alert("without student id")
    //     successStripe()

    // } else {
    //     alert("with student id")
    // const data = location.search.substring(1).split("=")
    //     const decoded = userData.student_id
    //     guestSuccessStripe(decoded)
    // }
  }, [isUserLoggedIn]);

  const successStripe = async () => {
    setLoading(true);
    const res = await requestData("subscriptionSuccess", "POST", {});
    if (res && res.error === false) {
      const res = await requestData2("getStudentProfile", "POST", {});
      if (res && res.error === false) {
        setUserData({
          ...res.data,
          total_course: res.total_course,
        });
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Payment Successful",
          showConfirmButton: true,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/today");
          }
        });
      } else {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Payment Successful",
          showConfirmButton: true,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/today");
          }
        });
      }
      setLoading(false);
    } else {
      toast.error(res.messages);
    }
  };

  const guestSuccessStripe = async (order_id, st_id, payment_type) => {
    // setNoCalled(noCalled+1)
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    myHeaders.append("studentid", st_id);
    const formData = new FormData();

    formData.append("order_id", order_id);
    formData.append("pg", payment_type);

    const options = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };

    setLoading(true);

    const res = await fetch(`${baseUrl}subscriptionSuccess`, options);
    const data = await res.json();
    // const data = await requestData("subscriptionSuccess","POST",{
    //     order_id
    // })
    setLoading(false);
    // console.log(data,"Dataaaa")
    if (data && data.error === false) {
      // localStorage.removeItem("token")
      // setUserData(null)

      getProfile();
      Swal.fire({
        position: "top-center",
        icon: "success",
        html:
          "<b>Payment Successful</b>" +
          "<br>" +
          "<p style='font-size:15px'>You will receive one email from digital@silvamethod.com with the temporary password and login link on your registered email ID. You can check your SPAM folder if it's not there in the Inbox.</p>" +
          "<br>" +
          'For any assistance write us at <a href="mailto:digital@silvamethod.com">digital@silvamethod.com</a> ',
        showConfirmButton: true,
        allowEnterKey: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // if(userData){
          //     navigate("/today")
          // }else{
          //     navigate("/")
          // }
          // if (isUserLoggedIn) {
          //     navigate("/today")
          // } else {
          //     navigate("/")
          // }
          navigate("/thank-you2");
        }
      });
    } else {
      toast.error(data.messages);
    }
  };

  const getProfile = async () => {
    const res = await requestData2("getStudentProfile", "POST", {});
    if (res && res.error === false) {
      // setUserData(res.data);
      setUserData({
        ...res.data,
        total_course: res.total_course,
      });
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100%", height: "100vh" }}
    >
      <HomeHeader />
      {loading && <RingLoader color="#2c3e50" size={130} />}
    </div>
  );
}

export default SubscriptionSuccess;
