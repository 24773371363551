import React, { useState } from 'react'
import "./login2.css";
import { Card, Nav } from 'react-bootstrap';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import { useLocation } from 'react-router-dom';
function LoginArea2() {
    const location = useLocation()
    const [activeTab,setActiveTab] = useState(location.state || "link-1")
    return (
        <div className='d-flex justify-content-center align-items-center pb-4 loginarea'>
            <Card style={{ borderRadius:"10px" }} className='formSection'>
                <Card.Body className='d-flex justify-content-center align-items-center flex-column text-center'>
                    <Nav variant="tabs" activeKey={activeTab}>
                        <Nav.Item onClick={()=>setActiveTab("link-1")}>
                            <Nav.Link eventKey={"link-1"}>Login</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={()=>setActiveTab("link-2")}>
                            <Nav.Link eventKey="link-2">Register</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    {
                        activeTab==="link-1"?(
                            <LoginForm activeTab={activeTab} />
                        ):(
                            <RegisterForm setActiveTab={setActiveTab} activeTab={activeTab} />
                        )

                    }
                </Card.Body>
            </Card>
        </div >
    )
}

export default LoginArea2



