import React, { useContext } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap'
import Badge from './Badge'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { AuthContext } from '../../../context/AllContext'

function LevelofMind() {

    const {isMobile, isTablet,isDesktopOrLaptop,isBigScreen,}= useContext(AuthContext);

  return (
    <div style={{backgroundImage:(isTablet|| isMobile)?`url('/img/bg/img-for-mobile-3.webp')`:`url('/img/bg/img-31.webp')`,backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPositionY:isMobile?"50%":isTablet?"65%":"",backgroundPositionX:isDesktopOrLaptop?"60%": isMobile?"35%":""}}>
        <div className="container d-flex flex-column justify-content-center align-items-center">
            <div className='mt-5 d-flex flex-column justify-content-center align-items-center'>
            <h5 className='text-black'>What are the different</h5>
            <HeadingNew title={"Levels of"} colored={" Mind?"}/>
            </div>
            <div>
                <Row>
                    <Col sm={12} md={12} lg={6} className='d-flex flex-column justify-content-center align-items-start' style={{padding:(isMobile || isTablet)?"50px 0px 600px 0px":isDesktopOrLaptop?"200px 0px":"300px 0px"}}>
                    <div className='px-3 d-flex flex-column justify-content-center align-items-start'>
                    <Badge text={"Beta"} backgroundColor="linear-gradient(90deg, #3520e5, #d801fb 50%)" color="#fff"/>
                    <div className='my-3'>
                    <HeadingNew title={"State of Mind"}/>
                    </div>
                    <ParaComp
                        data={[
                            "is an active and busy state of alertness. Beta brain waves are produced when you are highly alert i.e., when you are engaged in any kind of activity. Comparatively, beta waves are slower than the Alpha and Theta waves. Through Jose Silva mind control method, you can learn how to use these beta waves to improve your memory and other cognitive functions."
                        ]}
                        white={false}
                        fw={"500"}
                    />
                    </div>
                    </Col>
                    {/* <Col sm={12} md={12} lg={6} className='d-flex flex-column justify-content-center align-items-center'>
                        <img src="/img/contactimgnew.png" alt="" />
                    </Col> */}
                </Row>
            </div>
        </div>
    </div>
  )
}

export default LevelofMind