import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Image } from 'react-bootstrap'
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import ResultAccordion from '../../SilvaManifestationProgram/ResultAccordion';
import CustomCourseAccordion2 from './ResultAccordion2';
import ComboAccordion from './ComboAccordion';
import { Col, Row } from 'react-bootstrap'



function AllCourseAccordions2({ data, isCurrentCoursePurchased, lastcorused }) {

    const [isOpen, setIsOpen] = useState(false);
    // console.log(data, "Data new")
    const [searchResult, setSearchResult] = useState();
    const [allLessons, setAllLessons] = useState([])
    const { isMobile, isDesktopOrLaptop, isBigScreen } = useContext(AuthContext)
    const [searchQuery, setSearchQuery] = useState("")
    useEffect(() => {
        if (data) {
            let chapters = data?.chapters?.map((chap) => chap.lession.map((les) => les))
            let lessons = []
            chapters?.forEach((cha) => {
                lessons.push(...cha)
            })

            setAllLessons(lessons)

        }

    }, [data])

    const handleChangeSearch = useCallback(() => {
        if (searchQuery) {
            const regex = new RegExp(searchQuery, 'gi'); // 'i' flag for case-insensitive search
            const res = allLessons.filter(lesson => regex.test(lesson.lesson_title))
            setSearchResult(res)
        } else {
            setSearchResult()
        }
    }, [searchQuery])



    useEffect(() => {
        handleChangeSearch()
    }, [handleChangeSearch])


    // console.log(data.chapters, "data.chapters")



    return (
        <div className='container mt-5 coursesinglenew scrolldivnew' style={{
            width:
                ((isDesktopOrLaptop || isBigScreen) && !isCurrentCoursePurchased) ? "95%" : (isCurrentCoursePurchased === false && !isMobile) ? "100%" : "100%"
        }}>

            <div className='d-flex justify-content-between align-items-center mb-3' style={{
                marginLeft: 20
            }}>
                <HeadingNew colored={[
                    data?.course_title
                ]}
                    small
                />
            </div>

            {
                searchResult && searchResult.length === 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Image src={require("../../../images/noresults.jpg")} width={100} height={100} />
                        <HeadingNew title={`Sorry no results found for ${searchQuery} ☹️`} small />

                    </div>
                )
            }


            <Row className='container justify-content-center align-items-center gap-4' style={{
                margin: "0 auto",
                paddingLeft:10,
                paddingRight:10
            }}>

                {
                    data.chapters.map((chapter, i) => (
                        <Col xs={12}>
                            <ComboAccordion newdata={data} lastchapter={data.chapters.length - 1} lastcorused={lastcorused} allcourse key={i} data={chapter} index={i} isCurrentCoursePurchased={isCurrentCoursePurchased} />

                        </Col>
                    ))
                }

            </Row>




            {/* {data && Boolean(searchResult) === false &&
                data.chapters.map((chapter, i) => <CustomCourseAccordion2 newdata={data} lastchapter={data.chapters.length - 1} lastcorused={lastcorused} allcourse key={i} data={chapter} index={i} isCurrentCoursePurchased={isCurrentCoursePurchased} />)} */}
        </div>
    )
}

export default AllCourseAccordions2