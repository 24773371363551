import React from "react";
import { Alert, Container } from "react-bootstrap";

function CallNowCtr() {
  return (
    <Container className="cardbdy2 d-flex justify-content-center align-items-center py-3">
      <p className="text-white m-0 text-center fw-bold h5">Clear Up Confusion—Book a Free Consultation Today!</p>
    </Container>
  );
}

export default CallNowCtr;
