import { AuthContext } from '../../context/AllContext';
import React, { useContext } from 'react'
import { Card } from 'react-bootstrap'
import { HiKey } from "react-icons/hi";
import HeadingNew from '../AboutUs/HeadingNew';

function OutComeCard({title,data,icon,icon2}) {
     const {isMobile} = useContext(AuthContext)
    return (
        <Card style={{
            background: "rgb(6,2,7)",
            background: "rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)",
            padding:"5px 10px",
            borderRadius:"10px",
            border:"1px solid #636e72",
            width:"95%",
            margin:isMobile?"auto":"0"
            
        }}>
            <Card.Body className='d-flex justify-content-start flex-column gap-3'
            style={{
                position:"relative"
            }}
            >
                <span>
                   {
                    icon ? icon : <HiKey size={30} color='white' />
                   }
                </span>
                <HeadingNew
                    small
                    start
                    white
                    title={title}
                />
                <div style={{
                    paddingLeft:"15px",
                    color:"white"
                }}>

                <ul>
                    {/* <li>Instantly actionable steps that can help you lift your conversions as you go.</li>
                    <li>Instantly actionable steps that can help you lift your conversions as you go.</li> */}

                    {
                        data.map((val,i)=>{
                            return (
                                <li key={i} style={{
                                    color:"#dfe6e9"
                                }}
                                className='fontnine'
                                >
                                    {val}
                                </li>
                            )
                        })
                    }

                </ul>
                </div>
                <span style={{
                    position:"absolute",
                    bottom:"0",
                    right:"0",
                    opacity:"0.2"
                }}>
                    {
                        icon2 ? icon2:<HiKey size={150} color='white' />
                    }
                </span>


            </Card.Body>

        </Card>
    )
}

export default OutComeCard