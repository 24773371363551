import moment from "moment";
import React from "react";
import { useState } from "react";
import Countdown from "react-countdown";

function CountDownTimer({ date,setTargetDate,key,setKey}) {


  const updateDate=()=>{
    const originalDate = new Date(date);
    const originalDate2=originalDate.setDate(originalDate.getDate() + 7);
    // console.log(originalDate2,'org2')
    const updateDate= moment(originalDate2).format();
    // console.log(updateDate,'updateDate')
    // const updatedDateString = originalDate.toISOString().slice(0, 19);
    setTargetDate(updateDate);
    setKey(key+1);
  }


  
  const renderer = ({days, hours, minutes, seconds,}) => {

    return (
      <div className="d-flex justify-content-center align-items-center gap-3">
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <h5 className="text-white">{days < 10 ? '0' + days : days}</h5>
          <h6 className='text-center text-white' style={{ fontSize: '12px' }}>
            Days
          </h6>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <h5 className="text-white">{hours < 10 ? '0' + hours : hours}</h5>
          <h6 className='text-center text-white' style={{ fontSize: '12px' }}>
            Hours
          </h6>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <h5 className="text-white">{minutes < 10 ? '0' + minutes : minutes}</h5>
          <h6 className='text-center text-white' style={{ fontSize: '12px' }}>
            Minutes
          </h6>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <h5 className="text-white">{seconds < 10 ? '0' + seconds : seconds}</h5>
          <h6 className='text-center text-white'>
            Seconds
          </h6>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Countdown date={date} renderer={renderer} onComplete={updateDate} key={key}/>
    </div>
  );
}

export default CountDownTimer;
