import React from 'react'
import { useMediaQuery } from 'react-responsive';
import googleplayimg from "../../images/newimgs/googleplaybtn.webp"
import appstoreimg from "../../images/newimgs/appstorebtn.webp"
function MemberShipBanner() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })

    return (
        <>
            <section className="fact-area fact-map parallexcontact">
                <div className="container">
                    <div className="row justify-content-center align-items-center pt-5 mx-2">
                        <div className="col-xl-6 col-lg-6 col-md-10">
                            <div className="section-title mb-45">
                                <div className="section-text section-text-white">
                                    <h2 className='mb-3'>Best Transformation App for the Tech -Savvies</h2>
                                    <h4 className="mb-3">
                                        Discover multiple courses on the go with the Silva Method meditation app
                                        Search for “Silva Method” on your app store or Play store.
                                        Download the app and sign in for the transformation
                                    </h4>
                                   
                                </div>
                            </div>
                           
                            <div className='mt-3 mb-3'>
                                <h6 className=''>Available On:</h6>
                                <div className="d-flex justify-content-start gap-3 flex-sm-column flex-md-row">
                                    <img src={googleplayimg} style={{ width: "170px", height: "50px" }} />
                                    <img src={appstoreimg} style={{ width: "170px", height: "50px" }} />
                                </div>
                                {/* <h4 className="mb-3">
                                     Once you’ve downloaded the app, use your account email and password to login.
                                </h4> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-lg-6 col-md-8" style={{ height: "100%" }}>
                            <img 
                             src={require("../../images/membershipimages/app_1_.webp")}
                             alt="" style={{ width: "100%", zIndex: "50000", marginLeft: isDesktopOrLaptop ? "50px" : "0px" }} />

                            <div className="cta-satisfied">
                                <div className="single-satisfied">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MemberShipBanner