import React from "react";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'

function Overcome2() {
  const { isMobile } = useContext(AuthContext);

  const techniques = [
    {
      id: 1,
      text: "Glass of water",
    },
    {
      id: 2,
      text: "Mirror of the Mind",
    },
    {
      id: 3,
      text: "Mental Screening",
    },
    {
      id: 4,
      text: "Alarm clock",
    },
    {
      id: 5,
      text: "3 Finger Technique",
    },
    {
      id: 6,
      text: "Mental Video",
    },
    {
      id: 7,
      text: "3 scene technique",
    },
  ];

  const handleScroll= ()=>{
    polyfill()
    const item= document.querySelector('.scrollnewsection');

    scrollIntoView(item, {
      behavior: "smooth",
      block: "end", inline: "nearest"
    });
  }

  return (
    <div className={`theme-dark-2 py-${isMobile ? "0" : "5"}`}>
      <Container>
        <Row className="align-items-center">
          <Col sm={12} md={6} className={`order-${isMobile ? "2" : "1"}`}>
            <p
              className="h1 text-white fw-bold pb-3 px-3"
              style={{ maxWidth: isMobile ? "auto" : "500px" }}
            >
              Popular Techniques of the Silva Method
            </p>
            {/* <p style={{ color: "white",maxWidth:isMobile?"auto":"650px" }}>
            Unleash the power of our platform with a multitude of powerful
            features, empowering you to achieve your goals.
          </p> */}
            <div className="p-3">
              <Row>
                {techniques?.map((technique, i) => (
                  <Col sm={5} md={6}>
                    <p
                      className={`text-white ${isMobile ? "h5" : ""} ms-3`}
                      key={i}
                    >
                      <span className="me-2">
                        <IoCheckmarkCircleSharp color="#f1c40f" />
                      </span>{" "}
                      {technique.text}
                    </p>
                  </Col>
                ))}
                <div className="d-flex justify-content-start align-items-center pt-4 ps-4">
                  <button
                    className="herodiv-becomememberbtn3 px-5"
                    onClick={handleScroll}
                  >
                    {"Learn Techniques "}
                  </button>
                </div>
              </Row>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            className={`order-${isMobile ? "1" : "2"} mb-${
              isMobile ? "3" : "0"
            }`}
          >
            <img
              src="/img/courseimgs/combo/silva-method-popular-technique copy.webp"
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Overcome2;
