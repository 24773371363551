import React from 'react';

const StressImpact = () => {
  const styles = `
    .impact-container {
      margin: 0 auto;
    }

    .impact-section {
      background: white;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0 10px 30px rgba(124, 58, 237, 0.1);
      transition: transform 0.3s ease;
    }

    .impact-section:hover {
      transform: translateY(-5px);
    }

    .impact-content {
      background: linear-gradient(135deg, #7c3aed 0%, #4c1d95 100%);
      padding: 60px;
      color: white;
      position: relative;
    }

    .impact-pattern {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(circle at 2px 2px, rgba(255, 255, 255, 0.1) 1px, transparent 0);
      background-size: 30px 30px;
      opacity: 0.3;
    }

    .impact-title {
      font-size: 2.5rem;
      font-weight: 800;
      margin: 0 0 30px 0;
      line-height: 1.2;
      position: relative;
      z-index: 1;
    }

    .impact-text {
      font-size: 1.125rem;
      line-height: 1.8;
      margin: 0 0 20px 0;
      position: relative;
      z-index: 1;
      color: rgba(255, 255, 255, 0.9);
    }

    @media (max-width: 768px) {
      .impact-content {
        padding: 40px 30px;
      }

      .impact-title {
        font-size: 2rem;
      }
    }

    @media (max-width: 480px) {
      .impact-container {
        padding: 20px 15px;
      }

      .impact-content {
        padding: 30px 20px;
      }

      .impact-title {
        font-size: 1.75rem;
      }

      .impact-text {
        font-size: 1rem;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="container">
        <section className="impact-section">
          <div className="impact-content">
            <div className="impact-pattern"></div>
            <h1 className="heading-large text-start">How Stress Affects You Physically and Emotionally</h1>
            <p className="robotofont text-white">
              Stress is a SILENT KILLER. It impacts both your body and mind in ways you might not always notice. Physically, stress can cause headaches, muscle tension, digestive issues, and even weaken your immune system, making you more susceptible to illness. You may experience fatigue, sleep problems, or notice your heart rate and blood pressure rising.
            </p>
            <p className="robotofont text-white">
              Emotionally, stress can take a toll on your mood, leading to feelings of anxiety, irritability, or sadness. It can make you feel overwhelmed, frustrated, or even disconnected from the things you love. Prolonged emotional stress can affect your mental health, leading to burnout or depression.
            </p>
            <p className="robotofont text-white">
              Understanding how stress affects both your body and mind is the first step to taking back control and finding effective stress coping skills to manage it.
            </p>
            <img src='/img/stress/Image20241113105602.jpg' className='mt-4' style={{
              borderRadius:"15px"
            }} />
          </div>
        </section>
      </div>
    </>
  );
};

export default StressImpact;