import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadingNew from "../../AboutUs/HeadingNew";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import { FaCheckCircle } from "react-icons/fa";

function CourseFeatures({image,data,title}) {
  const { isMobile } = useContext(AuthContext);

  

  return (
    <div className="container">
      <div className="my-4">
      <HeadingNew title={title || 'Course Features'}/>
      </div>
      <Row className="justify-content-center align-items-center mb-5">
        <Col xs={12} md={6}>
          {isMobile ? (
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={image || "/img/landingcombo/5.jpg"}
                style={{
                  width: "95%",
                  height: "95%",
                  borderRadius: 20,
                }}
              />
            </div>
          ) : (
            <img
              src={image || "/img/landingcombo/5.jpg"}
              style={{
                width: "95%",
                height: "95%",
                borderRadius: 20,
              }}
            />
          )}
        </Col>
        <Col xs={12} md={6}>
          {
            data?.map((feature, i) =>
              <div key={i} className="d-flex gap-2 px-3 mt-2">
                <div>
                <FaCheckCircle color="green"/>
                </div>
                <div>
                 <h5>{feature?.title}</h5>
                  <p>{feature.text}</p>
                </div>
              </div>
            )
          }
        </Col>
      </Row>
    </div>
  );
}

export default CourseFeatures;
