import React from 'react'
import HomeHeading from '../Home/Home/HomeHeading'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import UnstressHeroSection from './UnstressHeroSection'
import BannerBooking4 from '../Draganpages/BannerBookingFour'
import BestMentorSection from './BestMentorSection'
import TrustedBy from '../Draganpages/TrustedBy'
import GrowSection from './GrowSection'
import BenefitsUnstress from './BenefitsUnstress'
import VideoTableSection2 from '../Draganpages/VideoTableSection2'
import DraganPageFaq from '../SilvaManifestationProgram/DraganPageFaq'
import CustomerTestimonialsUnstress from './CustomerTestimonialsUnstress'
import SubmitForm from './SubmitForm'
import DraganTestimonials from '../Draganpages/DraganTestimonials'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import VideoTableSection3 from '../silvamembership/VideoTableSection3'
import SilvaLifeFAQ from '../SilvaManifestationProgram/SilvaLifeFAQ'
import HeadingNew from '../AboutUs/HeadingNew'

function UnstressLanding() {
  const {isMobile} = useContext(AuthContext);

  return (
   <div style={{
    background:"#f7f3fd"
   }}>
    <HomeHeader />

    <UnstressHeroSection />
    <BannerBooking4 />
    <TrustedBy />
    <div className='parallexnewnwe' style={{
        paddingTop:isMobile?"50px":"50px",
        paddingBottom:isMobile?"50px":"50px",
       
     }}>
     <SubmitForm />
     </div>
    <BestMentorSection />
   
    <GrowSection />
    <BenefitsUnstress />
    <VideoTableSection3 />
    <div className='container my-5'>
    {/* <CustomerTestimonialsUnstress /> */}
    <div className='py-5'>
    <DraganTestimonials />
    </div>
    <div className='py-4'>
      <HeadingNew start small title={"Frequenly Asked Questions"} />
    <SilvaLifeFAQ />
    </div>
    
    </div>
    <div className='parallexnewnwe' style={{
        paddingTop:isMobile?"50px":"50px",
        paddingBottom:isMobile?"50px":"50px",
       
     }}>
     <SubmitForm />
     </div>
    <CustomFooter />
   </div>
  )
}

export default UnstressLanding


