import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../../context/AllContext';

const HealingResearchSection = () => {
  const {isMobile} = useContext(AuthContext)
  return (
    <div className="research-section mb-5">
      <Container>
        <Row className='justify-content-center align-items-center'>
        <Col lg={6} className="image-container">
            <Tilt className='d-flex justify-content-start align-items-center'>
            <img src="/img/bg/bghero/icons/mr0urdenkxxfbun0x79a.jpg" alt="Research on Healing Meditation"
            style={{
                width:isMobile?"100%":"90%",
                border:"3px solid #b44477"
            }}
            />
            </Tilt>
          </Col>
          <Col lg={6} className="research-content">
            <h2 className='lora-sans'>Research and studies on Healing Meditation</h2>
            <p className='robotofont' style={{
                color:"#b44477"
            }}>
              Research from the University of Massachusetts Medical School revealed that consistent meditation practice can bring remarkable health benefits, including a 45% reduction in heart disease, a 43% decrease in high blood pressure, and significant reductions in cholesterol levels, all while slowing the aging process. 
              But when it comes to health, it's essential not to follow any rigid ideas blindly. The key lies in taking a thoughtful, balanced approach by using our own intelligence. 
            </p>
            <p className='robotofont' style={{
                color:"#b44477"
            }}>
              Another study points out that healing meditation is a valuable complementary method for managing chronic pain, addressing both the physical and emotional dimensions of discomfort. Self-healing meditation offers a pathway to not just physical relief but emotional and psychological well-being, with techniques like guided meditation supporting this holistic journey.
            </p>
          </Col>
         
        </Row>
      </Container>
    </div>
  );
};

export default HealingResearchSection;
