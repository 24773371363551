import React from 'react';
import HeadingNew from '../AboutUs/HeadingNew';
import { useContext } from 'react';
import { AuthContext } from '../../context/AllContext';

const InstructorVideoSection = () => {
    const {isMobile} = useContext(AuthContext)
  return (
    <div className="toolbox-container">
      <div className="content-wrapper">
        <div className="content-side">

            <HeadingNew title={"Silva Unstress ToolBox"} start={!isMobile} />
          
          <p className="subtitle robotofont">
            Overcome the Biggest Threat to our Health with The Silva Unstress Toolbox
          </p>

          <div className="description">
            <p className='robotofont'>
              Chronic Stress is the "silent killer" of our health. So why tolerate
              its perpetual presence? Break free from the grips of stress and
              anxiety with Silva Unstress Toolbox and experience lasting relief,
              unwavering resilience, and inner bliss. Join this free masterclass to
              prioritize the most precious asset – your health.
            </p>
          </div>
        </div>

        <div className="video-side">
          <div className="video-wrapper">
            <video 
              controls 
              autoPlay
              muted
              controlsList="nodownload" 
            >
              <source 
                src="https://silva-method-official.b-cdn.net/Unstress%20Toolbox/Video%20sales%20letter%2C%20teachers.mp4" 
                type="video/mp4" 
              />
            </video>
          </div>
        </div>
      </div>

      <style>{`
        .toolbox-container {
          padding: 4rem 2rem;
        }

        .content-wrapper {
          max-width: 1400px;
          margin: 0 auto;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 4rem;
          align-items: center;
        }

        .content-side {
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }

        .title {
          font-size: 3.5rem;
          font-weight: 700;
          color: #1a365d;
          line-height: 1.2;
          margin: 0;
        }

        .subtitle {
          font-size: 1.5rem;
          font-weight: 600;
          color: #2d3748;
          line-height: 1.4;
          margin: 0;
        }

        .description {
          background: white;
          padding: 2rem;
          border-radius: 15px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        }

        .description p {
          font-size: 1.1rem;
          line-height: 1.8;
          color: #4a5568;
          margin: 0;
        }

        .video-side {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .video-wrapper {
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
                      0 10px 10px -5px rgba(0, 0, 0, 0.04);
        }

        video {
          width: 100%;
          display: block;
          border-radius: 20px;
        }

        @media (max-width: 1024px) {
          .content-wrapper {
            grid-template-columns: 1fr;
            gap: 2rem;
          }

          .content-side {
            text-align: center;
          }

          .toolbox-container {
            padding: 2rem 1rem;
          }

          .title {
            font-size: 2.5rem;
          }

          .subtitle {
            font-size: 1.25rem;
          }

          .description {
            padding: 1.5rem;
          }

          .description p {
            font-size: 1rem;
            line-height: 1.6;
          }
        }
      `}</style>
    </div>
  );
};

export default InstructorVideoSection;