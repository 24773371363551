import React from 'react'
import { Link } from 'react-router-dom'
import FAQ2 from '../../CoursePage/FAQ2'

function SilvaComboFAQ() {
  return (
    <div className=''>

        <FAQ2
        heading={"Who is the current President of The Silva International?"}
        body={<p className='text-light'>Diana Silva is Jose Silva’s daughter and the current President of Silva International in 2024. Click to know more about her: <a href="https://silvamethod.com/instructor/diana-silva" className='text-primary bg-light'>https://silvamethod.com/instructor/diana-silva</a></p>}
      />
      <FAQ2
        heading={"Why should I choose the combo course over buying individual courses?"}
        body={<p style={{ color: "white" }}>Enrolling in the combo course offers significant advantages. You'll gain access to all 8 original Silva Method courses. Moreover, individual courses could cost you over $500 but with our combo pack, you'll receive all 8 courses for just $345. Additionally, the combo pack includes live interactive classes along with recorded audio courses, which are not available with individual courses.</p>}
      />
      <FAQ2
        heading={"Will I have access to live interactive classes if I enroll in the Silva Method Complete Course (Combo Pack)?"}
        body={<p style={{ color: "white" }}>Absolutely! When you enroll in the Silva Method Complete Course (Combo Pack), you'll gain access to live interactive classes led by a certified Silva Method instructor. These sessions are available twice a month, spanning over two weekends. Plus, if you miss a class, you'll have the opportunity to catch up during the next scheduled session.</p>}
      />

      {/* <FAQ2
        heading={"Is a certificate provided upon completing the course?"}
        body={<p style={{ color: "white" }}>Absolutely! Upon successfully finishing all 8 courses and attending all required live interactive classes, you'll receive an exclusive Recognition Certificate, acknowledging your commitment to personal development.</p>}
      /> */}
      <FAQ2
        heading={"Which courses are part of the Silva Method Complete Course (Combo Pack)?"}
        body={<p style={{ color: "white" }}>
            The Silva Method Complete Course (Combo Pack) comprises all 8 courses exclusively crafted by the Silva Method. These include Silva Method Life System, Silva Method Intuition System, Silva Method Manifesting, Silva Mind Body Healing System, Silva Alpha Sound, Silva Theta Sound, Silva Love Program, and the Weekly Journal for finding a soul mate.
        </p>}
      />
       <FAQ2
        heading={"What are the steps to begin the process or access after purchasing?"}
        body={<p style={{ color: "white" }}>
            After purchasing, you'll receive an email in your registered email ID. This email will provide all the necessary credentials to log into the Silva Method portal and access all the courses.
        </p>}
      />
       {/* <FAQ2
        heading={"Are the Live interactive classes recorded?"}
        body={<p style={{ color: "white" }}>
           Absolutely! Recordings of the live interactive classes will be accessible for up to 2 days after the scheduled session. You can access these recordings by logging into your Silva account using the credentials provided in your registered email ID.
        </p>}
      /> */}
       <FAQ2
        heading={"Which platform will host the live interactive classes?"}
        body={<p style={{ color: "white" }}>
           The live interactive classes of the Silva Method will be conducted via Zoom.
        </p>}
      />
       <FAQ2
        heading={"How can I find and join the live interactive class?"}
        body={<p style={{ color: "white" }}>
           You'll find the schedule of the live interactive classes posted on this page. Additionally, a reminder email will be sent 72 hours before any upcoming classes.
        </p>}
      />
       {/* <FAQ2
        heading={"What should I do if I miss a live interactive class?"}
        body={<p style={{ color: "white" }}>
           Don't worry if you miss a live interactive session! You have options. Firstly, recordings of the live classes will be accessible for the next 2 days after the scheduled session via the Silva Method Portal. Simply log into your Silva account to access them. Secondly, you can join the next class covering the same topic, as classes will be ongoing for 12 months.
        </p>}
      /> */}
      <FAQ2
        heading={"How long will the live interactive classes be held?"}
        body={<p style={{ color: "white" }}>
           The live interactive classes are a new addition to the Silva Method Curriculum, exclusively available with this complete course (combo pack). These classes will run throughout the entire year, occurring twice a month.
        </p>}
      />
      <FAQ2
        heading={"Will I have lifetime access to the course after purchasing?"}
        body={<p style={{ color: "white" }}>
           Absolutely! After purchasing the Silva Method Complete Course (Combo Pack), you'll enjoy lifetime access to all the courses (recorded audio) and live interactive classes in your account.
        </p>}
      />
        <FAQ2
        heading={"What should I do if I am unable to enroll in the course?"}
        body={<p style={{ color: "white" }}>
           Simply log in to your profile, then navigate to the course page and click the "Start Now" button. If you encounter any further issues, please feel free to email us at digital@silvamethod.com. We're here to assist you!
        </p>}
      />
      <FAQ2
        heading={"Can I Download the Audio Courses?"}
        body={<p style={{ color: "white" }}>
           None of our courses are downloadable. To access the audio courses, please enroll and log into your profile using the email ID and password. For the best experience, ensure you have a stable internet connection.
        </p>}
      />
       <FAQ2
        heading={"Can I Download the Live Interactive Classes?"}
        body={<p style={{ color: "white" }}>
           None of our courses are downloadable.To join our live interactive classes, first enroll in our Combo Course. Once you enroll, you will receive email with the live class link, along with the date and time details. For the best experience, please ensure you have a stable internet connection.
        </p>}
      />
      <FAQ2
        heading={"What if I'm still struggling to understand the entire course process?"}
        body={<p style={{ color: "white" }}>
          If you have any lingering doubts about the post-purchase process, the complete curriculum, or how it all works, don't hesitate to reach out to us at digital@silvamethod.com. We're here to help!
        </p>}
      />
    </div>
  )
}

export default SilvaComboFAQ