import React from 'react'
import { IoMdCheckmarkCircle } from "react-icons/io";
import { handleScrollCombo2Curr,handleScrollCombo2Class, handleScrollCombo, handleScrollCombo2 } from '../../../utils/handleScrollEmotions';


function ComboCardSec() {


    // const handleScroll = () => {
    //     const doc = document.querySelector(".curriculumsection");
    //     if (doc) {
    //         doc.scrollIntoView({
    //             behavior: "smooth",
    //             block: "start", // Align the top of the element with the top of the viewport
    //             inline: "nearest", // Scroll until the element is in view
    //         });
    //     }
    // }

    // const handleScroll2 = () => {
    //     const doc = document.querySelector(".classsection")
    //     if (doc) {
    //         doc.scrollIntoView({
    //             behavior: "smooth",
    //             block: "start", // Align the top of the element with the top of the viewport
    //             inline: "nearest", // Scroll until the element is in view
    //         });
    //     }
    // }

    const benifits = [
        {
            id: 1,
            text: "8 original courses.",
        },
        {
            id: 2,
            text: "280+ lessons.",
        },
        {
            id: 3,
            text: "85+ exercises.",
        },

    ];


    const benifits2 = [
        {
            id: 4,
            text: "3+ live classes every month.",
          },
          {
            id: 5,
            text: "Free doubt-clearing sessions.",
          },
    ]
    return (
        <article className='article my-5'>
            

            <section class="cardcombodis">

          
                <div class="text-content">
                    <h3 style={{
                        fontWeight:"500",
                        fontSize:"1.2rem"
                    }} className='white-color'>Silva Method Complete Home Study Program</h3>
                    <p className='white-color' style={{
                        lineHeight:"1rem",
                        fontSize:"0.9rem"
                    }}>
                        Get access to complete Silva Method Digital course for a comprehensive learning
                    </p>
                    <p className="text-white" style={{
                        padding:0,
                        margin:0,
                        fontSize:"0.9rem"
                    }}>Additionally, enjoy these perks:</p>

                    <div className="my-3" style={{
                        marginLeft: 0
                    }}>
                        {benifits.map((benifit, i) => (
                            <div style={{
                                height: "auto"
                            }}>
                                <div className="d-flex align-items-center gap-3">
                                    <IoMdCheckmarkCircle color="white" size={23} />
                                    <p className="text-start text-white" style={{
                                        margin: 0,
                                        padding: 0,
                                        fontSize:"0.9rem"
                                    }} key={i}>
                                        {benifit.text}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <a style={{
                        cursor:"pointer"
                    }} onClick={handleScrollCombo}>View Curriculum</a>
                </div>
                <div class="visual">
                    <img src="/img/combo/combolive.jpg" alt="" />
                </div>
            </section>
        
           
            <section class="cardcombodis">

                <div class="text-content">
                    <div className='d-flex gap-2 flex-column'>
                        <img src='/img/combo/livegif.gif' width={90} height={25} style={{
                            objectFit: "cover"
                        }} />

                        <h3 style={{
                        fontWeight:"500",
                        fontSize:"1.2rem"
                    }} className='white-color'>
                            Interactive Program
                        </h3>
                    </div>
                    <p className="text-white pt-3" style={{
                        padding:0,
                        margin:0,
                        fontSize:"0.9rem"
                    }}>
                        Enjoy the live experience of learning in a transformative setting with cohorts
                    </p>
                    <p className="text-white" style={{
                        padding:0,
                        margin:0,
                        fontSize:"0.9rem"
                    }}>Additionally, enjoy these perks:</p>

                    <div className="my-3" style={{
                        marginLeft: 0
                    }}>
                        {benifits2.map((benifit, i) => (
                            <div style={{
                                height: "auto"
                            }}>
                                <div className="d-flex align-items-center gap-3">
                                    <IoMdCheckmarkCircle color="white" size={23} />
                                    <p className="text-start text-white" style={{
                                        margin: 0,
                                        padding: 0,
                                        fontSize:"0.9rem"
                                    }} key={i}>
                                        {benifit.text}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <a style={{
                        cursor:"pointer"
                    }} onClick={handleScrollCombo2}>View Class Schdedule</a>
                </div>
                <div class="visual">
                    <img src="/img/combo/combolive2.jpg" alt="" />

                </div>

            </section>
          
        </article>
    )
}

export default ComboCardSec