import React, { useContext } from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Collapsible from "react-collapsible";
import { BiLogOutCircle } from "react-icons/bi";
import { AuthContext } from "../../../context/AllContext";
import Swal from "sweetalert2";
import SilvaLogo from '../../../img/logo/title-logo.png'
import {AiOutlineClose} from "react-icons/ai"

const Sidebar = ({ show, handleClose, listData, handleShow, homeLink = "/" }) => {
  const { isUserLoggedIn, userData, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  // const Home = <NavLink to="/home">Home</NavLink>
  const About = <NavLink to="/about-silva-method">About</NavLink>;
  const Seminars = (
    <NavLink to="/courses/basic-lecture-series">Seminars </NavLink>
  );
  const Events = <NavLink to="/events/live">Events</NavLink>;
  const Instructors = <NavLink to="/instructor">Instructors</NavLink>;
  const Products = <NavLink to="/store/course/silva-theta-sound">Products</NavLink>;
  const Blogs = <a onClick={() => window.open("https://silvamethod.com/stores")}>Blogs</a>;


  const CurrentUser = <NavLink to="/store/profile/">{userData?.first_name ? userData?.first_name : "Guest"}</NavLink>;
  const navfont = {};

  const handleLogout = async () => {
    handleClose();
    Swal.fire({
      title: "Are you sure, you want to logout?",
      showCancelButton: true,
      confirmButtonText: "Logout",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        logout();
        navigate("/");
      } else {
        handleShow();
      }
    });
  };

  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            <span>
              <AiOutlineClose size={30} color="white" onClick={handleClose} />
            </span>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div style={{ borderRadius: "50%" }} className="text-center">
              <img
                src={SilvaLogo}
                alt="silva_logo"
                style={{ borderRadius: "50%" }}
                width={"50px"}
                height={"50px"}
              />
            </div>
            {/* {isUserLoggedIn && (
              <h3 className="white-color">Welcome {userData?.first_name?userData?.first_name:"Guest"}</h3>
            )} */}
            <ul style={{ paddingBottom: "10px" }}>
              <li>
                {" "}
                <NavLink to={homeLink}>Home </NavLink>
              </li>
            </ul>
            <ul>
              <li className="highlightedlink">
                <NavLink to="/silva-method-courses">Enroll Now</NavLink>
              </li>
            </ul>
            {/* <Collapsible trigger={Home} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/home">Home</NavLink></li>
                        {/* <li><NavLink to="/homeTwo">Home style 2</NavLink></li>
                        <li><NavLink to="/homeThree">Home style 3</NavLink></li>
                        <li><NavLink to="/homeFour">Home style 4</NavLink></li>
                        <li><NavLink to="/homeFive">Home style 5</NavLink></li> */}
            {/* </ul>
                  </Collapsible> */}

            <Collapsible
              trigger={About}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/jose-silva-method">José Silva</NavLink>
                </li>
                <li>
                  <NavLink to="/what-is-silva-method">
                    What is Silva Method
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about-silva-method">About us</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="/research">Research</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/what-is-silva-method">What is Silva Method</NavLink>
                </li> */}
              </ul>
            </Collapsible>

            <Collapsible
              trigger={Seminars}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/courses/basic-lecture-series" style={navfont}>
                    Basic Lecture Series(BLS-4)
                  </NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/courses/seminar">Seminars</NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/courses/silva-life-system-seminar">
                    Silva Life System
                  </NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/courses/silva-intution-system-seminar">
                    Silva Intuition System
                  </NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/courses/silva-mastery-system">
                    Silva Mastery System
                  </NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger={Events}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li style={navfont}>
                  <NavLink to="/events/live">Live</NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/events/online">Online</NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger={Instructors}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li style={navfont}>
                  <NavLink to="/instructor">Instructors</NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/instructor/find-instructor">
                    Find Instructor
                  </NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/instructor/become-silva-instructor">
                    Become an Instructor
                  </NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger={Products}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                {/* <li>
                  <NavLink to={"/store/course/combo-plan"}>
                    {"Complete course with live interaction"}
                  </NavLink>
                </li> */}
                {listData &&
                  listData.map((listItem) => (
                    <li key={listItem.course_id} style={navfont}>
                      <NavLink
                        to={`/store/course/${
                          listItem.course_id !== "love-program"
                            ? listItem.course_id
                            : "silva-love-program"
                        }`}
                      >
                        {listItem.course_title}
                      </NavLink>
                    </li>
                  ))}
                {/* <li style={navfont}>
                  <NavLink to="/store/books">Books</NavLink>
                </li> */}
                {/* <li><NavLink to="/about">Silva Life System & Silva Intuition System (The Complete Silva Method Course)</NavLink></li>
                           <li><NavLink to="/appoinment">Silva Life System</NavLink></li>
                           <li><NavLink to="/portfolioTwoColumn">Silva Intuition System</NavLink></li>
                           <li><NavLink to="/portfolioThreeColumn">Silva Method Complete Manifesting Program</NavLink></li>
                           <li><NavLink to="/portfolioSlider">Manifesting course</NavLink></li>
                           <li><NavLink to="/contact">Activating Intuition</NavLink></li>
                           <li><NavLink to="/notMatch">Healing & Problem Solving</NavLink></li>
                           <li><NavLink to="/about">Manage Body & Mind Simultaneously</NavLink></li>
                           <li><NavLink to="/appoinment">4 Steps to the Silva Life System</NavLink></li>
                           <li><NavLink to="/portfolioTwoColumn">Silva Long Relax Exercise by Jose Silva</NavLink></li>
                           <li><NavLink to="/portfolioThreeColumn">Silva Alpha Sound</NavLink></li>
                           <li><NavLink to="/portfolioSlider">Silva Theta Sound</NavLink></li>
                           <li><NavLink to="/contact">Home Study Programs</NavLink></li>
                           <li><NavLink to="/notMatch">Books</NavLink></li>
                           <li><NavLink to="/portfolioSlider">Dynamic Meditation</NavLink></li>
                           <li><NavLink to="/contact">Silva Master Classes</NavLink></li>
                           <li><NavLink to="/notMatch">Long Relax Exercise by Jose Silva</NavLink></li> */}
              </ul>
            </Collapsible>
            <Collapsible
              trigger={Blogs}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <a
                    onClick={() =>
                      window.open("https://silvamethod.com/stores")
                    }
                  >
                    English Blogs
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      window.open(
                        "https://silvamethod.com/stores/category/spanish"
                      )
                    }
                  >
                    Spanish Blogs
                  </a>
                </li>
                {/* <li style={navfont}>
                  <NavLink to="/store/blogs/">English Blogs</NavLink>
                </li>
                <li style={navfont}>
                  <NavLink to="/store/spanish-blogs/">Spanish Blogs</NavLink>
                </li> */}
              </ul>
            </Collapsible>
            {/* <ul style={{ paddingTop: "5px" }}>
              <li style={navfont}>
                <a style={{ color: "whitesmoke" }} onClick={() => window.open("https://silvamethod.com/stores")}>Blogs</a>
              </li>
            </ul> */}
            <ul style={{ paddingBottom: "10px" }}>
              {!isUserLoggedIn ? (
                <li style={navfont}>
                  <NavLink to="/login">Login/Register</NavLink>
                </li>
              ) : (
                <Collapsible
                  trigger={CurrentUser}
                  triggerTagName="div"
                  triggerOpenedClassName="icon_close"
                  triggerClassName="iconAdd"
                  open={false}
                >
                  <li onClick={handleLogout}>
                    <span className="white-color">
                      {" "}
                      Logout <BiLogOutCircle size={25} color="white" />
                    </span>
                  </li>
                  <li>
                    <NavLink to="/store/profile">My Profile</NavLink>
                  </li>
                  {userData && userData.enrollment_status == "Enrolled" && (
                    <li>
                      <NavLink to="/store/profile/new/settings/myorders">
                        My Orders
                      </NavLink>
                    </li>
                  )}

                  {userData && (
                    <li>
                      <NavLink to="/today">My Dashboard</NavLink>
                    </li>
                  )}
                </Collapsible>
              )}
            </ul>
            <li>
              <NavLink to="/become-silva-affiliate">
                Become an Affiliate
              </NavLink>
            </li>

            <ul style={{ paddingTop: "5px" }}>
              <li style={navfont}>
                <a
                  style={{ color: "whitesmoke" }}
                  onClick={() =>
                    window.open("https://silvamethod.com/stores/shop/")
                  }
                >
                  Books
                </a>
              </li>
            </ul>
            <ul style={{ paddingTop: "5px" }}>
              <li style={navfont}>
                <a
                  style={{ color: "whitesmoke" }}
                  onClick={() => window.open("https://silvacasesonline.com")}
                >
                  Silva Cases
                </a>
              </li>
            </ul>
            {/* <li><NavLink to="/youtube-videos">Youtube Videos</NavLink></li> */}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
