import React, { useState } from 'react'
import { Fade } from "react-awesome-reveal";
import Masonry from 'react-masonry-css';
import ReactHlsPlayer from 'react-hls-player';
import TestimonialCard from '../../newbooking/TestimonialCard';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import { useNavigate } from 'react-router-dom';

function ComboTestimonials() {

    const navigate = useNavigate()

    const {isMobile}  = useContext(AuthContext)
    const videoTestiomonials = [


        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://silva-method-official.b-cdn.net/updated-testimonials/Ewa's%20Pietrzak%20Testimonial%20%232-%20The%20Silva%20Method%20Ireland%20WM.mp4",
        //     name: "",
        //     designation: "",
        // },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Graduate%20Testimonial%2C%20Silva%20Method%20-%20Karin%20Barnes%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/9.mp4",
            name: "",
            designation: "",
        },
      
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://silva-method-official.b-cdn.net/updated-testimonials/Metoda%20Silvy%20-%20Referencje%20-%20Monika%20Kucharczyk%20WM.mp4",
        //     name: "",
        //     designation: "",
        // },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Short%20Stories%20of%20Transformation%20-%20Kinga%20-%20Silva%20Method%20WM.mp4",
            name: "",
            designation: "",
        },
        
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20Method%20Testimonial%20from%20Joanne%20Lim%20(Chinese%20version)%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/SILVA%20India%20Success%20Story%20-%20using%20SILVA%20Techniques%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20Method%20Testimonial%20from%20Kain%20Samiya%20(Chinese%20version)%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20Method%20Testimonial%20from%20Luo%20Wei%20Xiong%20(Chinese%20version)%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20Method%20Testimonial%20from%20Nazlan%20Bin%20Baharudin%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Manifestation%20Hack%20Using%20Silva%20Method%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20Method%20Testimonial%20fromMost%20of%20My%20Dreams%20Come%20Through%20With%20Silva%20Method%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20Method%20Testimonial%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20Method%20UK%20-%20Testimonial%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/Silva%20tools%20are%20useful%20and%20very%20practical%20-%20by%20Aisling%20-%20Silva%20Method%20Graduate-WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/%E8%A5%BF%E5%8D%8E%E8%AF%BE%E7%A8%8B%E8%A7%81%E8%AF%81%20(Silva%20Method%20Testimonial)%20WM-1.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/%E8%A5%BF%E5%8D%8E%E8%AF%BE%E7%A8%8B%E8%A7%81%E8%AF%81%20(Silva%20Method%20Testimonial)%20WM.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/%5BOFFICIAL%5D%20AniEraser%20-%20AI%20Remove%20Unwanted%20Watermarks_Objects%20From%20Photos%20and%20Videos.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/1.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/2.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/Donna%20Bigonzi%20-%20Silva%20Method%20Success%20Story%20-%20MUST%20WATCH!.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/Healed%20Wifes%20Knee%20by%20Meditating%20Miles%20Away%20(How%20Use%20Silva%20Method%20Alpha%20Brain%20Power).mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/How%20I%20Got%20My%20Dream%20Job%20Through%20Visualization%20Using%20Silva%20Method.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/Joanna's%20Michalowska%20Testimonial%20%231%20-%20The%20Silva%20Method%20Ireland.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/Karolina's%20Strzelecka%20Testimonial%20-%20%235%20-%20The%20Silva%20Method%20Ireland.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/My%20Fibromyalgia%20was%20healed%20through%20meditation%20and%20visualization.%20I%20was%20able%20to%20sit%20without%20pain!!!.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/Roisin's%20Sullivan%20Testimonial%20-%20%234%20-%20The%20Silva%20Method%20Ireland.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/3.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/4.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/5.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/6.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/7.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/8.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/9.mp4",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://silva-method-official.b-cdn.net/updated-testimonials/update-testimonials-2/10.mp4",
            name: "",
            designation: "",
        },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/dae84244-466c-41b8-8a14-be4b98034513/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/d5573927-cb7e-4339-b8da-b640222242b0/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/84e41eb1-17a1-4f2e-8e30-f17cf9c73add/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/7eb69234-1141-4dc2-ae9d-fa8b75487e24/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/e993e6fa-b421-4e4f-9ed5-d5f279eb443b/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/adb74194-c467-4775-a2c6-2bb62db476c3/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/a640c2a0-3d34-4979-8c78-38075709ac95/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/44dd2d77-9713-446d-8b1f-34be0758d35b/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/b6457194-5262-4a7f-b8f7-24d3e51bcb26/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/6ec68636-305a-4b15-aafa-16760f4ed1eb/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/857ca3c3-5969-4b6c-afa3-5b6f49da4cf7/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/f096fc4e-1346-4869-8579-3cd96652c911/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/bb3a7d49-b205-43a0-baa6-ccf57433a720/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/0c1eb7e6-0aa3-4154-9f87-4bc1329f5c30/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // },
        // {
        //     type: "video",
        //     text: "",
        //     image: "",
        //     video:
        //         "https://vz-47e4ab86-ce6.b-cdn.net/293850cf-822f-4ca2-8c97-948bafaa4940/playlist.m3u8",
        //     name: "",
        //     designation: "",
        // }
    ];


    const [showMore,setShowMore] = useState(false);



    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1,
    };


    return (
        <div className='pb-5' style={{
            marginTop:isMobile?"50px":"100px"
        }}>

            <div className="container">
            <div className='my-4 d-flex justify-content-center align-items-center flex-column gap-3'>
            <HeadingNew title={"Success Stories from Our "} colored={"Students"} />
            <div className='w-100 w-md-75 w-lg-75 d-flex justify-content-center align-items-center flex-column'>
            <div style={{
                    width: isMobile ? "95%" : "60%",
                    position: "relative"
                }}>

                   <h5 className="mb-3 text-center robotofont" style={{
                     lineHeight: isMobile?"1.3rem":"1.5rem",
                     fontSize:isMobile?"0.9rem":"1rem"
                 }}>
                                For over five decades, the Silva Method has impacted millions of lives worldwide, including celebrities, CEOs, and everyday individuals seeking personal breakthroughs. More than 12 million people in 110 countries have experienced what Silva Mind control meditation can do. Get inspired by our global community


                 </h5>

            </div>
            </div>
            </div>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >

                    {
                        showMore && (
                            videoTestiomonials.map((item, index) => (
                                <Fade duration={700}>
                                 <TestimonialCard key={index} data={item} />
                                </Fade>
                            ))
                        )
                    }
                    {
                        !showMore && (
                            videoTestiomonials.slice(0,isMobile?3:6).map((item, index) => (
                                <Fade duration={700}>
                                 <TestimonialCard key={index} data={item} />
                                </Fade>
                            ))
                        )

                    }

                </Masonry>
                <div className='d-flex justify-content-center align-items-center'>
                <button className='cssbuttons-io-button3' onClick={() => {
                    navigate("/success-stories")
                }}>{showMore ? "Show Less" : "Show More"}</button>
                </div>
            </div>
        </div>
    )
}

export default ComboTestimonials


// 


