import React from 'react';
import { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaCheckCircle } from "react-icons/fa"
import { AuthContext } from '../../context/AllContext';

function DayCardThree({ data, index }) {
    const { isMobile, isTablet } = useContext(AuthContext);

    return (
        <div className="container mt-4 bg-white" style={{ borderRadius: '10px' }}>
            <Row style={{ borderRadius: '10px', height: (isMobile || isTablet) ? "auto" : "auto" }}>
                {/* Left Section */}
                <Col md={3} className="text-white p-3 pt-4 d-flex justify-content-center align-items-center flex-column bg-grad borderradiuscard">
                    {/* <h3 className="white-color text-center">{data.split("-")[0]}</h3> */}
                    <p className="white-color">Chapter {index}</p>
                </Col>

                {/* Middle Section */}
                {/* <Col md={6} className="p-3 d-flex justify-content-center align-items-center flex-column bg-white" style={{ borderRight: '1px solid #9b59b6' }}>
          <p className="text-center">Stress-major causes and its influence on our health.</p>
        </Col> */}
                <Col md={6} className="p-3 d-flex justify-content-center align-items-start flex-column bg-white" style={{ borderRight: (isMobile || isTablet) ? "none" : '1px solid #9b59b6',
             }}>
                    {
                        data.map((val, i) => (
                            <div className="d-flex justify-content-center align-items-center gap-3">
                                <span style={{
                                    minWidth: "40px"
                                }}>
                                    <FaCheckCircle color="green" size={30} className="mr-3" />

                                </span>
                                <p className="text-start mt-3 normalpara" style={{
                                    // fontWeight:"600",
                                    // fontSize:"1.1rem"
                                }}>
                                    {val}
                                </p>
                            </div>
                        ))
                    }
                </Col>

                {/* Right Section */}
                <Col md={3} className={`d-flex justify-content-center align-items-center flex-column`}>
                    <h5 className="text-dark mb-3">Speaker</h5>

                    <div className={`d-flex justify-content-center align-items-center gap-3 ${(isMobile || isTablet) ? 'flex-column' : ''}`}>
                        {/* <img
              className="rounded-circle mr-3"
              src="https://www.fluentu.com/blog/educator-english/wp-content/uploads/sites/13/2023/10/smiling-woman-in-formal-wear-with-arms-crossed-standing-with-her-back-to-students.jpg"
              alt="Teacher"
              width="70"
              height="70"
            /> */}
                        <div className="text-center">
                            <h4>Dragan Vujovic</h4>
                            <p>Instructor</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default DayCardThree;
