import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import CustomFooter from "../Home/Home/HomeFooter/Footer";
import LoginArea2 from "./LoginArea/LoginArea2";


const Login = () => {
//   const title = "My Profile - Silva International Inc.";


  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="Login Page" subtitle="Login" />
      {/* <LoginArea /> */}
      <LoginArea2 />
      <CustomFooter />
    </>
  );
};

export default Login;
