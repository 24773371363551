import React from 'react'
import { Image } from 'react-bootstrap'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import NinthSectionCard from '../AdvertisePages/newmembership/NinthSectionCard'

function BenefitsSectionNew() {

    const data = [
        {
            num:1,
            title:"Infuse the habits of Achievers and Top Performers",
            para:[
                "Reading or studying often brings fleeting inspiration, but the Silva Method ensures a lasting transformation. Utilizing innovative behavioral change tools, it embeds empowering habits, beliefs, and emotional patterns. This shift makes peak performance, vibrant health, unwavering peace, and boundless joy your natural state."
            ]
        },
        {
            num:2,
            title:"Experience Extraordinary productivity in mind, body, and soul.",
            para:[
                "The Silva Method goes beyond conventional productivity hacks, offering mastery in mindset tools, flow states, heightened consciousness, and spiritual techniques. Revolutionize how you approach challenges, think, feel, and accomplish your goals."
            ]
        },
        {
            num:3,
            title:"Achieve Wholeness, Be a Work Superhero and Global Game-Changer",
            title2:"that matters",
            para:[
                "The Silva Method rejects the notion of sacrificing one aspect for another. With top-tier tools, it propels growth in every facet of your life. Be a superhero at work, exude vitality, and be a beacon in your relationships and the world. Achieve a holistic, thriving existence."
            ]
        },
        {
            num:4,
            title:"Elevate your Life through Impactful and Genuine Connections",
            para:[
                "Join the global community of Changemakers, a dynamic force of over 10M individuals. These aren't ordinary people; they're influential entrepreneurs, brilliant artists, and visionary thinkers dedicated to personal and planetary elevation. Connect, learn, and grow through exclusive events, meetups, workshops, and more, fostering meaningful connections worldwide."
            ]
        }
    ]
  return (
    <div className='container ninthsection py-5'>

        <h3 style={{
            fontWeight:"700",
            textAlign:"center",
            lineHeight:"35px"
        }}>
           <span style={{
            color:"#801cb6"
           }}>Dragan</span> has improved the quality of life of over <span style={{
            color:"#801cb6"
           }}>400,000</span> people from all walks of life through his aggregate effort of his own personal development courses and the courses of lecturers he has trained and influenced in <span style={{
            color:"#801cb6"
           }}>
            30 years.
           </span>
        </h3>
       
        {/* <HeadingNew small title={""} colored={"Mind Training"} /> */}
        {/* <ParaComp
        white={false}
        margin={false}
        data={[
        ]}
        /> */}
        <p className='normalpara'>
        Experience the extraordinary as you elevate every dimension of your life with Silva Method 4- days Immersion - Live Interactive Class

        </p>
        <div className="d-flex justify-content-center align-items-center flex-column gap-3">
        {
            data.map((val,i)=>(
                <NinthSectionCard value={val} />
            ))
        }
   
        </div>

    </div>
  )
}

export default BenefitsSectionNew