import React, { useContext } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { AiFillCheckCircle } from "react-icons/ai";
import { useState } from 'react';
import { AuthContext } from '../../context/AllContext';


function TeachersCard({ data, img }) {
    const [showMore, setShowMore] = useState(false);

    const { isMobile } = useContext(AuthContext);
    const buttonStyle = {
        background: '#f1c40f',
        border: '1px solid black', // Black border
        color: 'black', // Text color,
        padding: "8px 30px",
        fontWeight: "600",
        borderRadius: "10px",
        border: '1px solid whitesmoke', // White smoke border


    };


    const showMoreFunc = (val) => {
        if (!val) {
            setShowMore(true)
        } else {
            setShowMore(false)
        }
    }
    return (
        <Card style={{
            borderRadius: "10px",
            background: "rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)",
            border: "1px solid darkgray",
            width: isMobile ? "95%" : "50%"
        }} className='my-3'>
            <Card.Body className='px-4'>
                <Row className='justify-content-start'>
                    <Col md={4}>
                        <Image

                            // width="0"

                            // height="0"
                            // sizes="100vw"
                            // alt=""
                            className="w-95 h-auto contactbanner mobilehide"
                            style={
                                { width: "98%", borderRadius: "10px", height: "auto" }
                            }
                            src={img || 'https://assets-global.website-files.com/63c698560a57ab3deb917f5a/650c35ee89fbe437409c8a98_suraj-rename-p-500.webp'}
                        />
                    </Col>
                    <Col md={8} className='px-3 mt-3 mt-md-0'>

                        <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                            {
                                !isMobile && data.map((val, i) => (
                                    <p style={{
                                        color: "white",
                                        fontWeight: "0.9rem",
                                        lineHeight: isMobile ? "22px" : "25px"
                                    }}>{val}</p>
                                ))
                            }

                            {
                                isMobile && !showMore && (
                                    <p style={{
                                        color: "white",
                                        fontWeight: "0.9rem",
                                        lineHeight: isMobile ? "22px" : "25px"
                                    }}>{data[0]}</p>
                                )
                            }
                            {
                                isMobile && showMore && data.map((val, i) => (
                                    <p style={{
                                        color: "white",
                                        fontWeight: "0.9rem",
                                        lineHeight: isMobile ? "22px" : "25px"
                                    }}>{val}</p>
                                ))
                            }

                            {
                                data.length > 1 && isMobile && !showMore && (
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        zIndex: "100000000",
                                        marginBottom: "15px"
                                    }}>
                                        <Button style={buttonStyle} variant="primary" onClick={() => showMoreFunc(showMore)}>
                                            <AiFillCheckCircle color='black' size={30} /> Read More
                                        </Button>

                                    </div>
                                )
                            }
                            {
                                showMore && isMobile && (
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        zIndex: "100000000",
                                        marginBottom: "15px"
                                    }}>
                                        <Button style={buttonStyle} variant="primary" onClick={() => showMoreFunc(showMore)}>
                                            <AiFillCheckCircle color='black' size={30} /> Read Less
                                        </Button>

                                    </div>
                                )
                            }

                        </div>

                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default TeachersCard