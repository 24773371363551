import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { requestData } from "../../utils/baseUrl";
import { Link } from "react-router-dom";
import country from 'country-list-js';






const SingleCard = ({ name,countryId,countrydata={}}) => {
  const [cityparam,setCityparam]= useState('')

  const [countryData, setCountryData] = useState();

  useEffect(()=>{
    const nameArr = name?.split(" ");
    if (nameArr?.length > 3) {
      const param = `${nameArr
        ?.slice((0, 2))
        .join("_")
        .toLowerCase()}`;
      setCityparam(param);
      // setCityparam(`${nameArr?.slice((0,2)).join("_").toLowerCase()}`)
    } else if (nameArr?.length == 3) {
      const param = nameArr[2]?.toLowerCase();
      // setCityparam(nameArr[2]?.toLowerCase())
      setCityparam(param);
    }
  },[name])


  useEffect(() => {
    // console.log(country.names())
    if (countrydata && countrydata.country_name) {
      const cname = countrydata.country_name.split(" ").map((val) => val[0].toUpperCase() + val.substring(1).toLowerCase()).join(" ")
      // console.log(cname,"countryname")
    // console.log(country.ls("United Kingdom"),"newcountry")

     let coun;
     if(cname==="Great Britain"){
      coun = country.findByName("United Kingdom");
     }else{
      coun = country.findByName(cname);
     }
      if (coun) {
        setCountryData(coun)
      }
    }
  }, [countrydata])

  return <div style={{ borderRadius: '999em 999em 999em 999em', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", display: "block", padding: '20px', gap: '1rem', backgroundColor: 'white' }}>
    <div style={{ display: 'flex', color: 'black', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
    {
        countryData && (
          <img
            width={50}
            style={{ marginRight: "10px" }}
            height={50}
            alt="United States"
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryData.code.iso2}.svg`} />
        )
      }

{
        !countryData && (
          <img
            width={50}
            style={{ marginRight: "10px" }}
            height={50}
            alt="United States"
            src={require("../../images/silva-logo.png")} />
        )
      }

   
      <Link to={`/instructor/instructor_list/${cityparam}`} state={{id:countryId}}>{name}</Link>
    </div>
  </div>;
};


function HomeCountrySection() {
  const isBigScreen = useMediaQuery({ minWidth: 1440 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 767 });
  const [globalInstructors,setGlobalInstructors] = useState([])


  const getLocation = async () => {
    const res = await requestData("locationList", "POST", {});
    if (res && res.error === false) {
      let allglobal = [];
      const data = res.data[1].sub_region
      if(data){
        data.forEach((val)=>{
           val.locaiton.forEach((val2)=>{
              allglobal.push(val2)
           })
        })
      }
      shuffleArray(allglobal)
      setGlobalInstructors(allglobal)
      // console.log(allglobal,"allglobal")
      // setGlobalInstructors(res.data[1]);
      //console.log(res.data[1].sub_region[5])
    }
    //console.log(data.data.blog);
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  useEffect(() => {
    getLocation();
  }, [])
  return (
    <div style={{ width: "100%", backgroundColor: "#8019da",textAlign:'center'}}>
      <h3
        style={{
          width: isMobile ? "100%" : "70%",
          margin: "auto",
          padding: "30px",
          color: "white",
        }}
      >
        Find Out our Certified Silva Method Instructors Globally.
      </h3>
      <div
        className="countriescard"
        style={{
          width: "100%",
          backgroundImage: "linear-gradient(#3420e7, #da01fd)",
          margin: "0px 0px 10px 0px",
          padding: "50px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: 
            isBigScreen?"1fr 1fr 1fr 1fr":
            isDesktopOrLaptop
              ? "1fr 1fr 1fr"
              : isTablet
              ? "1fr 1fr"
              : "1fr",
            width: isMobile ? "100%" : "70%",
            gap: "2rem",
            margin: "auto",
            justifyContent: "space-evenly",
          }}
        >
          {globalInstructors.map((country) => (
            <SingleCard key={country.id} name={"SILVA METHOD " + country.country_name} countryId= {country?.id} countrydata={country} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeCountrySection;
