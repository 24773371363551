import React from 'react'
import { Accordion } from 'react-bootstrap'
import CustomAccordion2 from './CustomAccordion2'
import { Link } from 'react-router-dom'
import FAQ2 from '../CoursePage/FAQ2'

function DraganPageFaq() {
  return (
    <div className=''>
      <div className="row">


        {/* <div className="col-sm-12">
          <CustomAccordion2
            title={"WILL THIS COURSE HAVE A LIFETIME ACCESS AFTER I BUY IT ?"}
            text={<p style={{ color: "white" }}>Yes, Once you purchase a course , it will be available in your account for a lifetime. If you buy the <Link to={"/store/course/combo-plan"} style={{ color: "white", textDecoration: "none" }}>combo course</Link> all Silva Method courses will be unlocked for a lifetime.</p>} />
        </div> */}

        {/* <div className="col-sm-12">
          <CustomAccordion2
            title={"WHAT TO DO IF I GET ANY CONFUSION DURING THE COURSE ?"}
            text={<p style={{ color: "white" }}>The course is segregated properly into chapter wise. If you still have confusion, please let us know at digital@silvamethod.com / support@silvamethod.com and we will resolve the issues soon</p>}
          />
        </div> */}

        {/* <div className="col-sm-12">
          <CustomAccordion2 title={"WHAT TO DO IF I CAN’T ENROLL TO THE COURSE ?"}
            text={<p style={{ color: "white" }}>Login to your profile. After that, Go to the course page and Click the “Start Now” button. If you’re having any further issues, drop an email to digital@silvamethod.com</p>} />
        </div> */}
        {/* <div className="col-sm-12">
          <CustomAccordion2 title={"WHAT TO DO IF I CAN’T LOGIN TO THE COURSE ?"}
            text={<p style={{ color: "white" }}>If You haven’t received any login details after the purchase, please drop an email to digital@silvamethod.com concerning the matter.</p>} />
        </div> */}
        {/* <div className="col-sm-12">
          <CustomAccordion2 title={"IS THIS COURSE DOWNLOADABLE ?"}
            text={<p style={{ color: "white" }}>NO. This is a Digital Audio Program and you can listen to it as many times you want in your lifetime by accessing through your profile.</p>}
          />
        </div> */}
      </div>




      <FAQ2
        heading={"Are the techniques from the SLS going to take a lot of my time?"}
        body={<p style={{ color: "white" }}>No. They take very little of your time, from a couple of seconds to a couple of minutes.</p>}
      />
      <FAQ2
        heading={"Are techniques of greater usage of my brain from SLS complicated?"}
        body={<p style={{ color: "white" }}> No. They are quite simple and easy to use. Millions of our graduates around the world are proof of that.</p>}
      />

      <FAQ2
        heading={"Will I have some kind of a SLS manual/workbook where I can find the techniques I need?"}
        body={<p style={{ color: "white" }}>Definitely, yes. You will have access to the SLS Guide, which will include the methodological procedures for every technique from the SLS program.</p>}
      />
      <FAQ2
        heading={"Will there be space for questions during the SLS, if something is not clear to me?"}
        body={<p style={{ color: "white" }}>Definitely, yes. The course is composed in such a way as to allow adequate time for your questions.  </p>}
      />
      
    </div>
  )
}

export default DraganPageFaq