import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";

function BenifitsCards({ data,title }) {
  const { isMobile } = useContext(AuthContext);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column gap-3 my-5 text-center">
      <h2 className="newpageheader text-center">{title}</h2>
      <Row className="container justify-content-center align-items-center gy-3">
        {data.map((val, i) => (
          <Col xs={12} md={6} lg={4}>
            <Card
              style={{
                width: isMobile ? "100%" : "95%",
                height: isMobile ? "auto" : "400px",
                borderRadius: "10px",
              }}
            >
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {val.icon && <span>{val.icon}</span>}
                { val.image && val.image}
                <h3
                  style={{
                    color: "black",
                    fontWeight: "550",
                    fontSize: "1.5rem",
                    textAlign: "center",
                  }}
                >
                  {val.title}
                </h3>
                <p className="normalpara">{val.text}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default BenifitsCards;
