import React from 'react'
import { useNavigate } from 'react-router-dom'
import NinthSectionCard from '../../AdvertisePages/newmembership/NinthSectionCard'
import HeadingNew from '../../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap'
import SpanishBenefitsCard from './SpanishBenefitsCard'

function SpanishBenefits({ }) {
    const navigate = useNavigate()



    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymentsection");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    const data = [
        {
            num: 1,
            para: [
                "Bono #1: Obtendrás el Certificado de Graduado Silva al completar la Masterclass"
            ]
        },
        {
            num: 2,
            para: [
                "Bono #2: Disfrutarás de una sesión gratuita para aclarar dudas con instructores certificados"
            ]
        },
        {
            num: 3,
            para: [
                "Bono #3: Se te otorgará acceso a una vibrante comunidad de WhatsApp para interactuar con otros graduados de Silva "
            ]
        },
        {
            num: 4,
            para: [
                "Bono #4 Obtendrás el manual de Silva para la auto práctica y la aplicación en la vida real"
            ]
        },


    ]
    return (
        <div className='container d-flex justify-content-center align-items-center flex-column my-5'>
            <div className='mx-5 mt-2 mb-2'>
                <HeadingNew title={"Bono incluido solo si te registras en el programa hoy"} />
            </div>


            {/* <button className='styledbtn23'>
                Close your eyes and Imagine:
            </button> */}
            <Row className='justify-content-center align-items-center gy-3 my-4'>
                {
                    data.map((val, i) => (
                        <Col xs={12} md={6}>

                            <SpanishBenefitsCard value={val} fullwidth />
                        </Col>

                    ))
                }
            </Row>
          

            <div className='mt-2 mb-2'>
                <button className='herodiv-becomememberbtn mb-2' onClick={handleScroll}>
                Enlístate ahora
                </button>
            </div>

        </div>
    )
}

export default SpanishBenefits