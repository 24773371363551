import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useContext } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { AuthContext } from "../../../context/AllContext";
import HeadingNew from "../../AboutUs/HeadingNew";
import { IoCloseSharp } from "react-icons/io5";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function ProblemsSection() {
    const { isMobile, isTablet } = useContext(AuthContext);

    const data = [
        "Optas por una ruta diferente al trabajo un día, y luego te enteras de un accidente grave en tu camino habitual.",
        "Sentiste una conexión inmediata con tu pareja y, de alguna manera, supiste que era 'la indicada'",
        "Recordaste instantáneamente dónde dejaste tus llaves sin recordar conscientemente haberlas puesto allí.",
        "Detectaste cuando tu hijo necesitaba ayuda o estaba en problemas, incluso sin comunicación explícita.",
        "Decidiste invertir en una acción o empresa, y resulta ser una decisión acertada."
    ];


    const data2 = [
        "Estrés crónico, problemas de sueño y fatiga.",
        "Patrones de pensamiento que no te dan claridad y emociones negativas",
        "Que eres incapaz de vivir en el presente, agobiado por la culpa del pasado",
        "Que siempre pensaste demasiado en las situaciones y estas con ansiedad",
        "Que no tienes una mentalidad positiva y de crecimiento"
    ]

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymenttwonew");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    return (
        <div style={{
            backgroundImage: `url("/img/bg/bgnew3.jpg")`, // Background image source
            height: "auto",

            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            paddingTop: isMobile ? 20 : 100,
            paddingBottom: isMobile ? 20 : 100,
        }}>
            {/* <div className="d-flex justify-content-center align-items-center" style={{
                marginBottom:isMobile?"170px":"30px"
            }}>
                <div className="my-5 container">
                    <HeadingNew title={"Esta Masterclass es apta para ti si sientes alguna de estas situaciones"} />
                </div>
            </div> */}
            <Row className="container justify-content-center align-items-center">
                <Col xs={12} md={7}>
                    <HeadingNew title={"Esta Masterclass es apta para ti si sientes alguna de estas situaciones"} small start />

                    <Card style={{
                        width:isMobile?"100%":"90%",
                        marginLeft:0,
                        borderRadius:15
                    }} className="my-3">
                        <Card.Body>
                        {
                                data.map((val, i) => (
                                    <div className="d-flex gap-3">
                                        <span>
                                            <HiOutlineExclamationCircle color="#e1ad01" size={25} />
                                        </span>

                                        <p style={{
                                            color: "black",
                                            fontWeight: "500",
                                            lineHeight:"20px"

                                        }}>

                                            {val}
                                        </p>


                                    </div>
                                ))
                            }


                        </Card.Body>

                    </Card>
                    <HeadingNew title={"No son casualidades, sino un signo de tu sentido intuitivo que eventualmente perdemos debido a"} small start />

                    <Card style={{
                        width:isMobile?"100%":"90%",
                        marginLeft:0,
                        borderRadius:15
                    }} className="my-3">

                        <Card.Body>

                        {
                                data2.map((val, i) => (
                                    <div className="d-flex gap-3">
                                        <span>
                                            <IoShieldCheckmarkSharp color="green" size={25} />
                                        </span>

                                        <p style={{
                                            color: "black",
                                            fontWeight: "500",
                                            lineHeight:"20px"
                                        }}>

                                            {val}
                                        </p>


                                    </div>
                                ))
                            }
                        </Card.Body>

                    </Card>
                </Col>
                <Col xs={12} md={5}>
                    <img src="/img/bg/bgspanishnew.jpg" style={{
                        width: "100%",
                        borderRadius:15,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    }} />
                </Col>
            </Row>
        </div>
    );
}

export default ProblemsSection;
