import { Col, Container, Image, Row } from "react-bootstrap";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";

function TFTHero2() {
  const { isMobile, isTablet } = useContext(AuthContext);

  return (
    <section className={`hero-sectionone container py-${isMobile ? 3 : 5}`}>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={5}>
            <div>
              <Image
                src="/img/img_three_finger_technique1.jpg"
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </Col>
          <Col xs={12} lg={7} className={`mb-${isMobile || isTablet ? 2 : 0}`}>
            <div
              className="content-container"
              style={{
                width: isMobile || isTablet ? "97%" : "90%",
                marginLeft: isMobile || isTablet ? "0px" : "40px",
                marginTop: isMobile || isTablet ? "30px" : "0px",
              }}
            >
              <h2 className="newpageheader">The Three Fingers Technique</h2>

              <p
                className="normalpara"
                style={{
                  textAlign: "left",
                }}
              >
                In The Silva Method, this technique plays a central role. By
                gently closing your eyes and using a simple gesture—pressing the
                thumb to the index and middle finger—you can experience a sense
                of tranquility. This gentle action serves as a trigger, guiding
                you into the Alpha State of Mind. The Silva Three Fingers
                technique not only helps in preventing forgetfulness but also
                enhances focus. It is an effective method designed to empower
                you to regain control over your mind. Just like muscle strength,
                memory also improves with consistent and active utilization.
              </p>
              {/* <button
                className="herodiv-becomememberbtn"
                onClick={handleScroll}
              >
                Find out now
              </button> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TFTHero2;
