import React from 'react'
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap'
import { FaClock } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { AuthContext } from '../../context/AllContext';
import { BsCalendar2Date } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";

function BannerBooking() {

    const { isMobile,isTablet } = useContext(AuthContext);

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymenttwonew");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth"
            })
        }
    }
    return (
        <div className="banner">
            <div className='container'>
                {
                    (!isMobile && !isTablet) && (
                        <Row className='justify-content-center align-items-center'>
                            <Col>
                                <div className='d-flex justify-content-center align-items-center flex-column' style={{
                                    borderRight: "1px solid lightgray",

                                }}>
                                    <div className='d-flex justify-content-center align-items-centers gap-3'>
                                        <span>
                                            <BsCalendar2Date size={40} color='white' />
                                        </span>
                                        <div className='d-flex justify-content-center align-items-center flex-column'>
                                            <h5 style={{
                                                margin: "0 10px 0 0",
                                                color: "white",
                                                padding: "0",
                                            }}
                                            >{"20th , 21st , 27th & 28th January (4 hours each day)"}</h5>
                                        </div>
                                    </div>



                                </div>
                            </Col>
                            <Col>
                                <div className='d-flex justify-content-center align-items-center flex-column' style={{
                                    borderRight: "1px solid lightgray"
                                }}>
                                    <div className='d-flex justify-content-center align-items-centers gap-3'>
                                        <span>

                                            <IoTimeOutline size={25} color='white' />
                                        </span>
                                        <div className='d-flex justify-content-center align-items-center flex-column'>
                                            <h5 style={{
                                                margin: "0",
                                                padding: "0",
                                                color: "white",
                                            }}>UK-5:00 P.M</h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='d-flex justify-content-center align-items-center flex-column' style={{
                                    borderRight: "1px solid lightgray"
                                }}>
                                    <div className='d-flex justify-content-center align-items-centers gap-3'>
                                        <span>

                                            <IoTimeOutline size={25} color='white' />
                                        </span>
                                        <div className='d-flex justify-content-center align-items-center flex-column'>
                                            <h5 style={{
                                                margin: "0",
                                                padding: "0",
                                                color: "white",
                                            }}>
                                                US-( New York) 12:00 P.M

                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <button className='herodiv-becomememberbtn' onClick={handleScroll}>
                                        Join The Program
                                    </button>

                                </div>
                            </Col>
                        </Row>
                    )
                }


                {
                    (isMobile || isTablet) && (
                        <div>
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <span>
                                    <BsCalendar2Date size={30} color='white' />
                                </span>
                                <h5 style={{
                                    margin: "0",
                                    padding: "0",
                                    color: "white",
                                    fontWeight: "700"
                                }}>{"20th , 21st , 27th & 28th January (4 hours each day)"}</h5>

                            </div>
                            <div className='d-flex justify-content-center align-items-center gap-2 mt-3'>
                                <span>
                                    <IoTimeOutline size={30} color='white' />
                                </span>
                                <h5 style={{
                                    margin: "0",
                                    padding: "0",
                                    color: "white",
                                    fontWeight: "700"
                                }}>UK-5:00 P.M</h5>
                            </div>
                            <div className='d-flex justify-content-center align-items-center gap-2 mt-3'>
                                <span>
                                    <IoTimeOutline size={30} color='white' />
                                </span>
                                <h5 style={{
                                    margin: "0",
                                    padding: "0",
                                    color: "white",
                                    fontWeight: "700"
                                }}>
                                    US-( New York) 12:00 P.M
                                </h5>
                            </div>
                            <button className='herodiv-becomememberbtn mt-3' onClick={handleScroll}>
                                Join The Program
                            </button>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default BannerBooking