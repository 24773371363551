import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { Card, ListGroup } from 'react-bootstrap';
import { BiChevronRight, BiPlayCircle } from 'react-icons/bi';
import { useContext } from 'react';
import { AuthContext } from '../../context/AllContext';
import { useEffect } from 'react';
import { FaLock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BsCheckCircleFill } from 'react-icons/bs';

const AccordionItem = ({ chapter, isCurrentCoursePurchased }) => {

    const [closed, setClosed] = useState(true);
    const { isMobile } = useContext(AuthContext);
    const navigate = useNavigate()


    console.log(isCurrentCoursePurchased, "isCurrentCoursePurchased")




    return (
        <Card className="p-2 mb-2" style={{
            borderRadius: "10px",
            background: "#fff",
            width: "100%",
        }}>
            <Collapsible
                trigger={
                    <Card.Header
                        className="d-flex justify-content-between align-items-center"
                        style={{
                            background: "transparent",
                            // borderBottom: closed ? "0px solid #ccc" : "1px solid #ccc",
                            // transition: "border-bottom 0.3s ease-in-out"
                            borderBottom: "none",
                            fontSize: isMobile ? "12px" : "15px"
                        }}
                    >
                        <div className="d-flex align-items-center gap-3">
                            <BiChevronRight size={20} style={{
                                transform: closed ? 'rotate(0deg)' : 'rotate(90deg)',
                                transition: 'transform 0.3s ease-in-out'
                            }} />
                            <span style={{
                                color: "black",
                                textAlign: "start"
                            }}>{chapter?.chapter_title}</span>
                        </div>
                    </Card.Header>
                }
                onClose={() => {
                    setClosed(true);
                }}
                onOpen={() => {
                    setClosed(false);
                }}
                transitionTime={200}
            >
                <Card.Body style={{
                    background: "transparent"
                }}>

                    <ListGroup variant="flush" style={{
                        borderTop: "1px solid #ccc",
                        paddingTop: isMobile ? "10px" : "20px",
                    }}>
                        {chapter?.lession.map((item, index) => {
                            console.log(item,"item")
                            return (
                                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center bg-transparent py-3" style={{
                                    cursor: "pointer",
                                    borderBottom: "1px solid black"
                                }}
                                    onClick={() => {
                                        if (isCurrentCoursePurchased || item.preview_available==="Yes") {
                                            navigate(`/store/course/${chapter?.course_id}/${item?.chapter_id}/${item?.lesson_id}`)
                                        } else {
                                            alert("please buy the course first")
                                        }
                                    }}
                                >
                                    {isCurrentCoursePurchased && (
                                        <div className='d-flex gap-3 align-items-center'>
                                            <span>
                                                <BiPlayCircle color='black' size={isMobile ? 20 : 25} />
                                            </span>
                                            <span style={{
                                                fontSize: isMobile ? "12px" : "14px",
                                                textAlign: "start"
                                            }}>{item.lesson_title}</span>
    
                                        </div>
                                    )}
                                    {
                                        isCurrentCoursePurchased === false && item.preview_available==="Yes" && (
                                            <div className='d-flex gap-3 align-items-center'>
                                                <span>
                                                    <BiPlayCircle color='black' size={isMobile ? 20 : 25} />
                                                </span>
                                                <span style={{
                                                    fontSize: isMobile ? "12px" : "14px",
                                                    textAlign: "start"
    
                                                }}>{item.lesson_title}</span>
    
                                            </div>
                                        )
                                    }
    
                                    {
                                        !isCurrentCoursePurchased && (item.preview_available==="No" || !item.preview_available) && (
                                            <div className='d-flex gap-3 align-items-center'>
                                                <span>
                                                    <FaLock color='black' size={isMobile ? 20 : 20} />
                                                </span>
                                                <span style={{
                                                    fontSize: isMobile ? "12px" : "14px",
                                                    textAlign: "start"
    
                                                }}>{item.lesson_title}</span>
    
                                            </div>
                                        )
                                    }
                                    {
                                        item?.lesson_activity_status === "Completed" && (
                                            <BsCheckCircleFill className="scrollto" size={30} color={"#2ecc71"} />
                                        )
                                    }
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Card.Body>
            </Collapsible>
        </Card>
    );
};

const SingleChapterAccordion = ({ courseData, isCurrentCoursePurchased }) => {




    if (!courseData) {
        return null
    }

    return (
        <div className="w-100">

            {courseData?.chapters.map((chapter, index) => (
                <AccordionItem
                    key={index}
                    chapter={chapter}
                    isCurrentCoursePurchased={isCurrentCoursePurchased}
                />
            ))}
        </div>
    );
};

export default SingleChapterAccordion;