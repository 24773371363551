import React from 'react'
import "./habit.css"
function HabitHeroSection() {
  return (
    <div className='herosectionhabit'>
        <h2>Awesome Mobile App for Your</h2>
    </div>
  )
}

export default HabitHeroSection