import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../context/AllContext';

function LoveMindPerkSectionNew() {
    const {isMobile} = useContext(AuthContext);

    const masterclassdata = useMemo(() => {
        return [
            "Who is searching for his/her soulmate",
            "Someone desiring long-lasting relationships",
            "Those who are curious about their connection with the Universe",
            "One who can not balance Work and Personal Life",
            "Want to Improve health, relationships, and other areas of life",
            "Who has a Fixed mindset",
            "Who has self-doubts",
            "Who suffers from stress and anxiety",
            "One who can't get out of his guilt feelings",
            "Who endures restless nights due to Worries",
            "One who is seeking inner peace and happiness"
        ]

    }, [])

    const handleScroll = ()=>{
        const doc = document.querySelector(".lovemindformscroll");
        if(doc){
            doc.scrollIntoView({
                behavior:"smooth"
            })
        }
    }
    return (
        <div className='container'>
            <Row className='container justify-content-center align-items-center' style={{
                position: "relative"
            }}>
                <Col xs={12} md={6} className={isMobile?'order-2':'order-1'}>
                    <HeadingNew title={"Who is this masterclass for?"} start />

                    <Row className='my-3'>
                        {
                            masterclassdata.map((val, i) => (
                                <Col xs={12} md={6}>
                                    <div className='d-flex gap-2 mb-1' style={{
                                        width: "97%"
                                    }}>

                                        <img src='/img/checkicon2.png' width={25} height={25} />
                                        <p style={{
                                            fontSize: "0.9rem",
                                            color: "black",
                                            fontWeight: "500",
                                            margin: "0px"
                                        }}>
                                            {val}
                                        </p>

                                    </div>
                                </Col>


                            ))
                        }
                    </Row>
                    <button className='herodiv-becomememberbtnnew' style={{
                        marginLeft: "0",

                    }}
                    onClick={handleScroll}
                    >
                        Enroll now for free
                    </button>




                </Col>
                <Col xs={12} md={6} className={isMobile?'order-1':'order-2'}>


                    <div className='d-flex justify-content-end align-items-end'>
                        <Tilt style={{
                           display:"flex",
                           justifyContent:"flex-end",
                           alignItems:"flex-end"
                        }}>

                            <img src='/img/lovemind/perkimg1.png' style={{
                                width: "100%",
                            }} />
                        </Tilt>

                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default LoveMindPerkSectionNew