import React from 'react'
import { useMediaQuery } from 'react-responsive'
import HeadingNew from '../AboutUs/HeadingNew'

function BetterSection() {

    const btntexts = [
        "Long Relax Exercise",
        "Three Finger Technique",
        "Transform Stress",
        "Sleep Control Exercise",
        "Alarm Clock Technique",
        "Dream Control Exercise",
        "Healing The Past",
        "Energizing Exercise",
        "Glass Of Water Technique",
        "Headache Relief Exercise",
        "Creation Of Mental Laboratory",
        "Mirror Of The Mind",
        "Actualization Process",
        "Life Purpose Exercise",
        "Money Magnet Exercise",
        "Gratitude Exercise",
        "Target Image Exercise",
        "Money Magnet Exercise",
        "Integrating Greatness",
        "Relaxation Exercise",
        "Awareness Exercise",
        "Changing Limiting Belief",
        "Projection Exercise",
        "Eternal Garden Exercise",
        "Holo-Viewing Technique",
        "Exercise On Psychometry",
        "Caseworking"
    ]
    return (
        <div className="application container">
             
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <p className='mt-4 text-center' style={{ fontSize: "1.4rem", color: "black", fontWeight: "600" }}>Normal is Boring!</p>
                
                <div style={{padding:"0 12px"}}>
                <HeadingNew title={"Be extraordinary with The Silva Method Exercises."} />
                </div>
                {/* <div className="col-sm-6 col-md-3">
                    <img className='mx-2' style={{ width: isDesktopOrLaptop ? "100%" : "98%", marginBottom: isDesktopOrLaptop ? "0" : "10px" }} src="https://storyblok-cdn.The Silva Method.com/f/60579/1600x1200/3cf5d3a177/mvcom_hp_outcome_01.jpg?trans?quality=40&auto=webp&dpr=1&width=300" alt="" />
                </div>
                <div className="col-sm-6 col-md-3">
                    <img className='mx-2' style={{ width: isDesktopOrLaptop ? "100%" : "98%", marginBottom: isDesktopOrLaptop ? "0" : "10px" }} src="https://storyblok-cdn.The Silva Method.com/f/60579/1600x1200/3cf5d3a177/mvcom_hp_outcome_01.jpg?trans?quality=40&auto=webp&dpr=1&width=300" alt="" />
                </div>
                <div className="col-sm-6 col-md-3">
                    <img className='mx-2' style={{ width: isDesktopOrLaptop ? "100%" : "98%", marginBottom: isDesktopOrLaptop ? "0" : "10px" }} src="https://storyblok-cdn.The Silva Method.com/f/60579/1600x1200/3cf5d3a177/mvcom_hp_outcome_01.jpg?trans?quality=40&auto=webp&dpr=1&width=300" alt="" />
                </div>
                <div className="col-sm-6 col-md-3">
                    <img className='mx-2' style={{ width: isDesktopOrLaptop ? "100%" : "98%", marginBottom: isDesktopOrLaptop ? "0" : "10px" }} src="https://storyblok-cdn.The Silva Method.com/f/60579/1600x1200/3cf5d3a177/mvcom_hp_outcome_01.jpg?trans?quality=40&auto=webp&dpr=1&width=300" alt="" />
                </div> */}
            </div>
            <div className="py-4">
                <div style={{ maxHeight: "450px", overflowY: "scroll" }} className="scrollable d-flex justify-content-center gap-4 align-items-center flex-wrap">
                    {
                        btntexts.map((text, i) => (
                            <span className='badge rounded-pill py-2 px-4 roundedpilldata'>
                                {text}
                            </span>
                        ))
                    }
                </div>
            </div>
            {/* <h3 className='text-center text-dark mt-4'>And hundreds more areas of transformation.</h3> */}

        </div>
        </div>

    )
}

export default BetterSection