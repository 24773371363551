import React from 'react';
import { useContext } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';
import { AuthContext } from '../../../../context/AllContext';

const SleepDeprivationSection = () => {
    const {isMobile} = useContext(AuthContext);

    return (
        <div className="sleep-deprivation-section container">
            <Row className="px-4 align-items-start justify-content-center">
                <Col md={6} className="left-column">
                <h3 style={{
                        color: "black",
                        fontSize: isMobile ? "1.5rem" : "2rem"
                    }}
                        className='lora-sans'
                    >
                      Sleep Deprivation Statistics
                    </h3>
                    <div className="image-wrapper">
                        <img
                            src="/img/bg/blogpages/5-Sleep Deprivation Statistics/sleep-meditation.jpg"
                            alt="Sleep Deprivation"
                            className="section-image"
                        />
                    </div>
                    <p className="section-description mt-3 robotofont">
                        An estimated 20% of adults experience sleep deprivation, meaning they aren’t getting sufficient or what we call quality rest.
                    </p>
                    
                    <p className="section-content robotofont">
                                Common causes are:
                                <ul>
                                    <li><b>Voluntary habits</b>: Behaviorally induced insufficient sleep syndrome.</li>
                                    <li><b>Personal responsibilities</b>: Caring for others or managing commitments.</li>
                                    <li><b>Work schedules</b>: Jobs with long or irregular hours.</li>
                                    <li><b>Health conditions</b>: Chronic illnesses or sleep disorders.</li>
                                </ul>
                                Lack of sleep often results in excessive daytime drowsiness, mood swings, irritations, and less productivity. One of the most serious risks is drowsy driving, which significantly increases the chances of accidents and injuries.
                            </p>
                </Col>
                <Col md={6} className="right-column">
                  
                    <div className="chart-wrapper">
                        <PieChart
                            style={{
                                width: "70%",
                                height: "70%"
                            }}
                            data={[
                                { title: 'Sleep Deprived (20%)', value: 20, color: 'red' },
                                { title: 'Adequate Sleep (80%)', value: 80, color: '#36A2EB' },
                            ]}
                            animate
                            lineWidth={30}
                            label={({ dataEntry }) => `${dataEntry.title}`}
                            labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#000',
                            }}
                            radius={42}
                            labelPosition={70}
                        />
                        {/* Pie Chart Data Labels */}
                        <div className="chart-legend">
                            <div className="legend-item">
                                <span className="legend-color" style={{ backgroundColor: 'red' }}></span>
                                <span className="legend-text">Sleep Deprived (20%)</span>
                            </div>
                            <div className="legend-item">
                                <span className="legend-color" style={{ backgroundColor: '#36A2EB' }}></span>
                                <span className="legend-text">Adequate Sleep (80%)</span>
                            </div>
                        </div>
                    </div>
                  
                </Col>
            </Row>
        </div>
    );
};

export default SleepDeprivationSection;
