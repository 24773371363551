import React, { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";

function HomeHeading2({ title="", colored="", title2="",small=false,center=false }) {
  const {
    isTablet,
    isMobile,
  } = useContext(AuthContext);

  return (
    <>
      <h2
        style={{
          fontWeight: "800",
          fontSize: small?(isMobile || isTablet)?"25px":"25px":(isMobile || isTablet)?"30px":"50px",
          textAlign: center?"center":"start",
        }}
      >
        {title}{" "}
        <span
          style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}
        >
          {colored}
        </span>
       {" "}{title2}
      </h2>
    </>
  );
}

export default HomeHeading2;
