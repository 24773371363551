import React from 'react';
import { useNavigate } from 'react-router-dom';

const SilvaMethodSection = () => {
  const navigate = useNavigate()
  return (
    <div className="silva-container py-5">
      {/* Background decoration */}
      <div className='container'>
      <div className="background-decoration">
        <div className="decoration-circle top"></div>
        <div className="decoration-circle bottom"></div>
      </div>

      <div className="content-wrapper">
        {/* Main content container */}
        <div className="main-grid">
          
          {/* Left column - Image */}
          <div className="image-container">
            <div className="image-background"></div>
            <img 
              src="/img/stress/last section image.webp" 
              alt="Meditation and mindfulness" 
              className="feature-image"
            />
          </div>

          {/* Right column - Content */}
          <div className="content-column">
            <div>
            <h2 className="heading-large text-start text-black mb-0">
              The Silva Method
             
            </h2>
            <h2 className="heading-medium text-start text-black mt-0 mb-2">
                Learn The Best Coping Skills for Stress & Anxiety
              </h2>
              <div className=" mt-0">
              <p className='robotofont text-black text-start'>
              The Silva Method – America’s first mind empowerment program, developed by José Silva in the 1960s. For over 50 years, the Silva Method has been changing lives around the world, helping more than 12 million people find their calm, feel balanced, and take control of stress. This self-help mindfulness method offers some of the most effective techniques for stress relief. When it comes to the most popular Silva Techniques for stress relief, it’s clear: Long Relax, Short Relax, and the Three Fingers Technique are the top favorites.
              </p>
              <p className='robotofont text-black text-start'>
              The Silva Method is not just another mindfulness program; it’s a proven approach to living better, every day.


              </p>
            </div>
            </div>
          

            {/* Features */}
            <div className="features-grid">
              <div className="feature-card">
                <svg className="feature-icon" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="currentColor" d="M2 21v-2h2V4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v15h2v2H2zm5-2h10V5H7v14zm2-12h6v2H9V7zm0 4h6v2H9v-2z"/>
                </svg>
                <h3>Long Relax</h3>
              </div>
              <div className="feature-card">
                <svg className="feature-icon" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="currentColor" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
                </svg>
                <h3>Short Relax</h3>
              </div>
              <div className="feature-card">
                <svg className="feature-icon" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="currentColor" d="M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z"/>
                </svg>
                <h3>Three Fingers</h3>
              </div>
            </div>

            {/* CTA Section */}
            <div className="cta-section mt-3">
              <h3 className='heading-medium text-start text-white'>Enroll now in the Silva Method Complete Program</h3>
              <p className='robotofont text-white'>Enjoy Lifetime Access to all Silva Method courses, along with Weekly Free Live Interactive Classes led by certified instructors!</p>
              <button className="cta-button" onClick={()=>{
                navigate("/store/course/combo-plan")
              }}>
                <span>Enroll Now</span>
                <svg className="arrow-icon" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="currentColor" d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>

      <style>{`
        .silva-container {
         background: linear-gradient(135deg, #f5f3ff 0%, #ede9fe 100%);
        }

      

        .decoration-circle {
       
        }

        .decoration-circle.top {
          top: -10rem;
          right: -10rem;
          background: rgba(167, 139, 250, 0.2);
        }

        .decoration-circle.bottom {
          bottom: -10rem;
          left: -10rem;
          background: rgba(99, 102, 241, 0.2);
        }

        .content-wrapper {
          position: relative;
          max-width: 72rem;
          margin: 0 auto;
          z-index: 2;
        }

        .main-grid {
          display: grid;
          grid-template-columns: 1fr;
          gap: 4rem;
        }

        @media (min-width: 1024px) {
          .main-grid {
            grid-template-columns: 1fr 1fr;
            align-items: center;
          }
        }

        .image-container {
          position: relative;
          group-transition: transform 0.3s ease;
        }

        .image-background {
          position: absolute;
          inset: 0;
          background: #ddd6fe;
          border-radius: 1rem;
          transform: rotate(3deg);
          transition: transform 0.3s ease;
        }

        .image-container:hover .image-background {
          transform: rotate(6deg);
        }

        .feature-image {
          position: relative;
          width: 95% !important;
          height: auto;
          border-radius: 1rem;
          transform: rotate(-3deg);
          transition: transform 0.3s ease;
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
        }

        .image-container:hover .feature-image {
          transform: rotate(-6deg);
        }

        .content-column {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .main-title {
          font-size: 2.25rem;
          font-weight: 700;
          color: #1f2937;
          line-height: 1.2;
        }

        .subtitle {
          display: block;
          color: #7c3aed;
          margin-top: 0.5rem;
        }

        .description {
          color: #4b5563;
          font-size: 1.125rem;
          line-height: 1.7;
        }

        .features-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 1.5rem;
        }

        .feature-card {
          background: white;
          padding: 1rem;
          border-radius: 0.75rem;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease;
        }

        .feature-card:hover {
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
        }

        .feature-icon {
          color: #7c3aed;
          margin-bottom: 0.5rem;
        }

        .feature-card h3 {
          font-weight: 600;
          color: #1f2937;
          margin: 0;
        }

        .cta-section {
          background: linear-gradient(to right, #7c3aed, #6366f1);
          padding: 1.5rem;
          border-radius: 1rem;
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
          color: white;
        }

        .cta-section h3 {
          font-size: 1.25rem;
          font-weight: 600;
          margin: 0 0 1rem 0;
        }

        .cta-section p {
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 1.5rem;
        }

        .cta-button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background: white;
          color: #7c3aed;
          padding: 0.75rem 2rem;
          border: none;
          border-radius: 0.5rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        }

        .cta-button:hover {
          background: whitesmoke;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
        }

        .cta-button:hover .arrow-icon {
          transform: translateX(4px);
        }

        .arrow-icon {
          transition: transform 0.3s ease;
        }
      `}</style>
    </div>
  );
};

export default SilvaMethodSection;