import React, { useContext } from 'react'
import { useState } from 'react';
import ComboCourseHeader from '../ComboCourse2/ComboCourseHeader';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import { AiOutlineClose } from "react-icons/ai"
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import HeadingNew from '../../AboutUs/HeadingNew';
import { Col, Row } from 'react-bootstrap';
import SidebarPanel from '../ComboCourse2/SidebarPanel';
import { FaPlay } from "react-icons/fa";
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../context/AllContext';


function SecondHypnosisHero() {
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false)

    const { isMobile,isTablet } = useContext(AuthContext);


    const handleScroll = ()=>{
        const card = document.querySelector(".includelivecheck")
        if(card){
            card.scrollIntoView({
                behavior:"smooth"
            })
        }
      }
    return (
        <div className='benifits_image_bg' style={{
            paddingTop:50
        }}>
            {/* <ComboCourseHeader setShow={setShow} white={true} /> */}

            <SidebarPanel show={show} setShow={setShow} />
            <div className='py-5 container'>
                <Row className='container justify-content-center align-items-center'>
                    <Col xs={12} lg={6} className='itemstoshow'>
                        {/* <HeadingNew small white title={"Self-Hypnosis"} start /> */}
                        <HeadingNew white title={"Activate Your Money Magnetism to Attract Financial Abundance"} start />

                        {
                            (isMobile || isTablet) && (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }} className='mt-3'>
                                    <Tilt style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }} tiltEnable={true} glareMaxOpacity={0.2} glareColor="#000" glarePosition="all"
                                        glareBorderRadius="20px"
                                        reset={true}
                                    >
                                        <img src='/img/hypnosis/moneymagnet.jpg' style={{
                                            width: isMobile ? "100%" : "90%",
                                            borderRadius: 15,
                                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"
                                        }} />
                                    </Tilt>
                                </div>
                            )
                        }
                        <ParaComp
                            data={[
                                "Do financial worries keep you up at night and cast a shadow over your dreams and aspirations? It's time to break free from the cycle of stress and uncertainty. Discover the power of your mind to attract financial abundance with the Silva Method."
                            ]}
                            white={true}

                        />

                        <div className='d-flex justify-content-start align-items-center'>
                            <button className='btnnew22' onClick={handleScroll}>
                                <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                        fill="black"
                                    ></path>
                                </svg>
                                <span>Enroll Now</span>
                            </button>


                            {/* <div className="sonar-wrapper">
                                <div className="sonar-emitter" style={{
                                    cursor: "pointer"
                                }}
                                    onClick={() => {
                                        setOpen(true)
                                    }}
                                >
                                    <span style={{

                                    }}>
                                        <FaPlay color='#254470' size={25} />
                                    </span>
                                    <div className="sonar-wave">

                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </Col>
                    {
                        (!isMobile && !isTablet) && (
                            <Col xs={12} lg={6} style={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end"
                            }} className='itemstoshow2'>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end"
                                }}>
                                    <Tilt style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end"
                                    }} tiltEnable={true} glareMaxOpacity={0.2} glareColor="#000" glarePosition="all"
                                        glareBorderRadius="20px"
                                        reset={true}
                                    >
                                        <img src='/img/hypnosis/moneymagnet.jpg' style={{
                                            width: isMobile ? "100%" : "90%",
                                            borderRadius: 15,
                                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"
                                        }} />
                                    </Tilt>
                                </div>
                            </Col>
                        )
                    }

                </Row>

            </div>
            <Lightbox

                plugins={[Video, Fullscreen]}
                open={open}
                close={() => setOpen(false)}
                render={{
                    iconClose: () => <AiOutlineClose size={40} color='#fff' />,
                    iconEnterFullscreen: () => <BsArrowsFullscreen size={40} color='#fff' />,
                    iconExitFullscreen: () => <BsFullscreenExit size={40} color='#fff' />,
                }}
                slides={[
                    {
                        type: "video",
                        width: 1280,
                        height: 720,
                        poster: require("../../../images/frame.webp"),
                        autoPlay: true,
                        sources: [
                            {
                                src: "https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4",
                                type: "video/mp4"
                            }
                        ]
                    },
                    // ...
                ]}
            // ...
            />
        </div>
    )
}

export default SecondHypnosisHero





