import React, { useContext, useMemo } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import Button from '../One/Button'
import { AuthContext } from '../../../context/AllContext'

const ProblemSectionCard = ({ img, title, text }) => {
  const { isMobile } = useContext(AuthContext);

  return (
    <Card style={{
      width: isMobile ? "90%" : "95%",
      height: isMobile ? "470px" : "450px",
      borderRadius: "15px",
      position: "relative"
    }}>
      <img src={img} style={{
        width: "100%",
        height: "100%",
        position: "absolute",

        objectFit: "cover",
        top: "0px",
        borderRadius: "15px"
      }} />

      <div style={{
        position: "absolute",
        bottom: "0px",
        paddingLeft: "20px",
        paddingRight: "10px",
        paddingBottom: "15px"
      }}>
        <h3 style={{
          color: "white",
          fontWeight: "600"

        }}>{title}</h3>
        <p className='white-color cardlovemindproblem' style={{
          maxHeight: 100,
          overflowY: "scroll",
         
        }}>
          {text}
        </p>
      </div>


    </Card>
  )

}

function LoveMindProblemsCard({onClick}) {
  const handleScroll = ()=>{
    const doc = document.querySelector(".lovemindformscroll");
    if(doc){
        doc.scrollIntoView({
            behavior:"smooth"
        })
    }
}
  const data = useMemo(() => {
    return [
      {
        title: "Parent-Child",
        text: "Unhealthy parent-child relationships can stem from a lack of communication, trust, boundaries, or unresolved issues from the past, leading to misunderstandings and tension. The Unique techniques of the Love Mind upgrade relationships to a new level and foster healthier connections.",
        img: "/img/lovemind/cardimgs/1.png",

      },
      {
        title: "Siblings",
        text: "While a certain level of sibling rivalry is expected and natural when it becomes excessive, it can lead to detrimental effects on the mental and emotional well-being of individuals involved, as well as strain family dynamics. Resolve sibling conflict with Love Mind.",
        img: "/img/lovemind/cardimgs/2.png",
      },
      {
        title: "Husband-wife",
        text: "Dysfunctional husband-wife relationships can be improved with the Love Mind. It fosters better communication, emotional understanding, and unity to navigate conflicts and create harmony and fulfillment.",
        img: "/img/lovemind/cardimgs/3.jpg",
      },
      {
        title: "Romantic partner",
        text: "Toxic partner relationships result from a lack of respect, communication, trust, and boundaries. Negative behaviors like manipulation, control, jealousy, and abuse can intensify issues. Get access to the Love Mind and transform your love life with happiness.",
        img: "/img/lovemind/cardimgs/4.png",
      },
      {
        title: "Self-love",
        text: "Self-love is the most crucial relationship in one's life. If you don't feel good about yourself, it's hard to show love to others. Nurture self-love with Love Mind, which will boost your self-esteem and lead to a happier, more fulfilling life.",
        img: "/img/lovemind/cardimgs/5.png",
      },
      {
        title: "Professional connection",
        text: "The Love Mind will boost your confidence and communication skills while changing negative thinking habits. It will develop a mindset focused on growth and positivity, which will help improve your professional relationships through genuine care and understanding.",
        img: "/img/lovemind/cardimgs/6.png",
      },
      {
        title: "Friendship",
        text: "The Love Mind can enhance friendship by promoting positivity, empathy, and effective communication, fostering deeper connections and mutual understanding among friends.",
        img: "/img/lovemind/cardimgs/7.png",
      },
      {
        title: "Universal connection",
        text: "The Love Mind will teach you how to show unconditional love to all existence. That fosters compassion, unity, and understanding, transcending differences and nurturing interconnectedness. It cultivates empathy, peace, and a sense of belonging, enriching the human experience profoundly.",
        img: "/img/lovemind/cardimgs/8.jpg",
      },

    ]

  }, [])
  return (
    <div className='container py-5'>
      <HeadingNew title={"Relationship Challenges Addressed by the"} colored={"Love Mind"} />

      <Row className='justify-content-center align-items-center my-4 gy-2'>
        {
          data.map((val, i) => (
            <Col xs={12} md={4} lg={3}>
              <div className='d-flex justify-content-center align-items-center'>
                <ProblemSectionCard img={val.img}
                  title={val.title}
                  text={val.text} />
              </div>
            </Col>
          ))
        }




      </Row>

      <div className='d-flex justify-content-center align-items-center'>
        <Button text={"Reserve your spot now"} onClick={onClick || handleScroll} />

      </div>



    </div>
  )
}

export default LoveMindProblemsCard