import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Badge} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsCheckCircleFill } from "react-icons/bs"
import { HiOutlineArrowRight } from "react-icons/hi"
import { AiFillLock } from "react-icons/ai"
import { AuthContext } from '../../../context/AllContext';






function CustomCourseAccordion2({ data, index, isCurrentCoursePurchased }) {
    // const [activeIndex, setActiveIndex] = useState();
    const location = useLocation();
    const [isSubscribed, setIsSubscribed] = useState(false);
    //console.log(data.lession);
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    const { userData, isMobile } = useContext(AuthContext);
    useEffect(() => {
        if (userData) {
            if (userData.enrollment_status === "Enrolled") {
                setIsSubscribed(true)
            }
        }
    }, [userData])


    if (data && data.chapter_id) {
        return (
            <Accordion defaultActiveKey={0}>
                <Accordion.Item eventKey={index} className='mb-4'>
                    <Accordion.Header style={{ height: "100%" }}>
                        <div className='d-flex justify-content-start gap-3 align-items-center'>
                            {/* <h4 className='white-color'>{data && data.course_id}</h4> */}
                            <button className='courseindexbtn courseindexbtnnew'>
                                {index + 1 < 10 ? "0" + (index + 1) : index + 1}
                            </button>
                            <h6 className='accordionheader mt-2'>{data && data.chapter_title}</h6>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='listlesson'>

                        <li>
                            {
                                data && data.lession.map((val, idx) => (


                                    <ul className={`d-flex justify-content-between mt-3 pb-2`}
                                        style={{ borderBottom: "2px solid gray" }}>

                                        <div className='d-flex justify-content-start align-items-start gap-3'>
                                            <p style={{ fontSize: isDesktopOrLaptop ? "1rem" : "0.8rem",fontWeight:"600" }}>{val.lesson_title}</p>
                                            {/* <p style={{ color: "black", fontSize: "0.8rem",fontWeight:"600" }}>{val.lession_file_duration}</p> */}

                                        </div>




                                        {/* <div className={}>
                                            
                                           
                                        </div> */}
                                        {
                                            (isCurrentCoursePurchased || val.preview_available === "Yes") ? (
                                                <div className={`d-flex justify-content-center align-items-center gap-4 ${isDesktopOrLaptop ? "" : "flex-column"}`}>
                                                    <Badge className='badgenew' bg="light" style={{ color: "black" }} onClick={() => navigate(`/store/course/${data.course_id}/${data.chapter_id}/${val.lesson_id}`, { state: val })}>
                                                        {
                                                            isSubscribed ? "View" : "Preview"
                                                        }
                                                    </Badge>
                                                    {/* {JSON.stringify(val)} */}


                                                    {
                                                        val?.lesson_activity_status === "Completed" && (
                                                            <BsCheckCircleFill className="scrollto" size={30} color={"#2ecc71"} />
                                                        )
                                                    }

                                                    {
                                                        val?.lesson_activity_status !== "Completed" && (
                                                            <HiOutlineArrowRight size={30} color='#000'
                                                                onClick={() => navigate(`/store/course/${data.course_id}/${data.chapter_id}/${val.lesson_id}`, { state: val })} />
                                                        )
                                                    }

                                                </div>
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <AiFillLock size={30} color='#000' />
                                                </div>
                                            )
                                        }
                                    </ul>
                                ))
                            }
                        </li>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }
}

export default CustomCourseAccordion2