import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import SpanishHero from './SpanishHero'
import SpanishClassTable from './SpanishClassTable';
import Featured from '../../../components/featured/Featured';
import SpanishBannerSection from './SpanishBannerSection';
import RightLeftSectionSpanish from './RightLeftSpanish';
import EleventhSectionCustom from './WhoisprogramFor';
import SpanishBannerSectionNew from './SpanishBannerSectionNew';
import CustomFooter from '../../Home/Home/HomeFooter/Footer';
import SpanishLearnSection from './SpanishLearnSection';
import SpanishBannerSectionNew2 from './SpanishBanner2';

function SpansihLanding() {

  const classesData = [
    {
      live_class_id: 1,
      title: "Class 1",
      days: [
        {
          date: "24th Feb 2024",
          content: [
            "Long-Relaxation Exercise",
            "Sleep Control, Awake Control, and Alarm Clock Technique",
            "Mental Screening",
            "3 Scene Technique",
          ],
        },
        {
          date: "2nd March 2024",
          content: [
            "3- Finger Technique",
            "Long-Relaxation Exercise	3- Finger Technique	Home Projection	Connection with Purpose",
            "Mirror of the Mind",
            "Hand Levitation and Glove Anesthesia",
            "Glass of Water and Habit Control Technique",
          ],
        },
        {
          date: "9th March 2024",
          content: [
            "Home Projection",
            "Mental Projection",
            "Plant Projection",
            "Projection on Pet",
          ],
        },
        {
          date: "16th March 2024",
          content: [
            "Connection with Purpose",
            "Laboratory Making",
            "Human Anatomy",
            "Human Projection",
          ],
        },
      ],
    },
  ];


  return (
    <div>
      <HomeHeader />
      <SpanishHero />
      <Featured />

        <div className='parallexnew23'>
        <SpanishBannerSection />
          
        </div>
        <div className='parallexnew22'>

        <RightLeftSectionSpanish />
        <EleventhSectionCustom smallTitle={"Who is this Free Program for?"}
          or

          text={"If you're intrigued by the Silva Method or eager to explore its wonders, this beginner's Masterclass is perfect!"}
          title={"Are you someone, Who just wants to"}
        />
        <div className='container my-5'>
          <SpanishBannerSectionNew />

        </div>
        <SpanishLearnSection />
      </div>
      <div className='container'>
        <SpanishBannerSectionNew2 />

      </div>



      {/* <div className='container'>
        <SpanishClassTable classesData={classesData}/>
        </div> */}
      <CustomFooter />
    </div>
  )
}

export default SpansihLanding