import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadingNew from "../../AboutUs/HeadingNew";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";

function FirstSection() {
  const { isMobile } = useContext(AuthContext);


  const handleScroll = () => {
    const descard = document.querySelector('includelivecheck');

    if (descard) {
      descard.scrollIntoView({
        behavior: "smooth",
      });
    }
  };


  return (
    <Row className="mx-2 mx-md-0 justify-content-center align-items-center">
      <Col
        xs={12}
        md={6}
        className={`${isMobile ? "order-2 mt-3" : "order-1"}`}
      >
        <HeadingNew
          title={
            "Why Choose the Silva Method for Spiritual Healing and Growth?"
          }
          start={!isMobile}
          small
        />
        <p className={isMobile ? "text-center my-2" : "text-start my-4"}>
          The Silva Method stands at the forefront of spiritual education,
          uniquely blending cutting-edge scientific techniques with ancient
          wisdom to unlock your inherent healing potential and spiritual growth.
          Recognized globally as one of the top spiritual healing courses
          online, the Silva Method equips you with powerful techniques and tools
          to achieve profound emotional balance, mental clarity, and a deeper
          connection to your spiritual essence. Our courses are designed to
          guide you on how to learn spirituality effectively, making it easy for
          beginners and enriching for seasoned practitioners alike. Embrace the
          journey towards holistic well-being with the Silva Method, and
          discover why our spiritual training is the pathway to transforming
          your life.
        </p>
        {isMobile ? (
          <div className="d-flex justify-content-center align-items-center">
            <button
              className="primary_btn3"
              style={{
                alignSelf: "center",
              }}
              onClick={handleScroll}
            >
              Enroll Now
            </button>
          </div>
        ) : (
          <button
            className="primary_btn3"
            style={{
              alignSelf: "center",
            }}
            onClick={handleScroll}
          >
            Enroll Now
          </button>
        )}
      </Col>
      <Col xs={12} md={6} className={`${isMobile ? "order-1" : "order-2"}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <img
            src="/img/spiritual-healing/2nd-sec-img.png"
            style={{
              width: "95%",
              height: "95%",
              borderRadius: 20,
            }}
          />
        </div>
      </Col>
    </Row>
  );
}

export default FirstSection;
