import React, { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import NewHomeSlider from "../NewHomeSlider";
// import {AiFillCheckCircle} from "react-icons/ai";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HomeHeading from "./HomeHeading";
function SilvaBenefits() {
  const navigate = useNavigate();
  const { isDesktopOrLaptop } =
    useContext(AuthContext);


  const listyles = {
    fontWeight: "normal",
    color: "#000",
    marginBottom: "15px"
  }


  return (
    <div className="mt-5">
      <div className="text-center container">
        <HomeHeading title="Benefits of " colored="Mind Control Meditation" title2=" - Jose Silva Technique"/>
        <h5 className="mt-3 mb-3">
          Over 12 million people in 110 countries have experienced what Silva Method Meditation can do.
        </h5>
      </div>

      <Row className="justify-content-center align-items-center align-content-center">
        <Col xs={11} sm={11} md={11} lg={11} xl={5} >
          <div className={isDesktopOrLaptop ? "w-75 m-auto p-2 mt-3" : "w-80 m-auto p-2 mt-3"}>
            <div className="text-center mt-3">

              {/*  */}
              <div className="bulletdiv">
                <ul className="text-start bulletli">
                  <li className="one" style={listyles}>
                    <span><BsFillPatchCheckFill color="#801cb6" /></span> Improved focus, clarity, and mental acuity
                  </li>
                  <li className="one" style={listyles}>
                    <span><BsFillPatchCheckFill color="#801cb6" /></span>	Enhanced problem-solving skills and creative thinking
                  </li>
                  <li className="one" style={listyles}>
                    <span><BsFillPatchCheckFill color="#801cb6" /></span> Reduced stress, increased relaxation, and improved overall well-being
                  </li>
                  <li className="one" style={listyles}>
                    <span><BsFillPatchCheckFill color="#801cb6" /></span> Heightened intuition, self-awareness, and enhanced decision-making capabilities
                  </li>
                  <li className="one" style={listyles}>
                    <span><BsFillPatchCheckFill color="#801cb6" /></span> Empowerment to tap into your extraordinary potential and manifest desired outcomes
                  </li>
                  <li className="one" style={listyles}>
                    <span><BsFillPatchCheckFill color="#801cb6" /></span> Get peaceful and sound sleep, and say goodbye to sleepless nights.
                  </li>
                  <li className="one" style={listyles}>
                    <span><BsFillPatchCheckFill color="#801cb6" /></span> Ability to build fulfilling relationships and maintain healthier bonds in existing ones.
                  </li>
                </ul>
              </div>

              {/*  */}

              <button className="styledbtn23" onClick={() => navigate("/silva-method-courses")}>Get Access to All Courses</button>
            </div>
          </div>
        </Col>
        <Col xs={11} sm={11} md={11} lg={11} xl={6} >
          <NewHomeSlider />
        </Col>
      </Row>
    </div>
  );
}

export default SilvaBenefits;
