import React, { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'

function HeadingNew2({title, colored,small=false,boxed=false,boxedtext="",white=false,centered=true}) {
    const {isBigScreen,isDesktopOrLaptop,isTablet,isMobile} = useContext(AuthContext)

    return (
        <h2
            style={{
                fontWeight: "800",
                fontSize: isBigScreen ?"30px" : isDesktopOrLaptop ?"25px" : isTablet ?"20px" :"19px",
                textAlign: centered?"center":"start",
                color: white ? "white" : "#000"
            }}
        >

            {title} <span style={{ color: "#d801fb", fontWeight: "800", fontFamily: "Poppins" }}>{colored}</span>{" "}
            {
                boxed && (
                    <span style={{
                        padding: "10px 14px", color: "#d801fb",
                        border: "none", backgroundColor: "white",
                        fontSize: isBigScreen ?"30px" : isDesktopOrLaptop ?"25px" : isTablet ?"20px" :"19px",
                        fontWeight: "bolder",
                        borderRadius: "8px"
                    }}>
                        {
                            boxedtext
                        }
                    </span>
                )
            }
        </h2>
    )
}

export default HeadingNew2