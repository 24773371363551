import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AboutCard from "../../AboutUs/AboutCard";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import HeadingNew2 from "../../booking/HeadingNew2";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import HeadingNew from "../../AboutUs/HeadingNew";

function HarnessSection({ data }) {
  const { isDesktopOrLaptop } = useContext(AuthContext);

  return (
    <>
      <div className="parallexabout2 mt-3">
        {/* <div className="paradivabout mb-5">
          <h4
            className="white-color"
            style={{ fontFamily: "Poppins", textAlign: "center" }}
          >
            Harness the Power of Self-Healing, Distance Healing, and Help Heal
            Others
          </h4>
        </div> */}
        <HeadingNew
          title={
            "Harness the Power of Self-Healing, Distance Healing, and Help Heal Others"
          }
          white
          small
        />
        <Container>
          <Row>
            {data.map((el, i) => (
              <Col sm={12} md={6} lg={4} className="p-3">
                <Card
                  style={{
                    width: "100%",
                    height: "450px",
                    padding: "10px",
                    borderRadius:'15px'
                  }}
                >
                  <div className="mb-2">
                    <Image src={el.image || "/img/bgnew.jpg"} style={{borderRadius:'15px'}}/>
                  </div>
                  <div>
                    <h5 className="text-black text-center">{el.title}</h5>
                  </div>
                  <div className="px-2">
                    <p style={{lineHeight:'1.5rem'}}>{el.text}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        {/* <Row
          style={{
            width: "100%",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          

          {data?.map((el, i) => (
            <Col sm={12} lg={isDesktopOrLaptop? 5:3} md={12}>
              <Card style={{width:'100%',height: isDesktopOrLaptop? '400px':'450px', padding:'10px'}}>
                <Row>
                    <Col sm={12} md={12} lg={12} className="d-flex justify-content-center align-items-center">
                    <div className="bluebox">
                    <div className="innerdiv">
                      <Image src={el.img} />
                    </div>
                  </div>
                    </Col>
                    <Col sm={12} md={12} lg={12}>
                    <div className="abotparawrapper">
                    <HeadingNew2
                      title={el?.title || ""}
                    //   colored={colored || ""}
                      centered={false}
                      small
                    />
                    <ParaComp margin={false} white={false} data={[el?.text]} />
                  </div>
                    </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row> */}
      </div>
    </>
  );
}

export default HarnessSection;
