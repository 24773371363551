import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import { Col, Image, Row } from 'react-bootstrap'
import DuartionCard from './DuartionCard'
import { MdOutlineTimer, MdPhoneAndroid } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";

function CourseDuration({ data }) {


    return (
        <div className='d-flex justify-content-center align-items-center flex-column gap-3'>
            <HeadingNew
                small
                title={"Take charge of your"}
                colored={"Mind"}
            />
       
            <div className='my-4'>
                <Row>
                    <Col md={6} lg={4}>
                        <DuartionCard title={"Duration"}
                            icon={<MdOutlineTimer size={50} color='orange' />}
                            br={true}
                            text={["8 Days, Via Zoom live (30+ Hours)", "4 Days , For In-person classes (30+ Hours)"]} />
                    </Col>
                    <Col md={6} lg={4}>
                        <DuartionCard title={"Validity"}
                            icon={
                                <FaCalendarAlt size={50} color='orange' />
                            }
                            br={true}

                            text={["After you will become a Silva Graduate, You can join every week the Silva Graduate meets for your entire Lifetime for assistance."]} />
                    </Col>
                    <Col md={6} lg={4}>
                        {
                            data && (
                                <DuartionCard title={"Course Fees : "}
                                    icon={<MdPhoneAndroid size={50} color='orange' />}

                                    text={["₹ " + data, "Instant money back gurantee , terms & conditions applied"]} />
                            )
                        }
                    </Col>


                </Row>

            </div>

        </div>
    )
}

export default CourseDuration