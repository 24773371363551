import React from "react";
import ReactHlsPlayer from "react-hls-player";

function HlsVideoPlayer({ data }) {

    // console.log(data?.video_link,'data');

  const playerRef = React.useRef();

  function playVideo() {
    playerRef.current.play();
  }

  function pauseVideo() {
    playerRef.current.pause();
  }

  function toggleControls() {
    playerRef.current.controls = !playerRef.current.controls;
  }

  return (
    <div className="container">
      <div
        style={{
          marginTop: "30px",
        }}
      >
        <ReactHlsPlayer
          playerRef={playerRef}
          src={data?.video_link}
          controls
          width="100%" // Example width
          height="100%" // Example height
          style={{
            borderRadius: "15px",
          }}
        />
      </div>
    </div>
  );
}

export default HlsVideoPlayer;
