import React from 'react'
import { useNavigate } from 'react-router-dom'
import HeadingNew from '../AboutUs/HeadingNew'
import NinthSectionCard from '../AdvertisePages/newmembership/NinthSectionCard'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import NinthCard2 from './NinthCard2'

function PerksSection({ }) {
    const navigate = useNavigate()
   


    const handleShow = () => {
        const paymentsection = document.querySelector(".paymentsection");
        if(paymentsection){
            paymentsection.scrollIntoView({
                behavior:"smooth"
            })
        }
    }

    const data = [
        {
            num: 1,
            para: [
                "A Silva Graduate will get a stuadent manual to practice."
            ]
        },
        {
            num: 2,
            para: [
                "You will become a Silva graduate and can repeat the course from anywhere in the world with a minimal seat rent "
            ]
        },
        {
            num: 3,
            para: [
                "You can join weekly graduate meets for meditation practice and doubts."
            ]
        },
        {
            num: 4,
            para: [
                "Weekly cumulative healing sessions with all Silva Graduates."
            ]
        }
       

    ]
    return (
        <div className='container d-flex justify-content-center align-items-center flex-column my-5'>
            <div className='mt-2 mb-2'>
                <HeadingNew title={"Perks After"} colored={"Course Completion"} />
                {/* <HeadingNew small title={"Steps to Give You A Glimpse"} /> */}
            </div>


            {/* <button className='styledbtn23'>
                Close your eyes and Imagine:
            </button> */}
            <div className="d-flex justify-content-center align-items-center flex-column gap-3 my-3">
                {
                    data.map((val, i) => (
                        <NinthCard2 value={val} />
                    ))
                }
            </div>

            <div className='container my-3'>
                <ParaComp
                margin={false}
                white={false}
                data={[
                    "After the completion of our class, if you find this was not going to help you, there will be an instant money back gurantee, no questions asked."]}
                />

            </div>

            <div className='mt-2 mb-2'>
                <button className='herodiv-becomememberbtn mb-2' onClick={handleShow}>
                    Discover Now
                </button>
            </div>

        </div>
    )
}

export default PerksSection