import React, { useContext } from "react";
import Badge from "./Badge";
import HeadingNew from "../../AboutUs/HeadingNew";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import { AuthContext } from "../../../context/AllContext";

function DeltaMind() {
  const { isDesktopOrLaptop, isBigScreen, isTablet, isMobile } =
    useContext(AuthContext);

  return (
    <div
      style={{
        backgroundImage:
          isTablet || isMobile
            ? `url('/img/bg/img-for-mobile-6.webp')`
            : `url('/img/bg/img-34.webp')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: isBigScreen
          ? "50px 15px 600px 15px"
          : isTablet
          ? "50px 15px 600px 15px"
          :isMobile?"50px 15px 300px": "50px 15px 400px 15px",
        backgroundPositionY: isMobile ? "0%" : isTablet?"60%": "",
        backgroundPositionX: isMobile || isTablet ? "20%" : isDesktopOrLaptop?"50%": "",
      }}
    >
      <div className="container">
        <div className="my-3 d-flex flex-column justify-content-center align-items-center">
          <Badge text={"Delta"} />
          <div className="mt-4">
            <HeadingNew title={"State of Mind"} white />
          </div>
          <ParaComp
            data={[
              "is commonly observed during deep sleep or states of deep relaxation. This also indicates a state of profound relaxation, tranquility, and inner calmness. However, you may lose control of your body in the delta state of mind. The delta state of mind is also referred to a slower brain wave patta, promoting restful sleep, healing and rejuvination.",
            ]}
            fw={"500"}
          />
        </div>
      </div>
    </div>
  );
}

export default DeltaMind;
