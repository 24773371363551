import React, { useContext } from 'react'
import { Button, Container, Navbar } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AllContext';

function MemberShipFooter() {
    const navigate = useNavigate();
    const {userData} = useContext(AuthContext)
    const handleScroll = ()=>{
      document.querySelector(".indivcourses").scrollIntoView({
          behavior:"smooth"
      })
  }

   const isSmallMobile = useMediaQuery({minWidth:320,maxWidth:374})


   if(userData && userData.enrollment_status==="Enrolled"){
    return null
   }

  return (
     <Navbar fixed='bottom' bg='light' variant='light'>
        <Container>
          <Navbar.Brand onClick={() => navigate("/")}>
                <p className='mt-2' style={{fontWeight:"600",color:"black",wordWrap:"break-word"}}>Get Started With <br />
                Silva Method Courses
                </p>
          </Navbar.Brand>
          <Navbar.Toggle />
          {/* <Navbar.Collapse className="justify-content-end">
            <Navbar.Text> */}
              {
                !isSmallMobile && (
                  <button className='primary_btn3' onClick={handleScroll}>Enroll Now</button>
                )
              }
              {
                isSmallMobile && (
                  <button className='small_btn' onClick={handleScroll}>Enroll Now</button>

                )
              }
            {/* </Navbar.Text>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
  )
}

export default MemberShipFooter