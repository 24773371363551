import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { CgSandClock } from "react-icons/cg";
import { Col, Row } from 'react-bootstrap';

import Slider from "react-slick";


const buttons = [
    {
        index: 1,
        btnText: "Class 1",
        lessons: [
            "Transforming Stress",
            "Crafting Your Life Goals",
            "Using More of Your Brain and Mind",
        ],
        practice: [
            "Centering Exercise",
            "Transform Stress Now Exercise",
            "Outcome Exercise",
        ],
        result: [
            "Greater inner self-control",
            "Override fear and anger",
            "Your personal map of life",
        ],
        motivation: "Live call #1",
        time: "2024-02-24",
        img: "/img/unstressimages/1.webp",
    },
    {
        btnText: "Class 2",
        index: 2,

        lessons: [
            "Going beyond your limiting beliefs",
            "Sleep Control",
            "Alarm Clock",
        ],
        practice: [
            "Recognize limiting beliefs",
            "Sleep Control Exercise",
            "Alarm Clock Exercise",
        ],
        result: [
            "Create a new living experience",
            "Enjoy healthy, revitalizing sleep",
            "Develop inner trust and better mind/body management",
            "Have a better understanding on how to manifest goals",
        ],
        motivation: "Live call #2",
        time: "2024-03-02",
        img: "/img/unstressimages/2.webp",
    },
    {
        btnText: "Class 3",
        index: 3,

        lessons: [
            "Remembering Dreams and the Dream Control Technique",
            "Energizing Technique",
            "3 Fingers Technique",
        ],
        practice: [
            "Dream Control Exercise",
            "Energizing Exercise",
            "3 Fingers Technique",
        ],
        result: [
            "Getting help from the other side for more effective problem-solving",
            "Master your mind/manage your body",
            "Generate energy as needed throughout the day",
            "Attune to mind states for greater control of your living experience",
        ],
        motivation: "Live call #3",
        time: "2024-03-09",
        img: "/img/unstressimages/3.webp",
    },
    {
        btnText: "Class 4",
        index: 4,
        lessons: ["Awake Control (Energizing Technique) "],
        practice: ["Awake Control (Energizing Technique)"],
        result: ["Greater inner self-control"],
        motivation: "Live call #4",
        time: "2024-03-16",
        img: "/img/unstressimages/4.webp",
    },
    {
        btnText: "Class 5",
        index: 5,
        lessons: ["3 Finger Technique"],
        practice: ["3 Finger Technique"],
        result: [
            "Attune to mind states for greater control of your living experience",
        ],
        motivation: "Live call #5",
        time: "2024-03-23",
        img: "/img/unstressimages/5.webp",
    },
    {
        btnText: "Class 6",
        index: 6,
        lessons: ["Mirror of the Mind Exercise Part-1"],
        practice: ["Mirror of the Mind Exercise Part-1"],
        result: ["Solving problems in all areas of life"],
        motivation: "Live call #6",
        time: "2024-03-30",
        img: "/img/unstressimages/6.webp",
    },
    {
        btnText: "Class 7",
        index: 7,
        lessons: ["Mirror of the Mind Exercise Part-2"],
        practice: ["Mirror of the Mind Exercise Part-2"],
        result: ["Experience energy-infused meditations"],
        motivation: "Live call #7",
        time: "2024-04-06",
        img: "/img/unstressimages/7.webp",
    },
    {
        btnText: "Class 8",
        index: 8,
        lessons: ["Mirror of the Mind Exercise Part-3"],
        practice: ["Mirror of the Mind Exercise Part-3"],
        result: ["Learn the secret to creating effective mental programs"],
        motivation: "Live call #8",
        time: "2024-04-13",
        img: "/img/unstressimages/8.webp",
    },
    {
        btnText: "Class 9",
        index: 9,

        lessons: ["Energizing Water Exercise"],
        practice: ["Energizing Water Exercise"],
        result: ["Live a healthier life for the long haul"],
        motivation: "Live call #9",
        time: "2024-04-20",
        img: "/img/unstressimages/9.webp",
    },
    {
        btnText: "Class 10",
        index: 10,

        lessons: ["Headache Control Exercise"],
        practice: ["Headache Control Exercise"],
        result: ["Forgiveness Exercise"],
        motivation: "Live call #10",
        time: "2024-04-27",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 11",
        index: 11,

        lessons: ["Forgiveness Exercise"],
        practice: ["Forgiveness Exercise"],
        result: ["Free yourself of traumatic, unbearable past issues"],
        motivation: "Live call #11",
        time: "2024-05-04",
        img: "/img/unstressimages/11.webp",
    },
    {
        btnText: "Class 12",
        index: 12,

        lessons: ["Projection to a Point or Place in Space Exercise"],
        practice: ["Projection to a Point or Place in Space Exercise"],
        result: ["Ongoing development of the subjective senses"],
        motivation: "Live call #12",
        time: "2024-05-11",
        img: "/img/unstressimages/12.webp",
    },
    {
        btnText: "Class 13",
        index: 13,

        lessons: ["Projection into Inanimate Matter Exercise"],
        practice: ["Projection into Inanimate Matter Exercise"],
        result: ["Have access to information of all kinds beyond time and space"],
        motivation: "Live call #13",
        time: "2024-05-18",
        img: "/img/unstressimages/9.webp",
    },
    {
        btnText: "Class 14",
        index: 14,

        lessons: ["Project to Plants and Animals Exercise"],
        practice: ["Project to Plants and Animals Exercise"],
        result: ["Find solutions to solve all kinds of problems"],
        motivation: "Live call #14",
        time: "2024-05-25",
        img: "/img/unstressimages/12.webp",
    },
    {
        btnText: "Class 15",
        index: 15,
        lessons: ["Creating your Laboratory Exercise"],
        practice: ["Creating your Laboratory Exercise"],
        result: ["A robust Silva toolbox to facilitate problem solving"],
        motivation: "Live call #15",
        time: "2024-06-01",
        img: "/img/unstressimages/11.webp",
    },
    {
        btnText: "Class 16",
        index: 16,
        lessons: ["Creating your Counsellors Exercise along with Case working"],
        practice: ["Creating your Counsellors Exercise along with Case working"],
        result: ["The personification of your higher self"],
        motivation: "Live call #16",
        time: "2024-06-08",
        img: "/img/unstressimages/5.webp",
    },
    {
        btnText: "Class 17",
        index: 17,
        lessons: ["Projection into the Human Body Exercise"],
        practice: ["Projection into the Human Body Exercise"],
        result: ["The ultimate technique for evolving your subjective senses"],
        motivation: "Live call #17",
        time: "2024-06-15",
        img: "/img/unstressimages/8.webp",
    },
    {
        btnText: "Class 18",
        index: 18,

        lessons: ["Helping your loved ones through case working"],
        practice: ["Helping your loved ones through case working"],
        result: ["Experience your oneness with all matter"],
        motivation: "Live call #18",
        time: "2024-06-22",
        img: "/img/unstressimages/12.webp",
    },
    {
        btnText: "Class 19",
        index: 19,

        lessons: ["Establishing a multitude of subjective points of reference"],
        practice: ["Establishing a multitude of subjective points of reference"],
        result: ["Live a purpose driven life"],
        motivation: "Live call #19",
        time: "2024-06-29",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 20",
        index: 20,

        lessons: ["Projection to Purpose Exercise"],
        practice: ["Projection to Purpose Exercise"],
        result: ["Develop a clearer vision of your future"],
        motivation: "Live call #20",
        time: "2024-07-06",
        img: "/img/unstressimages/3.webp",
    },
    {
        btnText: "Class 21",
        index: 21,

        lessons: ["Energy Infused Meditations for Success"],
        practice: ["Energy Infused Meditations for Success"],
        result: ["The ability to compose effective mental programs"],
        motivation: "Live call #21",
        time: "2024-07-13",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 22",
        index: 22,

        lessons: ["Zap and Mini-booster Meditations"],
        practice: ["Zap and Mini-booster Meditations"],
        result: ["Ability to strengthen your connection with outcomes"],
        motivation: "Live call #22",
        time: "2024-07-20",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 23",
        index: 23,

        lessons: ["Seeds of Purpose Exercise"],
        practice: ["Seeds of Purpose Exercise"],
        result: ["Give your life direction and keep it in flow"],
        motivation: "Live call #23",
        time: "2024-07-27",
        img: "/img/unstressimages/3.webp",
    },
    {
        btnText: "Class 24",
        index: 24,

        lessons: ["Engaging Your Entire Being Exercise"],
        practice: ["Engaging Your Entire Being Exercise"],
        result: ["A wholistic approach to goal achievement"],
        motivation: "Live call #24",
        time: "2024-08-03",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 25",
        index: 25,

        lessons: ["Overcoming Obstacles Exercise"],
        practice: ["Overcoming Obstacles Exercise"],
        result: [
            "Ability to identify and dissolve blocks and obstacles as they appear",
        ],
        motivation: "Live call #25",
        time: "2024-08-10",
        img: "/img/unstressimages/3.webp",
    },
    {
        btnText: "Class 26",
        index: 26,

        lessons: ["Choice Points Exercise"],
        practice: ["Choice Points Exercise"],
        result: ["Living life knowing what to choose"],
        motivation: "Live call #26",
        time: "2024-08-17",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 27",
        index: 27,

        lessons: ["Law of Allowance Exercise"],
        practice: ["Law of Allowance Exercise"],
        result: ["An open door to what you most desire in life"],
        motivation: "Live call #27",
        time: "2024-08-24",
        img: "/img/unstressimages/3.webp",
    },
    {
        btnText: "Class 28",
        index: 28,

        lessons: ["The Expectation Exercise"],
        practice: ["The Expectation Exercise"],
        result: ["Personal evolution as a lifestyle"],
        motivation: "Live call #28",
        time: "2024-08-31",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 29",
        index: 29,

        lessons: ["Confidence Exercise"],
        practice: ["Confidence Exercise"],
        result: ["All things around you keep getting better and better"],
        motivation: "Live call #29",
        time: "2024-09-07",
        img: "/img/unstressimages/3.webp",
    },
    {
        btnText: "Class 30",
        index: 30,

        lessons: ["Staying Positive in Thoughts, Beliefs, and Behaviours"],
        practice: ["Staying Positive in Thoughts, Beliefs, and Behaviours"],
        result: ["A Life fulfilled"],
        motivation: "Live call #30",
        time: "2024-09-14",
        img: "/img/unstressimages/3.webp",
    },
    {
        btnText: "Class 31",
        index: 31,

        lessons: ["Gratitude and Appreciation Written Exercise"],
        practice: ["Gratitude and Appreciation Written Exercise"],
        result: ["Healed by the energy of gratitude"],
        motivation: "Live call #31",
        time: "2024-09-21",
        img: "/img/unstressimages/10.webp",
    },
    {
        btnText: "Class 32",
        index: 32,

        lessons: ["Money Magnet Exercise"],
        practice: ["Money Magnet Exercise"],
        result: ["You…having it all!"],
        motivation: "Live call #32",
        time: "2024-09-28",
        img: "/img/unstressimages/10.webp",
    },
];

const currentDate = new Date();

const isAfter11PM = (date) => {
    return date.getHours() >= 23; // 23 represents 11 PM in 24-hour format
  };

const upcomingClasses = buttons?.filter((button) => {
    const buttonDate = new Date(button?.time);
    return buttonDate >= currentDate || (buttonDate.toDateString() === currentDate.toDateString() && !isAfter11PM(currentDate));
  });



const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,

};
// Assuming 'buttons' is your data array
const ClassesVerticalTimeline = () => {

    const [showMore, setShowMore] = useState(false);


    const [slicedData, setSlicedData] = useState(() => {
        return buttons.slice(0, 12)
    })


    useEffect(() => {
        if (showMore) {
            setSlicedData(upcomingClasses)
        } else {
            setSlicedData(upcomingClasses.slice(0, 9))
        }
    }, [showMore])

    return (

        <div className='container py-5'>

            <Row className='justify-content-center align-items-center gy-5'>

                {
                    slicedData.map((val, i) => (
                        <Col xs={12} md={6} lg={4}>
                            <div class="cardclassnew" style={{
                                position:"relative"
                            }}>
                                
                                <div class="contentnew" style={{
                                    backgroundImage: `url(${val.img}), linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65))`,
                                    backgroundBlendMode: "overlay",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover"
                                }}>

                                    {/* <button className='comboclassbtn'>{val.btnText}</button> */}
                                    <h5 style={{
                                        padding: 0,
                                        margin: 0
                                    }} className='text-white'>Date : <span style={{
                                        fontWeight: "bold"
                                    }}>{val.time}</span></h5>
                                    <ul style={{
                                        fontSize: "13px"
                                    }}>
                                        <h5 className='white-color'>Lessons:</h5>
                                        {
                                            val.lessons.map((val, i) => (
                                                <li className='white-color mt-2' style={{
                                                    lineHeight: "1.2rem",
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    alignItems: "flex-start",

                                                }}>
                                                    <span style={{
                                                        width: "fit-content",
                                                        marginRight: 12,
                                                        alignSelf: "flex-start",
                                                    }}>
                                                        <img src='/img/hypnosis/checked.png' width={20} height={20} />
                                                    </span>
                                                    <span className='text-start' style={{
                                                        maxWidth: "calc(100% - 32px)"
                                                    }}>
                                                        {val}
                                                    </span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <ul style={{
                                        fontSize: "13px"
                                    }}>
                                        <h5 className='white-color'>Practice:</h5>
                                        {
                                            val.practice.map((val, i) => (
                                                <li className='white-color mt-2' style={{
                                                    lineHeight: "1.2rem",
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    alignItems: "flex-start",

                                                }}>
                                                    <span style={{
                                                        width: "fit-content",
                                                        marginRight: 12,
                                                        alignSelf: "flex-start", // Align the icon to flex-start
                                                    }}>
                                                        <img src='/img/hypnosis/checked.png' width={20} height={20} />
                                                    </span>
                                                    <span className='text-start' style={{
                                                        maxWidth: "calc(100% - 32px)"
                                                    }}>
                                                        {val}
                                                    </span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <ul style={{
                                        fontSize: "13px"
                                    }}>
                                        <h5 className='white-color'>Result:</h5>
                                        {
                                            val.result.map((val, i) => (
                                                <li className='white-color mt-2' style={{
                                                    lineHeight: "1.2rem",
                                                    listStyleType: "none",
                                                    display: "flex",
                                                    alignItems: "flex-start",

                                                }}>
                                                    <span style={{
                                                        width: "fit-content",
                                                        marginRight: 12,
                                                        alignSelf: "flex-start",
                                                    }}>
                                                        <img src='/img/hypnosis/checked.png' width={20} height={20} />
                                                    </span>
                                                    <span className='text-start' style={{
                                                        maxWidth: "calc(100% - 32px)"
                                                    }}>
                                                        {val}
                                                    </span>
                                                </li>
                                            ))
                                        }
                                    </ul>

                                </div>
                            </div>
                        </Col>
                    ))
                }


            </Row>
            <div className='d-flex justify-content-center align-items-center mt-3'>
                <button className='combobtnnew' onClick={() => {
                    setShowMore(!showMore)
                }}>{showMore ? "Show Less" : "Show More"}</button>
            </div>
        </div>
    );
}

export default ClassesVerticalTimeline;
