import { Card, Col, Image, Row } from 'react-bootstrap'

function ProgramCard({reversed,title,img}) {
    return (
        <Card style={{
            borderRadius:"10px",
            width:"100%",
            border:"1px solid #636e72",
            background: "rgb(7,92,79)",
            background: "rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)"
        }}>
            <Card.Body>
                <Row className='gap-4 px-4 py-3 justify-content-center align-items-center'>
                    <Col className={reversed?"order-2":"order-1"}>
                        <Image
                            // style={{
                            //     borderRadius:"10px",
                            // }}
                            src={img}
                            // width="0"

                            // height="0"
                            // sizes="100vw"
                            alt=""
                            className="w-95 h-auto contactbanner"
                            style={
                                { width: "98%", borderRadius: "6px", height: "auto" }
                            }

                           
                           

                        />
                    </Col>
                    <Col className={reversed?"order-1":"order-2"}>
                        <h4 className='white-color'>
                            {title || "Weekly Live Q&A and Guest Sessions"}
                        </h4>
                        {/* <p className='white-color'>
                            For expert insights and answers to all your questions
                        </p> */}
                    </Col>
                </Row>
            </Card.Body>

        </Card>
    )
}

export default ProgramCard