import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ComboPaymentCard from './ComboPaymentCard'
import HeadingNew from '../../AboutUs/HeadingNew'

function PaymentCombo({ data }) {
    return (
        <Row className='justify-content-center align-items-center'>
            <HeadingNew small title={"What are you waiting for?"} />
            <HeadingNew title={"Take action, enroll now"} />

            <Col xs={12} md={6}>
                <div className='d-flex justify-content-end align-items-center'>
                    <img
                        src='/img/bg/joebgnew2.png'
                        style={{
                            width: "85%",

                        }}
                    />
                </div>
            </Col>
            <Col xs={12} md={6}>
                {
                    data && (
                        <ComboPaymentCard datanew={data[0]} />
                    )
                }
            </Col>
        </Row>
    )
}

export default PaymentCombo