import React from 'react'
import FAQ2 from '../../CoursePage/FAQ2'

function TFTFaqs({data}) {
  return (
    <div  className='container' style={{
        paddingTop: "70px",
        paddingBottom: "50px"
      }}>
         <h3 className='text-left newpageheader'>
          Frequently Asked questions
        </h3>
        <div>
            {
                data.map((el,i)=>
                <FAQ2
                key={i}
                heading={el.heading}
                body={<p style={{ color: "white" }}>{el.body}</p>}
              />
                )
            }
        </div>
      </div>
  )
}

export default TFTFaqs