import React from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp2 from '../SilvaManifestationProgram/ParaComp2'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { CiUser } from "react-icons/ci";
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import { useMemo } from 'react'
import CheckboxComponent from './CustomCheckbox'
import CountUp from "react-countup";
import { GiTeacher } from "react-icons/gi";
import { IoMdGlobe } from "react-icons/io";
import { FaUserGraduate } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";

function IndividualSection() {

    const { isMobile } = useContext(AuthContext);
    const list = useMemo(() => {
        return [
            // "Better health and immunity",
            // "Optimize memory and productivity",
            // "Reach goals quicker, & solve problems creatively",
            // "Leverage increased levels of energy",
            // "Free your mind of negative beliefs and behaviors",
            "Wanting to improve your health?",
            "Not embracing the present, burdened by past guilt?",
            "Wrestling with timely decision-making?",
            "Eager to alleviate physical pain?",
            "Craving enhanced memory, creativity, and intuition?",
            "Yearning to alter habits or limiting beliefs?",
            "Longing to tap into higher intelligence for guidance?",
            "Seeking solutions for everyday problems?"
        ]
    }, [])


    const list2 = useMemo(() => {
        return [
            "Do you struggle with life challenges or uncertainty?",
            "Do you want to get out of limitations and live the life you desire?",
            "Do you want to get out of blockages and limitations and live the life that you truly desire?",
            "Do you want to activate your full potential?"
        ]
    }, [])


    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymenttwonew");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth"
            })
        }
    }
    return (
        <div style={{
            marginTop: "70px",
            marginBottom: "70px"
        }}>
            <Row className='container justify-content-center align-items-start' style={{
                margin: "auto"
            }}>
                <Col xs={12} md={8}>
                    <HeadingNew title={"You Are Extraordinary, You Just Don’t Know It!"} small start={true} />
                    <div className='mt-3'>
                       

                        {
                            isMobile && (
                                <Row className='justify-content-start align-items-center'>

                                    {
                                        list2.map((val, i) => (
                                            <>
                                                <Col xs={1} md={2}>
                                                    <CheckboxComponent id={i} />
                                                </Col>
                                                <Col xs={11} md={10}>
                                                    <p className='text-start ' style={{
                                                        fontSize: isMobile ? "0.8rem" : "1rem",
                                                        textAlign: "center",
                                                        fontWeight: "500",
                                                        lineHeight: isMobile ? "26px" : "30px",
                                                        marginTop: "10px",
                                                        marginLeft:isMobile?"10px":"0px"
                                                    }}>
                                                        {val}
                                                    </p>
                                                </Col>
                                            </>

                                        ))
                                    }
                                </Row>
                            )
                        }
                        {
                            !isMobile && list2.map((val, i) => (
                                <div className="d-flex justify-content-start align-items-center gap-3" key={i}>
                                    <CheckboxComponent id={i} />
                                    <p className='text-start ' style={{
                                        fontSize: isMobile ? "0.8rem" : "1rem",
                                        textAlign: "center",
                                        fontWeight: "500",
                                        lineHeight: isMobile ? "26px" : "30px",
                                        marginTop: "10px"

                                    }}>
                                        {val}
                                    </p>
                                </div>
                            ))
                        }

                        <div className='my-3'>
                            <HeadingNew start small title={"The Answer Lies Within You! But do you know how to access them?"} />
                        </div>
                        <div className='my-3'>
                           
                            <p className='normalpara text-start'>
                                "It all resides in the altered state of your mind that you are born with. All you need to do is to harness this state of mind at a special frequency of the brain. That’s exactly what the Silva Method is going to help you with so that you can function in a genius-like manner.",


                            </p>
                            <p className='normalpara text-start'>
                                "All you need to do is to harness this state of mind at a special frequency of brain.  That’s exactly what the Silva Method is going to help you with, so that you can function in a genius-like manner."
                            </p>

                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div style={{
                        width: "90%",
                        marginLeft: "20px"
                    }}>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        <Image
                                            src='/img/trophy.png'
                                            width={50}
                                            height={50}
                                        />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }}>80,000+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>SUCCESS STORIES</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                      
                                        <GiTeacher size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={500} duration={2.5} suffix="+" /> */}
                                         <h4 style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }}>500+</h4>

                                        <h6 style={{
                                            color: "black",
                                        }}>Instructors</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-06.png")}
                                            width={50}
                                            height={50}
                                        /> */}

                                        <IoMdGlobe size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={120} duration={2.5} suffix="+" /> */}
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }}>120+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>Countries</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-07.png")}
                                            width={50}
                                            height={50}
                                        /> */}
                                        <FaUserGraduate size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }}>12M</h4>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={12} duration={1.5} suffix="M" /> */}

                                        <h6 style={{
                                            color: "black",
                                        }}>Graduates</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-11.png")}
                                            width={50}
                                            height={50}
                                        /> */}

                                        <IoIosPeople size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={1} duration={1} suffix="M" /> */}
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }}>1M</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>Testimonials</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                    </div>
                </Col>

            </Row>

            <Row className='container justify-content-center align-items-center' style={{
                margin: "auto",
                marginTop: "70px"
            }}>
                <Col xs={12} md={6}>
                    <Image
                        src='/img/dragan/dragan.jpg'
                        style={{
                            width: isMobile ? "100%" : '95%',
                            height: '100%',
                            boxShadow: "10px 10px 26px -4px rgba(0,0,0,0.1)",
                            borderRadius: "10px",

                            objectFit: 'cover', // Make sure the image covers the entire container
                        }}
                    />

                </Col>
                <Col xs={12} md={6}>
                    <div className='mobilemargin'>
                        <h2 className='newpageheader'>Exclusive 4 Days of Class with <span className='underline'>Dragan Vujovic</span></h2>
                        {/* <h2 className='newpageheader mb-4'></h2> */}
                        {
                            isMobile && (
                                <Row className='justify-content-start align-items-center'>

                                    {
                                        list.map((val, i) => (
                                            <>
                                                <Col xs={1} md={2}>
                                                    {/* <span><Image src='/img/checkicon.png' className='checkimg' /></span> */}
                                                    <CheckboxComponent id={i} />
                                                </Col>
                                                <Col xs={11} md={10}>
                                                    <p className='text-start ' style={{
                                                        fontSize: isMobile ? "0.8rem" : "1rem",
                                                        textAlign: "center",
                                                        fontWeight: "500",
                                                        lineHeight: isMobile ? "26px" : "30px",
                                                        marginTop: "10px",
                                                        marginLeft:isMobile?"10px":"0px"

                                                    }}>
                                                        {val}
                                                    </p>
                                                </Col>
                                            </>

                                        ))
                                    }
                                </Row>
                            )
                        }
                        {
                            !isMobile && list.map((val, i) => (
                                <div className="d-flex justify-content-start align-items-center gap-3" key={i}>
                                    {/* <span><Image src='/img/checkicon.png' className='checkimg' /></span> */}
                                    <CheckboxComponent id={i} />
                                    <p className='text-start ' style={{
                                        fontSize: isMobile ? "0.8rem" : "1rem",
                                        textAlign: "center",
                                        fontWeight: "500",
                                        lineHeight: isMobile ? "26px" : "30px",
                                        marginTop: "10px"

                                    }}>
                                        {val}
                                    </p>
                                </div>
                            ))
                        }
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "15px"
                        }}>
                            <Button variant="warning" size="lg" block style={{
                                width: "250px",
                                fontWeight: "600",
                                borderRadius: "13px"
                            }}
                                className='paybtn'
                                onClick={handleScroll}
                            >
                                Join the Online Class
                            </Button>
                        </div>

                    </div>

                </Col>


            </Row>
        </div>
    )
}

export default IndividualSection