import React, { useContext } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import ParaComp from '../../../SilvaManifestationProgram/ParaComp'
import { AuthContext } from '../../../../context/AllContext'

function MeditateBegineerHeroSection() {
    const {isMobile} = useContext(AuthContext);

    return (
        <div className='hero-background'>
            <div className='container px-4 mx-auto' >
                <Row className='justify-content-center align-items-center py-5' style={{
                    height: isMobile?"auto":"50vh"
                }}>
                    <Col md={8}>
                        <h1 style={{
                            color: "white"
                            ,fontSize:isMobile?"2.5rem":"3rem",
                            textAlign:"start"
                        }}
                            className='lora-sans mt-4'
                        >
                            How to {" "}
                            <span style={{ color: '#FFEA00' }}>
                                Meditate
                            </span>
                        </h1>
                        <h2 style={{
                            color: "white",
                            fontSize:isMobile?"2rem":"2.5rem"
                        }} className='lora-sans'>
                            A Gentle Guide for Beginners
                        </h2>

                        <p className='white-color robotofont mt-3' style={{
                            fontSize: "1rem",
                            marginRight:"20px"
                        }}>
                            Meditation brings you closer to your true self. Even a 5-10 min meditation practice improves sleep quality, increases daily productivity, and helps you focus better. If you are a beginner and wondering how to meditate, then let us take you through the basics of how to meditate.
                        </p>
                                   
                    </Col>
                    <Col md={4}>
                        <Image src='/img/bg/bghero/icons/iz8xw5jazytjdbtjrtuv.jpg' style={{
                            borderRadius:"15px"
                        }} alt='how-to-meditate-for-beginners'/>
                    </Col>

                </Row>

            </div>
        </div>

    )
}

export default MeditateBegineerHeroSection