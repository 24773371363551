import React from "react";
import { Card, Image } from "react-bootstrap";
import { FaBrain,FaUserGraduate } from "react-icons/fa";
import { GiNightSleep } from "react-icons/gi";
import { IoIosBody } from "react-icons/io";
import { RiFocus2Line } from "react-icons/ri";
import { IoIosHappy,IoMdGlobe,IoIosPeople } from "react-icons/io";
import {GiTeacher} from "react-icons/gi";

function SilvaInformationCards() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap">
      <Card
        style={{
          width: "15rem",
          borderRadius: "12px",
        }}
      >
        <Card.Body>
          <div className="d-flex justify-content-around align-items-center">
            <span>
              <Image src="/img/trophy.png" width={50} height={50} />
            </span>
            <div className="d-flex justify-content-center align-items-center flex-column gap-2">
              <h4
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                80,000+
              </h4>
              <h6
                style={{
                  color: "black",
                }}
              >
                SUCCESS STORIES
              </h6>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card
        style={{
          width: "15rem",
          borderRadius: "12px",
        }}
      >
        <Card.Body>
          <div className="d-flex justify-content-around align-items-center">
            <span>
              <GiTeacher size={50} color="black" />
            </span>
            <div className="d-flex justify-content-center align-items-center flex-column gap-2">
              {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={500} duration={2.5} suffix="+" /> */}
              <h4
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                500+
              </h4>

              <h6
                style={{
                  color: "black",
                }}
              >
                Instructors
              </h6>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card
        style={{
          width: "15rem",
          borderRadius: "12px",
        }}
      >
        <Card.Body>
          <div className="d-flex justify-content-around align-items-center">
            <span>
              {/* <Image
                                            src={require("../../images/adimgs/icon-06.png")}
                                            width={50}
                                            height={50}
                                        /> */}

              <IoMdGlobe size={50} color="black" />
            </span>
            <div className="d-flex justify-content-center align-items-center flex-column gap-2">
              {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={120} duration={2.5} suffix="+" /> */}
              <h4
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                120+
              </h4>
              <h6
                style={{
                  color: "black",
                }}
              >
                Countries
              </h6>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card
        style={{
          width: "15rem",
          borderRadius: "12px",
        }}
      >
        <Card.Body>
          <div className="d-flex justify-content-around align-items-center">
            <span>
              {/* <Image
                                            src={require("../../images/adimgs/icon-07.png")}
                                            width={50}
                                            height={50}
                                        /> */}
              <FaUserGraduate size={50} color="black" />
            </span>
            <div className="d-flex justify-content-center align-items-center flex-column gap-2">
              <h4
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                12M+
              </h4>
              {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={12} duration={1.5} suffix="M" /> */}

              <h6
                style={{
                  color: "black",
                }}
              >
                Graduates
              </h6>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card
        style={{
          width: "15rem",
          borderRadius: "12px",
        }}
      >
        <Card.Body>
          <div className="d-flex justify-content-around align-items-center">
            <span>
              {/* <Image
                                            src={require("../../images/adimgs/icon-11.png")}
                                            width={50}
                                            height={50}
                                        /> */}

              <IoIosPeople size={50} color="black" />
            </span>
            <div className="d-flex justify-content-center align-items-center flex-column gap-2">
              {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={1} duration={1} suffix="M" /> */}
              <h4
                style={{
                  color: "black",
                  fontWeight: "700",
                }}
              >
                1M+
              </h4>
              <h6
                style={{
                  color: "black",
                }}
              >
                Testimonials
              </h6>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SilvaInformationCards;
