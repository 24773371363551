import React from 'react'
import FAQ2 from '../../../CoursePage/FAQ2'

function BlogFaqs() {
    const data = [
        {
            title: "Is sleep meditation suitable for everyone?",
            content: " Yes, sleep meditation is generally safe for most people. However, if you have a serious sleep disorder, consult with a healthcare professional before starting a new practice."
        },
        {
            title: "How long should I practice sleep meditation?",
            content: "Start with 10-15 minutes and gradually increase the duration as you become more comfortable."
        }
        ,
        {
            title: "Can I listen to sleep meditation while falling asleep?",
            content: " Yes, many people find it helpful to listen to guided meditations as they drift off to sleep."
        },
        {
            title: "Is sleep meditation the same as hypnosis?",
            content: " While both involve relaxation and suggestion, hypnosis is generally more focused on inducing a trance-like state, while sleep meditation aims to promote relaxation and sleep."
        }
    ]
    return (
        <div className='container d-flex justify-content-center align-items-center flex-column gap-3'>
            <h2 style={{ color: 'black', textAlign: 'start' }} className="lora-sans mt-4">
                Frequently Asked Questions
            </h2>
            <div>
                {
                    data.map((val) => (
                        <FAQ2
                            heading={val.title}
                            body={<p className='text-light robotofont'>{val.content}</p>}
                        />
                    ))
                }

            </div>

        </div>
    )
}

export default BlogFaqs