import React, { useContext } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomFooter from '../Home/Home/HomeFooter/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import { AuthContext } from '../../context/AllContext';
import useWindowSize from 'react-use/lib/useWindowSize'

import Confetti from 'react-confetti'

const ThankYouPage = () => {
    const { isMobile } = useContext(AuthContext)
    const { width, height } = useWindowSize()

    return (
        <>
            {/* <HomeHeader /> */}
            <div>
                <div
                    style={{
                        background: `rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)`,
                        border: '1px solid black',
                        padding: '50px 50px',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <h6
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            textAlign: 'center',
                            marginBottom: '25px',
                            lineHeight: '25px',
                        }}
                    >
                        Your registration has been received. We look forward to having you at the webinar!
                    </h6>
                    <hr />
                    <div className='d-flex justify-content-center align-items-center'>
                        <Image src='img/thankyou/trophy.png' height={isMobile ? "auto" : 500} style={{ borderRadius: '10px' }} />
                    </div>

                    <div className='row mt-4 justify-content-center align-items-center'>
                        {/* <Button
                            variant='primary'
                            className='col-md-3'
                            style={{
                                marginRight: "10px",
                                marginBottom: "10px"
                            }}
                            onClick={() => {
                            }}
                        >
                            Join WhatsApp Group
                        </Button> */}
                        <Button
                            variant='info'
                            style={{
                                marginRight: "10px",
                                marginBottom: "10px"
                            }}
                            className='col-md-3'
                            onClick={() => {
                                window.open("https://www.facebook.com/SilvaInternationalInc");
                            }}
                        >
                            Follow on Facebook
                        </Button>
                        <Button
                            variant='danger'
                            style={{
                                marginRight: "10px",
                                marginBottom: "10px"
                            }}
                            className='col-md-3'
                            onClick={() => {
                                window.open("https://www.instagram.com/silvamethodofficial/");
                            }}
                        >
                            Follow on Instagram
                        </Button>
                        <Button
                            variant='warning'
                            style={{
                                marginRight: "10px",
                                marginBottom: "10px"
                            }}
                            className='col-md-3'
                            onClick={() => {
                                window.open("https://www.youtube.com/channel/UCaCDqnK1-i0rb9tFPznvI4w")
                            }}
                        >
                            Subscribe on YouTube
                        </Button>
                    </div>

                    <div className='text-center mt-4'>
                        <Link className='primary_btn3 pulse' to="/" style={{
                            color:"white",
                            textDecoration:"none",
                            fontSize:"1.3rem"
                        }}>Back to Home</Link>
                    </div>
                </div>
            </div>
            {/* <footer className='py-3' style={{ backgroundColor: "#f4f5f9", width: "100%", height: "100%" }}>
                <p className="text-center" style={{ fontSize: "16px" }}>Copyright &copy; silvamethod.com</p>
            </footer> */}
            <Confetti
                width={width}
                gravity={0.2}
                initialVelocityX={10}
                initialVelocityY={10}
                height={height}
            />
        </>
    );
};

export default ThankYouPage;
