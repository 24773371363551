import React, {  useRef } from "react";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules

// import Image1 from '../../img/benefits/Image1.jpg';
// import Image2 from '../../img/benefits/Image2.jpg';
// import Image3 from '../../img/benefits/Image3.jpg';
// import Image4 from '../../img/benefits/Image4.jpg';
// import Image5 from '../../img/benefits/Image5.jpg';
// import Image6 from '../../img/benefits/Image6.jpg';
// import Image7 from '../../img/benefits/Image7.jpg';
// import Image8 from '../../img/benefits/Image8.jpg';


import Image1 from '../../images/sliders/Carousel Banners 1.webp';
import Image2 from '../../images/sliders/Carousel Banners 2.webp';
import Image3 from '../../images/sliders/Carousel Banners 3.webp';
import Image4 from '../../images/sliders/Carousel Banners 4.webp';
import Image5 from '../../images/sliders/Carousel Banners 5.webp';
import Image6 from '../../images/sliders/Carousel Banners 6.webp';
import Image7 from '../../images/sliders/Carousel Banners 7.webp';
import Image8 from '../../images/sliders/Carousel Banners 8.webp';


import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import {GrFormNext,GrFormPrevious} from "react-icons/gr"
import { useMediaQuery } from "react-responsive";


const data = [
  {
    cover: Image1,
    title: "image1"
  },
  {
    cover: Image2,
    title: "image2"
  },
  {
    cover: Image3,
    title: "Image3"
  },
  {
    cover: Image4,
    title: "Image4"
  },
  {
    cover: Image5,
    title: "Image5"
  },
  {
    cover: Image6,
    title: "Image6"
  },
  {
    cover: Image7,
    title: "Image7"
  },
  {
    cover: Image8,
    title: "Image8"
  }
]

export default function NewHomeSlider() {
  // const { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait } = useContext(AuthContext);
  // const { width,height } = useWindowSize();

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1500px)" });
  const isMedium = useMediaQuery({ minWidth: 1280, maxWidth: 1499 });

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });

  const isLargeMob = useMediaQuery({ minWidth: 600, maxWidth: 767 });
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 599 });

  const ref = useRef()
  return (
    <div style={{ width: "100%", position: "relative",marginTop:"20px" }}>
    
      {/* <Swiper
        style={{ width: isDesktopOrLaptop ? "400px" : "250px", height: isDesktopOrLaptop ? "400px" : "250px" }}
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        loop={true}
      >
        <SwiperSlide>
          <img src={Image1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image6} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image7} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image8} alt="" />
        </SwiperSlide>
      </Swiper> */}
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
            // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
            // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
            // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
            let currentVisibleSlide = 7;
            // if (parentWidth <= 1440) currentVisibleSlide = 3;
            // if (parentWidth <= 1080) currentVisibleSlide = 1;
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Card}
                slideWidth={isDesktopOrLaptop?500:isMedium?300:isTablet?250:isLargeMob?200:200}
                height={isDesktopOrLaptop?500:isMedium?400:isTablet?280:isLargeMob?280:230}
                carouselWidth={isDesktopOrLaptop?800:isMedium?600:isTablet?500:isLargeMob?400:300}
                data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={11}
                useGrabCursor
              />
            );
          }}
        />
        <div className="sliderstack" style={{textAlign:"center",marginTop:isDesktopOrLaptop?"20px":"0"}}>
          <GrFormPrevious
            size={40}
            onClick={() => {
              ref.current?.goBack();
            }}
          />
          <GrFormNext
            size={40}

            onClick={() => {
              ref.current?.goNext(6);
            }}
          />

        </div>
    </div>
  );
}


const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height:"100%",
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        src={cover}
      />
    </div>
  );
});