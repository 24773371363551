import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import HeroSectionBook from './HeroSectionBook'
import AboutAuthor from './AboutAuthor'
import SliderReviews from './SliderReviews'
import AboutBook from './AboutBook'
import MindControlTechniques from './MindControlTechniques'
import MindControlBooks from './MindControlBooks'
import SilvaExcersises from './SilvaExcersises'
import StatesMindControl from './StatesMindControl'
import LevelofMind from './LevelofMind'
import LifeSkills from './LifeSkills'
import AlphaStateMind from './AlphaStateMind'
import ThetaStateMind from './ThetaStateMind'
import DeltaMind from './DeltaMind'
import GradientCard from './SilvaGradientCard'
import ReadyGetSection from './ReadyGetSection'
import { useState } from 'react'
import PdfDownloadModal from './PdfDownloadModal'
import MindControlHero1 from '../MindControl1/MindControlHero1'
import BecomeMemberSection from '../../silvamembership/BecomeMemberSection'
import MIndControlCarousel1 from '../MindControl1/MIndControlCarousel1'
import AboutDianaSilva from '../MindControl1/AboutDianaSilva'
import MindControlFaqs from '../MindControl1/MindControlFaqs'
import { useLocation, useNavigate } from 'react-router-dom'
import SaleStrip from '../../Contact/SaleStrip'

function SilvaMindControlBook() {

  const location= useLocation();

  const [show,setShow]= useState(false);
  const navigate= useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log(location?.pathname,'pathname')

  return (
    <div>
        <HomeHeader />
        <HeroSectionBook handleShow={handleShow}/>
        <AboutAuthor />
        {/* <SaleStrip/> */}
        <AboutBook/>
        <SliderReviews />
        <MindControlTechniques/>
        <MindControlBooks handleShow={handleShow}/>
        <SilvaExcersises/>
        <StatesMindControl/>
        <LevelofMind/>
        <AlphaStateMind/>
        <ThetaStateMind/>
        <DeltaMind/>
        <LifeSkills/>
        {/* <SliderReviews /> */}
        <GradientCard />
        <ReadyGetSection />
        <MindControlHero1/>
        {/* <CoursesSection/> */}
        {/* <SaleStrip/> */}
        <div className='my-5'>
        <BecomeMemberSection cta={true} click={true} onclick={()=>navigate("/silva-method-courses")}/>
        </div>
        <MIndControlCarousel1/>
        <AboutDianaSilva/>
        <MindControlFaqs/>
        <CustomFooter />
        <PdfDownloadModal show={show} setShow={setShow} handleClose={handleClose}/>
    </div>
  )
}

export default SilvaMindControlBook