import React from 'react'
import silvalogo from "../../../images/newimgs/silvamethod-logo.png";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';


function EmotionalNavbar() {
    const {isMobile} = useContext(AuthContext);
    
  return (
    <nav className='d-flex justify-content-between align-items-center px-4  px-md-4 py-2 py-md-3 sticky-top' style={{
        position: 'relative', // Ensure the nav is positioned relatively
        borderRadius: "2.1rem",
        overflow: 'hidden', // Ensure the pseudo-element does not overflow,
    }}>
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, .4)', // Use desired background color with some opacity
            filter: 'blur(30px)',
            borderRadius: '2.1rem', // Match the border radius of the parent
            zIndex: -1 // Ensure the pseudo-element is behind the content
        }}></div>
        <img src={"/img/logo/Image20240626135215.png"} style={{
            width:isMobile?"50%":"auto"

        }} alt="" />
        <a href="mailto:digital@silvamethod.com" className='styledbtn2345' style={{ textDecoration: "none" }}>
            Support
        </a>
    </nav>
    
  )
}

export default EmotionalNavbar