import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import HeadingNew from '../../AboutUs/HeadingNew';
import { useMemo } from 'react';
import { AuthContext } from '../../../context/AllContext';
import HlsVideoPlayer from '../../Store/SingleLecturePage/HlsVideoPlayer';

function HypnosisReasons() {



    const { isMobile,isTablet } = useContext(AuthContext);


    const data = useMemo(() => {
        return [
            "Promotes deep relaxation",
            "Slows down brain frequencies",
            "Improves focus and concentration",
            "Enhances self-control",
            "Reprograms the subconscious mind",
            "Release pain and improve health",
            "Get rid of bad habits"
        ]

    }, []);

    const videoData={video_link:"https://vz-b9ba02fc-be4.b-cdn.net/12c7e98b-f262-4819-9fce-18bc769fb4a9/playlist.m3u8"}

    const handleScroll = ()=>{
        const card = document.querySelector(".includelivecheck")
        if(card){
            card.scrollIntoView({
                behavior:"smooth"
            })
        }
      }


    return (

        <div className='container my-5'>
            <Row className='container justify-content-center align-items-center' style={{
                position: "relative"
            }}>

                <Col xs={12} lg={6} className={'order-1'}>


                    <div className='d-flex justify-content-start align-items-start'>
                        {/* <video className='custom-single-training-media' src='https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4' style={{
                            width: (isTablet || isMobile) ? "100%" : "95%",
                            borderRadius: 15,
                            boxShadow: "0px 3px 17px -3px rgba(0, 0, 0, 0.36)",
                            marginBottom:(isTablet || isMobile)?"15px":"0px"
                        }}
                            autoPlay
                            controls
                            muted
                        /> */}
                        <HlsVideoPlayer data={videoData} />

                    </div>
                </Col>
                <Col xs={12} lg={6} className={'order-2'}>
                    <HeadingNew small title={"Why Should You Practice"} start />
                    <HeadingNew title={"Self-Hypnosis?"} start />

                    <Row className='my-3'>
                        {
                            data.map((val, i) => (
                                <Col xs={12} md={6}>
                                    <div className='d-flex gap-2 mb-1' style={{
                                        width: isMobile ? "100%" : "95%",
                                        paddingLeft: 10
                                    }}>

                                        <img src='/img/checkicon2.png' width={25} height={25} />
                                        <p style={{
                                            fontSize: "0.9rem",
                                            color: "black",
                                            fontWeight: "500",
                                            margin: "0px"
                                        }}>
                                            {val}
                                        </p>

                                    </div>
                                </Col>


                            ))
                        }
                    </Row>
                    <button className='herodiv-becomememberbtnnew' style={{
                        marginLeft: "0",

                    }}
                        onClick={handleScroll}
                    >
                        Enroll now
                    </button>




                </Col>

            </Row>

        </div>

        // 
        // <div className='container my-5'>
        //     <Row className='justify-content-center align-items-center'>


        //         <Col xs={12} md={7}>
        //             <video className='custom-single-training-media' src='https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4' style={{
        //                 width: "100%",
        //                 borderRadius: 15,
        //                 boxShadow: "0px 3px 17px -3px rgba(0, 0, 0, 0.36)"
        //             }}
        //                 autoPlay
        //                 controls
        //                 muted
        //             />
        //         </Col>
        //         <Col xs={12} md={5} style={{
        //             position: "relative",
        //             display: "flex",
        //             justifyContent: "flex-start",
        //             alignItems: "flex-start"
        //         }}>
        //             <div style={{
        //                 display: "flex",
        //                 justifyContent: "flex-start",
        //                 alignItems: "flex-start",
        //                 width: "100%",
        //                 paddingLeft:50,
        //                 flexDirection: "column"
        //             }}>
        //                 <h3 style={{
        //                     color:"black"
        //                 }}>
        //                 Why Should You Practice <span style={{
        //                     color:"#4b37a6",

        //                 }}>
        //                 Self Hypnosis?
        //                 </span>
        //                 </h3>

        //                 <div className='mt-3'>

        //                 </div>



        //                 {
        //                     data.map((val, i) => (
        //                         <div className='d-flex gap-3 justify-content-center align-items-center' style={{
        //                             alignSelf: "flex-start",
        //                             height: "35px"
        //                         }}>
        //                             <img src='/img/hypnosis/checked.png' width={25} height={25} />
        //                             <p style={{
        //                                 color: "black",
        //                                 marginTop: 10,
        //                                 fontWeight: "500"
        //                             }}>
        //                                 {val}
        //                             </p>
        //                         </div>
        //                     ))
        //                 }
        //                 <button className='herodiv-becomememberbtn3 mt-3' style={{
        //                     boxShadow:"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
        //                 }}>

        //                   Enroll Now
        //                 </button>


        //             </div>
        //         </Col>
        //     </Row>

        // </div>
    )
}

export default HypnosisReasons