import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import Tilt from 'react-parallax-tilt';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { AuthContext } from '../../../context/AllContext';


function WhySection() {
    const { isMobile } = useContext(AuthContext);

    const handleScroll = ()=>{
        const card = document.querySelector(".includelivecheck")
        if(card){
            card.scrollIntoView({
                behavior:"smooth"
            })
        }
      }

    return (
        <div className='container my-5'>
            <Row className='container justify-content-center align-items-center' style={{
                position: "relative"
            }}>
                <Col xs={12} lg={6} className={isMobile ? 'order-2' : 'order-1'}>
                    {!isMobile && <HeadingNew title={"Why Jose Silva Sleep Meditation Technique is Best for"} colored={"Sleep Disorder"} start/>}


                    <ParaComp
                        white={false}
                        data={[
                            "The Silva Method, founded by Jose Silva in 1966, offers a range of mind control techniques designed to enhance relaxation. It involves listening to a guided meditation or soothing audio that encourages a state of calmness and tranquility and effectively easing tension and stress, which leads to achieving a peaceful sleep too.",
                            "This relaxation technique helps people to fall asleep more easily and enjoy a restful night. Sleep Meditation Silva Method basically focuses on helping people let go of stress, tension, and annoying thoughts, allowing their minds and bodies to unwind.",
                            "Consistent engagement with Silva Method meditation sleep practices will inevitably lead to an enhancement in the quality of sleep, resulting in waking up with a sense of refreshment.",
                        ]}
                        fw={"400"}
                    />
                    <button className='herodiv-becomememberbtnnew' style={{
                        marginLeft: "0",

                    }}
                        onClick={handleScroll}
                    >
                        Enroll now
                    </button>




                </Col>
                <Col xs={12} lg={6} className={isMobile ? 'order-1' : 'order-2'} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection:"column"
                }}>


                        {isMobile &&  <HeadingNew title={"Boost Your Financial Well-Being With "} colored={"The Silva Method"} start={isMobile?false:true}/>}
                    <div className='d-flex justify-content-end align-items-end'>
                        <Tilt style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end"
                        }}>

                                <img src='/img/Sleep disorder.webp' style={{
                                    width: "95%",
                                }} />
                        </Tilt>
                    
                    </div>
                </Col>

            </Row>

        </div>
    )
}

export default WhySection