import React, { useState } from 'react';
import './Tabs.css'; // Create a separate CSS file for styling
import { CiCircleCheck } from "react-icons/ci";

const TabsSection = ({activeTab,setActiveTab,tabs}) => {
     
  
    return (
        <div className='my-3 d-flex justify-content-center align-items-center gap-3 flex-wrap'>
            {
                tabs.map((val,i) => (
                    <div className={`${tabs[activeTab]===val?"button-container bg-change-color":"button-container"}`}>
                        <button className={`${tabs[activeTab]===val?"arrow-button2":"arrow-button"}`} onClick={()=>setActiveTab(i)}>
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <span>
                                    <CiCircleCheck color={tabs[activeTab]===val?"white":"black"} size={40} />
                                </span>
                                <h4 className={`mt-2 hoverclassheader`} style={{
                                    fontWeight: "400",
                                    width:"100px",
                                    color:tabs[activeTab]===val?"white":"black"
                                }}>
                                    {val}
                                </h4>
                            </div>
                        </button>
                        {
                            tabs[activeTab]===val && (
                                <span className="arrow"></span>
                            )
                        }
                    </div>
                ))
            }


        </div>
    );
};

export default TabsSection;
