import React, { useCallback, useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import silvalogo from "../../images/newimgs/silvamethod-logo.png";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Nav, NavDropdown } from 'react-bootstrap';
import { BiLogIn } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from '../../context/AllContext';

function SilvaMembershipNavbar({ courseIds,title }) {
  const location = useLocation();
  const params = useParams()
  const { userData, isUserLoggedIn, logout, isMobile, isTablet } = useContext(AuthContext);
  const navigate = useNavigate();
  const [toggled, setToggled] = useState(false)
  const [homeLink, setHomeLink] = useState("/");
  const [links, setLinks] = useState([])


  // useEffect(() => {
  //   if (userData && userData.enrollment_status === "Enrolled") {
  //     setHomeLink("/today")
  //   }
  // }, [userData])

  // console.log(courseIds,"courseIds")





  useEffect(() => {

    if (courseIds) {
      const filter = courseIds.filter((l, i) => l.courseid !== params.id)
      setLinks(filter)
    }
  }, [params, courseIds])


  // console.log(courseIds,links,'dtaa')











  return (
    <Navbar
      style={{ background: "#fff" }}
      expand="lg"
      //  fixed='top'
      className="header-menu-area sticky_menu"
      onToggle={(val) => {
        if (val) {
          setToggled(true);
        } else {
          setToggled(false);
        }
      }}
    >
      <h1>{title}</h1>
      <Container>
        <Navbar.Brand onClick={() => navigate(homeLink)}>
          <img src={silvalogo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          {toggled ? (
            <AiOutlineClose size={25} color="black" />
          ) : (
            <FaBars size={25} color="black" />
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {!isMobile && !isTablet ? (
              <div>
                <button
                  className="btn btn-outline-dark navdroppricing"
                  style={{ borderRadius: "50px" }}
                >
                  <NavDropdown
                    title={
                      location.pathname.includes("/store/course")
                        ? "Other Courses"
                        : "All Courses"
                    }
                    id="basic-nav-dropdown"
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >


                    {links.map((val, i) => (
                      <NavDropdown.Item
                        onClick={() => navigate("/store/course/" + val?.courseid)}
                        key={i}
                       
                      >
                        {/* <span  className={val?.courseid?.includes('combo')?'combo-li':''}>{val.title}</span> {val?.courseid?.includes("combo") && <span style={{color:"red",fontWeight:"bold",marginLeft:"5px"}}>New</span>} */}
                        <span >{val.title}</span>
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </button>
              </div>
            ) : (
              <div>
                <NavDropdown
                  title={
                    location.pathname.includes("/store/course")
                      ? "Other Courses"
                      : "All Courses"
                  }
                  id="basic-nav-dropdown"
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  <NavDropdown.Item
                    onClick={() =>
                      navigate("/store/course/combo-plan")
                    }
                  >
                    Complete course with live interaction
                  </NavDropdown.Item>

                  {links.map((val, i) => (
                    <NavDropdown.Item
                      onClick={() => navigate("/store/course/" + val?.courseid)}
                      key={i}
                    >
                      {val.title}

                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </div>
            )}


            {isUserLoggedIn && (
              <>
                <Nav.Link>
                  <Button
                    variant="btn btn-outline-dark"
                    style={{ borderRadius: "50px" }}
                    onClick={logout}
                  >
                    {" "}
                    Logout
                  </Button>
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SilvaMembershipNavbar