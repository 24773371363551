import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { RiNumber1, RiNumber2, RiNumber3, RiNumber4, RiNumber5, RiNumber6, RiNumber7, RiNumber8, RiNumber9, RiNumber0 } from "react-icons/ri"
import { GrLinkNext } from "react-icons/gr";
import HeadingNew2 from '../booking/HeadingNew2';
import ParaComp2 from '../SilvaManifestationProgram/ParaComp2';
import { FaHourglassStart } from "react-icons/fa";

function SeminarTimeline2() {

    return (

        <VerticalTimeline
            lineColor='rgb(216, 1, 251)'
        >
                 <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                 <HeadingNew2 title={"TECHNIQUE OF THE LABORATORY AND"} colored={"ADVISORS"} small />
                <ParaComp2
                    data={[
                        "A simple and effective technique that in borderline situations helps you quickly find the solution, thus eliminating stress."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"THE BASICS OF"} colored={"INTUITION"} small />
                <ParaComp2
                    data={[
                        "Learn the effective use of affirmations and autosuggestion. Understanding the most important habit you must acquire to discover your intuitive abilities. Review the step-by-step courses to get the most out of the relaxation exercises and lay a solid foundation for the intuitive mind."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>

            {/* <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"SOURCE ENERGY DROP"} colored={"EXERCISE"} small />
                <ParaComp2
                    data={[
                        "a simple exercise that allows you to perceive the connection with   the universal source of energy. The energy from which everything begins and where all information exists. You will create your spiritual representation that is one with the source energy to help you solve problems. Learn to discipline your imagination so that you can control your intuition."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement> */}
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"SOURCE ENERGY DROP"} colored={"EXERCISE"} small />
                <ParaComp2
                    data={[
                        "a simple exercise that allows you to perceive the connection with   the universal source of energy. The energy from which everything begins and where all information exists. You will create your spiritual representation that is one with the source energy to help you solve problems. Learn to discipline your imagination so that you can control your intuition."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"HOLOVISION"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "It is a technique by which you will manifest more of what you desire while being guided by Source Energy trusting that you are going in the right direction."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"PROJECTION IN"} colored={"METALS"} small />
                <ParaComp2
                    data={[
                        "Develop your abilities of clairvoyance, clairaudience and clairsentience combined with the pure power of imagination, the most powerful tool in improving intuition. It opens the door to exploring this field from a subjective, imaginative perspective."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"TREE/LEAF/PET"} colored={"PROJECTION"} small />
                <ParaComp2
                    data={[
                        "It develops informational links at a subjective level between animals, plants and human beings."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"PROJECTION IN HUMAN"} colored={"ANATOMY"} small />
                <ParaComp2
                    data={[
                        "Learn how to apply visualization and imagery techniques to improve health or even reach healing levels. Through this exercise you will take a fascinating extrasensory journey through the human body learning how to use your imagination to correct imbalances."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                <HeadingNew2 title={"ORIENTATION EXERCISES AND THE DEMONSTRATION OF"} colored={"CLAIRVOYANCE"} small />
                <ParaComp2
                    data={[
                        "You will witness a demonstration of clairvoyance which you will then practice with your colleagues using the techniques learned during the course. Have you ever thought that you can learn things that you have no knowledge of in the objective world?"
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                <HeadingNew2 title={"SEEDS OF"} colored={"PURPOSE"} small />
                <ParaComp2
                    data={[
                        "It is an end-of-course exercise where your personal source energy representation guides you along the timeline to plant the seeds that will sprout in time indicating that you have not strayed from your chosen life path and purpose ."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
        </VerticalTimeline>

    )
}

export default SeminarTimeline2