import React, { useContext } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap';
import { CiBandage } from "react-icons/ci";
import { AuthContext } from '../../context/AllContext';
import { FaBrain,FaUserGraduate } from "react-icons/fa";
import { GiNightSleep } from "react-icons/gi";
import { IoIosBody } from "react-icons/io";
import { RiFocus2Line } from "react-icons/ri";
import { IoIosHappy,IoMdGlobe,IoIosPeople } from "react-icons/io";
import {GiTeacher} from "react-icons/gi";
import { GiMeditation } from "react-icons/gi";
import { RiMentalHealthLine } from "react-icons/ri";
import { TbHealthRecognition } from "react-icons/tb";
import { TiTree } from "react-icons/ti";


function CalmCardSectionTwo() {
    const {isMobile} = useContext(AuthContext)

    const data = [
        {
            icon: <GiMeditation color='#6a3093' size={70} />,
            title: "Importance of Meditation:",
            text: "Discover the integral role of meditation in well-being and unravel what sets the Silva Method apart from other techniques. Explore why meditation has become a cornerstone of holistic health and learn how the Silva Method uniquely enhances mental, emotional, and physical well-being, setting it apart in the realm of meditation practices."
        },
        {
            icon: <RiMentalHealthLine color='#6a3093' size={70} />,
            title: "Mind-Body-Brain Connection:",
            text: "Understand the intricate interplay between the mind, body, and brain. Explore how thoughts and emotions impact physical health, emphasizing the vital connection between mental and physical well-being."
        },
        {
            icon: <TbHealthRecognition color='#6a3093' size={70} />,
            title: "Good Health",
            text: "Quality sleep is essential for overall physical and mental health. This Sleep meditation promotes relaxation, reduces stress and anxiety, and can positively impact various bodily functions, including the immune system, hormonal balance, and cardiovascular health."
        },
        {
            icon: <TiTree color='#6a3093' size={70} />,
            title: "Visualization and Imagination:",
            text: "Learn how to use visualization and imagination techniques in manifesting goals. Learn techniques to vividly picture desired outcomes, enhancing creativity and empowering the mind to shape reality."
        },
        {
            icon: <RiMentalHealthLine color='#6a3093' size={70} />,
            title: "Developing Positive Thoughts:",
            text: "Master the art of cultivating positivity within the mind. Learn practical strategies to foster a positive mindset, influencing emotions, actions, and overall life satisfaction."
        },
        {
            icon: <FaBrain color='#6a3093' size={70} />,
            title: "Left Brain-Right Brain:",
            text: "Explore the dynamics of the left and right brain hemispheres. Understand how these distinct areas contribute to cognitive functions and how balanced utilization enhances creativity, problem-solving, and overall cognitive abilities."
        }
    ]
    return (
        <div className='d-flex justify-content-center align-items-center flex-column gap-3 my-5 text-center'>

            <div className='d-flex justify-content-center align-items-center flex-wrap'>
        
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        <Image
                                            src='/img/trophy.png'
                                            width={50}
                                            height={50}
                                        />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>80,000+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>SUCCESS STORIES</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                      
                                        <GiTeacher size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={500} duration={2.5} suffix="+" /> */}
                                         <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>500+</h4>

                                        <h6 style={{
                                            color: "black",
                                        }}>Instructors</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-06.png")}
                                            width={50}
                                            height={50}
                                        /> */}

                                        <IoMdGlobe size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={120} duration={2.5} suffix="+" /> */}
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>120+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>Countries</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-07.png")}
                                            width={50}
                                            height={50}
                                        /> */}
                                        <FaUserGraduate size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "600"
                                        }}>12M+</h4>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={12} duration={1.5} suffix="M" /> */}

                                        <h6 style={{
                                            color: "black",
                                        }}>Graduates</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        <Card style={{
                            width: "15rem",
                            borderRadius: "12px"
                        }}>
                            <Card.Body>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <span>
                                        {/* <Image
                                            src={require("../../images/adimgs/icon-11.png")}
                                            width={50}
                                            height={50}
                                        /> */}

                                        <IoIosPeople size={50} color='black' />

                                    </span>
                                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                                        {/* <CountUp style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }} end={1} duration={1} suffix="M" /> */}
                                        <h4 style={{
                                            color: "black",
                                            fontWeight: "700"
                                        }}>1M+</h4>
                                        <h6 style={{
                                            color: "black",
                                        }}>Testimonials</h6>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                   
            </div>
            <h1 className='newpageheader mt-5'>
               Benefits of The Silva Sleep Control Technique
            </h1>

            <Row className='container justify-content-center align-items-center gy-3'>
                {
                    data.map((val, i) => (
                        <Col xs={12} md={6} lg={4}>
                            <Card className='stresscard' style={{
                                width: isMobile?"100%":"95%",
                                height:isMobile?"auto":"450px",
                                borderRadius: "10px"
                            }}>
                                <Card.Body style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}>
                                    <span>{val.icon}</span>
                                    <h3 style={{
                                        color: "black",
                                        fontWeight: "550",
                                        fontSize: "1.5rem",
                                        textAlign: "center"
                                    }}>
                                        {val.title}
                                    </h3>
                                    <p className='normalpara'>
                                        {val.text}
                                    </p>
                                </Card.Body>

                            </Card>

                        </Col>
                    ))
                }

            </Row>
        </div>
    )
}

export default CalmCardSectionTwo