import React, { useContext } from "react";
import "../AboutUs/about.css";
import { AuthContext } from "../../context/AllContext";

function FirstContent() {
  // const handleScroll = () => {
  //   const cardscroll = document.querySelector(".paymenttwonew");

  //   if (cardscroll) {
  //     cardscroll.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   }
  // };

  const {isMobile,isTablet}= useContext(AuthContext);

  return (
    <section className={`pos-rel pt-${(isMobile)?'15':'80'} mb-3`}>
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          {/* <h2>
            The Silva Courses Refund Policy: Stress-Free, Safe, and Seamless
          </h2> */}
          <h1 className='newpageheader text-center'>The Silva Courses Refund Policy: Stress-Free, Safe, and Seamless</h1> 
          <p>
            At The Silva Method, we are committed to your success and personal
            growth. We believe in the power of our program but we understand
            that life can bring unexpected twists. If, within the first 15 days
            of your Silva course purchase, you find it’s not aligning with your
            expectations or fails to meet the perceived value of your
            investment, share your genuine reason with us at
            digital@silvamethod.com. Our commitment extends beyond merely
            providing a program, we will process your refund within 30 days of
            your refund request. Your satisfaction is our priority, and we are
            dedicated to supporting you on your journey towards success and
            fulfillment.
          </p>
          {/* <button className="herodiv-becomememberbtn" onClick={handleScroll}>
          Click to Boost Your Brain Power
          </button> */}
        </div>
      </div>
    </section>
  );
}

export default FirstContent;
