import React from 'react';

const StressImageCard = ({ image, title, description }) => {
   
    return (
        <>
            <div class="resource-stressed-card">
                <div class="resource-stressed-card-content">
                    <h3>
                        {title}
                    </h3>
                    <p>
                        {description}
                    </p>
                </div>
            </div>
        </>
    );
};

export default StressImageCard;
