import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import TestimonialCard from "../../newbooking/TestimonialCard";
import { Link } from "react-router-dom";
import HeadingNew from "../../AboutUs/HeadingNew";

function VideoTestimonials() {
  const reviews = [
    {
      type: "video",
      text: "",
      image: "",
      video:
        "https://vz-47e4ab86-ce6.b-cdn.net/e483775b-00be-4d08-98f3-f2cad0e4752b/playlist.m3u8",
      name: "",
      designation: "",
    },
    {
      type: "video",
      text: "",
      image: "",
      video:
        "https://vz-47e4ab86-ce6.b-cdn.net/7175f34c-af40-43ac-b565-44663239d18f/playlist.m3u8",
      name: "",
      designation: "",
    },
    // {
    //   type: "video",
    //   text: "",
    //   image: "",
    //   video:
    //     "https://vz-47e4ab86-ce6.b-cdn.net/0ed3e928-7883-4357-8675-a46749627dbe/playlist.m3u8",
    //   name: "",
    //   designation: "",
    // },
  ];

  return (
    <div>
      <Container>
        <div className="my-4">
        <HeadingNew title={"What People Say About "} colored={"The Silva Method"}/>
        </div>
        <Row>
          {reviews.map((review, i) => (
            <Col sm={12} md={6} lg={4} className="px-3">
              <TestimonialCard key={i} data={review} />
            </Col>
          ))}
          <Col sm={12} md={6} lg={4} className="px-3">
          <div className="position-relative">
            <Card className="w-100 apponitestimonial" style={{borderRadius:"15px"}}>
              <Image src="/img/appointment/Image20240822191304.png" style={{borderRadius:"15px",height:"100%"}}/>
            </Card>
            <div className="appointtesibtndiv">
              <Link to={'/success-stories'}>
              <button className="newappointbtn px-3 py-2">View More</button>
              </Link>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default VideoTestimonials;
