import React, { useContext, useState } from "react";
import HeadingNew from "../../AboutUs/HeadingNew";
import Slider from "react-slick";
import AllContext, { AuthContext } from "../../../context/AllContext";
import { Card } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";

function MIndControlCarousel1() {
  const { isMobile, isTablet, isBigScreen, isDesktop } =
    useContext(AuthContext);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    className: "center",
    infinite: true,
    cssEase: "linear",
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    dots: true,
    centerPadding:
      isMobile || isTablet ? "10px" : isBigScreen ? "400px" : "100px",
    arrows: false,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  const data = [1, 1, 1, 1];

  return (
    <div>
      <div className="my-5 mb-3">
        <HeadingNew title={"Silva Mind Control Method by jose Silva"} small />
        <HeadingNew title={"Quick Book "} colored={"Summary"} />
      </div>
      <div className="videos-carousel my-5">
        <Slider {...settings}>
          {data?.map((el, i) => (
            <div key={i}>
              <Card
                className="mindcontrolcard"
                style={{
                  width: isDesktop ? "95%" : "98%",
                  height: isMobile
                    ? "400px"
                    : currentSlide === i
                    ? "600px"
                    : isBigScreen
                    ? "500px"
                    : "400px",
                  borderRadius: "20px",
                  backgroundImage: `url('/img/bg/img-72.webp')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundPositionX:isMobile?"25%":"55%"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#00000078",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                    // border:"5px solid #ffffff"
                  }}
                 className="border-gradient-rounded"
                >
                  <img
                    src="/img/icon/play.gif"
                    alt=""
                    style={{ width: isMobile ? "25%" : isTablet ? "15%" : "" }}
                  />
                </div>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
      <div className="d-flex justify-content-center align-items-center my-3">
        <button className="mindcontrolnewBtn px-5 py-3 border-0">
          Write a Review{" "}
          <span className="ms-2">
            <FaChevronRight color="#fff" size={15} />
          </span>
        </button>
      </div>
    </div>
  );
}

export default MIndControlCarousel1;
