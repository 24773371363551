import React from 'react'
import HeadingNew from './HeadingNew'
import AboutCard2 from './AboutCard2'
import { Col, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { Link } from 'react-router-dom'

function AlphaState({ title,para,parablack,titlenew,btn }) {
  const isLargeScreen = useMediaQuery({ minWidth: 1200 })

  return (
    <div className='parallexabout pt-5'>
      {
        (title && !titlenew) ? (
          <div>
            <HeadingNew title={"How to Enter the"} small />
            <HeadingNew title={"Alpha"} colored={"State"} />
          </div>
        ) : (!title && !titlenew) ? (
          <div>
            <HeadingNew title={"Explore Other Techniques "} small />
            <HeadingNew title={"How to Use The Alpha State of Mind"} />
          </div>
        ):(
          <div>
             <HeadingNew title={titlenew} />
          </div>
        )
      }

     <div className="container">
     {
        para && (
          <div>
            <ParaComp
            white={!parablack}
            data={para}
            />
          </div>
        )
      }
     </div>
      <div className="abotparawrapper mt-5">
        <Row className={`justify-content-center align-items-center`}>
          <Col xs={12} sm={12} md={12} lg={12} xl={5} style={{ marginRight: isLargeScreen ? "20px" : "0px" }}>
            <AboutCard2 btnText="Step 1" titleText="Entry" para={"Begin by closing your eyes and visualizing a point slightly above eye level. This particular eye position encourages your brain to shift into the Alpha state. Take a deep breath and, upon exhaling, mentally repeat the number '3' three times. Take another deep breath and, on the subsequent exhale, mentally repeat the number '2' three times. Once again, inhale deeply, and as you exhale, mentally repeat the number '1' three times"} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5}>
            <AboutCard2 btnText='Step 2' titleText='Deepening' para={"To deepen your relaxation further, count down either from 100 to 1, 50 to 1, or 25 to 1, depending on your familiarity with meditation. Beginners can commence with counting backward from 100 to 1, gradually progressing to 50 to 1, and eventually 25 to 1. For experienced practitioners, counting from 25 to 1 can be employed."} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5} style={{ marginRight: isLargeScreen ? "20px" : "0px" }}>
            <AboutCard2 btnText='Step 3' titleText='Programming' para={"Utilize this stage to program your mind with positive thoughts and affirmations. It is important to remember that, at this level, the negative voice within remains subdued, creating an optimal environment to work with empowering thoughts that align with your desired belief system. Additionally, you can harness the power of your imagination to support the realization of specific goals."} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5}>
            <AboutCard2 btnText='Step 4' titleText='Exit' para={"As you conclude your meditation, guide your mind to transition out of the meditative state. Repeat the following to yourself:'I will now count from 1 to 5. When I reach 5, I will awaken completely, be fully alert, and experience enhanced physical, emotional, and spiritual well-being. 1, 2, 3. Upon reaching 5, I will open my eyes. 4, 5. I am now fully awake, completely alert, and feeling wonderful in every aspect.'"} />
          </Col>

        </Row>
      </div>

      {
        btn && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Link to="/store/course/combo-plan">
            <button className="primary_btn">
              Know More
            </button>
            </Link>
          </div>
        )
      }

      

    </div>
  )
}

export default AlphaState