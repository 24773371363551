import React, { useEffect, useState } from 'react';

function StressManagementTechniques() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const techniques = [
        {
            title: "Breathing Exercises: Quick Stress Relief",
            para: `When stress hits hard, sometimes you need something fast. Enter breathing exercises. Deep mindful breathing can help you calm down in just a few minutes. Try this simple technique:
            \n1. Breathe in slowly through your nose, filling your belly.\n2. Hold your breath for a few seconds.\n3. Exhale slowly through your mouth. \nThis method triggers your body’s relaxation response, slowing your heart rate and helping you chill out instantly.`,
            image: "/img/stress/breathing exercise.webp"
        },
        {
            title: "Mindfulness Meditation",
            para: "Meditation has become a go-to method for reducing stress and for a good reason. The practice involves paying attention to the present moment, which can help reduce cortisol and build emotional resilience.",
            image: "/img/stress/mindfullness-meditation.webp"
        },
        {
            title: "Exercise as a Stress Buster",
            para: "Exercise isn’t just good for your body—it’s one of the best ways to manage stress. Physical activity releases endorphins, your brain's natural stress-buster. Short walks, yoga, or even a quick dance break can work wonders.",
            image:"/img/stress/exercise as a stress buster.webp"
        },
        {
            title: "Progressive Muscle Relaxation (PMR)",
            para: "Sometimes your body also carries your mental stress. This technique involves tensing and then slowly relaxing each muscle group in your body, helping you release built-up tension.",
            image:"/img/stress/progressive muscle relaxation.webp"
        },
        {
            title: "Time Management",
            para: "Poor time management can lead to feeling overwhelmed. Learning to prioritize, create to-do lists, break down tasks, and set realistic deadlines can help you stay on track and manage stress effectively.",
            image: "/img/stress/time management.webp"
        }
    ];

    return (
        <section className={`techniques-section mt-5  ${isVisible ? 'fade-in' : ''}`}>
            <h2 className="heading-large text-center stressed-section-title text-black">
                Best Stress Management Techniques
            </h2>
            <p className="robotofont text-center ">
                Discover scientifically proven methods to take control of stress. Explore a combination of techniques to find what works best for you.
            </p>
            <div className="techniques-grid">
                {techniques.map((technique, index) => (
                    <div key={index} className="technique-card">
                        <div className="technique-content">
                            <h3 className="technique-title">{technique.title}</h3>
                            <p className="technique-para robotofont">{technique.para}</p>
                        </div>
                        <div className="technique-image">
                            <img src={technique.image} alt={`${technique.title}`} />
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default StressManagementTechniques;
