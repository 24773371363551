import React, { useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { allcodes } from '../../../utils/allCountryCodes';
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../utils/axioscall';

function SendOTP({setOtpSent}) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [loading, setLoading] = useState(false)

    const loginfetch = async (cc,cn,ph) => {
        var myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
            myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");
        // myHeaders.append("DeviceId", "{{asdfwedfgdasfds}}");
        // myHeaders.append("Token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzaWx2YW1ldGhvZC5jb20iLCJhdWQiOiJUaGVfQXVkIiwiaWF0IjoxNjgxNDQ0MzI3LCJuYmYiOjE2ODE0NDQzMzcsImV4cCI6NTM1MjE0NDQzMjcsImRhdGEiOiIxIn0.D-p1cFH0rSYBWN1tGnDG1UUvRejaEdwkcoxgcWAlwmo");

        var formdata = new FormData();
        formdata.append("country_code",cc)
        formdata.append("country_name",cn)

        formdata.append("phone", ph.toString());

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        setLoading(true)
        const res = await fetch(baseUrl + "loginWithOTP", requestOptions);
        setLoading(false)
        const data = await res.json();
        if (data.error === false) {
            localStorage.setItem("otpsent",true)
            localStorage.setItem("phone",ph)
            setOtpSent(true)
            toast.success(data.messages)
        } else {
            toast.error(data.messages)
        }
        // console.log(data)

    }

    const [countryCode, setCountryCode] = useState("");
    const [countryName,setCountryName] = useState("")


    const onSubmit = (data) => {
        if (!countryCode) {
            toast.error("Enter a country code");
            return
        }
        loginfetch(countryCode,countryName, data.phone)
    }

    const handleCountryChange = (e) => {
        const val = (e.target.value)
        const found = allcodes.find((ac,i)=>ac.name===val);

        if(found){
            setCountryCode(found.dial_code)
            setCountryName(found.name)
        }else{
            setCountryCode("")
            setCountryName("")
        }
    }
    

    return (
        <div className='login-area'>
            <div className="basic-login2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <select className="form-select mb-3" aria-label="Default select example" onChange={handleCountryChange}>
                        <option selected>Select country code </option>
                        {
                            allcodes.map((code, index) => (
                                <option key={index + 1} value={code.name}>{code.name}{`(${code.dial_code})`}</option>
                            ))
                        }
                    </select>

                    <label htmlFor="phone" className='mb-2'>Phone Number <span>**</span></label>
                    <input {...register("phone", { required: true, pattern: /^\d*[.]?\d*$/, maxLength: 13 })} required id="phone" name="phone" type="text"
                        placeholder="Enter Phone number" />
                    {
                        errors.phone && (
                            <Alert variant={"danger"}>
                                Invalid Phone number !
                            </Alert>
                        )
                    }
                    <button className='btn btn-success'>
                        {
                            loading ? (
                                <Spinner animation="border" variant="light" />
                            ) : "Send OTP"
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}

export default SendOTP