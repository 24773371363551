import React from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import LandingTwoHero from './LandingTwoHero'
import BannerBooking2 from './BannerBookingTwo'
import CalmCardSection from './CalmCardSection'
import PaymentSectionTwo from '../newbooking/PaymentSectionTwo'
import VideoTableSection2 from './VideoTableSection2'
import AboutInstructorSection2 from '../newbooking/AboutInstructorSection2'
import DraganTestimonials from './DraganTestimonials'
import DraganPageFaq from '../SilvaManifestationProgram/DraganPageFaq'
import Featured from '../../components/featured/Featured'
import TrustedBy from './TrustedBy'
import MyForm from './MyForm'
import MentalHealthTools from './MentalHealthTools'
import CalmCardSectionTwo from './CalmCardSectiontwo'
import BannerBooking3 from './BannerBookingThree'
import BookingFooter from '../newbooking/BookingFooter'

function LandingPage2() {
  return (
    <div style={{
      backgroundImage: "linear-gradient(rgba(226, 234, 255, 0) 0%, rgb(226, 234, 255) 100%)"
    }}>
      <HomeHeader />
      <LandingTwoHero />
      <BannerBooking3 />
      <TrustedBy />
      <div className='container my-5'>
        <MyForm />

      </div>
      <MentalHealthTools />

      {/* <PaymentSectionTwo /> */}
      <CalmCardSectionTwo />
      <VideoTableSection2 />
      <AboutInstructorSection2 />
      <DraganTestimonials />
      <div className='container my-5'>
        <DraganPageFaq />

      </div>
      {/* <CustomFooter /> */}
      <BookingFooter />
    </div>
  )
}

export default LandingPage2