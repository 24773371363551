import React from "react";
import TitleWithBadge from "../../AboutUs/TitleWithBadge";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import { Link } from "react-router-dom";
import "../../AboutUs/about.css";

function TFTHero() {
  const handleScroll = () => {
    const cardscroll = document.querySelector(".paymenttwonew");

    if (cardscroll) {
      cardscroll.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="fact-area fact-map parallexabout2 pos-rel pt-115 pb-60 mb-3">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          <h3 className="white-color">Silva Life System Masterclass for Ageless Brain Empowerment</h3>
          <p className="white-color">
            It is perfectly normal to experience occasional memory lapses or
            misplace essential things like keys or glasses. Various factors such
            as stress, insomnia, distraction, and the natural aging process can
            significantly impact our ability to remember details and maintain
            focus. After all, our memories play a crucial role in shaping who we
            are, and any decline in memory function may feel like a loss of
            self. Whether you’re a student, a working professional, or a retired
            person you can improve your memory and mental performance. You may
            ask, HOW? The answer lies in a simple yet effective technique known
            as The Three Fingers Technique.
          </p>
          <button className="herodiv-becomememberbtn" onClick={handleScroll}>
          Click to Boost Your Brain Power
          </button>
        </div>
      </div>
    </section>
  );
}

export default TFTHero;
