
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import RobertImage from '../../../images/research/team.webp'
import HeadingNew from '../../AboutUs/HeadingNew';
import NewPara from './NewPara';
import TickSection from './TickSection';
import { Col, Container, Image, Nav, NavLink, Row, Table } from 'react-bootstrap';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ResearchCard from '../../Contact/ResearchCard';

function OtherSections() {
  const [activeSection, setActiveSection] = useState('');

  const { isMobile, isDesktopOrLaptop, isBigScreen } = useContext(AuthContext);
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 999 });

  const sections = [
    { id: 'section1', title: 'How Jose Silva developed The Silva Method' },
    { id: 'section2', title: 'How Science Discovered Silva Method' },
    { id: 'section3', title: 'Silva Method Research & News' },
    { id: 'section4', title: 'Control of Brainwaves' },
    { id: 'section5', title: 'Results for Jose Silva Dream Control Method' },
    { id: 'section6', title: 'The Silva Methods results for psychic awareness' },
    { id: 'researchsection', title: 'Research results and research projects overview' },
    { id: 'section8', title: 'The Silva Methods results for social orientation' },
    { id: 'section9', title: 'International Educational Investigation Project' },
    { id: 'section10', title: 'Florida Prison Project' },

  ];


  const projects = [
    {
      title: 'Results in Improving Health',
      image: "/img/bg/science/improvedhealth.png",
      description:
        'In a survey of 1,107 participants, more than twice the percentage of those seeking health benefits reported improved health as a result of using the Silva Method.',
    },
    {
      title: 'Results in Improving Relaxation',
      image: "/img/bg/science/improvedrelaxation.png",
      description:
        'The ability to enter levels of deep relaxation is critical to the reduction of potentially damaging physical tension and psychological stress.',
    },
    {
      title: 'Results in Improving Sleep',
      image: "/img/bg/science/Improvedsleep.png",
      description:
        'A greater facility in entering sleep naturally is learned by BLS participants. Natural sleep is vital to the maintenance of proper physical and mental health.',
    },
    {
      title: "EEG Correlates of Attention in Humans",
      image: "/img/draganpage/12799.jpg",
      description: "This research was conducted to demonstrate the capacity of the Silva Method graduate to produce Alpha brain wave rhythm voluntarily, and its compatibility with the conscious production of images.",
      points: [
        "Trinity University, San Antonio, Texas, 1972.",
        "Drs. Frederick J. Bremner, V. Benignus, and F. Moritz.",
        "The research demonstrated that “the Silva subjects were quite capable of producing the Alpha rhythm” on demand Published in Neuropsychology, vol. 10 p. 307-312 and 467-469, England. "
      ]
    },
    {
      title: "The Silva Method’s Results for Brainwave Control",
      description: "A comparison of training data to baseline data for Silva Method trainees. ",
      image: "/img/bg/science/brainwavescontrol-1.png"
    },
    {
      title: "The Effect of The Silva Method on Enhancing Intuition and Creativity",
      description: "A group of 30 individuals who participated in the Silva Method were asked to complete three test instruments before and after the training: The HCP Profile Test to determine left versus right brain dominance; the HCP-PSY Test to determine beliefs and experiences about intuition; and the Intuitive Potentials Test, which measures an individual’s current intuitive potentials.",
      points: [
        "Appalachian State University, Boone, North Carolina, 1988.",
        "Dr. George Maycock, Appalachian State University.",
        "Individuals who completed the Silva Method BLS showed a significant increase in scores for all three tests. Individuals in the control group who did not participate in the training showed no significant gains in scores on any of the tests.",
        "This research study was presented at the American Educational Research Association 1988 Annual Meeting and has been published by Silva International, Inc."
      ],
      description2: "Imagination is a key component of creativity. The Silva Method offers techniques for exercising the imagination and increasing creativity.",
      image: "/img/bg/science/Improvedcreativity.png"
    },
    {
      title: "The Silva Method and Changes in EEG Alpha Power",
      description: "This research was done to demonstrate that the Silva Method graduate can increase the production of his or her alpha brain wave rhythm when entering the “basic plane level.”",
      points: [
        "Clinical Neurology Department, San Carlos Clinic Hospital, Madrid Spain, 1985.",
        "Jose Carlos Oliveros, MD, Head of the Clinical Electroneurophysiology Service, San Carlos Clinical Hospital; Tomas Ortiz, MD, Head of the Psychobiology Department, Computense University, Madrid; Ana Maria Selman, Bs.D., in Biological Sciences; Simon Arrigain, BS in physics. Head of Computer Service, Clinical Electroneurophysiology, San Carlos Clinical Hospital.",
        "Results indicated a significant increase in the median values of Alpha power in the occipital and temporal areas of the brain while the research subjects were in a state of “dynamic meditation.” "
      ],
      image: "/img/bg/science/luvz17ph4wbba0dwmox9.jpg"
    },
    {
      title: "Results for Jose Silva Dream Control Method",
      description: "Dream Control, a technique for remembering dreams and learning how to use dreams for practical problem-solving, was used successfully by about four times as many who were seeking benefits: ",
      image: "/img/bg/science/Dreamcontrol.png",
      id: "section5"
    },
    {
      title: "People Who Benefited from The Silva Method Basic Lecture Series",
      description: "This research was conducted for the Board of Directors of Mind Science Foundation so that they could evaluate whether participants in the Silva Method Basic Lecture Series are receiving the benefits they are seeking.",
      points: [
        "Mind Science Foundation, San Antonio, Texas, and Los Angeles, California, 1973.",
        "Dr. J.W. Hahn, Director of Mind Science Foundation.",
        "More than 1,100 Silva Method graduates who responded to the survey indicated that they had received more benefits than they had sought when they attended the BLS. Dr. Hahn reported that 99.6 percent of the respondents were satisfied with the course.",
        "Report presented to the Board of Directors of Mind Science Foundation, and published by Silva International in 1973. "
      ],
      image: "/img/bg/science/Image20240920153315.jpg"
    },
    {
      title: "The Silva Method’s Results in Improving Memory",
      description: "Increased memory functioning, including information storage and recall, are part of the BLS. Again, the number of graduates reporting successful results is higher than those originally seeking such results.",
      image: "/img/bg/science/improvedmemory.png"
    },
    {
      title: "The Effect of Expectations on The Effectiveness of The Silva Method",
      description: "This research was done to determine whether skeptics who come to the Basic Lecture Series with a low expectancy benefit as much as people who come expecting a lot.",
      points: [
        "Bar-Illan University, Department of Sociology, Israel, 1989.",
        "Rafael Liberman.",
        "Positive results: “The results show that ‘skeptics’ who take the Silva course attain the same achievements as those whose expectations were high[…]. The Silva Method can reduce the level of anxiety and make it possible to attain achievements beyond the participants’ expectations of success in the course.”",
        "Published by Silva International, Inc. "
      ],
      image: "/img/bg/science/htb0vdgqvldmbcigkrip.jpg"
    },
    {
      title: "The Silva Method’s Results for Psychic Awareness",
      description: "The development of a superior intuitive sense is thought to be related to success in many areas. Students in the BLS have experiences of a psychic nature, and the intuitive sense is enhanced by Silva training. ",
      image: "/img/bg/science/Psychicawareness.png",
      id: "section6"
    },
    {
      title: "The Silva Method and levels of anxiety",
      description: "This research was conducted to measure the effect of the Silva Method on both trait and state anxiety levels of subjects.",
      points: [
        "Psychology Department, Haifa University, Haifa, Israel, 1984.",
        "Rafael Liberman.",
        "The results confirm the hypothesis that the stable anxiety level of the participants on finishing the course and at the next stage will be significantly less than the stable anxiety level at the beginning of the same. Both “state anxiety” and “trait anxiety” levels were improved significantly. Previous researchers felt that trait anxiety levels could not be altered significantly, but the results from this study indicated that trait anxiety levels were significantly reduced following the completion of the Silva Method Basic Lecture Series. Follow-up studies showed that improvement continues the most when participants continue to practice.",
        "Published by Silva International, Inc."
      ],
      image: "/img/bg/science/leuh3czrkiautyihgfzz.jpg"
    },
    {
      title: "The Silva Method’s results for psychic awareness",
      description: "The development of a superior intuitive sense is thought to be related to success in many areas. Students in the BLS have experiences of a psychic nature, and the intuitive sense is enhanced by Silva training. ",
      image: "/img/bg/science/BeliefinESP.png"
    },
  ];

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6, // Adjust this value based on when you want the section to be considered 'active'
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log(entry.target.id, "entry.target.id")
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((section) => {
      console.log(section.id, "sectionid")
      const element = document.getElementById(section.id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [sections]);



  return (
    <Container fluid>
      <Row>
        <Col md={9} className={isMobile ? "order-2" : "order-1"}>
          <div className='blog-container mx-5'>
            <div className='blog-content'>
              <HeadingNew title={"Is The Silva Method "} colored={"Scientifically Proven?"} start />
              <HeadingNew small title={"Let's Uncover The Truth"} start />
              <img
                src='/img/bg/science/ujdew7zximp7wib6jrbf.jpg'
                alt='Phone Mockup'
                className='blog-image mt-4'
                style={{
                  borderRadius: "15px"
                }}
              />
              <ParaComp
                data={[
                  "The Silva Method is a self-help program developed by José Silva in the 1960s. Various studies, research projects, and practical applications have demonstrated its efficacy in improving mental, emotional, and physical well-being. Below, we present detailed insights and evidence supporting the science behind the Silva Method."
                ]}
                white={false}
                center={false}
              />
              <ParaComp
                data={[
                  "Does the Silva method really work? ....  Find Out What Studies Show."
                ]}
                white={false}
                center={false}
              />


            </div>

            <div className="my-4">

              <h1
                style={{
                  fontWeight: "700",
                  fontSize: isDesktopOrLaptop || isBigScreen ? "40px" : isTablet ? "30px" : "25px",
                  textAlign: "start",
                  color: "#000"
                }}
              >
                Silva Method Research: How Science Discovered  <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>The Silva Method</span>
              </h1>
              <HeadingNew title={"Pioneering Personal Growth Programs in America since"} colored={"1966"} small start />

              <ParaComp
                data={[
                  "For over five decades, we have empowered more than 12 million individuals across 110 countries to unlock the potential of their minds and transform their lives in remarkable ways. Utilizing a scientific and proven curriculum inspired by the groundbreaking work of our late founder, Jose Silva, who is widely recognized for introducing modern meditation to the Western world, we have guided our graduates in igniting profound and enduring positive transformations across various facets of their lives. From enhancing careers and finances to improving health, emotional well-being, and relationships, we have equipped individuals with the tools to create meaningful and lasting change."
                ]}
                white={false}
                center={false}
              />
              <div className="d-flex justify-content-center align-items-center">
                <Link to="/jose-silva-method">
                  <button className="primary_btn2">Read More</button>
                </Link>
              </div>
            </div>


          </div>

          <div className='second-section' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <div
              style={{
                width: isMobile || isTablet ? "100%" : "100%",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                textAlign: "center",
                padding: "20px",
              }}
            >
              <div className="my-3">
                <HeadingNew title={"A PROGRAM BACKED BY"} start colored={"SCIENCE & RESEARCH"} />
              </div>
              <p className='text-start'>
                We have spent nearly 50 years researching, experimenting &
                collaborating with leading scientists, researchers, and personal growth
                leaders. The first scientist to investigate the research of The Silva
                Method was Dr. J. Wilfrid Hahn of the Mind Science Foundation in
                California, who went on to endorse our work. Since then, various
                research institutions, universities, and scientists have studied and
                verified our research, including:
              </p>
              <img
                src={require("../../../images/seminar/campuses.webp")}
                alt="Research Campuses"
                style={{ width: "100%", height: "auto", marginTop: "20px" }}
              />
              <p className='text-start'>
                Over the years we have continued to fine-tune the process of tapping
                into the human mind's inner potential—and often profoundly changing
                our students' lives in the process. We do this through an optimized
                curriculum that includes the Centering Exercise, guided meditations,
                visualization techniques, intuition-enhancing tools, and Theta level
                exercises, which guide our students into an even deeper level of mind
                than Alpha.
              </p>
              <button className='cssbuttons-io-button3'>
                Enroll Now
              </button>
            </div>
          </div>

          <div className='container' id='section1'>
            <HeadingNew title={"How Jose Silva developed"} colored={"The Silva Method"} small start />
            <NewPara
              start={true}
              para={"Jose Silva, born in 1914 in Laredo, Texas, began his career as a radio repairman and electronics technician. His fascination with the mind and its capabilities led him to explore how mental techniques could improve performance and problem-solving.  Initially designed to enhance his children’s academic performance, Silva's techniques evolved through rigorous experimentation and observation. After 22 years of vast research In the 1960s, Silva developed his method by integrating his technical knowledge with psychological principles. His breakthrough came when he successfully applied his techniques to enhance learning and intuition. Silva's method gained popularity, leading him to establish Silva Mind Control seminars and workshops worldwide. "}
            />

          </div>


          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              width: "100%",
              margin: "auto",
              padding: "15px",
            }}
          >


            <div style={{ width: "100%", textAlign: "center" }}>
              <img
                src={require("../../../images/MindBodyHealing/jose_research.webp")}
                alt=""

              />
              <div
                style={{
                  backgroundColor: "#e4f1fd",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <h3 className="text-dark mt-2">JOSE SILVA</h3>
                <p className="text-dark">FOUNDER OF SILVA METHOD</p>
              </div>
            </div>


            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
            
              <HeadingNew title={"IN 1966, A RADIO REPAIRMAN FROM TEXAS FOUND THE ANSWER."} start />

              <ParaComp
                data={[
                  "Jose Silva began his journey as a humble radio repairman in Laredo, Texas, and later as an electronics instructor with the United States Army Signal Corps.",
                  "Despite his career in electronics, Silva had a keen interest in hypnosis and the workings of the human mind. He spent his days working at his job and his nights studying the work of thought leaders like Freud, Jung and Adler.",
                  "The turning point that rocketed Silva towards his breakthroughs was a simple question. How do you make the mind more efficient?",
                  "Through his professional knowledge, Silva already knew that reducing the resistance in an electrical wire allowed more electricity to flow through it (known as Ohm's law). Using this theory as an anchor, he then wondered.."
                ]}
                white={false}
              />

            </div>


            <div
              style={{
                width: "100%",
                margin: "auto",
                padding: "25px 10px",
                textAlign: "center",
              }}
            >

              <HeadingNew start title={"What would happen if you could reduce the resistance in the human brain?"} />

              <ParaComp
                data={[
                  " Would doing this cause it to be more efficient at absorbing information, and perhaps even grant it access to reservoirs of previously hidden creativity and intuition? Driven by this theory, Silva began a thorough process of research and experimentation, starting with his own children. By training them to function at brain frequency levels known as Alpha and Theta-levels of deep relaxation that most people experience while meditating or in light sleep-he was able to significantly improve their grades at school, and even found evidence of enhanced intuitive functioning within them."
                ]}
                white={false}
                center={false}
              />
              <Table>
                <thead>
                  <tr style={{ backgroundColor: "#8019da", color: "white" }}>
                    <th>State</th>
                    <th>Brain Wave Frequency (cycles per second)</th>
                    <th>Associated with...</th>
                  </tr>
                </thead>
                <tbody >
                  <tr style={{ color: "black", fontWeight: "600" }}>
                    <td>Beta</td>
                    <td>14-21 and higher</td>
                    <td>
                      {" "}
                      Waking state, the five senses. Perception of time and space.
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontWeight: "600" }}>
                    <td>Alpha</td>
                    <td>7-14</td>
                    <td>
                      Light sleep, meditation, intuition. No time and space
                      limitation.
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontWeight: "600" }}>
                    <td>Theta</td>
                    <td>4-7</td>
                    <td>Deeper sleep, meditation.</td>
                  </tr>
                  <tr style={{ color: "black", fontWeight: "600" }}>
                    <td>Delta</td>
                    <td>0-4</td>
                    <td>Deep sleep. You are unconscious at Delta.</td>
                  </tr>
                </tbody>
              </Table>
              <div style={{ padding: "20px 0px", textAlign: "left" }}>

                <ParaComp
                  data={[
                    "In the following years, Silva began sharing his methods with other people in his community. What he found was that by teaching people to consciously guide themselves to the Alpha and Theta level of mind through Centering Techniques, he could show many of them how to, while fully conscious, reprogram their minds like a computer.",
                    "Why is this important? Well, imagine being able to rewire yourself, without spending thousands of dollars on a professional hypnotherapist. What if you could more easily overcome bad habits like smoking or snacking? What if you could raise your IQ, cultivate a positive wealth mindset, and awaken your mind's natural healing capacity, all by rewiring your subconscious?",
                    "The result that followed were highly encouraging. A multitude of silva practitioners began reporting a variety of benefits, from stress relief to enhanced creativity and intuition to even potentially accelerated physical and emotional healing.",
                    "In short, people who used The Silva Method were often able to achieve greater control over their emotional state, their habits, their careers and finances, and their health."
                  ]}
                  white={false}
                  margin={false}
                />

              </div>
              <NavLink to="/silva-method-courses">
                <button
                  style={{
                    height: "70px",
                    width: "250px",
                    border: "none",
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "35px",
                  }}
                >
                  Start Learning Now
                </button>
              </NavLink>
            </div>


          </div>


          <div className='container third-section'>
            <div className='d-flex justify-content-center align-items-start flex-column'>
              <HeadingNew
                title={"The Silva Method: "}
                colored={"A Psychorientology"}
                start
                small
              />


              <NewPara
                para={
                  "Initially, José Silva faced skepticism, from his friends even from his wife Paula, until she eventually saw the positive effects on their children. Eventually, Silva’s work attracted the attention of one scientist, who became a close friend and associate and brought the research findings to the attention of many other scientists. His pioneering research, overcoming numerous challenges, created lasting memories and marked the first step in human evolution through metaphysics. This is how scientists discovered the new science Silva named “Psychorientology”."

                }
                start={true}
              />

            </div>

          </div>
          {/* Add more content sections here */}


          <div className='container mt-4 fourth-section'>
            <div style={{ backgroundColor: "#9b37f2" }}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: isMobile || isTablet ? "column" : "row",
                  margin: "auto",
                  padding: "15px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 className='robotofont white-color' style={{ color: "white" }}>
                    " Based on the newest findings from Harvard's recent genetic study
                    on the effects of relaxation, breathing awareness, and positive
                    guided imagery, I feel confident that many of the methods, as
                    taught by Silva International, can stimulate gene expression in
                    the brain that are involved in reducing physical and psychological
                    stress and boosting immune functioning. "
                  </h3>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={RobertImage}
                    alt=""
                    width="auto"
                  />
                  <div
                    style={{
                      backgroundColor: "white",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    <h3>Mark Robert Waldman</h3>
                    <p>
                      NEUROSCIENTIST & BESTSELLING AUTHOR OF HOW GOD CHANGES YOUR
                      BRAIN
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className='container fifth-section' id='section2'>
            <div>
              <div style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                textAlign: "center",
                padding: "20px 0 20px 0px"
              }}>

                <HeadingNew
                  title={"How Science Discovered"}
                  colored={"Silva Method"}
                  start
                  small
                />
                <img
                  src="https://silvamethod.com/static/media/research-silvamethod.ce27548ac289b5641e95.webp"
                  alt="How Science Discovered Silva Method"
                  className='mt-4'
                  style={{ width: "100%", height: "auto", marginBottom: "20px", borderRadius: "15px" }}
                />
                <p style={{ marginBottom: "20px" }} className='text-start robotofont'>
                  The first scientist to investigate José Silva's research in Laredo, Texas, was Dr. J.W. Hahn, the director of the Mind Science Foundation in San Antonio, Texas. Through Dr. Hahn's involvement, numerous other scientists became interested in the pioneering research into the mind and human potential. The Mind Science Foundation was established by oil millionaire Tom Slick Sr., who requested Dr. Hahn to examine Silva’s work. Dr. Hahn returned with a highly favorable report.
                </p>
                <p className='text-start robotofont'>
                  Following Dr. Hahn’s visit, he contacted Dr. N.E. West, the head of the Department of Psychology at Wayland Baptist University in Plainview, Texas. He arranged for him to visit Laredo to review Silva’s findings. Dr. West was impressed by what he observed. Despite having visited the laboratories of Dr. J.B. Rhine at Duke University on two occasions, he remarked that he had never seen so much valid scientific work done on ESP.
                </p>
              </div>
            </div>


          </div>
          <div style={{ padding: "20px 0" }}>
            <div style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              textAlign: "start",
              padding: "20px"
            }}>

              <HeadingNew
                title={"The Rise of the "}
                colored={"Silva Method"}
                small
              />
              <p className='text-start robotofont mt-2' style={{ marginBottom: "20px" }}>
                Dr. West invited Silva to lecture on the Silva Method to psychology students in Plainview. The visit was so successful that he recommended Silva to Dord Fitz, an art professor in Amarillo. Impressed by the interest of his students, Fitz invited Silva to teach a class, which attracted over 80 attendees. The high demand led to more classes, and the Silva Method quickly grew by word of mouth.
              </p>
              <p className='text-start robotofont' style={{ marginBottom: "20px" }}>
                Today, the Silva Method is offered in over 110 countries and 29 languages, benefiting millions. Meanwhile, Silva’s visit to the Mind Science Foundation Laboratory in Los Angeles reinforced his belief in the mind’s ability to influence matter through thought.
              </p>
              <img
                src="/img/bg/science/Jose-Silva-archive-photo.webp"
                alt="José Silva and Art Professor Dord Fitz"
                style={{ width: isMobile ? "100%" : "50%", height: "auto", margin: "20px 0" }}
              />
              <p className='text-start robotofont' style={{ fontStyle: "italic", marginTop: "10px", maxWidth: isMobile ? "100%" : "50%" }}>
                ART PROFESSOR DORD FITZ (left) and José Silva are interviewed by an Amarillo, Texas television station in the art studio where the first Silva Method lectures were conducted.
              </p>
            </div>

            <div className="container my-5">
              <HeadingNew title={"Great Personalities across Different Fields are drawn by"} colored={"The Silva Method"} start />

              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <ResearchCard quote={"The Silva Method helped me with my concentration, and it helped me to relax before the pressures of a game."}
                  quoteowner={"- Russell “Bucky” Dent"}
                  para={"Numerous world-class athletes continue to leverage the principles of the Silva Method to visualize their desired outcomes, sharpen their focus, and maintain peak mental and physical conditions. The Silva Method has garnered significant popularity, particularly in sports that emphasize a holistic mind-body approach toward attaining athletic excellence Russell “Bucky” Dent, a baseball player for the Chicago White Sox, embarked on his journey with the Silva Method. Three years later, he achieved an extraordinary accomplishment by being named the Most Valuable Player at the World Series."}
                  banner={"Sports"}
                  img={require("../../../images/research/russel_bucky.jpg")}
                />
                {/* <ResearchCard
              banner={"Business"}
              quote={"Silva demonstrates that there is no limit to what the mind can do."}
              quoteowner={"- Robert Stiller, CEO of Green Mountain Coffee "}
              img={require("../../../images/research/robert-stiller_416x416.webp")}
              para={"Renowned corporations including RCA Records, Green Mountain Coffee, and The Arnold Agency have recognized the value of Silva training for their employees. The reported advantages encompass a wide range of areas, spanning amplified creativity, enhanced business management, boosted motivation, and improved stress management."}
            />

            <ResearchCard
              para={"Artists, writers, actors, and performers from diverse backgrounds widely embrace the Silva Method. Esteemed personal growth leaders, coaches, and authors also actively utilize, endorse, and draw inspiration from this transformative methodology. Wayne Dyer, for instance, holds high regard for any book authored by Jose Silva, even before delving into its contents. Highly inspired by Silva’s groundbreaking research, Shakti Gawain wrote the best-selling book- “Creative Visualisation."}
              quote={"Tap into a deep storehouse of knowledge and wisdom."}
              quoteowner={"-Shakti Gawain, Author of  “Creative Visualisation” & “Living in the Light”"}
              banner={"Personal Growth"}
              img={require("../../../images/research/shakti_gayen.jpg")}
            /> */}
                <ResearchCard
                  banner={"Well- Being"}
                  para={"Within the realm of healthcare, the principles of the Silva Method have gained wide acceptance as a vital aspect of holistic healing. Esteemed healthcare professionals and scientists, including Dr. O Carl Simonton of the Simonton Research Institute, have incorporated the Silva Method into their therapeutic regimens, recognizing its immense power. Professor Clancy D. McKenzie, a distinguished figure in mental health, has successfully utilized Silva's techniques to assist his patients on their healing journey."}
                  quote={"the most powerful single tools that I have to offer patients."}
                  quoteowner={"-Dr. O Carl Simonton Founder of the Simonton Research Institute"}
                  img={require("../../../images/research/simonton_Kassel.png")}
                />

                <Link to={"/success-stories"} className='mt-2'>
                  <button className='cssbuttons-io-button3'>
                    See More Testimonials

                  </button>

                </Link>
              </div>
            </div>
          </div>
          <div style={{ padding: "20px 0 0 0" }} id='section3'>
            <div style={{
              width: "95%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              textAlign: "start",
              padding: "20px 0px 20px 0px"
            }}>

              <HeadingNew
                title={"Silva Method "}
                colored={"Research & News"}
                small
                start
              />

              <p className='mt-3' style={{ marginBottom: "20px" }}>
                Silva continued his research with the support of Dr. Hahn. To validate the Silva Method, Dr. Hahn conducted a major survey, mailing 10,000 graduates. Over 1,100 responded, reporting spectacular results, with 99.6% satisfaction.
              </p>
              <p style={{ marginBottom: "20px" }}>
                Dr. Hahn replicated the survey in Albuquerque, confirming the same high satisfaction. Armed with this proof, he persuaded other scientists to investigate. Dr. Puryear hosted a Silva Method class at Trinity University and Drs. Barns and McKenzie invited Silva to lecture at the University of Texas Medical School, impressing attendees by demonstrating conscious control of brain waves.
              </p>
              <p style={{ marginBottom: "20px" }}>
                Dr. Hahn then arranged for Dr. Bremner at Trinity University to test Silva's trainees. This led to the first scientific verification of brain wave control, with results published in Neuropsychologia, Vol. 10, pages 307-312 and 467-469.
              </p>
              <img
                src="/img/bg/science/drwilfred.webp"
                alt="Dr. J. Wilfred Hahn and José Silva"
                style={{ width: isMobile ? "100%" : "50%", height: "auto", margin: "20px 0" }}
              />
              <p style={{ fontStyle: "italic", marginTop: "10px", maxWidth: isMobile ? "100%" : "50%" }}>
                LECTURING TOGETHER are Dr. J. Wilfred Hahn (left) and José Silva (right) at the Silva Method two-week Ultimate Seminar in Laredo, Texas.
              </p>
            </div>
          </div>




          <div style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            textAlign: "start",
            padding: "20px"
          }}>
            <HeadingNew small title={"New Projects for "} colored={"Jose Silva "} start />

            <NewPara
              para={"During his visit to the Mind Science Foundation in Los Angeles, Dr. Hahn suggested three projects to José Silva:"}
              start={true}
            />

            <TickSection data={[
              "Investigate a Brazilian psychic surgeon by the name of Arrigo.",
              "To bring Uri Geller to the U.S. to study the energy that he used to bend spoons.",
              "To teach the Silva Method to 49 Ph.D. geologists and petroleum engineers of the McCullough Oil Corp."
            ]} />

            <NewPara
              start={true}
              para={"However, Arrigo died in a car accident before the project could start. Silva opted not to bring Geller to the U.S. as he found no practical application for spoon bending. The results of the oil company's ESP training were never released. Dr. Hahn left the Foundation to join Silva in Laredo, where Dr. George DeSau conducted numerous studies on the benefits of the Silva Method across various groups, including students, alcoholics, welfare mothers, and business executives. DeSau's research in Philadelphia, Guam, and Venezuela showed consistent positive results."}
            />

            <NewPara
              start={true}
              para={"Silva also collaborated with Dr. Richard McKenzie from the University of Texas Medical School. Over the years, the public’s understanding of the field grew, leading Silva to authorize a shorter version of the Basic Lecture Series (BLS) while still covering all techniques. Specialized BLS presentations were developed for corporations and salespeople, and home study courses were created for diverse areas such as health, relationships, sports, family, and business management."}
            />

            <div style={{
              marginTop: "20px",
              width: isMobile ? "100%" : "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}>
              <img
                src='/img/bg/science/Mind-Science-Foundation.webp'
                alt='Mind Science Foundation'
                style={{
                  borderRadius: "15px",
                  marginBottom: "10px"
                }}
              />
              <NewPara
                para={"MIND SCIENCE FOUNDATION director J. Wilfred Hahn, (left) brought José Silva to the Los Angeles branch of the Foundation to observe their research."}
                start={true}
              />
            </div>
          </div>
          <div className='container'>
            <HeadingNew
              title={"Presentation to "}
              colored={"Scientists"}
              small
              start
            />

            <NewPara
              start={true}
              para={"The three scientists are now on the Silva International staff. Dr. Hahn, Dr. DeSau, and Dr. McKenzie were instrumental in arranging for 40 scientists from all over the United States to meet Silva in Houston, Texas, so that he could explain to them what he had done and what his future plans were. Those in attendance were:"}
            />

          </div>


          <div>
            <div style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              textAlign: "start",
              padding: "20px"
            }}>
              <TickSection data={[
                "Rev. Marzel Allard, S.J., Montreal, Quebec",
                "V.A. Benignus, Ph.D., Univ. of North Carolina",
                "Mrs. Gayla Benignus, M.S., Univ. of North Carolina",
                "Mrs. Lendell W. Braud, M.A., Texas Southern Univ.",
                "William G. Braud, Ph.D., Univ. of Houston",
                "Frederick J. Bremner, Ph.D., and his wife, Trinity Univ.",
                "Barbara B. Brown. Ph.D., Veterans’ Administration Hospital, Sepulveda, Calif.",
                "Melvin Bucholtz, emissary for Dr. David Shapiro, Harvard Medical School",
                "Thomas H. Budzynski, Ph.D., the Univ. of Colorado Medical Center",
                "Jeffery P. Chang, Ph.D., Univ. of Texas Medical Branch • Leslie M. Cooper, Ph.D., Brigham Young Univ.",
                "Arthur J. Deikman, M.D., Mill Valley, Calif.",
                "George T. DeSau, Ed.D., Silva Int.",
                "Paul Dunn, M.D., Oak Park, Illinois",
                "Doug Eddy, Ph.D., Carnegie-Mellon, Pittsburgh",
                "Stan L. Falor, M.D., McMurray, PA",
                "Lester G. Fehmi, Ph.D., New York State Univ.",
                "Rev. John Frizelle, Ph.D., Incarnate Word College, San Antonio",
                "Irving Geller, Ph.D., Texas Tech Univ. School of Medicine",
                "J.W. Hahn, Ph.D., Silva International",
                "Dr. Paul Henchaw, Tucson Arizona",
                "W. Lindsay Jacob, M.D., Pittsburgh, Pennsylvania",
                "Kendall Johnson, emissary for Thelma Moss, Ph.D., UCLA Medical Center, Los Angeles;",
                "Bonnie Kaplan, emissary for Thomas B. Mulholland, Ph.D., Veterans’ Administration Hospital, Bedford, Massachusetts",
                "Perry London, Ph.D., Univ. of Southern California, Los Angeles",
                "Alejandro Martinez Marquez, Ph.D., National Polytechnic Institute, Mexico, D.F.",
                "Clancy McKenzie, M.D., Bala Cynwyd, Pennsylvania",
                "Richard E. McKenzie, Ph.D., Silva International",
                "John Mihalasky, Ed.D., Newark College of Engineering;",
                "William Opel, filmmaker",
                "Dr. Robert E. Omstein, Langley Porter Institute, Univ. of Calif. Medical Center, San Francisco",
                "Bur l Payne, Ph.D., Way l and, Massachusetts",
                "Erik Peper, Berkeley, California",
                "William G. Roll, Psychical Research Foundation, Duke Station, Durham, North Carolina",
                "José Zarul Braiz, Arch., emissary for Engineer Benjamin Rubio, National Polytechnic Institute, Mexico, D.F.",
                "Milan Ryzl, Ph.D., San Jose, Calif.",
                "Gertrude Schmeidler, Ph.D., City College of the Univ. of New York",
                "Max Toth, Queens, New York",
                "N.E. West, Ph.D., and wife, Veterans’ Administration Hospital, Fort Meade, South Dakota",
                "lan Wickramasekera, Ph.D., Univ. of Illinois, Peoria."
              ]} />

              <NewPara
                para={
                  "The following doctors, Ph.D.s, and Silva International staff members took part in introducing Silva’s work to the visiting scientists:"
                }
                start={true}
              />

              <TickSection
                data={[
                  "Dr. J.W. Hahn",
                  "Dr. George DeSau,",
                  "Dr. Richard McKenzie",
                  "Dr. N.W. West",
                  "Dr. Fred Bremner",
                  "Dr. William Braud,",
                  "Dr. Jeffery Chang",
                  "Dr. Gayla Benignus",
                  "Dr. Paul Dunn",
                  "Dr. Stan Falor",
                  "Rev. John Frizelle",
                  "Dr. Irving Geller",
                  "Dr. Alejandro Martinez Marquez",
                  "Dr. Clancy McKenzie",
                  "Bill Opel",
                  "James Needham",
                  "Harry McKnight"
                ]}
              />
            </div>

            <div style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              textAlign: "start",
              marginTop: "40px"
            }}>
              <div style={{
                width: isMobile ? "100%" : "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}>
                <img
                  src='/img/bg/science/Silva-Method-scientists.webp'
                  alt='Silva Method Scientists'
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    marginBottom: "10px"
                  }}
                />
                <NewPara
                  start={true}
                  para={
                    "Silva Method scientists spoke at the Silva Method Convention in Mexico City in 1972. Front row: Dr. Richard McKenzie, Dr. Fred Bremner, Dr. Jeffrey Chang, Dr. William Braud, Dr. Irving Geller, Dr. Alejandro Martinez, and Cleve Baxter. Back row: Dr. Phillip Chancellor, Dr. J. Wilfred Hahn, José Silva, Juan Silva, Dr. Alberto Sanchez-Vilchis, Dr. N.E. West, and Harry McKnight"
                  }
                />
              </div>
            </div>
          </div>

          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >

            <HeadingNew title={"ARE YOU LIVING LIFE AT THE?"} colored={"'ALPHA LEVEL'"} start />

            <ParaComp
              data={[
                "Mind science research has suggested that the key to most of the things we want in life, whether it's abundance, career success, health, happiness or enlightenment, lies in a particular state of mind caused by specific frequencies of the brain.",
                "Scientists call these frequencies alpha and theta brainwaves frequencies.",
                "We attained these states of mind unconsciously during sleep.The question is... how do you achieve this state of mind during waking consciousness? And more importantly, how do you use it to overcome your limitations and challenges, triumph over your unwanted habits and negative thought patterns, and enrich certain aspects of your life?"
              ]}
              white={false}
            />

          </div>

          <div className='mt-4' id='section4' style={{
            marginRight: "20px"
          }}>


            <div style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              textAlign: "start",
              marginBottom: "20px"
            }}>
              <HeadingNew start title={"Control of "} colored={"Brainwaves"} />
              <HeadingNew title={"Research by Dr. Fred Bremner"} small start />
            </div>




            <div style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              textAlign: "start"
            }}>
              <NewPara
                start={true}
                para={
                  "Since 1966, when the first Silva Method Basic Lecture Series was introduced, millions of people worldwide have embraced this mental training technique. The reports of positive outcomes from those who have practiced the Silva Method are as extensive as the number of participants."
                }
              />
              <NewPara
                start={true}
                para={
                  "Over the years, numerous scientific studies have explored the benefits reported by Silva Method practitioners, both physiological and psychological. This section provides a summary of some of these research findings."
                }
              />
              <NewPara
                start={true}
                para={
                  "Recent advancements in biofeedback have highlighted the potential for voluntary control over various physiological functions, including brain waves. The ability to generate Alpha rhythm, which is associated with relaxation, stress reduction, improved intuition, and other advantages, has been demonstrated in individuals trained in the Silva Method, who have shown the capacity to control this Alpha rhythm voluntarily."
                }
              />
            </div>

            <div style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
            }}>
              <img
                src='/img/bg/science/pvbynzj4ftuhbex1vyxz.jpg'
                alt='Brainwaves Research'
                style={{
                  width: "100%",
                  borderRadius: "15px"
                }}
              />
            </div>
          </div>


          <Container className="my-5" id='section3'>
            <HeadingNew title={"Silva Method Research Projects Overview"} start />
            {projects.map((project, index) => (
              <div className="project-section mb-5 mt-5" key={index} id={project.id || ""}>
                <div className='d-flex flex-column align-items-start'>

                  <div className="project-content text-start">
                    <h3>{project.title}</h3>
                    {project.image && (
                      <Image
                        src={project.image}
                        alt={project.title}
                        fluid
                        style={{ width: "95%", borderRadius: "15px", marginBottom: "20px" }}
                      />
                    )}
                    <p>{project.description}</p>
                    {project.points && (
                      <TickSection data={project.points} />
                    )}
                    {project.description2 && (
                      <h5 className='text-dark mt-2'>{project.description2}</h5>
                    )}

                  </div>
                </div>
              </div>
            ))}
          </Container>


          <Container className="my-5">
            <HeadingNew small title={"The Silva Method’s results for "} start colored={"Habit Control"} />

            <div className="d-flex flex-column align-items-start">
              <Image
                src='/img/bg/science/habitcontrol-1.png'
                alt="Habit Control"
                fluid
                style={{ width: '95%', borderRadius: '15px', marginBottom: '20px' }}
              />
              <NewPara
                para={"The control of habits such as smoking and overeating can be difficult. Participants report success using The Silva Method to control habits."}
                start={true}
              />
            </div>

            <Row className='my-5'>
              <Col md={12} className='d-flex flex-column align-items-start'>
                <div className='text-start'>
                  <p>
                    <b>COMBINED ESP EVENTS -</b>
                    {" "} Precognition Telepathy Clairvoyance After completion of the BLS, there was a marked decrease in the number of people who reported zero psychic experiences prior to the class.
                  </p>
                  <p>
                    <b>PSYCHIC EXPERIENCES -</b>
                    {" "} The same people reported an increase in psychic experiences after the Basic Lecture Series.
                  </p>
                </div>
              </Col>
              <Col md={12} className='d-flex justify-content-start'>
                <Image
                  src='/img/bg/science/BeliefinESP2-1.png'
                  alt="ESP Belief"
                  fluid
                  style={{ width: '95%', borderRadius: '15px' }}
                />
              </Col>
            </Row>
          </Container>


          <div className='container mt-5'>
            <div className='d-flex justify-content-center align-items-start flex-column'>
              {/* <HeadingNew title={"High School Personality Questionnaire"} /> */}
              <h2 style={{
                color: "black",
                fontWeight: "600"
              }}>
                High School Personality Questionnaire
              </h2>
              <HeadingNew small title={"Research by Dr. George DeSau:"} start />

              <div className='mt-1' style={{
                maxWidth: "100%"
              }}>
                <NewPara
                  start={true}
                  para={"This project illustrates the changes that occurred to high school students after The Silva Method was taught. The instrument used Cattell’s High School Personality Questionnaire (HSPQ), was selected for its wide range of factors that relate to claims for The Silva Method."}
                />
                <NewPara
                  start={true}

                  para={
                    "About t Value The t value is obtained by a mathematical formula determined by statistical tables. The higher the t value the more significance it has. All t values in these results have reached the predetermined level of positive statistical relevance."
                  }
                />
                <NewPara
                  start={true}

                  para={"About p Value As the statistical measure of probability, p indicates the least probable number of times an exactly controlled experiment must be repeated to obtain the given results by pure chance. For example, if p=.01, then more than one hundred repetitions of the original experiment."}
                />

              </div>


            </div>

          </div>


          <Container className="my-5">
            <HeadingNew title={"Research Results and Research Projects Overview"} start />

            <div className="my-4">
              <HeadingNew title={"The Silva Method’s Results for Ego Strength"} small start />
              <img src='/img/bg/science/Egostrength.png' />
              <NewPara
                para={"Psychological pre-and post-testing of high school students showed a strong movement toward 'high ego strength, mature, facing reality, calm,' and away from being easily upset."}
                start={true}
              />
            </div>

            <div className="my-4">
              <HeadingNew title={"The Albuquerque Report"} small start />
              <NewPara
                para={"This research was done to measure the positive influence of the Silva Method on personality factors. Conducted with a group of 30 people in a regular Basic Lecture Series."}
                start={true}
              />
              <NewPara
                para={"Albuquerque, New Mexico, 1974."}
                start={true}
              />
              <NewPara
                para={"George T. DeSau, Ed.D., and Paul Seawell."}
                start={true}
              />
              <NewPara
                para={"The results confirm the informal observations of definite and valuable changes taking place in Silva Method courses. The individuals tested appear to be moving away from any indication of neurotic disorders and toward greater self-guidance, fulfillment, and balance based on their own internal points of reference. The researchers said that, 'this shift appears significant indeed.'"}
                start={true}
              />
              <NewPara
                para={"Published by Silva Int, Inc., 1974."}
                start={true}
              />
            </div>

            <div className="my-4">
              <HeadingNew title={"The Silva Method’s Results for Self-Assurance, Free of Guilt"} small start />
              <img src='/img/bg/science/Screenshot-2020-06-11-at-15.55.30.png' />
              <NewPara
                para={"A test group showed responses indicating 'tranquil, composed, relaxed,' and away from 'tense, frustrated, driven, overwrought.' This shift is characteristic."}
                start={true}
              />

            </div>
          </Container>


          <Container className="my-5">
            <HeadingNew title={"Incarnate Word High School Project"} small start />

            <div className="my-4">
              <NewPara
                para={"This research was done to find out what benefits the high school students felt they received from the Silva Method Basic Lecture Series."}
                start={true}
              />

              <TickSection
                data={[
                  "Incarnate Word School for Girls, San Antonio, Texas, 1972.",
                  "Ray Glau, Don Kreipke, and Ron Bynum from the Silva Method; and Sister Miriam Garana, and Sister Teresa Connors of Incarnate Word High School.",
                  "A total of 371 of the 460 students from seventh grade to seniors took the Silva Method Basic Lecture Series. The results were very positive. School officials were surprised that so many of the students reported that the Silva Method helped to remove feelings of depression and fear.",
                  "Several students admitted that they had enrolled in the course because it was better than staying in class, but afterward, 96 percent said they were glad they had taken the course and felt it was worth the time.",
                  "“According to the numerous interviews, my own observations, and the results of the questionnaire, I can honestly say that the project was highly successful. It is not a panacea, but when the principles of the Silva Method are used effectively, it prepares the atmosphere in which confidence can grow, in which there can be personal and academic growth and achievement—the type of growth parents and educators dream of—the total development of the person.”",
                  "Sister Miriam was visited by Father John Rilley, principal of Hallahan High School in Philadelphia, which helped open the way for the Silva Method to be taught at Acre and in two other high schools in the area, with full psychometric testing to determine the actual personality factors that change when students learn to use more of their minds to help them solve more problems."
                ]}
              />

              <img className='mt-4' src='/img/bg/science/cgmz6fr8wbwoutfua9nq.jpg' style={{
                borderRadius: "15px"
              }} />





            </div>
          </Container>

          <Container className='my-5'>
            <HeadingNew title={"The Silva Method’s results for tranquility, relaxation"} small start />
            <NewPara
              para={"BLS students shifted toward “self-assured, placid, serene,” and away from “apprehensive, self-reproaching, and worry.” "}
              start={true}
            />
            <img src='/img/bg/science/tranquilityrelaxation.png' />


            <HeadingNew title={"Silva Method courses: their effects in three secondary schools"} small start />

            <NewPara
              para={"This research was done to measure the influence of the Silva Method on personality factors amongst high school students, with psychometric testing using Cattell’s High School Personality Questionnaire."}
              start={true}
            />
            <TickSection
              data={[
                "Philadelphia, Pennsylvania, 1974.",
                "Schools: Hallahan High School, 2,000 students; St. Fidelis High School, 120 students; Lawrenceville High School, 520 students.",
                "George T. DeSau, Ed.D.",
                "Positive results in all three schools. “A factor of change which is persistent and strong after Silva Method training is that of a shifting to internal points of reference—another way of saying the recognition by an individual of his own value and a significant step toward self-control as opposed to being controlled by external others. Further, the reduction of guilt… has tremendous therapeutic as well as educational implications. The other consistent element is that of individuals reporting themselves as being more relaxed and tranquil, certainly characteristics that are much needed in the educational milieu, to say nothing of society in general.”",
                "Published by Silva Int, Inc., 1974"
              ]}
            />
            <div className='mt-4' id='section8'>

              <HeadingNew title={"The Silva Method’s results for social orientation"} small start />
            </div>

            <div className='mt-4'>
              <NewPara
                start={true}
                para={"The development of an inner-conscious direction in the BLS leads to the release of inhibition in social interaction. A test group shifted toward “spontaneous and social boldness,” and away from “shy, timid and threat sensitive.”"} />

              <img src='/img/bg/science/Socialorientation.png' />
            </div>

            <div className='mt-4'>
              <HeadingNew title={"Niños Cantores del Zulia School Project"} small start />
              <NewPara
                start={true}
                para={
                  "This research was conducted ten years after the research at the schools in Pennsylvania to determine the influence of the BLS on students now that there is “a new level of awareness and commitment to the inclusion of a subjective mind training program for self-development” in school curricula."
                }
              />
              <TickSection
                data={[
                  "George T. DeSau, Ed.D., and Marcelino Alcala, 1981. ",
                  "Maracaibo, Venezuela.",
                  "The results were consistent with previous research. ",
                  "Published by Silva Int, Inc."
                ]}
              />

            </div>

            <div className='mt-4'>
              <HeadingNew title={"The Silva Method’s results for stability of ego strength"} start small />
              <NewPara
                start={true}
                para={"Follow-up testing shows that the major changes brought about by the Basic Lecture Series are long-lasting. In fact, there is an increase in stability over a period of time."}
              />
              <img src='/img/bg/science/stabilityofegostrength.png' />
            </div>

            <div className='mt-4' id='section9'>
              <HeadingNew title={"International Educational Investigation Project"} small start />
              <NewPara
                start={true}
                para={"This project was run to determine the effects of teaching the Silva Method in the Parochial schools in Guam."}
              />
              <TickSection
                data={[
                  "Guam, 1986.",
                  "Three parish schools.",
                  "Dr. George DeSau, Sister Mary Bernard Unpingo, Sister Naomi Curtin, Nelda Sheets.",
                  "Very positive results, as published in anecdotal reports by Silva International."
                ]}
              />

            </div>

            <div className='mt-4'>
              <HeadingNew
                title={"The Silva Method’s results for trust, cooperation"}
                start
                small
              />

              <NewPara
                start={true}
                para={"Basic Lecture Series students showed a greater willingness to work with others, rather than being continuously competitive, suspicious, and distrustful."}
              />

              <img src='/img/bg/science/trustcooperation.png' />

            </div>

            <div className='mt-4'>
              <HeadingNew
                start
                title={"The Silva Method’s results for enthusiasm"}
                small

              />
              <NewPara
                start={true}
                para={"BLS participants showed greater cheerfulness, enthusiasm, and joie de vivre. This movement away from gloominess and pessimism may well result from newfound freedom from prior anxieties and inner turmoil."}
              />
              <img src='/img/bg/science/Enthusiasm-1.png' />


            </div>

            <div className='mt-4'>
              <HeadingNew
                start
                small
                title={"The effect of the Silva Method’s Three Fingers Technique on the improvement of grades of university students"}
              />
              <NewPara
                start={true}
                para={"The students who had learned the Three Fingers Technique improved their test scores nine percentage points—or 17 percent—from one term to the next, while the control group actually dropped one point over the same period. "}
              />

              <TickSection
                data={[
                  "University of Tasmania, Hobart, Tasmania, Australia, 1988. ",
                  "Dr. E. Henry Stanton.",
                  "Published in the Journal of the Society of Accelerative Learning and Teaching, and by Silva International."
                ]}
              />

            </div>

            <div className='mt-4'>
              <HeadingNew
                start
                small
                title={"The Silva Method’s results: warmhearted, outgoing"}
              />
              <NewPara
                start={true}
                para={"This scale shows a movement toward the compassionate aspects of personality, with qualities such as “warmhearted, outgoing and participating,” and away from “reserved, detached, critical and aloof.” "}
              />

              <img src='/img/bg/science/warmheartedoutgoing.png' />

              <HeadingNew
                small
                start
                title={"The effect of very brief exposure to The Silva Method’s Three Fingers Technique on reducing anxiety while taking tests"}
              />

              <NewPara
                start={true}
                para={"With only 30 minutes of class time devoted to learning how to enter the Alpha level and use the Three Fingers Technique, almost every one of the 15 college students felt the exercise was helpful, and more than half the students felt that they obtained specific benefits in reducing test anxiety and improving test performance."}
              />
              <TickSection
                data={[
                  "Appalachian State University, Boone, North Carolina, 1990.",
                  "Dr. George Maycock."
                ]}
              />

            </div>

            <div className='mt-4'>
              <HeadingNew
                title={"The Silva Method and academic performance in higher education"}
                small
                start
              />

              <NewPara
                para={"This research was done to measure the effect of the Silva Method on the learning process, intelligence quotient, and personality factors among university students."}
                start={true}
              />

              <TickSection
                data={[
                  "Autonomous University of Tlaxcala, Mexico, 1986.",
                  "Lilia Alcira Vaca, M.D., Departmental Director of Psychopedagogy of the above university, Jorge Mario Medina, Edmundo Sanchez Collazos.",
                  "Results indicate that 67.7 percent of the subjects improved their IQ classification. There was also a significant improvement in personality traits such as sociability, communication, confidence, self-acceptance, unproved self-image, self-reliance, development of creativity, and others. “Therefore, it can be stated that the practice of the Silva Method techniques contributes to the improvement of academic performance.”",
                  "Published by Dinámica Mental Método Silva, Mexico, D.F."
                ]}
              />

            </div>

            <div className='mt-4'>
              <HeadingNew
                small
                start
                title={"Developments of Self-Image"}
              />
              <h3 className='mt-3' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                Research by James Motiff:
              </h3>
              <NewPara
                start={true}
                para={"There are many aspects to self-concept and self-image. An individual’s self-conception is of critical importance in life. Psychological testing has confirmed the positive effects of the Basic Lecture Series on several characteristics of self-image."}
              />

              <h3 className='mt-3' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                RCA Records Executives

              </h3>
              <NewPara
                start={true}
                para={"The research was conducted to determine positive personality changes in 25 executives with RCA Records as a result of taking the BLS."}

              />

              <TickSection
                data={[
                  "Glen Rock, New Jersey, 1982.",
                  "George T. DeSau, Ed.D.",
                  "Results: “Indications of change were very positive in terms of personal development as well as viewed from the perspective of characteristics that should make for effective management.” ",
                  "Published by Silva International, Inc."
                ]}
              />

            </div>

            <div className='mt-4' id='researchsection'>
              <HeadingNew
                small
                start
                title={"Research results and research projects overview"}
              />

              <h3 className='mt-3' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method’s results for physical self-image

              </h3>

              <NewPara
                start={true}
                para={"Basic Lecture Series – participants perceived themselves to be healthier than before the Silva Method classes and were more accepting of their appearance, behavior, and stature."}
              />

              <img src='/img/bg/science/physicalselfimage.png' />

              <h3 className='mt-3' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Ottawa County Project
              </h3>

              <NewPara
                para={"This research was designed to measure the effect of the Silva Method as a rehabilitation tool in social work."}
                start={true}
              />

              <TickSection
                data={[
                  "Social Services Department, Ottawa County, Michigan, and Psychology Department of Hope College, Holland, Michigan, 1974.",
                  "Dr. James Motiff, Psychology Department, Hope University, Holland, Michigan; Larry Hildore, and David A. Wallace.",
                  "Positive results. Test data revealed that the participants—60 single mothers on welfare—showed marked improvement in every category except “Remembrance and Insight from Dreams.” The most frequently expressed feeling from participants was thankfulness for the Silva Method experience; the second most expressed feeling was that of wanting to help others. Within a few weeks of completing the BLS, 40 of the 60 women gained employment. ",
                  "Published by Silva International, Inc., 1974."
                ]}
              />

              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>

                The Silva Method’s results for personal self-image
              </h3>

              <NewPara
                para={"Adherence to moral or ethical standards and greater concern for the rights of others were perceived as greatly enhanced by the BLS, along with general self-esteem."}
                start={true}
              />
              <img src='/img/bg/science/personalselfimage.png' />


              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method and personality

              </h3>

              <NewPara
                para={"This research was done to measure the influence of the Silva Method on the personality variables of 200 people"}
                start={true}
              />
              <TickSection
                data={[
                  "Complutense University, Madrid, Spain, 1981.",
                  "Pilar Usanos Tamayo, Psy.D.",
                  "Positive results are shown. Prior to the Silva Method, there were no differences between the experimental and the control group. After the BLS there were significant differences: The subjects who took the course were more open and communicative than those who didn’t. Four months after the BLS, in the follow-up phase, the difference between the two groups was greater.",
                  "Doctoral Thesis: Mind Control and Personality Variances; Experimental Study, Pilar Usanos, Madrid Complutense University, 1984. Published in Mexico by 0. Multicolor, 1981."
                ]}
              />

              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method’s results for moral-ethnical self-image
              </h3>

              <NewPara
                para={"Balance, integration of emotions, and harmony in personal affairs showed a significant improvement."}
                start={true}

              />

              <img src='/img/bg/science/Screenshot-2020-06-11-at-16.28.02.png' />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The alcoholic study
              </h3>

              <NewPara
                para={"This research was done to measure the influence of the Silva Method on personality changes amongst a group of alcoholics and their spouses."}
                start={true}
              />

              <TickSection
                data={[
                  "Half-way house, 1973.",
                  "George T. DeSau, Ed.D., and Hector M. Cantú, M.S.W.",
                  "Positive results. Six months after completion of the BLS, 12 of the 15 alcoholics had no alcohol in the interim. One had taken a glass of wine and stopped. One had twice started drinking but managed to stop without becoming intoxicated to the point of requiring hospitalization. One required hospitalization for excessive drinking of alcohol.",
                  "Published by Silva International, Inc.",
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method’s results for family self-image

              </h3>

              <NewPara
                para={"Balance, integration of emotions, and harmony in personal affairs showed a significant improvement."}
                start={true}

              />

              <img src='/img/bg/science/familyselfimage-1.png' />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                Performance enhancement through visualization and mental rehearsal


              </h3>

              <NewPara
                para={"Measuring and comparing the effectiveness in improving athletic skills by:"}
                start={true}

              />

              <NewPara
                para={"1) the use of mental rehearsal at the alpha level (using Silva Method techniques)"}
                start={true}
              />

              <NewPara
                para={"2) the use of physical practice"}
                start={true}
              />
              <NewPara
                para={"2) the use of physical practice"}
                start={true}
              />
              <NewPara
                para={"3) the use of a combination of mental and physical practice."}
                start={true}
              />

              <TickSection
                data={[
                  "Nyack Junior High School, Nyack, New York, 1987. ",
                  "Cecelia A. Prediger. ",
                  "Results: Mental practice produced almost as much improvement as the same amount of time in physical practice and a combination of mental and physical practice for the same total length of time produced more than twice as much improvement in a specific athletic skill.",
                  "Published by Silva International, Inc., 1987."
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method’s results for self-image: identity
              </h3>
              <NewPara
                para={"This factor deals with an individual’s total concept of “What I Am.” BLS students learned to value themselves more highly."}
                start={true}
              />

              <img
                src='/img/bg/science/Screenshot-2020-06-11-at-16.34.46-1.png'
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The consciousness movement and the mental patient

              </h3>

              <NewPara
                para={"This research was conducted by two psychiatrists with their patients to measure the effects of the Silva Method on seriously disturbed psychiatric patients."}
                start={true}
              />
              <TickSection
                data={[
                  "Philadelphia, Pennsylvania, 1980.",
                  "Clancy D. McKenzie, M.D., Director of Philadelphia Psychiatric Consultation Service; and Lance S. Wright, M.D., Senior Attending Psychiatrist, Institute of Pennsylvania Hospital and Associate Professor of Child Psychiatry at Hahnemann Medical Hospital.",
                  "Improvement was observed in all of the patients, except two, one of whom did not help and the other worsened due to factors not associated with the Silva Method. An efficient use of the control of dreams was also achieved to help correct problems. “The course was found to be safe and potentially beneficial for neurotics. It was relatively safe and beneficial for the highly disturbed individual, when under the care of a psychiatrist familiar with the program. Both clinical and objective psychological data show that the benefits far outweigh any negative effect.”",
                  "Published in Voices: The Art and Science of Psychotherapy, 1981. Also published by Silva International, Inc."
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method’s results for social self-image


              </h3>

              <NewPara
                para={"Self-image is of great importance in social interaction. Improvement in this area correlates with the ability to deal with people more satisfactorily."}
                start={true}
              />
              <img src='/img/bg/science/Socialselfimage.png' />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }} id='section10'>
                Florida Prison Project

              </h3>

              <NewPara
                start={true}
                para={"This research was conducted to determine the effects of the Silva Method Basic Lecture Series on a group of inmates who were incarcerated for the first time in the Florida state prison system"}
              />

              <TickSection
                data={[
                  "Indian River Correctional Institution, Vero Beach, Florida, 1979.",
                  "J.W. Hahn, Ph.D., Chaplain Gene M. Little, Ph.D., and Ed Bemd Jr.",
                  "The results were excellent. Prison Chaplain Gene M. Little, who administered personality tests to participants before and after the BLS, called it “A very positive program.” He added that none of the 14 participants in the program had gotten into any trouble since completing the BLS and that the inmates themselves reported that they were much more relaxed, and their grades in the high school and college courses were improving.",
                  "Published by the Silva Method of Brevard County, Florida, and by Silva International, Inc."
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method’s results for self-satisfaction

              </h3>

              <NewPara
                para={"How one feels about his or her identity is illustrated here. The Silva group learned to be far less critical and negative about themselves."}
                start={true}
              />

              <img src='/img/bg/science/Screenshot-2020-06-11-at-20.53.47-1.png' />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method and Psychoprophylactic Childbirth


              </h3>

              <NewPara
                para={"This project was undertaken to evaluate the effects of the Silva Method of relaxation for expectant parents and its influence on the baby."}
                start={true}
              />

              <TickSection
                data={[
                  "Ministry of Public Health, Puriscal, Costa Rica, Central America, 1982.",
                  "Socorro Rodriguez, M.D., Director of the Mental Health Department, Public Health Ministry of Costa Rica.",
                  "”Studies and experience in this field in Costa Rica show that through simple psychoprophylactic [sic] childbirth methods and Silva Method techniques adapted for this purpose, women and their partners can participate in the childbearing process from its beginning to actual delivery.”",
                  "Partial results published at the Silva Method 3rd. World Convention in San Jose, Costa Rica, 1982. 29. The Silva Method and Non-traumatic Induction to Anesthesia",
                  "Measured the effect of the Silva Method relaxation techniques amongst patients programmed for surgery, evaluating the reduction in their consumption of chemical anesthetics and the elimination of pain.",
                  "Specialties Hospital, Mexican Institute of Social Security, Puebla, Mexico, 1981.Miguel Bautista, M.D.",
                  "Positive results. 50 percent reduction in the use of chemical anesthetics. 90 percent elimination of pain during the 72 hours following the operation.",
                  "Work was presented at the Latinoamericano Congress for Anesthesia in Panama in 1981."
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method’s results: behavior
              </h3>
              <NewPara
                para={"Basic Lecture Series participants moved toward patterns indicating greater acceptance of their own behavior and a decrease in self-criticism."}
                start={true}
              />
              <img src='/img/bg/science/behaviour.png' />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method for improving inadequate behavior in children

              </h3>

              <NewPara
                para={"This research was done to show the effects of the Silva Method in the changing of inadequate behavior (aggression, rebelliousness, lack of discipline) in children."}
                start={true}
              />
              <TickSection
                data={[
                  "Education Faculty – Catholic University, Maniales, Colombia, 1985.",
                  "Dr. Milena de Munoz and Dr. Gloria Nelly Martinez. ",
                  "Positive results."
                ]}
              />
              <NewPara
                start={true}
                para={"This research was done to show the effects of the Silva Method in the changing of inadequate behavior (aggression, rebelliousness, lack of discipline) in children."}
              />
              <TickSection
                data={[
                  "Education Faculty – Catholic University, Maniales, Colombia, 1985.",
                  "Dr. Milena de Munoz and Dr. Gloria Nelly Martinez. ",
                  "Positive results."
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Silva Method and its uses

              </h3>

              <NewPara
                para={"This research was conducted to measure the influence of the Silva Method on personality factors among university professors."}
                start={true}
              />

              <TickSection
                data={[
                  "School of Dentistry, Autonomous University of the State of Mexico, Toluca. Edo. de Mexico,, 1980.",
                  "Psychology Department of the same university.",
                  "With the collaboration of Pilar Usanos Tamayo, Psy.D.",
                  "Researchers reported that the Silva Method “motivated a positive change in the subjects along the following lines: A feeling of healthiness, with less physical discomfort of possible organic origin; an increase in self-confidence, feeling more optimistic and useful; a certain advance in the process of psychological maturity; more acceptance and integration to social conventions; a decrease in susceptibility, fear, and apprehensions; higher acceptance of reality; a disposition towards extroversion, becoming more open and sociable.”",
                  "Published by Silva International, Inc."
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                Benefits of The Silva Method to teachers and counselors
              </h3>

              <NewPara
                para={"This research was conducted to determine how much benefit the Silva Method Basic Lecture Series offers to teachers who have already had considerable formal education when the BLS is included along with their regular university courses."}
                start={true}
              />
              <TickSection
                data={[
                  "Appalachian State University, Boone, North Carolina, 1985.",
                  "Dr. George Maycock. Appalachian State University.",
                  "Results: “This study showed that teachers and counselors can benefit from taking the Silva Method as part of their professional training. In this way, the benefits of the Silva Method will multiply as these teachers and counselors interact with their classes and clients.”",
                  "Published by Silva International, Inc."
                ]}
              />

              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                The Effect of The Silva Method on Cognitive Controls

              </h3>

              <NewPara
                para={"This research was done to determine the effects of meditation and relaxation on subjects’ ability to perceive objective information. "}
                start={true}
              />
              <TickSection
                data={[
                  "David Miller",
                  "Silva Method graduates who performed physical relaxation before entering the laboratory level demonstrated a greater tendency to focus their concentration inwardly. They were able to identify fewer objects than they could at the Beta level. Graduates who entered their laboratory level without relaxing demonstrated an increased ability in outwardly focused concentration from that of their Beta level."
                ]}
              />
              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>
                Performance enhancement through visualization
                <br />
                <i style={{
                  fontSize: "0.9rem"
                }}> By Cecelia A. Prediger
                </i>

              </h3>

              <NewPara
                start={true}
                para={"In a landmark research study, Cecelia A. Prediger of Nyack Junior High School, New York, documented for the first time the dramatic amount of total improvement, as measured by goals scored, that takes place when athletes combine mental rehearsal and physical practice."}
              />

              <NewPara
                start={true}
                para={"Studies by other researchers have demonstrated that mental practice at the Alpha level has almost as much benefit as the same amount of time spent in physical practice."}
              />



              <NewPara
                start={true}
                para={"The study confirmed their research and went on to show an electrifying increase in goals scored when the total practice time is divided equally between mental and physical practice."}
              />

              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black"
              }}>

                RESEARCH RESULTS

              </h3>



              <img
                src='/img/bg/science/performanceenhancement-1.png'
              />

              <h3 className='mt-4' style={{
                fontWeight: "600",
                fontSize: "1.2rem",
                color: "black",
                textAlign: "start"
              }}>
                The Silva Method: Pioneering Personal Growth Programs in America since 1966
              </h3>

              <NewPara
                start={true}
                para={"As scientific evidence shows, the Silva Method has been proven to bring about positive changes in students' lives across all areas. For over 50 years, we’ve helped 12 million people in 110 countries achieve transformative changes in their lives. Through a proven curriculum inspired by José Silva's pioneering work, our graduates have seen lasting improvements in careers, finances, health, and relationships."}
              />
              <NewPara
                start={true}

                para={"If you're eager to learn more about What really is the Silva Method? and how this powerful tool can elevate your own life to extraordinary levels of success, then enroll now in The Silva Method Complete Program. Gain lifetime access to all Silva Method courses and benefit from unlimited live interactive sessions with certified instructors."}
              />
            </div>


          </Container>

        </Col>
        <Col md={3} className={isMobile ? "order-1" : "order-2"}>
          <Nav className="flex-column sticky-top gap-2 bg-light p-3" style={{ top: '5rem' }}>
            <h3>Jump to section</h3>
            {sections.map((section) => (
              <a key={section.id} href={`#${section.id}`} style={{
                fontSize: "17px",
                margin: "0px",
                padding: "0px",
                color: section.id === activeSection ? "#0d6efd" : "black"
              }}>
                {section.title}
              </a>
            ))}
          </Nav>
        </Col>
      </Row>

    </Container>
  )
}

export default OtherSections

