import React, { useContext } from 'react'
import CountUp from "react-countup";
import { AuthContext } from '../../../context/AllContext';

function FeaturedSection() {
    const {isMobile} = useContext(AuthContext);

    return (
        <div className='container'>
            <div className='d-flex flex-row justify-content-center align-items-center flex-wrap' style={{
                gap:isMobile?"1.5rem":"4rem"
            }}>
                <div
                    className="d-flex justify-content-start align-items-center flex-column"
                    style={{ marginLeft: "10px" }}
                >
                    <h2
                        className="text-center"
                        style={{
                            fontSize: "1rem",
                            color: "black",
                            fontWeight: "400",
                        }}
                    >
                        <CountUp end={12} style={{
                            fontSize:"2rem",
                            fontWeight:"600"
                        }} duration={2.5} suffix="M+" />
                    </h2>
                    <h2
                        className="text-center"
                        style={{ fontSize: "15px", fontWeight: "500",color:"black" }}
                    >
                        Graduates
                    </h2>
                </div>
                <div
                    className="d-flex justify-content-start align-items-center flex-column"
                    style={{ marginLeft: "10px" }}
                >
                    <h2
                        className="text-center"
                        style={{
                            fontSize: "1rem",
                            color: "black",
                            fontWeight: "400",
                        }}
                    >
                        <CountUp end={500}  style={{
                            fontSize:"2rem",
                            fontWeight:"600"
                        }} duration={2.5} suffix="+" />
                    </h2>
                    <h2
                        className="text-center"
                        style={{ fontSize: "15px", fontWeight: "500",color:"black" }}
                    >
                        Instructors
                    </h2>
                </div>
                <div
                    className="d-flex justify-content-start align-items-center flex-column"
                    style={{ marginLeft: "10px" }}
                >
                    <h2
                        className="text-center"
                        style={{
                            fontSize: "1rem",
                            color: "black",
                            fontWeight: "400",
                        }}
                    >
                        <CountUp end={120}  style={{
                            fontSize:"2rem",
                            fontWeight:"600"
                        }} duration={2.5} suffix="+" />
                    </h2>
                    <h2
                        className="text-center"
                        style={{ fontSize: "15px", fontWeight: "500",color:"black" }}
                    >
                        Countries
                    </h2>
                </div>
                <div
                    className="d-flex justify-content-start align-items-center flex-column"
                    style={{ marginLeft: "10px" }}
                >
                    <h2
                        className="text-center"
                        style={{
                            fontSize: "1rem",
                            color: "black",
                            fontWeight: "400",
                        }}
                    >
                        <CountUp end={1}  style={{
                            fontSize:"2rem",
                            fontWeight:"600"
                        }} duration={2.5} suffix="M+" />
                    </h2>
                    <h2
                        className="text-center"
                        style={{ fontSize: "15px", fontWeight: "500",color:"black" }}
                    >
                        Testimonials
                    </h2>
                </div>

                <div
                    className="d-flex justify-content-start align-items-center flex-column"
                    style={{ marginLeft: "10px" }}
                >
                    <h2
                        className="text-center"
                        style={{
                            fontSize: "1rem",
                            color: "black",
                            fontWeight: "400",
                        }}
                    >
                        <CountUp end={100}  style={{
                            fontSize:"2rem",
                            fontWeight:"600"
                        }} duration={2.5} suffix="K+" />
                    </h2>
                    <h2
                        className="text-center"
                        style={{ fontSize: "15px", fontWeight: "500",color:"black" }}
                    >
                        Social Media Followers
                    </h2>
                </div>
            </div>

        </div>
    )
}

export default FeaturedSection