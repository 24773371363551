import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import WhoIsThisFor from './WhoIsThisFor'
import ImageBannerSection from '../About/ImageBannerSection'

import PaymentSection from './PaymentSection'
import FAQ22 from '../booking/FAQ22'
import CourseDuration from './CourseDuration'
import SeminarTimeline from './SeminarTimeLine'
import SeminarTimeline2 from './SeminarTimeline2'
import HeadingNew2 from '../booking/HeadingNew2'
import { Image } from 'react-bootstrap'
import PerksSection from './PerksSection'

function WhatsCovered({data:datanew}) {
    const { isMobile } = useContext(AuthContext);



    return (
        <>
            <div className='parallexabout py-5'>
                <HeadingNew
                    title={"What you will learn at the Silva Mind Development"}
                    colored={"Program"}
                />

                {/* <ParaComp
                    data={[
                        "Unlocking the Potential of Your Mind and Transforming Your Life with the Silva Method Seminar"
                    ]}
                    center
                    white={false}
                /> */}


               <div className='mt-4 mb-3'>
               <HeadingNew
                    title={"What you will learn at the Silva Life System (4 Days) "}
                    small
                    underline
                />
               </div>




                <SeminarTimeline />

                <div className='mt-5 mb-3'>
               <HeadingNew
                    title={"What you will learn at the Silva Intution System (4 Days) "}
                    small
                    underline
                />
               </div>
               <SeminarTimeline2 />
                <div className='d-flex justify-content-center align-items-center'>
                    <div className={isMobile ? 'w-100 px-2' : 'w-50'}>
                        <ParaComp
                            data={[
                                "Applications of Gratitude, Forgiveness and Compassion as a daily practice to heal past trauma, reshape your worldview and create a more solid positive mindset."
                            ]}
                            white={false}
                            center
                        />


                    </div>

                </div>

                {/* <MasonaryGrid /> */}



            </div>
            <div className='container py-5'>
                <CourseDuration data={datanew} />

            </div>
            {/* <WhoIsThisFor /> */}

            <div className='container'>
                <ImageBannerSection />

            </div>

            <div className='container'>
                <PerksSection />

            </div>

            <div className="container my-5 d-flex justify-content-center align-items-center flex-column">
                    <HeadingNew2
                        title={"Certification Awaiting!"}
                    />
                    <div className='mt-4'>
                        <HeadingNew2
                            small
                            title={"You are worth it! You will become a internationally certified Silva Graduate on the completion of the full Silva Life and Intuition System."}
                        />
                    </div>
                    <Image
                        src={"/img/programs/certificate.jpg"}
                        width={isMobile?350:450}
                        className="mt-4"
                    // height={350}

                    // height="0"
                    // sizes="100vw"
                    // alt=""
                    // className="contactbanner mobilehide mt-4"
                    // style={
                    //     {borderRadius: "10px", height: "auto" }
                    // }

                    />

                   

                   
                </div>
            <div className='py-5'>
                <PaymentSection data={datanew} />

            </div>
            {/* <div className='container py-5'>
                <HeadingNew small title={"Frequently Asked Questions"} />
                {
                    data.map((val, i) => (
                        <FAQ22 heading={val.heading} body={val.body} />
                    ))
                }

            </div> */}


        </>

    )
}

export default WhatsCovered