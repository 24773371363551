import React, { useContext } from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import LandingOneHero from './LandingOneHero'
import "./DraganPages.css";
import CalmCardSection from './CalmCardSection';
import MentalHealthTools from './MentalHealthTools';
import TrustedBy from './TrustedBy';
import DraganTestimonials from './DraganTestimonials';
import PaymentSectionTwo from '../newbooking/PaymentSectionTwo';
import VideoTableSection from '../newbooking/VideoTableSection';
import BannerBooking from '../newbooking/BannerBooking';
import DraganPageFaq from '../SilvaManifestationProgram/DraganPageFaq';
import { Image } from 'react-bootstrap';
import BookingFooter from '../newbooking/BookingFooter';
import AboutInstructorSection2 from '../newbooking/AboutInstructorSection2';
import JoinWhatsAppModal from './ModalPage';
import { useState } from 'react';
import { AuthContext } from '../../context/AllContext';
import BonusCard from './BonusCard';


function LandingPage1() {
  const [show, setShow] = useState(false);
  const {isMobile}= useContext(AuthContext)


  const onHide = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }
  return (
    <div style={{
      backgroundImage: "linear-gradient(rgba(226, 234, 255, 0) 0%, rgb(226, 234, 255) 100%)"
    }}>
      {/* <HomeHeader /> */}

      <div>
        <div className='d-flex justify-content-center align-items-center gap-2 flex-column'>

          <Image
            src={require("../../images/newimgs/silvamethod-logo.png")}
            className='mt-3'
          />
          {<Image
            src={"/img/bg/dragan-bg-up.png"}
            style={{width:'100%'}}
          />}
        
          
        </div>
      </div>


      <LandingOneHero />
      <BannerBooking />


      <CalmCardSection />

      <div className='my-5'>
        <h3 className='newpageheader text-center'>

          Take The First Step To A Soundless Sleep

        </h3>
        <PaymentSectionTwo />
      </div>
      {/* <img src="/img/draganpage/102-up.jpg" alt="" style={{}}/> */}
      <MentalHealthTools />



      <VideoTableSection />
      <BonusCard/>

      <TrustedBy />
      <AboutInstructorSection2 />

      <DraganTestimonials />

      <div className='container' style={{
        paddingTop: "70px",
        paddingBottom: "50px"

      }}>
        <h3 className='text-left newpageheader'>
          Frequently Asked questions
        </h3>
        <DraganPageFaq />
      </div>
      <BookingFooter />


      <JoinWhatsAppModal show={show} onHide={onHide} handleShow={handleShow}  />
    </div>
  )
}

export default LandingPage1