import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Outlet } from 'react-router-dom'

function WithCookiePage() {
    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText="I Accept"
                cookieName="myAwesomeCookieName2"
                // style={{ background: "black",opacity:"0.8" }}
                overlayStyle={{background: "black",opacity:"0.8"}}
                buttonStyle={{ color: "#4e503b", fontSize: "18px",opacity:"1",borderRadius:"8px" }}
                expires={365}
            >
               <span style={{color:"white",fontSize:"18px"}}>
               This website uses cookies to enhance the user experience.{" "}
               </span>
            </CookieConsent>
            <Outlet />
        </>
    )
}

export default WithCookiePage