import React, { useContext } from 'react'
import { Col, Image, Row } from 'react-bootstrap'

import ParaComp from '../../../SilvaManifestationProgram/ParaComp';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../context/AllContext';
import HomeHeading from '../HomeHeading';

function ResearchSection() {
  const { isBigScreen, isDesktopOrLaptop,isMobile, isTablet } = useContext(AuthContext);

  return (
    <div className="">

      <div className="container mt-5">
        <Row style={{marginLeft:isTablet?"20px":"0px"}} className={isTablet?'justify-content-center align-items-center gap-4':'justify-content-between align-items-center'}>
          <Col xs={12} sm={12} md={6} lg={6} style={{marginLeft:"10px"}} className='order-2 order-md-1'>
              <Image src={require("../../../../images/MindBodyHealing/jose_research.webp")} />
              {(isMobile || isTablet) && <div className='d-flex align-items-center justify-content-center mt-4'><Link to={"/research"}><button className='styledbtn23'>Know More</button></Link></div>}
          </Col>
          <Col xs={12} sm={12} md={12} lg={5} style={{marginLeft:"10px"}} className='order-1 order-md-2 pe-4'>
             
              <HomeHeading title='How to Control Your Mind and Thoughts- ' colored='The Trailblazing Research' title2='' start />
              <ParaComp
                data={[
                  "Founded in 1960 with humble beginning self-taught parapsychologist Jose Silva developed ‘Silva Mind Control’ to help his children do better in school and increase their chances of success in life. Since then, this dynamic meditation technique has evolved. Considered the original and most imitated meditation program in the U.S., The Silva Method teaches students specialized guided imagery techniques to rewire their subconscious and negative programming, tap into their true potential and achieve their goals."
                ]}
                white={false}
              />

              <Link to={"/research"}>
              {(isBigScreen || isDesktopOrLaptop) && <button className='styledbtn23'>Know More</button>}
              </Link>
          </Col>
        </Row>
      </div>
    </div>

  )
}

export default ResearchSection