import React from 'react'
import EmotionalNavbar from './EmotionalNavbar'
import HeroSectionEnt from './HeroSectionEnt'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import FeaturedSection from '../SilvaComboCourse/FeaturedSection'
import TrustedBy2 from './TrustedBy2'
import EmotionalWhySection from './EmotionalWhySection'
import EqTestComp from './EqTestComp'

function EmotionalInt2() {
    const { isMobile } = useContext(AuthContext);

    return (
        <div >
            <div style={{
                position: "relative",
                background: "#301934"
            }}>

                <div className='container' style={{
                    position: "absolute",
                    top: "15px",
                    left: "50%",
                    transform: "translateX(-50%)" // Center horizontally
                }}>
                    <EmotionalNavbar />

                </div>
                <div style={{
                    paddingTop: "120px",
                    paddingBottom: isMobile ? "20px" : "70px"
                }}>



                    <HeroSectionEnt />

                </div>
            </div>


            <div className='py-5'>
                <FeaturedSection />

                <TrustedBy2 />


               <div className='pt-3'>
               <EmotionalWhySection />

               </div>
      <EqTestComp />

            </div>

        </div>
    )
}

export default EmotionalInt2