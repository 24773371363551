import React, { useMemo } from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import BenefitsGrid from '../CoursePage/BenefitsGrid'
import BenefitsGrid2 from '../CoursePage/BenefitsGrid2'
import BenefitsGrid3 from './BenefitsGrid2'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import ExtraSection from './ExtraSection'

function FullAgenda() {
    const handleShow = () => {
        const paymentsection = document.querySelector(".paymentsection");
        if(paymentsection){
            paymentsection.scrollIntoView({
                behavior:"smooth"
            })
        }
    }
    const data = useMemo(() => {
        return [
            {
                title: "Silva Life System (2 days)",
                para: "the course is aimed at self-improvement and learning practical techniques so that you can manifest successfully in getting what you want. This course helps you lay the foundations for your evolution and achieve your goals more successfully.",
                class: "green",
                now: 50,
            },

            {
                title: "Silva Intuition System (2 days)",
                para: "the course is oriented towards developing intuition, inspiration, creativity, spiritual development, discovering the purpose of life as well as learning more advanced techniques. You will discover how to practically use the universe of information from the subjective dimension.",
                class: "green",
                now: 80
            },

        ]
    }, [])
    return (
          <div className='container py-5'>
            <HeadingNew
                title={"What you will learn in Silva Life & Intuition System training"}

            />

            <div className='my-4'>
                {/* <ParaComp
                    white={false}
                    margin={false}
                    center
                    data={[
                        "With this course we offer the most efficient option to complete and experience the two courses together. Silva Life System and Silva Intuition System in 4 consecutive days.",
                        "With this experience you will give yourself a gift by which you will 'slow down' for four days in a friendly, relaxing environment that will support you to focus only on YOU.",
                        "Imagine that in these four days you will live experiences and tangible results that you will be able to use even after the end of the course so that you have more success, achievements and create your life and its quality as you want."
                    ]}
                /> */}
                <HeadingNew
                    small
                    title={"It all starts with a decision and a commitment to invest in the most valuable asset you have... YOU."}
                />

            </div>
            {/* <BenefitsGrid3 data={data} classname={"autoheight"} /> */}

           <div className='py-4'>
           <ExtraSection />
           </div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
            <button className='primary_btn' onClick={handleShow}>
                Join The Seminar
            </button>
            </div>
        </div>

    )
}

export default FullAgenda