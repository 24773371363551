import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import GlassmorphicCard from './GlassMorphCard'
import { Col, Row } from 'react-bootstrap'
import { FaCheckCircle } from "react-icons/fa";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';

function ReadyGetSection() {
    const {isMobile} = useContext(AuthContext);

    const data = [
        "Lifetime access to Silva Method individual courses",
        "Lifetime access to Silva Method combo courses",
        "Mind Control digital audio programs",
        "Opportunity to connect with a vibrant community"
    ]
    return (
        <div className='getreadyBg'>
            <div className='container'>
                <Row className='justify-content-center align-items-center'>
                    <Col xs={12} md={6}>
                        <GlassmorphicCard />

                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{
                            paddingLeft: isMobile?15:80,
                            marginTop:isMobile?15:0
                        }}>
                            <HeadingNew start small title={"What you will get"} white />
                            <div>
                                {
                                    data.map((val, i) => (
                                        <div className='d-flex gap-2 mt-3'>
                                            <span>  <FaCheckCircle color='#00FF00' /></span>
                                            <p style={{
                                                margin: 0,
                                                padding: 0,
                                                color: "white",
                                                textAlign: "start",
                                                lineHeight: "1.3"
                                            }}>
                                                {val}
                                            </p>
                                        </div>

                                    ))
                                }


                            </div>

                        </div>


                    </Col>

                </Row>

            </div>
        </div>
    )
}

export default ReadyGetSection