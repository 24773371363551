import React from 'react'
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";


function ComboCourseHeader({setShow,white=false}) {
    function onSelect({ key }) {
        console.log(`${key} selected`);
    }

    function onVisibleChange(visible) {
        console.log(visible);
    }

    const menu = (
        <Menu onSelect={onSelect} style={{
            padding: 10,
            width: "auto",
            borderRadius: 10
        }}>

            <MenuItem key="1">Live In Person</MenuItem>
            <Divider />
            <MenuItem key="2">Live Online ZOOM</MenuItem>
        </Menu>
    );
    return (
        <nav className='container d-flex justify-content-between align-items-center px-xs-3 px-md-0'>

            <img src={white?"/img/logowhite.png":'/img/logo/title-logo-removebg-preview.png'} style={{
                width: 50,
                height: 50,
                objectFit: white?"contain":"cover"
            }} />

            <div className='showtoggle'>
               <RxHamburgerMenu color='white' size={40} onClick={()=>{
                setShow(true)
               }} />
            </div>
            <div className='hidelinks'>
                <div className='d-flex'>

                    <Dropdown
                        trigger={['click', "focus", "hover"]}
                        overlay={menu}

                        animation="slide-up"
                        onVisibleChange={onVisibleChange}
                    >
                        <a href='#' className='comboanchor' style={{
                            color: "white",
                            margin: "0px",
                            padding: "0px"
                        }}>Events <span>
                                <MdKeyboardArrowDown color='white' size={25} />
                            </span></a>


                    </Dropdown>
                    <Dropdown
                        trigger={['click', "focus", "hover"]}
                        overlay={menu}

                        animation="slide-up"
                        onVisibleChange={onVisibleChange}
                    >
                        <a href='#' className='comboanchor' style={{
                            color: "white",
                            margin: "0px",
                            padding: "0px"
                        }}>Instructors <span>
                                <MdKeyboardArrowDown color='white' size={25} />
                            </span></a>


                    </Dropdown>
                    <Dropdown
                        trigger={['click', "focus", "hover"]}
                        overlay={menu}

                        animation="slide-up"
                        onVisibleChange={onVisibleChange}
                    >
                        <a href='#' className='comboanchor' style={{
                            color: "white",
                            margin: "0px",
                            padding: "0px"
                        }}>Home Study Courses <span>
                                <MdKeyboardArrowDown color='white' size={25} />
                            </span></a>


                    </Dropdown>

                    <div style={{
                        alignSelf: "center"
                    }}>
                        <a href='#' className='comboanchor' style={{
                            color: "white",
                            margin: "0px",
                            padding: "0px"
                        }}>Contact us</a>
                    </div>
                </div>
            </div>

        </nav>
    )
}

export default ComboCourseHeader