import React, { useMemo } from 'react'
import Slider from 'react-slick'
import HeadingNew from '../AboutUs/HeadingNew'
import { useMediaQuery } from 'react-responsive'
import ParaComp from '../SilvaManifestationProgram/ParaComp'

function Slider2({scrollTo}) {
    const images = useMemo(() => {
        return [
            "/img/bg/bghero/flatimages/2.png",
            "/img/bg/bghero/flatimages/3.png",

            "/img/bg/bghero/flatimages/4.png",
            "/img/bg/bghero/flatimages/5.png",
            "/img/bg/bghero/flatimages/6.png",
            "/img/bg/bghero/flatimages/7.png",
            "/img/bg/bghero/flatimages/8.png",
            "/img/bg/bghero/flatimages/9.png",








        ]
    }, [])



    const images2 = useMemo(() => {
        return [
            "/img/bg/bghero/flatimages/Deep Relax Meditation -3 to 1.png",
            "/img/bg/bghero/flatimages/Guided Sleep Meditation.png",
            "/img/bg/bghero/flatimages/Imagination  Exercise.png",
            "/img/bg/bghero/flatimages/Mental Screen Exercise.png",
            "/img/bg/bghero/flatimages/Power of Forgoiveness Exercise.png",
            "/img/bg/bghero/flatimages/problem-card-design-33.png",
            "/img/bg/bghero/flatimages/projection Exercise.png",
            "/img/bg/bghero/flatimages//Visualization Exercise.png"


        ]

    }, [])
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

    const settings = {
        infinite: true,
        speed: 10000, // Controls the speed of the marquee
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Immediate slide transition to create marquee effect
        cssEase: 'linear', // Smooth transition
        variableWidth: true,
        arrows: false,
        pauseOnHover: true, // Optional: pause on hover
    };
    const settings2 = {
        infinite: true,
        speed: 13000, // Controls the speed of the marquee
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Immediate slide transition to create marquee effect
        cssEase: 'linear', // Smooth transition
        variableWidth: true,
        arrows: false,
        pauseOnHover: true, // Optional: pause on hover
    };

    return (
        <div style={{
            position: "relative",
            marginTop: isMobile?"50px":"100px",
        }}>
            <div style={{
                marginTop:isMobile?"50px":"100px",
                marginBottom: "30px"
            }}>
                <HeadingNew title={"Solutions "} colored={"Silva Brings"} />
                <HeadingNew title={"to Life’s Toughest Struggles"} small />

               <div className='container d-flex justify-content-center align-items-center'>
               <h5 className="mt-3 mb-3 text-center robotofont" style={{
                width:isMobile?"95%":"80%",
                lineHeight: isMobile ? "1.3rem" : "1.5rem",
                  fontSize: isMobile ? "0.9rem" : "1rem"
               }}>
                    Silva Method isn't just about meditation; it's about transforming your life. Whether you're looking to melt away stress, manage negative emotions, heal your body naturally, or manifest your dream life, we've got the perfect technique for you. From relationships to careers, our dynamic meditations cover it all, helping you create a life that's not just lived, but loved!

                </h5>

               </div>


            </div>

            <div className={`${isMobile ? 'slider-newpage' : 'slider-newpage mb-5'}`}>
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index}>
                            <img
                                src={image}
                                alt={`slide-${index}`}
                                style={{
                                    width: isMobile ? "200px" : "650px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                    marginRight: isMobile?"5px":"10px",
                                }}
                            />
                        </div>
                    ))}
                </Slider>
                <div style={{
                    marginTop: isMobile ? "5px" : "15px"
                }}>
                    <Slider {...settings2}>
                        {images2.map((image, index) => (
                            <div key={index}>
                                <img
                                    src={image}
                                    alt={`slide-${index}`}
                                    style={{
                                        width: isMobile ? "200px" : "650px",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                        marginRight: isMobile?"5px":"10px",
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Slider2