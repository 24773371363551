import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Image } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew';
import ResultAccordion from '../../SilvaManifestationProgram/ResultAccordion';
import CustomCourseAccordion from '../../CoursePage/CustomCourseAccordion';
import { AuthContext } from '../../../context/AllContext';
import CustomCourseAccordion2 from './CustomCourseAcc2';

function CourseAccodions2({ data, isCurrentCoursePurchased, lastCourse, margin = true, loadMore }) {

    const [searchResult, setSearchResult] = useState();
    const [allLessons, setAllLessons] = useState([])
    const { isMobile, isDesktopOrLaptop, isBigScreen } = useContext(AuthContext)
    const [searchQuery, setSearchQuery] = useState("")


    useEffect(() => {
        if (data) {
            let chapters = data?.chapters?.map((chap) => chap.lession.map((les) => les))
            let lessons = []
            chapters?.forEach((cha) => {
                lessons.push(...cha)
            })

            setAllLessons(lessons)

        }
    }, [data])

    const handleChangeSearch = useCallback(() => {
        if (searchQuery) {
            const regex = new RegExp(searchQuery, 'gi');
            const res = allLessons.filter(lesson => regex.test(lesson.lesson_title))
            setSearchResult(res)
        } else {
            setSearchResult()
        }
    }, [searchQuery])



    useEffect(() => {
        handleChangeSearch()
    }, [handleChangeSearch])






    return (
        <div className={`coursesinglenew ${margin ? 'mt-5' : ''}`} style={{
            width:
                // (isCurrentCoursePurchased===false || !isMobile)?"60%":isMobile?"100%":"96%"
                ((isDesktopOrLaptop || isBigScreen) && !isCurrentCoursePurchased) ? "95%" : (isCurrentCoursePurchased === false && !isMobile) ? "100%" : "100%"
            // isMobile?"100%":(isCurrentCoursePurchased===false || !isMobile)?"60%":"96%"
        }}>
            <div className='d-flex justify-content-between align-items-center mb-3 gap-3'>
                <h4>Course Details</h4>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Search a lessson" style={{ height: "50px" }} onChange={(e) => setSearchQuery(e.target.value)} />
                    </Form.Group>
                </Form>

            </div>

            {
                searchResult && searchResult.length === 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Image src={require("../../../images/noresults.jpg")} width={100} height={100} />
                        <HeadingNew title={`Sorry no results found for ${searchQuery} ☹️`} small />

                    </div>
                )
            }

            {
                searchResult && searchResult.length > 0 && (
                    <ResultAccordion searchResult={searchResult} searchQuery={searchQuery} course_id={data.course_id} isSubscribed={isCurrentCoursePurchased} white={false} data={data} />
                )
            }



            {data && Boolean(searchResult) === false && loadMore &&
                data.chapters.map((chapter, i) => <CustomCourseAccordion2 lastCourse={lastCourse} key={i}
                    data={chapter} lastchapter={data.chapters.length - 1} alldata={data}
                    index={i} isCurrentCoursePurchased={isCurrentCoursePurchased} />)}

            {data && Boolean(searchResult) === false && !loadMore &&
                data.chapters.slice(0, 5).map((chapter, i) => <CustomCourseAccordion2 lastCourse={lastCourse} key={i}
                    data={chapter} lastchapter={data.chapters.length - 1} alldata={data}
                    index={i} isCurrentCoursePurchased={isCurrentCoursePurchased} />)}



        </div>
    )
}

export default CourseAccodions2