import React, { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import moment from "moment";
import { BsCalendarDate } from "react-icons/bs";
import { GrMapLocation } from "react-icons/gr";
import MapPlace from "./MapPlace";
import { AiOutlineCar, AiFillSafetyCertificate } from "react-icons/ai";
import { FaWalking } from "react-icons/fa";
import { BsTrainFront, BsShare } from "react-icons/bs";
import { BiCycling } from "react-icons/bi";
import SocialShareButtons from "../SilvaManifestationProgram/SocialShareButtons";
import { Tooltip } from "react-tooltip";
import ModalNew from "./ModalNew";
import { HashLoader } from "react-spinners";
import SuggestedClasses from "./SuggestedClasses";
import { FaLanguage } from "react-icons/fa";
import Swal from "sweetalert2";
import { Card } from "react-bootstrap";
import CustomCard from "../../components/SingleShop/Card";

function CourseDetails() {
  // alert(location.pathname)
  const url = window.location.href;
  // console.log(url);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // document.title="Course Details";
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1280px)" });

  const { id } = useParams();
  //console.log(id);
  const [singleClass, setSingleClass] = useState([]);

  const getSingleClass = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency");

    if (country) {
      myHeaders.append("country", country.toString());
    }

    if (currency) {
      myHeaders.append("currency", currency.toString());
    }
    const formData = new FormData();
    formData.append("class_id", id);
    const options = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };
    setLoading(true);
    const res = await fetch(
      `https://silvamethod.com/manage/api/classDetail`,
      options
    );
    setLoading(false);
    const data = await res.json();
    // console.log(data, "Dataaaa");
    setSingleClass(data.data);
  };

  useEffect(() => {
    getSingleClass(id);
  }, [id]);

  // console.log(singleClass, "Single");

  const handleGetDirections = (mode, destination) => {
    const origin = "";
    const dest = destination || "Boston, MA";
    const url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
      origin
    )}&destination=${encodeURIComponent(dest)}&travelmode=${mode}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  if (loading) {
    return (
      <div className="container h-100">
        <div className="d-flex justify-content-center align-items-center">
          <HashLoader color="black" loading={loading} size={100} />
        </div>
      </div>
    );
  }

  return (
    <div className="">
      {singleClass && singleClass[0] && (
        <>
          <div className="container d-flex justify-content-center flex-column align-items-center blobdiv">
            <div
              style={{
                width: isDesktopOrLaptop ? "80%" : "100%",
                borderRadius: "14px",
                marginBottom: "20px",
              }}
              className="p-5 d-flex justify-content-center align-items-center"
            >
              {/* {
                                    singleClass[0].class_image && (
                                        <img
                                            loading='lazy'

                                            style={{ borderRadius: "14px" }}
                                            // style={{ position: "absolute", top: "40px" }}
                                            src={singleClass[0].class_image || ""}
                                            alt="" />
                                    )
                                } */}
              <CustomCard
                index={Number(singleClass[0]?.course_id)}
                ins_image={singleClass[0]?.photo || require("../../images/no-photo-icon-22.png")}
                title={singleClass[0]?.course_name}
                ins_name={singleClass[0]?.instructor_name}
                date={`${moment(singleClass[0]?.start_date).format("Do MMM")}- ${moment(
                    singleClass[0]?.end_date
                ).format("Do MMM YYYY")}`}
                language={singleClass[0]?.language}
                mode={singleClass[0]?.delivery_method}
                borderRadiusAll
                all_instructor={singleClass[0]?.all_instructor}
              />
            </div>
            <div
              style={{
                width: isDesktopOrLaptop ? "70%" : "100%",
                borderRadius: "14px",
              }}
              className=""
            >
              <span
                style={{
                  fontSize: "1.1rem",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {moment(singleClass[0].start_date).format("MMMM") +
                  " " +
                  moment(singleClass[0].start_date).format("D")}
              </span>
              <br />
              <span
                style={{
                  fontSize: "1.1rem",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                <FaLanguage
                  size={40}
                  color="#801cb6"
                  style={{ marginRight: "10px" }}
                />
                {singleClass[0].language}
              </span>
              <div className="d-flex justify-content-between align-items-center">
                <h2
                  className="my-4"
                  style={{
                    fontWeight: "800",
                    fontSize: isDesktopOrLaptop ? "40px" : "20px",
                  }}
                >
                  {singleClass[0].course_title}
                </h2>

                <a
                  data-tooltip-id="my-tooltip3"
                  data-tooltip-place="bottom"
                  data-tooltip-content="Share Event"
                >
                  <h2
                    className="my-4"
                    style={{
                      fontWeight: "800",
                      fontSize: isDesktopOrLaptop ? "40px" : "20px",
                    }}
                  >
                    <BsShare size={30} onClick={handleShow} color="#801cb6" />
                  </h2>
                </a>
              </div>

              <div
                style={{
                  width: "50%",
                  padding: "5px 15px",
                  backgroundColor: "#801cb6",
                  color: "white",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                <b style={{ textTransform: "capitalize" }}>
                  {singleClass[0].delivery_method}
                </b>
              </div>
              {/* <span dangerouslySetInnerHTML={{ __html: singleClass[0].course_description }}>
                                </span> */}

              <h2
                className="my-4"
                style={{
                  fontWeight: "800",
                  fontSize: isDesktopOrLaptop ? "25px" : "15px",
                }}
              >
                When and Where
              </h2>

              <div className="row mt-3 gap-3" style={{ marginLeft: "0px" }}>
                <div className="col-sm-12 col-md-6">
                  <h5
                    className="text-dark"
                    style={{ fontSize: isDesktopOrLaptop ? "20px" : "12px" }}
                  >
                    <BsCalendarDate
                      size={20}
                      style={{ marginBottom: "5px", marginRight: "5px" }}
                    />{" "}
                    Date and time
                  </h5>
                  <h5
                    className="text-dark"
                    style={{ fontSize: isDesktopOrLaptop ? "15px" : "10px" }}
                  >
                    {moment(singleClass[0].start_date).format("LL") || ""}-
                    {moment(singleClass[0].end_date).format("LL") || ""}
                  </h5>
                </div>
                {singleClass[0]?.delivery_method === "live" && (
                  <div className="col-sm-12 col-md-6">
                    <h5
                      className="text-dark"
                      style={{ fontSize: isDesktopOrLaptop ? "20px" : "14px" }}
                    >
                      <GrMapLocation
                        size={20}
                        style={{ marginBottom: "5px", marginRight: "5px" }}
                      />{" "}
                      Location
                    </h5>
                    <h5
                      className="text-dark"
                      style={{ fontSize: isDesktopOrLaptop ? "15px" : "10px" }}
                    >
                      {singleClass[0]?.address || ""}
                    </h5>
                  </div>
                )}

                {singleClass[0]?.delivery_method === "live" &&
                  singleClass[0]?.latitude &&
                  singleClass[0]?.longitude && (
                    <div className="col-12 my-3 mapviewdiv">
                      {singleClass[0] &&
                        singleClass[0].latitude &&
                        singleClass[0].longitude && (
                          <MapPlace
                            address={singleClass[0].address}
                            location={[
                              singleClass[0].latitude,
                              singleClass[0].longitude,
                            ]}
                          />
                        )}
                      <div className="d-flex justify-content-center mt-3">
                        <span
                          style={{
                            textAlign: "center",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          How To Get There
                        </span>
                      </div>
                      <div
                        className="d-flex justify-content-center gap-4 pb-3"
                        style={{ borderBottom: "2px solid #801cb6" }}
                      >
                        <FaWalking
                          size={40}
                          color="#801cb6"
                          style={{ marginTop: "20px" }}
                          onClick={() =>
                            handleGetDirections(
                              "walking",
                              singleClass[0].address
                            )
                          }
                        />
                        <AiOutlineCar
                          size={40}
                          color="#801cb6"
                          style={{ marginTop: "20px" }}
                          onClick={() =>
                            handleGetDirections(
                              "driving",
                              singleClass[0].address
                            )
                          }
                        />
                        <BiCycling
                          size={40}
                          color="#801cb6"
                          style={{ marginTop: "20px" }}
                          onClick={() =>
                            handleGetDirections(
                              "bicycling",
                              singleClass[0].address
                            )
                          }
                        />
                        <BsTrainFront
                          size={40}
                          color="#801cb6"
                          style={{ marginTop: "20px" }}
                          onClick={() =>
                            handleGetDirections(
                              "transit",
                              singleClass[0].address
                            )
                          }
                        />
                      </div>
                    </div>
                  )}

                <h2
                  className="my-3"
                  style={{
                    fontWeight: "800",
                    fontSize: isDesktopOrLaptop ? "25px" : "14px",
                  }}
                >
                  Instructor Name - {singleClass[0].instructor_name || ""}
                </h2>

                {singleClass[0].is_certified_instructor === "Y" && (
                  <h2
                    className=""
                    style={{
                      fontWeight: "800",
                      fontSize: isDesktopOrLaptop ? "20px" : "10px",
                    }}
                  >
                    <AiFillSafetyCertificate size={40} color="green" />{" "}
                    Certified Silva Method Instructor
                  </h2>
                )}

                {singleClass[0].is_coach === "Y" && (
                  <h2
                    className=""
                    style={{
                      fontWeight: "800",
                      fontSize: isDesktopOrLaptop ? "20px" : "10px",
                    }}
                  >
                    <AiFillSafetyCertificate size={40} color="green" />{" "}
                    Certified Silva Method Coach
                  </h2>
                )}

                {singleClass[0].is_director === "Y" && (
                  <h2
                    className=""
                    style={{
                      fontWeight: "800",
                      fontSize: isDesktopOrLaptop ? "20px" : "10px",
                    }}
                  >
                    <AiFillSafetyCertificate size={40} color="green" />{" "}
                    Certified Silva Method Director
                  </h2>
                )}

                {singleClass[0].is_event_organizer === "Y" && (
                  <h2
                    className=""
                    style={{
                      fontWeight: "800",
                      fontSize: isDesktopOrLaptop ? "20px" : "10px",
                    }}
                  >
                    <AiFillSafetyCertificate size={40} color="green" />{" "}
                    Certified Silva Method Event Organizer
                  </h2>
                )}

                <div className="col-12 mt-3">
                  <h2
                    className="my-3"
                    style={{
                      fontWeight: "800",
                      fontSize: isDesktopOrLaptop ? "25px" : "14px",
                    }}
                  >
                    About This Event
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: singleClass[0].course_description,
                    }}
                  ></p>

                  <Card style={{ width: "100%", borderRadius: "15px" }}>
                    <Card.Body>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: singleClass[0]?.details,
                        }}
                      ></p>
                    </Card.Body>
                  </Card>

                  <button
                    className="primary_btn"
                    onClick={() => {
                      if (singleClass[0].class_booking_link) {
                        if (
                          singleClass[0].class_booking_link.startsWith(
                            "https://"
                          )
                        ) {
                          window.open(
                            singleClass[0].class_booking_link,
                            "_blank"
                          );
                        } else {
                          window.open(
                            `https://${singleClass[0].class_booking_link}`,
                            "_blank"
                          );
                        }
                        // window.location.assign(singleClass[0].class_booking_link)
                      } else {
                        Swal.fire({
                          icon: "info",
                          html: 'Please contact <a href="mailto:digital@silvamethod.com">digital@silvamethod.com</a> for more details about this event ',

                          showCloseButton: true,
                          focusConfirm: false,
                        });
                      }
                    }}
                  >
                    Book Your Class Now!
                  </button>
                </div>

                {/* <div className="col-12 mt-2">
                                        <img src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F410191819%2F1317617497023%2F1%2Foriginal.20221215-075134?h=2000&w=720&auto=format%2Ccompress&q=75&sharp=10&s=347924e6bfdb88384434f04bdf7c0be6" alt="" />
                                    </div>
                                    <div className="col-12 mt-2">
                                        <img src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F410191899%2F1317617497023%2F1%2Foriginal.20221215-075144?h=2000&w=720&auto=format%2Ccompress&q=75&sharp=10&s=cf3ae11b309fae32b562d61b08770290" alt="" />
                                    </div>

                                    <div className="col-12 my-2">
                                        <ReactPlayer
                                            width={"100%"}
                                            height={isDesktopOrLaptop ? "500px" : isTablet ? "300px" : "200px"}
                                            url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                                        />
                                    </div> */}

                {/* <div className="col-sm-12 col-md-8">
                                        <h2 className='my-3' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop ? "25px" : "14px", }}>
                                            Tags
                                        </h2>
                                        <div className='d-flex justify-content-start align-items-center flex-wrap gap-2'>
                                            {
                                                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((val, i) => (
                                                    <span className='badgespan' key={i}>
                                                        Event
                                                    </span>
                                                ))
                                            }

                                        </div>
                                    </div> */}

                <div className="col-12">
                  <h3
                    className="mt-4"
                    style={{
                      fontWeight: "800",
                      fontSize: isDesktopOrLaptop ? "25px" : "14px",
                    }}
                  >
                    Share With Friends
                  </h3>
                  <SocialShareButtons url={url} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container">
        <SuggestedClasses id={id} handleShow={handleShow} />
      </div>

      <Tooltip id="my-tooltip3" />
      <ModalNew show={show} handleClose={handleClose} />
    </div>
  );
}

export default CourseDetails;
