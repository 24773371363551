import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'

function LifeSkills() {
  return (
    <div className='py-5'>
        <div className="container d-flex flex-column justify-content-center align-items-center">
            <h5>Silva Mind Control Seminars - How to Master the</h5>
            <HeadingNew title={"Ultimate "} colored={"Life Skill"}/>
            <ParaComp
                data={[
                    `The emergence of the Silva Method in early 20s America created a wave of excitement and curiosity. It's impact exceeded expectations, leading many to search for "Silva Mind Control classes near me." While seeking these classes, one may come across Silva Mind Control seminars, exceptional events where certified Silva Method instructors impart powerful mind con- trol techniques.`,
                    "To find Silva Method classes nearby, one can search online or visit the official Silva Method International website at silva- method.com. This platform provides comprehensive information on upcoming Silva events, including Mind Control semi- nars and live or online training sessions in various countries. With its presence in 110 countries, the Silva Method conduct these workshops and sessions, led by certified instructors, on a country-wise basis. This presents an incredible opportunity for individuals aspiring to master mind control techniques, as they can learn and practice within a supportive group setting.",
                    "Attending Silva Mind Control Seminars offers invaluable tools and strategies for personal growth, self-improvement, and achieving success in diverse areas of life. It is well worth exploring these seminars if you desire to unlock the untapped po- tential of your mind and undergo positive transformations.",
                ]}
                white={false}
                fw={"400"}
            />
        </div>
    </div>
  )
}

export default LifeSkills