import React, { useState } from "react";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import GlassofWaterHero from "./GlassofWaterHero";
import CalmCardSection from "../Draganpages/CalmCardSection";
import SilvaInformationCards from "./SilvaInformationCards";
import { FaBrain,FaInfoCircle,FaUserGraduate  } from "react-icons/fa";
import { FcIdea,FcBusinessman ,FcManager  } from "react-icons/fc";
import { MdMan } from "react-icons/md";
import { CgGirl } from "react-icons/cg";
import BenifitsCards from "./GlassofWaterBenifits";
import WhoForCards from "./WhoForCards";
import PaymentSectionTwo from "../newbooking/PaymentSectionTwo";
import BonusCard from "../Draganpages/BonusCard";
import AboutInstructorSection2 from "../newbooking/AboutInstructorSection2";
import DraganTestimonials from "../Draganpages/DraganTestimonials";
import DraganPageFaq from "../SilvaManifestationProgram/DraganPageFaq";
import BookingFooter from "../newbooking/BookingFooter";
import VideoTableSection from "../newbooking/VideoTableSection";
import JoinWhatsAppModal from "../Draganpages/ModalPage";
import GlassofWaterHero2 from "./GlassofWaterHero2";
import WhereUse from "./WhereUse";
// import { PiStudentBold } from "react-icons/pi";


function GlassofWaterLanding() {
  const benifits = [
    {
      icon: <FaBrain color="#6a3093" size={70} />,
      title: "Enhanced Problem Solving",
      text: "Discover crucial insights & missing elements to solve complex problems effectively.",
    },
    {
      icon: <FcIdea size={70}/>,
      title: "Decision-Making Clarity",
      text: "Get that extra nudge, a final hint, or perspective in making well-informed decisions",
    },
    {
      icon: <FaInfoCircle color="#6a3093" size={70} />,
      title: "Information Retrieval",
      text: "Uncover information or signs, to navigate challenges and find solutions.",
    },
  ];

  const whoFors=[
    {
      icon: <FcBusinessman  color="#6a3093" size={70} />,
      title: "Business owners",
    },
    {
      icon: <FcManager  color="#6a3093" size={70} />,
      title: "Corporates",
    },
    {
      icon: <FaUserGraduate   color="#6a3093" size={70} />,
      title: "Students",
    },
    {
      icon: <CgGirl   color="#6a3093" size={70} />,
      title: "Housewives",
    },
    {
      icon: <MdMan   color="#6a3093" size={70} />,
      title: "Stock Brokers",
    },
  ];

  const whereData=[
    {
      id:1,
      text:'Enhance Career Growth with Thoughtful Choices '
    },
    {
      id:2,
      text:'Choose Ideal Companions or Life Partners'
    },
    {
      id:3,
      text:'Navigate Family Conflicts Effectively'
    },
    {
      id:4,
      text:'Detect Potential Health Issues with Vigilance'
    },
    {
      id:5,
      text:'Optimize Business Decisions for Improved Outcomes'
    }
  ]

  const [show, setShow] = useState(false);
  
  const onHide = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  return (
    <div style={{
      backgroundImage: "linear-gradient(rgba(226, 234, 255, 0) 0%, rgb(226, 234, 255) 100%)"
    }}>
      {/* <HomeHeader /> */}
      <GlassofWaterHero />
      {/* <CalmCardSection /> */}
      <SilvaInformationCards />
      <GlassofWaterHero2/>
      <BenifitsCards data={benifits} title={'Benefits of Glass of Water Techniques'}/>
      <WhoForCards data={whoFors}/>
      <WhereUse data={whereData}/>
      <PaymentSectionTwo/>
      <VideoTableSection />
      <BonusCard/>
      <AboutInstructorSection2 />

      <DraganTestimonials />

      <div className='container' style={{
        paddingTop: "70px",
        paddingBottom: "50px"

      }}>
        <h3 className='text-left newpageheader'>
          Frequently Asked questions
        </h3>
        <DraganPageFaq />
      </div>
      <BookingFooter />
      <JoinWhatsAppModal show={show} onHide={onHide} handleShow={handleShow}  />
    </div>
  );
}

export default GlassofWaterLanding;
