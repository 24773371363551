import React from 'react'
import TabBars3 from './Tabbars3'
import HeadingNew from '../../AboutUs/HeadingNew'
import ClassesCard from './ClassesCard'
import ClassesVerticalTimeline from './ClassesVerticalTimeline'

function TabbarSection() {
    return (
        <div className='container-fluid my-5 d-flex justify-content-center align-items-center flex-column tabbarsection'>
           
            <HeadingNew title={"Live Interactive Classes Schedule"} />
            {/* <p className='normalpara' style={{
maxWidth:"50%"
}}>
Calm makes it easy to find what you're looking for and explore it at your own pace. Whether you want to sleep better, feel more relaxed, or improve your focus and productivity, Calm has something for you.
</p> */}

            {/* <TabBars3 /> */}
            {/* <ClassesCard/> */}
            <ClassesVerticalTimeline />
        </div>
    )
}

export default TabbarSection