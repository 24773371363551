import React from 'react';
import './GlassmorphicCard.css'; // Import CSS for glassmorphism effect
import { Card } from 'react-bootstrap';
import HeadingNew from '../../AboutUs/HeadingNew';
import { MdChevronRight } from 'react-icons/md';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';

const GlassmorphicCard = () => {
    const {isMobile} = useContext(AuthContext);

    return (
        <Card style={{
            padding: isMobile?"30px":"50px",
            width: isMobile?"95%":"97%",
            margin:"auto",
            borderRadius:"25px"
        }}
            className="glassmorphic-card"

        >
            <Card.Body>
                {/* <Card.Title></Card.Title> */}
                <HeadingNew
                    title={"Ready to get started"}
                    start
                    white
                />
                <Card.Text className='mt-3 white-color' style={{
                    lineHeight: "1.3",
                    fontWeight: "400"

                }}>
                    Isn’t it intriguing, how some people find contentment and joy with little, while others despite having everything to be happy about, remain perpetually dissatisfied? The key lies in taking control of our minds rather than allowing them to dictate our thoughts, feelings, and emotions.
                </Card.Text>

                <Card.Text className='mt-3 white-color' style={{
                    lineHeight: "1.3",
                    fontWeight: "400"
                }}>
                    Over 6M changemakers have joined to take charge of their mind and embark on a journey of self-discovery.
                </Card.Text>
                <Card.Subtitle className='mt-3 white-color' style={{
                    lineHeight: "1.3",
                    fontWeight: "600",
                    color: "white"
                }}>
                    What are you waiting for?
                </Card.Subtitle>
                <button className='px-3 mt-4 py-3' style={{ borderRadius: "10px", color: "black", fontWeight: "700", backgroundColor: "#f1c40f", border: "none" }}>
                    Begin your journey !
                     </button>

            </Card.Body>

        </Card>
    );
}

export default GlassmorphicCard;