import React from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import Tilt from 'react-parallax-tilt';

function WhatIsLoveMind() {
    const handleScroll = ()=>{
        const doc = document.querySelector(".lovemindformscroll");
        if(doc){
            doc.scrollIntoView({
                behavior:"smooth"
            })
        }
    }
    return (
        <div className='container my-4'>
            <Row className='justify-content-center align-items-center'>

                <Col xs={12} md={6}>
                    {/* <Tilt reset={false}>
                        <img src='/img/lovemind/lovemind2pic1.png'
                            style={{
                                width: "100%",


                            }}

                        />
                    </Tilt> */}
                    <div className="containernewnew">
                        <div className="box box1">
                            <Tilt style={{
                                width: "100%",
                                height: "100%",
                            }}>
                                <img src='/img/img45.jpg'
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        objectFit: "cover"
                                    }}
                                />

                            </Tilt>
                        </div>
                        <div className="box box2">
                            <Tilt style={{
                                width: "100%",
                                height: "100%",
                            }}>
                                <img src='/img/img46.jpg'
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        objectFit: "cover"
                                    }}
                                />
                            </Tilt>
                        </div>
                        <div className="box box3">
                            <Tilt style={{
                                width: "100%",
                                height: "100%",
                            }}>
                                <img
                                    src=
                                    '/img/img47.jpg'
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        objectFit: "cover"
                                    }}
                                />
                            </Tilt>
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={6}>
                    <div className='container'>
                        <HeadingNew title={"What is"} colored={"Love Mind"} start />


                        <p style={{
                            color: "black"
                        }}>
                            Love Mind is a unique course covering all aspects of our relationships. Begins with our relationship with ourselves, and our self-esteem. Then goes on into the area of our relationships with our closest ones( emotional partners, children, parents, and friends). In the end, it covers our relationship with the ‘whole’ ( universe, nature, God…).
                        </p>
                        <button className='herodiv-becomememberbtn3' onClick={handleScroll}>
                            Enroll Now

                        </button>

                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default WhatIsLoveMind