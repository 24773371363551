import React, { useContext } from "react";
import HeadingNew from "../AboutUs/HeadingNew";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HomeHeading from "../Home/Home/HomeHeading";
import { AuthContext } from "../../context/AllContext";


function BannerNewHome({scrollTo}) {
  const {isMobile} = useContext(AuthContext);
  
  return (
    <div className="banner-new-home">
        <HomeHeading title={"Get " } colored="50% OFF" title2=" on the " />
        <div className="mt-2">
        <HeadingNew small title={"Silva Method Complete Program"} />
        </div>
        <h5 className="mt-3 mb-3 text-center robotofont" style={{
                    lineHeight: isMobile ? "1.3rem" : "1.5rem",
                    fontSize: isMobile ? "0.9rem" : "1rem"
                }}>
                        Join Today and Get Lifetime Access to All Original Silva Courses + Free Weekly Live Classes with Our Expert Instructors!

                </h5>
      <button className="cssbuttons-io-button3" onClick={scrollTo}>Enroll Now!</button>
    </div>
  );
}

export default BannerNewHome;
