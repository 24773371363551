import React, { useContext } from 'react'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { AuthContext } from '../../../context/AllContext'
import { useNavigate } from 'react-router-dom';
import ShineBtn from './ShineBtn';

function NewComboHeroSection({ func }) {
    const { isMobile } = useContext(AuthContext);

    const navigate = useNavigate();


    return (
        <div className='container' style={{
            paddingTop: "150px"
        }}>
            <div className='d-flex justify-content-center align-items-center flex-column'>
                <h1 className='text-center headline' style={{
                    color: "#141E23",
                    width: "70%",
                    fontWeight: "700",
                    fontSize: isMobile ? "2rem" : "3.3rem"
                }}>
                    Transform your life with {" "} {" "}
                    <em style={{
                        marginRight: "10px"
                    }}>Original&nbsp;
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none">
                            <path fill="none" d="M325,18C228.7-8.3,118.5,8.3,78,21C22.4,38.4,4.6,54.6,5.6,77.6c1.4,32.4,52.2,54,142.6,63.7 c66.2,7.1,212.2,7.5,273.5-8.3c64.4-16.6,104.3-57.6,33.8-98.2C386.7-4.9,179.4-1.4,126.3,20.7" />
                        </svg>
                    </em>
                    Silva Method Combo Course
                </h1>


                <div style={{
                    width: isMobile ? "95%" : "55%",
                    position: "relative"
                }}>
                    <ParaComp
                        data={[
                            "Transform Your Mind, Body, and Spirit with All-Inclusive Access to Our Live Interactive Classes Led by Certified Silva Method Instructors. Plus, Enjoy Lifetime Access to All Silva Method Lessons."
                        ]}
                        white={false}
                        center
                        margin={isMobile ? false : true}
                    />

                    <div className="d-flex justify-content-center align-items-centetr mt-4 mt-md-5">
                      
                        <ShineBtn onClick={func} text={"Start Your Journey Now"} />
                    </div>
                    {
                        !isMobile && (
                            <img src='/img/cta_arrow_left.svg' style={{
                                position: "absolute",
                                left: "-30px",
                                top: "0",
                                height: "100%",
                                opacity: "0.6"
                            }} />

                        )
                    }
                </div>

                <div className='d-flex gap-2 justify-content-center align-items-center mt-4'>
                    <div class="avatars">
                       {
                        isMobile? (
                            <>
                             <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>
                       
                            </>
                        ):(
                            <>
                             <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Giselle.jpg" alt="" /></a>

                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/K.%20Galway.jpg" alt="" /></a>
                            </>
                        )
                       }


                    </div>
                    <h6 style={{
                        color: "black"
                    }}>
                        Join 12M+
                        <br />
                        Active Students
                    </h6>
                </div>
                <img src='/img/combo-new-banner.jpg' style={{
                    width: isMobile ? "100%" : "60%",
                    objectFit: "contain",
                    borderRadius: "10px",
                    opacity: "1"
                }}
                    className='my-5'
                />
                
            </div>

        </div>
    )
}

export default NewComboHeroSection