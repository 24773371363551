import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import {AiOutlineClose} from "react-icons/ai";
import SocialShareButtons from '../SilvaManifestationProgram/SocialShareButtons';
import { useLocation, useParams } from 'react-router-dom';

function ModalNew({show,handleClose}) {
  const location = useLocation();
  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <div>
          <Modal.Title style={{fontSize:"1.1rem",color:"black"}}>Share With Friends</Modal.Title>
          </div>
          <span className='text-end'>
            <AiOutlineClose size={20} onClick={handleClose} color='gray' />
          </span>
        </Modal.Header>
        <Modal.Body>
            <SocialShareButtons url={location.pathname} />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
  )
}

export default ModalNew