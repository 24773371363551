import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AllContext'
import { Card } from 'react-bootstrap';
import "./productsection.css"
import { useState } from 'react';
import { useEffect } from 'react';
import { requestData } from '../../../../utils/baseUrl';
import { useNavigate } from 'react-router-dom';
import HeadingNew from '../../../AboutUs/HeadingNew';

function FourthHeroSection() {

    const navigate = useNavigate()
    const { isDesktopOrLaptop, isBigScreen, userData } = useContext(AuthContext);
    const [listData, setListData] = useState([]);


    function arraysAreEqual(arr1, arr2) {
        // Check if every element in arr2 is included in arr1
        return arr2.every(element => arr1.includes(element));
    }

    const getAllData = async () => {

        const res = await requestData('latestCourseList', "POST", {
            "start_index": "0",
            "no_of_records": "20"
        });


        // console.log(res,'rtesponbse')

        


        const enrolled = userData ? userData.enrolled_course.map((val) => val.course_id) : [];
       


        const ff = res.data.map((c) => {
            if (c.is_combo_course === "Yes") {
                const mappedincluded = c.included_course.map((val) => val.course_id);

                const result = arraysAreEqual(enrolled, mappedincluded)

                return {
                    ...c,
                    course_purchased: result
                }

            } else {
             

                return {
                    ...c,
                    course_purchased: enrolled.includes(c.course_id)
                }

            }
        })







        // const mappedData = []




        // let filtered;


        // if (enrolled.length === Number(userData.total_course)) {
        //     filtered = mappedData.filter((val) => val.course_id !== "combo-plan")
        // } else {
        //     filtered = mappedData
        // }





        // const enrolledData = userData? userData.enrolled_course.map((val)=>val.course_id):[];


        setListData(ff)
    }

    useEffect(() => {
        getAllData();
    }, [userData]);


    const handleNavigate = async (course_id) => {

        // console.log(course_id,'course_id')

        if (course_id === "silva-method-complete-course-with-live-interactive-program") {
            navigate("/store/course/combo-plan")
        } else {
            navigate(`/store/course/${course_id}`)
        }
    };



    useEffect(() => {
        console.log(listData, "listData")

        if (listData && listData.length > 0) {
            console.log(listData, "listData")
        }
    }, [listData])

    return (
        <div className='mt-4 indivcourses' style={{ position: "relative", paddingTop: "50px", paddingBottom: "50px" }}>


            {/* <h2 className='mb-3' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop || isBigScreen ? "60px" : "30px", textAlign: "center" }}>
                Explore
            </h2>
            <h2 className='mb-3' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop || isBigScreen ? "40px" : "25px", textAlign: "center" }}>
                Silva Individual Courses
            </h2> */}
            <div className='mb-5'>
                <HeadingNew title={"Explore"} colored={"Silva Method Courses"} />
            </div>

            <div className="container-fluid">
                <div className='productcardsection'>
                    {
                        listData?.reverse().map((val, i) => (
                            // <Link to={`/store/course/${val?.course_id}`} key={i}>

                            <Card className='productsinglecard boxshadowclass' key={i} onClick={() => {
                                handleNavigate(val.course_id);
                            }} style={{
                                // backgroundImage: `url(${val?.web_vertical_image})` || "https://silvamethod.com/manage/assets/uploads/Advanced%20combo-22.webp"
                                backgroundImage:`url(${val?.course_id==="begineer-combo-course"?'https://silvamethod.com/manage/assets/uploads/combo%20course-2_1.webp':val?.course_id==="advanced-combo-course"?'https://silvamethod.com/manage/assets/uploads/Advanced%20combo-22.webp':val?.web_vertical_image})` 
                            }}
                            >
                                <Card.Body>
                                    {
                                        (val?.discount_percentage && val?.discount_percentage != 0) && val.course_purchased === false && (
                                            <div className='fourthprice'>

                                            </div>
                                        )
                                    }

                                    {
                                        (!val?.discount_percentage || val?.discount_percentage == 0) && val.course_purchased === false && (
                                            <div className='fourthprice2'>

                                            </div>
                                        )
                                    }
                                    {
                                        val.course_purchased === false && (
                                            <div className='fourthprices'>


                                                {
                                                    (val?.discount_percentage && val?.discount_percentage != 0) && (val.course_purchased === false) && (
                                                        <h3 style={{ textDecoration: "line-through", color: "red" }}>
                                                            <h3 style={{ color: "white" }} className='text-center'>
                                                                ${val?.price}
                                                            </h3>
                                                        </h3>
                                                    )
                                                }

                                                {
                                                    (val?.discount_percentage && val?.discount_percentage != 0) && (val.course_purchased === false) && (
                                                        <h4 style={{ color: "white" }} className='text-center'>
                                                            ${Math.floor((val?.price) - ((val?.discount_percentage * parseInt(val?.price)) / 100))}
                                                        </h4>
                                                    )
                                                }

                                            </div>
                                        )
                                    }

                                    <div className="fourthprices2">
                                        {
                                            (!val?.discount_percentage || val?.discount_percentage == 0) && (val.course_purchased === false) && (
                                                <h4 style={{ color: "white" }} className='text-center'>
                                                    ${val?.price}
                                                </h4>
                                            )
                                        }

                                    </div>

                                    <div className='lastfourthcardsection'>
                                        <h4 className='lastfourthcardheader'>
                                            {
                                                val?.course_title
                                            }
                                        </h4>
                                        {
                                            val?.total_chapters && val?.total_lessons ? (
                                                <h5 className='lastfourthcardheader2'>
                                                    {val?.total_chapters} {val?.total_chapters < 2 ? "Chapter" : "Chapters"} | {val?.total_lessons} {val?.total_lessons < 2 ? "Lesson" : "Lessons"}
                                                </h5>
                                            ) : val?.course_id==='advanced-combo-course'?(
                                                <h5 className='lastfourthcardheader2'>
                                                    23 Chapters | 104 Lessons
                                                </h5>
                                            ): val?.course_id==='begineer-combo-course'?(
                                                <h5 className='lastfourthcardheader2'>
                                                    27 Chapters | 126 Lessons
                                                </h5>
                                            ):null
                                        }


                                        <div className='lastfourthsection2'>


                                            <button className='herodiv-becomememberbtn3'>
                                                {val?.course_purchased ? "View Course" : "Enroll Now"}
                                            </button>


                                            <span className='fourthsectionspan'>
                                                Life Time Access
                                            </span>

                                        </div>

                                    </div>
                                </Card.Body>

                            </Card>
                            // </Link>

                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default FourthHeroSection