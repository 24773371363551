import { AuthContext } from '../../context/AllContext';
import React, { useContext } from 'react'
import { Card } from 'react-bootstrap'

function WeeksCard({ title, text,number }) {
    const { isMobile } = useContext(AuthContext);

    return (
        <Card style={{
            // border: "1px solid darkgray",
            width: "auto",
            height: isMobile ? "auto" : "170px"
        }}>
            <Card.Header className='d-flex justify-content-start align-items-center gap-3 cardbg' style={{
                // border: "1px solid darkgray",

            }}>
                <div className={`mycircle`}>
                    <span>{number}</span>
                </div>
                {/* <h6 className='white-color'>WEEK 1</h6> */}
                <h6 className='white-color mt-2' style={{
                    lineHeight: "25px"
                }}>{title || "Fundamentals of Performance Marketing"}</h6>
            </Card.Header>
            <Card.Body className='cardbg2' style={
                {
                    // border: "1px solid darkgray",
                }
            }>
                <p className='white-color' >
                    {text || "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto cupiditate dicta qui minima atque placeat suscipit ea mollitia neque facilis? Voluptatem iure blanditiis aut autem recusandae, a ut aperiam iusto!"}
                </p>
            </Card.Body>
        </Card>
    )
}

export default WeeksCard