import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

function ImgComp({ src, classes = "", link = null }) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })

    return (
        <div className={`row justify-content-center align-items-center align-content-center mb-3 ${classes}`}>
            <div className="col-sm-12 col-md-10">
                {
                    link ? (
                        <Link to={link}>
                            <img src={src} style={{ width: "100%", height: isDesktopOrLaptop ? "500px" : "250px", objectFit: "contain" }} />
                        </Link>
                    ) : (
                        <img src={src} style={{ width: "100%", height: isDesktopOrLaptop ? "500px" : "250px", objectFit: "contain" }} />
                    )
                }
            </div>
        </div>
    )
}

export default ImgComp