import React, { useContext, useMemo, useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { useEffect } from 'react';
import { AuthContext } from '../../../context/AllContext';

function EqTestComp() {
    const { isMobile } = useContext(AuthContext)
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [answers, setAnswers] = useState(new Array(10).fill(null)); // Initialize answers array with null values for each question
    const [totalScore, setTotalScore] = useState(40);
    const [result, setResult] = useState(null);

    const questions = useMemo(() => {
        return [
            {
                question: "You receive constructive criticism from your boss. How do you react?",
                options: [
                    "Get defensive and argue back",
                    "Take it personally and feel upset",
                    "Brush it off and ignore it",
                    "Listen attentively and consider the feedback",

                ],
                scores: [1, 2, 3, 4],
            },
            {
                question: "You're stuck in traffic and running late for an important meeting. How do you handle the situation?",
                options: [
                    "Become anxious and stressed",
                    "Practice deep breathing and stay calm",
                    "Honk and express frustration",
                    "Resign to the situation and accept being late",
                ],
                scores: [2, 4, 1, 3],
            },
            {
                question: "You make a mistake that affects your team's project. What's your next step?",
                options: [
                    "Blame others and avoid taking responsibility",
                    "Panic and feel overwhelmed with guilt",

                    "Ignore it and hope no one notices",
                    "Apologize and offer solutions to fix it",
                ],
                scores: [1, 3, 2, 4],
            },
            {
                question: "How do you handle conflicts within your family?",
                options: [
                    "Listen to everyone's perspective and work towards a resolution", ,
                    "Avoid confrontation and keep your feelings to yourself",
                    "Engage in arguments to prove your point",
                    "Walk away and give yourself space to cool off",
                ],
                scores: [4, 2, 1, 3],
            },
            {
                question: "How do you handle rejection in a romantic relationship?",
                options: [
                    "Blame yourself and dwell on your flaws",
                    "Convince yourself you never liked them anyway",
                    "Allow yourself to feel sad but focus on self-improvement and moving forward",
                    "Rebound immediately with someone else to avoid facing your feelings",
                ],
                scores: [1, 2, 4, 3],
            },
            {
                question: "How do you react when someone interrupts you while speaking?",
                options: [
                    "Get annoyed and raise your voice to be heard",
                    "Politely acknowledge their input and continue your point afterward",
                    "Shut down and let them take over the conversation",
                    "Interrupt them back to assert your dominance",
                ],
                scores: [2, 4, 3, 1],
            },
            {
                question: "How do you react when someone disagrees with your opinion?",
                options: [
                    "Argue aggressively to prove your point",
                    "Feel personally attacked and defensive",

                    "Dismiss their opinion as irrelevant",
                    "Listen to their perspective and engage in constructive dialogue",
                ],
                scores: [1, 2, 3, 4],
            },
            {
                question: "You're at a social gathering where you don't know anyone. How do you approach the situation?",
                options: [
                    "Strike up conversations with different people and try to find common interests",
                    "Stay by yourself and avoid interacting with others",
                    "Introduce yourself to a few people but feel awkward and uncomfortable",
                    "Leave the gathering early because you feel out of place",
                ],
                scores: [4, 3, 1, 2],
            },
            {
                question: "Your friend is going through a tough time emotionally. How do you support them?",
                options: [
                    "Offer practical solutions to fix their problems",
                    "Listen empathetically without offering advice",
                    "Provide distractions to take their mind off things",
                    "Tell them to toughen up and get over it",
                ],
                scores: [3, 4, 2, 1],
            },
            {
                question: "How do you deal with someone who is always negative?",
                options: [
                    "Avoid them as much as possible",
                    "Argue with them to prove they're wrong",
                    "Listen to them and try to offer a different perspective",
                    "Ignore their negativity and change the subject",
                ],
                scores: [2, 1, 4, 3],
            },
        ];
    }, []);

    const handleOptionChange = (questionIndex, answerIndex) => {
        const updatedAnswers = [...answers];
        updatedAnswers[questionIndex] = answerIndex; // Store the selected answer for the question
        setAnswers(updatedAnswers);
    };

    const handleNextQuestion = () => {
        setActiveQuestion(activeQuestion + 1);
    };

    const handlePreviousQuestion = () => {
        setActiveQuestion(activeQuestion - 1);
    };

    const hasAnsweredCurrentQuestion = () => {
        return answers[activeQuestion] !== null;
    };

    const calculateTotalScore = () => {
        let score = 0;
        answers.forEach((answer, index) => {
            if (answer !== null) {
                score += questions[index].scores[answer];
            }
        });
        setTotalScore(score);
        determineResult(score);
    };


    const determineResult = (score) => {
        if (score >= 34) {
            setResult(
                <div>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Congratulations on Your Outstanding EQ Results!
                    </p>
                    <p style={{
                        color: "white"
                    }}>
                        We are thrilled to see that your EQ test results indicate an exceptionally high level of emotional intelligence. You have demonstrated a profound ability to understand and manage your emotions, empathize with others, and easily navigate social complexities. These skills are invaluable in both personal and professional spheres, leading to deeper relationships and more fulfilling experiences.
                    </p>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Elevate Your Skills with the Silva Method
                    </p>
                    <p className='white-color'>
                        You’re already a pro at emotional intelligence, but there’s always more to learn and achieve. <b>The Silva Method Complete Course</b> is designed to help you refine and expand your skills.  Purchase now and get lifetime access to the complete Silva course with live interactive sessions with the instructors.
                    </p>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Special Offer Just for You!
                    </p>
                    <p className='white-color'>
                        As a token of our appreciation for taking the EQ test, we’re excited to offer you a <b>55% discount</b> on the Silva Method Complete Course. Use the code <b>DESIRE24</b> to claim this exclusive offer.
                    </p>
                    <p className='white-color'>
                        Embark on your journey to becoming the best version of yourself. Enroll today and continue to cultivate the extraordinary emotional intelligence you already possess!
                    </p>

                    <button className='cssbuttons-io-button' onClick={() => {
                        window.open("https://silvamethod.com/store/course/combo-plan?token=DESIRE24")
                    }}> Get Flat 55% off</button>

                </div>
            );
        } else if (score >= 25) {
            setResult(
                <div>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Great Job on Your EQ Test!
                    </p>
                    <p style={{
                        color: "white"
                    }}>
                        You've done a fantastic job on your EQ test, showing a solid understanding of emotional intelligence. With some additional growth, you can greatly enhance your communication and relationships.
                    </p>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Boost Your EQ with the Silva Method
                    </p>
                    <p className='white-color'>
                        <b>The Silva Method Complete Course</b> is perfect for helping you build on your existing strengths.  This Course is designed to help you refine and expand your skills.  Purchase now and get lifetime access to the complete Silva course with live interactive sessions with the instructors.
                    </p>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Special Offer Just for You!
                    </p>
                    <p className='white-color'>
                        As a token of our appreciation for taking the EQ test, were excited to offer you a <b>55% discount</b> on the <b>Silva Method Complete Course</b>. Use the code <b>DESIRE24</b> to claim this exclusive offer.
                    </p>
                    <p className='white-color'>
                        Take the next step in your personal development. Enroll today and unlock your full potential!

                    </p>

                    <button className='cssbuttons-io-button' onClick={() => {
                        window.open("https://silvamethod.com/store/course/combo-plan?token=DESIRE24")
                    }}> Get Flat 55% off</button>
                </div>
            );
        } else {
            setResult(
                <div>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Thank You for Taking the EQ Test!
                    </p>
                    <p style={{
                        color: "white"
                    }}>
                        Your results indicate that there are significant areas for improvement in your emotional intelligence. This is a wonderful first step in recognizing where you can grow. Enhancing your EQ can lead to better self-understanding, improved relationships, and greater overall well-being.
                    </p>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Elevate Your Emotional Intelligence with the Silva Method
                    </p>
                    <p className='white-color'>
                        <b>The Silva Method Complete Course</b> is specially designed to help you develop the core aspects of emotional intelligence. Purchase now and get lifetime access to the complete <b>Silva course with live interactive sessions</b> with the instructors.
                    </p>
                    <p style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "1.5rem"
                    }}>
                        Special Offer Just for You!
                    </p>
                    <p className='white-color'>
                        As a token of our appreciation for taking the EQ test, were excited to offer you a 55% discount on the <b>Silva Method Complete Course</b>. Use the code <b>DESIRE24</b> to claim this exclusive offer.
                    </p>
                    <p className='white-color'>
                        Now is the perfect time to invest in yourself and unlock your full potential. Enroll today and start building the emotional skills that will lead you to a more balanced and fulfilling life!

                    </p>

                    <button className='cssbuttons-io-button' onClick={() => {
                        window.open("https://silvamethod.com/store/course/combo-plan?token=DESIRE24")
                    }}> Get Flat 55% off</button>
                </div>
            );
        }
    };



    const handleSubmit = () => {
        calculateTotalScore();
    };



    return (
        <div className='my-5 container d-flex justify-content-center align-items-center flex-column gap-3'>


            <Card style={{
                borderRadius: "15px",
                padding: "15px",
                width: isMobile ? "100%" : "90%",
                background: "linear-gradient(to right, #7F00FF, #E100FF)"
            }} >

                <Card.Header>
                    <h2 style={{
                        color: "WHITE",
                        textAlign: isMobile ? "start" : "start",
                        fontWeight: "700",
                        fontSize: isMobile ? "1.5rem" : "2rem"
                    }}
                    className='my-3'
                    >
                        Try Our Free <span style={{
                            color: "rgb(255, 193, 7)",
                        }}>
                            Emotional Quotient
                        </span> Test
                    </h2>
                    <ParaComp
                        data={[
                            "How well are you managing your emotions? Participate in our free EQ Self-Assessment test to discover your emotional intelligence level."
                        ]}
                        margin={false}
                        center={isMobile ? false : false}
                        white={true}
                    />

                </Card.Header>

                {result ? (
                    <Card.Body>
                        <div class="card__border"></div>
                        {result}
                    </Card.Body>
                ) : (
                    <>  <div class="card__border"></div>
                        <Card.Body>
                            <Badge bg='success' style={{
                                fontSize: "1rem",
                                marginBottom: "15px",
                                padding: "7px 15px",
                                borderRadius: "8px"
                            }}>{activeQuestion + 1} / 10</Badge>
                            <div>
                                <p style={{
                                    color: "white",
                                    fontWeight: "600",
                                    fontSize: "1.2rem",
                                    lineHeight: "1.6rem",

                                }}>
                                    {questions[activeQuestion].question}
                                </p>

                                <div>
                                    {questions[activeQuestion].options.map((val, i) => (
                                        <div className="form-check" style={{ fontSize: "1.15rem", marginBottom: "8px" }} key={i}>
                                            <input className="form-check-input" value={i} type="radio" name="flexRadioDefault" id={`radio-${i + 1}`}
                                                checked={answers[activeQuestion] === i} // Check if this option is selected for the current question
                                                onChange={() => handleOptionChange(activeQuestion, i)} />
                                            <label className="form-check-label" htmlFor={`radio-${i + 1}`} style={{ fontSize: "0.9rem", fontWeight: "500", color: "white" }}>
                                                {val}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='d-flex justify-content-start align-items-center gap-3 mt-3 flex-wrap'>
                                {activeQuestion !== 0 && (
                                    <button className='btn btn-warning' onClick={handlePreviousQuestion}>Previous Question</button>
                                )}

                                {activeQuestion === questions.length - 1 && hasAnsweredCurrentQuestion() && (
                                    <button className='btn btn-success' onClick={handleSubmit}>Submit</button>
                                )}

                                {hasAnsweredCurrentQuestion() && activeQuestion !== questions.length - 1 && (
                                    <button className='btn btn-light' onClick={handleNextQuestion}>Next Question</button>
                                )}
                            </div>
                        </Card.Body>
                    </>
                )}
            </Card>
        </div>
    );
}

export default EqTestComp;
