import React from 'react'
import Badge from './Badge'
import HeadingNew from '../../AboutUs/HeadingNew'
import { MdChevronRight } from "react-icons/md";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import { Button } from 'react-bootstrap';

function MindControlBooks({handleShow}) {
  const { isMobile ,isTablet, isDesktopOrLaptop,} = useContext(AuthContext);

  return (
    // <div className='py-5 d-flex flex-column justify-content-center align-items-center'>
    //     <div className='d-flex justify-content-center align-items-center'>
    //         <Badge text='The Silva' color='White' backgroundColor='#472078'/>
    //     </div>
    //     <div>
    //         <HeadingNew title={"Mind Control Method"}/>
    //         <h5 className='text-black'>The Revolutionary Program by the Founder of World's Most Famous Mind Control Course </h5>
    //     </div>
    //     <div className='d-flex gap-3'>
    //         <button className='px-4 py-3' style={{borderRadius:"10px",color:"black",fontWeight:"700",backgroundColor:"#f1c40f",border:"none"}}>Want to read <span><MdChevronRight /></span></button>
    //         <button className='px-4 py-3' style={{borderRadius:"10px"}}>Download free PDF <span><MdChevronRight /></span></button>
    //     </div>
    // </div>
    <div className='position-relative'>
      <img src={isMobile ? "/img/book/book-img-for-mobile.webp" :isTablet? "/img/bg/silva-book-page-3-28-3.webp":"/img/bg/silva-book-page-3-28.webp"} alt="" style={{ width: "100%", height: "100%" }} />
      <div style={{ position: "absolute", top: isMobile ? 10 : 50, left: "50%", padding: isMobile ? "10px" :(isTablet || isDesktopOrLaptop)?"0px": "60px", transform: "translateX(-50%)", width: "100%" }}>
        <div className='d-flex justify-content-center align-items-center'>
          <Badge text='The Silva' color='White' backgroundColor='#472078' />
        </div>
        <div>
          <HeadingNew title={"Mind Control Method"} white />
          <h5 className='text-white my-3 text-center'>The Revolutionary Program by the Founder of World's Most Famous Mind Control Course </h5>
        </div>
        {
          isMobile?(
            <div className='d-flex justify-content-center align-items-center gap-3'>
          <Button size='sm' variant="warning" style={{
            borderRadius:"5px",
            padding:"12px 15px",
            fontWeight:"600",
            backgroundColor:"#f1c40f",
            border:"none",
            flex:"none",
          }}
          onClick={handleShow}
          >Want to read <span><MdChevronRight /></span></Button>
          {/* <a size='sm' variant="outline-light" style={{
            borderRadius:"5px",
            fontWeight:"600",
            padding:"12px 15px",
            flex:"none",
          }} href="/documents/The_Silva_Mind_Control_Method_Book.pdf"> */}
            Download free PDF  
            <span><MdChevronRight color='#fff'/></span>
            {/* </a> */}

        </div>
          ):(
            <div className='d-flex gap-3 justify-content-center align-items-center my-4 flex-wrap'>
            <button className='px-3 py-3' style={{borderRadius:"10px",color:"black",fontWeight:"600",backgroundColor:"#f1c40f",border:"none"}} onClick={handleShow}>Want to read <span><MdChevronRight /></span></button>
             <button className='px-3 py-3' style={{borderRadius:"10px",backgroundColor:"transparent",border:"2px solid white",color:"#fff",fontWeight:"700"}} onClick={handleShow}>Download free PDF <span><MdChevronRight color='#fff'/></span></button>
         </div>
          )
        }
       
      </div>
    </div>
  )
}

export default MindControlBooks