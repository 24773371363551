
export const REACT_BASE_URL = process.env.REACT_APP_BASE_URL;

export const domainUrl = process.env.REACT_APP_DOMAIN_URL

export const baseUrl = process.env.REACT_APP_API_URL;


export const requestData = async function (path, method = "POST", body) {

    // console.log(token3,"tokensending")
    //console.log(body);
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    const st_id = localStorage.getItem("token")
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")
    // if(token3){
    //     myHeaders.append("Token",token3)
    // }else if(newtoken){
    //     myHeaders.append("Token",newtoken)
    // }

    if (country) {
        myHeaders.append("country", country.toString())
    }

    if (currency) {
        myHeaders.append("currency", currency.toString())

    }
    if (st_id) {
        myHeaders.append("studentid", st_id.toString())
    }


    const formData = new FormData();

    const keys = Object.keys(body);

    if (keys.length > 0) {
        for (let key in body) {
            formData.append(key.toString(), body[key])
        }
    }


    // console.log(typeof parseInt(st_id),st_id,"sudentid")

    const options = {
        method: method,
        headers: myHeaders,
        body: formData
    }

    const res = await fetch(`${baseUrl}${path}`, options)
    const data = await res.json();
    return data
}


export const reqData = async function (path, method = "POST", body) {

    //console.log(body);
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");


    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")


    if (country) {
        myHeaders.append("country", country.toString())
    }

    if (currency) {
        myHeaders.append("currency", currency.toString())

    }
    const st_id = localStorage.getItem("token")

    // if(token3){
    //     myHeaders.append("Token",token3)
    // }else if(newtoken){
    //     myHeaders.append("Token",newtoken)
    // }

    if (st_id) {
        myHeaders.append("studentid", parseInt(st_id))
    }

    const formData = new FormData();

    const keys = Object.keys(body);

    if (keys.length > 0) {
        for (let key in body) {
            formData.append(key.toString(), body[key])
        }
    }

    const options = {
        method: method,
        headers: myHeaders,
        body: formData
    }

    const res = await fetch(`${baseUrl}${path}`, options)
    const data = await res.json();
    return data
}


export const requestData2 = async function (path, method = "POST", body) {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")


    if (country) {
        myHeaders.append("country", country.toString())
    }

    if (currency) {
        myHeaders.append("currency", currency.toString())

    }
    const st_id = localStorage.getItem("token")


    // console.log("parseint",st_id,typeof st_id);
    // console.log("parseint",parseInt(st_id),typeof parseInt(st_id))
    // if(token3){
    //     myHeaders.append("Token",token3)
    // }else if(newtoken){
    //     myHeaders.append("Token",newtoken)
    // }

    if (st_id) {
        myHeaders.append("studentid", parseInt(st_id))
    }

    const formData = new FormData();

    const keys = Object.keys(body);

    if (keys.length > 0) {
        for (let key in body) {
            formData.append(key.toString(), body[key])
        }
    }
    // console.log(myHeaders)
    const options = {
        method: method,
        headers: myHeaders,
        body: formData
    }

    const res = await fetch(`${baseUrl}${path}`, options)
    const data = await res.json();
    return data
}



export const requestData3 = async function (path, method = "POST", body) {
    //console.log(body);
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")


    if (country) {
        myHeaders.append("country", country.toString())
    }

    if (currency) {
        myHeaders.append("currency", currency.toString())

    }
    const st_id = localStorage.getItem("token")

    // console.log(token3,"tokensending")
    if (st_id) {
        myHeaders.append("studentid", parseInt(st_id))
    }

    // if(token3){
    //     myHeaders.append("Token",token3)
    // }else if(newtoken){
    //     myHeaders.append("Token",newtoken)
    // }

    const formData = new FormData();

    const keys = Object.keys(body);

    if (keys.length > 0) {
        for (let key in body) {
            formData.append(key.toString(), body[key])
        }
    }

    const options = {
        method: method,
        headers: myHeaders,
        body: formData
    }

    const res = await fetch(`${baseUrl}${path}`, options)
    const data = await res.json();
    return data
}

export const requestData4 = async function (path, method = "POST", body) {
    //console.log(body);
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")


    if (country) {
        myHeaders.append("country", country.toString())
    }

    if (currency) {
        myHeaders.append("currency", currency.toString())

    }
    const st_id = localStorage.getItem("token")

    if (st_id) {
        myHeaders.append("studentid", parseInt(st_id))
    }
    // if(token3){
    //     myHeaders.append("Token",token3)
    // }else if(newtoken){
    //     myHeaders.append("Token",newtoken)
    // }

    const formData = new FormData();

    const keys = Object.keys(body);

    if (keys.length > 0) {
        for (let key in body) {
            formData.append(key.toString(), body[key])
        }
    }

    const options = {
        method: method,
        headers: myHeaders,
        body: formData
    }

    const res = await fetch(`${baseUrl}${path}`, options)
    const data = await res.json();
    return data
}


export const requestData5 = async function (path, method = "POST", body) {
    //console.log(body);
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")


    if (country) {
        myHeaders.append("country", country.toString())
    }

    if (currency) {
        myHeaders.append("currency", currency.toString())

    }
    const st_id = localStorage.getItem("token")
    if (st_id) {
        myHeaders.append("studentid", parseInt(st_id))
    }
    // if(token3){
    //     myHeaders.append("Token",token3)
    // }else if(newtoken){
    //     myHeaders.append("Token",newtoken)
    // }else{
    //     myHeaders.append("Token",reactLocalStorage.get("token"))
    // }

    const formData = new FormData();

    const keys = Object.keys(body);

    if (keys.length > 0) {
        for (let key in body) {
            formData.append(key.toString(), body[key])
        }
    }

    const options = {
        method: method,
        headers: myHeaders,
        body: formData
    }

    const res = await fetch(`${baseUrl}${path}`, options)
    const data = await res.json();
    return data
}




export const getProductsfromWP = async () => {
    const consumerKey = "ck_867f7222debbc2379ec6089b2adceb34c5fe9aa8";
    const consumerSecret = "cs_055b06fdc6c875b666d6e4b06709f355cb685239";

    const url = `https://silvamethod.com/store/wp-json/wc/v3/products?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`
    const res = await fetch(url);
    const data = await res.json();
    return data;
}


export const checkIsCoursePurchased= async(course_id,userData)=>{
    // console.log(course_id,userData,'params')
    const res = await requestData("myOrders", "POST", {
    });
    // console.log(res,'result');
    // const orders= res.data;
     const purchasedCourse= userData?.enrolled_course;

     if(course_id ==='combo-plan' || course_id === 'silva-method-complete-course-with-live-interactive-program'){
        const courseExists = res?.data?.some(course => course.course_id === course_id);
        // console.log(courseExists,'course exist')
        if(courseExists){
            return true;
        }else{
            return false;
        }
     }else{
        if(purchasedCourse.includes(course_id)){
            return true;
        }else{
            return false;
        }
     }
}



