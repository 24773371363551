import { useContext } from "react";
import { AuthContext } from "../../../../context/AllContext";
import { Navigate } from "react-router-dom";
import HomeHeader from "../HomeHeader/HomeHeader";
import Homeherodiv from "../HomeHeroSection/Homeherodiv";
import Featured from "../../../../components/featured/Featured";
import BreakThroughs from "../../../silvamembership/BreakThroughs";
import AboutSilvaMethod from "../../../AboutUs/AboutSilvaMethod";
import HowSilvaWorks from "../HowSilvaWorks";
import SilvaBenefits from "../SilvaBenefits";
import ResearchSection from "../Researchsection/ResearchSection";
import HomeClasses from "../HomeClasses";
import Testimonials from "../../../../components/featured/Reviews";
import ProductSlide from "../../../Store/products/ProductSlide";
import HomeCountrySection from "../../../../components/CountrySection/HomeCountrySection";
import AudioBlogs from "../../../Blogs/AudioBlog";
import BecomeMember from "../BecomeMember";
import Newsletter from "../../../../components/Newsletter/Newsletter";
import CustomFooter from "../HomeFooter/Footer";
import CourseSlide from "../../../../components/HomeHeroSingleSlide/FirstSlide";



const HomeStudy2 = () => {
  const { isDesktopOrLaptop, userData, isUserLoggedIn } = useContext(AuthContext);
  //console.log(userData);

  if (isUserLoggedIn && userData && userData.subscription !== "Subscribed") {
    return <Navigate to="/silva-membership" />
  } else if (isUserLoggedIn && userData && userData.subscription === "Subscribed") {
    return <Navigate to="/today" />
  }
  // else if(isUserLoggedIn && userData && userData.strip_payment_status!=="paid"){
  //   return <Navigate to="/silva-membership" />
  // }
  return (
    <div>
      {/* <Helmet>
        <title>Silva Home Page</title>
        <meta name='description'
          content='Silva method Membership'
        />
      </Helmet> */}

      
      <HomeHeader />
      <Homeherodiv />
      <Featured />
      <BreakThroughs />
      <AboutSilvaMethod />
      <CourseSlide />
      <div className="parallexabout">
        <HowSilvaWorks />
        <SilvaBenefits />
        <ResearchSection />
      </div>

      <HomeClasses />
      <Testimonials />
      {/* <AboutSilva /> */}
      <div className={isDesktopOrLaptop ? "" : "col-12 m-auto pt-2 pb-5"}>
        <ProductSlide />

      </div>

      {/* <HomeProducts /> */}
      {/* <InstructorsSlide/> */}
      {/* <HomeEvents/> */}
      {/* <HomeBlogs /> */}
      <HomeCountrySection />
      <AudioBlogs />
      <BecomeMember />
      <Newsletter />

      <CustomFooter />
    </div>

  );
};

export default HomeStudy2;