import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'

function DuartionCard({ icon, title, text, br = false }) {
    const { isMobile } = useContext(AuthContext);

    return (
        <div className='d-flex justify-content-start align-items-center flex-column' style={{
            padding: "15px 20px",
            borderRight: isMobile ? "none" : br ? "1px solid lightgray" : "none",
            height: isMobile ? "auto" : "300px",
            borderBottom: isMobile ? "1px solid lightgray" : "none"
        }}>
            <span className='mb-4'>
                {icon}
            </span>
            <div style={{
                height: isMobile ? "auto" : "80px",
                marginBottom: isMobile ? "15px" : "0"
            }}>
                <HeadingNew
                    white={false}
                    small
                    title={title}

                />
            </div>


            <div>
               
                <ParaComp
                    white={false}
                    margin={false}
                    fw={500}
                    center

                    data={text}
                />
            </div>


        </div>
    )
}

export default DuartionCard