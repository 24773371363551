import React from "react";
import { Card, Image } from "react-bootstrap";
import CountUp from "react-countup";
import HeadingNew from "../../../AboutUs/HeadingNew";

function SecondHeroSection() {
  return (
    <div style={{ position: "relative" }} className="secondherocont">
      <div className="pattern"></div>
      <div className="mb-4">
      <HeadingNew title={'Why choose'} colored={"Silva Method"}/>
      </div>
      <div style={{ zIndex: "11", position: "relative" }}>
        <div className="socialmediaherosection section1">
        <div
            style={{
              width: "fit-content",
              padding: "0px 15px",
              borderRadius: "20px",
              border:"1px solid #747682"
            }}
            
          >
            <Card.Body className="d-flex justify-content-center align-items-center gap-4">
              <Image
                fluid
                src={require("../../../../images/adimgs/icons/icon-07.png")}
                style={{ width: "30px", height: "25px" }}
              />
              <div>
                <h5 className="text-black fw-bold">Testimonials</h5>
                <div className="d-flex justify-content-center align-items-end">
                  <h5 className="text-black fw-bold">1M+</h5>
                </div>
              </div>
            </Card.Body>
          </div>
          <div
            style={{
              width: "fit-content",
              padding: "0px 15px",
              borderRadius: "20px",
              border:"1px solid #747682",
            }}
            
          >
            <Card.Body className="d-flex justify-content-center align-items-center gap-4">
              <Image
                fluid
                src={require("../../../../images/adimgs/icons/icon-05.png")}
                style={{ width: "30px", height: "30px" }}
              />
              <div>
                <h5 className="text-black fw-bold">Amazon</h5>
                <div className="d-flex justify-content-center align-items-end">
                  <h5 className="text-black fw-bold">4.9</h5>
                  <h5 className="text-black fw-bold">/5</h5>
                </div>
              </div>
            </Card.Body>
          </div>
          <div
            style={{
              width: "fit-content",
              padding: "0px 15px",
              borderRadius: "20px",
              border:"1px solid #747682"
            }}
          >
            <Card.Body className="d-flex justify-content-center align-items-center gap-4">
              <Image
                fluid
                src={require("../../../../images/adimgs/icons/icon-06.png")}
                style={{ width: "20px", height: "30px" }}
              />
              <div>
                <h5 className="text-black fw-bold">Facebook</h5>
                <div className="d-flex justify-content-center align-items-end">
                  <h5 className="text-black fw-bold">4.7</h5>
                  <h5 className="text-black fw-bold">/5</h5>
                </div>
              </div>
            </Card.Body>
          </div>
      
        </div>
        <div className="socialmediaherosection section2">
          <div
            style={{
              width: "fit-content",
              // padding: "8px 15px",
              borderRadius: "20px",
              border:"1px solid #747682"
            }}
          >
            <Card.Body className="d-flex justify-content-center align-items-center gap-4">
            <div style={{width:"70px",height:"70px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"10px",padding:"10px"}}>
                <Image
                  fluid
                  src={require("../../../../images/adimgs/icons/icon-10.png")}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <div className="d-flex justify-content-center align-items-end">
                    <CountUp
                     style={{fontWeight:"700", fontSize:"1.5rem",color:"black"}}
                    end={500}
                    duration={2.5}
                    suffix="+"
                  />
                </div>
                <h4>Instructors</h4>
              </div>
            </Card.Body>
          </div>
          <div
            style={{
              width: "fit-content",
              // padding: "8px 15px",
              borderRadius: "20px",
              border:"1px solid #747682"
            }}
          >
            <Card.Body className="d-flex justify-content-center align-items-center gap-4">
            <div style={{width:"70px",height:"70px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"10px",padding:"10px"}}>
                <Image
                  fluid
                  src={require("../../../../images/adimgs/icons/icon-09.png")}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <div className="d-flex justify-content-center align-items-end">
                <CountUp
                 style={{fontWeight:"700", fontSize:"1.5rem",color:"black"}}
                  end={120}
                  duration={2.5}
                  suffix="+"
                />
                </div>
                <h4>Countries</h4>
              </div>
            </Card.Body>
          </div>
          <div
            style={{
              width: "fit-content",
              // padding: "8px 15px",
              borderRadius: "20px",
              border:"1px solid #747682"
            }}
          >
            <Card.Body className="d-flex justify-content-center align-items-center gap-4">
            <div style={{width:"70px",height:"70px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"10px",padding:"10px"}}>
                <Image
                  fluid
                  src={require("../../../../images/adimgs/icons/icon-08.png")}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <div className="d-flex justify-content-center align-items-end">
                <CountUp
                  style={{fontWeight:"700", fontSize:"1.5rem",color:"black"}}
                  end={12}
                  duration={2.5}
                  suffix="M+"
                />
                </div>
                <h4>Graduates</h4>
              </div>
            </Card.Body>
          </div>
          <div
            style={{
              width: "fit-content",
              // padding: "8px 15px",
              borderRadius: "20px",
              border:"1px solid #747682"
            }}
          >
            <Card.Body className="d-flex justify-content-center align-items-center gap-2">
            <div style={{width:"70px",height:"70px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"10px",padding:"10px"}}>
                <Image
                  fluid
                  src={require("../../../../images/adimgs/icons/icon-11.png")}
                  style={{ width: "100%", height: "100%"}}
                />
              </div>
              <div>
                <div className="d-flex justify-content-center align-items-end">
                <CountUp
                  style={{fontWeight:"700", fontSize:"1.5rem",color:"black"}}
                  end={100}
                  duration={2.5}
                  suffix="K+"
                />
                </div>
                <h6>Social Media Followers</h6>
              </div>
            </Card.Body>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondHeroSection;
