import React, { useContext } from "react";
import HeadingNew from "../AboutUs/HeadingNew";
import { useState } from "react";
import TabsSection from "./TabsSection";
import DayCard from "./DayCard";
import { AuthContext } from "../../context/AllContext";

function VideoTableSection() {
  const { isMobile,isTablet } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(() => {
    if (isMobile) {
      return null;
    } else {
      return 0;
    }
  });

  const tabs = ["Day one", "Day two", "Day three", "Day four"];

  const day1 = [
    "Stress-major causes and its influence on our health.",
    "Worry Transformer- worries containing regularly fears as one of two major stressors during our daily thinking. Mental technique for transforming worries into fulfilled desires.",
    "First Relaxation Exercise- strategic health benefits from relaxation in the alpha frequency. Its crucial role in neutralising stress.",
    "First Key Life Decision- Taking full responsibility for your own life.",
    "Guilt Eliminator- mental technique eliminating guilt feeling and blaming as the second greatest stressor in our daily thinking.",
    "Second Relaxation Exercise- Long Relax. Completion of the relaxation exercise content.",
    "The Awake Control- Mental technique for changing the undesired state into desired one ( fatigue, lack  of concentration, bad mood, etc.)",
    "Second Key Life Decision- Living fully in the now moment.",
    "Mirror of the Mind- Goal achievement and problem solving technique. Its rules and importance of attention and intention in this visualisation technique.",
  ];

  const day2 = [
    "Three Fingers Technique- General application for finding things, excellent communication, calming oneself, neutralising ’stage fright’, etc.",
    "Sleep Control Technique - for entering and achieving deep, normal, physiological sleep, excellent for  people with insomnia problems.",
    "To Awake Control- technique enabling you to wake up when you need it at the desired time, full of energy.",
    "Dream Control Technique- informational technique enabling you to get the information you need through a dream that same night.",
  ];

  const day3 = [
    "Discussion- Questions and answers regarding participants’ first experiences while using the techniques from the first day of SLS.",
    "Headache Control and Glove Anestesia- these techniques are used in special moments of pain, in order to neutralise pain and direct body processes towards health.",
    "Three Fingers Technique- special application for learning purposes using greater memory, concentration and understanding.",
    "Mirror of the Mind-complex usage incorporating more projects into one mental exercise.",
  ];

  const day4 = [
    "Formula for Your Functioning in Life. - Six elements for functioning in one’s life, according to deep life principles, starting with sincere desire and ending with action.",
    "Glass of Water Technique- informational technique through which your brain brings to you the information you need mostly during your awake state, unlike the Dream Control",
    "Habit Control- technique enabling you to change your limiting habits and thereby experience continuous growth. 5 life signals that self-change is necessary.",
    "Summary and guidelines -for applying the acquired knowledge and continuous practicing of techniques on your own.",
  ];

  return (
    <div className="pb-5 d-flex justify-content-center align-items-center flex-column">
    
      <div className="container">
        <h3 className={`newpageheader text-center pb-${(isMobile || isTablet)?0:5}`}>
          Day by Day Class Schedule
        </h3>

        <TabsSection
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />

        {activeTab === 0 && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            {day1.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )}
        {activeTab === 1 && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            {day2.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )}
        {activeTab === 2 && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            {day3.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )}
        {activeTab === 3 && (
          <div className="d-flex justify-content-center align-items-center flex-column gap-3">
            {day4.map((val, i) => (
              <DayCard data={val} index={i + 1} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default VideoTableSection;
