import React from 'react'
import HeadingNew from '../../../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap';
import { MindfulnessCard } from './MindfulCard';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AllContext';

function BeginnersGuidedMeditation() {
  const {isMobile} = useContext(AuthContext);

    const mindfulnessContent = [
        {
          title: "Mindfulness for Stress and Anxiety",
          img:"/img/bg/blogpages/blogpagemindful/Mindfulness for stress and anxiety.webp",
          description: "One of the popular uses of mindfulness is for managing stress and anxiety. Guided meditations focusing on breathing and body awareness can help calm the mind and reduce anxious thoughts. For example, mindfulness meditation courses like the Silva method, could be your meditation teacher, guiding you through a practice where you focus on the sensations in your body and let go of any tension you are holding."
        },
        {
          title: "Mindfulness for Sleep Improvement",
          img:"/img/bg/blogpages/blogpagemindful/Mindfulness for Sleep Improvement.webp",
          description: "Difficulty sleeping is a common issue that mindfulness can address. Meditation and sleep have always been interlinked for a direct impact. Guided mindfulness meditations for sleep often involve a body scan technique, where you focus on each part of your body, allowing it to relax before falling asleep. This mindfulness meditation technique is best for people dealing with insomnia or irregular sleep patterns. By calming the nervous system, mindfulness for sleep improvement is a great tool."
        },
        {
          title: "Mindfulness Meditation for Weight Loss",
          img:"/img/bg/blogpages/blogpagemindful/Mindfulness Meditation for Weight Loss.webp",
          description: "When you bring mindfulness into your daily life, you start to influence the subconscious mind, leading to healthier habits. Mindfulness meditation for weight loss typically focuses on mindful eating, where you are guided to fully experience each bite of food and recognize when you're full. This approach helps you break the cycle of emotional eating and encourages a healthier relationship with food."
        },
        {
          title: "Mindfulness at Work",
          img:"/img/bg/blogpages/blogpagemindful/Mindfulness at Work.webp",
          description: "It can be challenging to stay mindful in a busy work environment. However, practising mindfulness exercises at work can help improve focus, reduce work stress, and enhance your productivity. A short guided mindfulness meditation during your lunch break, for example, can help you reset and return to your tasks with a clear, refreshed mind."
        },
        {
          title: "Mindfulness for Daily Life",
          img:"/img/bg/blogpages/blogpagemindful/Mindfulness for Daily Life.webp",
          description: "Incorporating mindfulness in daily life is all about being present, no matter what you're doing. Whether you are walking, cooking, or even driving, mindfulness teaches you to pay attention to details. Guided meditations that encourage mindfulness in everyday activities help you reprogram the subconscious that helps you react calmly to stressful situations."
        }
      ];


  return (
    <div className={isMobile?"container px-4 my-5":'container my-5'}>
        <HeadingNew colored={"Guided mindfulness meditations"} />
        <HeadingNew title={"For Beginners"} small />
        <p className='robotofont text-center mt-3'>
        Different people turn to mindfulness meditation for different reasons. However, practising guided mindfulness meditation tailored to specific issues has been beneficial for many individuals providing peace and mind. Below are some guided mindfulness meditations designed to address specific issues that many beginners face:
        </p>
        <Row className='justify-content-center align-items-center gy-4'>
             {
                mindfulnessContent.map((val)=>(
                    <Col xs={12} md={6} lg={4}>
                    <MindfulnessCard description={val.description} title={val.title} img={val.img} />

            
                    </Col>
                ))
             }

        </Row>
    </div>
  )
}

export default BeginnersGuidedMeditation