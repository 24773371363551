import React from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import HeroSectionLandingOne from './One/HeroSectionLandingOne'
import Featured from '../../components/featured/Featured'
import PopularSection from './One/PopularSection'
import FirstSection from './One/FirstSection'
import ComboHero2 from '../LandingPages/ComboCourse/ComboHero2'
import { useState } from 'react'
import { reqData } from '../../utils/baseUrl'
import { useEffect } from 'react'
import ReviewsContainer from '../../components/DifferenceSection/ReviewsContainer'
import ContactSectionNew from '../payment/ContactNewSection'
import HarnessSection from './One/HarnessSection'
import { FaBrain } from "react-icons/fa";
import BenifitsCards from '../LandingPages/GlassofWaterBenifits'
import TFTFaqs from '../LandingPages/ThreeFingerTechnique/TFTFaqs'
import CourseFeatures from './One/CourseFeatures'
import EnrollmentDetailsSection from './One/EnrollmentDetailsSection'
import NewComboBanner from '../LandingPages/ComboCourse/NewComboBanner'

function ComboLiveLandingPageOne() {
    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const harnessdata=[
        {
            id:1,
            title:"Transformative Techniques",
            text:"Learn proven methods rooted in scientific research to tap into your innate healing abilities and promote wellness in yourself and others, including the powerful practice of distance healing.",
            image:'/img/spiritual-healing/3rd-sec-images/3rd-sec-img-1.png'
        },
        {
            id:2,
            title:"Empowerment Through Practice",
            text:"Discover how regular practice of Silva Method techniques can empower you to take charge of your health and well-being, fostering a sense of control and resilience, even from a distance.",
            image:'/img/spiritual-healing/3rd-sec-images/3rd-sec-img-2.png'
        },
        {
            id:3,
            title:"Enhanced Stress Management",
            text:"Explore effective stress reduction techniques that not only alleviate tension but also contribute to overall physical and mental wellness, while also being applicable in remote healing scenarios.",
            image:'/img/spiritual-healing/3rd-sec-images/3rd-sec-img-3.png'
        },
        {
            id:4,
            title:"Community Support",
            text:"Join a community of like-minded individuals on a shared journey of healing and personal growth, offering encouragement, inspiration, and camaraderie, both in person and virtually.",
            image:'/img/spiritual-healing/3rd-sec-images/3rd-sec-img-4.png'
        },
        {
            id:5,
            title:"Complementary Health Approach",
            text:"Understand the role of self-healing techniques as a complementary approach to traditional healthcare, promoting holistic well-being and balance, including the integration of distance healing practices.",
            image:'/img/spiritual-healing/3rd-sec-images/3rd-sec-img-5.png'
        },
        {
            id:6,
            title:"Spread Healing",
            text:"Gain the knowledge and skills to extend healing energy to others, fostering a sense of purpose and fulfillment in contributing to the well-being of those around you, regardless of physical distance.",
            image:'/img/spiritual-healing/3rd-sec-images/3rd-sec-img-6.png'
        }
    ];

    const benifitsData=[
        {
            id:1,
            text:'Unlock your innate ability to tap into your inner wisdom and make decisions aligned with your highest good.',
            title:'Enhanced Intuition',
            image: <img src="/img/spiritual-healing/icons/icons-01.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'40px'}}/>,
        },
        {
            id:2,
            text:'Learn proven techniques to manage stress effectively, promoting mental, emotional, and physical well-being.',
            title:'Stress Reduction',
             image: <img src="/img/spiritual-healing/icons/icons-02.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'40px'}}/>,
        },
        {
            id:3,
            text:"Cultivate emotional resilience and positivity, transforming how you navigate life's challenges.",
            title:'Emotional Health',
             image: <img src="/img/spiritual-healing/icons/icons-03.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'40px'}}/>,
        },
        {
            id:4,
            text:'Embark on a journey of self-discovery, deepening your connection to your spiritual essence and the world around you.',
            title:'Spiritual Awakening',
             image: <img src="/img/spiritual-healing/icons/icons-04.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'40px'}}/>,
        },
        {
            id:5,
            text:'Gain access to a supportive network of like-minded individuals, enhancing your learning through shared experiences.',
            title:'Community Connection',
             image: <img src="/img/spiritual-healing/icons/icons-05.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'40px'}}/>,
        },
        {
            id:6,
            text:'Benefit from personalized feedback and insights from experienced instructors, tailored to your unique path of spiritual growth.',
            title:'Personalized Guidance',
             image: <img src="/img/spiritual-healing/icons/icons-06.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'40px'}}/>,
        },
        {
            id:7,
            text:'Acquire skills for life, from meditation to energy healing, that you can apply in various aspects of your daily life for overall improvement.',
            title:'Lifelong Skills',
             image: <img src="/img/spiritual-healing/icons/icons-07.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'40px'}}/>,
        }
    ];

    const faqsData = [
        {
          id: 1,
          heading: "What Makes This Course Unique?",
          body: "Explore how the Silva Method integrates modern science with ancient wisdom to offer a unique approach to spiritual healing and personal growth.",
        },
        {
          id: 2,
          heading: "Who Can Benefit?",
          body: "Whether you're new to spirituality or seeking deeper practices, our course is designed for anyone looking to enhance their well-being and spiritual connection.",
        },
        {
            id: 3,
            heading: "How Do Online Classes Work?",
            body: "Get details on how our live and recorded sessions provide flexibility and personal interaction, ensuring a comprehensive learning experience.",
          },
          {
            id: 4,
            heading: "What Support Is Available?",
            body: "Learn about the continuous support from our community and instructors, ensuring your questions are answered and your journey is fruitful.",
          },
          {
            id: 5,
            heading: "Can I Learn At My Own Pace?",
            body: "Yes, with lifetime access to course materials, learn at a pace that suits your lifestyle and needs.",
          },
          {
            id: 6,
            heading: "How Interactive Are the Live Sessions? ",
            body: "The live sessions are designed to be highly interactive, allowing for real-time questions and answers, direct feedback from instructors, and the opportunity for live demonstrations. This format ensures that learners can actively participate and engage with the content and their peers.",
          },
          {
            id: 7,
            heading: "What If I Miss a Live Class? ",
            body: "If you miss a live class, don't worry. All sessions are recorded and made available to course participants. You can watch the recordings at your convenience, ensuring you don't miss out on the learning experience.",
          },
          {
            id: 8,
            heading: "Can I Interact with Other Students?",
            body: "Yes, our platform includes features like discussion forums and group projects, enabling you to connect, share insights, and learn from fellow participants. This community aspect enriches your learning experience and builds a network of like-minded individuals.",
          },
          {
            id: 9,
            heading: "How Do Live Sessions Enhance My Learning Experience",
            body: "Live sessions add a dynamic and personal touch to your learning journey. They offer immediate clarification on complex topics, facilitate deeper understanding through interaction, and provide a platform for real-time feedback, making the learning process more engaging and effective.",
          },
      ];

      const successData=[
        {
            image:'',
            name:'Real Transformations',
            designation:'',
            comment:'Hear from individuals who have experienced profound personal growth and healing, showcasing the life-changing impact of our course.',
        },
        {
            image:'',
            name:'Diverse Journeys',
            designation:'',
            comment:'Stories from a wide range of participants, highlighting how the Silva Method has enhanced their spiritual and emotional well-being.',
        },
        {
            image:'',
            name:'Proof of Impact',
            designation:'',
            comment:'Each testimonial serves as a testament to the effectiveness of our spiritual healing and educational approach, inspiring new learners to embark on their journey.',
        }
      ];

      const featuresData=[
        {
          id:1,
          title:'Over 280 Lessons',
          text:'Engage with a diverse curriculum covering all facets of spiritual growth and healing.'
        },
        {
          id:2,
          title:'85+ Engaging Exercises',
          text:'Practice with exercises designed for deep spiritual connection and energy healing.'
        },
        {
          id:3,
          title:'Lifetime Access',
          text:'Unlimited access to all course materials, allowing you to learn at your own pace.'
        },
        {
          id:4,
          title:'4 Live Classes Monthly',
          text:'Interact directly with experts in spirituality and healing, enhancing your learning experience.'
        },
        {
          id:5,
          title:'Renowned Experts',
          text:'Learn from seasoned instructors renowned for their expertise in spiritual healing.'
        },
        {
          id:6,
          title:'Global Community',
          text:'Join a supportive community of like-minded individuals on a similar path to enlightenment and well-being.'
        },
        {
          id:7,
          title:'Top-Rated Program',
          text:'Recognized as one of the best online spirituality courses for its comprehensive and transformative approach.'
        }
      ];

      const mettInstructorData=[
        {
          id:1,
          title:'Experienced Guides',
          text:'Our instructors are not just teachers; they are mentors who have walked the path of spiritual growth and healing themselves. Each brings a wealth of knowledge and personal experience to the table.'
        },
        {
          id:2,
          title:'Certified Professionals',
          text:"All Silva Method instructors are rigorously trained and certified, ensuring they meet high standards of expertise and teaching ability."
        },
        {
          id:3,
          title:'Diverse Specializations',
          text:": Our team includes experts in various aspects of spirituality and healing, from meditation masters to energy healing practitioners, offering a comprehensive learning experience."
        },
        {
          id:4,
          title:'Personalized Support',
          text:"Instructors are dedicated to providing personalized guidance and support, helping you navigate your spiritual journey with confidence."
        },
      ];



    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "silva-method-complete-course-with-live-interactive-program",
        });
        setLoading(false);
        if (res.error === false) {
            setCourseData(res.data);
        }
    };

    useEffect(() => {
        getCourseData()
    }, [])
    return (
        <div style={{
            background: "#f7f3fd"
        }}>
            <HomeHeader />
            <HeroSectionLandingOne />
            <Featured />
            <div className='container mt-5' style={{
                background: "#f4f5f9"
            }}>
                <FirstSection />
            </div>

            <HarnessSection data={harnessdata}/>
            <CourseFeatures data={featuresData} image={'/img/spiritual-healing/4th-sec-img.png'}/>
            <PopularSection />
            {/* <ComboHero2 data={courseData} isCurrentCoursePurchased={false} /> */}
            {/* <NewComboBanner/> */}
            <BenifitsCards data={benifitsData} title={'Benefits'}/>
            {/* <SuccessStoriesNew/> */}

            <ReviewsContainer />
            <CourseFeatures data={mettInstructorData} image={'/img/online-menifestation/instructor-img.png'} title={'Meet the Instructors'}/>
            <div className='container my-5'>
                {/* <HeadingNew title={"Frequently Asked Questions"} small start /> */}
                {/* <SilvaLifeFAQ /> */}
            {/* <RefundBanner /> */}

            <TFTFaqs data={faqsData}/>

            </div>
            {/* <EnrollmentDetailsSection/> */}
            {/* <SuccessStoriesNew title='Success Stories' data={successData}/> */}
            <ContactSectionNew image={'/img/online-menifestation/footer-img.png'}/>

            <CustomFooter />
        </div>
    )
}

export default ComboLiveLandingPageOne