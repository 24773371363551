import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import {RiNumber1,RiNumber2,RiNumber3,RiNumber4,RiNumber5,RiNumber6,RiNumber7,RiNumber8,RiNumber9,RiNumber0} from "react-icons/ri"
import {GrLinkNext} from "react-icons/gr";
import HeadingNew2 from '../booking/HeadingNew2';
import ParaComp2 from '../SilvaManifestationProgram/ParaComp2';
import { FaHourglassStart } from "react-icons/fa";

function SeminarTimeline() {
     
    return (
        <VerticalTimeline
        lineColor='rgb(216, 1, 251)'
        >
             <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                 <HeadingNew2 title={"LONG RELAXATION"} colored={"EXERCISE"} small />
                <ParaComp2
                    data={[
                        "Learn to master the state of deep relaxation. Set up your body, thoughts and mind for the experience of relaxation. It sets mental benchmarks for shorter and more effective relaxations. It opens the way to deeper Theta relaxation."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                iconClassName='circle circle1'
                icon={<FaHourglassStart color='white' />}
                
            >
                <HeadingNew2 title={"SLEEP CONTROL"} colored={"TECHNIQUE"} small />
                <ParaComp2

                    data={[
                        "You will learn to eliminate insomnia by generating a deep, physiological, healthy, uninterrupted and restful sleep."
                    ]}
                    white={false}
                />

            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                <HeadingNew2 title={"ALARM CLOCK"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "You will be amazed that you can wake up in the morning at the desired time without using alarm clocks but only the natural ability of your body."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                <HeadingNew2 title={"WAKEFULNESS CONTROL"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "Are you tired and sleepy? Are you feeling thirsty? Can't take a nap? Use this technique and in a few minutes you will eliminate fatigue and drowsiness thus changing your energy level."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                <HeadingNew2 title={"DREAM CONTROL"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "Discover how to interpret subconscious messages from dreams and how to use them to solve problems."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                <HeadingNew2 title={"HAND LEVITATION"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "A very simple relaxation technique that stimulates the production of the Theta brain frequency."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                 <HeadingNew2 title={"GLOVE ANESTHESIA"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "A spectacular technique by which you quickly eliminate pain in yourself or in others. Especially for those who have small children."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                 <HeadingNew2 title={"HEADACHE CONTROL"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "A simple but effective technique to get rid of headaches and migraines without the use of drugs, just your mind."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                 <HeadingNew2 title={"MENTAL SCREEN/MEMORY"} colored={"PEGS"} small />
                <ParaComp2
                    data={[
                        "A spectacular memorization technique that proves that you can remember information regardless of age."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                 <HeadingNew2 title={"THE THREE FINGER"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "A simple and effective technique that in borderline situations helps you quickly find the solution, thus eliminating stress."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            {/* <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='white' />}
            >
                 <HeadingNew2 title={"THE FIVE FINGERS"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "Do you want to recover? Trouble concentrating? No inspiration? Running out of ideas? With this technique, things change quickly, making self-mastery more efficient."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement> */}
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                 <HeadingNew2 title={"THE GLASS OF WATER"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "Are you confused and don't know which way to go? With this technique in 72 hours you will discover which is the best option and which decision to make without making a mistake."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                 <HeadingNew2 title={"MIND MIRROR"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "This technique was created over 50 years ago. Today you can recognize it under the popular name of the Law of Attraction. With this technique anything becomes possible! You can use it in business, in interpersonal relations, in health... Every problem has a solution!"
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                 <HeadingNew2 title={"HABITS CONTROL"} colored={"TECHNIQUE"} small />
                <ParaComp2
                    data={[
                        "If you feel confused because of the habits you have, this is the technique to use whether you want to eliminate smoking, lose weight, stop biting your nails... The results are deep and lasting."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
            
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{ background: '#d801fb', color: '#fff' }}
                icon={<FaHourglassStart color='#fff' />}
            >
                 <HeadingNew2 title={"THE FANTASTIC VOYAGE"} colored={"EXERCISE"} small />
                <ParaComp2
                    data={[
                        "With this exercise you will go beyond the limits of space and time, better understanding the micro and macrocosm."
                    ]}
                    white={false}
                />
            </VerticalTimelineElement>
       
          

           
           
        </VerticalTimeline>


    )
}

export default SeminarTimeline