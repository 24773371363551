import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function ScrollTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, [])
    return (
        <>
            <Outlet />
        </>
    )
}