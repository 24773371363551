import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Tilt from 'react-parallax-tilt';
import { useNavigate } from 'react-router-dom';
import HeadingNew from '../../../AboutUs/HeadingNew';
import ReactPlayer from 'react-player';
import { MindfulnessCard } from './MindfulCard';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AllContext';

function MindFulnessBenefits() {

    const { isMobile } = useContext(AuthContext)

    const navigate = useNavigate()

    const benefits = [
        {
            title: "Reduces Stress",
            description:
                "Mindfulness meditation helps to lower cortisol levels, the hormone associated with stress. This practice encourages peace and calm , even during challenging moments. ",
            icon: "/img/bg/bghero/icons/reduce stress.png" // Change this to your actual icon path
        },
        {
            title: "Improves Sleep Quality",
            description:
                "There’s a significant link between meditation and sleep. Regular mindfulness meditation helps quiet the mind , leading to  more restful and deeper sleep. ",
            icon: "/img/bg/bghero/icons/boost immunity.png" // Change this to your actual icon path
        },
        {
            title: "Enhances Focus and concentration",
            description:
                "By regularly practising mindfulness meditation , you train your mind to stay focused  on the present , improving concentration and reducing distractions.",
            icon: "/img/bg/bghero/icons/emotional balance.png" // Change this to your actual icon path
        },
        {
            title: "Promotes Emotional Health",
            description:
                "Mindfulness meditation with mindful self compassion encourages emotional regulation, helping you maintain peace of mind and balance during stressful situations.",
            icon: "/img/bg/bghero/icons/Supports Pain Management.png" // Change this to your actual icon path
        },
        {
            title: "Increases Self- Awareness",
            description:
                "This practice allows you to observe your thoughts and behaviours without judgement, offering insights into your mental and emotional states of mind. ",
            icon: "/img/bg/bghero/icons/cellular regeneration.png" // Change this to your actual icon path
        },
        {
            title: "Improves Memory and Cognitive function",
            description:
                "Mindfulness based cognitive therapy helps strengthen cognitive functions, making it easier to process and recall informations",
            icon: "/img/bg/bghero/icons/Improves sleep quality.png" // Change this to your actual icon path
        },
        {
            title: "Encourages  Healthy Relationships ",
            description:
                "You develop a sense of compassion and empathy, leading to better communication and more meaningful connections with others. ",
            icon: "/img/bg/bghero/icons/balance energy.png" // Change this to your actual icon path
        },
        {
            title: "Supports Physical Health",
            description:
                "Studies show that mindfulness meditation can lower blood pressure and improve immune system function, helping your body heal more effectively.",
            icon: "/img/bg/bghero/icons/Increase Mind body awareness.png" // Change this to your actual icon path
        },
        {
            title: "Fostera a calm mindset",
            description:
                "For many, meditation becomes a daily guide for staying calm, centred and grounded, even in the face of daily stressors",
            icon: "/img/bg/bghero/icons/Improves focus and clarity.png" // Change this to your actual icon path
        },
        {
            title: "Teaches Matter over Mind",
            description:
                "Practising mindfulness meditation with mindful self compassion strengthens the connection between body and mind, allowing you to maintain control over t your thoughts and emotions, which is essential in mastering matter over mind. ",
            icon: "/img/bg/bghero/icons/Emotional releases.png" // Change this to your actual icon path
        }
    ];

    return (
        <div className='container py-5'>
            <div className='d-flex justify-content-center align-items-center flex-column'>

                <HeadingNew title={"10 Benefits of "} small start />
                <HeadingNew colored={"Mindfulness Meditation"} start />


                <img src='/img/bg/blogpages/blogpagemindful/10 key benefits of mindfulness meditation_Mesa de trabajo 1 copia.webp' className='mt-3' style={{
                    borderRadius: "15px"
                }} />

                <p className='robotofont mt-4'>
                    With the rise of meditation in the western world. mindfulness meditation has become an essential practice for many people
                    seeking balance in their everyday lives.
                    As we’ve discussed, the distinction between mindfulness and mindfulness meditation, the key difference lies in how we practise them.
                    While mindfulness can be integrated in any activity, mindfulness meditation involves dedicating time to sit quietly,
                    focus and observe the mind without judgement.


                </p>
                <p className='robotofont mb-4'>

                    However, both have a common yet significant benefit of bridging the gap between the conscious and subconscious mind,
                    promoting inner peace. When you cultivate both meditation and mindfulness,
                    you experience peace and mind working together to enhance emotional well-being. For many including myself.
                    Beginning mindfulness meditation provides not only a guide to greater self-awareness but also tangible
                    improvements in daily life… from better sleep to more mindful eating. If you're new to mindfulness, what is it that draws so many to this practice,
                    and how can it benefit you?


                </p>

                <HeadingNew title={"Here are 10 key benefits of mindfulness meditation"} small />

                <Row className={isMobile ? 'justify-content-center align-items-center gy-4 mt-3 px-4' : 'container justify-content-center align-items-center gy-4 mt-3'}>
                    {
                        benefits.map((val) => (
                            <Col xs={12} md={6} lg={4}>
                                <MindfulnessCard title={val.title} description={val.description} />
                                {/* <div className="healing-card">
                                    <div className="healing-icon-container">
                                        <img src={val.icon} className='healing-card-icon' width={100} />
                                      
                                    </div>
                                    <div className="healing-card-content">
                                        <h3 className="healing-card-title">{val.title}</h3>
                                        <p className="healing-card-description">
                                            {val.description}
                                        </p>
                                    </div>
                                </div> */}
                            </Col>
                        ))
                    }


                </Row>

                <div className='mt-3'>
                    <p className='robotofont my-3'>
                        By incorporating mindfulness meditation into your daily routine , you’ll notice improvements in both your mental and physical health, creating a strong foundation for overall well-being. Here’s a 10 minute mindfulness meditation for you to start your  practice.
                    </p>

                    <div className='d-flex justify-content-center align-items-center'>
                        {/* <ReactPlayer url={"https://www.youtube.com/watch?v=IfrWaoIzxeg"} width={"100%"} height={"50vh"} /> */}
                        <div className="player-wrapper-bro">
                            <ReactPlayer
                                className="react-player-bro"
                                url={"https://www.youtube.com/watch?v=IfrWaoIzxeg"}
                                width="100%"
                                height="100%"
                                controls={true}
                                light={false}
                                playing={false}
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 1 }
                                    }
                                }}
                            />
                        </div>

                    </div>

                </div>



            </div>

        </div>
    )
}

export default MindFulnessBenefits