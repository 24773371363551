import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import { AuthContext } from '../../../context/AllContext'
import { useContext } from 'react'
import { BsFillFileEarmarkPersonFill, BsPhoneFill } from "react-icons/bs";
import { useState } from 'react'
import { AiOutlineMail } from "react-icons/ai";
import toast from 'react-hot-toast'
import { requestData } from '../../../utils/baseUrl'

function FormSection({title}) {

    const { isMobile } = useContext(AuthContext)

    const [values, setValues] = useState({
        name: "",
        email: "",
    })

    const [loading, setLoading] = useState(false);


    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!values.email) {
            toast.error("Enter a valid email");
            return
        }

        if (!values.name) {
            toast.error("Enter a valid name");
            return
        }


        const matchedEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values.email);
        if (!matchedEmail) {
            toast.error("Enter a valid email");
            return
        }



        setLoading(true)
        const res = await requestData("selfHypnosisForm", "POST", values);
        setLoading(false)

        // console.log(res)

        if (res && res.error === false) {
            toast.success(res.messages)
            setValues({
                name: "",
                email: "",

            })
        } else {
            toast.error(res.messages)
        }
    }

    return (
        <div className='container' style={{
            paddingTop: 70,
            paddingBottom:25
        }}>
            <Row>

                <Col xs={12} md={6}>
                    <img src='https://silvamethod.com/img/contactimgnew.png' />
                </Col>
                <Col xs={12} md={6}>
                    <div className={`px-${isMobile?"2":""}`}>
                    <HeadingNew title={title || "Submit your details to receive a complimentary self-hypnosis meditation "} small />
                    </div>
                    <p className='my-2' style={{
                        color: "black",
                        textAlign: "center",
                        fontWeight: "500"
                    }}>
                        Learn from a certified Silva Method instructor
                    </p>



                    {/*  */}


                    <div className='d-flex justify-content-center align-items-center flex-wrap' style={{ width: "95%", marginLeft: "15px" }}>
                        <div class="inputArea inp2">
                            <span>
                                <BsFillFileEarmarkPersonFill color='#9d9d9d' />
                            </span>
                            <input
                                onChange={handleChange}
                                value={values.name}
                                type="text" name="name"
                                placeholder="Your Name"
                            />
                        </div>
                        <div class="inputArea inp2">
                            <span>
                                <AiOutlineMail color='#9d9d9d' />
                            </span>
                            <input
                                onChange={handleChange}
                                value={values.email}
                                type="email" id="1-email" inputmode="email" name="email" class="auth0-lock-input"
                                placeholder="Your Email" autocomplete="off" autocapitalize="off" aria-label="Email"
                                aria-invalid="false" />
                        </div>




                        <div className="d-flex justify-content-center-align-items-center flex-column">


                            <div style={{ margin: "0 auto" }}>
                                <button className='styledbtn23new' disabled={loading} onClick={handleSubmit}>
                                    {
                                        loading ? (
                                            <Spinner animation="border" variant="light" />
                                        ) : "Submit"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default FormSection