import React from 'react'
import { BsFillPatchCheckFill } from "react-icons/bs";
import HeadingNew from '../AboutUs/HeadingNew';
import { useNavigate } from 'react-router-dom';

function MemberShipHowItWorks() {
    const navigate = useNavigate()
    const listyles = {
        fontWeight: "normal",
        color: "#000",
        marginBottom: "15px"
      }
    return (
        <div className='container' style={{ marginBottom: "30px" }}>
            <div className="row justify-content-center align-items-center p-3">
                <div className="col-sm-10 col-md-10">
                    {/* <h2 className='text-center mb-4'>Why Get a Silva Method Membership</h2> */}
                    <HeadingNew title={"Why People are determined to choose"} colored={"The Silva Method Courses"}  />
                    {/* <h3 className='text-center'>Experience The Evolution Of Online Learning</h3> */}
                </div>
                <div className="col-sm-10 col-md-8">
                    <img src="https://storyblok-cdn.The Silva Method.com/f/60579/1594x883/c981ac783d/mvcom_hp_aa_devices.png?trans?quality=40&auto=webp&dpr=1&width=800" alt="" />
                </div>
                <div className="col-sm-10 col-md-7">
                    {/* <p style={{fontSize:"1.1rem"}}>
                        The The Silva Method Quests online learning platform combines the power of daily microlearning and community to transform you in ways traditional education never could.
                    </p>
                    <p style={{fontSize:"1.1rem"}}>
                        You’ll experience life-changing personal growth in just 20 minutes a day. And enjoy support, accountability, and friendship from thousands of Quest students worldwide.
                    </p> */}
                    <div className="bulletdiv">
                        <ul className="text-start bulletli">
                            <li className="one" style={listyles}>
                                <span><BsFillPatchCheckFill color="#801cb6" /></span> Lifetime access to Silva Method courses, allowing you to continue your journey of personal development at your own peace.
                            </li>
                            <li className="one" style={listyles}>
                                <span><BsFillPatchCheckFill color="#801cb6" /></span> Meditation tips and techniques and other Mind development training to be a pro at practicing Silva Method Exercises.
                            </li>
                            <li className="one" style={listyles}>
                                <span><BsFillPatchCheckFill color="#801cb6" /></span> Expert Guidance from Silva Method Instructors provides the knowledge and support you need to achieve your goals.
                            </li>
                            <li className="one" style={listyles}>
                                <span><BsFillPatchCheckFill color="#801cb6" /></span> Access to Exclusive Resources including Silva Method Audiobooks, guided meditation techniques, and Silva Mind control meditation .
                            </li>
                            <li className="one" style={listyles}>
                                <span><BsFillPatchCheckFill color="#801cb6" /></span> Join a vibrant community of like-minded individuals, where you can connect, share experiences, and learn from one another.
                            </li>
                        </ul>
                    </div>
                   <div className="d-flex justify-content-center align-items-center">
                   <button className="primary_btn2"  onClick={()=>navigate("/store/course/combo-plan")}>
                        Get Access to All Courses
                    </button>
                   </div>
                </div>

              
                {/* <div className="col-6 d-flex justify-content-center">
                    <button className='primary_btn2'>
                        LEARN MORE
                    </button>
                </div> */}
            </div>

        </div>
    )
}

export default MemberShipHowItWorks