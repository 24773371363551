import React from 'react'
import { FaSeedling, FaTree, FaUtensils } from 'react-icons/fa';
import StressIconCard from './StressIconCard';

function StressRelieveExercise() {

    const stressReliefActivities = [
        {
            title: "Gardening",
            description: "Ever felt the warm soil between your fingers or the joy of watching something you planted start to grow? Gardening isn’t just about making your backyard look pretty; it’s about reconnecting with the earth and finding peace in nurturing life.",
            icon: <img src='/img/stress/icons/icon_gardening.png' width={60} />
        },
        {
            title: "Art and Craft Projects",
            description: "Creative activities like painting, knitting, or drawing offer a fun and fulfilling way to relieve stress fast. When you're focused on a craft, you’re less likely to ruminate on worries, and the act of creating something can be incredibly satisfying. It’s not about creating a masterpiece; it’s about giving your mind a chance to wander away from your thoughts.",
            icon:  <img src='/img/stress/icons/icon_art and crafts projects.png' width={60} />
        },
        {
            title: "Walking in Nature",
            description: "When the walls of your mind start closing in, step outside and let nature be your sanctuary. Walk among trees, feel the breeze on your face, and listen to the birds. Breathe deeply and soak in the beauty around you.",
            icon:  <img src='/img/stress/icons/icon_walking in nature.png' width={60} />
        },
        {
            title: "Cooking or Baking",
            description: "Cooking isn’t just about feeding your stomach; it’s a nourishing experience for your heart and mind. Slicing vegetables, measuring out ingredients, and savoring the aroma of your favorite meal bubbling away—it all brings a sense of focus and calm. Plus, there’s nothing quite like the reward of enjoying a meal you’ve lovingly prepared, especially with those you care about.",
            icon:  <img src='/img/stress/icons/icon_cooking or baking.png' width={60} />
        },
        {
            title: "Exercise",
            description: "Exercise doesn’t have to mean sweating it out in a gym if that’s not your thing. It can be as simple as dancing in your living room, going for a run, or doing some gentle yoga. Moving your body is one of the fastest ways to shake off stress and feel alive again.",
            icon:  <img src='/img/stress/icons/icon_exercise.png' width={60} />
        },
        {
            title: "Board Games and Puzzles",
            description: "Gathering around a table with friends or family for a board game can bring shared smiles, friendly competition, and joy. Even solo puzzles can be a meditative escape, giving your brain something rewarding to focus on that has nothing to do with your to-do list.",
            icon:  <img src='/img/stress/icons/icon_board games and puzzle.png' width={60} />
        },
        {
            title: "Reading a Book",
            description: "There’s something magical about getting lost in a story. When reality feels heavy, a book can whisk you away to another world or inspire you with new ways to look at your own. Reading is a gentle way to rest your mind.",
            icon:  <img src='/img/stress/icons/icon_reading a book.png' width={60} />
        },
        {
            title: "Listening to Music",
            description: "Music has a powerful effect on emotions and can help you relieve stress fast. Calming or uplifting tunes can instantly change your mood and reduce anxiety. Curate playlists for relaxation or energizing motivation.",
            icon:  <img src='/img/stress/icons/icon_board games and puzzle copy 2.png' width={60} />
        },
        {
            title: "Mindful Exercise Practices",
            description: "Practices like meditation, yoga, and tai chi align your body, mind, & soul. The slow, purposeful movements paired with deep breaths create a sense of calm that’s hard to find elsewhere.",
            icon:  <img src='/img/stress/icons/icon_mindful exercise practices.png' width={60} />
        },
        {
            title: "Volunteering",
            description: "Sometimes the best way to cope with your stress is to help someone else. Volunteering gives you a chance to step outside your worries and do something meaningful. The sense of purpose and connection can be incredibly fulfilling.",
            icon:  <img src='/img/stress/icons/icon_volunteering.png' width={60} />
        }
    ];
    return (
        <div className='my-5'>
            <h2 className='heading-large text-black'>
                Stress Relief Activities for Adults
            </h2>
            <p className='robotofont'>
                Stress is an unwanted guest of our life, whom we cannot ignore even if we want to. That’s why learning how to cope with stress isn’t just important—it’s essential.  Here’s how to incorporate simple yet powerful stress relief activities for adults to help you breathe a bit easier and truly feel like yourself again.
            </p>
            <section class="resources-stressed">
                <div class="resources-stressed-grid">
                    {
                        stressReliefActivities.map((val) => (
                            <StressIconCard title={val.title}
                                description={val.description}
                                icon={val.icon}
                            />
                        ))

                    }
                </div>
            </section>

            <p className='robotofont mt-3'>
            Stress doesn’t have to rule your life. By embracing these activities, you give yourself permission to pause, breathe, and rediscover the simple joys that make life beautiful. Remember, it's okay to take things one step at a time, and learning how to cope with stress is a journey, not a destination. Take care of yourself—you deserve it.


            </p>

        </div>
    )
}

export default StressRelieveExercise