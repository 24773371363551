import React, { useContext, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { AiFillLock } from "react-icons/ai";
import "../Login/LoginArea/login2.css";
import { AuthContext } from "../../context/AllContext";
import { toast } from "react-hot-toast";
import { requestData } from "../../utils/baseUrl";
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";

function ResetPasswordForm() {
  const { isMobile, isTablet } = useContext(AuthContext);

  const { token } = useParams();
  const navigate= useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState("");

  const handleSubmit = async () => {
    if (!password || !confirmPassword) {
      toast.error("Please enter the required fields");
      return;
    } else if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else {
      // console.log(password,confirmPassword);
        //  console.log(token, password,confirmPassword,"emailtoken");
      setLoading(true);
      const res = await requestData("resetPassword", "POST", {
        token: token,
        password: password,
        confirm_password: confirmPassword,
      });
      setLoading(false);
      //   console.log(res, "reset Passwords response");
      if (res.error === false) {
          toast.success("Password reset successfully");
          navigate("/login")
      }
      else{
        toast.error(res.messages);
      }
    }
  };
  return (
    <div className="d-flex justify-content-center align-items-center mt-4">
      <Card
        style={{
          borderRadius: "10px",
          width: isMobile ? "100%" : isTablet ? "70%" : "50%",
        }}
        className=""
      >
        <Card.Body>
          <label>Password*</label>
          <div class="inputArea inp2">
            <span>
              <AiFillLock color="#9d9d9d" />
            </span>
            <input
              // onChange={handleChange}
              // value={values.name}
              type="password"
              name="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <label>Confirm Password*</label>

          <div class="inputArea inp2">
            <span>
              <AiFillLock color="#9d9d9d" />
            </span>
            <input
              // onChange={handleChange}
              // value={values.email}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              id="1-email"
              inputmode="email"
              name="email"
              class="auth0-lock-input"
              placeholder="Enter Confirm Password"
              autocomplete="off"
              autocapitalize="off"
              aria-label="Email"
              aria-invalid="false"
            />
          </div>

          <div></div>
          <button className="primary_btn3 marginsubmit" onClick={handleSubmit}>
            {loading ? (
              <SyncLoader
                color={"white"}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              "Submit"
            )}
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ResetPasswordForm;
