import React from 'react'
import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from '../../context/AllContext';

function TitleWithBadge({badge,title,white=false}) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1440px)' });
    const isSmallDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
    const {isMobile} = useContext(AuthContext);
    const isSmallMobile = useMediaQuery({maxWidth:319})

  return (
    <div className='titlebadge wrapperhighlight'>
          {
            badge && (
              <span className='highlightedspan'>
              {
                  badge
              }
          </span>
            )
          }
            <h2
                style={{
                    fontWeight: "800",
                    fontSize: isDesktopOrLaptop ? "80px" : isSmallDesktop ? "40px" : isTablet ? "30px" : "50px",
                    margin: (isSmallMobile || isMobile)?"0 0 0 0px":"0 0 0 20px",
                    textAlign: (isMobile)?"start":"center",
                    color:white?"#fff":"#000"
                }}
            >
               {
                title
               }
            </h2>
    </div>
  )
}

export default TitleWithBadge