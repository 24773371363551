import React, { useContext } from 'react'
import silvabook from "../../images/newimgs/img3.avif"
import { AuthContext } from '../../context/AllContext';
import { Link } from 'react-router-dom';
import HeadingNew from '../../pages/AboutUs/HeadingNew';
function BookContent() {
    const { isDesktopOrLaptop,
        isTabletOrMobile } = useContext(AuthContext);
    return (
        <div className='container'>
            <div className="container mt-5">
                <div className='d-flex justify-content-center align-items-center mt-5 mb-5'>
                    {/* <div style={{ maxWidth: isDesktopOrLaptop ? "60%" : "100%" }} >
                                               <HeadingNew title={"Silva Method Courses: Unleash the Best Version of You"} />

                    </div> */}
                        <HeadingNew title={"Silva Method Courses: Unleash the Best Version of You"} />

                </div>
                <div className="row justify-content-center gap-3">
                    <div className="col-sm-12 col-md-5">
                        <img src={silvabook} alt="silva-mind-control-method" style={{ width: "100%", height: "300px",borderRadius:"10px" }} />
                    </div>
                    <div className="col-sm-12 col-md-6 text-start">
                        <p style={{ fontSize: "1.6rem", color: "black", fontWeight: "500" }}>
                          Get Access to Never Ending Wisdom of Silva through One Membership Only
                        </p>
                        {/* <p style={{ fontSize: "1.3rem", color: "black", fontWeight: "500"}}>
                            Top 10 Book of Los Angeles Times and Los Angeles Daily News
                        </p> */}
                        <p style={{ fontSize: "1.2rem", color: "black"}}>
                        Embrace growth and success with Silva’s transformative courses, empowering you to stand out from the crowd.
                         Upgrade your skills, level up your businesses, and foster meaningful relationships. Expand your horizons, achieve your dreams, and live a fulfilling life, through Silva Method Courses, in just a few clicks on our online platform.
                        </p>
                        <Link to='/silva-method-courses'>
                        <button className='primary_btn' style={{borderRadius:"40px",marginBottom:"20px"}}>
                           Enroll Now
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookContent