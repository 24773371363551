import React, { useContext } from 'react'
import { Card } from 'react-bootstrap'
import { AuthContext } from '../../context/AllContext';

function NewReviewsCard({ value }) {
    const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } = useContext(AuthContext);

    return (
        <Card style={{ width: isBigScreen ? '96%' : "98%", height: isBigScreen ? "300px" : isDesktopOrLaptop ? "250px" : isTablet ? "230px" : "240px", borderRadius: "10px" }} className='customcard222'>
            <Card.Body className={isBigScreen ? "d-flex justify-content-start align-items-center gap-4" : "d-flex justify-content-start align-items-center"}>
                {
                    isBigScreen && (
                        <div style={{ paddingRight: isBigScreen ? "20px" : "10px", borderRight: "5px solid #801cb6", marginRight: isBigScreen ? "0px" : "10px" }}>
                            <Card.Img
                                src={value.image}
                                style={{ width: isBigScreen ? "240px" : "180px", height: isBigScreen ? "230px" : "160px", objectFit: "cover", borderRadius: "10px" }}
                            />
                        </div>
                    )
                }
                <div className='text-start mt-3'>

                    <div>
                        <Card.Title style={{ color: "black", fontWeight: "600", fontSize: isBigScreen ? "1.5rem" : isDesktopOrLaptop ? "1rem" : isTablet ? "0.9rem" : "0.7rem", textAlign: isBigScreen ? "start" : "start" }}>
                            {
                                value.comment
                            }
                        </Card.Title>
                    </div>
                    <div className={isBigScreen ? 'd-flex' : "d-flex justify-content-start align-items-center align-content-center gap-3"}>
                        {
                            !isBigScreen && (
                                <div>
                                    <Card.Img
                                        src={value.image}
                                        style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                                    />
                                </div>
                            )
                        }
                        <div style={{ marginBottom: isBigScreen ? "0px" : "20px" }}>
                            <Card.Subtitle style={{
                                color: "black", fontWeight: "bold", marginBottom: "10px", marginTop: "30px", fontSize: isBigScreen ? "1.2rem" :
                                    isDesktopOrLaptop ? "1rem" : isTablet ? "0.9rem" : "0.7rem"
                            }}>
                                {value.name}
                            </Card.Subtitle>
                            <Card.Subtitle style={{
                                color: "black", fontSize: isBigScreen ? "1.2rem" :
                                    isDesktopOrLaptop ? "1rem" : isTablet ? "0.9rem" : "0.7rem"
                            }}>
                                {
                                    value.designation
                                }
                            </Card.Subtitle>
                        </div>
                    </div>


                </div>


            </Card.Body>
        </Card>
    )
}

export default NewReviewsCard