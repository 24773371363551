import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import forbes from "../../images/forbes.png";
import nytimes from "../../images/nytimes.png";
import usatoday from "../../images/usatoday.png";
import washington from "../../images/washingtonpost.png"
import HeadingNew from '../AboutUs/HeadingNew';

function Sponsors() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })

    const imgstyles = {
        width: "150px",
        height: "40px",
        objectFit: "contain"
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };
    return (
        <div className='container' style={{ width: isDesktopOrLaptop ? "50%" : "98%" }}>
            <div className="row justify-content-center align-items-center align-content-center mb-4">
                <div className="col-sm-4 col-md-8">
                    {/* <h3 className='text-center'>Trusted by governments, Fortune 500 and companies that focus on human development everywhere</h3> */}
                    <HeadingNew title={"Trusted by governments, Fortune 500 companies  that focus on human development everywhere"} small />
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center gap-4 sponsorslider">
                <div>
                    <img
                        style={imgstyles}
                        src={forbes}
                    />
                </div>


                <div>
                    <img
                        style={imgstyles}
                        src={nytimes}

                    />
                </div>

                <div>
                    <img
                        style={imgstyles}
                        src={usatoday}
                    />
                </div>

                <div>
                    <img
                        style={imgstyles}
                        src={washington}
                    />
                </div>



            </div>
        </div>
    )
}

export default Sponsors