import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { AuthContext } from '../../context/AllContext';
import { requestData, requestData2 } from '../../utils/baseUrl';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Timer from './Timer';

function PaymentSuccess() {
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();

  const location = useLocation()

  const { isUserLoggedIn, userData } = useContext(AuthContext)




  useEffect(() => {
    if (userData) {
      const data = location.search.substring(1).split("&");
      const planId = data[0].split("=")[1]
      const price = data[1].split("=")[1]
      const coupon = data[2].split("=")[1]
      const token = data[3].split("=")[1];


      const dataToSend = {}
      if (planId) {
        // console.log(atob(planId))
        dataToSend.course_id = atob(planId)
      }
      if (price) {
        dataToSend.price = atob(price)
      }

      if (coupon) {
        dataToSend.coupon_code = atob(coupon)
      }
      if (token) {
        dataToSend.token = token
      }
      if (isUserLoggedIn) {
        dataToSend.student_id = userData.student_id
      }

      // console.log(dataToSend)
      paymentsuccess(dataToSend)
    } else {
      const data = location.search.substring(1)?.split("&");
      const planId = data[0]?.split("=")[1]
      const price = data[1]?.split("=")[1]
      const coupon = data[2]?.split("=")[1]
      const token = data[3]?.split("=")[1];


      const dataToSend = {}
      if (planId) {
        // console.log(atob(planId))
        dataToSend.course_id = atob(planId)
      }
      if (price) {
        dataToSend.price = atob(price)
      }

      if (coupon) {
        dataToSend.coupon_code = atob(coupon)
      }
      if (token) {
        dataToSend.token = token
      }


      // console.log(dataToSend)
      paymentsuccess(dataToSend)
    }

  }, [isUserLoggedIn])

  const paymentsuccess = async (data) => {
    setLoading(true)
    const res = await requestData("payPalSuccess", "POST", data);

    setLoading(false)

    // console.log(res,"response")
    if (res && res.error === false) {
      const res2 = await requestData2("getStudentProfile", "POST", {});
      swalfire()
    } else {
      swalfire2()
    }
  }


  const swalfire = async function () {
  
    if (userData) {
      Swal.fire({
        position: 'top-center',
        icon: 'success',
        html: userData?`<b>Payment Successful, Please check your email for login credentials</b>
        <b>If you find any dificulty during login please contact'
  <a href="mailto:digital@digital@silvamethod.com">digital@digital@silvamethod.com</a>`:`<b>Payment Successful`,
        showConfirmButton: true,
        allowOutsideClick:false,
        allowEscapeKey:false,
        allowEnterKey:false
      }).then((result) => {
        if (result.isConfirmed) {
          if(userData){
            navigate("/today")
          }else{
            navigate("/")
          }
        }
      })
    }

  }


  const swalfire2 = function () {
    Swal.fire({
      position: 'top-center',
      icon: 'error',
      title: 'Payment Failed',
    })

  }



  return (
    <div className='d-flex justify-content-center align-items-center'>
      {
        isUserLoggedIn && (
          <Timer />
        )
      }
      {
        loading && (
          <Spinner animation="border" variant="dark" />
        )
      }
    </div>
  )
}

export default PaymentSuccess