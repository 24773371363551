import React from 'react'
import {ShareSocial} from 'react-share-social' 
import { REACT_BASE_URL } from '../../utils/baseUrl';

function SocialShareButtons({url}) {

    const style = {
        root: {
          background: 'transparent',
          padding:"0",
          margin:"0"
        },
      };
  return (
    <div className='' style={{marginBottom:"100px"}}>
     {/* <h4 className='white-color'>Share: </h4> */}
     <ShareSocial 
     url ={window.location.href}
     socialTypes={['facebook','twitter','reddit','linkedin','telegram']}
     style={style}
   />
    </div>
  )
}

export default SocialShareButtons