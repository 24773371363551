import React from "react";

function Badge({ text="The Silva", backgroundColor="#f1c40f", width="100px", height="30px", fontSize, fontWeight="500",color="black" }) {
  return (
    <>
      <div
        style={{
          width: width,
          height:height,
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: color,
          fontSize: fontSize,
          fontWeight:fontWeight,
          background: backgroundColor
        }}
      >
        {text}
      </div>
    </>
  );
}

export default Badge;
