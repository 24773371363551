import React, { useContext, useEffect, useState } from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from "react-slick";
import MoonLoader from "react-spinners/MoonLoader";
import SliderCard from "./SliderCard";
import HeadingNew from "../../AboutUs/HeadingNew";
import { reqData } from "../../../utils/baseUrl";
import { AuthContext } from "../../../context/AllContext";
import "./slider.css"
import { Link } from "react-router-dom";

function TestimonialSliderCombo() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useContext(AuthContext);

  const settings = {
    className: "center",
    infinite: true,
    arrows: false,
    cssEase: "linear",
    centerMode: false,
    slidesToShow: isMobile ? 1 : 2,

    speed: 500,

    dots: true,
    autoplay: true
  };

  const getReviews = async () => {
    setLoading(true);
    const res = await reqData("/testimonialList", "POST", {
      start_index: 0,
      no_of_records: 100,
    });

    // console.log(res,'response...')
    if (res.error === false) {
      setReviews(res?.data?.filter((el, i) => i !== 8));
    }
    setLoading(false);
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <div style={{
      marginTop:isMobile?"60px":"120px",
    }}>
      <HeadingNew
        colored={"About Us"}
        title={"See what others have to say"}
      />
      {loading ? (
        <div className="d-flex flex-column w-100 align-items-center justify-content-center p-5">
          <MoonLoader loading={loading} size={100} color="#250f74" />
        </div>
      ) : (
        <div className="reviews-carousel mt-2 mb-5">
          <Slider {...settings}>
            {reviews.map((review, i) => (
              <div key={i}>
                <SliderCard data={review} />
              </div>
            ))}
          </Slider>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center" style={{
        marginBottom:isMobile?"50px":"100px"
      }}>
      <Link to={"/testimonials"}>
        <button className="cssbuttons-io-button3">View all</button>
      </Link>
      </div>
    </div>
  );
}

export default TestimonialSliderCombo;
