import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Alert } from 'react-bootstrap'
import Countdown from 'react-countdown'

function TimerComp({ datanew,setPricePlan2 }) {





   const [remaining,setRemaining] = useState(null)
   const [isValidOffer,setIsValidOffer] = useState(false);


    





    const [countdownKey, setCountdownKey] = useState(0);

    const handleCountdownComplete = () => {
        setCountdownKey(countdownKey + 1);
    };
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
       
            // Render a countdown
            return (
                <div className='d-flex justify-content-start align-items-center gap-3 paymentcardsss'>
                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                        <button className='newbtn2'>
                            {days < 10 ? "0 " + days : days}
                        </button>
                        <h6 className='text-center text-dark' style={{fontSize:"12px"}}>Days</h6>
                    </div>

                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                        <button className='newbtn2'>
                            {hours < 10 ? "0 " + hours : hours}
                        </button>
                        <h6 className='text-center text-dark' style={{fontSize:"12px"}}>Hours</h6>
                    </div>

                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                        <button className='newbtn2'>
                            {minutes < 10 ? "0 " + minutes : minutes}
                        </button>
                        <h6 className='text-center text-dark' style={{fontSize:"12px"}}>Minutes</h6>
                    </div>


                    <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                        <button className='newbtn2'>
                            {seconds < 10 ? "0 " + seconds : seconds}
                        </button>
                        <h6 className='text-center text-dark' style={{fontSize:"12px"}}>Seconds</h6>
                    </div>
                </div>
            );
       
    };
    useEffect(() => {

        // targetDate.current.setTime(targetDate.current.getTime() + 98310000);
        // setShouldStartFromCurrent(targetDate.current.getTime() < currentDate.current.getTime())

        if(datanew.offer_end){
            const date = moment(datanew.offer_end);
            const milliseconds = date.valueOf();
            const diff = milliseconds-Date.now()
            if(diff>0){
                setRemaining(Date.now()+diff)
             setIsValidOffer(true)

            }else{
              setRemaining(Date.now() + 98310000)
             setIsValidOffer(false)
            }
        }else{
            setRemaining(Date.now() + 98310000)
            setIsValidOffer(false)

        }
        setPricePlan2(Math.floor((datanew?.price) - (((datanew?.discount_percentage || 0) * parseInt(datanew?.price)) / 100)))
        // if (datanew.offer_end) {
        //     var today = moment();
        //     var expirationDate = moment("2023-07-19");
        //     var duration = moment.duration(expirationDate.diff(today));

        //     var durationInMilliseconds = duration.asMilliseconds();

        //     // console.log("Remaining" + durationInMilliseconds);
        //     setTimeRemaining(durationInMilliseconds)

        // }
    }, [datanew])




    if(!isValidOffer){
        return null
    }
    return (

        <Alert className='d-flex justify-content-center align-items-center flex-column' style={{
            background: "#f9f9fb",
            color: "black",
            border: "none",
            borderRadius: "15px",
            width: "100%",
            padding:"0.5rem"

        }}>
            <h5 className='text-dark' style={{fontSize: "0.8rem"}}>Remaining for this <span style={{ color: "rgb(128, 28, 182)", fontWeight: "600", fontSize: "0.8rem" }}>Discount </span></h5>


           {
            remaining && (
                <Countdown autoStart
                date={remaining}
                key={countdownKey}
                onComplete={handleCountdownComplete}
                renderer={renderer}
            />
            )
           }
        </Alert>

    )
}

export default TimerComp