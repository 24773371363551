import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaThumbtack } from 'react-icons/fa';
import { AuthContext } from '../../../../context/AllContext';
import HeadingNew from '../../../AboutUs/HeadingNew';

const MindfulnessMeditationComponent = () => {
    const navigate = useNavigate();
    const { isMobile } = useContext(AuthContext);

    return (
        <div className={isMobile?"my-3":"my-5"} style={{
            backgroundImage: 'linear-gradient(to right, #5B21B6, #3730A3)', // Similar gradient
            paddingTop: '5rem',
            paddingBottom: '3rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            fontFamily: 'sans-serif',
        }}>
            <div className='container'>
                <Row className="align-items-center">
                    <Col md={5}>
                        <div className='d-flex justify-content-start'>
                            <img
                                src="https://res.cloudinary.com/dzkwltgyd/image/upload/v1729757075/glif-run-outputs/idgqexnk3yfhrnm1il4v.jpg" // Placeholder image, replace as needed
                                alt="Mindfulness Meditation"
                                className="img-fluid"
                                style={{
                                    width: isMobile?"100%":"90%",
                                    borderRadius: "20px",
                                    marginBottom:isMobile?"20px":"0px",
                                    border:"2px solid white"
                                }}
                            />
                        </div>
                    </Col>

                    <Col md={7}>
                       
                        <HeadingNew title={"Mindfulness Meditation"} start white />
                        <HeadingNew title={" A Brief Introduction!"} small start white />

                        <p className="robotofont mb-2 mt-4 white-color" style={{
                            fontSize: isMobile ? "1rem" : "1.1rem"
                        }}>
                          

                          Meditation and mindfulness are powerful practices that help individuals cultivate present-moment awareness,
                        enhancing both mental clarity and emotional balance. Mindfulness meditation is a great exercise to begin your mindfulness journey.
                        In a world that moves at lightning speed, it’s easy to feel like your mind is constantly racing
                        . juggling thoughts, worries, and endless to-dos. This is where mindfulness meditation steps in,
                        offering a gentle pause amidst the chaos. It’s not just about sitting still. It's about learning to truly be in the moment,
                         finding peace in the present rather than getting lost in a whirlwind of thoughts.




                        </p>

                        <p className="robotofont mb-2 white-color" style={{
                            fontSize: isMobile ? "1rem" : "1.1rem"
                        }}>
                           
                           Additionally, Mindfulness meditation invites you to focus on your breath, notice your thoughts,
                            and let them pass without clinging to them. This simple act of being aware can lead to a shift 
                            in your mindset and transform challenging states of mind. Not only this, mindfulness creates a harmony of peace and mind, 
                            reducing stress and anxiety. It also allows you to tap into your subconscious mind,
                             helping to reveal patterns of thoughts that influence your emotions and behaviours.


                        </p>

                        <p className="robotofont mb-4 white-color" style={{
                            fontSize: isMobile ? "1rem" : "1.1rem"
                        }}>
                           But there’s more to it than just a peaceful mind. Meditation and mindfulness go hand in hand…offering benefits
                        that extend into every corner of life, including improved focus, reduced stress, and even better sleep. By making time for mindfulness meditation
                        you’re not just clearing your mind. you’re creating room for rest and rejuvenation, which can have a profound impact on how you face each day.


                        </p>
                        <p className="robotofont mb-4 white-color" style={{
                            fontSize: isMobile ? "1rem" : "1.1rem"
                        }}>
                          Think of it like this , just as you recharge your phone, mindfulness meditation is how you recharge your mind and spirit.
                           It’s a way to tap into that deep sense of peace and mind  that many of us yearn for but rarely find in our busy lives. 
                           Moreover, practising mindfulness daily, you can reprogram your subconscious mind and open up new avenues of personal growth
                            and help in achieving a deeper state of mental clarity. So why not give yourself the gift of a few quiet moments each day?
                             You’ll get answers to all the “what’s and why’s” of mindfulness exercises as we dive deep into the mindfulness meditation basics.


                        </p>
                    </Col>
                </Row>

                {/* Key Takeaway Section */}
                <Row className="justify-content-center mt-5">
                    <Col xs={12} md={12} lg={12}>
                        <div className="key-takeaway">
                            <FaThumbtack className="pin-icon" />
                            <h2 className="key-takeaway-heading">Key Takeaway</h2>
                            <p className="key-takeaway-text robotofont">
                                Mindfulness is all about focusing on the present moment, being aware of your thoughts,
                                 and accepting them without any judgment, rather than dwelling in the past or future.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default MindfulnessMeditationComponent;
