import React from 'react';
import HeadingNew from '../../../AboutUs/HeadingNew';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AllContext';

const MindFulWays = () => {
  const {isMobile} = useContext(AuthContext);

  const practices = [
    {
      title: "Mindful Eating",
      description: "Slow down and try to truly experience your food as you eat. Pay attention to the textures, flavours and smells. This small practice can transform a rushed meal into a calming ritual.",
      imageSize: "400x300"
    },
    {
      title: "Mindful Breathing",
      description: "Throughout the day, take a moment to focus on your breath. Even one minute of mindful breathing can help you centre your thoughts and reduce stress and cultivate peace and mind.",
      imageSize: "400x300"
    },
    {
      title: "Mindful Walking",
      description: "Whether you are walking to work or taking a stroll in the park, focus on the sensations of your feet hitting and your breath as you move. This practice is excellent for staying present and grounded.",
      imageSize: "400x300"
    },
    {
      title: "Mindful Listening",
      description: "In conversations, instead of thinking about what you'll say next, focus entirely on the other person's words. Mindful listening strengthens relationships and enhances communications.",
      imageSize: "400x300"
    },
    {
      title: "Mindful Stretching",
      description: "Incorporate small stretches into your daily routine and pay attention to how your body feels. Mindful stretching can help release tension and improve focus, especially after long periods of sitting.",
      imageSize: "400x300"
    },
    {
      title: "Mindful Technology Use",
      description: "Be conscious of how often you check your phone or email or social media. Set boundaries for your technology use and practice being fully present when you are away from the screens.",
      imageSize: "400x300"
    },
    {
      title: "Mindful Gratitude",
      description: "At the end of each day, reflect on a few things you are grateful for. This simple practice can shift your mindset, promoting a sense of peace and contentment.",
      imageSize: "400x300"
    },
    {
      title:"Mindful Self Compassion",
      description:"Practising mindful self compassion helps reduce feelings of self criticism, replacing them with understanding and patience towards your emotional experiences. So learn to treat yourself as you would treat a dear friend. "
    }

  ];

  return (
    <div className="container">
      <div className="header">
        <HeadingNew title={"8 Ways to Be"} colored={"Mindful"} />
        <p className="robotofont mt-3">
        You don't need to carve out large chunks of time , there are simple ways to practise mindfulness in daily life or throughout the day. By paying closer attention to the moment, and cultivating mindful self compassion, you can bring a sense of calm and awareness into everything you do. By paying closer attention to the moment, and understanding the concept of matter over mind, you can bring a sense of calm and peace and mind into everything you do. To help you start, here are 7 simple and easy ways to incorporate mindfulness into your daily life: 
        </p>
        <img src='/img/bg/blogpages/blogpagemindful/7 ways to be Mindful-01.webp' style={{
          width:isMobile?"100%":"90%"
        }} />
      </div>

    

      <div className="practices-grid">
        {practices.map((practice, index) => (
          <div key={index} className="practice-card">
           
            <div className="card-content">
              <h3>{practice.title}</h3>
              <p>{practice.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="footer">
        <p className='robotofont'>
          By incorporating these mindfulness steps for beginners and practising mindfulness in your everyday activities, 
          you can gradually create a more mindful lifestyle. With plenty of mindfulness training online options and resources 
          at your disposal, there's never been a better time to begin your journey.
        </p>
      </div>

      <style>{`
        .mindfulness-container {
          min-height: 100vh;
          padding: 4rem 2rem;
          background: linear-gradient(135deg, #f5f7ff 0%, #f0f4ff 100%);
          font-family: 'system-ui', sans-serif;
        }

        .header {
          max-width: 1000px;
          margin: 0 auto 4rem;
          text-align: center;
        }

        .header h1 {
          font-size: 3.5rem;
          color: #2d3748;
          margin-bottom: 1.5rem;
          font-weight: 700;
          letter-spacing: -0.5px;
          line-height: 1.2;
        }

        .subtitle {
          font-size: 1.25rem;
          color: #4a5568;
          line-height: 1.6;
          max-width: 800px;
          margin: 0 auto;
        }

        .practices-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 2rem;
          max-width: 1200px;
          margin: 0 auto;
        }

        .practice-card {
          background: white;
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          position: relative;
          background:rgb(245, 244, 239);
        }

        .practice-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        }

        .card-image {
          position: relative;
          padding-top: 66.67%; /* 3:2 aspect ratio */
          overflow: hidden;
        }

        .card-image img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.5s ease;
        }

        .practice-card:hover .card-image img {
          transform: scale(1.05);
        }

        .card-content {
          padding: 2rem;
        }

        .card-content h3 {
          font-size: 1.5rem;
          color: #2d3748;
          margin-bottom: 1rem;
          font-weight: 600;
        }

        .card-content p {
          color: #4a5568;
          line-height: 1.6;
          margin: 0;
        }

        .footer {
          width:100%;
          margin: 4rem auto 0;
          text-align: center;
          color: #4a5568;
          line-height: 1.6;
          padding: 2rem;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 15px;
          backdrop-filter: blur(10px);
        }

        @media (max-width: 768px) {
          .mindfulness-container {
            padding: 2rem 1rem;
          }

          .header h1 {
            font-size: 2.5rem;
          }

          .practices-grid {
            grid-template-columns: 1fr;
          }

          .card-content {
            padding: 1.5rem;
          }
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          .practices-grid {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .practice-card {
          animation: fadeIn 0.6s ease-out forwards;
          animation-delay: calc(var(--animation-order) * 0.1s);
          opacity: 0;
        }

        /* Custom scrollbar */
        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
          background: #c5c5c5;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #a8a8a8;
        }
      `}</style>
    </div>
  );
};

export default MindFulWays;