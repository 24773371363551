import React from 'react'
import ReviewsContainer from '../../components/DifferenceSection/ReviewsContainer'

function StoriesSlider() {

   
  return (
    <div className='reviewslider mt-5'>
        <ReviewsContainer />
    </div>
  )
}

export default StoriesSlider