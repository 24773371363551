import React from 'react'
import TabBars from '../Draganpages/TabBars'
import TabBars2 from './Tabbar2'

function BenefitsUnstress() {
    return (
        <div className='container-fluid my-5 d-flex justify-content-center align-items-center flex-column'>
            <h1 className='newpageheader text-center'>
                Achieve 100% Resolution to your problems
            </h1>
            {/* <p className='normalpara' style={{
        maxWidth:"50%"
    }}>
        Calm makes it easy to find what you're looking for and explore it at your own pace. Whether you want to sleep better, feel more relaxed, or improve your focus and productivity, Calm has something for you.
    </p> */}

            <TabBars2 />
        </div>
    )
}

export default BenefitsUnstress