import moment from 'moment';
import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoIosClose } from "react-icons/io";
import { useAudio } from 'react-use';
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { TbPlayerTrackPrevFilled } from "react-icons/tb";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { FaVolumeMute } from "react-icons/fa";
import { GoUnmute } from "react-icons/go";

function SidebarFullMusic({ modalshow, setShow,datanew }) {






    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [audio, state, controls, ref] = useAudio({
        src: datanew?.url || "",
        autoPlay: true,
    });


    

    useEffect(() => {
        if (datanew && datanew.url) {
            controls.play();
        }
    }, [datanew, controls]);






    if(!datanew){
        return null
    }


    return (
        <div>
            <Offcanvas show={modalshow} onHide={handleClose}>
                <Offcanvas.Header className='d-flex justify-content-between'>
                    <Offcanvas.Title className='white-color'>
                        Now Playing
                    </Offcanvas.Title>
                    <span>
                        <IoIosClose color='white' size={40} onClick={handleClose} />
                    </span>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {audio}
                           <Image src='/Assets/images/image1.jpg' style={{
                        border: "4px solid #9232e5",
                        borderRadius: "30px"
                    }} className='divboi' />
                    <Offcanvas.Title className='white-color mt-3 text-center'>
                        {datanew?.title}
                    </Offcanvas.Title>
                    <p className='white-color text-center' style={{
                        padding: "0px",
                        margin: "0px"
                    }}>
                        {datanew?.description}

                    </p>
                    <div className='d-flex justify-content-between'>
                        <p style={{
                            padding: 0,
                            margin: 0,
                            color: "white",
                            fontWeight: "600"
                        }}>
                            {
                                moment.utc(state.time * 1000).format("mm:ss")
                            }
                        </p>
                        <p style={{
                            padding: 0,
                            margin: 0,
                            color: "white",
                            fontWeight: "600"
                        }}>
                            {
                                moment.utc(state.duration * 1000).format("mm:ss")
                            }
                        </p>
                    </div>
                    <Form.Range max={state.duration} value={state.time} onChange={(e) => {
                        controls.seek(e.target.value)
                    }} />

                    <div className='d-flex justify-content-between align-items-center mt-3 px-5'>
                        <span>
                            <TbPlayerTrackPrevFilled color='#bc8de5' onClick={() => {
                                controls.seek(state.time - 10)
                            }} />
                        </span>
                        <span style={{
                            padding: "10px",
                            borderRadius: "15px",
                            border: "2px solid #bc8de5"
                        }}>
                            {state.paused ? (
                                <FaPlay color='#bc8de5' onClick={() => {
                                    controls.play()
                                }} />
                            ) : (
                                <FaPause color='#bc8de5' onClick={() => {
                                    controls.pause()
                                }} />
                            )}
                        </span>
                        <span>
                            <TbPlayerTrackNextFilled color='#bc8de5' onClick={() => {
                                controls.seek(state.time + 10)
                            }} />
                        </span>

                    </div>
                    <div className='d-flex justify-content-center align-items-center flex-column mt-5' style={{
                        border: "2px solid #bc8de5",
                        width: "70px",
                        padding: "10px",
                        borderRadius: "10px"
                    }}>
                        <span className='white-color'>
                            {
                                state.muted ? "Unmute" : "Mute"
                            }
                        </span>
                        <span>
                            {
                                state.muted ? (
                                    <FaVolumeMute color='#bc8de5' onClick={() => {
                                        controls.unmute()
                                    }} />

                                ) : (
                                    <GoUnmute color='#bc8de5' onClick={() => {
                                        controls.mute()
                                    }} />
                                )
                            }

                        </span>


                    </div>



                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default SidebarFullMusic