import React from 'react'
import { useMediaQuery } from 'react-responsive'

function ImageTextSection() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 })
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 })
    return (
        <div className="container">
            <div className='container'>
            {/* <div className="row justify-content-center align-items-center gap-3">
                <div className="col-sm-12 col-md-5">
                    <img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                        style={{
                            width: "100%",
                            height: "400px",
                            objectFit: "cover",
                            borderRadius: "10px"
                        }}
                    />
                </div>
                <div className="col-sm-12 col-md-5">
                    <div className='text-start'>
                        <h2>
                            Game-changing Breakthroughs Laid Silva Method’s Groundwork: ESP
                        </h2>
                        <p className='aboutpara2'>
                            Inspired by the incredible progress of his own children, Jose Silva sought to elevate his meditation and mind control techniques.
                            Initially met with skepticism, scientists were astounded when he successfully trained his daughter and 39 other children in ESP,
                            proving it could be learned by anyone. Silva's unwavering belief led to the development of the first-ever method in history to train individuals in using ESP.
                        </p>

                    </div>
                </div>
            </div> */}
            {/* <div className="row justify-content-center align-items-center mb-5 mt-4 gap-3">

                {
                    isDesktopOrLaptop ? (
                        <>
                            <div className="col-sm-12 col-md-5">
                                <div className='text-start'>
                                    <h2>
                                        The beauty of Advanced dynamic meditation training
                                    </h2>
                                    <p className='aboutpara2'>
                                        The foundation of the Silva Method was enriched with the introduction of dynamic meditation courses, a creation by Jose Silva in just three years.
                                        This mindfulness meditation bestows the marvelous benefits of rejuvenating the mind and body,
                                        while also boosting energy levels. With over 500,000 experiments providing validation, its significance cannot be dismissed by any open-minded scientist.
                                    </p>

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5">
                                <img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                    style={{
                                        width: "100%",
                                        height: "400px",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-sm-12 col-md-5">
                                <img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                    style={{
                                        width: "100%",
                                        height: "400px",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-5">
                                <div className='text-start'>
                                    <h2>
                                        The beauty of Advanced dynamic meditation training
                                    </h2>
                                    <p className='aboutpara2'>
                                        The foundation of the Silva Method was enriched with the introduction of dynamic meditation courses, a creation by Jose Silva in just three years.
                                        This mindfulness meditation bestows the marvelous benefits of rejuvenating the mind and body,
                                        while also boosting energy levels. With over 500,000 experiments providing validation, its significance cannot be dismissed by any open-minded scientist.
                                    </p>


                                </div>
                            </div>

                        </>
                    )
                }
            </div> */}

        </div>
        </div>
    )
}

export default ImageTextSection