import React from 'react'
import EmotionalNavbar from '../EmotionalIntelligence/EmotionalNavbar'
import NewFooter2 from '../FreeCourse/NewFooter2'
import HeroSectionEnt from '../EmotionalIntelligence/HeroSectionEnt'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import FeaturedSection from '../SilvaComboCourse/FeaturedSection'
import TrustedBy2 from '../EmotionalIntelligence/TrustedBy2'
import EmotionalWhySection from '../EmotionalIntelligence/EmotionalWhySection'
import Approach from '../hypnosisPage2/ApproachSection'
import ManifestDesires from './ManifestDesires'
import { useMemo } from 'react'
import ManifestDesires2 from './ManifestDesires2'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import WhatsAlpha from '../HypnosisPage/WhatsAlpha'
import ComparisonTable2 from '../SilvaComboCourse/ComparisonTable2'
import ComboIncluded from '../SilvaComboCourse/ComboIncluded'
import ComboTestimonials from '../SilvaComboCourse/ComboTestimonials'
import ComboPaymentNew from '../SilvaComboCourse/ComboPaymentNew'
import ContactSection from '../../CoursePage/ContactSection'
import NewComboBanner from '../ComboCourse/NewComboBanner'

function Manifestation() {
    const { isMobile } = useContext(AuthContext);



    function scrollToPayment() {
        polyfill()

        const doc = document.querySelector(".scrollnewsection")
        setTimeout(() => {

            scrollIntoView(doc, {
                behavior: "smooth",
                block: "end", inline: "nearest"
            });
        }, 300)
    }

    return (
        <div>
            <div style={{
                position: "relative",
                background: "#301934"
            }}>

                <div className='container' style={{
                    position: "absolute",
                    top: "15px",
                    left: "50%",
                    transform: "translateX(-50%)" // Center horizontally
                }}>
                    <EmotionalNavbar />

                </div>
                <div style={{
                    paddingTop:  isMobile ? "120px" : "150px",
                    paddingBottom: isMobile ? "20px" : "120px"
                }}>



                    <HeroSectionEnt />

                </div>
            </div>

            <div className='py-5' style={{
                // background: "#ededed"
            }}>
                <FeaturedSection />

                <TrustedBy2 />


                <div className='pt-3'>
                    <Approach />

                    <ManifestDesires />

                    <ManifestDesires2 />

                </div>

            </div>
            <div style={{
                //   background: "#ededed"
            }}>

            {
                isMobile ? (
                    <img src='https://www.silvacasesonline.com/assets/img/Image20240613163449.jpg' style={{
                        width: "100%",
                        objectFit: "cover",
                        cursor: "pointer",

                    }}
                        onClick={() => {
                            window.open("https://silvamethod.com/7-day-meditation-masterclass")
                        }}
                    />
                ) : (
                    <img src='https://www.silvacasesonline.com/assets/img/Image20240613161212.jpg' style={{
                        width: "100%",
                        objectFit: "cover",
                        cursor: "pointer"
                    }}
                        onClick={() => {
                            window.open("https://silvamethod.com/7-day-meditation-masterclass")
                        }}
                    />
                )

            }

            <WhatsAlpha />




            <div className='d-flex justify-content-center align-items-center my-5'>
                <img src='/img/hypnosis/infographics.png' style={{
                    borderRadius: 10
                }} />
            </div>
            <ComparisonTable2 func={scrollToPayment} />

            <ComboIncluded func={scrollToPayment} />
{/* <NewComboBanner/> */}



            <ComboTestimonials />
            <ComboPaymentNew />




            <ContactSection />
            </div>

            <NewFooter2 />
        </div>
    )
}

export default Manifestation