import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

function QrCodeSection({ purchased = false }) {
    const [show, setShow] = useState(true);


    const closeIconClick = (event) => {
        setShow(false);
    };


    return (
        <div className={`d-flex justify-content-center align-items-center h-100 w-100 ${purchased?"container":""}`} style={{
            position: "relative"
        }}>

            {
                (purchased && show) ? (
                    <img src={require("../../../../img/qr2.jpg")}
                        style={{
                            width: "100%",
                            cursor: "pointer"

                        }}

                        onClick={() => window.open("https://play.google.com/store/apps/details?id=com.silvamethod")}
                    />
                ) : (!purchased) ? (
                    <img src={require("../../../../img/qr.jpg")}
                        style={{
                            width: "100%",
                            cursor: "pointer"
                        }}
                        onClick={() => window.open("https://play.google.com/store/apps/details?id=com.silvamethod")}
                    />
                ) : null
            }
            {
                purchased && (
                    <span style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px"
                    }}

                    >
                        <AiOutlineClose size={30} color="white" onClick={closeIconClick} />
                    </span>
                )
            }
        </div>
    )
}

export default QrCodeSection