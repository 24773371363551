import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";
import DayCardTwo from "./DayCardTwo";

function VideoTableSection2() {
  const { isMobile,isTablet } = useContext(AuthContext);

 


  const day1 = [
    [
        "History of Silva Method",
        "Jose Silva"
    ],
    [
        "Brain & Mind relation",
        "What we need to do to achieve our goals",
        "Parts of our brain"
    ],
    [
        "Brainwave frequencies: Beta, Alpha, Theta, Delta",
        "Explanation of Beta, Alpha, Theta, Delta brainwave states",
        "How to balance your brain",
        "What are thoughts"
    ],
    [
        "How to stay in Alpha level",
        "Alpha level advantages"
    ],
    [
        "How Silva Method can help in Physical health",
        "How Silva Method can help in Mental Health",
        "How Silva Method can help in Social health",
        "How Silva Method can help in Education",
        "How Silva Method can help in Wealth",
        "How Silva Method can help in Relationship",
        "How Silva Method can help in Spirituality"
    ]
  ];



  return (
    <div className="pb-5 d-flex justify-content-center align-items-center flex-column">
    
      <div className="container">
        <h3 className={`newpageheader text-center pb-${(isMobile || isTablet)?0:5}`}>
           Schedule of the masterclass
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-column">
            {day1.map((val, i) => (
              <DayCardTwo data={val} index={i + 1} />
            ))}
          </div>
 
       
      </div>
    </div>
  );
}

export default VideoTableSection2;
