import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import { MdEmail } from "react-icons/md";
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import { IoIosPin } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

function NewFooter() {
    const { isMobile,isTablet } = useContext(AuthContext);

    const navigate = useNavigate();


    return (
        <div className='bgfooternew' style={{

            paddingTop: "80px",
            paddingBottom: "50px"
        }}>
            <footer>
                <div className='container'>
                    <Row className='justify-content-center align-items-start gy-5'>
                        <Col xs={12} md={6} lg={3} style={{
                            borderRight: "1px solid #ccc",
                        }}>
                            <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-4' style={{

                                paddingRight: "40px",
                                paddingLeft: isMobile ? "30px" : "0px"
                            }}>
                                <Image src='/img/footer/footer-03.webp' />
                                <p className='texthovergray' style={{
                                    lineHeight: "1.2rem",

                                }}>
                                    We are a global organization dedicated to changing lives by empowering minds.
                                </p>
                                <div className='d-flex justify-content-center align-items-start flex-column'>
                                    <h5 className='fw-semibold text-light'>Contact Info</h5>
                                    <p className='texthovergray'>
                                        <span style={{
                                            marginRight: "10px",
                                        }}><MdEmail color='white' size={20} /></span>
                                        <a href="mailto:digital@silvamethod.com" style={{
                                            color: "white"
                                        }}>digital@silvamethod.com</a>
                                    </p>
                                </div>

                                <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                                    <h5 className='fw-semibold text-light'>Stay Connected</h5>

                                    <div className='d-flex gap-2'>
                                        <Image src='img/footer/footer-05.webp' style={{
                                            cursor: "pointer"
                                        }} width={40} height={40}
                                            onClick={() => {
                                                window.open("https://www.youtube.com/channel/UCaCDqnK1-i0rb9tFPznvI4w")
                                            }}
                                        />
                                        <Image src='img/footer/footer-06.webp' style={{
                                            cursor: "pointer"
                                        }} width={40} height={40}
                                            onClick={() => {
                                                window.open("https://www.instagram.com/silvamethodofficial/")
                                            }}
                                        />
                                        <Image src='img/footer/footer-07.webp' style={{
                                            cursor: "pointer"
                                        }} width={40} height={40}
                                        onClick={() => {
                                            window.open("https://www.facebook.com/SilvaInternationalInc")
                                        }}
                                        />
                                        <Image src='img/footer/footer-08.webp' width={40} height={40} style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            window.open("https://x.com/SilvaHomeOffice?mx=2")
                                        }}
                                        />

                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col xs={12} md={6} lg={3}>
                            <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-5' style={{
                                paddingLeft: isMobile ? "30px" : "40px"
                            }}>
                                <div className='d-flex justify-content-center align-items-start flex-column gap-3'>
                                    <h5 className='fw-semibold text-light'>Courses</h5>
                                    <ul className='texthovergray' style={{
                                        listStyleType: "none",
                                        lineHeight: "25px"
                                    }}>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/courses/silva-life-system-seminar")
                                        }}
                                        >Silva Life System</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/courses/silva-intution-system-seminar")
                                        }}
                                        >Silva Intuition System</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/courses/silva-mastery-system")
                                        }}
                                        >Silva Mastery System</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/courses/basic-lecture-series")
                                        }}
                                        >Basic Lecture Series(BLS)</li>

                                    </ul>
                                </div>
                                <div className='d-flex justify-content-center align-items-start flex-column gap-3'>
                                    <h5 className='fw-semibold text-light'>Silva Updates</h5>
                                    <ul className='texthovergray' style={{
                                        listStyleType: "none",
                                        lineHeight: "25px"
                                    }}>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/what-is-silva-method")
                                        }}
                                        >What is Silva Method</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/silva-method-courses")
                                        }}
                                        >Jose Silva Method Courses</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/jose-silva-method")
                                        }}
                                        >A biography on Jose Silva's penmanship</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/youtube-videos")
                                        }}
                                        >Youtube Videos</li>

                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-5' style={{
                                paddingLeft: isTablet?"0px":"30px"
                            }}>
                                <div className='d-flex justify-content-center align-items-start flex-column gap-3'>
                                    <h5 className='fw-semibold text-light'>Quick Links</h5>
                                    <ul className='texthovergray' style={{
                                        listStyleType: "none",
                                        lineHeight: "25px"
                                    }}>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/youtube-videos")
                                        }}
                                        >Silva Method Youtube Videos</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                           window.open("https://silvamethod.com/stores/shop/")
                                        }}
                                        >Books</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/silva-method-faq")
                                        }}
                                        >FAQs</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/become-silva-affiliate")
                                        }}
                                        >Become an Affiliate</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            window.open("https://silvacasesonline.com")
                                        }}
                                        >Silva Cases</li>
                                        <li style={{
                                            cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                            navigate("/login")
                                        }}
                                        >Join Now</li>
                                    </ul>
                                </div>

                                <div className='d-flex justify-content-center align-items-start flex-column gap-3 flex-wrap'>
                                    <h5 className='fw-semibold text-light'>Silva International <br /> Whatsapp group</h5>
                                    <button className='footer-whatapp-Btn' style={{
                                        margin: "0px",
                                    }}
                                    onClick={()=>{
                                        window.open("https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ")
                                    }}
                                    >
                                      <span><RiWhatsappFill color='white' size={20} /></span>  Join Group

                                    </button>
                                </div>

                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>

                            <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-4' style={{
                                paddingLeft: "30px"
                            }}>

                                <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                                    <h6 className='fw-semibold text-light'>Rating A+</h6>
                                    <a
                                        href="https://www.bbb.org/us/tx/laredo/profile/educational-consultant/silva-international-inc-0825-19971/#sealclick"
                                        id="bbblink"
                                        class="ruhzbum"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img
                                            src="https://seal-austin.bbb.org/logo/ruhzbum/bbb-19971.png"
                                            style={{ border: "0" }}
                                            alt="Silva International Inc BBB Business Review"
                                        />
                                    </a>
                                </div>

                                <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                                    <h6 className='fw-semibold text-light'>Reach Us</h6>
                                    <div className='d-flex gap-2'>
                                        <button className='btn' style={{
                                            background: "linear-gradient(90deg, rgba(53,32,230,1) 0%, rgba(217,1,252,1) 49%)",
                                            color: "white",
                                            borderRadius: "10px",
                                        }}
                                        onClick={()=>{
                                            navigate("/contact")
                                        }}
                                        >Contact us</button>
                                        <button className='btn btn-light' style={{
                                            borderRadius: "10px",
                                        }}
                                        onClick={()=>{
                                            navigate("/login")
                                        }}
                                       
                                        >Join us</button>


                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                                    <h6 className='fw-semibold text-light'><span style={{
                                        marginRight: "5px"
                                    }}><IoIosPin color='red' /></span> Find us on google maps</h6>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7076.144526545807!2d-99.47702!3d27.529214000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866121401541c593%3A0x5b7e28a5e5bcc98f!2s3426%20N%20Buena%20Vista%20Ave%2C%20Laredo%2C%20TX%2078043%2C%20USA!5e0!3m2!1sen!2sin!4v1715943007951!5m2!1sen!2sin" style={{
                                        width: isMobile?"100%":"80%",
                                        height: isMobile?"200px":"150px",
                                        borderRadius: "10px",
                                        border: "0px"
                                    }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>

            </footer>
        </div>
    )
}

export default NewFooter