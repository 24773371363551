import React from 'react'
import HeadingNew from '../../../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AllContext'

function DifferenceSectionMindful() {
    const {isMobile} = useContext(AuthContext);
    
    return (
        <div className={isMobile?"container px-4":'container py-5'}>

            <Row className='justify-content-center align-items-center'>
                <Col xs={12}>
                    <div className='d-flex justify-content-start flex-column' style={{
                        width:"95%"
                    }}>
                    <HeadingNew title={"Difference between"} colored={"Mindfulness and Meditation "} start />
                    <p className='robotofont mt-4'>
                        The relationship between meditation and mindfulness allows individuals to navigate their thoughts and emotions more effectively.
                         While both mindfulness and meditation are interconnected, they aren't identical. 
                         Let's explore the subtle yet important distinctions between these two approaches to a peaceful and balanced states of mind.
                    </p>
                    <p className='robotofont'>
                        The essence of mindfulness, what is it, lies in observing thoughts and emotions without becoming overwhelmed by them.
                         Mindfulness can be practised anywhere and at any time. It’s like a guide that helps you navigate daily life with a clear,
                          calm mind and achieve peace and mind. whether you’re at work . walking, or even eating. I remember beginning mindfulness with mindful
                           self compassion during stressful workdays, where just noticing my breath for a moment made all the difference.
                    </p>
                    <p className='robotofont'>
                        On the other hand, mindfulness meditation is a structured practice where you intentionally focus your attention…..often on the breath or a specific object..while sitting in a quiet space. It’s a dedicated time to cultivate mindfulness and can greatly improve your sleep and overall peace of mind. In fact, meditation and sleep are closely linked, with mindfulness meditation known to calm racing thoughts and prepare the mind for restful sleep.
                    </p>

                    <img src='/img/bg/blogpages/blogpagemindful/Difference between Mindfulness and Meditation.webp' className='mt-3 mb-4' style={{
                        borderRadius:"20px"
                    }} />


                        
                    <HeadingNew small title={"Here are the main differences:"} start />

                    <h3>
                    </h3>
                    <ul className="causes-list robotofont">
                        <li>
                            Mindfulness is a state of mind practised throughout daily activities, whereas mindfulness meditation is a formal, seated practice.
                        </li>
                        <li>
                            In mindfulness meditation, you dedicate time for focused attention, while mindfulness can be done anytime without formal settings.
                        </li>
                        <li>
                            Mindfulness meditation often leads to better sleep, whereas mindfulness generally helps maintain focus and calm throughout the day. The connection between  meditation and sleep is significant, as regular meditation and sleep can help reduce insomnia and promote deeper, more restorative rest.
                        </li>

                    </ul>

                    </div>

                </Col>
             
            </Row>


        </div>
    )
}

export default DifferenceSectionMindful