import { Button, Card } from 'react-bootstrap'
import {AiFillCheckCircle} from "react-icons/ai";
import HeadingNew from '../AboutUs/HeadingNew';
import ParaComp from '../SilvaManifestationProgram/ParaComp';


function JoinCard({handleShow}) {
    const buttonStyle = {
        background: '#f1c40f',
        color: 'black',
        border: "1px solid #7F00FF",
        fontWeight:"600",
        padding:"10px auto"
        // Set text color
        // Add any other CSS properties you need
    };
    return (
        <Card
            style={{
                background: "rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)",
                border: "1px solid #167799",
                color: "white",
                width: "auto",
                padding: "10px 12px",
                borderRadius: "12px",
            }}
        >
            <Card.Body>
                <div className='my-3'>
                <HeadingNew
                    title={"Excited to Join?"}
                    white
                    start
                    small
                />
                </div>
                <ParaComp
                    margin={false}
                    data={[
                        "Over a million individuals have already experienced the wave of transformation, revolutionizing their personal and professional lives. They've conquered fears and unlocked a new, improved version of themselves. Whether it's your physical well-being or your mental health, the Silva Method has it covered. So, why wait? Jump on board in this thrilling adventure and unveil the amazing new you!"
                    ]}
                />

                <div className="d-grid gap-2">
                    <Button style={buttonStyle} size='md' onClick={handleShow}>
                        <span><AiFillCheckCircle size={28} color='black' /></span> Sign Up Now
                    </Button>
                </div>


                {/* <p className='white-color text-left mt-4'>
                    The cohort is limited to 100 people.
                    Hurry up! Join now.
                </p> */}
            </Card.Body>
        </Card>
    )
}

export default JoinCard