import React from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import ReactPlayer from 'react-player';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import Featured from '../../../components/featured/Featured';

function LoveMindTwoHero() {
    const { isMobile } = useContext(AuthContext);

    const handleScroll = () => {
        const doc = document.querySelector(".lovemindformscroll");
        if (doc) {
            doc.scrollIntoView({
                behavior: "smooth"
            })
        }
    }

    return (

        <div>
            <Row className='justify-content-center align-items-center mb-5' style={{
                position: "relative",
                backgroundImage: `url(/img/bg/bglovemind.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <Col xs={12} md={7} xl={6} className={isMobile ? "order-2" : "order-1"}>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: isMobile ? "center" : "flex-start",
                        flexDirection: "column",

                        width: '100%',
                        height: '100%',

                        borderTopRightRadius: "15px",
                        borderBottomRightRadius: "15px",
                        padding: isMobile ? "30px 30px 20px 30px" : "100px 50px 100px 100px"
                    }}>
                        {/* <HeadingNew title={"Build Healthy Self-esteem"} white small /> */}

                        <p style={{
                            color:"white",
                            fontSize:"1rem",
                            fontWeight:"bold",
                            marginBottom:"0px",
                        }}>
                            Build Healthy Self-esteem
                        </p>
                        <h1 style={{
                            fontSize: isMobile ? "2.5rem" : "3.5rem",
                            fontWeight: "bold",
                            color: "white",
                            marginBottom: "0px",
                            marginTop: isMobile ? "0px" : "5px",
                            marginBottom:"5px"

                        }}>
                            Love Mind
                        </h1>
                        <h3 style={{
                            fontSize: isMobile ? "1.6rem" : "3rem",
                            fontWeight: "bold",
                            color: "white",
                            textAlign: isMobile ? "center" : "start"
                        }}>
                            <span style={{
                                color: '#FFDD00'
                            }}>
                                Free</span> Online Masterclass
                        </h3>



                        {
                            !isMobile && (
                                <p className='text-start text-light' style={{
                                    width: isMobile ? "100%" : "75%"
                                }}>
                                    Release guilt and stress, embrace self-compassion, and forge genuine connections. By fostering inner peace, harmonize with the universe, and foster meaningful relationships and profound fulfillment

                                </p>
                            )
                        }
                        <button className='cssbuttons-io-button mt-2 mt-md-0' onClick={handleScroll}>
                            Enroll now for free
                        </button>



                    </div>


                </Col>
                <Col xs={12} md={5} xl={6} className={isMobile ? "order-1" : "order-2"}>
                    <div className={isMobile ? 'lovemindvid d-flex justify-content-center align-items-center' : 'lovemindvid d-flex justify-content-end align-items-center'} style={{
                        width: "100%",
                        height: "100%",
                        padding: isMobile ? 0 : 50,
                        paddingLeft: isMobile ? 0 : 20



                    }}>
                        <video src={"https://silva-method-official.b-cdn.net/Unstress%20Toolbox/dragan.mp4"}
                            controls
                            width={"auto"}
                            height={"auto"}
                            poster='/img/lovemind/thumbvideo.png'

                            loop
                            style={{

                                width: isMobile ? "95%" : "100%",
                                height: isMobile ? "95%" : "100%",


                            }}
                        />


                    </div>



                </Col>
            </Row>
        </div>
    )
}

export default LoveMindTwoHero