import React from "react";
import HeadingNew from "../../AboutUs/HeadingNew";
import { Col, Image, Row } from "react-bootstrap";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import { Link } from "react-router-dom";

function AboutDianaSilva() {
  return (
    <div className="my-5 mb-0 py-5 aboutdianabg">
      <HeadingNew title={"About Silva International President"} white />
      <div className="container my-5">
        <Row>
          <Col sm={12} md={4} lg={3}>
            <div className="p-3">
              <Image
                src="/img/img-17.webp"
                style={{ borderRadius: "15px", border: "2px solid white"}}
              />
            </div>
          </Col>
          <Col sm={12} md={8} lg={9}>
            <div className="px-3">
              <HeadingNew title={"Diana Silva"} white small start />
              <h5 className="text-white">
                Current President of Silva Method International
              </h5>
              <ParaComp
                data={[
                  "Diana, the youngest daughter of Jose Silva, is our very own in-house Silva Life Coach and the current president of Silva method International INC. She supports students and seekers alike with the Silva Method and life. Having grown up Silva, she has spent her entire life working in an environment rich with personal growth and mind empowerment. She is a certified and qualified Silva Coach, which means that she uses natural insight combined with genuine coaching tools and the always effective Silva Method Techniques to help you get from where you are now to where you want to be. What is great about Diana is..",
                ]}
                white
                fw={"300"}
              />
              <Link to="/instructor/diana-silva">
              <button className="px-5 py-3 border-0 mt-5" style={{borderRadius:"10px",backgroundColor:"#ffd00e",fontWeight:"600"}}>Know More</button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );  
}

export default AboutDianaSilva;
