import React, { useContext, useMemo, useState } from 'react'
import { Card, Col, Form, Image, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import ReactStars from 'react-stars'
import Tilt from 'react-parallax-tilt';
import { AuthContext } from '../../../context/AllContext';
import Button from '../One/Button';
import { allcodes } from '../../../utils/allCountryCodes';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { reqData } from '../../../utils/baseUrl';

function WhatIsLawOfAttraction() {
    const { isMobile } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        page: "love mind program",
        name: "",
        email: "",
        country_code: "+1",
        whats_app: ""
    })

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
      if (!values.country_code) {
            toast.error("Please enter a country code");
            return
        } else if (!values.whats_app) {
            toast.error("Please enter your whatsapp number");
            return
        }

        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

        const isValidEmail = pattern.test(values.email);

        if (!isValidEmail) {
            toast.error("Please enter a valid email address");
            return
        }
        setLoading(true)
        const res = await reqData("/LeadGenerationForm", "POST", values);
        setLoading(false)

        if (res && res.error === false) {
            setValues({
                page: "love-mind",
                name: "",
                email: "",
                country_code: "+91",
                whats_app: ""
            })
            Swal.fire({
                title: "Thanks for submitting the form",
                icon: "success"
            });
            // setTimeout(() => {
            //     window.open('https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ')
            // }, 3000)
        }
    }


    return (
        <div className={isMobile ? "" : 'd-flex justify-content-center align-items-center'}>
            <Row className='container justify-content-center align-items-center' style={{
                paddingRight: "0"
            }}>
                <Col xs={12} md={6}>

                    <h2 className='fontclass text-center' style={{ color: 'white' }}>
                        Say YES To Silva Method Online Live Learning Experience
                    </h2>
                    <Image src='/img/love_mind/image1-transparent.webp' />
                </Col>
                <Col xs={12} md={6}>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        width: "100%"
                    }}>




                        <Card style={{
                            borderRadius: "15px",
                            width: isMobile ? "100%" : "80%",
                            '--bs-gutter-x': '0'
                        }}
                            className='cardnewglass formscroll'
                        >
                            {/* <div className="card-image-container">

                                <Card.Img src='/img/bg/cardimagebg.jpg' style={{
                                    borderTopLeftRadius: "15px",
                                    borderTopRightRadius: "15px"
                                }} />
                            </div> */}


                            <Card.Body style={{
                                backgroundImage: "url('/img/bg/bgpatternlovemind2.jpg')",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "15px",
                                padding: "25px"
                            }}>
                                <Card.Title className='text-dark mb-3'>
                                    Submit The form below to book your class  for free!
                                </Card.Title>
                                <Form>
                                    <Form.Group controlId='formFullName'>
                                        <Form.Label className='text-dark'>Full Name <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='full name'
                                            name='name'
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId='formEmail' className='mt-2'>
                                        <Form.Label className='text-dark'>Email address <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type='email'
                                            placeholder='Enter your email'
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId='countryCode' className='mt-2'>
                                        <Form.Label className='text-dark'>Country Code <span className='text-danger'>*</span></Form.Label>
                                        <Form.Select required onChange={e => {
                                            const found = allcodes.find((val) => val.name === e.target.value);
                                            setValues({
                                                ...values,
                                                country_code: found.dial_code
                                            })
                                        }}

                                        >
                                            {
                                                allcodes.map((val) => (
                                                    <option value={val.name} selected={val.code === "US"}>{val.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group controlId='wpnumber' className='mt-2'>
                                        <Form.Label className='text-dark'>WhatsApp Number <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter your WhatsApp number'
                                            name='whatsapp'
                                            value={values.whats_app} onChange={(e) => {

                                                setValues({
                                                    ...values,
                                                    whats_app: e.target.value
                                                })
                                            }}
                                        // value={formData.whatsapp}
                                        // onChange={handleChange}
                                        />
                                    </Form.Group>



                                    {/* <Button variant='light' type='submit' className='mt-2' disabled={loading}>
                                        {loading ? '...loading' : 'Submit'}
                                    </Button> */}
                                    <button className="bn632-hover bn22" onClick={handleSubmit} disabled={loading}>
                                        {
                                            loading ? '...loading' : 'Submit'
                                        }
                                    </button>

                                </Form>



                            </Card.Body>

                        </Card>


                    </div>
                </Col>


            </Row>
        </div>

    )
}

export default WhatIsLawOfAttraction