import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { reqData } from '../../utils/baseUrl';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';

function FailedPayment() {
    const navigate = useNavigate()
    
   useEffect(()=>{
    Swal.fire({
        position: 'top-center',
        icon: 'error',
        title: 'Payment Failed',
        showConfirmButton: true,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
    }).then(async (result) => {
        if (result.isConfirmed) {
            navigate("/")
        }
    })
   },[])
  return (
    <div>
       
    </div>
  )
}

export default FailedPayment