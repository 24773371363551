import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { Card, Col, Image, Row } from 'react-bootstrap'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'

function EleventhSectionCustom({ data = [
    {
        img: require("../../../images/adimgs/icon-17.png"),
        text: "Reduce Stress, Anxiety, and Depression"
    },
    {
        img: require("../../../images/adimgs/icon-18.png"),
        text: "Reduce Tension and Headaches"
    },
    {
        img: require("../../../images/adimgs/icon-19.png"),
        text: "Sound and Restful Sleep"
    },
    {
        img: require("../../../images/adimgs/icon-20.png"),
        text: "Boost Energy Levels"
    },
    {
        img: require("../../../images/adimgs/icon-21.png"),
        text: "Reduce Tension and Headaches"
    },
    {
        img: require("../../../images/adimgs/icon-22.png"),
        text: "Boost Creativity and Inspiration"
    },
    {
        img: require("../../../images/adimgs/icon-23.png"),
        text: "Build Healthy and Fulfilling Relationships"
    },
    {
        img: require("../../../images/adimgs/icon-24.png"),
        text: "Enhance Intelligence"
    },
    {
        img: require("../../../images/adimgs/icon-25.png"),
        text: "Accelerate Learning and Boost Memory"
    },
    {
        img: require("../../../images/adimgs/icon-26.png"),
        text: "Boost Self-Esteem and Self-Confidence"
    }
], title, text, smallTitle, or = true }) {


    return (
        <div>
            <div className='mt-2'>
                <HeadingNew title={smallTitle} />
                <p className='text-center mt-3' style={{
                    fontSize:16,
                    color:"black"
                }}>
                    {text}
                </p>
                {or && (
                    <h3 className='text-center text-dark'>Or</h3>
                )}


                <HeadingNew title={title} small />

            </div>
            <div className='container mb-3 mt-4'>
                <Row className='justify-content-center align-items-center gap-4'>
                    {
                        data.map((val, i) => (
                            <Col key={i} xl={2} lg={3} md={4}>
                                <Card className='cardeleventh'>
                                    <Card.Body className='cardeleventhcontent'>
                                        <Image src={val.img} width={100} height={100} />
                                        <ParaComp
                                            center
                                            data={[
                                                val.text
                                            ]}
                                            white={false}
                                        />

                                    </Card.Body>
                                </Card>
                            </Col>

                        ))
                    }
                </Row>


            </div>

        </div>
    )
}

export default EleventhSectionCustom