import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AllContext'

function CourseProgressions() {
    const {userData} = useContext(AuthContext);


  return (
    <div className='container'>
        {/* <h3>Hello world</h3> */}

    </div>
  )
}

export default CourseProgressions