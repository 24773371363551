import React from 'react'
import { TypeAnimation } from 'react-type-animation'
import { AuthContext } from '../../../context/AllContext';
import { useContext } from 'react';
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill';
import { useNavigate } from 'react-router-dom';
import ShineBtn from '../SilvaComboCourse/ShineBtn';


function HeroSectionEmo2() {
    const { isMobile, isTablet } = useContext(AuthContext);
    const navigate = useNavigate()
    const handleScrollCombo2Curr2 = () => {
        polyfill()

        const doc = document.querySelector(".includelivecheck");
        setTimeout(() => {
            // if (doc) {
            //     doc.scrollIntoView({
            //         behavior: "smooth",
            //         block: "start", // Align the top of the element with the top of the viewport
            //         inline: "nearest", // Scroll until the element is in view
            //     })
            // }
            scrollIntoView(doc, {
                behavior: "smooth",
                block: "end", inline: "nearest"
            });
        }, 500)


    }




    return (
        <div className='d-flex justify-content-center align-items-center flex-column' style={{
            paddingTop:"100px",
        }}>
            <div className='container d-flex justify-content-center align-items-center flex-column'>
                <h1 className='fw-700' style={{
                    fontSize: isMobile?"1.8rem":"2.5rem",
                    color: "black",
                    background:"#FFD23F",
                    padding:"10px 15px",
                    borderRadius:"10px",
                    textAlign:isMobile?"center":"start"
                    
                }}>Emotional Intelligence </h1>

                <h2 className='mt-3' style={{
                    fontSize: isMobile?"1.2rem":"1.5rem",
                    color: "black",
                    fontWeight: "700",
                    textAlign:isMobile?"center":"start"
                }}>
                    The Art of {" "}
                    <TypeAnimation
                        sequence={[
                            'Perceiving', // Types 'One'
                            1000, // Waits 1s
                            'Evaluating', // Deletes 'One' and types 'Two'
                            1000, // Waits 2s
                            'Expressing', // Types 'Three' without deleting 'Two'
                            1000,
                            "Controlling",
                            1000
                        ]}
                        wrapper="h2"
                        cursor={true}
                        repeat={Infinity}
                        style={{ color: "#7a0dff", lineHeight: "1.1rem",   fontSize: isMobile?"1.2rem":"1.5rem", display: "inline", fontWeight: "700", marginRight: isMobile?"10px":"0px" }}
                    />
                    {
                        isMobile?<>
                        <br/>Emotions
                        </>:"Emotions"
                    }
                </h2>

                <div style={{
                    maxWidth: isMobile?"95%":"70%"
                }}>

                    <p className='text-center mt-2 mb-2' style={{
                        fontSize: isMobile ? "1rem" : "1.1rem",
                        color: "black",
                        margin: "0px",
                        padding: "0px"
                    }}>
                        <b style={{
                            paddingRight: "5px"
                        }}>Do you know EI or EQ is as crucial as IQ?</b>
                        Yes, it is. Emotional intelligence helps us understand and manage our emotions and respond effectively to others. Mastering this skill can transform your life, and foster success, deeper relationships, and fulfillment. Enroll in the Silva Method to elevate your emotional intelligence effortlessly.


                    </p>


                </div>
                <div className='d-flex gap-2 justify-content-center align-items-center my-3' style={{
                    width: "fit-content"
                }}>
                    <div className='d-flex justify-content-center align-items-center gap-2'>
                        <div class="avatars">
                            {
                                isMobile ? (
                                    <>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>

                                    </>
                                ) : (
                                    <>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>
                                        <a onClick={handleScrollCombo2Curr2} style={{
                                            cursor: "pointer"
                                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Giselle.jpg" alt="" /></a>

                                        <a onClick={handleScrollCombo2Curr2} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/K.%20Galway.jpg" alt="" /></a>
                                    </>
                                )
                            }


                        </div>
                        <h6 style={{
                            color: "black",
                        }}>
                            Join 12M+
                            <br />
                            Active Students
                        </h6>
                    </div>
                </div>
                <div className='d-flex justify-content-start align-items-center mt-3 mb-3 gap-3'>
                <ShineBtn onClick={handleScrollCombo2Curr2} text={"Start Your Journey Now"} />
                    {/* <button className='btnnew22' onClick={handleScrollCombo2Curr2}>
                        <svg
                            height="24"
                            width="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="black"
                            ></path>
                        </svg>
                        <span>Enroll Now</span>
                    </button> */}


                    {/* <button className='btnnew22' onClick={handleScrollCombo2Curr2}>
                       
                        <span>Check Your Eq for Free</span>
                    </button> */}

                </div>

            </div>
            {
                isMobile? (
                    <img src='/img/combo/Emotional Intelligence-for-mobile.webp' />

                ):(
                    <img src='/img/combo/Emotional Intelligence23.jpg' />

                )
            }

        </div>
    )
}

export default HeroSectionEmo2