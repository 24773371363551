import React, { useContext } from 'react'
import { Card } from 'react-bootstrap'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { AuthContext } from '../../../context/AllContext'
import HomeHeading2 from '../../Home/Home/HomeHeading2'

function NinthSectionCard({ value,fullwidth }) {
    const { isTablet, isMobile } = useContext(AuthContext)
    return (
        <Card style={{ borderRadius: "15px", padding: "10px 10px 0 0", backgroundColor: "#f4f5f9", width: (isTablet || isMobile) ? "95%" :fullwidth?"100%": "70%", height: "auto" }}>
            <Card.Body className='d-flex justify-content-start align-items-start gap-3' style={{ flexDirection: isMobile ? "column" : "row" }}>
                <div>
                    <div className='bluebox'>
                        <div className="innerdiv d-flex justify-content-center align-items-center" style={{ fontSize: "2rem" }}>
                            <span className='white-color'>
                                {value.num}
                            </span>
                        </div>
                    </div>

                </div>
                <div className='textbox'>
                    {/* <h3 style={{fontWeight:"800"}}>Silva <span style={{color:"rgb(216, 1, 251)",fontSize:"inherit",fontWeight:"800"}}>Three Fingers</span> Approach</h3> */}
                    {/* <HeadingNew start={false} small title={"Silva Three Fingers Approach"} /> */}
                    <HomeHeading2 title={value.title} small colored={value.colored} title2={value.title2} center={false} />

                    <p className='normalpara text-start'>
                        {value.para}
                    </p>

                    {/* <ParaComp
                        center={false}
                        white={false}
                        margin={false}
                        data={value.para}
                    /> */}

                </div>
            </Card.Body>
        </Card>
    )
}

export default NinthSectionCard