import React, { useContext } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../context/AllContext';
import { Link } from 'react-router-dom';


const RefundBanner = () => {

    const {isMobile,isTablet}= useContext(AuthContext);

  return (
    <Card style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:(isMobile || isTablet)?'center':'start',padding:'14px',borderRadius:'15px'}} className='parallexnewzone'>
      <Card.Body>
        <Row className='d-flex justify-content-center align-items-center'>
          {/* First Section */}
          <Col xs={12} md={3}>
           <div style={{width:'150px',height:'150px',borderRadius:'150px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'white',padding:'10px',margin:'0 auto'}}>
            <p style={{fontSize:'40px',fontWeight:'900',padding:0,margin:0}}>15</p>
            <p style={{fontSize:'20px',fontWeight:'700',padding:0,margin:0}}>Days</p>
            <p style={{fontSize:'10px',fontWeight:'600',padding:'2px 10px',margin:0,backgroundColor:'black',color:'white',borderRadius:'5px',marginTop:'5px'}}>Risk Free</p>
           </div>
          </Col>

          {/* Second Section */}
          <Col xs={12} md={9}>
            <div className="m-3">
              <h4 className='text-white font-weight-bold'>Try it for 15 days risk-free</h4>
              <p style={{color:'#ffffffc4'}}>Remember you don’t have to say YES right now. You only have to say MAYBE. Go through the materials for 15 days risk-free from the day of purchase. You can refund yourself anytime with a simple process. Don’t hassle, don’t worry. Just reach out through our <Link to={'/refund'} style={{color:'white',fontWeight:'bold',fontSize:'1rem'}}>Refund Page</Link> to know all the details.</p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RefundBanner;
