import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { useNavigate } from 'react-router-dom';
import { Card, Image } from 'react-bootstrap';
import TimerComp from '../../CoursePage/TimerComp';

function PriceBoxRem({ course }) {
    const [pricePlan, setPricePlan] = useState()
    const navigate = useNavigate();

  
    return (
        <Card style={{
            width: "350px", alignSelf: "center",
            height: "auto",
            // marginLeft: "auto", marginRight: (isTab || isBigMobile || isSmallMobile2) ? "auto" : "0",
            margin: "auto",
            borderRadius: "15px"
        }}
            className='newmemcard'
        >

            <Card.Img src={course.web_image} style={{ borderRadius: "10px 10px 0 0" }} />

            <Card.Body>


                <TimerComp datanew={course} setPricePlan2={setPricePlan} />

                <div className='container d-flex gap-4 mt-3'>
                    <strike style={{ color: "gray" }}><h2 style={{ color: "gray" }}>${course.price}</h2></strike>
                    <h2 style={{ color: "black" }}>
                        ${Math.floor((course.price) - (((course.discount_percentage || 0) * parseInt(course.price)) / 100))}
                    </h2>
                </div>
                {
                    course.discount_percentage && (
                        <div className="container">
                            <p>You will get {course.discount_percentage}% off</p>
                        </div>
                    )
                }

                <div className="container mt-2">

                    <button className='styledbtn23new' onClick={() => navigate(`/store/course/${course.course_id}`)}>Enroll Now</button>
                </div>
                <div style={{ margin: "20px 0 15px 0" }}>
                    <Image src={require("../../../images/visamastercard.png")} height={55} />
                </div>
            </Card.Body>
        </Card>

    )
}

export default PriceBoxRem