import React, { useMemo } from 'react';
import CustomNavbar from './CustomNavbar';
import { Badge, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import HeadingNew from '../AboutUs/HeadingNew';
import { FaLanguage, FaLayerGroup, FaStar, FaTrophy, FaVideo } from 'react-icons/fa';
import ParaComp from '../SilvaManifestationProgram/ParaComp';
import WhatIsSilvaMethodNew from '../CoursePage2/WhatIsSilvaMethodNew';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/AllContext';
import ReactStars from "react-rating-stars-component";
import { AttentionSeeker, Fade, Slide } from 'react-awesome-reveal';
import { useEffect } from 'react';
import { useState } from 'react';

function NewCourseSectionHero({ coursedata, isCurrentCoursePurchased, lastCourse, courseStatusData, scrollTo, scrollToNew }) {
    const navigate = useNavigate();
    const { isMobile, isMediumDesktop, isTab } = useContext(AuthContext);
    const { id } = useParams()
    const [courseLevel, setCourseLevel] = useState()





    const heroobj = useMemo(() => {
        if (isMobile) {
            return {

            }
        } else {
            return {
                // position: "absolute",
                // top: "50%",
                // transform: "translateY(-50%)"
            }
        }
    }, [isMobile])

    const herobackobj = useMemo(() => {
        if (isMobile) {
            return {

            }
        } else {
            return {
                backgroundImage: "url('/img/bg/Image20240911162939.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: 'auto',
                paddingBottom: "5rem",
                width: '100%',
                position: "relative",

            }
        }
    }, [isMobile])

    function getCourseLevel(course_id) {
        if (course_id === "silva-life-system-digital-audio") {
            setCourseLevel("Beginner")
        } else if (course_id === "silva-mind-body-healing-program" || course_id === "silva-method-manifesting-program") {
            setCourseLevel("Advanced")
        } else if (course_id === "silva-intuition-system-digital-audio-program") {
            setCourseLevel("Intermediate")
        } else if (course_id === "combo-plan") {
            setCourseLevel("Beginner to Advanced")
        } else {
            setCourseLevel("Beginner")
        }
    }


    // const handleScroll = (e) => {
    //     setIsNavbarVisible(e.target.scrollTop)
    //   };

    //   useEffect(() => {
    //     window.addEventListener('scroll', handleScroll, true);

    //     // Remove the event listener
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll, true);
    //     };
    //   }, []);

    useEffect(() => {
        getCourseLevel(id)
    }, [id])




    if (!coursedata) {
        return null
    }


    return (
        <div
            style={herobackobj}
        >
            <div className='d-flex justify-content-center align-items-center flex-column'>
                <div style={{

                    width: "100%",
                }}>
                    <CustomNavbar />

                </div>
            </div>
            <div className={isMobile ? '' : 'container'} style={{
                marginTop: isMobile ? "15px" : "3%"
            }}>
                <Row className='container align-items-center justify-content-center' style={{
                    marginTop: isMobile ? "20px" : "0px"
                }}>
                    <Col xs={12} md={8}>

                        <div style={heroobj}>

                            <HeadingNew
                                title={coursedata?.course_title}
                                white={!isMobile}
                                start
                            />





                            <div style={{ display: 'flex', gap: '10px' }}>
                                {/* Beginner Badge */}
                                <AttentionSeeker effect='pulse' triggerOnce={false} cascade damping={0.3}>
                                    <Badge className='my-2' pill bg='success' style={{
                                        display: 'flex', alignItems: 'center', padding: isMobile ? "5px 10px" : '10px 20px', fontSize: isMobile?"1rem":'1.2em', backgroundColor: "#23c483 !important"

                                    }}>
                                        <FaStar style={{ marginRight: '8px' }} color='#fff' size={isMobile?15:20} /> {courseLevel}
                                    </Badge>
                                </AttentionSeeker>
                            </div>


                            {
                                isMobile && (
                                    <div style={heroobj}>
                                        <img src={coursedata?.web_image} className='mt-2' style={{
                                            border: "2px solid white",
                                            borderRadius: "15px",

                                        }} />

                                    </div>
                                )
                            }

                            {
                                !isMobile && (
                                    <div className='my-3' style={{
                                        maxWidth: isMobile ? "100%" : "80%"
                                    }}>

                                        <ParaComp
                                            data={[
                                                <h5 className={isMobile ? 'robotofont' : 'robotofont white-color'} style={{
                                                    fontSize: "1rem",
                                                    lineHeight: "1.3rem",
                                                }}>
                                                    {
                                                        coursedata?.banner_description
                                                    }
                                                </h5>
                                            ]}

                                            // white={isMobile?false:true}
                                            margin={false}


                                        />

                                    </div>
                                )
                            }


                            {
                                !isMobile && coursedata && coursedata?.is_combo_course==="No" && (
                                    <div style={{ display: 'flex', justifyContent: 'start', width: isMobile ? "100%" : "auto", marginTop: "15px" }}>


                                        <Card style={{
                                            display: 'flex', flexDirection: 'row', backgroundColor: '#1e1e1e', padding: '20px 20px', borderRadius: '10px', maxWidth: "auto"

                                            , justifyContent: "start",
                                            alignItems: "center",
                                            margin: 0,
                                            flexWrap: "wrap",
                                            gap: isMobile ? "15px" : "0px"
                                        }}
                                            className='robotofont'
                                        >

                                            <div style={{ display: 'flex', alignItems: isMobile ? 'start' : 'center', marginRight: '30px', color: 'white' }}>
                                                <FaVideo color='white' size={30} style={{ marginRight: '10px' }} />
                                                <span><strong>{coursedata?.total_lessons}</strong> {" "} {coursedata?.total_lessons > 2 ? "Lessons" : "Lesson"}</span>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: isMobile ? 'start' : 'center', marginRight: '30px', color: 'white' }}>
                                                <FaLayerGroup color='white' size={25} style={{ marginRight: '10px' }} />
                                                <span><strong>{coursedata?.total_chapters}</strong> {" "} {coursedata?.total_chapters > 2 ? "Chapters" : "Chapter"}</span>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: isMobile ? 'start' : 'center', color: 'white' }}>
                                                <FaLanguage color='white' size={30} style={{ marginRight: '10px' }} />
                                                <span><strong>English</strong></span>
                                            </div>

                                        </Card>

                                    </div>
                                )
                            }
                            {
                                lastCourse && id === lastCourse.course_id && isCurrentCoursePurchased && (
                                    <div>
                                        <button className='cssbuttons-io-button4' style={{
                                            width: isMobile ? "100%" : "auto"
                                        }}
                                            onClick={() => {
                                                navigate(`/store/course/${lastCourse.course_id}/${lastCourse.chapter_id}/${lastCourse.lesson_id}`)
                                            }}

                                        >
                                            Last visited lesson :  <strong>
                                                {lastCourse.lesson_id.split("_").join(" ")}
                                            </strong>

                                        </button>

                                    </div>
                                )
                            }

                            {
                                courseStatusData && isCurrentCoursePurchased && (

                                    <div className='my-3' style={{
                                        width: isMobile ? "100%" : "50%"
                                    }} >
                                        <ProgressBar variant="success" now={courseStatusData.totalLessonsCompleted} max={courseStatusData.totalNumberOfLessons} />
                                        <h6 className='lastfourthcardheader2 mt-2' style={{
                                            fontSize: "1rem",
                                            color: isMobile ? "black" : "white"
                                        }}>
                                            {courseStatusData.totalLessonsCompleted} Lessons completed out of {courseStatusData.totalNumberOfLessons} Lessons
                                        </h6>

                                    </div>

                                )
                            }

                            {
                                !isCurrentCoursePurchased && (
                                    <div className={isMobile ? 'd-flex gap-3 mt-2 align-items-center flex-wrap' : 'd-flex gap-3 mt-4 align-items-center flex-wrap'}>
                                        <AttentionSeeker effect='pulse' triggerOnce={false} cascade damping={0.6} style={{
                                            width: isMobile ? "100%" : "200px"
                                        }} delay={500}>
                                            <button className='cssbuttons-io-button4 mt-2' style={{
                                                width: "100%"
                                            }}
                                                onClick={() => {
                                                    scrollTo()
                                                }}
                                            >
                                                Join Now

                                            </button>
                                        </AttentionSeeker>
                                        <AttentionSeeker effect='pulse' triggerOnce={false} cascade damping={0.7} delay={1000} style={{
                                            width: isMobile ? "100%" : "auto"
                                        }}>

                                            <button className='cssbuttons-io-button5' style={{
                                                width: "100%"
                                            }}
                                                onClick={scrollToNew}
                                            >
                                                Download Curriculum

                                            </button>
                                        </AttentionSeeker>


                                    </div>
                                )
                            }


                            {
                                isMobile && coursedata && coursedata?.is_combo_course==="No" && (
                                    <div style={{ display: 'flex', justifyContent: 'start', width: isMobile ? "100%" : "auto", marginTop: "15px" }}>


                                        <Card style={{
                                            display: 'flex', flexDirection: 'row', backgroundColor: '#1e1e1e', padding: '20px 20px', borderRadius: '10px', maxWidth: "auto"

                                            , justifyContent: "start",
                                            alignItems: "center",
                                            margin: 0,
                                            flexWrap: "wrap",
                                            gap: isMobile ? "15px" : "0px"
                                        }}
                                            className='robotofont'
                                        >

                                            <div style={{ display: 'flex', alignItems: isMobile ? 'start' : 'center', marginRight: '30px', color: 'white' }}>
                                                <FaVideo color='white' size={30} style={{ marginRight: '10px' }} />
                                                <span><strong>{coursedata?.total_lessons}</strong> {" "} {coursedata?.total_lessons > 2 ? "Lessons" : "Lesson"}</span>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: isMobile ? 'start' : 'center', marginRight: '30px', color: 'white' }}>
                                                <FaLayerGroup color='white' size={25} style={{ marginRight: '10px' }} />
                                                <span><strong>{coursedata?.total_chapters}</strong> {" "} {coursedata?.total_chapters > 2 ? "Chapters" : "Chapter"}</span>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: isMobile ? 'start' : 'center', color: 'white' }}>
                                                <FaLanguage color='white' size={30} style={{ marginRight: '10px' }} />
                                                <span><strong>English</strong></span>
                                            </div>

                                        </Card>

                                    </div>
                                )
                            }


                            {
                                isMobile && (
                                    <div className='mt-4' style={{
                                        maxWidth: isMobile ? "100%" : "80%"
                                    }}>

                                        <ParaComp
                                            data={[
                                                <h5 className={isMobile ? 'robotofont' : 'robotofont white-color'} style={{
                                                    fontSize: "1rem",
                                                    lineHeight: "1.3rem",
                                                }}>
                                                    {
                                                        coursedata?.banner_description
                                                    }
                                                </h5>
                                            ]}

                                            // white={isMobile?false:true}
                                            margin={false}


                                        />

                                    </div>
                                )
                            }





                            {
                                !isCurrentCoursePurchased && (
                                    <Fade direction='up' cascade damping={0.8} style={{
                                        width: "100%"
                                    }}>

                                        <div className={isMobile ? "d-flex gap-4 align-items-center justify-content-center flex-wrap" : "d-flex gap-4 align-items-center mt-4 flex-wrap"}>

                                            <div className={isMobile ? 'd-flex gap-2 justify-content-center align-items-center mt-2 flex-wrap' : 'd-flex gap-2 justify-content-center align-items-center mt-4 flex-wrap'}>

                                                <div class="avatars">
                                                    {
                                                        isMobile ? (
                                                            <>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>

                                                            </>
                                                        ) : (
                                                            <>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>
                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} style={{
                                                                    cursor: "pointer"
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Giselle.jpg" alt="" /></a>

                                                                <a onClick={() => {
                                                                    navigate("/success-stories")
                                                                }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/K.%20Galway.jpg" alt="" /></a>
                                                            </>
                                                        )
                                                    }


                                                </div>
                                                <h6 style={{
                                                    color: isMobile ? "black" : "white"
                                                }}>
                                                    Join 12M+
                                                    <br />
                                                    Active Students
                                                </h6>
                                            </div>



                                            <div style={{
                                                height: 20,
                                                width: "1px",
                                                background: "#fff"
                                            }}>

                                            </div>


                                            <div className='d-flex flex-column'>
                                                <h5 className={isMobile ? 'robotofont' : 'robotofont white-color'} style={{
                                                    fontWeight: "700",
                                                    margin: 0,
                                                    padding: 0,
                                                }}>{coursedata?.course_rating + "+"} <span style={{
                                                    fontWeight: "500"
                                                }}>({coursedata?.how_many_students}+ ratings)</span></h5>
                                                <ReactStars

                                                    count={5}
                                                    value={Number(coursedata?.course_rating)}
                                                    edit={false}
                                                    size={24}
                                                    activeColor="#ffd700"
                                                    classNames={"p-0 m-0"}
                                                />
                                            </div>





                                        </div>
                                    </Fade>
                                )
                            }
                        </div>
                    </Col>
                    {
                        !isMobile && (
                            <Col xs={12} md={4}>
                                <div style={heroobj} className='d-flex justify-content-end align-items-center'>
                                    <img src={coursedata?.web_vertical_image} style={{
                                        border: "2px solid white",
                                        borderRadius: "15px",
                                        maxWidth: (isMediumDesktop || isTab) ? "80%" : "95%"
                                    }} />

                                </div>
                            </Col>
                        )
                    }

                </Row>

            </div>




            {/* Add content inside the hero section if needed */}
        </div>
    );
}

export default NewCourseSectionHero;
