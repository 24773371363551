import React, { useContext } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import Button from './Button';
import "./Heart.css"
import { AuthContext } from '../../../context/AllContext';
import { IoCalendarOutline } from "react-icons/io5";

function LearningSectionLM1() {
    const {isMobile} = useContext(AuthContext);

    const handleScroll = () => {
        const cardscroll = document.querySelector(".lovemindpayment");
    
        if (cardscroll) {
          cardscroll.scrollIntoView({
            behavior: "smooth",
          });
        }
      };


    return (
        <div style={{
            marginBottom: "100px",
            marginLeft:"15px",
            marginRight:"15px"
        }}>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={6} className='order-2'>
                    <HeadingNew title={"“Love Mind” Live Masterclass"} start />
                    <p className='text-start mt-3 mb-3'>
                    Discover how to connect with the universe to find balance in your body, mind, and spirit. Listen closely to the universe's guidance, leading you to a place of peace and understanding.
                    </p>
                    
                    <div className='d-flex align-items-center gap-2 py-3'>
                        <IoCalendarOutline />
                        <h5 className='m-0 text-black'> 16th, 17th, 23rd & 24th March( Total 16 hrs.)</h5>
                    </div>
                    <div className='mt-3'>
                    <Button text={"Reserve your spot now"} onClick={handleScroll}/>
                    </div>

                </Col>
                <Col xs={12} md={6} className='order-1'>
                    <div style={{
                        position: "relative",
                        margin:"0 auto"
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            margin:"0 auto"
                        }}
                            className='heartbeat-animation'
                        >
                            <img src='/img/landingcombo/8.webp'
                                style={{
                                    width: "100%",
                                    height: "100%"
                                }}
                            />
                        </div>
                        <div style={{
                            
                            position: "absolute",
                            bottom: isMobile?20:80,
                            left: isMobile?50:100
                        }}>
                            <Card style={{
                                width: isMobile?"12rem":"15rem",
                                borderRadius: 20,
                                border: "none !important",
                                backgroundColor: "#FFA500",

                            }} className='cardmodi boxcard'>
                                <Card.Body style={{
                                    background: "#b31d9d",
                                    border: "2px solid #FFA500",
                                    borderRadius: 20

                                }}>
                                    {/* <div className='d-flex justify-content-center align-items-center'>
                            <img src='/img/icon/star.png' style={{
                                width:40,
                                height:40,
                                objectFit:"cover"
                            }} />
                            <div className='d-flex flex-column justify-content-center align-items-center flex-wrap'>
                                <h4 className='text-dark'>90,000 Students</h4>
                                <p>long_relax_meditation_silva_alpha_sound</p>

                            </div>

                        </div> */}

                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                        <div className='d-flex justify-content-center align-items-center gap-3'>
                                            <img src='/img/icon/star.png' style={{
                                                width: isMobile?20:40,
                                                height: isMobile?20:40,
                                                objectFit: "cover"
                                            }} />
                                           {
                                            isMobile?(<h6 className='text-dark mt-1 white-color' style={{
                                                textDecoration: "underline",
                                                textDecorationColor: "white",
                                                textUnderlineOffset: 7
                                            }}>90,000 Students</h6>):(
                                                <h5 className='text-dark mt-1 white-color' style={{
                                                    textDecoration: "underline",
                                                    textDecorationColor: "white",
                                                    textUnderlineOffset: 7
                                                }}>90,000 Students</h5>
                                            )
                                           }
                                        </div>
                                        <h5 style={{
                                            alignSelf:"flex-start",
                                            color:"white",
                                            fontSize:12,
                                            marginTop:10
                                        }}>Achieved their goals</h5>

                                    </div>


                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </Col>
            </Row>
        </div>
    )
}

export default LearningSectionLM1
