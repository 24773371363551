import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import { TypeAnimation } from 'react-type-animation';

function HeroSectionLandingOne() {
    const { isMobile } = useContext(AuthContext);

    const [imageIndex, setImageIndex] = useState(0);
    const images = [
        "/img/landingcombo/1.jpg",
        "/img/landingcombo/4.jpg",
        "/img/landingcombo/3.jpg"
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const handleScroll = (name) => {
        const descard = document.querySelector(name);
    
        if (descard) {
          descard.scrollIntoView({
            behavior: "smooth",
          });
        }
      };


    return (
        <div style={{
            width: "100%",
            position: "relative",
            height: isMobile ? "60vh" : "80vh"
        }}>
            <div style={{
                position: "absolute",
                left: isMobile ? "2%" : "10%",
                top: isMobile ? "15%" : "30%",
                padding: isMobile ? "10px" : "0px",
                zIndex: 2,
                width: isMobile ? "96%" : "40%"
            }}>
                <HeadingNew title={"Unlock the Power of Spiritual Healing with "} white start />
                <TypeAnimation
                    sequence={[
                        'Silva Method Courses',
                        500,
                        'Silva Method Courses', //  Continuing previous Text
                        500,
                        'Silva Method Courses',
                        500,
                        'Silva Method Courses',
                        500,
                        'Silva Method Courses',
                        500,
                        '',
                        500,
                      ]}
                    wrapper="span"
                    
                    style={{ fontSize: isMobile?"2em":'3.5em', display: 'inline-block',fontWeight:"bold",color:"#FFC815" }}
                    repeat={Infinity}
                />
                {
                    isMobile ? (
                        <p className='my-3 white-color' style={{
                            lineHeight: isMobile ? "22px" : "inherit"
                        }}>
                            Dive into our Online Spiritual Courses and discover transformative energy healing techniques and spiritual growth with Silva Method's top-rated programs.
                        </p>
                    ) : (
                        <p className='my-3 white-color'>
                            Dive into our Online Spiritual Courses and discover transformative energy healing techniques and spiritual growth with Silva Method's top-rated programs.
                        </p>
                    )
                }

                <button className='primary_btn3' style={{
                    backgroundImage: "linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%)",
                    color: "black",
                    fontWeight: "600"
                }}
                onClick={()=>handleScroll('.includelivecheck')}
                >
                    Enroll Now

                </button>

            </div>

            {images.map((imageUrl, index) => (
                <img
                    key={index}
                    src={imageUrl}
                    alt={`Slide ${index + 1}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: index === imageIndex ? 1 : 0,
                        opacity: index === imageIndex ? 1 : 0,
                        transition: 'opacity 1s ease-in-out',
                    }}
                />
            ))}

            <div style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 3,
            }}>
                {images.map((_, index) => (
                    <span
                        key={index}
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: index === imageIndex ? "#fff" : "rgba(255, 255, 255, 0.5)",
                            margin: "0 5px",
                            cursor: "pointer"
                        }}
                        onClick={() => setImageIndex(index)}
                    />
                ))}
            </div>
            <div style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                background: isMobile ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.2)",
                zIndex: 1
            }}>

            </div>

        </div>
    )
}

export default HeroSectionLandingOne