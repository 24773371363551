import React, { useContext } from 'react';
import { Card, Image } from 'react-bootstrap';
import { BiPlayCircle } from 'react-icons/bi';
import { AuthContext } from '../../../../context/AllContext';

function TypesMeditateCard3({ item, color, imgSrc, black = false, reversed = false,altTag="" }) {
  const { isMobile } = useContext(AuthContext);

  return (
    <div
      className={isMobile ? 'card mb-2 shadow-sm mx-auto' : 'card mb-4 shadow-sm mx-auto'}
      style={{
        background: color,
        transition: 'all 0.3s ease',
        border: 'none',
        borderRadius: '25px',
        padding: isMobile ? '10px' : '20px',
        overflow: 'hidden',
        width: isMobile ? '96%' : '95%',
        height: 'auto',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-5px)';
        e.currentTarget.style.boxShadow = '0 10px 20px rgba(0,0,0,0.1)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)';
      }}
    >
      <div
        className={`d-flex flex-${reversed ? 'row-reverse' : 'row'} align-items-center ${isMobile?' flex-wrap':''}`}
        style={{ gap: '20px' }}
      >
        {/* Text Section */}
        <div className="card-body">

        {imgSrc && isMobile &&  (
          <div style={{
            flex:"none",
            marginBottom:"15px"
          }}>
            <Image
              src={imgSrc}
              alt={altTag}
              style={{
                width: isMobile ? '90vw' : '300px',
                objectFit:"cover",
                borderRadius: '15px',
              }}
            />
          </div>
        )}
          <h2
            style={{ color: black ? 'black' : 'white', textAlign: 'start', fontSize: isMobile?"1.5rem":'2rem' }}
            className="lora-sans"
          >
            {item.title}
          </h2>
          <p
            className={
              black ? 'robotofont mt-3 highlight-container' : 'robotofont mt-3 highlight-container white-color'
            }
            style={{ fontSize: '1rem' }}
          >
            {item.content}
          </p>
          {item.buttonText && (
            <button className="btn btn-warning">
              <span>
                <BiPlayCircle color="black" style={{ marginRight: '5px' }} />
              </span>
              {item.buttonText}
            </button>
          )}
        </div>

        {/* Image Section */}
        {imgSrc && !isMobile &&  (
          <div style={{
            flex:"none"
          }}>
            <Image
              src={imgSrc}
              alt={altTag}
              style={{
                width: isMobile ? '90vw' : '300px',
                objectFit:"cover",
                borderRadius: '15px',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TypesMeditateCard3;
