import React, { useContext, useState, useEffect } from "react";
import { Alert, Badge, Card, Col, Container, Image, Row } from "react-bootstrap";
import { MdAccessTime } from "react-icons/md";
import { AuthContext } from "../../../context/AllContext";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";

function ZoomDetails() {
  const { isMobile } = useContext(AuthContext);

  const [meeting, setMeeting] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disableBtn, setDisableButton] = useState(true);

  // Fetch meeting details when the component mounts
  useEffect(() => {
    const fetchMeeting = async () => {
      try {
        const response = await axios.post("https://silvacasesonline.com:5000/api/v1/admin/private/getMeeting");
        setMeeting(response.data); // Set the meeting data in the state

        // Check if the meeting date matches the current date
        const meetingDate = moment(response.data.nextMeetingDate).format("YYYY-MM-DD");
        const currentDate = moment().format("YYYY-MM-DD");

        // Disable the button if the current date doesn't match the meeting date
        if (meetingDate !== currentDate) {
          setDisableButton(true);
        } else {
          setDisableButton(false);
        }
      } catch (err) {
        setError(err.response ? err.response.data : "Error fetching meeting");
      } finally {
        setLoading(false);
      }
    };

    fetchMeeting();
  }, []);

  // Button click handler
  const buttonClick = () => {
    if (!meeting) return;

    if (!disableBtn) {
      window.open(meeting.meetingLink);
    } else {
      Swal.fire({
        title: "Meeting Not Active",
        text: "The meeting is not scheduled for today. Please check the meeting date.",
        icon: "warning",
      });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  if (meeting && meeting.isMeetingActive === false) {
    return null;
  }

  return (
    <Container>
      <Card className="w-100 p-4 m-0" style={{ borderRadius: "15px", backgroundImage: "url('/img/bg/bglovemind.jpg')" }}>
        <Row>
          <Col sm={12} md={12} lg={6} className="px-2">
            <div className="w-100 h-100">
              <Image src="/img/live_interaction_image_recoding.jpg" style={{ width: "100%", height: "100%", borderRadius: "15px" }} />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="px-2 d-flex flex-column justify-content-center">
            <Alert.Heading style={{ color: "white" }} className="my-3" as={isMobile ? "h4" : "h3"}>
              Upcoming <Badge bg="danger">Live</Badge> class information
            </Alert.Heading>
            <div>
              <p style={{ fontWeight: "500", fontSize: isMobile ? "15px" : "20px", color: "white" }}>
                Topic: {meeting?.topic}
              </p>
            </div>
            <div>
              <p style={{ fontWeight: "500", fontSize: isMobile ? "15px" : "20px", color: "white" }}>
                Date: {moment(meeting?.nextMeetingDate).format('dddd, Do MMM YYYY')}
              </p>
            </div>
            <div>
              <div className="d-flex gap-3 flex-wrap mt-3 border border-light py-2 rounded" style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
                <div className="border-end px-2 border-light d-flex flex-column justify-content-center align-items-center gap-3">
                  <MdAccessTime color="#fff" />
                  <h6 className="text-white">EST - {meeting?.timeZones?.est}(USA)</h6>
                </div>
                <div className="border-end px-2 border-light d-flex flex-column justify-content-center align-items-center gap-3">
                  <MdAccessTime color="#fff" />
                  <h6 className="text-white">PST - {meeting?.timeZones?.pst}(USA)</h6>
                </div>
                <div className="border-end px-2 border-light d-flex flex-column justify-content-center align-items-center gap-3">
                  <MdAccessTime color="#fff" />
                  <h6 className="text-white">London - {meeting?.timeZones?.london}</h6>
                </div>
                <div className=" border-light px-2 d-flex flex-column justify-content-center align-items-center gap-3">
                  <MdAccessTime color="#fff" />
                  <h6 className="text-white">IST - {meeting?.timeZones?.ist}</h6>
                </div>
              </div>
            </div>
            <div className="my-3">
              <button
                className="cssbuttons-io-button"
                style={{ padding: "10px", cursor: disableBtn ? "not-allowed" : "pointer" }}
                onClick={buttonClick}  // Alert will be shown if disabled
              >
                Join Zoom Meeting
              </button>
            </div>
            <p className="text-white">
              Button link will be active on {moment(meeting?.nextMeetingDate).format('dddd, Do MMM YYYY')} (EST- {meeting?.timeZones?.est}(USA) / PST- {meeting?.timeZones?.pst}(USA) / London- {meeting?.timeZones?.london} / IST- {meeting?.timeZones?.ist})
            </p>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default ZoomDetails;
