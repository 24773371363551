import React, { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AllContext';

const meditationTypes = [
  {
    title: "Morning Meditation",
    content: "Starting your day with morning meditation can bring clarity and calmness, setting a positive tone for the rest of your day. A 5-minute morning meditation is perfect for those short on time but still seeking a moment of peace before diving into their daily activities. By practicing daily calm morning meditation, you allow your mind to gently wake up and align with the present moment, fostering focus and reducing stress. For those who want a deeper experience, a 15-minute morning meditation offers more time to connect with your breath and relax your thoughts. Whether you choose a 5 min morning meditation or opt for a longer session, the benefits are profound— improved mood, increased mindfulness, and a clear mind to tackle your goals. Incorporating morning meditation into your routine, even for just five minutes, can transform your mornings and help you approach each day with a refreshed, centered mindset."
  },
  {
    title: "Sleep Meditation",
    content: "Sleep meditation is designed to help people fall into a deep, restful sleep. With the fast-paced demands of modern life, sleep can often be disrupted by stress and overthinking. Sleep meditation, especially those that include guided meditation for sleep or relaxing meditation sounds, works to calm the mind and body, allowing a natural transition to sleep. Popular techniques like progressive relaxation and visualization are often incorporated to ensure better rest. A well-rested body and mind are crucial for productivity and overall well-being. This form of meditation addresses one of the most common challenges people face—restlessness at night. Many opt for free sleep meditation apps or free meditation podcasts that are readily available, making them accessible and easy to incorporate into daily life. With consistent practice, sleep meditation can significantly improve sleep quality."
  },
  {
    title: "Meditation for Self-Love and Healing",
    content: "In times of emotional or physical distress, meditation for self-love and healing helps us reconnect with our inner strength. At times, the most important person we neglect is ourselves. Meditation for self-love and healing is an essential practice that helps nurture our emotional well-being, rebuild confidence, and promote personal growth. It focuses on fostering positive affirmations, cultivating self-compassion, and healing emotional wounds through mindful reflection. This type of meditation often uses affirmations and visualizations to foster a deep sense of inner peace. Phrases like “I am Worthy”, “I am Enough”, or imagining a bright healing light enveloping your body are common in these practices. It’s a powerful practice for those looking to recover from heartbreak, trauma, or general feelings of inadequacy. The consistent practice of meditation for self-love and healing leads to stronger emotional resilience and a more positive outlook on life."
  },
  {
    title: "10-Minute Calming Meditation",
    content: "For those pressed for time, a 10-minute calming meditation can make all the difference. You don't need to spend hours sitting in silence to feel the benefits of meditation. A focused 10-minute calming meditation can reset your mood, improve concentration, and help reduce stress in just a few minutes. This makes it an excellent option for busy professionals, students, or parents who can’t dedicate longer periods to meditative practices. The key here again is consistency rather than duration. Practicing a 10-minute calming meditation daily builds a habit that can transform your day, helping you handle stress with greater ease and boosting your emotional well-being. You can also pair this with relaxing meditation sounds for a heightened experience."
  },
  {
    title: "Guided Meditation",
    content: "Guided meditation is one of the most accessible ways to begin your meditation journey, especially for those new to the practice. With a guide—either live or through recordings—you’re led through the meditation step-by-step. This structured approach removes any uncertainty, making it easier for beginners to stay focused and understand how to start meditation without feeling overwhelmed. You can find free guided meditations on various apps or podcasts, offering different themes to suit your needs. Whether you are looking for a quick 10-minute guided meditation for relaxation or a longer session for self-growth, these meditations can help you explore new techniques and deepen your practice. Guided meditation for beginners is an excellent way to build a solid foundation, allowing you to experience the benefits of mindfulness and calm with minimal effort."
  }
];

const MeditationTypesSection = () => {
  const { isMobile } = useContext(AuthContext)
  

  return (
    <div className="meditation-section py-5">
      <div className='container'>
        <h3 style={{
          color: "white",
          fontSize: "2.5rem",
          textAlign: "center",
          lineHeight: "3rem"
        }}
          className='lora-sans'
        >
          Most Popular Forms of{" "}
          <span style={{ color: '#ffea00' }}>
            Meditation{" "}
          </span>
          <br />
          by Human Needs
        </h3>

        <p className="robotofont white-color text-center my-3" style={{
          fontSize: isMobile ? "1rem" : "1.1rem"
        }}>
          So far we have explored the various types of meditation, however, it is also important to notice that meditation has evolved into various forms, each designed to address specific human needs such as stress relief, self-care, or improved sleep. With growing awareness of mental and emotional well-being, people turn to different types of meditation practices tailored to help them relax, heal, and recharge. Meditation practices like sleep meditation, meditation for self-love and healing, 10-minute calming meditations, and guided meditation have gained immense popularity due to their practical benefits and ease of practice. These meditations not only offer relaxation and peace but also promote emotional and physical well-being. Below are some of the most popular forms of meditation, each serving a unique purpose based on the needs of the practitioner.


        </p>
        <div className="meditation-grid">
          {meditationTypes.map((type, index) => (
            <div key={index} className="meditation-card">
              <h4 style={{
                color: "#ffea00",
                fontSize: "1.8rem",
                textAlign: "start",

              }}
                className='lora-sans'>{type.title}</h4>
              <p className={'expanded robotofont white-color text-start my-3'} style={{
                fontSize: isMobile ? "1rem" : "1.1rem"
              }}>
                {type.content}
              </p>
            
            </div>
          ))}
        </div>
        <p className="robotofont mt-3 highlight-container text-start text-white" style={{ fontSize: '1.1rem', fontWeight: "400" }}>
          Meditation offers a variety of practices tailored to meet specific human needs, whether it's calming the mind for better sleep, nurturing self-love, or simply finding a few minutes of peace amidst a busy day. However, a great starting point for anyone new to meditation is guided meditation for beginners. Stay tuned as we dive deeper into this topic and explore how guided meditation for beginners can help you build a strong foundation for your meditation journey.
        </p>

      </div>
      <style jsx>{`
        .meditation-section {
           background: linear-gradient(to right, rgb(91, 33, 182), rgb(55, 48, 163));
         
          color: white;
          font-family: Arial, sans-serif;
        }
        h1 {
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1rem;
        }
        .intro {
          font-size: 1.1rem;
          text-align: center;
          max-width: 800px;
          margin: 0 auto 2rem;
        }
        .meditation-grid {
          display: grid;
          gap: 1.5rem;
          grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        }
        .meditation-card {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 1rem;
          padding: 1.5rem;
          backdrop-filter: blur(5px);
          transition: background-color 0.3s ease;
        }
        .meditation-card:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        .meditation-card h2 {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .meditation-card p {
          font-size: 0.9rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          transition: all 0.3s ease;
        }
        .meditation-card p.expanded {
          -webkit-line-clamp: unset;
        }
        .toggle-btn {
          background: none;
          border: none;
          color: white;
          font-size: 0.9rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 0;
          margin-top: 1rem;
        }
        .icon {
          width: 1rem;
          height: 1rem;
          margin-left: 0.25rem;
        }
      `}</style>
    </div>
  );
};

export default MeditationTypesSection;