import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import homeimg from "../../images/homeimages/jose-silva-all-photo-2048x1024.webp"
import { Col, Row } from "react-bootstrap";
import HomeHeading from "../Home/Home/HomeHeading";
const AboutSilvaMethod = () => {
  const [text] = useState("Discover the transformative power of The Silva Method,  Silva's groundbreaking research that became America’s first personal growth program since 1966. For over five decades, The Silva Method has impacted millions of lives worldwide, including celebrities, CEOs, and everyday individuals seeking personal breakthroughs. By developing practical mental reprogramming techniques, Jose Silva revolutionized our understanding of the mind. Through mindfulness training and relaxation meditation, our mission is to continue spreading the practices using which you can control and program your mind, leading to improved health, focus, and the fulfillment of your extraordinary potential. Begin your journey to unlock your extraordinary potential and experience the transformative power of Silva today.")
  const [readMore, setReadMore] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1440px)' });
  const isSmallDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });

  return (
    <>

      <div
        className={
          isDesktopOrLaptop
            ? "text-center mt-5 w-50 m-auto p-2 mb-3"
            : "text-center mt-2 p-3"
        }
      >
        <div>


          {/* <h2
            style={{
              fontWeight: "800",
              fontSize: isDesktopOrLaptop ? "60px" : isSmallDesktop ? "50px" : isTablet ? "40px" : "30px",
              margin: "0 0 0 20px",
              textAlign: "center"
            }}
          >
            <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>José Silva</span>
            - The Trailblazer & Inventor of Silva Mind Control
          </h2> */}
          <HomeHeading title="" colored="José Silva" title2="- The Trailblazer & Inventor of the Silva Method"/>
          {/* <h2
            style={{
              fontWeight: "800",
              fontSize: isDesktopOrLaptop ? "60px" : "30px",
            }}
          >
            The <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Mind</span>{" "} Behind Silva’s Revolutionary Techniques

          </h2> */}
          <h4 className="mt-3 text-muted">
            Transforming lives since 1966
          </h4>

        </div>


      </div>
      <>
        {/* <img src={homeimg} /> */}

      </>
      {/* <div
        className={
          isDesktopOrLaptop
            ? "text-center mt-5 w-50 m-auto p-2 mb-3"
            : "text-center mt-2 p-3"
        }
      > */}
      <div className="container mb-5">
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} sm={12} md={6}>
            <img src={homeimg} />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <div className="mx-3">
              {
                !readMore && (
                  <div>
                    <p style={{ fontSize: isDesktopOrLaptop ? "18px" : "15px", paddingTop: '15px', paddingBottom: '15px', marginBottom: "0" }}>
                      {text.substring(0, 398) + " "}
                      <span style={{ color: "purple", border: "none",fontWeight:"800", }} onClick={() => setReadMore(true)}>Read More</span>
                    </p>
                  
                    
                  </div>
                )
              }
              {
                readMore && (
                  <div>
                    <p style={{ fontSize: isDesktopOrLaptop ? "18px" : "15px", paddingTop: '15px', paddingBottom: '15px', marginBottom: "0" }}>
                      {text}
                    <span style={{ color: "purple", border: "none",fontWeight:"800",marginLeft:"7px" }} onClick={() => setReadMore(false)}>Read Less</span>
                    </p>
                  </div>
                )
              }
              <div className="d-flex justify-content-center align-items-center" style={{ marginTop: isDesktopOrLaptop ? "0px" : isSmallDesktop ? "0px" : isTablet ? "10px" : "15px" }}>
                <Link
                  to="/jose-silva-method"
                  style={{
                    maxWidth: isSmallDesktop || isDesktopOrLaptop ? "50%" : "70%",
                  }}

                >
                  <button className="styledbtn23">Read More About Jose Silva</button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* </div> */}
    </>
  );
};

export default AboutSilvaMethod;
