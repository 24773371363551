import React, { useMemo } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import Tilt from 'react-parallax-tilt';
import Button from '../One/Button';
import CountUp from 'react-countup';

function AboutInstructorSectionNew() {

    const { isMobile } = useContext(AuthContext);
    const list = useMemo(() => {
        return [
            "For 36 years Dragan has been a prominent figure in personal development, recognised as a sought-after  mental coach and lecturer",
            "With an extensive resume, Dragan has conducted more than 500 live seminars with more than 50 thousand participants, together with countless online courses for public and top company clients",
            "His list of company clients includes Motorola, Deloitte, Amway, GE Money Bank, Just of Switzerland,etc.",
            "As a mental coach in sports Dragan trained teams like A.C. Sparta (soccer), Czech National Soccer Team-U-21, Czech National Hockey Team U-20 with numerous individual trainings in tennis, basketball, golf and ice-hockey. For example, Jakub Mensik, with whom Dragan is working continuously as a mental coach is the best ranked young player on the world ATP list ( 18 yrs, no. 147).",
            "Dragan is an author of 63 products in personal development and mental training, wth topics like ‘ Love Mind’, ’Super Health All Life Long’, ‘ Wealth is Your Natural Right’,etc.",
            "Dragan has been highly sought after as an individual coach in one-on-one mental training. His focus spans marital harmony, health improvement, sales performance, limiting habits change and more."
        ]
    })
    const handleScroll = () => {
        const doc = document.querySelector(".lovemindformscroll");
        if (doc) {
            doc.scrollIntoView({
                behavior: "smooth"
            })
        }
    }
    return (
        <Container className='py-5'>





            <Row className='justify-content-center align-items-center my-5' style={{
                margin: "auto"
            }}>
                <Col xs={12} lg={6} className={isMobile ? 'order-md-2' : 'order-md-2'}>
                    <div className='d-flex justify-content-end align-items-center'>
                        <img
                            className='boxshadow'
                            src='/img/unstressimges.jpg'
                            style={{
                                width: isMobile ? "100%" : "90%",
                                border: "1px solid lightgray",
                                boxShadow: "10px 10px 5px -7px rgba(0,0,0,0.23)",
                                borderRadius: "8px",
                                marginBottom: "15px"
                            }}
                        />
                    </div>

                </Col>
                <Col xs={12} lg={6} className='order-md-1'>
                    <HeadingNew title={"Meet Your"} colored={"Instructors"} start />

                    <HeadingNew small title={"DraganV: A Renowned Silva Method Instructor"} start />

                    <p className='mt-2' style={{
                        fontWeight: "500"
                    }}>
                        Dragan Vujovic is a Certified Silva Method Instructor. He stands as a towering figure in the realm of personal development and mental coaching. With an illustrious career spanning 36 years, he has established himself as a legendary figure in the field. He and Jose Silva, the founder of the Silva Method, were close friends, and he received direct training from him. Dragan has conducted 500+ live seminars with 50K+ participants and offers numerous online courses for both public and corporate clients. He has authored 63 products on personal development and mental training, including topics like ‘The Unstress Toolbox', 'Wealth is Your Natural Right', and ‘Self-Change as the Key to Success’, etc. He provides group and personal mental training for sales, management, relationships, personal growth, sports, health, communication, learning, and stress management. DraganV has developed 'Top Mental Training', a unique system for maximizing brain usage. As an expert relationship development and self-esteem mentor, He launched the 'Love Mind', a specialized product aimed at discovering the deepest principles and laws that regulate all of our relationships, fostering an understanding of our connection with the universe, and attaining inner peace.

                    </p>
                    <div className='d-flex gy-3 flex-wrap'>
                        <div className='d-flex flex-column' style={{
                            marginRight: "30px"
                        }}>
                            <CountUp end={400} duration={2.5} suffix="K+" style={{
                                color: "#000",
                                fontSize: "2.5rem",
                                fontWeight: "bold"
                            }} />
                            <p style={{
                                color: "black",
                                fontSize: "1.2rem"
                            }}>Life Transformations</p>

                        </div>
                        <div className='d-flex flex-column' style={{
                            marginRight: "30px"
                        }}>
                            <CountUp end={500} duration={1.5} suffix="+" style={{
                                color: "#000",
                                fontSize: "2.5rem",
                                fontWeight: "bold"
                            }} />
                            <p style={{
                                color: "black",
                                fontSize: "1.2rem"
                            }}>Live Courses</p>

                        </div>
                        <div className='d-flex flex-column' style={{
                            marginRight: "30px"
                        }}>
                            <CountUp end={60} duration={1} suffix="+" style={{
                                color: "#000",
                                fontSize: "2.5rem",
                                fontWeight: "bold"
                            }} />
                            <p style={{
                                color: "black",
                                fontSize: "1.2rem"
                            }}>Programs</p>

                        </div>

                    </div>
                    <Button text={"Join for free"} onClick={handleScroll} />

                </Col>

            </Row>

        </Container>
    )
}

export default AboutInstructorSectionNew