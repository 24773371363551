import React from 'react'
import { Card } from 'react-bootstrap'
import Collapsible from 'react-collapsible'
import { AiOutlineUp,AiOutlineDown } from "react-icons/ai";
import { FaChevronDown,FaChevronUp } from "react-icons/fa";
import "./faq2.css"
import { useState } from 'react';
import { useEffect } from 'react';
function FAQ222({heading,body,offShadow}) {

    const [opened,setOpened] = useState(false);

    return (
        <div className='my-3'>
            <Collapsible trigger={<FAQheader opened={opened} heading={heading} offShadow={offShadow}/>}
            transitionTime={300}
            transitionCloseTime={300}
            onOpening={()=>setOpened(true)}
            onClose={()=>setOpened(false)}
            openedClassName='openedcollapse'
            easing='ease-out'
            >
                <FAQbody body={body} />
            </Collapsible>
        </div>
    )
}

export default FAQ222


function FAQheader({opened,heading,offShadow}) {

    return (
        <div className={`faqaccordionheader ${opened?'openheader22':''}`} style={{boxShadow: offShadow?"none":""}}>
            <h4 style={{ margin: "0px", padding: "0px",color:opened?'#fff':'#000',fontSize:"1rem" }}>
                {heading}
            </h4>
            <span>
                {
                    opened ? (
                        <FaChevronUp color={opened?'white':'black'} />
                    ):(
                        <FaChevronDown color={opened?'white':'black'} />
                    )
                }
            </span>
        </div>
    )
}

function FAQbody({body}) {
    return (
        <div className='faqaccordioncontent22'>
            {body}
        </div>
    )
}

