import React from 'react'

import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'

import OtherSections from './OtherSections'

import CustomFooter from '../../Home/Home/HomeFooter/Footer'

function SciencePage() {
  return (
    <div>

      <HomeHeader />

      <div className='container pt-5'>
        <OtherSections />
      </div>
      <CustomFooter />
    </div>
  )
}

export default SciencePage