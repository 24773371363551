import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";
import DayCardTwo from "../Draganpages/DayCardTwo";
import DayCardThree from "./DayCardThree";

function VideoTableSection3() {
  const { isMobile,isTablet } = useContext(AuthContext);

 


  const day1 = [
    [
        "Introduction",
        "Transform worries and tension",
        "Eliminate Guilt",
        "Deep relaxation"
    ],
    [
        "Stress management",
        "Improve focus and concentration",
    ],
    [
        "Inner peace",
        "Accepting abundance",
        "Practice Session",
    ],
   
  ];



  return (
    <div className="pb-5 d-flex justify-content-center align-items-center flex-column">
    
      <div className="container">
        <h3 className={`newpageheader text-center pb-${(isMobile || isTablet)?0:5}`}>
          Explore the Course Curriculum
        </h3>
        <div className="d-flex justify-content-center align-items-center flex-column">
            {day1.map((val, i) => (
              <DayCardThree data={val} index={i + 1} />
            ))}
          </div>
 
       
      </div>
    </div>
  );
}

export default VideoTableSection3;
