import React from 'react'
import { useEffect } from 'react';
import MeditateBegineerHeroSection from './MeditateBegineerHeroSection';
import HomeHeader from '../../../Home/Home/HomeHeader/HomeHeader';
import OtherMeditateSections from './OtherMeditateSections';
import "./blog.css"
import BeginnerSection from './BeginnerSection';
import StartMeditationComp from './StartMeditationComp';
import MeditationMistakesCard from './Preview';
import CustomFooter from '../../../Home/Home/HomeFooter/Footer';
import MeditationTypesSection from './MeditationTypes';


function BlogLandingPage1() {
 


  return (
    <>
     <div>
      <HomeHeader />

      
      <MeditateBegineerHeroSection />
      



      <div style={{
        width:"100%",
        height:"1px",
        background:"lightgrey"
      }}>

      </div>

      <OtherMeditateSections />
      <MeditationTypesSection />

      <BeginnerSection />
      <StartMeditationComp />
     <div className='container'>
     <MeditationMistakesCard />


     </div>


      
    </div>
    <CustomFooter />
    </>
  )
}

export default BlogLandingPage1