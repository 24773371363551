import React from "react";
import "./products.css";
//import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function SingleHomeProducts2({ data }) {
  // console.log(data);
  const navigate = useNavigate();
  const handleNavigate = () => {
    window.open(data?.permalink);
  };
 
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {data?.name}
    </Tooltip>
  );
  return (
    <div className="uniquepro m-4">
      <main>
        <div className="book-card">
          <div className="book-card__cover">
            <div className="book-card__book">
              <div className="book-card__book-front">
                <img className="book-card__img" src={data?.images[0]?.src} />
              </div>
              <div className="book-card__book-back"></div>
              <div className="book-card__book-side"></div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="book-card__title">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <h6
                style={{
                  width: "150px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginTop: "10px",
                }}
              >
                {data?.name?.length < 30
                  ? data?.name?.substring(0, 30)
                  : data?.name?.substring(0, 30) + "..."}
              </h6>
            </OverlayTrigger>
            </div>

            <div dangerouslySetInnerHTML={{ __html: data?.price_html }} />

            {/* <div className="book-card__author">
                            By {data.author || "Silva International"}
                        </div> */}
            <button
              className="primary_btn3"
              style={{ position: "absolute", top: "75px" }}
              onClick={handleNavigate}
            >
              View Details
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SingleHomeProducts2;
