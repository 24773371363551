import React, { useContext } from 'react'
import { AuthContext } from '../../../../context/AllContext'
import { ImFilePlay } from "react-icons/im";
import { Card, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HeadingNew from '../../../AboutUs/HeadingNew';

function ThirdHeroSection() {
    const { isDesktopOrLaptop, isBigScreen, isMobile,userData } = useContext(AuthContext)
    const navigate = useNavigate()
    return (
        <div className='thirdsectionhero' style={{paddingTop:"50px",paddingBottom:"50px"}}>
            {/* <h2 className='mb-3' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop || isBigScreen ? "60px" : "30px", textAlign: "center" }}>
                Complete Silva <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Method</span> Program
            </h2>
            <h2 className='mb-3' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop || isBigScreen ? "40px" : "25px", textAlign: "center" }}>
                Home Study Programs
            </h2> */}
            <div>
                <HeadingNew title={"Silva Method "} colored={"Home Study Programs"}/>
            </div>

            <div className="socialmediaherosection mt-4">
                <Card style={{ width: isMobile ? "96%" : "fit-content", padding: "12px 25px", borderRadius: "20px" }}>
                    <Card.Body className='d-flex justify-content-center align-items-center gap-4'>
                        <div>
                            <div className='bluebox'>
                                <div className="innerdiv d-flex justify-content-center align-items-center" style={{ fontSize: "2rem",paddingTop:"25px",paddingBottom:"25px" }}>
                                    <span className='white-color'>
                                        <Image src='/img/courseimgs/courseicons/icon_full_course.svg' style={{width:"50px",height:"50px"}}/>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-end'>
                                <h3 className='ratinghead'>04</h3>

                            </div>
                            <h4 className='ratingchapp'>Full Course</h4>

                        </div>
                    </Card.Body>
                </Card>

                <Card style={{ width: isMobile ? "96%" : "fit-content", padding: "12px 25px", borderRadius: "20px" }}>
                    <Card.Body className='d-flex justify-content-center align-items-center gap-4'>
                        <div>
                            <div className='bluebox'>
                                <div className="innerdiv d-flex justify-content-center align-items-center" style={{ fontSize: "2rem" }}>
                                    <span className='white-color'>
                                    <Image src='/img/courseimgs/courseicons/icon_chapters.svg' style={{width:"50px",height:"50px"}}/>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-end'>
                                <h3 className='ratinghead'>45+</h3>

                            </div>
                            <h4 className='ratingchapp'>Chapters</h4>

                        </div>
                    </Card.Body>
                </Card>

                <Card style={{ width: isMobile ? "96%" : "fit-content", padding: "12px 25px", borderRadius: "20px" }}>
                    <Card.Body className='d-flex justify-content-center align-items-center gap-4'>
                        <div>
                            <div className='bluebox'>
                                <div className="innerdiv d-flex justify-content-center align-items-center" style={{ fontSize: "2rem" }}>
                                    <span className='white-color'>
                                    <Image src='/img/courseimgs/courseicons/icon_lession.svg' style={{width:"50px",height:"50px"}}/>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-end'>
                                <h3 className='ratinghead'>300+</h3>

                            </div>
                            <h4 className='ratingchapp'>Lessons</h4>

                        </div>
                    </Card.Body>
                </Card>




            </div>

            {
                userData && userData.enrollment_status === "Enrolled" && (
                    <button className='homestudybtn2' onClick={() => navigate("/today")}>
                        View My Courses
                    </button>
                )
            }

            {
                (!userData || (userData && userData.enrollment_status !== "Enrolled")) && (
                    <button className='homestudybtn2' onClick={() => navigate("/silva-method-courses")}>
                        Get All Courses
                    </button>
                )
            }

          

        </div>
    )
}

export default ThirdHeroSection