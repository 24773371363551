import React from 'react'

function ShineBtn({onClick=()=>{},text}) {
    return (
        <div className="containerpurple">

            <a onClick={onClick} className="btn-alt-purple">
                {text}
            </a>
        </div>
    )
}

export default ShineBtn