import { Button, Modal } from "react-bootstrap";
import SubmitForm from "./SubmitForm";
import { IoMdClose } from "react-icons/io";

export default function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            className="parallexnewnwe2"
            style={{
                background:"transparent"
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
         
            <Modal.Body style={{
                background:"transparent",
                position:"relative"
            }}
            className="parallexnewnwe"
            >

                <span style={{
                    position:"absolute",
                    top:10,
                    right:10,
                    zIndex:9999999999999
                }}
                onClick={props.onHide}
                >
                <IoMdClose color="white" size={30} />
                </span>
                

            
               <SubmitForm />
            </Modal.Body>
           
        </Modal>
    );
}