import React, { useContext, useMemo } from "react";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";
import MyVerticallyCenteredModal from "./SubmitModal";

function TabBars2() {
  const { isMobile, isTablet } = useContext(AuthContext);
  const handleScroll = () => {
    const cardscroll = document.querySelector(".paymenttwonew2");

    if (cardscroll) {
      cardscroll.scrollIntoView({
        behavior: "smooth"
      })
    }
  }
  const [modalShow, setModalShow] = React.useState(false);

  const [activeBtn, setActiveBtn] = useState(1);
  const buttons = [
    {
      index: 1,
      btnText: "Guilt Feeling",
      text: "Sometimes you may feel sinking under your Guilt. Your negative thought pattern is the reason. To Eliminate your guilt you have to discover its root. The “Unstress Toolbox” addresses guilt by employing powerful techniques that rewire thought patterns. By promoting a shift in perspective, it fosters self-awareness and encourages self-forgiveness and acceptance. Whenever your guilt bothers you, indulge yourself in meditation and practice the Unstress Toolbox, reprogram the mind, and get instant relief from your guilt.",
      img: "/img/unstressimages/Anxiety.jpg",
    },
    {
      index: 2,
      btnText: "Chronic stress",
      img: "/img/unstressimages/Chronic-stress.jpg",
      text: "Chronic stress can be feeling trapped in a dark pit with no way out. Stress is the natural reaction of your body to challenges. When you sense a threat, your nervous system releases stress hormones like adrenaline and cortisol into your bloodstream. This product will help your body to release Endorphins to counteract stress hormones. By directing your thoughts and soothing your mind, you can mitigate the stress reaction and promote a sense of calmness.",
    },
    {
      index: 3,
      btnText: "Anxiety",
      img: "/img/unstressimages/Guilt-Feeling.jpg",
      text: "Unstress Toolbox provides practical tools and techniques to alleviate anxiety by fostering relaxation and mental clarity. Through guided meditations, deep breathing exercises, and visualization techniques, individuals learn to shift their focus away from stressors and cultivate a sense of calmness and control. By regularly practicing these methods, participants can reprogram their minds to respond more calmly to challenging situations, reducing the frequency and intensity of anxiety symptoms.",
    },
    {
      index: 4,
      btnText: "Worries",
      img: "/img/unstressimages/Lack-of-concentration.jpg",
      text: "Worries are more than just fleeting thoughts; they can deeply impact your mental and physical well-being. When left unchecked, persistent worries can manifest as stress, and anxiety, and even contribute to various health issues. The body's stress response, triggered by worry, can lead to elevated levels of cortisol and adrenaline, which, over time, can take a toll on your immune system, cardiovascular health, digestive system, and overall vitality. Unstress Toolbox's 'worry transformer' stands out as the ultimate remedy for worries.",
    },
    {
      index: 5,
      btnText: "Self-Doubt",
      img: "/img/unstressimages/Mental-uneasiness.jpg",
      text: "The Three Fingers Technique is one of the core techniques of the Silva Life System. This technique can help you to enter the Alpha State of mind. This technique is also a secret weapon against being forgetful. Using it can improve your concentration and help you remember things better.",
    },
    {
      index: 6,
      btnText: "Mental uneasiness",
      img: "/img/unstressimages/Worries.jpg",
      text: "In the fast-paced, modern world, stress has become an almost inevitable part of our lives. Whether it's due to work pressure, personal challenges, or societal expectations, many of us find ourselves constantly battling feelings of inner nervousness and tension. “The Inner Peace tool” is not just a concept but a practice—a way of life—that allows individuals to shift from a state of constant inner nervousness to one of tranquility, joy, and freedom.",
    }

  ];

  const imagestyles = useMemo(() => {
    if (isTablet) {
      return {
        display: "flex",
        justifyContent: "center",
      };
    } else {
      return {};
    }
  }, [isTablet, isMobile]);

  return (
    <>
      <div className="py-4">
        <div
          style={{
            position: "relative",
          }}
        >
          <Card
            style={{
              width: isMobile || isTablet ? "100%" : "60rem",
              padding: isMobile || isTablet ? "30px 30px 0px 30px" : "50px",
              paddingBottom: isMobile || isTablet ? "0px" : "auto",
              border: "none",
              borderRadius: "15px",
              height: isMobile || isTablet ? "auto" : "600px",
              position: "relative",
            }}
          >
            <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
              {buttons.map((val) => (
                <button
                  className={`button-29 ${activeBtn === val.index ? "button-30" : ""
                    }`}
                  role="button"
                  onClick={() => setActiveBtn(val.index)}
                >
                  {val.btnText}
                </button>
              ))}
            </div>
            <Card.Body
              style={{
                padding: isMobile || isTablet ? "0" : "30px",
                border: "none",
              }}
              className="d-flex align-items-center"
            >
              <Row
                className={`${isTablet
                    ? "justify-content-center align-items-center"
                    : "justify-content-start align-items-center"
                  }`}
              >
                <Col
                  xs={12}
                  lg={6}
                  style={{
                    marginTop: isMobile || isTablet ? "50px" : "0px",
                  }}
                >
                  <h3 style={{
                    fontWeight: "bold"
                  }}>{buttons[activeBtn - 1].btnText}</h3>
                  <p
                    className="normalpara"
                    style={{
                      textAlign: "left",
                      fontWeight: "400 !important",
                    }}
                  >
                    {buttons[activeBtn - 1].text}
                  </p>
                  <button className="herodiv-becomememberbtn mt-3" style={{
                    marginBottom: isMobile ? "20px" : "0"
                  }} onClick={() => {
                    if (isMobile) {
                      setModalShow(true)
                    } else {
                      handleScroll()
                    }
                  }}>
                    Enroll For Free
                  </button>
                </Col>

                {(isMobile || isTablet) && (
                  <Col xs={12} lg={6} style={imagestyles}>
                    <img
                      src={buttons[activeBtn - 1].img}
                      style={{
                        // position: "absolute",
                        // bottom: "0",
                        right: "50px",
                        objectFit: "cover",
                        width: "auto",
                        borderTopLeftRadius: "40px",
                        borderTopRightRadius: "40px",
                        height: "400px",
                        // marginTop: isMobile ? "400px" : "0",
                        borderImage: "initial",
                        borderBottom: "none",
                        // borderRadius:"10px",
                        borderTop: "8px solid rgb(222, 222, 222)",
                        borderRight: "8px solid rgb(222, 222, 222)",
                        borderLeft: "8px solid rgb(222, 222, 222)",
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
            {!isMobile && !isTablet && (
              <img
                src={buttons[activeBtn - 1].img}
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "50px",
                  objectFit: "cover",
                  width: "auto",
                  borderTopLeftRadius: "40px",
                  borderTopRightRadius: "40px",
                  height: "400px",
                  marginTop: isMobile ? "400px" : "0",
                  borderImage: "initial",
                  borderBottom: "none",
                  // borderRadius:"10px",
                  borderTop: "8px solid rgb(222, 222, 222)",
                  borderRight: "8px solid rgb(222, 222, 222)",
                  borderLeft: "8px solid rgb(222, 222, 222)",
                }}
              />
            )}
          </Card>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default TabBars2;
