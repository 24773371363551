import React from 'react';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import ServicesOneABout from './ServicesOneAbout/ServicesOneABout';
import AboutTopSection from '../../../components/AboutTopSection/AboutTopSection';
import ImageGallerySection from '../../../components/ImageGallerySection/ImageGallerySection';
import ImageTextSection from '../../../components/ImageTextSection/ImageTextSection';
import DifferenceSection from '../../../components/DifferenceSection/DifferenceSection';
import ReviewsContainer from '../../../components/DifferenceSection/ReviewsContainer';
import BookContent from '../../../components/Bookcontent/BookContent';
import CustomFooter from '../../Home/Home/HomeFooter/Footer';

const ServicesOne = () => {
   
   return (
      <>
         <HomeHeader/>
         {/* <CommonPageHeader title="About Us" subtitle="Services" /> */}
         <AboutTopSection />
         <ImageGallerySection />
         <ServicesOneABout/>
         {/* <CalculateArea/> */}
         {/* <ServicesOneHiringArea/> */}
         <ImageTextSection />
         {/* <AboutCounter2 /> */}
         <DifferenceSection />
         <ReviewsContainer />
         <BookContent />
         {/* <Footer/> */}
         {/* <CalculateArea/> */}
         {/* <ServicesOneHiringArea/> */}
         <CustomFooter/>
      </>
   );
};

export default ServicesOne;