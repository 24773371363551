import React from 'react'
import { Accordion } from 'react-bootstrap'
import CustomAccordion2 from './CustomAccordion2'
import { Link } from 'react-router-dom'
import FAQ2 from '../CoursePage/FAQ2'

function SilvaLifeFAQ() {
  return (
    <div className=''>
      <div className="row">


        {/* <div className="col-sm-12">
          <CustomAccordion2
            title={"WILL THIS COURSE HAVE A LIFETIME ACCESS AFTER I BUY IT ?"}
            text={<p style={{ color: "white" }}>Yes, Once you purchase a course , it will be available in your account for a lifetime. If you buy the <Link to={"/store/course/combo-plan"} style={{ color: "white", textDecoration: "none" }}>combo course</Link> all Silva Method courses will be unlocked for a lifetime.</p>} />
        </div> */}

        {/* <div className="col-sm-12">
          <CustomAccordion2
            title={"WHAT TO DO IF I GET ANY CONFUSION DURING THE COURSE ?"}
            text={<p style={{ color: "white" }}>The course is segregated properly into chapter wise. If you still have confusion, please let us know at digital@silvamethod.com / support@silvamethod.com and we will resolve the issues soon</p>}
          />
        </div> */}

        {/* <div className="col-sm-12">
          <CustomAccordion2 title={"WHAT TO DO IF I CAN’T ENROLL TO THE COURSE ?"}
            text={<p style={{ color: "white" }}>Login to your profile. After that, Go to the course page and Click the “Start Now” button. If you’re having any further issues, drop an email to digital@silvamethod.com</p>} />
        </div> */}
        {/* <div className="col-sm-12">
          <CustomAccordion2 title={"WHAT TO DO IF I CAN’T LOGIN TO THE COURSE ?"}
            text={<p style={{ color: "white" }}>If You haven’t received any login details after the purchase, please drop an email to digital@silvamethod.com concerning the matter.</p>} />
        </div> */}
        {/* <div className="col-sm-12">
          <CustomAccordion2 title={"IS THIS COURSE DOWNLOADABLE ?"}
            text={<p style={{ color: "white" }}>NO. This is a Digital Audio Program and you can listen to it as many times you want in your lifetime by accessing through your profile.</p>}
          />
        </div> */}
      </div>

      <FAQ2
        heading={"Who is the current President of The Silva International?"}
        body={<p className='text-light'>Diana Silva is Jose Silva’s daughter and the current President of Silva International in 2024. Click to know more about her: <a href="https://silvamethod.com/instructor/diana-silva" className='text-primary bg-light'>https://silvamethod.com/instructor/diana-silva</a></p>}
      />


      <FAQ2
        heading={"WILL THIS COURSE HAVE A LIFETIME ACCESS AFTER I BUY IT ?"}
        body={<p style={{ color: "white" }}>Yes, Once you purchase a course , it will be available in your account for a lifetime. If you buy the <Link to={"/store/course/combo-plan"} style={{ color: "white", textDecoration: "none" }}>combo course</Link> all Silva Method courses will be unlocked for a lifetime.</p>}
      />
      <FAQ2
        heading={"WHAT TO DO IF I GET ANY CONFUSION DURING THE COURSE ?"}
        body={<p style={{ color: "white" }}>The course is segregated properly into chapter wise. If you still have confusion, please let us know at digital@silvamethod.com / support@silvamethod.com and we will resolve the issues soon</p>}
      />

      <FAQ2
        heading={"WHAT TO DO IF I CAN’T ENROLL TO THE COURSE ?"}
        body={<p style={{ color: "white" }}>Login to your profile. After that, Go to the course page and Click the “Start Now” button. If you’re having any further issues, drop an email to digital@silvamethod.com</p>}
      />
      <FAQ2
        heading={"WHAT TO DO IF I CAN’T LOGIN TO THE COURSE ?"}
        body={<p style={{ color: "white" }}>If You haven’t received any login details after the purchase, please drop an email to digital@silvamethod.com concerning the matter.</p>}
      />
       <FAQ2
        heading={"IS THIS COURSE DOWNLOADABLE ?"}
        body={<p style={{ color: "white" }}>NO. This is a Digital Audio Program and you can listen to it as many times you want in your lifetime by accessing through your profile.</p>}
      />
    </div>
  )
}

export default SilvaLifeFAQ