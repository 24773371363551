import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadingNew from "../AboutUs/HeadingNew";
import { BsFillPatchCheckFill } from "react-icons/bs";

function MemberShipHowWorks() {
  const listItems = [
    {
      id: 1,
      text: "Lifetime access to Silva Method courses, allowing you to continue your journey of personal development at your own peace.",
    },
    {
      id: 2,
      text: "Meditation tips and techniques and other Mind development training to be a pro at practicing Silva Method Exercises.",
    },
    {
      id: 3,
      text: "Expert Guidance from Silva Method Instructors provides the knowledge and support you need to achieve your goals.",
    },
    {
      id: 4,
      text: "Access to Exclusive Resources including Silva Method Audiobooks, guided meditation techniques, and Silva Mind control meditation.",
    },
    {
      id: 5,
      text: "Join a vibrant community of like-minded individuals, where you can connect, share experiences, and learn from one another.",
    },
  ];

  return (
    <div className="container flex-column d-flex justify-content-center align-items-center">
      <div>
        <HeadingNew
          title={"Why People are determined to choose"}
          colored={"The Silva Method Courses"}
        />
      </div>
      <div className="mt-3">
        {listItems.map(
          (items, i) => (
            <div className="d-flex align-items-center">
              <p className="text-end">
                <BsFillPatchCheckFill color="green"/>
              </p>
              <b className="text-start text-black p-2">{items.text}</b>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default MemberShipHowWorks;
