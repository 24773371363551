import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import HeadingNew from './HeadingNew'
import HeadingNew2 from './HeadingNew2'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { useMediaQuery } from 'react-responsive'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'

function AboutCard({ img,title,colored,para=[] }) {
    const isMobile = useMediaQuery({minWidth:320,maxWidth:767})
    const {isDesktopOrLaptop} = useContext(AuthContext)
    return (
        <div>
            <Card className='aboutcardstyle'>
                <Card.Body className={`d-flex justify-content-center align-items-start ${(isMobile) ?"flex-column":""}`}>
                    <div className='bluebox'>
                        <div className="innerdiv">
                            <Image src={img} />
                        </div>
                    </div>

                    <div className='abotparawrapper'>
                        <HeadingNew2 title={title|| ""} colored={colored || ""} centered={false} />
                        <ParaComp
                            margin={false}
                            white={false}
                            data={para} />
                    </div>


                </Card.Body>
            </Card>
        </div>
    )
}

export default AboutCard