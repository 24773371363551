import React, { useContext, useMemo } from "react";
import Slider from "react-slick";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { AuthContext } from "../../context/AllContext";

import HomeHeading from "../Home/Home/HomeHeading";

const AudioBlogs = () => {
  const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } =
    useContext(AuthContext);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isBigScreen ? 3 : isDesktopOrLaptop ? 2 : isTablet ? 2 : 1,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: false,
    prevArrow: <GrFormPrevious color="white" />,
    nextArrow: <GrFormNext color="white" />,
  };

  const urls = useMemo(() => {
    return [
      {
        url: "https://soundcloud.com/silva-method/what-is-silva-method-the-origins",
        img: require("../../images/audioblogimgs/AudioBanner1.webp"),
      },
      {
        url: "https://soundcloud.com/silva-method/what-is-silva-intuition-system-silva-method-audio-vlogs-2",
        img: require("../../images/audioblogimgs/AudioBanner2.webp"),
      },
      {
        url: "https://soundcloud.com/silva-method/the-power-of-subconscious-mind-how-to-wake-it-up-silva-method-audio-vlogs-3",
        img: require("../../images/audioblogimgs/AudioBanner3.webp"),
      },
      {
        url: "https://soundcloud.com/silva-method/how-to-improve-your-memory-using-silva-mind-control-silva-method-audio-vlogs-4",
        img: require("../../images/audioblogimgs/AudioBanner4.webp"),
      },
      {
        url: "https://soundcloud.com/silva-method/what-is-silva-life-system-silva-method-audio-vlogs-5",
        img: require("../../images/audioblogimgs/AudioBanner5.webp"),
      },
    ];
  }, []);
  return (
    <>
      <section className="latest-news-area pt-10 mt-3">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title pos-rel mb-25">
                <div>
                  <HomeHeading title="Listen To Our " colored="Audio Blogs" title2="" />
                  <h5 className="mt-3 mb-3 text-center">
                    Over 12 million people in 110 countries have experienced what
                    Silva Method Meditation can do.
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="ytaudio" style={{ marginBottom: "80px" }}>
            <Slider {...settings}>
              {urls.map((url, i) => (
                <img
                  src={url.img}
                  onClick={() => window.open(url.url)}
                  className="audioimg"
                  key={i}
                />
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default AudioBlogs;
