import React,{ useState } from 'react'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { Lightbox } from 'yet-another-react-lightbox';
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import Video from "yet-another-react-lightbox/plugins/video";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import { CgPlayButtonR } from "react-icons/cg"
import { AuthContext } from '../../../context/AllContext';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';



function SleepMeditationHero() {
    const navigate = useNavigate()
    const { isMobile, isBigScreen, userData, isTablet } = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    const handleScroll = () => {
        const descard = document.querySelector('.includelivecheck');
    
        if (descard) {
          descard.scrollIntoView({
            behavior: "smooth",
          });
        }
      };

    return (
        <>
            <div className='sleepmedi2bgnew'>
                <div className="container" style={{ position: "relative" }}>

                    <Row className='bgmainhome'>
                        <Col lg={7} xl={8}>
                            <div className='leftcontent'>
                                <HeadingNew title={"The Silva Method:"} colored='From Restless to Restful Overcome Insomnia Naturally' start={true} white />
                                <ParaComp
                                    white
                                    center={false}
                                    data={[
                                        "Do you find it challenging to fall asleep because stress and anxiety are bothering you, even if you feel tired? Or even after sleeping throughout the night, do you still feel tired and irritable? Then Silva Method Sleep Meditation is the right solution for your problems.",
                                    ]} 
                                    fw={"400"}
                                
                                    />
                                <div style={{ display: "inline-block", position: "relative", zIndex: "1" ,marginTop:"1rem"}}>
                                    {
                                        userData && userData.enrollment_status === "Enrolled" && (
                                            <button className='homestudybtn' onClick={() => navigate("/today")}>
                                                Start Now
                                            </button>
                                        )
                                    }

                                    {
                                        (!userData || (userData && userData.enrollment_status !== "Enrolled")) && (
                                            <button className='homestudybtn'
                                             onClick={handleScroll}
                                             >
                                                Discover Now
                                            </button>
                                        )
                                    }
                                    {/* <button className='homestudybtn'>
                                        Get All Courses
                                    </button> */}
                                    <span className='mx-3'>
                                        <CgPlayButtonR color='#fff' size={45} onClick={() => setOpen(true)} />
                                    </span>
                                    {/* <button className='homestudybtn2'>
                               blah blah
                            </button> */}
                                </div>
                            </div>
                        </Col>


                    </Row>



                </div>

            </div>
            {/* <div className='homestudycardwrapper'>
                <Card className='homestudycard boxshadowclass' style={{ width: isMobile ? "90%" : isTablet ? "80%" : !isBigScreen ? "60%" : "45%" }}>
                    <Card.Body className='d-flex justify-content-center align-items-center flex-column px-3 py-3 flex-column'>

                        <button className='aboutclassbtn2'>
                            Featured On
                        </button>
                        <div className={`d-flex flex-wrap justify-content-around align-items-center mt-4 ${isMobile ? "" : "gap-4"}`}>
                            <div>
                                <img
                                    style={imgstyles}
                                    src={forbes}
                                />
                            </div>


                            <div>
                                <img
                                    style={imgstyles}
                                    src={nytimes}

                                />
                            </div>

                            <div>
                                <img
                                    style={imgstyles}
                                    src={usatoday}
                                />
                            </div>

                            <div>
                                <img
                                    style={imgstyles}
                                    src={washington}
                                />
                            </div>
                        </div>



                    </Card.Body>
                </Card>
            </div> */}
            <Lightbox

                plugins={[Video, Fullscreen]}
                open={open}
                close={() => setOpen(false)}
                render={{
                    iconClose: () => <AiOutlineClose size={40} color='#fff' />,
                    iconEnterFullscreen: () => <BsArrowsFullscreen size={40} color='#fff' />,
                    iconExitFullscreen: () => <BsFullscreenExit size={40} color='#fff' />,
                }}
                slides={[
                    {
                        type: "video",
                        width: 1280,
                        height: 720,
                        poster: require("../../../images/frame.webp"),
                        autoPlay: true,
                        sources: [
                            {
                                src: "https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4",
                                type: "video/mp4"
                            }
                        ]
                    },
                    // ...
                ]}
            // ...
            />
        </>

    )
}

export default SleepMeditationHero