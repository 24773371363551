import React from 'react'
import { Image } from 'react-bootstrap'

function LearnCard({ src, text }) {
  return (
    <div className='mb-3 d-flex justify-content-center align-items-center flex-column gap-3'>
      {/* <div className='d-flex justify-content-center align-items-center' style={{
        width:"300px",
        height:"300px",
        backgroundColor:"#110613",
        borderRadius:"10px",
       border:"1px solid #636e72"
    }}> */}
      <Image
        src={src}
         style={{
          width:"95%",
          margin:"auto",
          borderRadius:"10px",
         border:"1px solid #636e72"
         }}
        // width="0"

        // height="0"
        // sizes="100vw"
        // alt=""
        // className="w-95 h-auto contactbanner"
        // style={
        //   {
        //     width: "98%", borderRadius: "10px", height: "auto", margin: "auto",
        //     borderRadius: "10px",
        //     border: "1px solid #636e72"
        //   }
        // }
      />
      {/* </div> */}
      <p className='white-color text-center' style={{
        maxWidth: "90%",
        fontSize: "0.9rem",
        lineHeight: "20px"
      }}>
        {text}
      </p>
    </div>
  )
}

export default LearnCard