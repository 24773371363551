import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";

function HeadingNew22({
  title,
  colored,
  small = false,
  boxed = false,
  boxedtext = "",
  white = false,
  bottomMargin = false,
  start = false,
  marginleft = false,
  underline=false,
  right
}) {
  const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } =
    useContext(AuthContext);
  return (
    <div>
      <h2
        style={{
          fontWeight: small?"700":"800",
          fontSize: isBigScreen
            ? small
              ? "23px"
              : "50px"
            : isDesktopOrLaptop
              ? small
                ? "20px"
                : "40px"
              : isTablet
                ? small
                  ? "20px"
                  : "40px"
                : isMobile
                  ? small
                    ? "20px"
                    : "30px"
                  : "20px",
          textAlign: right?"right":start ? "start" : "center",
          color: white ? "white" : "#000",
          marginBottom: bottomMargin ? "15px" : "0",
          marginLeft: (marginleft && !isMobile) ? "18px" : "0px",
          lineHeight: !small ?isBigScreen ? "56px" : isDesktopOrLaptop ? "50px" : isTablet ? "50px" : "40px":
          isBigScreen?"35px":"25px",
          letterSpacing:(isMobile || isTablet) ? "0.1px":"0.5px",
          textDecoration:(isMobile || isTablet)?"none":underline?"underline":"none",
          textDecorationThickness:underline?"0.2rem":"0",
          textUnderlineOffset:underline?"1rem":"0",
          textDecorationColor:underline?"rgb(216, 1, 251)":"none"
        }}
        className="animate__animated animate__fadeIn"
      >
        {title}{" "}
        {colored && (
          <div
            style={{
              color: white ? "#FFCC00" : "rgb(128, 28, 182)",
              fontWeight: "800",
              fontFamily: "Poppins",
              display: "inline-block",
            }}
          >
            {colored}
          </div>
        )}{" "}
        {boxed && (
          <p
            style={{
              padding: "16px 20px",
              color: "#fab80a",
              border: "none",
              backgroundColor: "white",
              fontSize: isBigScreen
                ? "40px"
                : isDesktopOrLaptop
                  ? "30px"
                  : isTablet
                    ? "26px"
                    : "20px",
              fontWeight: "800",
              borderRadius: "8px",
              display: "inline-block",
            }}
          >
            {boxedtext}
          </p>
        )}
      </h2>
    </div>
  );
}

export default HeadingNew22;