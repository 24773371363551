import React, { useContext } from 'react'
import { Row, Col, Spinner } from "react-bootstrap";
import LastHeading from '../../AboutUs/LastHeading';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { AuthContext } from '../../../context/AllContext';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { requestData } from '../../../utils/baseUrl';

function ComboContactSection() {


  const { isMobile } = useContext(AuthContext);



  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  })

  const [loading, setLoading] = useState(false);


  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const handleContact = async () => {
    const { name, email, phone, message } = values;

    
    const matchedEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

    if (!matchedEmail) {
      toast.error("Please enter a valid email");
      return
    }
    if (!name || name.length < 3) {
      toast.error("Please enter a valid name");
      return
    }
    if (!phone || !(/^[0-9]+$/.test(phone)) || phone.length > 13) {
      toast.error("Please enter a valid phone");
      return
    }
    if (!message || message.length < 4) {
      toast.error("Please enter a longer message");
      return
    }
    setLoading(true)
    const res = await requestData("ContactUsForm", "POST", values);
    setLoading(false)

    // console.log(res)

    if (res && res.error === false) {
      toast.success(res.messages)
      setValues({
        name: "",
        email: "",
        phone: "",
        message: ""
      })
    } else {
      toast.error(res.messages)
    }
  }

  return (



    <Row className='container justify-content-center align-items-center' style={{
      margin: "auto"
    }}>
      <Col xs={12} md={6}>
        <div style={{
          alignSelf: isMobile ? "center" : "flex-start"

        }}>
          <img src={require("../../../images/about-us-convert/course.webp")} style={{
            width: "90%",
            borderRadius: 15
          }} />
        </div>
      </Col>
      <Col xs={12} md={6}>

        <div>
          <Row className='justify-content-center align-items-center gy-3'>
            <div>
              <h4 style={{ fontFamily: "Poppins", textAlign: "center" }}>
                Still have queries?
              </h4>


              <LastHeading boxed boxedtext='Contact Us' white />
              <ParaComp
                margin={false}
                data={[
                  <p style={{
                    margin: 0,
                    padding: 0
                  }}>Please fill in the form and we will respond as quickly as we can. You can also reach us at
                    <a href="mailto:digital@silvamethod.com" style={{ color: "#8e44ad", fontWeight: "600" }}> digital@silvamethod.com </a>

                    {/* <a href='tel:+1-800-545-6463' style={{ color: "#8e44ad", fontWeight: "600" }}>+1-800-545-6463 (Toll free USA)</a> or <a style={{ color: "#8e44ad", fontWeight: "600" }}
                                                href="tel:+1-956-286-3004">+1-956-286-3004</a> */}
                  </p>
                ]}
              />
            </div>



            <Col xs={12} md={12}>
              <input className='devtoinput' placeholder='enter your name' name='name' onChange={handleChange} value={values.name} />

            </Col>
            <Col xs={12} md={6}>
              <div style={{
                paddingRight: 5
              }}>
                <input name='email' onChange={handleChange} className='devtoinput' style={{
                  marginRight: 10
                
                }}
                value={values.email}
                placeholder='enter your email' />
              </div>

            </Col>
            <Col xs={12} md={6}>
              <div style={{
                paddingLeft: isMobile ? 0 : 5
              }}>
                <input
                 value={values.phone}
                 name='phone' onChange={handleChange}
                className='devtoinput' placeholder='enter your phone' />
              </div>
            </Col>
            <Col xs={12}>
              <textarea cols={50} className='devtoinput2' placeholder='write your message'
               name='message' onChange={handleChange}
               value={values.message}
              />

            </Col>
            <Col xs={12}>
              <ParaComp
                margin={false}
                white={false}
                data={[
                  <p>By submitting you agree to our <Link to="/terms-and-condition" style={{ color: "#6f25af", fontWeight: "600" }}>Terms and Conditions </Link>
                    and <Link to={"/privacy-policy"} style={{ color: "#6f25af", fontWeight: "600" }}>Privacy Policy</Link></p>
                ]}
              />
              <button className='combobtnnew' onClick={handleContact}>
                {
                  loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : "Submit"
                }
              </button>

            </Col>
          </Row>
        </div>
      </Col>

    </Row>

  )
}

export default ComboContactSection