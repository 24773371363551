import React from 'react'
import { Image } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import TabbarMedi from './TabbarMedi'
import { useState } from 'react';
import SidebarFullMusic from './SidebarFullMusic';
import BenifitSectionHyp from '../HypnosisPage/BenefitsSectionHyp';
import { AuthContext } from '../../../context/AllContext';
import { useContext } from 'react';
import { checkIsCoursePurchased, reqData } from '../../../utils/baseUrl';
import ComboHero2 from '../ComboCourse/ComboHero2';
import { useEffect } from 'react';
import ComboIncludes from '../ComboCourse2/ComboIncludes';
import ComboTwoHero from '../ComboCourse2/ComboTwoHero';
import AllCourseAccordions2 from '../ComboCourse2/AllCourseAccordions2';
import ComboAccordion from '../ComboCourse2/ComboAccordion';
import ComboBenefitsSection from '../ComboCourse2/ComboBenefitsSection';
import LiveInteractiveBenefits from '../ComboCourse2/LiveInteractiveBenefits';
import BreakThroughs2 from '../ComboCourse2/BreakThroughs2';
import BonusSection from '../ComboCourse2/BonusSection';
import TabbarSection from '../ComboCourse/TabbarSection';
import TestimonialSliderCombo from '../ComboCourse2/TestimonialSliderCombo';
import ComboContactSection from '../ComboCourse2/ComboContactSection';
import TestimonialSectionNew from './TestimonailsSection';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';


function DashboardHeroSection() {

  const { id } = useParams()
  const [freeCourseData,setFreeCourseData] = useState()
  const [courseData, setCourseData] = useState([]);
  const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);

  const { userData, isMobile } = useContext(AuthContext);

  const [allCourseData, setAllCouurseData] = useState();

  const [loading, setLoading] = useState(false);

  const getCourseData = async () => {
    setLoading(true);
    const res = await reqData("courseDetail", "POST", {
      course_id: "combo-plan",
    });
    setLoading(false);
    if (res.error === false) {
      setCourseData(res.data);
    }
  };



  const getAllLectures = async (id) => {
    try {
      const headers = {
        'Authorization': 'Bearer ' + atob(id),
        'Content-Type': 'application/json'
      };
      // const res = await axios.post("http://localhost:5000/api/v1/user/auth/allfreecourses"
      const res = await axios.post("https://silvacasesonline.com:5000/api/v1/user/auth/allfreecourses"

      , {
      }, {
        headers
      })

      const data = {
        lessons:res.data.data,
        user:res.data.user
      }

      console.log(data,"datadata")
      setFreeCourseData(data)

    } catch (err) {
      const er = err.response.data.success
      if(!er){
        toast.error("Invalid url")
        return

      }
    }
  }






  const getAllCourses = async () => {
    setLoading(true);
    const res = await reqData("latestCourseList", "POST", {
      start_index: 0,
      no_of_records: 20,
    });



    if (res && res.error === false) {
      const allc = res.data
        .filter(
          (val) =>
            val.course_id !== "combo-plan" &&
            val.course_id !== "silva-method-complete-course-with-live-interactive-program" && val.course_id !== "unstress-toolbox"
        )
        .map((val) => val.course_id);

      const promisesArr = [];

      allc.forEach((cid) => {

        promisesArr.push(
          reqData("courseDetail", "POST", {
            course_id: cid,
          })
        );
      });

      const result = await Promise.all(promisesArr);
      setLoading(false);

      const finalResult = result
        .map((val) => {
          if (val.error === false) {
            return val.data;
          }
        })
        .sort((a, b) => a[0].precedence - b[0].precedence);
        // console.log(finalResult,'finalresult')
      setAllCouurseData(finalResult);
    }
  };



  const getPurchasedStat = async () => {
    const status = await checkIsCoursePurchased("combo-plan", userData);
    setIsCurrentCoursePurchased(status)
  }


  useEffect(() => {
    getCourseData();
    getAllCourses();
    getPurchasedStat();
  }, [userData]);


  useEffect(() => {
    if (id) {
      getAllLectures(id)
    }
  }, [id])



  return (
    <div className='bgcombonew' style={{
      paddingTop: "60px",
      paddingBottom: "60px"
    }}>
      {
        isMobile ? (
          <Image src="/img/draganpage/102-mobile.png" className='mb-4' />


        ) : (
          <Image src='/img/bg/dragan-bg-up.png' />

        )
      }
      <div className='d-flex justify-content-center align-items-center flex-column'>
        <div className='container'>
          {
            freeCourseData && freeCourseData?.user?.firstname && (
              <HeadingNew title={"Welcome"} colored={freeCourseData?.user?.firstname[0].toUpperCase()+freeCourseData?.user?.firstname.substring(1)} />

            )
          }
          <HeadingNew small title={"Here is the list of unlocked meditations"} />


        </div>
        {
          freeCourseData &&  freeCourseData.lessons && (
            <TabbarMedi data={freeCourseData.lessons} />
          )
        }


      </div>

      <BenifitSectionHyp btnlink />
      <BreakThroughs2 />

      <div className='my-5'>
        <ComboBenefitsSection btnlink />

      </div>
      <div className='mt-5'>
        <LiveInteractiveBenefits />

      </div>
      <TestimonialSectionNew />
      <div className='py-5'>
        <ComboContactSection />

      </div>

    </div>
  )
}

export default DashboardHeroSection