import React, { useContext } from "react";
import NewHeader from "../../AdvertisePages/newmembership/NewHeader";
import AppointmentHero from "./AppointmentHero";
import TrustedBy from "../../Draganpages/TrustedBy";
import AppointmentOptions from "./AppointmentOptions";
import BecomeMemberSection from "../../silvamembership/BecomeMemberSection";
import BreakThroughs from "../../silvamembership/BreakThroughs";
import SecondHeroSection from "../../Home/Home/Homestudycourses/SecondHeroSection";
import NewComboFaqs from "../newComboLanding/NewCombofaq";
import BiggestProblem from "./BiggestProblem";
import AppointmentFooter from "./AppointMentFooter";
import VideoTestimonials from "./VideoTestimonials";
import { AuthContext } from "../../../context/AllContext";
import CallNowCtr from "./CallNowCtr";
import HomePageHeroSection from "../../CoursePage2/HomePageHeroSection";

function AppoinmentIndex() {
  const faqs = [

    {
      id: 1,
      title:"Is there any free consultation?",
      text: `Yes, we offer free consultations where you can connect with an expert support team member to discuss your questions or concerns. Our team is here to help you understand how the Silva Method can benefit you personally. You can WhatsApp us at 9932600266 or by emailing us at digital@silvamethod.com`,
    },
    {
      id: 2,
      title:"How long will it take to get a response from the support team?",
      text: `Our support team is dedicated to providing timely assistance. You can expect to receive a response within 24 hours of reaching out.`,
    },
    {
      id: 3,
      title:"What is the Silva Method, and how does it work?",
      text: `The Silva Method is a self-help and meditation program that trains your mind to achieve greater focus, relaxation, and intuition. It works by guiding you into deep meditative states, where you can reprogram your subconscious mind for positive change.`,
    },
    {
      id: 4,
      title:"What are the main benefits of practicing the Silva Method?",
      text: `Practicing the Silva Method can enhance mental clarity, reduce stress, boost creativity, improve intuition, and help you achieve your goals.`,
    },
    {
      id: 5,
      title:"How can I find a certified Silva Method instructor near me?",
      text:  <p className="text-white">You can find a certified Silva Method instructor by visiting the official Silva Method website <a href="www.silvamethod.com" className="text-primary px-1">www.silvamethod.com</a> or contacting local wellness centers that may offer courses. </p>,
    },
    {
      id: 6,
      title:"Are there online Silva Method instructors available for virtual classes?",
      text: `Yes, many certified Silva Method instructors offer online courses and virtual classes, making it accessible from anywhere.`,
    },
    {
      id: 7,
      title:"How long does it take to see results from the Silva Method?",
      text: `Results vary depending on individual commitment and practice, but some people report improvements in focus, stress reduction, and goal achievement within a few weeks.`,
    },
    {
      id: 8,
      title:"Is the Silva Method suitable for beginners?",
      text:  <p className="text-white">Yes, the Silva Method is designed for people of all levels, including beginners. It provides step-by-step guidance to help you develop your skills. If you are a beginner then click on this link:<a href="https://silvamethod.com/store/course/combo-plan" className="text-primary px-1">https://silvamethod.com/store/course/combo-plan</a>  and start your journey. </p>,
    },
    {
      id: 9,
      title:"How does the Silva Method compare to other meditation techniques?",
      text: `This dynamic meditation technique is unique in its focus on mental programming, intuition development, and goal achievement, making it more goal-oriented than some traditional meditation techniques.`,
    },
    {
      id: 10,
      title:"How can I start learning the Silva Method?",
      text: `You can start learning the Silva Method by enrolling in the BLS course with a certified instructor. Or you can purchase original Silva Method courses from our official website or app.`,
    },
    {
      id: 11,
      title:"Are there any prerequisites for learning the Silva Method?",
      text: `There are no prerequisites; anyone can start learning the Silva Method, regardless of experience.`,
    },
    {
      id: 12,
      title:"Can the Silva Method help with achieving financial goals?",
      text: `Yes, the Silva Method includes techniques for visualization and mental programming that can help you focus on and achieve financial goals.`,
    },
    {
      id: 13,
      title:"Are there any success stories or testimonials from Silva Method practitioners?",
      text: <p className="text-white">Yes, many practitioners share success stories and testimonials, often highlighting personal growth, improved intuition, and goal achievement. Click on this link to check out all reviews.  <a href="https://silvamethod.com/success-stories" className="text-primary ps-1">https://silvamethod.com/success-stories</a></p>,
    },
    {
      id: 14,
      title:"How often should I practice the Silva Method for optimal results?",
      text: `For optimal results, it's recommended to practice the Silva Method daily. Consistency is key, so integrating the techniques into your daily routine will help you achieve the best outcomes over time.`,
    },

  ];

  const {isMobile}= useContext(AuthContext)

  return (
    <div>
      <NewHeader />
      <AppointmentHero />
      <div className={`py-${isMobile?"":"3"} mb-${isMobile?"":"5"}`} style={{backgroundColor:"#dcd5e733"}}>
      <TrustedBy />
      </div>
      <AppointmentOptions />
      <BiggestProblem/>
      <div className="my-3">
        {/* <BecomeMemberSection /> */}
        <HomePageHeroSection />
      </div>
      <BreakThroughs />
      <div className="my-5">
      <SecondHeroSection />
      </div>
      <CallNowCtr/>
      <div className="my-4">
      <VideoTestimonials/>
      </div>
      <NewComboFaqs faqs={faqs} extraClass={`py-5`}/>
      <AppointmentFooter/>
    </div>
  );
}

export default AppoinmentIndex;
