import React from 'react';
import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../../../context/AllContext';

const CausesOfInsomniaSection = () => {
    const { isMobile } = useContext(AuthContext)
    return (
        <div className="causes-insomnia-section container">
            <Row className="px-4 align-items-center">
                {/* Left Column - Text */}
                <Col md={6} className="text-column">
                    <h3 style={{
                        color: "black",
                        fontSize: isMobile ? "2rem" : "2.5rem"
                    }}
                        className='lora-sans'
                    >
                        What Causes {" "}
                        <span style={{ color: '#572ac7' }}>
                            Insomnia{" "}
                        </span>
                        in People?
                    </h3>

                    {
                        isMobile && (
                            <div className='d-flex justify-content-end align-items-center'>
                            <img
                                src="/img/bg/blogpages/4-What Causes Insomnia in People/sleep-meditation1-1.jpg" // Replace with an actual image URL
                                alt="Causes of Insomnia"
                                className="img-fluid causes-image my-3"
                                style={{
                                    width: "100%"
                                }}
                            />
    
                        </div>
                        )
                    }
                    <p className="section-description robotofont">
                        Sleep is as crucial to our survival and our health as basic human needs, yet it often doesn’t get the attention it deserves. According to the American Academy of Sleep Medicine, about 30% of adults experience insomnia symptoms, and around 10% suffer from severe insomnia that affects their daily lives.
                    </p>
                    <h3 style={{
                        color: "black",
                        fontSize: isMobile ? "1.5rem" : "2rem"
                    }}
                        className='lora-sans'
                    >
                      The Actual Reasons Behind This
                    </h3>
                    <ul className="causes-list robotofont">
                        <li>Worries about work, relationships, or finances can keep the mind racing, making it hard to fall asleep or stay asleep throughout the night.</li>
                        <li>Irregular bedtime routines, excessive screen time before bed, and consuming too much caffeine or alcohol can all interfere with the body’s natural sleep cycle.</li>
                        <li>Chronic pain, asthma, arthritis, and other medical issues can cause discomfort that disrupts sleep. Sleep disorders like sleep apnea or restless leg syndrome are also common culprits.</li>
                        <li>Conditions such as depression, anxiety, and PTSD often come hand-in-hand with sleep disturbances, contributing to long-term insomnia.</li>
                        <li>Certain prescription drugs for high blood pressure, depression, or asthma may list insomnia as a side effect.</li>
                    </ul>
                </Col>

                {/* Right Column - Image */}
                {
                    !isMobile && (
                        <Col md={6} className="image-column">
                    <div className='d-flex justify-content-end align-items-center'>
                        <img
                            src="/img/bg/blogpages/4-What Causes Insomnia in People/sleep-meditation1-1.jpg" // Replace with an actual image URL
                            alt="Causes of Insomnia"
                            className="img-fluid causes-image"
                            style={{
                                width: "95%"
                            }}
                        />

                    </div>
                </Col>
                    )
                }
            </Row>


        </div>
    );
}

export default CausesOfInsomniaSection;
