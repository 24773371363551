import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LearnTo from './LearnTo'

function ExtraSection() {
  return (
    <Row className='justify-content-center align-items-center'>
        <Col md={6}>
            <LearnTo img={"https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(1).webp"}
            title={"SILVA LIFE SYSTEM"}
            subtitle={"Jan 20,21 and Jan 27,28 (First 4 days)"}
            para={"Silva Life System: Master your mind, enhance creativity, make better decisions, and live in flow. Learn dynamic meditations to unlock intuition, take control, and manifest a better reality."}
            list={[
                "Overcome stress",
                "Sleep better- get to sleep and stay asleep",
                "Tap into your dreams to help with problem solving",
                "Learn to manifest your goals",
                "Learn pain management techniques especially during times of emergency",
                "Achieve greater health",
                "Improve memory and cognitive skills",
                "Improve academic career- get better grades",
                "Create and maintain healthier relationships with those around you",
                "Awaken and develop your intuition for daily problem solving and creative projects"
            ]}
            />
        </Col>
        <Col md={6}>
        <LearnTo img={"https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(4).webp"}
            title={"SILVA INTUITION SYSTEM"}
            subtitle={"Feb 3,4 and Feb 10,11 (Last 4 days)"}
            para={"Silva Intuition System: Enhance intuition, detect health issues, and send healing in days. Connect to Source energy, clarify your soul's purpose, and leave transformed with new tools for self-improvement."}
            list={[
                "Develop and strengthen your intuition even more",
                "Feel and be inspired",
                "Gain new tools to help with creative problem solving",
                "Discover your life’s purpose and start living a purpose driven life",
                "Develop and deepen your spirituality",
                "Learn to consciously manifest with ease and grace ",
                "Continue to build a better and stronger foundation for your life",
                "Re-write your story and get in alignment with flow",
                "Learn to send healing to others and the world",
                "Experience interconnectedness with the world and life itself",
                "Start living a life of fulfillment"
            ]}
            />
        </Col>

    </Row>
  )
}

export default ExtraSection