import React from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import ComboCardSec from './ComboCardSec'


function ComboIncludes() {
    return (
        <div className='container mt-5'>
            <HeadingNew title={"What will you get with"} colored={"Combo Course"} />
          
            
            <ComboCardSec />
            {/* <ComboCardSec /> */}

        </div>
    )
}

export default ComboIncludes