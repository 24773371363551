import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import HeadingNew from "../../AboutUs/HeadingNew";
import { useContext } from "react";
import AllContext, { AuthContext } from "../../../context/AllContext";
import { useMemo } from "react";

const GradientCard = () => {
  const { isMobile } = useContext(AuthContext);

  const data = useMemo(() => {
    const baseUrl = "/img/book/img-";
    const images = [];
    const startImageNumber = 35; // Change this to the starting image number
    const endImageNumber = 64; // Change this to the ending image number

    for (let i = startImageNumber; i <= endImageNumber; i++) {
      images.push(`${baseUrl}${i}.webp`);
    }

    return images;
  }, []);


  const carddata = useMemo(() => {
    return [
      {
        title: "Silva Mind Control for Business Managers",
        img: "/img/book/img-65.webp"
      },
      {
        title: "Silva Mind Control For Sales Managers",
        img: "/img/book/img-66.webp"
      },
      {
        title: "The Silva Mind Method: for Getting Help from the Other Side",
        img: "/img/book/img-67.webp"
      },
      {
        title: "Reflections - Personal Evaluation by the founder of Silva Method",
        img: "/img/book/img-68.webp"
      },
      {
        title: "Everyday ESP - A new way of living",
        img: "/img/book/img-69.webp"
      },
      {
        title: "I have a Hunch",
        img: "/img/book/img-70.webp"
      }
    ]

  }, [])
  return (
    <div className="bg-book-1">
      <HeadingNew small title={"Popular Mind Control Books By"} />
      <HeadingNew title={"Jose"} colored={"Silva"} />
      <div className="container">
        <div className="d-flex justify-content-center align-items-center">
          <Card
            style={{
              width: "100%",
              margin: isMobile ? "20px" : "20px 100px",
              borderRadius: "15px",
            }}
          >
            <Card.Body
              style={{
                paddingTop: isMobile ? 20 : 40,
                paddingBottom: isMobile ? 20 : 40
              }}
            >
              <div className="d-flex justify-content-center align-items-center flex-wrap gap-2">
                {
                  data.map((val, i) => (
                    <img
                      src={val}
                      width={isMobile?80:100} style={{
                        borderRadius: "8px"
                      }}
                    />
                  ))
                }


              </div>
            </Card.Body>
          </Card>
        </div>
        <div style={{
          margin: isMobile ? "20px" : "20px 100px",
        }}>
          <Row className="justify-content-center align-items-center gy-2">
            {
              carddata.map((val, i) => (
                <Col xs={12} md={6}>
                  <Card
                    style={{
                      width: "97%",
                      padding: 10,
                      borderRadius: 15

                    }}
                    className="classcardbook"
                  >
                    <Card.Body className="d-flex gap-3 justify-content-start align-items-center">
                      <img
                        width={80}
                        style={{
                          objectFit: "cover",
                        }}
                        src={val.img}
                      />
                      <div>
                        <Card.Title style={{
                          color: "black",
                          fontWeight:"600"
                        }}>{val.title}</Card.Title>
                        <Card.Subtitle>Written By Jose Silva</Card.Subtitle>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

              ))
            }


          </Row>
        </div>

      </div>
    </div>
  );
};

export default GradientCard;
