import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AllContext";
import { Navigate } from "react-router-dom";
// import HomeHeader from "./HomeHeader/HomeHeader";
import Homeherodiv from "./HomeHeroSection/Homeherodiv"
import Featured from "../../../components/featured/Featured"
import AboutSilvaMethod from "../../AboutUs/AboutSilvaMethod"
import CourseSlide from "../../../components/HomeHeroSingleSlide/FirstSlide"
import Testimonials from "../../../components/featured/Reviews"
//  import HomeBlogs  from "./HomeBlogs/HomeBlogs"


//  import AudioBlogs  from "../../Blogs/AudioBlog"
import Newsletter from "../../../components/Newsletter/Newsletter"
import CustomFooter from "./HomeFooter/Footer"
import HomeCountrySection from "../../../components/CountrySection/HomeCountrySection"
import HomeClasses from "./HomeClasses"
//  import ProductSlide  from "../../Store/products/ProductSlide"
import SilvaBenefits from "./SilvaBenefits"
import BecomeMember from "./BecomeMember"
import HowSilvaWorks from "./HowSilvaWorks"
import ResearchSection from "./Researchsection/ResearchSection"
import BreakThroughs from "../../silvamembership/BreakThroughs"
import HomeHeader from './HomeHeader/HomeHeader'
import LoadingSpinner from "../../LoadingSpinner";
import loadable from "@loadable/component";


const AudioBlogs = loadable(() => import('../../Blogs/AudioBlog'), {
  fallback: <LoadingSpinner />
})



const ProductSlide = loadable(() => import('../../Store/products/ProductSlide'), {
  fallback: <LoadingSpinner />
})


const SilvaHome = () => {
  const { isDesktopOrLaptop, userData, isUserLoggedIn } = useContext(AuthContext);
  //console.log(userData);



  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },[])

  if (isUserLoggedIn && userData && userData.enrollment_status !== "Enrolled") {
    return <Navigate to="/" />
  } else if (isUserLoggedIn && userData && userData.enrollment_status === "Enrolled") {
    return <Navigate to="/today" />
  }
  // else if(isUserLoggedIn && userData && userData.strip_payment_status!=="paid"){
  //   return <Navigate to="/silva-membership" />
  // }

  return (
    <div>
      {/* <Helmet>
        <title>Silva Home Page</title>
        <meta name='description'
          content='Silva method Membership'
        />
      </Helmet> */}

      
      <HomeHeader />
      <Homeherodiv />
      <Featured />
      <BreakThroughs />
      <AboutSilvaMethod />
      <CourseSlide />
      <div className="parallexadvertise">
        <HowSilvaWorks />
        <SilvaBenefits />
        <ResearchSection />
      </div>

      <HomeClasses />
      <Testimonials />
      {/* <AboutSilva /> */}
      <div className={isDesktopOrLaptop ? "" : "col-12 m-auto pt-2 pb-5"}>
        <ProductSlide />

      </div>

      {/* <HomeProducts /> */}
      {/* <InstructorsSlide/> */}
      {/* <HomeEvents/> */}
      {/* <HomeBlogs /> */}
      <HomeCountrySection />
      <AudioBlogs />
      <BecomeMember />
      <Newsletter />

      <CustomFooter />
    </div>

  );
};

export default SilvaHome;
