import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ParallaxProvider } from 'react-scroll-parallax';
import AllContext from './context/AllContext';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';



const rootElement = document.getElementById("root");



// ReactDOM.render(
//   <React.Fragment>
//     <BrowserRouter>
//       <AllContext>
//         <ParallaxProvider>
//           <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY}>
//             <App />
//           </GoogleOAuthProvider>
//         </ParallaxProvider>
//       </AllContext>
//     </BrowserRouter>
//   </React.Fragment>,
//   rootElement
// );

ReactDOM.render(<HelmetProvider>
  <BrowserRouter>
    <AllContext>
      <ParallaxProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY}>
          <App />
        </GoogleOAuthProvider>
      </ParallaxProvider>
    </AllContext>
  </BrowserRouter>
</HelmetProvider>, rootElement);


// ReactDOM.hydrate(
//   <HelmetProvider>
//   <BrowserRouter>
//     <AllContext>
//       <ParallaxProvider>
//         <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_KEY}>
//           <App />
//         </GoogleOAuthProvider>
//       </ParallaxProvider>
//     </AllContext>
//   </BrowserRouter>
// </HelmetProvider>,
//   rootElement
// )




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
