import { Card, Image } from 'react-bootstrap'
import CountUp from "react-countup";
import "./herosection.css"

function SecondHeroSection() {


    return (
        <div style={{ position: "relative" }} className='secondherocont'>
            <div className='pattern'>
            </div>

            <div style={{ zIndex: "11", position: "relative" }}>
                <div className='socialmediaherosection section1'>
                    <Card style={{ width: "fit-content", padding: "8px 15px", borderRadius: "20px" }} className='boxshadowclass'>
                        <Card.Body className='d-flex justify-content-center align-items-center gap-4'>
                            <Image fluid src={require("../../images/adimgs/icon-09.png")}
                                // width="0"

                                // height="0"
                                // sizes="100vw"
                                // alt=""
                                // className="w-95 h-auto contactbanner mobilehide"
                                // style={
                                //     { width: "98%", borderRadius: "10px", height: "auto" }
                                // }
                                width={100}
                                height={100}
                            />
                            <div>
                                <h4>Amazon</h4>
                                <div className='d-flex justify-content-center align-items-end'>
                                    <h3 className='ratinghead'>4.6</h3>
                                    <h5 className='ratinghead2'>/5</h5>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>



                    <Card style={{ width: "fit-content", padding: "8px 15px", borderRadius: "20px" }} className='boxshadowclass'>
                        <Card.Body className='d-flex justify-content-center align-items-center gap-4'>
                            <Image fluid src={require("../../images/adimgs/icon-10.png")}
                                // width="0"

                                // height="0"
                                // sizes="100vw"
                                // alt=""
                                // className="w-95 h-auto contactbanner mobilehide"
                                // style={
                                //     { width: "98%", borderRadius: "10px", height: "auto" }
                                // }
                                width={100}
                                height={100}
                            />
                            <div>
                                <h4>Facebook</h4>
                                <div className='d-flex justify-content-center align-items-end'>
                                    <h3 className='ratinghead'>4.7</h3>
                                    <h5 className='ratinghead2'>/5</h5>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>


                    <Card style={{ width: "fit-content", padding: "8px 15px", borderRadius: "20px" }} className='boxshadowclass'>
                        <Card.Body className='d-flex justify-content-center align-items-center gap-4'>
                            <Image fluid src={require("../../images/adimgs/icon-11.png")}
                                // width="0"

                                // height="0"
                                // sizes="100vw"
                                // alt=""
                                // className="w-95 h-auto contactbanner mobilehide"
                                // style={
                                //     { width: "98%", borderRadius: "10px", height: "auto" }
                                // }
                                width={100}
                                height={100}
                            />
                            <div>
                                <h4>Testimonials</h4>
                                <div className='d-flex justify-content-center align-items-end'>
                                    <h3 className='ratinghead'>1M</h3>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                </div>
                <div className='socialmediaherosection section2'>

                    {/* <Card className='herocardsection'>
                                <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                                    <div className='imagedivhome'>
                                        <Image fluid src={"/adimgs/icon-05.png")} style={{ width: "100%", height: "100%" }} />
                                    </div>
                                    <div className='mt-3'>

                                        <CountUp className='ratinghead' end={500} duration={2.5} suffix="+" />



                                    </div>
                                    <div>
                                        <h5 className='ratinghead6'>Instructors</h5>
                                    </div>
                                </Card.Body>
                            </Card> */}

                    <Card className='herocardsection boxshadowclass'>
                        <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                            <div className='imagedivhome'>
                                <Image fluid src={require("../../images/adimgs/icon-05.png")}
                                    // width="0"

                                    // height="0"
                                    // sizes="100vw"
                                    // alt=""
                                    // className="w-95 h-auto contactbanner mobilehide"
                                    // style={
                                    //     { width: "98%", borderRadius: "10px", height: "auto" }
                                    // }
                                    width={30}
                                    height={30}
                                />
                            </div>
                            <div className='mt-3'>

                                <CountUp className='ratinghead' end={500} duration={2.5} suffix="+" />



                            </div>
                            <div>
                                <h5 className='ratinghead6'>Instructors</h5>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className='herocardsection boxshadowclass'>
                        <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                            <div className='imagedivhome'>
                                <Image fluid src={require("../../images/adimgs/icon-06.png")}
                                    // width="0"

                                    // height="0"
                                    // sizes="100vw"
                                    // alt=""
                                    // className="w-95 h-auto contactbanner mobilehide"
                                    // style={
                                    //     { width: "98%", borderRadius: "10px", height: "auto" }
                                    // }
                                    width={30}
                                    height={30}
                                />
                            </div>
                            <div className='mt-3'>
                                <CountUp className='ratinghead' end={120} duration={2.5} suffix="+" />
                            </div>
                            <div>
                                <h5 className='ratinghead6'>Countries</h5>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className='herocardsection boxshadowclass'>
                        <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                            <div className='imagedivhome'>
                                <Image fluid src={require("../../images/adimgs/icon-07.png")}
                                    // width="0"

                                    // height="0"
                                    // sizes="100vw"
                                    // alt=""
                                    // className="w-95 h-auto contactbanner mobilehide"
                                    // style={
                                    //     { width: "98%", borderRadius: "10px", height: "auto" }
                                    // }
                                    width={30}
                                    height={30}
                                />
                            </div>
                            <div className='mt-3'>

                                <span className='ratinghead'>12M</span>
                            </div>
                            <div>
                                <h5 className='ratinghead6'>Graduates</h5>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className='herocardsection boxshadowclass'>
                        <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                            <div className='imagedivhome'>
                                <Image fluid src={require("../../images/adimgs/icon-08.png")}
                                    // width="0"

                                    // height="0"
                                    // sizes="100vw"
                                    // alt=""
                                    // className="w-95 h-auto contactbanner mobilehide"
                                    // style={
                                    //     { width: "98%", borderRadius: "10px", height: "auto" }
                                    // }
                                    width={30}
                                    height={20}
                                />
                            </div>
                            <div className='mt-3'>
                                <CountUp className='ratinghead' end={68} duration={2.5} suffix="K" />

                            </div>
                            <div>
                                <h5 className='ratinghead6'>Social Media Followers</h5>
                            </div>
                        </Card.Body>
                    </Card>


                </div>
            </div>
        </div>
    )
}

export default SecondHeroSection