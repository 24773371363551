import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';

function LMHeroSection({title,image,textSecData,btnText,onclick,white=false}) {

    const {isMobile,isTablet}= useContext(AuthContext);


  return (
    <div className='container py-5 d-flex justify-content-end align-items-center'>
        <Card className='w-100' style={{borderRadius:'15px',backgroundColor:'#36003e',backgroundImage:"url('/img/bg/bglovemind.jpg')"}}>
        <Row>
            <Col sm={12} md={12} lg={5} className='d-flex align-items-center'>
                <Image src={image || ''}/>
            </Col>
            <Col sm={12} md={12} lg={7} className='d-flex align-items-center'>
                <div>
                <div className='m-4 mb-2'>
                    <HeadingNew title={title ||""} small start white={white? true:false}/>
                </div>
                <div className='p-3 px-4'>
                {
                    textSecData?.map((data,i)=>
                    <div>
                        {data}
                    </div>
                    )
                }
                </div>
                <div className='m-4 mt-2'>
                    <button className='herodiv-becomememberbtn3' onClick={onclick}>{btnText || "Join Now"}</button>
                </div>
                </div>
            </Col>
        </Row>
        </Card>
    </div>
  )
}

export default LMHeroSection