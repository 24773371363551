import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import ImgComp from '../SilvaManifestationProgram/ImgComp'

function TrainerSection() {


    return (
        <div className='container maintrainer px-4 mt-3'>
            <div className="row justify-content-center gap-2">
                <div className="col-sm-12 col-md-10 col-lg-8 text-center">
                    {/* <h2 className='mb-4'>Your Trainers</h2> */}
                    {/* <h3 className='mb-4'>Learn from the Best of the Best</h3> */}
                    <div style={{ padding: "0 12px" }}>
                        <HeadingNew title={"Unleash the Magic of Silva with the Best Instructors"} />
                    </div>
                    {/* <p style={{ fontSize: "1.1rem", color: "black" }}>Our role as Silva Method Instructors is to empower minds and contribute to a brighter world for future generations. Unlike any other online meditation classes, we go beyond guidance, addressing your questions and explaining the reasons behind our methods. </p>
                    <p style={{ fontSize: "1.1rem", color: "black" }}>
                        Through comprehensive training and personal experiences, our instructors are driven by the proven effectiveness of the Silva Method. We are dedicated to sharing these transformative techniques with individuals from all walks of life, utilizing captivating, thought-provoking, interactive, and fun teaching approaches.
                    </p> */}

                    <ParaComp
                        white={false}
                        data={[
                            "Through comprehensive training and personal experiences, our instructors are driven by the proven effectiveness of the Silva Method courses. We are dedicated to sharing these meditation techniques with individuals from all walks of life, utilizing captivating, thought-provoking, interactive, and fun teaching approaches.",
                            "As Silva Method Instructors, we empower minds and contribute to a brighter world for future generations. Unlike any other online meditation classes, we go beyond guidance, addressing your questions and explaining the reasons behind our meditation techniques.",

                        ]}
                    />


                    <ImgComp src={require("../../images/500 + Instructors Collage.webp")} link="/instructor/find-instructor" />

                </div>
            </div>
            {/* <Row className="justify-content-center gap-4">

                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((val, i) => (
                        <Col xs={5} sm={5} md={6} lg={3} >
                            <Card style={{ borderRadius: "15px", width: "100%" }}>
                                <Card.Img style={{ borderRadius: "15px" }} variant="top" src="https://storyblok-cdn.The Silva Method.com/f/60579/640x640/8b060c6820/mvcom_hp_author-lisa-nichols.jpg?trans?quality=40&auto=webp&dpr=1&width=280" />
                                <Card.Body>
                                    <h4 style={{ fontWeight: "600" }}>Lisa Nichols</h4>
                                    <p>Motivational Speaker & Influence Expert</p>
                                </Card.Body>
                            </Card>
                        </Col>

                    ))
                }
            </Row> */}
            {/* <Slider {...settings}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].
                    map((val, i) => (
                        <div key={i}>
                            <Card style={{ borderRadius: "15px", width: isDesktopOrLaptop ? "90%" : "98%", height: "auto" }}>
                                <Card.Img style={{ borderRadius: "15px" }} variant="top" src="https://storyblok-cdn.The Silva Method.com/f/60579/640x640/8b060c6820/mvcom_hp_author-lisa-nichols.jpg?trans?quality=40&auto=webp&dpr=1&width=280" />
                                <Card.Body>
                                    <h4 style={{ fontWeight: "600" }}>Lisa Nichols</h4>
                                    <p>Motivational Speaker & Influence Expert</p>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }


            </Slider> */}
            {/* <AboutInstructorCard /> */}
        </div >
    )
}

export default TrainerSection