import React from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
import SeminarHeroSection from './SeminarHeroSection'
import WhatsCovered from './WhatsCovered'
import SilvaBenefits2 from './SilvaBenefits2'
import Testimonials2 from './SilvaBenefits2'
import { useState } from 'react'
import { useEffect } from 'react'
import { reqData } from '../../utils/baseUrl'

function BookingNew() {
  const [data,setData]= useState(null)

  const getData = async()=>{
      try{
          const res = await reqData("settings","POST",{});

          if(res && res.error===false){
              setData(res.data.live_class_payment_amount)
              // console.log(res.data,"dataaa")
          }else{
              console.log(res)
          }

      }catch(err){
          console.log(err)
      }
  }


  useEffect(()=>{
    getData()
  },[])

  return (
    <div style={{
      backgroundColor:"whitesmoke"
    }}>
        <HomeHeader />
        <SeminarHeroSection />
        <WhatsCovered data={data} />
        <Testimonials2 />
        <CustomFooter />
    </div>
  )
}

export default BookingNew