import React from 'react';
import { Image } from 'react-bootstrap';

const BookingFooter = () => {

    const currentYear= new Date().getFullYear();

    return (
        <div className="mt-5">
            <footer className="text-center text-white" style={{
                background:"#fff"
            }}>
                {/* Grid container */}
                <div className="container p-4 pb-0 d-flex justify-content-center align-items-center flex-column">
                    {/* Section: Social media */}
                    <section className="mb-4">
                        {/* Facebook */}
                        <Image
                                src={require("../../images/newimgs/silvamethod-logo.png")}
                                width={250}
                                height={40}
                            />
                    </section>

                    <p style={{
                        color:"black",
                        fontSize:"1rem",
                        fontWeight:"500"
                    }}>
                    For any support, please mail to digital@silvamethod.com Our support team will get back to you within 24 hours. 
                    </p>
                    {/* Section: Social media */}
                </div>
                {/* Grid container */}

                {/* Copyright */}
                <div className="text-center p-3" style={{ backgroundColor: '#6a3093' }}>
                    © {currentYear} Copyright:{" "}
                    <a className="text-white" href="https://silvamethod.com/">
                        silvamethod.com
                    </a>
                </div>
                {/* Copyright */}
            </footer>
        </div>
    );
};

export default BookingFooter;
