import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import SecondHeroSection from '../../Home/Home/Homestudycourses/SecondHeroSection'
import RightLeftSection from './RightLeftSection'
import PaymentSectionTwo from '../../newbooking/PaymentSectionTwo'
import ContactSectionNew from '../../payment/ContactNewSection'
import SilvaLifeFAQ from '../../SilvaManifestationProgram/SilvaLifeFAQ'
import HeadingNew from '../../AboutUs/HeadingNew'
import LoveMindTwoHero from './LoveMindTwoHero'
import WhatIsLoveMind from './WhatIsLoveMind'
import WhatIsLawOfAttraction from './WhatIsLawOfAttraction'
import PerkSectionLoveMind from './PerkSectionLoveMind'
import LoveMindCTA from './LoveMindCTA'
import LoveMindPerks from './LoveMindPerks'
import Tilt from 'react-parallax-tilt';
import AboutInstructorSectionNew from './AboutInstructorSection'
import DraganTestimonials from '../../Draganpages/DraganTestimonials'
import LoveMindBanner from './LoveMindBanner'
import LoveMindProblemsCard from './LoveMindProblemsCard'
import LoveMindPerkSectionNew from './LoveMindPerkSectionNew'
import LoveMindLearnList from './LoveMindLearnList'

function LoveMindTwo() {
    return (
        <div className='parallexnew22'>
            <HomeHeader />
            <LoveMindTwoHero />
            {/* <Featured /> */}

            {/* <div className='container pb-5'>

              
                <LoveMindBanner />
            </div> */}
         
            <WhatIsLoveMind />

            <div className='container mb-5' >
                <Tilt glareEnable={true} tiltEnable={false} glareMaxOpacity={0.2} glareColor="#000" glarePosition="all" glareBorderRadius="20px">
                    <LoveMindCTA maintitle={"“Love Mind” Free Online Masterclass"} fancytitle={"3rd & 9th March"}
                    subTitle={"12 P.M. NYT , 5 P.M. UK"}
                        btnText={"Reserve your spot now"}
                    />

                </Tilt>
            </div>

            <div className='newbgnew'>
                <WhatIsLawOfAttraction />

            </div>
            <PerkSectionLoveMind />
           
            <AboutInstructorSectionNew />

            <LoveMindProblemsCard />
            <LoveMindPerkSectionNew />
            <LoveMindLearnList />
            {/* <LoveMindPerks /> */}

            {/* <RightLeftSection /> */}
            {/* <LeftRightSection /> */}
            {/* <PaymentSectionTwo /> */}
            <DraganTestimonials />

            {/* <div className='container my-5'>
                <HeadingNew small title={"Frequenly Asked Questions"} start />
                <SilvaLifeFAQ />


            </div> */}

            <ContactSectionNew />

            <CustomFooter />
        </div>
    )
}

export default LoveMindTwo