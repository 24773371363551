import React from 'react'
import './newcombo.css'
import NewComboFeature from './NewCombofetures'
import NewComboHero from './NewComboLandingHero'
import OvercomeSection from './OverComeSection'
import Overcome2 from './OverCome2'
import NewBeginnerCombo from './NewBeginnerCombo'
import ComboParentSection from './ComboParentSection'
import LandingHero2 from './LandingHero2'
import Footer from './Footer'
import NewComboFaqs from './NewCombofaq'
import ComboPaymentNew from '../SilvaComboCourse/ComboPaymentNew'

function NewComboLanding() {

  const faqs = [
    {
      id: 1,
      title:
        "What is the Silva Method, and how can it help me overcome addiction?",
      text: `The Silva Method is a dynamic meditation program designed to reprogram the mind. It helps you access altered states of consciousness to break free from addictions and unhealthy habits by targeting their root causes.`,
    },
    {
      id: 2,
      title: "How does the Habit Control Technique work?",
      text: `The Habit Control Technique uses deep meditation at the alpha level of your mind to rewire your thought patterns and eliminate cravings, helping you regain control over your habits.`,
    },
    {
      id: 3,
      title: "Can the Silva Method help with multiple types of addictions?",
      text: `Yes, the Silva Method is effective for various addictions, including smoking, excessive drinking, gambling, unhealthy eating, and more. Its techniques are designed to address the underlying psychological factors behind these habits.`,
    },
    {
      id: 4,
      title: "Is the Silva Method safe to use alongside other treatments?",
      text: `Absolutely. The Silva Method is a non-invasive, holistic approach that complements other treatments. However, it's always a good idea to consult with your healthcare provider.`,
    },
    {
      id: 5,
      title: "How long does it take to see results with the Silva Method?",
      text: `Results vary depending on the individual and the specific habit or addiction. Some people experience changes within a few weeks, while others may take longer. Consistency and practice are key.`,
    },
    {
      id: 6,
      title:
        "Do I need any prior experience with meditation to use the Silva Method?",
      text: `No prior experience is required. The Silva Method is suitable for beginners and provides guided instructions to help you achieve the necessary meditative states.`,
    },
    {
      id: 7,
      title:
        "What makes the Silva Method different from other addiction treatments?",
      text: `The Silva Method uniquely focuses on accessing deeper levels of the mind to change thought patterns at their core. It combines meditation, visualization, and mind-reprogramming techniques to create lasting change.`,
    },
    {
      id: 8,
      title:
        "Can the Silva Method help me if I've tried other methods without success?",
      text: `Yes, many users have found success with the Silva Method after struggling with other approaches. Its unique techniques, such as the Mirror of the Mind and Alpha Level Reprogramming, offer new ways to tackle persistent issues.`,
    },
    {
      id: 9,
      title: "What is the Alpha Level Reprogramming Technique?",
      text: `The Alpha Level Reprogramming Technique involves entering a deeply relaxed state (alpha level) where you can reprogram your mind to release negative habits and reinforce positive behaviors.`,
    },
    {
      id: 10,
      title:
        "How can I get started with the Silva Method to overcome my addiction?",
      text: `Begin your journey with the Silva Method by enrolling in the Beginners Combo Course, which provides in-depth training in mind control techniques. To fully master the program, you can upgrade by enrolling in the Silva Method Advanced Course after completing the beginner’s program.`,
    },
  ];

  return (
    <div>
      <NewComboHero/>
      <NewComboFeature/>
      <OvercomeSection/>
      {/* <LandingHero2/> */}
      <ComboPaymentNew extraClass={'theme-dark'} white={true}/>
      <Overcome2/>
      {/* <ComboParentSection/> */}
      <NewComboFaqs faqs={faqs}/>
      <Footer/>
    </div>
  )
}

export default NewComboLanding