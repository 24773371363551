import React, { useContext } from 'react'
import { AuthContext } from '../../context/AllContext';
import { Card } from 'react-bootstrap';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";


function TandC() {

    const { isMobile, isTablet } = useContext(AuthContext);

    const TandClist= [
        "You have to purchase with a valid ID.",
        "Please cross-check while registering with your email ID.",
        "In case of not get the login credentials, kindly check your spam folder.",
        "It must be within 15 days of your purchase.",
        "If you encounter login issues or experience confusion with courses and haven't received a response to your email sent to digital@silvamethod.com within 48 hours, you may submit a refund request.",
        "Please be aware that purchases of Silva courses made without a coupon code or discount are non-refundable, even if a coupon code or offer becomes available after the purchase.",
        "We reserve the right to modify or discontinue any course or content without prior notice. In such cases, refunds will be provided at our preference.",
    ];


  return (
    <div className="container mb-2 d-flex justify-content-center align-items-center flex-column">
      <h2
        className={`w-${isMobile || isTablet ? "100" : "50"} text-center mb-4 `}
      >
        Terms and condition
      </h2>
      <h4
        className={`w-${isMobile || isTablet ? "100" : "50"} text-center mb-4 `}
      >
        Please read these Terms and Conditions carefully before sending a refund
        request.
      </h4>
      <Card
        style={{
          width: "100%" ,
          padding: "30px 30px",
          //   paddingBottom: isMobile || isTablet ? "0px" : "auto",
          border: "none",
          borderRadius: "15px",
          height:  "auto" ,
          position: "relative",
        }}
      >
        {TandClist?.map((val, i) => (
          <div>
            <p className="d-flex gap-2">
              <span style={{ display: "block" }}>
                <IoMdCheckmarkCircleOutline color="green" />
              </span>{" "}
              {val}
            </p>
          </div>
        ))}
      </Card>

      <Card
        className="mt-3 w-100"
        style={{ width: isMobile ? "100%" : "80%", borderRadius: "15px" }}
      >
        <Card.Body className="d-flex justify-content-center align-items-center flex-column px-5 py-3">
          {/* <blockquote style={{fontSize:"1rem",color:"black",fontWeight:"500"}}>
                        "The greatest discovery you will ever make , is the potential of your own mind." <small style={{marginLeft:"10px",fontSize:"1.2rem"}}><i>-Jose Silva</i></small>
                    </blockquote> */}


            <h6 className='m-0 py-2'>
            We will try our best to proceed with the refund as soon as possible, but in the meantime, we need your support and patience. 
            </h6>

        </Card.Body>
      </Card>
    </div>
  );
}

export default TandC