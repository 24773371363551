import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import HeadingNew from "../../AboutUs/HeadingNew";
import { useMemo } from "react";
import { AuthContext } from "../../../context/AllContext";
import HlsVideoPlayer from "../../Store/SingleLecturePage/HlsVideoPlayer";

function HypnosisReasons() {
  const { isMobile, isTablet } = useContext(AuthContext);

  const data = useMemo(() => {
    return [
      "Decrease stress, anxiety, and depression.",
      "Deeply relax your mind and body.",
      "Increases the power of acceptance",
      "Reprograms the subconscious mind",
      "Release negative thought patterns",
      "Meditate before sleep",
      "Slow down your brain frequencies",
    ];
  }, []);

  const handleScroll = () => {
    const card = document.querySelector(".includelivecheck");
    if (card) {
      card.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="container my-5">
      <Row
        className="container justify-content-center align-items-center"
        style={{
          position: "relative",
        }}
      >
        <Col xs={12} lg={6} className={"order-1"}>
          <div className="d-flex justify-content-start align-items-start">
            <video
              className="custom-single-training-media"
              src="https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4"
              style={{
                width: isTablet || isMobile ? "100%" : "95%",
                borderRadius: 15,
                boxShadow: "0px 3px 17px -3px rgba(0, 0, 0, 0.36)",
                marginBottom: isTablet || isMobile ? "15px" : "0px",
              }}
              autoPlay
              controls
              muted
            />
          </div>
        </Col>
        <Col xs={12} lg={6} className={"order-2"}>
          <HeadingNew small title={"Secure a peaceful sleep with "} start />
          <HeadingNew title={"The Silva Method"} start />

          <Row className="my-3">
            {data.map((val, i) => (
              <Col xs={12} md={6}>
                <div
                  className="d-flex gap-2 mb-1"
                  style={{
                    width: isMobile ? "100%" : "95%",
                    paddingLeft: 10,
                  }}
                >
                  <img src="/img/checkicon2.png" width={25} height={25} />
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "black",
                      fontWeight: "500",
                      margin: "0px",
                    }}
                  >
                    {val}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
          <button
            className="herodiv-becomememberbtnnew"
            style={{
              marginLeft: "0",
            }}
            onClick={handleScroll}
          >
            Enroll now
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default HypnosisReasons;
