import React, { useContext } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import HeadingNew from '../../../AboutUs/HeadingNew'
import ParaComp from '../../../SilvaManifestationProgram/ParaComp'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../../context/AllContext'

function DraganBanner2() {

    const {isMobile}= useContext(AuthContext);

  return (
    <div className="container my-4 mb-5">
            <Row className='justify-content-center align-items-center align-content-center'>
                <Col xs={12} sm={12} md={12} lg={6}>
                   <div className={`d-flex justify-content-${isMobile?"center":"end"} align-items-center pe-3`}>
                   <Image src={"/img/dragan/image-for-dragan-new2.webp"} style={{width:"75%"}}/>
                   </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                    {/* <HeadingNew title={"Game-"} colored={"Changing"} /> */}
                    <div className='my-3 px-3'>
                        <HeadingNew title={"Newest Added Course: "} colored={"Silva Unstress Toolbox"} small start/>
                        {/* <HeadingNew title={"Silva Ultramind ESP System : Trials turned Invention"} small /> */}
                    </div>
                    <div className='px-3'>
                        <ParaComp
                            data={[
                                "Introducing the Silva Unstress Toolbox, a tailored solution crafted for individuals who want to neutralze and elliminate the greatest enemy of their health-chronic stress. Designed by our legendary instructor Dragan Vujovic, mentored by Jose Silva himself. This unique program offers to you liberation from cronic stress, creating thereby the basis for your lasting health. At the same time it empowers you to take control over your life and live it in line with your deepest desires. If you are ready to banish negativity at its core, join us now and conquer stress to emerge stronger than ever before."
                            ]}
                            white={false}
                        />
                   <Link to={"/store/course/unstress-toolbox"}>
                   <button className='herodiv-becomememberbtn mt-3'>
                        Get Access Today
                    </button>
                   </Link>
                    </div>
                </Col>
            </Row>
        </div>
  )
}

export default DraganBanner2