import React, { useState } from 'react';

const ArrowButton = ({showCard,setShowCard,onclick}) => {

  return (
    <div className={showCard?"arrowbtn arrowdirectionleft":"arrowbtn arrowdirectionright"} onClick={onclick}>
    <span></span>
    <span></span>
    <span></span>
</div>
  );
};

export default ArrowButton;
