import React from "react";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'
import { AuthContext } from "../../../context/AllContext";
import ParaComp from "../../SilvaManifestationProgram/ParaComp";
import { FaPhone } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";

function BiggestProblem() {
  const { isMobile } = useContext(AuthContext);

  const techniques = [
    {
      id: 1,
      text: "Glass of water",
    },
    {
      id: 2,
      text: "Mirror of the Mind",
    },
    {
      id: 3,
      text: "Mental Screening",
    },
    {
      id: 4,
      text: "Alarm clock",
    },
    {
      id: 5,
      text: "3 Finger Technique",
    },
    {
      id: 6,
      text: "Mental Video",
    },
    {
      id: 7,
      text: "3 scene technique",
    },
  ];

  const handleScroll= ()=>{
    polyfill()
    const item= document.querySelector('.scrollnewsection');

    scrollIntoView(item, {
      behavior: "smooth",
      block: "end", inline: "nearest"
    });
  }

    const handleDial = () => {
      window.location.href = "tel:+919932600266";
    };

  return (
    <div className={`pb-${isMobile ? "0" : "5"}`}>
      <Container>
        <Row className="align-items-center">
          <Col sm={12} md={6} className={`order-${isMobile ? "2" : "1"}`}>
           <div className="container">
           <p
              className="h1 fw-bold mb-4"
            >
              Solve Your Biggest Problems with The Silva Method
            </p>
         <ParaComp
            data={[
                "Are you facing stress, anxiety, depression, addictions, sleep issues, mood swings, financial struggles, health concerns, self-doubt, or setbacks? Whatever challenges you're dealing with—mental or physical—The Silva Method offers a comprehensive solution.",
                "Backed by over 20 years of extensive research, this method was developed by Jose Silva to empower individuals to take control of their lives. The Silva Method is a powerful self-help program designed to transform your life through dynamic meditation and proven techniques. By setting clear goals and visualizing them with positive beliefs and expectations, you can turn your dreams into reality. Discover the life-changing power of The Silva Method today!"
            ]}
            margin={false}
            white={false}
         />
         <button className="newappointbtn p-2 px-5 d-flex justify-content-center align-items-center my-4" onClick={handleDial}><IoIosCall className="pe-2"/> WhatsApp now</button>
           </div>
          </Col>
          <Col
            sm={12}
            md={6}
            className={`order-${isMobile ? "1" : "2"} mb-${
              isMobile ? "3" : "0"
            } px-${isMobile ?"3":"5"}`}
            style={{borderRadius:"15px"}}
          >
            <img
              src="/img/appointment-solve.webp"
              alt=""
              style={{borderRadius:"15px"}}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BiggestProblem;
