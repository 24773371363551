import React from 'react'
import { useContext } from 'react'
import { Card, Spinner } from 'react-bootstrap';
import "../../Home/Home/Homestudycourses/productsection.css"
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AllContext';
import { requestData } from '../../../utils/baseUrl';

function CourseGridSection({loading,setLoading,listData,setListData,getAllData,tabs,tabid,tab}) {

    const { isMobile } = useContext(AuthContext)

    const navigate = useNavigate()

    const { userData } = useContext(AuthContext);

    const [data, setData] = useState([]);

    const [showMore, setShowMore] = useState(true);

    useEffect(() => {
        getAllData();
    }, [userData]);

    const handleNavigate = async (course_id) => {

     
        if (course_id === "silva-method-complete-course-with-live-interactive-program") {
            navigate("/store/course/combo-plan")
        } else {
            navigate(`/store/course/${course_id}`)
        }
    };


    useEffect(() => {
     

        const filtereddata2 = tab.included_course.map((val)=>val.course_id);

        const newfilter = listData.filter((val)=>filtereddata2.includes(val.course_id)).reverse();


        if (showMore) {
            if (isMobile) {
                setData(newfilter.slice(0, 3))
            } else {
                setData(newfilter.slice(0, 6))
            }
        } else {
            setData(newfilter)
        }
    }, [showMore, listData,tab])



    if (loading) {
        return <div className='d-flex justify-content-center align-items-center h-100'>
            <Spinner animation="border" color='black' />;
        </div>
    }


 

    return (
        <div className='indivcourses' style={{ position: "relative" }}>

            <div className="container-fluid">
                <div className='productcardsection'>
                    {
                        data.map((val, i) => (
                            // <Link to={`/store/course/${val?.course_id}`} key={i}>

                            <Card className='productsinglecard boxshadowclass card-shine' key={i} onClick={() => {
                                handleNavigate(val.course_id);
                            }} style={{
                                backgroundImage: `url(${val?.web_vertical_image || "https://silvamethod.com/manage/assets/uploads/noimg.jpg"})`
                            }}
                            >
                                <Card.Body>
                                    {/* {
                                        (val?.discount_percentage && val?.discount_percentage != 0) && (
                                            <div className='fourthprice'>

                                            </div>
                                        )
                                    }

                                    {
                                        (!val?.discount_percentage || val?.discount_percentage == 0) && (
                                            <div className='fourthprice2'>

                                            </div>
                                        )
                                    } */}

                                    {
                                        val.course_purchased===false && <div className='fourthprices'>


                                            {
                                                (val?.discount_percentage && val?.discount_percentage != 0) && (
                                                    <h3 style={{ textDecoration: "line-through", color: "red" }}>
                                                        <h3 style={{ color: "white" }} className='text-center'>
                                                            ${val?.price}
                                                        </h3>
                                                    </h3>
                                                )
                                            }

                                            {
                                                (val?.discount_percentage && val?.discount_percentage != 0) && (
                                                    <h4 style={{ color: "white" }} className='text-center'>
                                                        ${Math.floor((val?.price) - ((val?.discount_percentage * parseInt(val?.price)) / 100))}
                                                    </h4>
                                                )
                                            }

                                        </div>
                                    }

                                    {/* <div className="fourthprices2">
                                        {
                                            (!val?.discount_percentage || val?.discount_percentage == 0) && (
                                                <h4 style={{ color: "white" }} className='text-center'>
                                                    ${val?.price}
                                                </h4>
                                            )
                                        }

                                    </div> */}

                                    <div className='lastfourthcardsection'>
                                        <h4 className='lastfourthcardheader'>
                                            {
                                                val?.course_title
                                            }
                                        </h4>
                                        {
                                            val?.total_chapters && val?.total_lessons ? (
                                                <h5 className='lastfourthcardheader2'>
                                                    {val?.total_chapters} {val?.total_chapters < 2 ? "Chapter" : "Chapters"} | {val?.total_lessons} {val?.total_lessons < 2 ? "Lesson" : "Lessons"}
                                                </h5>
                                            ) : (
                                                <h5 className='lastfourthcardheader2'>
                                                    48 Chapters | 238 Lessons
                                                </h5>
                                            )
                                        }


                                        <div className='lastfourthsection2'>

                                            {/* 
                                            <button className='herodiv-becomememberbtn3'>
                                                {val?.course_purchased ? "View Course" : "Enroll Now"}
                                            </button> */}
                                            {
                                                <button className='herodiv-becomememberbtn3'>
                                                    {val.course_purchased === true ? "View Course" : "Enroll Now"}
                                                </button>
                                            }


                                            <span className='fourthsectionspan'>
                                                Life Time Access
                                            </span>

                                        </div>

                                    </div>
                                </Card.Body>

                            </Card>
                            // </Link>

                        ))
                    }

                </div>
                <div className='d-flex justify-content-center align-items-center my-3'>
                    <button onClick={() => {
                        setShowMore(!showMore)
                    }}
                        className='primary_btn3'
                        style={{
                            alignSelf: "center"
                        }}
                    >
                        {
                            showMore ? "Show More" : "Show Less"
                        }
                    </button>

                </div>

            </div>

        </div>
    )
}

export default CourseGridSection