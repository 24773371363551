import React from 'react'
import { FcGoogle } from "react-icons/fc";
import { BsFillPhoneFill } from "react-icons/bs";
import {  useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';

function SocialLoginCommon({ fetchSocialLogin, handleShow, activeTab }) {
    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            // console.log(tokenResponse);
            const userInfo = await axios.get(
                'https://www.googleapis.com/oauth2/v3/userinfo',
                { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
            );

            // console.log(userInfo.data, "userData");

            if (userInfo && userInfo.data) {
                fetchSocialLogin(
                    userInfo.data.email,
                    userInfo.data.given_name,
                    userInfo.data.family_name,
                    userInfo.data.picture
                );
            }
        },
        onError: errorResponse => console.log(errorResponse),
    },
    );
    return (
        <>
            {/* <a href="#" className="socialLink">
                <BsFacebook size={30} color='#4267b2' />
                <span>Continue with Facebook</span>
            </a> */}
            <a className="socialLink" onClick={() => loginWithGoogle()}>
                <FcGoogle size={30} />
                <span>Continue with Google</span>
            </a>
            {
                activeTab === "link-1" && (
                    <a className="socialLink" onClick={handleShow}>
                        <BsFillPhoneFill size={30} />
                        <span>Login With OTP</span>
                    </a>
                )
            }
        </>
    )
}

export default SocialLoginCommon