import React from "react";
import { useContext } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import { FaChess, FaSmoking } from "react-icons/fa";
import { BiDrink } from "react-icons/bi";
import { IoLogoGameControllerB } from "react-icons/io";
import { SiProcessingfoundation } from "react-icons/si";
import { MdFastfood } from "react-icons/md";
import { GiThink } from "react-icons/gi";

function NewComboFeature() {
  const { isMobile, isTablet } = useContext(AuthContext);

  const points = [
    {
      id: 1,
      title: "Smoking",
      sympotomps:
        "Persistent cough and shortness of breath, Yellowed teeth, Strong urge to smoke",
      howitfeels:
        "Breathing improves, energy levels rise, and there’s a renewed sense of vitality and health.",
      icon: <FaSmoking className="text-dark" />,
    },
    {
      id: 2,
      title: "Excessive Drinking",
      sympotomps:
        "Increased tolerance, withdrawal symptoms, impaired judgment, and relationship issues.",
      howitfeels:
        "Clarity of mind, improved relationships, and a healthier, more balanced lifestyle.",
      icon: <BiDrink className="text-dark" />,
    },
    {
      id: 3,
      title: "Gambling",
      sympotomps: "Financial problems, anxiety, and compulsive behavior.",
      howitfeels:
        "Greater financial stability, reduced stress, and improved focus on personal goals.Overeating or Unhealthy Eating.",
      icon: <IoLogoGameControllerB className="text-dark" />,
    },
    {
      id: 4,
      title: "Gaming/ Excessive Screen Time",
      sympotomps: "Social withdrawal, decreased productivity, and eye strain.",
      howitfeels:
        "Increased real-world engagement, better productivity, and improved physical well-being.",
      icon: <IoLogoGameControllerB className="text-dark" />,
    },
    {
      id: 5,
      title: "Procrastination",
      sympotomps:
        "Chronic delay in starting tasks, missed deadlines, feelings of guilt or stress, and lower productivity.",
      howitfeels:
        "Enhanced time management, reduced stress, a sense of accomplishment, and increased productivity.",
      icon: <GiThink className="text-dark" />,
    },
    {
      id: 6,
      title: "Overeating/Unhealthy Eating",
      sympotomps:
        "Weight gain, digestive issues, low energy, and emotional eating patterns.",
      howitfeels:
        "Improved digestion, weight management, increased energy, and a healthier relationship with food.",
      icon: <MdFastfood className="text-dark" />,
    },
  ];

  return (
    <div className={`theme-dark pt-5 pb-${isMobile ? "0" : "5"}`}>
      <div className="text-center">
        <p
          className="h1 text-white fw-bold"
          style={{ maxWidth: isMobile ? "75%" : "", margin: "0 auto 20px" }}
        >
          Become Stronger than Your Cravings{" "}
        </p>
        <p
          style={{
            maxWidth: isMobile ? "90%" : "600px",
            color: "white",
            margin: "auto",
          }}
        >
          Strength comes from overcoming addiction and that’s exactly what the
          Silva Method is going to do for you- reprogramming your mind to
          reclaim your life.
        </p>
      </div>
      {!isTablet && !isMobile ? (
        <Container className={`py-${isMobile ? "3" : "5"}`}>
          <Row className="align-items-center">
            <Col
              lg={4}
              sm={12}
              className={`text-${
                isMobile ? "start" : "end"
              } text-lg-end mb-4 mb-lg-0`}
            >
              <div
                className={`feature d-flex flex-lg-row-reverse gap-3 py-${
                  isMobile ? "3" : "4"
                } px-${isMobile ? "3" : "0"}`}
              >
                <div
                  className="hypnosisicon  text-light mb-2"
                  style={{ flex: "none", borderRadius: "20px" }}
                >
                  <FaSmoking className="text-dark" />
                </div>
                <div>
                  <div>
                    <h5 style={{ color: "#f1c40f" }}>Smoking</h5>

                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:
                    </p>
                    <p className="text-light">
                      Persistent cough and shortness of breath, Yellowed teeth,
                      Strong urge to smoke
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:
                    </p>
                    <p className="text-white">
                      Breathing improves, energy levels rise, and there’s a
                      renewed sense of vitality and health.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`feature d-flex flex-lg-row-reverse gap-3 py-${
                  isMobile ? "3" : "4"
                } px-${isMobile ? "3" : "0"}`}
              >
                <div
                  className="hypnosisicon  text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <BiDrink className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>Excessive Drinking</h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Increased tolerance, withdrawal symptoms, impaired
                      judgment, and relationship issues.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Clarity of mind, improved relationships, and a healthier,
                      more balanced lifestyle.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`feature d-flex flex-lg-row-reverse gap-3 py-${
                  isMobile ? "3" : "4"
                } px-${isMobile ? "3" : "0"}`}
              >
                <div
                  className="hypnosisicon  text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <FaChess className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>Gambling</h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Financial problems, anxiety, and compulsive behavior.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Greater financial stability, reduced stress, and improved
                      focus on personal goals. Overeating or Unhealthy Eating.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} sm={12} className="text-center mb-4 mb-lg-0">
              <img
                src={
                  isMobile
                    ? "/img/courseimgs/combo/become-stronger-than-craving for-mobile.webp"
                    : "/img/courseimgs/combo/become-stronger-than-craving copy.webp"
                }
                alt="Main"
                className="img-fluid"
              />
            </Col>
            <Col lg={4} sm={12} className="text-start">
              <div
                className={`feature d-flex gap-3 py-${
                  isMobile ? "3" : "4"
                } px-${isMobile ? "3" : "0"}`}
              >
                <div
                  className="hypnosisicon  text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <IoLogoGameControllerB className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>
                    Gaming/ Excessive Screen Time
                  </h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Social withdrawal, decreased productivity, and eye strain.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Increased real-world engagement, better productivity, and
                      improved physical well-being.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`feature d-flex gap-3 py-${
                  isMobile ? "3" : "4"
                } px-${isMobile ? "3" : "0"}`}
              >
                <div
                  className="hypnosisicon text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <GiThink className="text-black" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>Procrastination</h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Chronic delay in starting tasks, missed deadlines,
                      feelings of guilt or stress, and lower productivity.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Enhanced time management, reduced stress, a sense of
                      accomplishment, and increased productivity.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={`feature d-flex gap-3 py-${
                  isMobile ? "3" : "4"
                } px-${isMobile ? "3" : "0"}`}
              >
                <div
                  className="hypnosisicon text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <MdFastfood className="text-black" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>
                    Overeating/Unhealthy Eating
                  </h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Weight gain, digestive issues, low energy, and emotional
                      eating patterns.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Improved digestion, weight management, increased energy,
                      and a healthier relationship with food.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      {isTablet ? (
        <Container className="my-5">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src="/img/courseimgs/combo/become-stronger-than-craving copy.webp"
              alt="Main"
              className="img-fluid"
            />
          </div>
          <Row className="align-items-center">
            <Col md={6} className="px-3">
              <div
                className={`feature d-flex flex-lg-row-reverse gap-3 py-${
                  isMobile ? "3" : "5"
                } px-${isMobile ? "3" : "0"}`}
              >
                <div
                  className="hypnosisicon  text-light mb-2"
                  style={{ flex: "none", borderRadius: "20px" }}
                >
                  <FaSmoking className="text-dark" />
                </div>
                <div>
                  <div>
                    <h5 style={{ color: "#f1c40f" }}>Smoking</h5>

                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:
                    </p>
                    <p className="text-light">
                      Persistent cough and shortness of breath, Yellowed teeth,
                      Strong urge to smoke
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:
                    </p>
                    <p className="text-white">
                      Breathing improves, energy levels rise, and there’s a
                      renewed sense of vitality and health.
                    </p>
                  </div>
                </div>
              </div>
              <div className={`feature d-flex gap-3 py-3 px-3}`}>
                <div
                  className="hypnosisicon  text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <BiDrink className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>Excessive Drinking</h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Increased tolerance, withdrawal symptoms, impaired
                      judgment, and relationship issues.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Clarity of mind, improved relationships, and a healthier,
                      more balanced lifestyle.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature d-flex gap-3 py-5">
                <div
                  className="hypnosisicon text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <FaChess className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>Gambling</h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Financial problems, anxiety, and compulsive behavior.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Greater financial stability, reduced stress, and improved
                      focus on personal goals. Overeating or Unhealthy Eating.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="p-3">
              <div className="feature d-flex gap-3 py-5">
                <div
                  className="hypnosisicon  text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <IoLogoGameControllerB className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>
                    Gaming/ Excessive Screen Time
                  </h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Social withdrawal, decreased productivity, and eye strain.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Increased real-world engagement, better productivity, and
                      improved physical well-being.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature d-flex gap-3 py-5">
                <div
                  className="hypnosisicon text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <GiThink className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>Procrastination</h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Chronic delay in starting tasks, missed deadlines,
                      feelings of guilt or stress, and lower productivity.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Enhanced time management, reduced stress, a sense of
                      accomplishment, and increased productivity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature d-flex gap-3 py-5">
                <div
                  className="hypnosisicon text-light mb-2"
                  style={{ flex: "none", borderRadius: "25px" }}
                >
                  <MdFastfood className="text-dark" />
                </div>
                <div>
                  <h5 style={{ color: "#f1c40f" }}>
                    Overeating/Unhealthy Eating
                  </h5>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      Symptoms:{" "}
                    </p>
                    <p className="text-light">
                      Weight gain, digestive issues, low energy, and emotional
                      eating patterns.
                    </p>
                  </div>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">
                      Improved digestion, weight management, increased energy,
                      and a healthier relationship with food.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      {isMobile && (
        <div className="p-3">
          {points.map((point, i) => (
            <Accordion defaultActiveKey={0} className="shadow-none newhabit">
              <Accordion.Item eventKey={i}>
                <Accordion.Header>
                  <div className="p-2 bg-warning rounded">{point.icon}</div>
                  <div className="px-3">{point?.title}</div>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="text-white fw-bold text-decoration-underline">
                    Symptoms:{" "}
                  </p>
                  <p className="text-light">{point.sympotomps}</p>
                  <div>
                    <p className="text-white fw-bold text-decoration-underline">
                      How It Feels to Quit:{" "}
                    </p>
                    <p className="text-light">{point.howitfeels}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
          <div className="d-flex align-items-center justify-content-center">
            <img
              src="/img/courseimgs/combo/become-stronger-than-craving for-mobile.webp"
              alt="Main"
              className="img-fluid"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default NewComboFeature;
