import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AllContext';
import { AiFillLock } from "react-icons/ai"
import { Accordion, Badge } from 'react-bootstrap';
import { HiOutlineArrowRight } from "react-icons/hi"
import { BsCheckCircleFill } from "react-icons/bs"

function ResultAccordion({searchResult,searchQuery,course_id,isSubscribed,white=true,data}) {
    //console.log(data.lession);
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })




    if (searchResult && searchQuery) {
        return (
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className='mb-4'>
                    <Accordion.Header>
                        <div className='d-flex justify-content-start flex-column'>
                            {/* <h4 className={`${white?'white-color':''}`}>{data && data.course_id}</h4> */}
                            <h4 className={`accordionheader`}>Results for {searchQuery}</h4>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className='listlesson'>
                        <li>
                            {
                                searchResult && searchResult.map((val, i) => (
                                    <ul className='d-flex justify-content-between mt-3 pb-2' style={{ borderBottom: "2px solid gray" }}>
                                        <div className='d-flex justify-content-start gap-3'>
                                            <img src={val.image || "https://assets.The Silva Method.com/api/v1/assets/c6cfed91-b485-43fc-9284-098f77dde4ff.jpg"} style={{ width: "120px", height: "60px", objectFit: "cover" }} />
                                            <div className='d-flex justify-content-start align-items-start flex-column'>
                                                <p className={`${white?'white-color':''}`} style={{ fontSize: isDesktopOrLaptop ? "1rem" : "0.8rem" }}>{val.lesson_title}</p>
                                                
                                                <p className={`${white?'white-color':''}`} style={{ fontSize: isDesktopOrLaptop ? "1rem" : "0.8rem" }}>
                                                    Chapter Name: {val.chapter_id.replace(/-/g, " ")}</p>
                                                <p style={{ color:white?'white':"black", fontSize: "1.05rem" }}>
                                                    
                                                    Duration: {val.lession_file_duration}</p>
                                            </div>
                                        </div>
                                        {
                                            (isSubscribed || val.preview_available === "Yes") ? (
                                                <div className={`d-flex justify-content-center align-items-center gap-4 ${isDesktopOrLaptop ? "" : "flex-column"}`}>
                                                    <Badge className='badgenew' bg="light" style={{ color: "black" }} onClick={() => navigate(`/store/course/${course_id}/${val.chapter_id}/${val.lesson_id}`, { state: val })}>
                                                        {
                                                            isSubscribed ? "View" : "Preview"
                                                        }
                                                    </Badge>

                                                    {
                                                        val?.lesson_activity_status==="Completed" && (
                                                            <BsCheckCircleFill className="scrollto" size={30} color={white?'white':"#2ecc71"} />
                                                        )
                                                    }

                                                    {
                                                        val?.lesson_activity_status!=="Completed" && (
                                                            <HiOutlineArrowRight size={30} color={white?'white':"black"} onClick={() => navigate(`/store/course/${data.course_id}/${data.chapter_id}/${val.lesson_id}`, { state: val })} />
                                                        )
                                                    }
                                                   
                                                    {/* <HiOutlineArrowRight size={20} color='white' /> */}
                                                </div>
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <AiFillLock size={30} color={white?'white':"black"} />
                                                </div>
                                            )
                                        }
                                    </ul>
                                ))
                            }
                        </li>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }
}

export default ResultAccordion