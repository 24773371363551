import React from "react";
import { useContext } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import HeadingNew from "../../AboutUs/HeadingNew";

function ComboPlusLiveSection4() {
  const { isDesktopOrLaptop, isBigScreen, isTablet, isMobile } =
    useContext(AuthContext);

    const handleScroll = (name) => {
      const descard = document.querySelector(name);
  
      if (descard) {
        descard.scrollIntoView({
          behavior: "smooth",
        });
      }  
    };


  return (
    <div className="container">
      <Row>
        <Col lg={6} md={12} sm={12} className={`p-3`}>
          <Image
            src="/img/online-menifestation/instructor-img.png"
            style={{ borderRadius: "15px", width: "100%", height: "100%" }}
            className="px-2"
          />
        </Col>
        <Col
          lg={6}
          md={12}
          sm={12}
          className={`px-5 py-3 d-flex justify-content-center align-items-center`}
        >
          <div>
            <div className="my-3">
            <HeadingNew title={"Meet the Instructors"} start/>
            </div>
            <p>
              Meet our team of certified manifestation experts who are dedicated
              to helping you unlock your manifesting potential and achieve your
              goals. Our instructors bring a wealth of knowledge and experience
              to the table, with years of experience in teaching manifestation
              techniques and guiding individuals towards their desired outcomes.
              With their expert guidance and support, you'll gain the confidence
              and clarity you need to manifest the life of your dreams with ease
              and grace.
            </p>
            <button className="primary_btn3" onClick={()=> handleScroll('.combo_main_hero_bg2') }>Join Our Manifestation Community</button>
          </div>
        </Col>
        {/* <Col lg={12} md={12} sm={12}>
          <Row>
            <Col lg={6} md={12} sm={12} className={`p-3 ${isMobile || isTablet?"order-2":""}`}>
              <Image src="/img/combo_Collage.png" style={{ borderRadius: "15px",width:'100%',height:'100%' }} className="px-2"/>
            </Col>
            <Col lg={6} md={12} sm={12} className={`px-5 py-3 d-flex justify-content-center align-items-center ${isMobile || isTablet?"order-1":""}`}>
              <div>
                <p>Lorem Ipsam</p>
                <h3>What is Lorem Ipsum?</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries,
                </p>
                <button className="primary_btn3">Enroll Now</button>
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </div>
  );
}

export default ComboPlusLiveSection4;
