import React, {  useEffect, useState } from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import SingleHomeProducts2 from "./SingleHomeProducts2";
import HashLoader from "react-spinners/HashLoader";
import booksdata from "./booksdata.json";
import HomeHeading from "../../Home/Home/HomeHeading";

function ProductSlide() {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1500px)" });
  const isMedium = useMediaQuery({ minWidth: 1280, maxWidth: 1499 });

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });

  const isLargeMob = useMediaQuery({ minWidth: 600, maxWidth: 767 });

  const [products, setProducts] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const getProducts = async () => {
    setIsloading(true);
    setProducts(booksdata);
    setIsloading(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: isDesktopOrLaptop
      ? "100px"
      : isTablet
      ? "35px"
      : isLargeMob
      ? "70px"
      : "70px",
    slidesToShow: isDesktopOrLaptop
      ? 5
      : isMedium
      ? 4
      : isTablet
      ? 3
      : isLargeMob
      ? 2
      : 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
    dots: false,
    autoplay: true,
    slidesToScroll: 1,
  };

  return (
    <div
      className={
        isDesktopOrLaptop
          ? "col-lg-12 gap-3"
          : "col-lg-12 gap-3 not-slide-icon"
      }
    >
     <div className="my-4">
     <HomeHeading title="Best Books Inspired by " colored="The Silva Method" title2=""/>
     </div>
      <h5 className="mt-3 mb-3 text-center">
        Over 12 million people in 110 countries have experienced what Silva
        Method Meditation can do.
      </h5>
    
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <HashLoader color="#36d7b7" size={100} />
        </div>
      ) : (
        <Slider {...settings}>
          {products &&
            products.map((product, i) => (
              <SingleHomeProducts2
                key={product.id}
                data={products.length > 0 ? product : {}}
              />
            ))}
        </Slider>
      )}
    </div>
  );
}

export default ProductSlide;
