import React, { useMemo } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import HeadingNew from '../AboutUs/HeadingNew'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import CheckboxComponent from './CustomCheckbox'

function AboutInstructorSection2() {

    const { isMobile } = useContext(AuthContext);
    const list = useMemo(() => {
        return [
            "For 36 years Dragan has been a prominent figure in personal development, recognised as a sought-after  mental coach and lecturer",
            "With an extensive resume, Dragan has conducted more than 500 live seminars with more than 50 thousand participants, together with countless online courses for public and top company clients",
            "His list of company clients includes Motorola, Deloitte, Amway, GE Money Bank, Just of Switzerland,etc.",
            "As a mental coach in sports Dragan trained teams like A.C. Sparta (soccer), Czech National Soccer Team-U-21, Czech National Hockey Team U-20 with numerous individual trainings in tennis, basketball, golf and ice-hockey. For example, Jakub Mensik, with whom Dragan is working continuously as a mental coach is the best ranked young player on the world ATP list ( 18 yrs, no. 147).",
            "Dragan is an author of 63 products in personal development and mental training, wth topics like ‘ Love Mind’, ’Super Health All Life Long’, ‘ Wealth is Your Natural Right’,etc.",
            "Dragan has been highly sought after as an individual coach in one-on-one mental training. His focus spans marital harmony, health improvement, sales performance, limiting habits change and more."
        ]
    })

    return (
        <Container className='py-5'>
            <div className='mb-2'>
                <h2 className='newpageheader text-center'>About Dragan</h2>
                {/* <HeadingNew title={"About the Lecturer"} /> */}
            </div>

            {/* <p style={{
                color: "black",
                fontSize: "1.2rem",
                fontWeight: "600",
                textAlign: "center"

            }}>
                Dragan Vujovic, Certified Silva Method Instructor, Author, Mental Coach and Personal Development Trainer
            </p> */}
            <div className='container mt-3' style={{
                maxWidth: isMobile ? "100%" : "60%"
            }}>

                <h4 style={{
                    fontWeight: "600",
                    fontSize: "25px",
                    color: "black",
                    marginTop: "20px",
                    textAlign: "center"
                }}>
                   Certified Silva Method Instructor, Author, Mental Coach and Personal Development Trainer
                </h4>
            </div>

            {/* <h5 className='newpageheader text-center'>

            </h5> */}

            {/* <HeadingNew small title={""} /> */}

            <Row className='justify-content-center align-items-center my-5' style={{
                margin: "auto"
            }}>
                <Col xs={12} lg={6} className={isMobile ? 'px-2 order-md-2' : 'px-5 order-md-2'}>
                    <Image
                        className='boxshadow'
                        src='/img/dragan/dragan2.jpg'
                        style={{
                            width: isMobile ? "100%" : "90%",
                            border: "1px solid lightgray",
                            boxShadow: "10px 10px 5px -7px rgba(0,0,0,0.23)",
                            borderRadius: "8px",
                            marginBottom: "15px"
                        }}
                    />
                    <p style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                        color: "black"
                    }}>
                        Dragan together with Novak Djokovic Tennis Legend and World no.1 Player in Belgrade 2022, During One Week of Training Of Novak and Jakub Mensik, Dragan’s mental coaching player
                    </p>

                </Col>
                <Col xs={12} lg={6} className='order-md-1'>
                    {
                        isMobile && (
                            <Row className={`container justify-content-center ${isMobile?"align-items-start":"align-items-center"}`}>

                                {
                                    list.map((val, i) => (
                                        <>
                                            <Col xs={1} md={2}>
                                                {/* <span><Image src='/img/checkicon.png' className='checkimg' /></span> */}
                                                <CheckboxComponent id={i} />
                                            </Col>
                                            <Col xs={11} md={10}>
                                                <p style={{
                                                    marginLeft:isMobile?"10px":"0px",
                                                    marginBottom:isMobile?"20px":"0"
                                                }} className='text-start normalpara'>
                                                    {val}
                                                </p>
                                            </Col>
                                        </>

                                    ))
                                }
                            </Row>
                        )
                    }
                    {
                        !isMobile && list.map((val, i) => (
                            <div className="d-flex justify-content-start align-items-center gap-3" key={i}>
                                {/* <span><Image src='/img/checkicon.png' className='checkimg' /></span> */}
                                <CheckboxComponent id={i} />
                                <p className='text-start normalpara'>
                                    {val}
                                </p>
                            </div>
                        ))
                    }
                    {/* <div>
                        <ParaComp
                            white={false}
                            margin={false}
                            data={[
                                "Meet Dragan Vujovic, a Certified Silva Method Instructor, Author, and renowned Mental Coach with 36 years of expertise in personal development. Widely recognized for his impactful seminars, Dragan has empowered over 50 thousand participants through 500 live sessions and numerous online courses, catering to both public and esteemed corporate clients such as Motorola, Deloitte, Amway, GE Money Bank, and Just of Switzerland.",
                                "As a seasoned mental coach in sports, Dragan has trained teams like A.C. Sparta (soccer), the Czech National Soccer Team-U-21, and the Czech National Hockey Team U-20, along with individual sessions in tennis, basketball, golf, and ice hockey. Notably, he has mentored talents like Jakub Mensik, the top-ranked young player on the world ATP list.",
                                "Beyond coaching, Dragan is an accomplished author of 63 personal development and mental training products, covering topics like 'Love Mind,' 'Super Health All Life Long,' and 'Wealth is Your Natural Right.' His coaching services have transformed the lives of over 400 thousand people, addressing areas such as marital harmony, health improvement, sales performance, and habit change.",
                                "Join Dragan Vujovic as your guide in the transformative Silva Lifesystem course, where he brings his wealth of experience to elevate your personal development journey."
                            ]}
                        />
                    </div> */}
                    <div>
                        <Image
                            src='https://viteznamysl.cz/wp-content/uploads/2014/12/podpis-draganvujovic-web.png'

                        />
                        <Image
                            src='/img/brands.png'
                        />

                    </div>
                </Col>

            </Row>
        </Container>
    )
}

export default AboutInstructorSection2