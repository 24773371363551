import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useContext } from "react";
import { AuthContext } from "../../context/AllContext";

function LandingTwoHero() {
    const { isMobile, isTablet } = useContext(AuthContext);

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymenttwonew");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };
    return (
        <section className={`hero-sectionone container py-${(isMobile) ? 3 : 5}`}>
            <Container fluid>
                <Row className="justify-content-center align-items-center">
                    <Col xs={12} lg={7} className={`mb-${(isMobile || isTablet) ? 2 : 0}`}>
                        <div
                            className="content-container"
                            style={{
                                width: (isMobile || isTablet) ? "97%" : "90%",
                                marginLeft: (isMobile || isTablet) ? "0px" : "40px",
                                marginTop: (isMobile || isTablet) ? "30px" : "0px",
                                textAlign: (isMobile || isTablet) ? "center" : "left",
                            }}
                        >
                            <h2 className="newpageheader">
                                Silva Life System Introductory Class
                            </h2>

                            <h4
                                style={{
                                    fontWeight: "600",
                                    fontSize: "25px",
                                    color: "black",
                                    marginTop: "20px",

                                }}
                            >
                                A free introduction to Silva Life System
                            </h4>

                            <p
                                className="normalpara"
                            >
                                Often, when facing tough times, we ask, "Why me, God?" Well! It's time to shift from blame to empowerment. The Silva Life System tackles modern challenges—stress, anxiety, work-life balance, health, and wealth management. In this introductory session, explore the tangible benefits that the Silva Life System brings to your everyday life. This system provides a holistic approach to enhancing physical and mental well-being. Rooted in ancient wisdom yet tailored for today, it unveils potent techniques aligned with the pace of contemporary living.
                            </p>
                            <button
                                className="herodiv-becomememberbtnnew"
                                onClick={handleScroll}
                            >
                                Reserve Your Spot For <span style={{
                                    color:"yellow",
                                    fontWeight:"bold"
                                }}>
                                Free
                                </span>
                            </button>
                        </div>
                    </Col>
                    <Col xs={12} lg={5}>
                        {/* <div className="video-container"> */}
                        {/* <ReactPlayer
                url="/videos/draganvideo.mp4"
                controls
                width="100%"
                height={600}
                config={{ 
                  file: {
                    attributes: {
                      controlsList: "nodownload", // Hide download button
                    },
                  },
                }}
              /> */}
                        <Image src="https://silvamethod.com/img/dragan/dragan.jpg" />
                        {/* </div> */}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LandingTwoHero;
