import React from 'react'
import TabBars from './TabBars'

function MentalHealthTools() {
    return (
        <div className='container my-5 d-flex justify-content-center align-items-center flex-column'>
            <h1 className='newpageheader text-center'>
                Explore The Silva Life System Techniques
            </h1>
            {/* <p className='normalpara' style={{
                maxWidth:"50%"
            }}>
                Calm makes it easy to find what you're looking for and explore it at your own pace. Whether you want to sleep better, feel more relaxed, or improve your focus and productivity, Calm has something for you.
            </p> */}

            <TabBars />
        </div>
    )
}

export default MentalHealthTools