import React, { useState } from 'react';
import styles from './mindfulform.module.css';
import axios from 'axios';
import toast from 'react-hot-toast';

const MindfulnessSignup = () => {
    const [loading,setLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
        setLoading(true)
        const res = await axios.post("https://silvacasesonline.com:5000/api/v1/admin/private/mindfulpdfdownload",{
            firstname:formData.firstName,
            lastname:formData.lastName,
            email:formData.email
        });
        setLoading(false)

        setFormData({
            firstName:"",
            lastName:"",
            email:""
        })

        toast.success(res.data.message)
        window.open(res.data.downloadLink)
    }catch(err){
        console.log(err)
        toast.error(err.response.data.error)
    }
    console.log('Form submitted:', formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.imageSection}>
          <img 
            src="https://res.cloudinary.com/dzkwltgyd/image/upload/v1729840917/glif-run-outputs/vvqjvpm4jbtgtkwzfuju.jpg" 
            alt="Mindfulness Meditation"
            className={styles.image}
          />
        </div>
        <div className={styles.formSection}>
          <h1 className={styles.heading}>
            <span className={styles.highlight}>FREE</span> Mindfulness Guide Awaits!
          </h1>
          <h2 className={styles.subheading}>
            Download our Mindfulness Exercises and Tips to Apply in your Daily Life for FREE
          </h2>
          
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className={styles.input}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className={styles.input}
                required
              />
            </div>
            <button type="submit" className={styles.button} disabled={loading}>

              {
                loading ? "...loading":"Download PDF"
              }
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MindfulnessSignup;
