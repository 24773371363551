import React, { useContext, useMemo } from "react";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";

function TabBars() {
  const { isMobile, isTablet } = useContext(AuthContext);

  const [activeBtn, setActiveBtn] = useState(1);
  const buttons = [
    {
      index: 1,
      btnText: "Sleep Control",
      text: "The Silva Sleep Control Technique, a form of sleep meditation, promotes natural sleep without reliance on medications. Consistent practice enhances both the quality and duration of sleep. Embrace this method before bedtime to experience organic improvements in your sleep patterns.",
      img: "/img/draganpage/draganpageimgs/sleep-control.png",
    },
    {
      index: 2,
      btnText: "Dream Control",
      img: "/img/draganpage/draganpageimgs/dream-control.png",
      text: "Discover the Silva Dream Control Technique to master the art of dream recall as your personal information hub. This magical technique taps into the depths of your subconscious, providing insights that can shape your waking reality.",
    },
    {
      index: 3,
      btnText: "Clock Exercise",
      img: "/img/draganpage/draganpageimgs/alarm-clock.png",
      text: "Explore the innovative Clock Exercise for seamless morning awakenings. By cultivating mental focus and intention before sleep, this technique empowers you to naturally wake at your chosen time without an alarm clock or the help of others.",
    },
    {
      index: 4,
      btnText: "Headache control",
      img: "/img/draganpage/draganpageimgs/headache-control.png",
      text: "Silva Headache Control Technique, a non-medication approach, empowers individuals to alleviate headaches and migraines through mental techniques. This method harnesses the power of the mind, offering a drug-free alternative for effective relief from head discomfort.",
    },
    {
      index: 5,
      btnText: "Three Fingers Technique",
      img: "/img/draganpage/draganpageimgs/three-finger.jpg",
      text: "The Three Fingers Technique is one of the core techniques of the Silva Life System. This technique can help you to enter the Alpha State of mind. This technique is also a secret weapon against being forgetful. Using it can improve your concentration and help you remember things better.",
    },
    {
      index: 6,
      btnText: "Glass of Water Technique",
      img: "/img/draganpage/draganpageimgs/glass-of-water.jpg",
      text: "The Glass of Water technique is popular for its impressive ability to help with problem-solving and achieving goals. It offers a systematic way to access the potential of your subconscious mind.",
    },
    {
      index: 7,
      btnText: "Mirror of the Mind",
      img: "/img/draganpage/draganpageimgs/mirror-of-the-mind.jpg",
      text: "The mirror of the mind technique helps to enter the Alpha state of mind. In this state, individuals can experience a profound sense of deep relaxation and calmness that helps them transform problems into projects and easily solve them as they desire.",
    },
    {
      index: 8,
      btnText: "Habit control",
      img: "/img/draganpage/draganpageimgs/headache-control.png",
      text: "This powerful tool enables you to reprogram your mind and helps you to deal with bad habits like smoking, drinking, biting nails, and more. Silva Habit Control Technique reshapes thought patterns, offering a science-backed path to a healthier, more fulfilling life.",
    },
  ];

  const imagestyles = useMemo(() => {
    if (isTablet) {
      return {
        display: "flex",
        justifyContent: "center",
      };
    } else {
      return {};
    }
  }, [isTablet, isMobile]);

  return (
    <div className="py-4">
      <div
        style={{
          position: "relative",
        }}
      >
        <Card
          style={{
            width: isMobile || isTablet ? "100%" : "60rem",
            padding: isMobile || isTablet ? "30px 30px 0px 30px" : "50px",
            paddingBottom: isMobile || isTablet ? "0px" : "auto",
            border: "none",
            borderRadius: "15px",
            height: isMobile || isTablet ? "auto" : "600px",
            position: "relative",
          }}
        >
          <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
            {buttons.map((val) => (
              <button
                className={`button-29 ${
                  activeBtn === val.index ? "button-30" : ""
                }`}
                role="button"
                onClick={() => setActiveBtn(val.index)}
              >
                {val.btnText}
              </button>
            ))}
          </div>
          <Card.Body
            style={{
              padding: isMobile || isTablet ? "0" : "30px",
              border: "none",
            }}
            className="d-flex align-items-center"
          >
            <Row
              className={`${
                isTablet
                  ? "justify-content-center align-items-center"
                  : "justify-content-start align-items-center"
              }`}
            >
              <Col
                xs={12}
                lg={6}
                style={{
                  marginTop: isMobile || isTablet ? "50px" : "0px",
                }}
              >
                <h4>{buttons[activeBtn - 1].btnText}</h4>
                <p
                  className="normalpara"
                  style={{
                    textAlign: "left",
                    fontWeight: "400 !important",
                  }}
                >
                  {buttons[activeBtn - 1].text}
                </p>
              </Col>

              {(isMobile || isTablet) && (
                <Col xs={12} lg={6} style={imagestyles}>
                  <img
                    src={buttons[activeBtn - 1].img}
                    style={{
                      // position: "absolute",
                      // bottom: "0",
                      right: "50px",
                      objectFit: "cover",
                      width: "auto",
                      borderTopLeftRadius: "40px",
                      borderTopRightRadius: "40px",
                      height: "400px",
                      // marginTop: isMobile ? "400px" : "0",
                      borderImage: "initial",
                      borderBottom: "none",
                      // borderRadius:"10px",
                      borderTop: "8px solid rgb(222, 222, 222)",
                      borderRight: "8px solid rgb(222, 222, 222)",
                      borderLeft: "8px solid rgb(222, 222, 222)",
                    }}
                  />
                </Col>
              )}
            </Row>
          </Card.Body>
          {!isMobile && !isTablet && (
            <img
              src={buttons[activeBtn - 1].img}
              style={{
                position: "absolute",
                bottom: "0",
                right: "50px",
                objectFit: "cover",
                width: "auto",
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
                height: "400px",
                marginTop: isMobile ? "400px" : "0",
                borderImage: "initial",
                borderBottom: "none",
                // borderRadius:"10px",
                borderTop: "8px solid rgb(222, 222, 222)",
                borderRight: "8px solid rgb(222, 222, 222)",
                borderLeft: "8px solid rgb(222, 222, 222)",
              }}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export default TabBars;
