import React, { useContext } from 'react'
import "./cardlist.css"
import { Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import { useMediaQuery } from 'react-responsive';
import HeadingNew from '../../AboutUs/HeadingNew';
import { BsFillMoonStarsFill } from "react-icons/bs";
import { GiBrain } from "react-icons/gi";
import { GiJumpAcross } from "react-icons/gi";
import { FaRocket } from "react-icons/fa";
import { GiLovers } from "react-icons/gi";
import { GrSteps } from "react-icons/gr";
import { FaPeace } from "react-icons/fa";
import { HiLightBulb } from "react-icons/hi";
import { GiStairsGoal } from "react-icons/gi";
import { FaBrain } from "react-icons/fa";
import { AuthContext } from '../../../context/AllContext';


function BreakThroughs2() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1279 })
  const {isMobile} = useContext(AuthContext);

  const settings = {
    dots: false,
    infinite: true,
    pauseOnFocus:true,
    slidesToShow: isDesktopOrLaptop ? 4 : isTablet ? 2 : 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    
    arrows: false
  };
  const data = [
    {
      img: "/img/horizontalimages/Achieve Inner Peace.webp",
      text: "Achieve Inner Peace",
      icon:<FaPeace color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Activate Intuition.webp",
      text: "Activate Intuition",
      icon:<GiBrain color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Boost Energy.webp",
      text: "Boost Energy",
      icon:<GiJumpAcross color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Boost Self-Esteem.webp",
      text: "Boost Self Esteem",
      icon:<FaRocket color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Create Healthy Relationships.webp",
      text: "Create Healthy Relationships",
      icon:<GiLovers color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Eliminate Stress, Anxiety, Depression.webp",
      text: "Eliminate Stress, Anxiety, Depression",
      icon:<GiBrain color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Grow in Career.webp",
      text: "Grow in Career",
      icon:<GrSteps color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Improve memory and Focus.webp",
      text: "Improve memory and Focus",
      icon:<FaBrain color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Set and focus on Goals.webp",
      text: "Set and focus on Goals",
      icon:<GiStairsGoal color='black' size={30} />
    },
    {
      img: "/img/horizontalimages/Sleep Better.webp",
      text: "Sleep Better",
      icon:<BsFillMoonStarsFill color='black' size={30} />
      
    },
    {
      img: "/img/horizontalimages/Enhance Creativity.webp",
      text: "Enhance Creativity",
      icon:<HiLightBulb color='black' size={30} />
    },
  ]


  return (

    <div style={{
      paddingTop:isMobile?"0px":"60px",
      paddingBottom:isMobile?"0px":"60px"
    }}>
      <div className='container gap-3 d-flex flex-column justify-content-center align-items-center'>
        <HeadingNew title={"Empower and Enrich with"} colored={"The Silva Method"} />
        <p className='text-dark' style={{
          textAlign:"center",
          maxWidth:"80%",
          fontWeight:500,
          fontSize:"1.1rem"
        }}>
          Access The Silva Method Online Meditation Classes and Mind development training for holistic growth. Join a vibrant community of 12M+ students and 500+ certified Silva instructors committed to living a fulfilling life.
        </p>
      </div>
      <Slider {...settings}>

        {
          data.map((val, i) => (
            <div class="card-list">
              <article class="cardrespons">
                <figure class="card-image2222">
                  <img src={val.img} alt="An orange painted blue, cut in half laying on a blue background" />
                </figure>
                <div class="card-header2222">
                
                  <a href="#" style={{
                    color: "black",
                  }}>
                     {val.text}
                  </a>
                  <span>
                    {val.icon}
                  </span>
                </div>

              </article>
            </div>
          ))
        }
      </Slider>


    </div>
  )
}

export default BreakThroughs2