import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import CustomTimeLineComp from './CustomTimeLineComp';
import Badge from './Badge';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';

function MindControlTechniques() {
    const { isMobile } = useContext(AuthContext);


    const techniquesData = [
        "Developing and sustaining a positive mental attitude to help you deal with challenging situations in life.",
        "Discovering what faith is and how you can use your mind to help you guide in life.",
        "Reducing and managing negative emotions such as stress, anxiety, and depression.",
        "Strengthening your immune system and overcoming health conditions without medications and lengthy doctor appointments.",
        "Managing a healthy sleep pattern and overcoming conditions like insomnia without any drugs.",
        "Having the ability to wake up with new-found strength and energy naturally without the use of supplements",
        "Gathering significant pieces of information or insights by recalling and programmings dreams to solve a problem",
        "Getting rid of tension or mastering the ability to manage migraine headaches without drugs",
        "Developing and sustaining a positive mental attitude to help you deal with challenging situations in life.",
        "Discovering what faith is and how you can use your mind to help you guide in life.",
        "Reducing and managing negative emotions such as stress, anxiety, and depression.",
        "Strengthening your immune system and overcoming health conditions without medications and lengthy doctor appointments.",
        "Managing a healthy sleep pattern and overcoming conditions like insomnia without any drugs.",
        "Having the ability to wake up with new-found strength and energy naturally without the use of supplements",
        "Gathering significant pieces of information or insights by recalling and programmings dreams to solve a problem",
        "Getting rid of tension or mastering the ability to manage migraine headaches without drugs"
    ];


    return (
        <div className='py-5 position-relative'>
            
            {
                !isMobile && (
                    <div style={{ position: "absolute", top: 500, right: 0 }}>
                <img src="/img/icon/img-21.webp" alt="" style={{ width: "100%", maxWidth: "200px" }} />
            </div>
                )
            }
            <div className='container'>
                <HeadingNew title={"Discover Your True Potential with"} small />
                <HeadingNew title={"Mind Control "} colored={"Techniques"} />
                <ParaComp
                    data={[
                        "The Silva Mind Control Method, authored by Jose Silva, delves into unlocking the latent capacities of the human mind. Jose dedicated decades to studying and refining mind control techniques. His groundbreaking book, the culmination of 24 years of research and practical application, transformed the notion of dynamic meditation in the United States. It also sparked the inception of America's 1st personal growth program",
                        "The revelation of mind control techniques took the scientific community, media, and prominent figures by surprise, triggering an incredible aftermath. These techniques centered around accessing the Alpha or subconscious level of the mind, enabling the reprogramming of cognitive functions for enhancement and improvement.",
                        "Furthermore, this transformative process has the potential to revolutionize your life in not just 99 ways, but beyond. However, the ultimate step, the 100th way, lies in your actions and intentions when utilizing these techniques. While the mind control methods offer limitless potential, they remain mere philosophies if not implemented.",
                        "Furthermore, this transformative process has the potential to revolutionize your life in not just 99 ways, but beyond. However, the ultimate step, the 100th way, lies in your actions and intentions when utilizing these techniques. While the mind control methods offer limitless potential, they remain mere philosophies if not implemented."
                    ]}
                    white={false}
                    fw={"500"}
                />
                        <div>
                            <img src="/img/img-27.webp" alt="" style={{ width: "100%", height: "100%", }} />
                        </div>
                   
                <div className='mt-5'>
                    <h5 className='text-black text-center'>Profound Changes in Life with</h5>
                    <HeadingNew title={"The Silva Mind Control "} colored={"Method & Training"} small />
                </div>
                <div className='d-flex justify-content-center align-items-center my-4'>
                    <Badge text='Imagine' />
                </div>

                <div className='d-flex justify-content-center align-items-center'>
                    <CustomTimeLineComp data={techniquesData} />
                </div>
            </div>
        </div>
    )
}

export default MindControlTechniques