import React from 'react'
import { Button, Col, Image, Row } from 'react-bootstrap'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import HeadingNew from '../AboutUs/HeadingNew'
import { useMemo } from 'react'
import { useEffect } from 'react'
import Featured from '../../components/featured/Featured'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import ReactPlayer from 'react-player'
import './EmbeddedVideo.css'; // Create a separate CSS file for styling
import ResponsiveEmbed from 'react-responsive-embed'

function SeminarHeroSectionTwo() {
    const { isMobile } = useContext(AuthContext);

    useEffect(() => {
        // Add the 'animate' class to trigger the animation when the component mounts
        document.querySelector('.underline').classList.add('animate');
    }, []); // The empty dependency array ensures that this effect runs once on mount

    const list = useMemo(() => {
        return [
            "Better health and immunity",
            "Optimize memory and productivity",
            "Reach goals quicker, & solve problems creatively",
            "Leverage increased levels of energy",
            "Free your mind of negative beliefs and behaviors"
        ]
    }, [])

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymenttwonew");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth"
            })
        }
    }
    return (
        <div className='py-5 textureback d-flex justify-content-center align-items-center flex-column'>
            <div className='d-flex justify-content-center align-items-center gap-4 mb-4'>
                <Image
                    src={require("../../images/newimgs/silvamethod-logo.png")}

                />
            </div>
            <h1 className='newpageheader'>The Silva Life System</h1>
            <p style={{
                fontWeight: "600",
                fontSize: "25px",
                color: "black",
                marginTop: "20px",
                textAlign: "center"
            }} className=''>Unlock the  Infinite Potential of Your Mind
                through the Silva Method! </p>


            <div className='container mt-3' style={{
                maxWidth: isMobile ? "100%" : "50%"
            }}>
                {/* <ParaComp
                    white={false}
                    center
                    margin={false}
                    data={[
                    ]}
                /> */}
                <p style={{
                    fontSize: isMobile ? "1.2rem" : "1.5rem",
                    textAlign: "center",
                    // fontWeight: "500",
                    lineHeight: isMobile ? "30px" : "35px"
                }}>
                    "In this exclusive live class, you'll learn the simple formulas to Unlock the path of Happiness, Abundance and Fulfilment to revitalize and rejuvenate yourself."
                </p>

            </div>

            {/* content to copy */}

            {/* <Row className='container justify-content-center align-items-center mt-3'>
                <Col xs={12} md={6}>
                    <Image
                        src='/img/dragan/dragan.png'
                        style={{
                            width: '95%',
                            height: '100%',
                            border: "1px solid gray",
                            boxShadow: "10px 10px 26px -4px rgba(0,0,0,0.2)",
                            borderRadius: "10px",

                            objectFit: 'cover', // Make sure the image covers the entire container
                        }}
                    />

                </Col>
                <Col xs={12} md={6}>
                    <div className='mobilemargin'>
                        <h2 className='newpageheader'>Exclusive 4 Days of Class with</h2>
                        <h2 className='newpageheader mb-4'><span className='underline'>Dragan Vujovic</span></h2>
                        {
                            isMobile && (
                                <Row className='justify-content-start align-items-center'>

                                    {
                                        list.map((val, i) => (
                                            <>
                                                <Col xs={2}>
                                                    <span><Image src='/img/checkicon.png' className='checkimg' /></span>
                                                </Col>
                                                <Col xs={10}>
                                                    <p className='text-start mt-3' style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                                                        {val}
                                                    </p>
                                                </Col>
                                            </>

                                        ))
                                    }
                                </Row>
                            )
                        }
                        {
                            !isMobile && list.map((val, i) => (
                                <div className="d-flex justify-content-start align-items-center gap-3" key={i}>
                                    <span><Image src='/img/checkicon.png' className='checkimg' /></span>
                                    <p className='text-start mt-3' style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                                        {val}
                                    </p>
                                </div>
                            ))
                        }
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "15px"
                        }}>
                            <Button variant="warning" size="lg" block style={{
                                width: "250px",
                                fontWeight: "600",
                                borderRadius: "13px"
                            }}
                                className='paybtn'
                                onClick={handleScroll}
                            >
                                Enroll Today
                                <br />
                                $700
                            </Button>
                        </div>

                    </div>

                </Col>


            </Row> */}

            {/* <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "15px"
            }}>
                <Button variant="warning" size="lg" block style={{
                    width: "250px",
                    fontWeight: "600",
                    borderRadius: "13px"
                }}
                    className='paybtn'
                    onClick={handleScroll}
                >
                    Secure your spot

                </Button>
            </div> */}
            {/* <div className="bannernew">
                <h1>Register Now!</h1>
                <p>Limited Seats Available</p>
                <Button variant="warning" size="lg">
                    Register
                </Button>
            </div> */}

            <div className='video-container22 mt-4'>

                {/* <iframe
                    title="Embedded Video"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Br5Hqtp03po"
                    frameBorder="0"
                    allowFullScreen
                ></iframe> */}
                <ResponsiveEmbed
                    ratio="16:9"
                    src="https://www.youtube.com/embed/Br5Hqtp03po"
                    allowFullScreen
                />
            </div>

            {/* content to exit */}
        </div>
    )
}

export default SeminarHeroSectionTwo