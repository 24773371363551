import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import BlackFridayHeroSection from './BlackFridayHeroSection'
import "./BlackFridaySection.css"
import ProductSection from './ProductSection'
import { BlackFridayTimer, ProductGrid } from './CardAndTimer'
import { StatisticsDisplay } from './StatsDisplay'
import Slider1 from '../../CoursePage2/Slider1'
import BecomeMemberSection from '../../silvamembership/BecomeMemberSection'
import NewHomeHeroSection from '../../CoursePage2/NewHomeHeroSection'
import HomePageHeroSection from '../../CoursePage2/HomePageHeroSection'
import BonusSection from '../ComboCourse2/BonusSection'
import AddOn from '../ComboCourse/AddOn'
import ComboTestimonials from '../SilvaComboCourse/ComboTestimonials'
import MobileSection from '../../CoursePage2/MobileSection'
import AboutSilvaMethod from '../../CoursePage2/AboutSilvaMethod'
import SilvaComboFAQ from '../ComboCourse/SilvaComboFAQ'
import BecomeMember from '../../Home/Home/BecomeMember'
import Slider1ForBlackFriday from '../../CoursePage2/Slider1ForBlackFriday'
import BecomeMemberSection2 from '../../silvamembership/BecomeMemberSection2'
import BlackFridayComboSection from '../../CoursePage2/BlackFridayHeroSection'
import AddOn2 from '../ComboCourse2/BonusSection2'
import SilvaComboFAQ2 from '../ComboCourse/SilvaComboFAQ2'
import HeadingNewBlackFriday from '../../AboutUs/HeadingNewBlackFriday'
import BecomeMember2 from '../../Home/Home/BecomeMember2'

function BlackFridaySale() {
  return (
    <div>
        <HomeHeader />
        <BlackFridayHeroSection />
        <ProductSection />


      {/* <NewHomeHeroSection /> */}
   

      <div className='ps_wrapper'>
      <div className='container py-5'>
        <HeadingNewBlackFriday title={"Frequently Asked Questions"} white />
      <SilvaComboFAQ2 />


      </div>
      </div>

   
      <BecomeMember2 />
      
        <CustomFooter />
    </div>
  )
}

export default BlackFridaySale