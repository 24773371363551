import React, { useContext } from 'react'
import SeminarHeroSectionTwo from './SeminarHeroSectionTwo'
import Featured from '../../components/featured/Featured'
import BannerBooking from './BannerBooking'
import VideoTableSection from './VideoTableSection'
import PaymentSectionTwo from './PaymentSectionTwo'
import IndividualSection from './IndividualSection'
import AboutInstructorSection2 from './AboutInstructorSection2'
import TestimonialsSection from './TestimonialsSection'
import BenefitsSectionNew from './BenefitsSectionsNew'
import SilvaLifeFAQ from '../SilvaManifestationProgram/SilvaLifeFAQ'
import HeadingNew from '../AboutUs/HeadingNew'
import { FaStar } from "react-icons/fa";
import SocialSection from './SocialSection'
import AfterOrderSection2 from './AfterOrderSection2'
import { Col, Image, Row } from 'react-bootstrap'
import BookingFooter from './BookingFooter'
import GallerySection from './GallerySection'
import ImageBannerSection from '../About/ImageBannerSection'
import HeadingNew2 from '../booking/HeadingNew2'
import { AuthContext } from '../../context/AllContext'
import DuartionCard from './DuartionCard'
import { FaCalendarAlt } from "react-icons/fa";
import { MdPhoneAndroid, MdOutlineTimer } from "react-icons/md";
import { useEffect } from 'react'
import DraganPageFaq from '../SilvaManifestationProgram/DraganPageFaq'
import Testimonials from './Testimonials'
import { useState } from 'react'
import JoinWhatsAppModal from '../Draganpages/ModalPage'
import BonusCard from '../Draganpages/BonusCard'



function BookingNewTwo() {



  const { isMobile } = useContext(AuthContext);

  const [show, setShow] = useState(false);


  const onHide = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, [])


  return (
    <div style={{
      backgroundColor: "whitesmoke"
    }}>
      {/* <HomeHeader /> */}
      <SeminarHeroSectionTwo />

      <BannerBooking />

      <div className='mt-4 d-flex justify-content-center align-items-center gap-2'>
        <span>
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
        </span>
        <h6 style={{
          color: "black",
          fontSize: isMobile ? "12px" : "20px",
          fontWeight: "600",
          marginTop: isMobile ? "10px" : "10px"
        }}>Silva Method got featured on</h6>
        <span>
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
          <FaStar color='#f1c40f' size={isMobile ? 15 : 30} />
        </span>
      </div>
      <Featured />


      {/* <SocialSection /> */}

      <IndividualSection />
      <VideoTableSection />
      <BonusCard/>
      <AboutInstructorSection2 />
      <Testimonials />

      <BenefitsSectionNew />

      <div className='my-5'>
        <Row>
          <Col md={6} lg={4}>
            <DuartionCard title={"Duration"}
              icon={<MdOutlineTimer size={50} color='orange' />}
              br={true}
              text={["4 Days, Via Zoom live (16+ Hours)", "2 Weeks , For In-person classes"]} />
          </Col>
          <Col md={6} lg={4}>
            <DuartionCard title={"Validity"}
              icon={
                <FaCalendarAlt size={50} color='orange' />
              }
              br={true}

              text={["After you will become a Silva Graduate, You can join every week the Silva Graduate meets for your entire Lifetime for assistance."]} />
          </Col>
          <Col md={6} lg={4}>

            <DuartionCard title={"Course Fees : "}
              icon={<MdPhoneAndroid size={50} color='orange' />}

              text={[<div class="style-1">
              <del>
                <span class="amount">$1400</span>
              </del>
              <ins>
                <span class="amount">$700</span>
              </ins>
            </div>, "Instant money back gurantee , terms & conditions applied"]} />

          </Col>


        </Row>

      </div>

      <ImageBannerSection />




      <TestimonialsSection />

      <AfterOrderSection2 />
      <GallerySection />

      {/* <SeminarHeroSection /> */}


      <PaymentSectionTwo />
      <div className="container my-5 d-flex justify-content-center align-items-center flex-column">
        {/* <HeadingNew2
          title={"Certification Awaiting!"}
        /> */}
        <h2 className='newpageheader'>
          Certification Awaiting!
        </h2>
        <div className='mt-4'>
          <h4 style={{
            fontWeight: "600",
            fontSize: "25px",
            color: "black",
            marginTop: "20px",
            textAlign: "center"
          }}>
            You are worth it! You will become a internationally certified Silva Graduate on the completion of the full Silva Life and Intuition System.
          </h4>
          {/* <HeadingNew2
            small
            title={"You are worth it! You will become a internationally certified Silva Graduate on the completion of the full Silva Life and Intuition System."}
          /> */}
        </div>
        <Image
          src={"/img/programs/certificate.jpg"}
          width={isMobile ? 350 : 450}
          className="mt-4"


        />




      </div>
      <div className='container'>
        <HeadingNew small start title={"Frequently Asked Questions"} />
        <DraganPageFaq />
      </div>
      <BookingFooter />
      <JoinWhatsAppModal show={show} onHide={onHide} handleShow={handleShow}  />
      {/* <Testimonials2 /> */}
      {/* <CustomFooter /> */}
    </div>
  )
}

export default BookingNewTwo