import React, { useContext } from 'react'
import { useMediaQuery } from 'react-responsive';
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from 'react-slick';
import mem1 from "../../images/membership/1 Sleep Better.webp";
import mem2 from "../../images/membership/2 Boost Energy.webp";
import mem3 from "../../images/membership/3 Enhance Creativity.webp";
import mem4 from "../../images/membership/4 Eliminate Stress, Anxiety, Depression.webp";
import mem5 from "../../images/membership/5 Grow in Career.webp";
import mem6 from "../../images/membership/6 Improve memory and Focus.webp";
import mem7 from "../../images/membership/7 Create Healthy Relationships.webp";
import mem8 from "../../images/membership/8 Achieve Inner Peace.webp";
import mem9 from "../../images/membership/9 Set and focus on Goals.webp";
import mem10 from "../../images/membership/10 Boost Self-Esteem.webp";
import mem11 from "../../images/membership/11 Activate Intuition.webp"
import HomeHeading from '../Home/Home/HomeHeading';
import HomeHeading3 from '../Home/Home/HomeHeading3';
import HeadingNew from '../AboutUs/HeadingNew';
import { AuthContext } from '../../context/AllContext';


function BreakThroughs() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1440px)' });
    const isSmallDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });

    const {isMobile} = useContext(AuthContext);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: isDesktopOrLaptop ? 4 : isSmallDesktop ? 3 : isTablet ? 3 : 2,
        slidesToScroll: isDesktopOrLaptop ? 2 : 1,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 4000,
        cssEase: "linear",
        centerMode: false,
        // centerPadding: isDesktopOrLaptop?'10%':"0%",
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };
    // const imgstyle = {
    //     borderRadius: "10px",
    //     marginLeft: "10px"
    // }

    const imgstyle = { width: "98%", borderRadius: "10px", height: "auto" }

    return (

        <div className="application container" style={{
            marginTop:isMobile?"30px":"60px"
        }}>

            <div className='container mt-2'>
                <div className='row justify-content-center'>
                    <div className="col-sm-12 col-md-9">
                        {/* <p className='text-center' style={{ fontSize: "2.2rem", lineHeight: "3rem", fontWeight: "700", color: "black" }}>
                    </p> */}

                        {/* <h2 
                            style={{
                                fontWeight: "800",
                                fontSize: isDesktopOrLaptop ? "60px" : isSmallDesktop ? "50px" : isTablet ? "40px" : "30px",
                                margin: "0 0 0 20px",
                                textAlign: "center"
                            }}
                        >

                            <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Empower and Enrich</span>{" "}  with Silva Mind Control Method

                        </h2> */}

                        <HeadingNew title={"Empower and Enrich with"} colored={"The Silva Method"} />

                        {/* <HomeHeading3 colored={"The Silva Method"} title={"Empower and Enrich with "} /> */}

                        <h5 className="mt-3 mb-3 text-center robotofont" style={{
                     lineHeight: isMobile?"1.3rem":"1.5rem",
                     fontSize:isMobile?"0.9rem":"1rem"
                 }}>
                      Access The Silva Method Online Meditation Classes and Mind development training for holistic growth.
                      Join a vibrant community of 12M+ students and 500+ certified Silva instructors committed to living a fulfilling life.
                 </h5>
                       
                    </div>
                </div>
            </div>
            <div>
                <div className='memberslider container my-4'>
                    <Slider {...settings}>
                        <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
                            <img
                                src={mem1}
                                alt=""
                                style={imgstyle}
                            />
                            <img
                                src={mem2}
                                alt=""
                                style={imgstyle}
                            />
                        </div>
                        <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
                            <img
                                src={mem3}
                                alt=""
                                style={imgstyle}
                            />
                            <img
                                src={mem4}
                                alt=""
                                style={imgstyle}
                            />
                        </div>
                        <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
                            <img
                                src={mem5}
                                alt=""
                                style={imgstyle}
                            />
                            <img
                                src={mem6}
                                alt=""
                                style={imgstyle}
                            />
                        </div>

                        <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
                            <img
                                src={mem7}
                                alt=""
                                style={imgstyle}
                            />
                            <img
                                src={mem8}
                                alt=""
                                style={imgstyle}
                            />
                        </div>

                        <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
                            <img
                                src={mem9}
                                alt=""
                                style={imgstyle}
                            />
                            <img
                                src={mem10}
                                alt=""
                                style={imgstyle}
                            />
                        </div>

                        <div className='d-flex justify-content-center align-items-center flex-column gap-1'>
                            <img
                                src={mem11}
                                alt=""
                                style={imgstyle}
                            />
                            <img
                                src={mem1}
                                alt=""
                                style={imgstyle}
                            />
                        </div>

                    </Slider>
                </div>
            </div>
        </div>

    )
}

export default BreakThroughs