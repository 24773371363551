import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import { Link, useNavigate } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { useMemo } from 'react'

function PopularSilvaTechniques() {
    const navigate = useNavigate()
    const data = useMemo(() => {
        return [
            {
                img: require("../../images/techniques/New folder (2)/img-1.jpg"),
                title: "Sleep Control",
                desc:"The sleep control technique is designed to help you master your internal dialogue. Learn to sleep naturally without the use of medicines."
            },
            {
                img: require("../../images/techniques/img-1.jpg"),
                title: "Transform Stress",
                desc:"The Silva Method provide you with the knowledge and techniques necessary to cultivate stress resilience and attain a harmonious state of mind."
            },
            {
                img: require("../../images/techniques/img-2.jpg"),
                title: "Dream Control",
                desc:"Through consistent practiceof Silva Mind Control Method, learn to recall your dreams and use them to solve problems in real life problems."
            },

            {
                img: require("../../images/techniques/New folder (2)/img-2.jpg"),
                title: "Headache relief",
                desc:"Through consistent practice of mindfulness trainings, and the effective use of visualization, individuals can significantly decrease muscle tension, alleviate stress, and reduce the occurrence of headaches and migraines without using medicines."
            },
            {
                img: require("../../images/techniques/img-8.jpg"),
                title: "Long Relaxation Exercise",
                desc:"The Silva Method provides guided meditation to manage stress, anxiety, and depression and quiet your mind for the experience of deep and long relaxation"
            },
            {
                img: require("../../images/techniques/img-9.jpg"),
                title: "Alarm Clock Technique",
                desc:"Practicing guided meditation techniques such as the Jose Silva Method can progressively improve your capacity to awaken at your intended time without relying on an alarm clock."
            }
        ]
    }, [])
    return (
        <div className='popularsilva thirdsectionhero'>
            <div className="container my-5">
                <HeadingNew title={"Popular Silva Techniques for"} colored={"Better Sleep"} />
            </div>

            <div className="container">
                <div className='productcardsection'>
                    {
                        data.map((val, i) => (
                            <Link to={`/store/course/combo-plan`} key={i}>

                                <Card className='productsinglecard boxshadowclass' key={i} style={{
                                    backgroundImage: `url(${val?.img})`
                                }}
                                >
                                    <Card.Body>

                                        <div className='lastfourthcardsection'>

                                            <h4 className='lastfourthcardheader'>
                                                {
                                                    val?.title
                                                }
                                            </h4>

                                            <p className='lastfourthcardheader2'>
                                                {
                                                    val.desc
                                                }
                                            </p>

                                            <div className='lastfourthsection2'>
                                                <button className='herodiv-becomememberbtn3'>
                                                    Enroll Now
                                                </button>
                                            </div>

                                        </div>
                                    </Card.Body>

                                </Card>
                            </Link>

                        ))
                    }
                </div>
            </div>
            <div className='lastfourthsection2 d-flex justify-content-center align-items-center mb-4'>
                <button className='herodiv-becomememberbtn mt-3 mb-2' onClick={()=>navigate("/store/course/combo-plan")}>
                    Explore Other Techniques
                </button>
            </div>
        </div>
    )
}

export default PopularSilvaTechniques