

import React, { useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../context/AllContext';

function DoctorConsultSection() {

  const { isMobile } = useContext(AuthContext);



  const styles = useMemo(() => {

    return {
      bannerContainer: {

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto',
        padding: isMobile ? "30px 20px" : "40px 20px",
        // backgroundColor: '#4CAF50',
        background: " linear-gradient(to right, rgb(238 237 255), rgb(255 248 249))",
        color: 'white',

        textAlign: 'center',
        borderRadius: isMobile ? "10px" : '15px',
        marginTop: isMobile ? "0px" : "70px",
      },
      title: {
        fontSize: '36px',
        marginBottom: '20px',

      },
      button: {
        fontSize: '18px',
        padding: '10px 20px',
        backgroundColor: '#ffffff',
        color: '#4CAF50',
        border: 'none',
        borderRadius: '5px',
        marginTop: "20px",
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
      }
    }
  }, [isMobile]);

  return (
    <div className='container my-5'>
      <div style={styles.bannerContainer}>
        <h2 className="heading-large text-black">When to Consult A Doctor?</h2>

        <p className="robotofont">
          If you've tried the stress-relieving techniques mentioned above but still find yourself struggling with symptoms, it may be time to see a doctor. They can prescribe medication, suggest therapy, or recommend additional lifestyle changes to help lower your anxiety levels.
        </p>
      </div>
    </div>
  );
}



export default DoctorConsultSection;
