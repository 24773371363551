import React from 'react'

function ParaComp2({data=[],white=true}) {
  return (
    <div>
        {
            data.map((val,i)=>(
                <p style={{ fontSize: "14px", marginTop: "20px",textAlign:"start",color:white?"white":"black" }} key={i}>
                {val}
            </p>
            ))
        }
    </div>
  )
}

export default ParaComp2