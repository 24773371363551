import React from 'react'
import { Card } from 'react-bootstrap'


function AboutCard2({ btnText = "", titleText = "", para, btn, data,height,width }) {


    return (
        <Card style={{ width: width||"100%", height: height||"auto", borderRadius: "15px" }} className='cardhover22'>
            <Card.Body>
                <div className='d-flex justify-content-start align-items-center'>
                    {
                        btnText && (
                            <button className='styledbtn newstylebtn mt-2'>
                                {
                                    btnText
                                }
                            </button>
                        )
                    }
                    <h3 className='mt-3 headingnew2222' style={{ marginLeft: "20px", fontWeight: "700", fontFamily: "Poppins" }}>
                        {titleText}
                    </h3>
                </div>
                <div style={{ padding: "10px 20px 0 20px" }}>
                    {
                        data ? (
                            <div>
                                {
                                    data.map((val, i) => (
                                        <p key={i} style={{ color: "black", fontFamily: "Poppins" }} className='para2222'>
                                            {val}
                                        </p>
                                    ))
                                }
                            </div>
                        ) : (
                            <p style={{ color: "black", fontFamily: "Poppins" }} className='para2222'>
                                {para}
                            </p>
                        )
                    }
                </div>

            </Card.Body>
        </Card>
    )
}

export default AboutCard2