import React from "react";
import { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { AuthContext } from "../../../context/AllContext";

function CourseCurriculumCard({ data, index }) {
  const { isMobile, isTablet } = useContext(AuthContext);

  return (
    <div className="container mt-4 bg-white" style={{ borderRadius: "10px" }}>
      <Row
        style={{
          borderRadius: "10px",
          height: "auto" ,
        }}
      >
        <Col
          md={3}
          className="text-white p-3 pt-4 d-flex justify-content-center align-items-center flex-column bg-grad borderradiuscard"
        >
          <p className="white-color">Part {index}</p>
        </Col>
        <Col
          md={8}
          className="p-3 d-flex justify-content-center align-items-start flex-column bg-white"
        >
          {data?.content?.map((el, i) => (
            <div className="d-flex justify-content-center align-items-center gap-3">
              <span
                style={{
                  minWidth: "40px",
                }}
              >
                <FaCheckCircle color="green" size={30} className="mr-3" />
              </span>

              <p className="text-start  normalpara">{el}</p>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
}

export default CourseCurriculumCard;
