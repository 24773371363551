import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useContext } from 'react'
import { FaCheckCircle } from "react-icons/fa";
import { useMemo } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew';
import { AuthContext } from '../../../context/AllContext';
import MyVerticallyCenteredModal from '../../Unstress-landing/SubmitModal';
import { useState } from 'react';
import { useEffect } from 'react';

function SpanishLearnSection2() {
    const { isMobile } = useContext(AuthContext);
    const [modalShow, setModalShow] = React.useState(false);

    // const handleScroll = () => {
    //     const cardscroll = document.querySelector(".paymenttwonew2");

    //     if (cardscroll) {
    //         cardscroll.scrollIntoView({
    //             behavior: "smooth"
    //         })
    //     }
    // }

    const handleScroll = () => {
        const cardscroll = document.querySelector(".paymentsection");

        if (cardscroll) {
            cardscroll.scrollIntoView({
                behavior: "smooth",
            });
        }
    };


    const checkData = useMemo(() => {
        return [
            "Encontrarás alivio para los dolores de cabeza, las migrañas y el dolor físico sin medicamentos.",
            "Aprenderás a controlar los malos hábitos, las adicciones, el peso y más.",
            "Controlarás los problemas de insomnio de forma natural y aprenderás a despertarte a la hora deseada.",
            "Perfeccionarás tu visualización y habilidades intuitivas para una mejor toma de decisiones.",
            "Desarrollarás la confianza en ti mismo y la autoestima para enfrentar los desafíos de la vida con tranquilidad.",
            "Dominarás la capacidad de entrar en un profundo estado de relajación en cualquier momento y en cualquier lugar.",
            "Te embarcarás en un viaje fantástico, expandiendo horizontes mentales más allá del tiempo y el espacio",
            "Conectarás con la fuente de energía universal para obtener orientación e información.",

        ]

    }, [])

    const checkData2 = useMemo(() => {
        return [
            "Dominarás la proyección mental sobre plantas, mascotas, metales y humanos para obtener puntos de referencia subjetivos y recibir información.",
            "Utilizarás “Percepción Extra Sensorial” para localizar objetos perdidos y ayudarás a otros a recuperar información vital.",
            "Desarrollarás la clarividencia, identificando el sonido, el olor, la solidez, y la temperatura de manera única.",
            "Crearás laboratorios mentales y visualizarás consejeros para resolver problemas."
        ]

    }, [])


    const [data, setData] = useState(checkData)

    const [readMore, setReadMore] = useState(true);


    useEffect(() => {
        if (!readMore) {
            setData([
                ...checkData,
                ...checkData2
            ])
        } else {
            setData(checkData)
        }
    }, [readMore])


    return (

        <>
            <div className='container my-4'>
                <Row className='justify-content-center align-items-center'>
                    <Col xs={12} md={6} className={isMobile ? 'order-2' : 'order-1'}>
                        <div className={`${isMobile ? 'px-4' : 'px-4 py-5'}`}>
                            <HeadingNew
                                title={"Beneficios de la Masterclass en vivo"}
                                start
                            />



                            <div className='my-3'>
                                {
                                    data.map((val, i) => (
                                        <div className="d-flex justify-content-start align-items-center gap-3">
                                            <span style={{
                                                minWidth: "20px"
                                            }}>
                                                <FaCheckCircle color="green" size={20} className="mr-3 mb-3" />

                                            </span>
                                            <p className="text-start normalpara" style={{
                                                // fontWeight:"600",
                                                // fontSize:"1.1rem"
                                            }}>
                                                {val}
                                            </p>
                                        </div>
                                    ))
                                }


                                {
                                    readMore ? (
                                        <div>
                                            <a style={{
                                                color: "blue",
                                                cursor: "pointer"
                                            }}
                                                onClick={() => {
                                                    setReadMore(false)
                                                }}

                                            >Read More</a>
                                        </div>
                                    ) : (
                                        <div>
                                            <a style={{
                                                color: "blue",
                                                cursor: "pointer"

                                            }}
                                                onClick={() => {
                                                    setReadMore(true)
                                                }}

                                            >Read Less</a>
                                        </div>
                                    )
                                }
                            </div>


                            <button className="herodiv-becomememberbtnnew mt-2" onClick={() => {
                                if (isMobile) {
                                    setModalShow(true)
                                } else {
                                    handleScroll()
                                }
                            }}>
                                Enlístate ahora
                            </button>
                        </div>


                    </Col>
                    <Col xs={12} md={6} className={isMobile ? 'order-1' : 'order-2'}>
                        {/* <MasonaryGrid /> */}
                        <div className="containernewnew">
                            <div className="box box1">
                                <img src='/img/img4.jpg'
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                        boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"
                                    }}
                                />


                            </div>
                            <div className="box box2">
                                <img src='/img/img6.jpg'
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                        boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"

                                    }}
                                />
                            </div>
                            <div className="box box3">
                                <img
                                    src=
                                    '/img/img5.jpg'
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                        boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"

                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default SpanishLearnSection2