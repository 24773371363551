import React, { useContext } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AllContext';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';

function SubscriptionFailure() {
    const navigate = useNavigate();

    useEffect(()=>{
        Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: 'Payment Failed',
            showConfirmButton: true,
            showConfirmButton: true,
            allowOutsideClick:false,
            allowEscapeKey:false,
            allowEnterKey:false
          }).then((result) => {
            if (result.isConfirmed) {
              // if(userData){
              //   navigate("/today")
              // }else{
              //    navigate("/")
              // }
              navigate("/silva-method-courses")
            }
          })
    },[])
  return (
    <div>
      <HomeHeader />
    </div>
  )
}

export default SubscriptionFailure