import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { allcodes } from '../../utils/allCountryCodes';
import { useEffect } from 'react';
import { reqData } from '../../utils/baseUrl';
import toast from 'react-hot-toast';
import HeadingNew from '../AboutUs/HeadingNew';
import Swal from 'sweetalert2';

function FormExample({ validated, setValidated }) {
  const [loading, setLoading] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false)

  const [values, setValues] = useState({
    page: "unstress-toolbox",
    name: "",
    email: "",
    country_code: "+91",
    whats_app: ""
  });

  const [acceptTandC, setAcceptTandC] = useState(false);


  const handleSubmit = async (event) => {

    // const form = event.currentTarget;

    // if (form.checkValidity() === false) {
    //   event.preventDefault();

    //   event.stopPropagation();
    // } else {
    //   toast.error("Please enter the required fields")

    // }
    event.preventDefault();





    const checkvalues = Object.values(values).every((val) => !!val);




    if (checkvalues) {
      setValidated(true)
      setLoading(true)
      const res = await reqData("/LeadGenerationForm", "POST", values);
      setLoading(false)

      if (res && res.error === false) {
        setValues({
          name: "",
          email: "",
          country_code: "+91",
          whats_app: ""
        })
        Swal.fire({
          title: "Thanks for submitting the form",
          icon: "success"
        });
        setTimeout(() => {
          window.open('https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ')
        }, 3000)
      } else {
        toast.error(res.error)
      }
    } else {
      toast.error("Please enter all the fields");
      return
    }

    // Swal.fire({
    //   title: "Thanks for submitting the form",
    //   icon: "success",
    //   confirmButtonText: "Join Silva Method International Whatsapp Group",
    // }).then((res)=>{
    //   if(res.isConfirmed){
    //     window.open('https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ')
    //   }
    // })

    // Swal.fire({
    //   title: "Thanks for submitting the form",
    //   icon: "success",
    // });

    // setTimeout(()=>{
    //   window.open('https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ')
    // },3000)
  };







  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className={`p-4 newform`}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
          <Form.Label className='white-color'>Full Name <span className='text-danger'>*</span></Form.Label>
          <Form.Control required type="text" placeholder="Full Name" value={values.name} onChange={(e) => {
            setValues({
              ...values,
              name: e.target.value
            })
          }} />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12" controlId="validationCustom02" className="mb-3">
          <Form.Label className='white-color'>Email Address <span className='text-danger'>*</span></Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Email Address"
            value={values.email}
            onChange={(e) => {
              setValues({
                ...values,
                email: e.target.value
              })
            }}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03" className="mb-3">
          <Form.Label className='white-color'>Country Code <span className='text-danger'>*</span></Form.Label>
          <Form.Select required>
            {
              allcodes.map((val) => (
                <option value={val.name} selected={val.code === "US"} onChange={(e) => {
                  setValues({
                    ...values,
                    country_code: val.dial_code
                  })
                }}>{val.name}</option>
              ))
            }
          </Form.Select>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12" controlId="validationCustom04" className="mb-3">
          <Form.Label className='white-color'>Whatsapp number <span className='text-danger'>*</span></Form.Label>
          <Form.Control required type="text" placeholder="Phone" value={values.whats_app} onChange={(e) => {

            setValues({
              ...values,
              whats_app: e.target.value
            })
          }} />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
        <Form.Check
          required
          label="Agree to terms and conditions"
          className='white-color'
          feedback="You must agree before submitting."
          feedbackType="invalid"
          onChange={() => setAcceptTandC(!acceptTandC)}
        />
      </Form.Group>
      <button className="herodiv-becomememberbtn" onClick={handleSubmit} disabled={loading}>
        {
          loading ? '...loading' : 'Submit Form and Join WhatsApp Group'
        }
      </button>
    </Form>
  );
}

export default FormExample;
