import React, { useContext } from "react";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import CustomFooter from "../Home/Home/HomeFooter/Footer";
import { AuthContext } from "../../context/AllContext";

function WorkBook() {
  const { isUserLoggedIn, userData,isMobile } = useContext(AuthContext);

  return (
    <div>
      <HomeHeader />
      <div
        className="container d-flex justify-content-center align-items-center flex-column"
        style={{
          marginTop: "100px",
          marginBottom: "50px",
        }}
      >
        {!isUserLoggedIn && (
          <button className="herodiv-becomememberbtn">
            Please login to view the content
          </button>
        )}
        {isUserLoggedIn && userData && userData.pdf_link ? (
            <iframe
              title="PDF Viewer"
              src={userData.pdf_link}
              width="100%"
              height={isMobile?"350px":"600px"}
            />
        ) : (
          <h4>You dont have permission to view this content</h4>
        )}
      </div>
      <CustomFooter />
    </div>
  );
}

export default WorkBook;
