import React from "react";
import SleepMeditationHero from "./SleepMeditationHero";
import TrustedBy from "../../Draganpages/TrustedBy";
import SecurePeaceSection from "./SecurePeaceSection";
import BenifitSectionHyp from "../HypnosisPage/BenefitsSectionHyp";
import WhySection from "./WhySection";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import HypnoisComboCard from "../ComboCourse/HypnosisComboCard";
import { reqData } from "../../../utils/baseUrl";
import { useEffect } from "react";
import PopularSilvaTechniques from "../../seopages/PopularSilvaTechniques";
import AlphaStateOfMind from "../../seopages/AlphaStateOfMind";
import FormSection from "../HypnosisPage/FormSection";
import NewFooter from "../hypnosisPage2/NewFooter";
import NewComboBanner from "../ComboCourse/NewComboBanner";

function SleepMeditation() {
  const [show, setShow] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userData, isBigScreen, isDesktopOrLaptop, isTablet } =
    useContext(AuthContext);

  const getCourseData = async () => {
    setLoading(true);
    const res = await reqData("courseDetail", "POST", {
      course_id: "combo-plan",
    });

    // console.log(res.data[0].course_description, "Response")

    setLoading(false);
    if (res.error === false) {
      setCourseData(res.data);
    }
  };

  useEffect(() => {
    getCourseData();
  }, [userData]);

  return (
    <div>
      <SleepMeditationHero />
      <TrustedBy />
      <SecurePeaceSection />
      <BenifitSectionHyp />
      {/* <NewComboBanner/> */}
      <WhySection />
      {courseData && courseData[0] && (
        <HypnoisComboCard
          data={courseData}
          showCard={showCard}
          setShowCard={setShowCard}
        />
      )}
      <AlphaStateOfMind />
      <PopularSilvaTechniques />
      <div className="d-flex justify-content-center align-items-center">
        <img
          src="/img/hypnosis/info2.png"
          style={{
            borderRadius: 10,
          }}
        />
      </div>
      <FormSection title={"Submit your details to receive a free Silva Method sleep meditation"}/>
      <NewFooter/>
    </div>
  );
}

export default SleepMeditation;
