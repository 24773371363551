import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import HeadingNew from '../AboutUs/HeadingNew';
import { AuthContext } from '../../context/AllContext';
import AppointmentFormModal from '../CoursePage2/DownloadModal';
import { useState } from 'react';

function NewCourseDetailsBannerSection({courseData,isCurrentCoursePurchased}) {
    const {isMobile} = useContext(AuthContext);


    const [modalShow, setModalShow] = useState(false);



    const handleDownload = () => {
        // Handle download logic here
      
        setModalShow(true)
    };

    if(!courseData){
        return null;
    }

    return (
      <>
        <div className='container py-5 bannerdownloadcurri'>
            <div className='d-flex justify-content-center align-items-center'>
                <Card style={{
                    width: "100%",
                    padding: isMobile ? "10px 17px" : "20px 30px",
                    borderRadius: "15px",
                    backgroundImage: "url('/img/bg/Image20240911162303.jpg')",
                    boxShadow: "0px 9px 30px rgba(111, 30, 81, 0.5)",
                    border: "2px solid #f7de4e",
                    backgroundSize: 'cover',
                    backgroundPosition: isMobile?"top":'center',
                    position: 'relative',
                }}>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.3)',
                        borderRadius: "13px",
                    }} />

                    <Card.Body style={{
                        position: "relative",
                        zIndex: 1,
                    }}>
                        <Row className='align-items-center'>
                            <Col md={4}>
                                <img src='/img/bg/img-29.webp' style={{
                                    borderRadius: "15px",
                                    width: '100%',
                                    height: 'auto',
                                    transform:"scaleX(-1)"
                                }} alt="Course Image" />
                            </Col>
                            <Col md={8}>
                                <div className='d-flex align-items-end flex-column' style={{
                                    marginTop: isMobile ? "10px" : "0px"
                                }}>
                                    <div style={{
                                        width: "97%"
                                    }}>
                                        <HeadingNew title={"Get to know the course"}
                                            start
                                            white
                                        />
                                        <h5 className='robotofont my-3 white-color' style={{
                                            fontSize: "1rem",
                                            lineHeight: "1.3rem"
                                        }}>
                                            Discover the curriculum of {courseData?.course_title} and unlock the secrets to mastering your mind and achieving personal success. Download our comprehensive PDF guide to explore the powerful techniques you'll learn, from mental clarity and stress relief to enhanced intuition and goal achievement. Click below to get started on your journey to a better you!
                                        </h5>
                                        <button className='cssbuttons-io-button5 cssbuttons-io-button56 mt-1' onClick={()=>{
                                            if(isCurrentCoursePurchased){
                                                window.location.href = "https://silvamethod.com/silva-complete-curriculum.pdf"

                                            }else{
                                                handleDownload()
                                            }
                                        }}>
                                            Download course curriculum
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </div>
          <AppointmentFormModal show={modalShow}
          onHide={() => setModalShow(false)} />
      </>
    );
}

export default NewCourseDetailsBannerSection;