import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import LoveMindOneHero from './LoveMindOneHero'
import "../lovelandingone.css"
import LearningSectionLM1 from './LearningSectionLM1'
import { useState } from 'react'
import { reqData } from '../../../utils/baseUrl.js'
import { useEffect } from 'react'
import ContactSectionNew from '../../payment/ContactNewSection.js'
import Featured from '../../../components/featured/Featured.js'
import BenefitsLPNewSection from './BenefitsLPNewSection.js'
import LMHeroSection from './LMHeroSection1.js'
import ParaComp from '../../SilvaManifestationProgram/ParaComp.js'
import LMHero2 from './LMHero2.js'
import CourseCurriculum from './CourseCurriculum.js'
import AboutInstructorLM from './AboutInstructor.js'
import LMPaymentSection from './LMPaymentSection.js'
import LoveMindCTA from '../Two/LoveMindCTA.js'
import Tilt from 'react-parallax-tilt';
import LoveMindProblemsCard from '../Two/LoveMindProblemsCard.js'

function LoveMindOne() {
    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const benifitsData=[
        {
            id:1,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:'Neutralize stress- the greatest enemy of your health.'
        },
        {
            id:2,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:'Get rid of guilt feelings and blaming in your relationship with yourself and others'
        },
        {
            id:3,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:'Discover the 4 key principles of healthy self-esteem; the crucial relationship in your life.'
        },
        {
            id:4,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:'Cultivate communication skills that foster deep connections and elevate relationships to new levels.'
        },
        {
            id:5,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:'Develop strategies to overcome limiting habits, paving the way for personal growth and fulfillment.'
        },
        {
            id:6,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:'Find out the Formula for Success in Life, from genuine desire to decisive action, serving as your compass in navigating any situation.'
        },
        {
            id:7,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:'Learn effective methods for gathering information and making decisions when faced with uncertainty or dilemmas.'
        },
        {
            id:8,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:"Discover the 3 life purposes and 3 highest laws of life to align with the Universe, God, or the concept of the ‘whole’."
        },
        {
            id:9,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:"Foster meaningful connections with parents, children, siblings, friends, colleagues, and all beings through love and care."
        },
        {
            id:10,
            image:'https://img.freepik.com/free-photo/frustrated-man-with-laptop_1368-4973.jpg?w=826&t=st=1707472341~exp=1707472941~hmac=434f63bd6475226cbfe35792a5eb610e56beb6856d1b2c5108da296f87b153b9',
            text:"Learn to show unconditional love to all existence."
        },
        
    ];

    const textSectionData=[
        <ParaComp
            data={['Experience a profound transformation through “Love Mind”, a remarkable program within the Silva Method. This masterful program is crafted by Dragan Vujovic, a top Silva Method instructor renowned for his expertise. He has taught more than 500 live courses and created 63 programs in the area of mental training and personal development since 1987.']}
            margin={false}
            fw={400}
            white={true}
        />,
        ,
        <ParaComp
            data={['Love Mind will equip you with the deepest laws and principles governing relationships, enabling you to understand their essence and build them into your life. Whether forging new connections or nurturing existing ones, this program enables you to cultivate harmony, fulfillment, and unity in every aspect of your life.']}
            margin={false}
            fw={400}
            white={true}
        />,
        <ParaComp
            data={['Through a blend of unique mental, communicational, and emotional techniques,  you will be able to navigate your life in the ‘ocean of relationships’, creating harmony, fulfillment and unity.']}
            margin={false}
            fw={400}
            white={true}
        />
    ];

    const whoForData=[
        {
          id:1,
          text:'Who is searching for his/her soulmate'
        },
        {
          id:2,
          text:'Someone desiring long-lasting relationships'
        },
        {
          id:3,
          text:'Those who are curious about their connection with the Universe'
        },
        {
          id:4,
          text:'One who can not balance Work and Personal Life'
        },
        {
          id:5,
          text:'Want to Improve health, relationships, and other areas of life'
        },
        {
            id:6,
            text:'Who has a Fixed mindset'
          },
          {
            id:7,
            text:'Who has self-doubts'
          },
          {
            id:8,
            text:'Who suffers from stress and anxiety'
          },
          {
            id:9,
            text:"One who can't get out of his guilt feelings"
          },
          {
            id:10,
            text:"Who endures restless nights due to Worries"
          },
          {
            id:11,
            text:"One who is seeking inner peace and happiness"
          },
      ]
    

    const textSectionData2=[
        <ParaComp
            data={["Dragan Vujovic is a Certified Silva Method Instructor. He stands as a towering figure in the realm of personal development and mental coaching. With an illustrious career spanning 36 years, he has established himself as a legendary figure in the field. He and Jose Silva, the founder of the Silva Method, were close friends, and he received direct training from him. Dragan has conducted 500+ live seminars with 50K+ participants and offers numerous online courses for both public and corporate clients. He has authored 63 products on personal development and mental training, including topics like ‘The Unstress Toolbox', 'Wealth is Your Natural Right', and ‘Self-Change as the Key to Success’, etc. He provides group and personal mental training for sales, management, relationships, personal growth, sports, health, communication, learning, and stress management. DraganV has developed 'Top Mental Training', a unique system for maximizing brain usage. As an expert relationship development and self-esteem mentor, He launched the 'Love Mind', a specialized product aimed at discovering the deepest principles and laws that regulate all of our relationships, fostering an understanding of our connection with the universe, and attaining inner peace."]}
            margin={false}
            fw={400}
            white={false}
        />,
        
    ];



    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "silva-method-complete-course-with-live-interactive-program",
        });
        setLoading(false);
        if (res.error === false) {
            setCourseData(res.data);
        }
    };

    useEffect(() => {
        getCourseData()
    }, []);



    const handleScroll = () => {
        const cardscroll = document.querySelector(".lovemindpayment");
    
        if (cardscroll) {
          cardscroll.scrollIntoView({
            behavior: "smooth",
          });
        }
      };


    return (
        <div style={{
            // backgroundImage:"linear-gradient(to top, #cd9cf2 0%, #f6f3ff 100%)"
            background: "#f0e6fa"
        }}>
            <HomeHeader />
            <LoveMindOneHero />
            <Featured />
            <div className='container mb-5' >
                <Tilt glareEnable={true} tiltEnable={false} glareMaxOpacity={0.2} glareColor="#000" glarePosition="all" glareBorderRadius="20px">
                    <LoveMindCTA maintitle={"“Love Mind” Main Masterclass"} fancytitle={"16th, 17th, 23rd & 24th March"}
                        subTitle={"12 P.M. NYT , 5 P.M. UK"}
                        btnText={"Reserve your spot now"}
                        onClick={handleScroll}
                    />

                </Tilt>
            </div>

            {/* <div className='container pb-5'>
                <SecondHeroSection />
            </div> */}
            {/* <ComboHero2 data={courseData} isCurrentCoursePurchased={false} /> */}
            {/* <LMBookingForm/> */}
            <div className='lovemindpayment'>
            {/* <PaymentSectionTwo /> */}
            <LMPaymentSection/>
            </div>

            <div className='container py-5'>
                <LearningSectionLM1 />
                <LMHeroSection white title={'Master the Energy in Your Relationships'} textSecData={textSectionData} btnText={"Join Now"} image={'/img/love_mind/image2.webp'} onclick={handleScroll}/>
                {/* <HowIsServiceSection /> */}
                {/* <WhereUse data={whoForData} title={'Who is this masterclass for?'}/> */}
                <LoveMindProblemsCard onClick={handleScroll}/>
                <BenefitsLPNewSection data={whoForData} title={'Who is this masterclass for?'} image={'/img/love_mind/image3.webp'}/>
                <LMHero2/>
                {/* <LearningSectionLM1 /> */}
            </div>
            <BenefitsLPNewSection data={benifitsData} colored={"Love Mind"} image={'/img/love_mind/image5.webp'}/>
            {/* <BenefitsSectionLM1 benifitsData={benifitsData}/> */}
           <CourseCurriculum/>
           <AboutInstructorLM/>
            {/* <PopularSilvaTechniques /> */}

            {/* <div className='bg-slider'>
                <div className='container py-5'>
                    <TestiMonialsSectionLM1 />
                </div>
            </div> */}
            {/* <ReviewsContainer /> */}
            <ContactSectionNew />
            <CustomFooter />
        </div>
    )
}

export default LoveMindOne