import React from 'react';

export const StatisticsDisplay = () => {
  const stats = [
    { number: '12M+', label: 'SILVA GRADUATES WORLDWIDE', icon: "/img/blackfriday/icons/Silva Graduates Worldwide.webp" },
    { number: '120+', label: 'COUNTRIES', icon: '/img/blackfriday/icons/Countries.webp' },
    { number: '100+', label: 'SILVA INSTRUCTORS WORLDWIDE', icon: '/img/blackfriday/icons/instructors.webp' },
  ];

  return (
    <div className="bf_container_y6">
      <div className="sts_inner_m4">
        {stats.map((stat, index) => (
          <div key={index} className="sts_item_p2">
            <div className="sts_content_b7">
              <img className="sts_icon_r5" src={stat.icon} style={{
                width:"50%"
              }} />
              <br />
              <h2 className="sts_number_h6">
                <span className="sts_value_t3">{stat.number}</span>
              </h2>
              <p className="sts_label_n9">{stat.label}</p>
              <div className="sts_highlight_v2"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
