import React from 'react'

function LoggedInHeroSection2({text}) {
  return (
    <div className='container mt-4'>
        <h5 style={{marginLeft:"8px",color:"#000"}}>{text}</h5>
    </div>
  )
}

export default LoggedInHeroSection2