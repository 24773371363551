import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import HeadingNew from "../../AboutUs/HeadingNew";
import { AuthContext } from "../../../context/AllContext";
import { useContext } from "react";

function BenefitsTick({ text }) {
    return (
        <div className='d-flex justify-content-center align-items-center gap-3'>
            <span className='text-center'>
                <FaCheckCircle color='#801cb6' size={30} />
            </span>
            <p style={{
                fontWeight: "500",
                color: 'black',
                fontSize: 14,
                fontFamily: "Poppins"
            }}>
                {text}
            </p>


        </div>
    )

}

function BenefitsLPNewSection({ data, image, title, colored,white }) {
  const imgArray = [
    "https://img.freepik.com/free-photo/portrait-person-practicing-yoga-outdoors-nature_23-2151046729.jpg?t=st=1707723916~exp=1707727516~hmac=da1308ed74fd434a3855f25e822c8e66699e4cbae3b24cd064007a55b97b3e6b&w=1380",
    "https://img.freepik.com/free-photo/person-practicing-yoga-meditation-outdoors-nature_23-2150838405.jpg?t=st=1707723919~exp=1707727519~hmac=fdb197c6f271015b169798aa2f4eac89f83aa836b228c74b5541e81c529033ef&w=1380",
  ];

  const { isMobile } = useContext(AuthContext);

  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplaySpeed: 4000,
    cssEase: "linear",
    autoplay: true,
  };

  return (
    <div className="container">
        <HeadingNew
          title={title || "Explore the Benefits of"}
          colored={colored || ""}
          small
          // white={white?true:false}
        />

        <Row className="justify-content-between align-items-center p-5">
          <Col xs={12} md={12} lg={6} className="d-flex justify-content-center">
            <div className="d-flex justify-content-center align-items-start flex-column mb-5">
              {data.map((el, i) => (
                <div>
                  <BenefitsTick text={el?.text} />
                  {/* <p>{el.text}</p> */}
                </div>
              ))}
            </div>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={image || imgArray[0]}
                style={{
                  width: "90%",
                  height: "90%",
                  objectFit: "cover",
                  borderRadius: 10,
                }}
              />
            </div>
          </Col>
        </Row>
    </div>
  );
}

export default BenefitsLPNewSection;
