import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";

function LastHeading({
  title,
  colored,
  small = false,
  boxed = false,
  boxedtext = "",
  white = false,
  bottomMargin = false,
  start = false,
  marginleft = false,
}) {
  const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } =
    useContext(AuthContext);
  return (
    <>
      <h2
        style={{
          fontWeight: small?"700":"800",
          fontSize: isBigScreen
            ? small
              ? "30px"
              : "50px"
            : isDesktopOrLaptop
              ? small
                ? "20px"
                : "50px"
              : isTablet
                ? small
                  ? "20px"
                  : "40px"
                : isMobile
                  ? small
                    ? "20px"
                    : "40px"
                  : "20px",
          textAlign: start ? "start" : "center",
          color: white ? "white" : "#000",
          marginBottom: bottomMargin ? "25px" : "0",
          marginLeft: (marginleft && !isMobile) ? "18px" : "0px",
          lineHeight: !small ?isBigScreen ? "56px" : isDesktopOrLaptop ? "50px" : isTablet ? "35px" : "40px":
          isBigScreen?"35px":"25px",
          letterSpacing:(isMobile || isTablet) ? "0.1px":"0.5px"

        }}
      >
        {title}{" "}
        {colored && (
          <div
            style={{
              color: white ? "#d801fb" : "rgb(128, 28, 182)",
              fontWeight: "800",
              fontFamily: "Poppins",
              display: "inline-block",
            }}
          >
            {colored}
          </div>
        )}{" "}
        {boxed && (
          <p
            style={{
              padding: "16px 20px",
              color: "#000",
              border: "none",
              backgroundColor: "#f1c40f",
              fontSize: isBigScreen
                ? "40px"
                : isDesktopOrLaptop
                  ? "30px"
                  : isTablet
                    ? "26px"
                    : "20px",
              fontWeight: "800",
              borderRadius: "8px",
              display: "inline-block",
            }}
          >
            {boxedtext}
          </p>
        )}
      </h2>
    </>
  );
}

export default LastHeading;