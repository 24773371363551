import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AllContext';

const CommonPageHeader = ({title,subtitle}) => {

   const {isMobile,isLaptop} = useContext(AuthContext);




   return (
      <>
         <section className={"breadcrumb-bg"} style={{paddingTop:isLaptop?"80px":"30px",paddingBottom:isLaptop?"10px":"30px"}}>
            <div className="container">
               <div className="row">
                  {/* <div className="col-lg-9 col-md-9">
                     <div className="page-title">
                        <h1>{title}</h1>
                     </div>
                  </div> */}
                  <div className="d-flex justify-content-center align-items-center">
                     <div className="page-breadcumb">
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb ">
                              <li className="breadcrumb-item">
                                 <Link to="/">Home</Link>
                              </li>
                              <li className="breadcrumb-item active" aria-current="page">{subtitle}</li>
                           </ol>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonPageHeader;