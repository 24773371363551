import { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import AllContext, { AuthContext } from "../../../context/AllContext";


const TestimonialsHero = () => {
    const {isMobile,isTablet} = useContext(AuthContext)
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                width: (isMobile || isTablet)?"100%":'50%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: isMobile?'2rem':'4rem',
                background: "transparent"
                //   background: 'linear-gradient(to right, rgba(16, 185, 129, 0.5), rgba(16, 185, 129, 0.5))',
                //   backgroundImage: 'linear-gradient(to right, rgba(16, 185, 129, 0.5), rgba(16, 185, 129, 0.5))',
                //   backgroundRepeat: 'no-repeat',
                //   backgroundSize: 'cover',
                //   backgroundPosition: 'center',

            }}
        >
            <Fade style={{
                marginBottom:20
            }}>

                <button
                    style={{
                        border: '1px solid rgba(255, 255, 255, 0.7)',
                        borderRadius: '0.375rem',
                        padding: '0.5rem 1rem',
                        color: '#fff',
                        fontSize: '0.875rem',
                        marginBottom: '1.25rem',
                        transition: 'all 0.3s ease-in-out',
                        background:"transparent"
                    }}
                >
                    Testimonials & Success Stories
                </button>
            </Fade>
            <Fade>
                <h1
                    style={{
                        margin: 'auto',
                        fontFamily: 'sans-serif',
                        fontSize: isMobile?"2rem":'3rem',
                        fontWeight: 'bold',
                        letterSpacing: 'normal',
                        color: '#e6e6e6',
                        '@media (min-width: 640px)': {
                            fontSize: '4.375rem',
                        },
                    }}
                >
                    Real Stories of Empowerment with 
                    {/* <span
                        style={{
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            color: '#e6e6e6',
                            marginRight: 10,
                            marginLeft: 10
                        }}
                    >
                        About
                    </span> */}
                    <span
                        style={{
                            position: 'relative',
                            whiteSpace: 'nowrap',
                            display:isMobile?"block":"inline",
                            color: '#ffd000',
                        }}
                    >

                        <span
                            style={{
                                position: 'relative',
                                marginLeft: 10,
                            }}
                        >
                            The Silva Method
                        </span>
                        <svg
                            aria-hidden="true"
                            viewBox="0 0 418 42"
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                height: '0.58em',
                                width: '100%',
                                fill: '#ffd000',
                                opacity: '0.6',
                            }}
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.780 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.540-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.810 23.239-7.825 27.934-10.149 28.304-14.005 .417-4.348-3.529-6-16.878-7.066Z"
                            ></path>
                        </svg>
                    </span>
                </h1>
            </Fade>
            <Fade>
                <h2
                    style={{
                        margin: 'auto',
                        marginTop: isMobile?"1rem":'3rem',
                        maxWidth: '100%',
                        fontSize: isMobile?'1rem':'1.125rem',
                        color: '#ffffff',
                        lineHeight: '1.75rem',
                        fontWeight:500
                    }}
                >
                    For over 55 years, The Silva Method has touched lives worldwide, from celebrities to CEOs to everyday individuals seeking growth. Experience firsthand testimonials of transformation, from unlocking hidden talents to achieving profound breakthroughs. Join us and unleash your fullest potential today.
                </h2>
            </Fade>
            {/* <a
          href=""
          style={{
            backgroundColor: '#ffd000',
            borderRadius: '1.5rem',
            color: '#000',
            fontWeight: '500',
            padding: '1rem 2rem',
            marginTop: '2.5rem',
            transition: 'background-color 0.3s ease-in-out',
          }}
        >
          Get started
        </a> */}
        </div>
    );
};

export default TestimonialsHero;
