
export const generateToken2= ()=>{

    const headers= new Headers();
    headers.append('Content-Type','application/x-www-form-urlencoded');
    headers.append('Authorization','Basic '+ btoa(`${process.env.REACT_APP_PAYPAL_CLIENT_ID}:${process.env.REACT_APP_PAYPAL_SECRET_KEY}`));
    // headers.append('Authorization','Basic '+ btoa(`AWy7EMbIhZyXuw97cLzsaDLW6ohSI2dANthCqsWUOH0X2fUk07o45Kj4te5vn88f811nPfnrKRkX-nIP:ECX86yC-CeUd6UTJeiQvvzWh_YIgNmtRBJUyQH6KwLnkCc5vTe5Pmz72mKp_e8PPmXCdbt2oyhVJS8BQ`));


    const requestOptions={
        method: 'POST',
        headers: headers,
        body: 'grant_type=client_credentials'
    }
    return new Promise((resolve, reject)=>{
        fetch(`${process.env.REACT_APP_PAYPAL_URL2}/v1/oauth2/token`,requestOptions).then((response)=>response.text())
        // fetch(`https://api.sandbox.paypal.com/v1/oauth2/token`,requestOptions).then((response)=>response.text())
        .then(res=> {
            // console.log(res,'paypal reslut');
            
            const {access_token}= JSON.parse(res);
            resolve(access_token)
        }).catch(err=> {
            reject(err);
        })
    })
};

export const createOrder2= (token='',paypaltoken,email,payment_gateway,currency="USD",price,event_id)=>{
    // console.log(course_name,order_id,token,value,'paypal func run');
    const headers= new Headers();
    headers.append('Content-Type','application/json');
    headers.append('Authorization',`Bearer ${token}`);

    const orderDetails= {
        "intent": "CAPTURE",
        "purchase_units": [
            {
                "items": [
                    {
                        "name": "Silva Method Live Webiner",
                        "description": "Life time access",
                        "quantity": "1",
                        "unit_amount": {
                            "currency_code": currency,
                            "value": price
                        }
                    }
                ],
                "amount": {
                    "currency_code": currency,
                    "value": price,
                    "breakdown": {
                        "item_total": {
                            "currency_code": currency,
                            "value": price
                        }
                    }
                }
              
            },
        ],
        "application_context": {
            // "return_url": `${process.env.REACT_APP_BASE_URL}live-subscription-success?email=${email}&payment_gateway=${payment_gateway}&event_id=${event_id}`,
            // "cancel_url": `${process.env.REACT_APP_BASE_URL}live-subscription-failed`
            // "return_url": `${process.env.REACT_APP_BASE_URL}live-subscription-success?q=${paypaltoken}&email=${email}&payment_gateway=${payment_gateway}&event_id=${event_id}`,
            // "cancel_url": `${process.env.REACT_APP_BASE_URL}live-subscription-failed`
            "return_url": `http://localhost:3000/live-subscription-success?q=${paypaltoken}&email=${email}&payment_gateway=${payment_gateway}&event_id=${event_id}`,
            "cancel_url": `http://localhost:3000/live-subscription-failed`
            
            
        }
    }
    // value={`${REACT_BASE_URL}subscription-success?q=${btoa(paypalOrderID)}&s=${btoa(studentId)}&pg=${btoa("paypal")}`}
    const requestOptions={
        method: 'POST',
        headers: headers,
        body: JSON.stringify(orderDetails)
    }

    return new Promise((resolve, reject)=>{
        fetch(`${process.env.REACT_APP_PAYPAL_URL2}/v2/checkout/orders`,requestOptions).then((response)=>response.text())
        // fetch(`https://api.sandbox.paypal.com/v2/checkout/orders`,requestOptions).then((response)=>response.text())
        .then(res=> {
            // console.log(res,'paypal order reslut');
            const res2= JSON.parse(res);
            resolve(res2)
        }).catch(err=> {
            reject(err);
        })
    })
};
