import { useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';
import { TfiEmail } from "react-icons/tfi";
import { FaChevronDown } from "react-icons/fa";
import Collapsible from 'react-collapsible';
import TreeDropDown from './TreeDropDown';

function SidebarPanel({ show, setShow }) {

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [open, setOpen] = useState(false);

    const trigger = useMemo(() => {
        return (
            <a style={{
                marginLeft: 10,
                marginRight: 10,
                cursor: "pointer"

            }} className='white-color linkhover d-flex gap-3 align-items-center justify-content-between'>
                <div className='d-flex gap-3 align-items-center'>
                    <span style={{
                        marginLeft: 10
                    }}>
                        <TfiEmail size={25} color='white' />

                    </span>  <span>
                        Events
                    </span>
                </div>
                <span>
                    <FaChevronDown color='white' size={20} />
                </span>
            </a>
        )
    }, [])

    return (
        <div className='secondsidebar'>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img src='/img/logo/title-logo-removebg-preview.png' style={{
                            width: 50,
                            height: 50,
                            objectFit: "cover"
                        }} />
                    </Offcanvas.Title>
                    <span>
                        <IoMdClose size={30} color='white' onClick={() => {
                            setShow(false)
                        }} />
                    </span>
                </Offcanvas.Header>
                <Offcanvas.Body style={{
                    padding: 0,
                }}>
                    <div>
                        <TreeDropDown triggerText={"Events"} triggerIcon={<TfiEmail size={25} color='white' />} links={[
                            {
                                name: "Live",
                                to: "/"
                            },
                            {
                                name: "Online",
                                to: "/"
                            }
                        ]} />
                        
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default SidebarPanel;