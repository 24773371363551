import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { useLocation } from 'react-router-dom'
// import 'leaflet/dist/leaflet.css'
// import L from 'leaflet';

// const position = [51.505, -0.09]

function MapPlace({ address, location }) {
    const [position, setPostion] = useState(null)

    useEffect(() => {
        if (location[0] && location[1]) {
            setPostion([Number(location[0]), Number(location[1])]);
        }
    }, [location]);


    const Recenter = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }
    // useEffect(()=>{
    // //   if(address){
    // //     fetchAddress(address)
    // //   }

    // console.log(location,"locations")
    // if(location[0] && location[1]){
    //     setPostion([Number(location[0]),Number(location[1])])
    // }
    // },[address])

    if (!position) {
        return null
    }
    return (
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}
        >

            <Recenter lat={position[0]} lng={position[1]} />
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
            // attribution='&copy; <a href="https://carto.com/">carto.com</a> contributors'
            />
            <Marker position={position}>
                <Popup>
                    {
                        address || ""
                    }
                </Popup>
            </Marker>
        </MapContainer>
    )
}

export default MapPlace