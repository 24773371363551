import React from 'react'
import HeadingNew from '../../../AboutUs/HeadingNew'
import { AuthContext } from '../../../../context/AllContext';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { MindfulnessCard } from './MindfulCard';

function MindfulnessMeditationTechniques() {
    const { isMobile } = useContext(AuthContext)

    const techniques = [
        {
            title: "Body Scan Meditation",
            img: "/img/bg/bghero/icons/visualization 4.webp",
            description: "A popular guided meditation technique, body scan meditation involves bringing awareness to different  parts of your body, starting from your feet and moving upward. This practice helps you tune into your physical sensations, promoting relaxation and a sense of control over both body and mind. Many find it beneficial for reducing anxiety particularly after long, stressful days. It’s a powerful reminder of the connection between matter over mind."
        },
        {
            title: "Breathing Meditation",
            img: "/img/bg/bghero/icons/Breath Awareness.webp",
            description: "Focusing on the breath is one of the simplest yet most effective ways to  manage mindfulness for social anxiety. By centering your attention on each inhale and exhale, you calm the mind and bring yourself into the present moment. Through mindful breathing  techniques, you can effortlessly reconnect with peace and mind promoting stillness and clarity. This also helps you reset your mental state whenever anxiety strikes."
        },
        {
            title: "Loving- Kindness meditation",
            description: "This meditation technique fosters compassion, both for yourself and others. It involves silently repeating phrases like, “ May I be happy, May I be healthy”, or ‘May others be peaceful”. Over time, it helps soften judgemental thoughts and fosters emotional balance, which is especially beneficial for managing anxious states of mind. ",
            img: "/img/bg/bghero/icons/Affirmation 2.webp"
        },
        {
            title: "Mindful walking",
            description: "Incorporating mindfulness into daily activities is an accessible way to practise. Mindful walking is a technique where you pay close attention to the sensations of walking–how your feet touch the group, the rhythm of your breath as you move. This can be especially grounding for those experiencing social anxiety, allowing you to focus on the present moment instead of future worries. ",
            img: "/img/bg/bghero/icons/Body Scanning.webp"
        },
        {
            title: "Guided Visualization",
            description: "This technique involves using your imagination to create a peaceful or calming scenario in your mind, such as walking through a serene forest or sitting  by the ocean. It’s often  included in mindfulness meditation courses and online training for those who struggle with anxious thoughts. This form of meditation helps you regain control over your thoughts , reinforcing the idea of matter over mind. ",
            img: "/img/bg/bghero/icons/Grounding 2.webp"
        },
        {
            title: "Online mindfulness courses",
            description: "Now if you are looking for online mindfulness courses or mindfulness training .. then you can begin your journey with the Silva Method Complete Program. It is a complete package of mindfulness meditation where you can start your journey as a beginner and as you complete the course you will be a pro in practising mindfulness, equipped with advanced mindfulness meditation techniques.",
            img: "/img/bg/bghero/icons/Mindfulness 1.webp"
        },
    ];
    return (
        <div className='container'>
            <HeadingNew title={"Mindfulness Meditation Techniques"} start />
            <p className='robotofont mt-3'>
                As we have already covered the link between mindfulness and mindfulness meditation, now it’s time to dive deeper into the mindfulness meditation techniques. When it comes to mindfulness techniques for anxiety, particularly social anxiety, having the right set of tools can make a significant difference. While mindfulness practices often emphasise the mind, it’s crucial to recognize the balance between matter over mind when cultivating awareness of the physical body.
            </p>
            <p className='robotofont'>
                Whether you are new to mindfulness or already on this journey, exploring different mindfulness techniques can help you find a practice that best suits your needs.  When I was struggling with anxiety in social settings, and it wasn't until I tried guided  mindfulness meditations that I began to feel more grounded. These meditation, along with mindfulness meditation courses and mindfulness training online, have proven to be effective in managing various states of mind including my subconscious.
            </p>
            <p className='robotofont'>
                Here are few mindfulness meditation techniques and relevant mindfulness meditation courses online that can help you achieve a magicmind:

            </p>

            <img src='/img/bg/blogpages/blogpagemindful/Mindfulness Meditation Techniques -01.webp' style={{
                width:"100%"
            }} />
            <Row className={isMobile ? 'px-3 justify-content-center align-items-center gy-3 my-4' : 'px-3 justify-content-center align-items-center gy-4  my-4'}>
                {
                    techniques.map((technique) => (
                        <Col xs={12} md={6} lg={4}>


                            {/* <div className="healing-card">
                                <div className="healing-icon-container">
                                    <img src={technique.img}
                                        style={{
                                            width: "100%",
                                            borderRadius: "10px"
                                        }}
                                    />
                                </div>
                                <div className="healing-card-content">
                                    <h3 className="healing-card-title lora-sans">{technique.title}</h3>
                                    <p className="healing-card-description robotofont">
                                        {technique.description}
                                    </p>
                                </div>
                            </div> */}
                            <MindfulnessCard description={technique.description} title={technique.title} />

                        </Col>
                    ))
                }

            </Row>

            <HeadingNew title={"Here’s a Free Guided Mindfulness meditation for you"} start small />
            <div className='my-3'>
            <div className="player-wrapper-bro">
                            <ReactPlayer
                                className="react-player-bro"
                                url={"https://www.youtube.com/watch?v=toTfc1QdC2U&t=52s"}
                                width="100%"
                                height="100%"
                                controls={true}
                                light={false}
                                playing={false}
                                config={{
                                    youtube: {
                                        playerVars: { showinfo: 1 }
                                    }
                                }}
                            />
                        </div>
            </div>

        </div>
    )
}

export default MindfulnessMeditationTechniques