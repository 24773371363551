import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import HeadingNew from "../../../AboutUs/HeadingNew";
import ParaComp from "../../../SilvaManifestationProgram/ParaComp";
import { Link, useNavigate } from "react-router-dom";
import HomeHeading from "../HomeHeading";
import Button from "../../../LoveMind/One/Button";
import CountdownTimer from "./CountDownTimer";
import TimerComp2 from "../../../newbooking/TimerCompNew";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AllContext";
import moment from "moment";

function ComboPromoteBanner() {
  const navigate = useNavigate();
  const [dateExpired,setDateExpired]= useState(false);
  const {isMobile,isTablet}= useContext(AuthContext);
  const [targetDate,setTargetDate]= useState("2024-08-17T21:00:00");
  const [key,setKey]= useState(1);

  // console.log(targetDate,'targetDate')

  return (
    <div
      style={{
        backgroundImage: "url('/img/bg/home-combo.webp')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "1px",
      }}
    >
      <div className="container my-5 text-center">
        <Container>
          <div
            style={{
              width: "100%",
              borderRadius: "15px",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <HeadingNew
              title={"Mind Empowerment Made Easy with"}
              colored={"Life Time Access"}
              small
              start={true}
              white
            /> */}
            {/* <h2 className="text-white">
              Mind Empowerment Made Easy with <span>Life Time Access</span>
            </h2> */}
            <div>
              <HeadingNew title={"Get Complete Course + Live Interactive Program"} white/>
            </div>
            <div className="mt-3">
              <p
                style={{
                  color: "#6e7277",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  textAlign: "center",
                  border: "1px solid white",
                }}
                className="dancing-script px-3 py-2 rounded-3"
              >
                Mind Empowerment Made Easy with Life Time Access
              </p>
            </div>
            <div className="container my-3">
              <p
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "1rem",
                  textAlign: "justify",
                }}
              >
                Tired of watching pre-recorded classes? Your search ends here!
                Experience live- learning with Silva Method Live Interactive
                program interaction - The new Silva Method curriculum that
                allows you to access both recorded and Live interactive classes
                for your optimal learning experience. Dive into immersive
                learning with live sessions led by certified instructors.
                Connect with fellow learners, get instant answers, and enjoy
                hands-on practice. Say goodbye to doubts as you engage in
                real-time discussions and receive personalized guidance tailored
                to your goals. Join the Silva method Newly launched Curriculum
                and make learning fun and engaging !
              </p>
            </div>
            <Link to={"/store/course/combo-plan"}>
              <Image
                src={
                  isMobile
                    ? "/img/combo-new-banner.webp"
                    : "/img/combo-new-banner.jpg"
                }
                style={{ width: "100%", height: "100%", borderRadius: "15px" }}
              />
            </Link>
            {/* <Button text={"Enroll Now"} onClick={()=>navigate("/store/course/combo-plan")}/> */}
            {/* <Row className="w-100 pt-5">
              <Col xl={4} lg={5} md={12} sm={12}>
                
              </Col>
              <Col xl={8} lg={7} md={12} sm={12}>
                                 
              </Col>
            </Row> */}
            <div className="d-flex flex-wrap gap-5 align-items-center justify-content-center my-5">
              <div>
                {/* <div className="my-3">
                </div> */}
                <button
                  className="herodiv-becomememberbtn3 py-3"
                  style={{ fontSize: "17px" }}
                  onClick={() => navigate("/store/course/combo-plan")}
                >
                  Get Access Now
                </button>
              </div>
              <div>
                {/* <HeadingNew title={"Join Our Upcoming Class On:"} small white/> */}
                <div
                  className="d-flex flex-column justify-content-start align-items-center rounded-3"
                >
                  <div>
                    <HeadingNew title={"Classes Every Saturday"} white small />
                  </div>
                  {!dateExpired && (
                    <h6 className="text-white my-2">Next Class:</h6>
                  )}
                  <CountdownTimer
                    date={targetDate}
                    setTargetDate={setTargetDate}
                    key={key}
                    setKey={setKey}
                  />
                  {/* {!dateExpired && (
                    <HeadingNew
                      title={moment(targetDate).format('dddd, Do MMM YYYY')}
                      white
                      small
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ComboPromoteBanner;
