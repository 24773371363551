import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Link } from "react-router-dom";
import HomeHeading from "../../pages/Home/Home/HomeHeading";

function CourseSlide() {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1440px)" });
  const isSmallDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });

  const [courses, setCourses] = useState([]);

  const getCourses = async () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");

    const formData = new FormData();
    formData.append("start_index", "0");
    formData.append("no_of_records", "100");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")
    

    if (country) {
      myHeaders.append("country", country.toString())
    }

    if (currency) {
      myHeaders.append("currency", currency.toString())
    }
    const options = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };
    const res = await fetch(
      `https://silvamethod.com/manage/api/home`,
      options
    );
    const data = await res.json();
    //console.log(data.data.classes);
    setCourses(data.data.course);
  };

  useEffect(() => {
    getCourses();
  }, []);

  //console.log(courses);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 4000,
    cssEase: "linear",
    centerMode: true,
    centerPadding: isDesktopOrLaptop ? "15%" : "10%",
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
  };
  return (
    <div className="row firstslide mb-5">
      <div className="w-75 m-auto text-center">
        <h2
          style={{
            fontWeight: "800",
            fontSize: isDesktopOrLaptop
              ? "60px"
              : isSmallDesktop
                ? "50px"
                : isTablet
                  ? "40px"
                  : "30px",
            margin: "0 0 0 20px",
            textAlign: "center",
          }}
        >

          <span
            style={{
              color: "#801cb6",
              fontWeight: "800",
              fontFamily: "Poppins",
            }}
          >
           José Silva's
          </span>{" "}


          Courses
        </h2>
        {/* <HomeHeading title="" colored="Silva" t /> */}
        {/* <h2 style={{fontWeight: "800",fontSize: isDesktopOrLaptop ? "60px" : "30px",}}>One <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Membership,</span> Multiple Courses</h2> */}
        <h5 className="mt-3">
          Enroll to Original Jose Silva Courses & discover the endless possibilities of Success
        </h5>
      </div>

      <Slider {...settings} arrows>
        {courses &&
          courses.map((course, i) => (
            <Link to={`/store/course/${course.course_id}`} key={i}>
              <div key={course.course_id} className="col-12">
                <img
                  src={course.web_image}
                  alt={course.web_image}
                  style={{
                    width: isDesktopOrLaptop ? "97%" : "96.5%",
                    margin: isDesktopOrLaptop ? "30px" : "0px",
                    borderRadius: "10px",
                    //  height: isDesktopOrLaptop ? "500px" : isTablet?"320px":"200px"
                  }}
                />
              </div>
            </Link>
          ))}
      </Slider>
    </div>
  );
}

export default CourseSlide;
