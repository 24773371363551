import React, { useContext, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AllContext";
import { IoMdReturnRight } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";



function GatwaySection() {
  const { isMobile, isTablet } = useContext(AuthContext);

  const options = [
    {
      id: 1,
      title: "Paypal",
      subtitle:
        "Send a refund request through PayPal with the following steps:",
      steps: [
        <p className="text-start">Log in to your PayPal account (with which you purchased the courses)</p>,
        <p className="text-start">Go to the Activity page</p>,
        <p className="text-start">Click on this particular purchase</p>,
        <p className="text-start">Put all the details</p>,
        <p className="text-start">Then click on the refund request</p>
      ],
      image:require('../../images/paypal-logo-preview.png'),
      imgStyle:{
        width: "auto", 
        height:(isMobile|| isTablet)? "30px":"40px", 
        margin:'5px',
        borderRadius:'10px',
      }
    },
    {
      id: 2,
      title: "Stripe",
      subtitle: "",
      steps: [
        <p className="text-start ">"To get a refund who have purchased any course online using the Stripe payment method, send us an email at <a href="mailto:digital@silvamethod.com" style={{color:'blue'}}>digital@silvamethod.com</a>, from your valid ID through which you purchased our course or linked to your account. Make sure to mention your transaction ID. We will refund your money within 30 days of your refund request."</p>,
      ],
      
      image: require('../../images/stripe-logo.png'),
      imgStyle:{
        width: "auto", 
        height:(isMobile|| isTablet)? "40px":"50px", 
        margin:'5px',
        borderRadius:'10px',
      }
    },
  ];


  // console.log(options.steps,'steps')

  return (
    <div className="parallexcontact pt-2">
      <div className="container contactsection mt-5">
        {/* <p
        className="text-center"
        style={{ fontSize: "1.2rem", color: "black", fontWeight: "600" }}
      >
        If you've a question about Silva Method in general or during your
      </p> */}
        <div className="d-flex justify-content-center flex-wrap">
          <h2
            className="text-center"
            style={{ fontSize: "50px", fontWeight: "700" }}
          >
            Silva Method Digital Courses - Refund Process
          </h2>
          {/* <button className="styledbtn mt-2">Practice</button> */}
        </div>

        <p
          className="text-center mt-2"
          style={{ fontSize: "1.2rem", color: "black", fontWeight: "600" }}
        >
          The Original Silva Method Effortless Refund Process to Ensure a Smooth
          Return Experience
        </p>

        <Row className="justify-content-center align-items-center my-3">
          {options.map((option, i) => (
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                style={{
                  width: "97%",
                  height:(isMobile|| isTablet)? "": "301px",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "5px 6px 5px -2px rgba(0,0,0,0.5)",
                }}
              >
                <Card.Body className="refund_gateway_card" style={{height:(isMobile || isTablet)?'450px':''}}>
                  <Image
                    fluid
                    src={option.image}
                    style={option.imgStyle}
                  />
                  {option.steps?.map((val, i) => (
                    <Row className="mx-3">
                      {option?.steps?.length>1 && <Col sm={4} md={4} lg={3}>
                        <p className="text-start">
                          <span>
                            <IoMdReturnRight />{" "}
                          </span>
                          Step {i + 1}:
                        </p>
                      </Col>}
                      <Col sm={option?.steps?.length>1 ? 8:12} md={option?.steps?.length>1 ?8:12} lg={option?.steps?.length>1 ?9:12}>
                        {/* <p className="text-start ">{val}</p> */}
                        {val}
                      </Col>
                    </Row>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default GatwaySection;



{/* <div className="container my-2 d-flex justify-content-center align-items-center flex-column">
      <h2
        className={`w-${isMobile || isTablet ? "100" : "50"} text-center mb-4 `}
      >
        Silva Method Digital Courses - Refund Process
      </h2>
      <h4  className={`w-${isMobile || isTablet ? "100" : "50"} text-center mb-4 `}>The Original Silva Method Effortless Refund Process to Ensure a Smooth Return Experience</h4>
      <Card
        style={{
          width: isMobile || isTablet ? "100%" : "45rem",
          padding: "30px 30px",
          //   paddingBottom: isMobile || isTablet ? "0px" : "auto",
          border: "none",
          borderRadius: "15px",
          height: isMobile || isTablet ? "auto" : "400px",
          position: "relative",
        }}
      >
        <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
          {options.map((val, i) => (
            <button
              className={`button-29 ${active === i ? "button-30" : ""}`}
              role="button"
              onClick={() => setActive(i)}
            >
              {val.title}
            </button>
          ))}
        </div>
        <Card.Body>
            {options[active]?.steps?.map((val, i) => 
            <Row className={`mb-${(isMobile || isTablet)? '2':''}`}>
                <Col sm={2} md={2} lg={2}>
                <p className={`mb-${(isMobile || isTablet)? '0':''}`}><span><IoMdReturnRight color="#6a3093"/> </span>Step {i+1}:</p>
                </Col>
                <Col sm={10} md={10} lg={10}>
                {val}
                </Col>
            </Row>
            )}
        </Card.Body>
      </Card>
    </div> */}