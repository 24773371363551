import React, { useMemo, useState } from 'react'
import { FaChevronDown } from "react-icons/fa";
import { Link } from 'react-router-dom';

import Collapsible from 'react-collapsible';

function TreeDropDown({ triggerText, triggerIcon, links = [] }) {
    const [opened,setOpened] = useState(false);

    const trigger = useMemo(() => {
        return (
            <a style={{
                marginLeft: 10,
                marginRight: 10,
                cursor: "pointer"

            }} className='white-color linkhover d-flex gap-3 align-items-center justify-content-between'>
                <div className='d-flex gap-3 align-items-center'>
                    <span style={{
                        marginLeft: 10
                    }}>
                        {/* <TfiEmail size={25} color='white' /> */}
                        {triggerIcon}

                    </span>  <span>
                        {triggerText}
                    </span>
                </div>
                <span className={`${opened?"opened":"closed"}`}>
                    <FaChevronDown color='white' size={20} />
                </span>
            </a>
        )
    }, [opened])
    return (
        <Collapsible trigger={trigger} easing='ease-in' onOpening={()=>{
            setOpened(true)
        }}
        onClosing={()=>{
            setOpened(false)
        }}
        >
            {links.map((val) => (
                <Link to={val.to} style={{
                    marginLeft: 67,
                    marginRight: 10,
                    cursor: "pointer"

                }} className='white-color linkhover2 d-flex gap-3 align-items-center'>
                    <span>
                        {val.name}
                    </span>

                </Link>
            ))}


        </Collapsible>
    )
}

export default TreeDropDown