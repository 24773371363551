import React, { useContext } from 'react'
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'
import { AuthContext } from '../../../context/AllContext'
import { Image } from 'react-bootstrap'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { useNavigate } from 'react-router-dom'

function ThankYouPageForm() {
    const { isMobile } = useContext(AuthContext)
    const { width, height } = useWindowSize()
    const navigate = useNavigate()
    return (
        <>
            <div className='bg-thanks px-1'>

                <h2 className='dancing-script2' style={{
                    color: "white",
                    fontSize: "4rem",
                    textAlign: "center"
                }}>Thank you</h2>
                <h4 style={{
                    color: "white",
                    fontSize: "2rem",
                    textAlign: "center"
                }} className='mt-3 mb-4'>For being a part of Silva Community</h4>
                <ParaComp data={[
                    "Check your email for the first day meditation"
                ]}
                    margin={false}
                    center
                />
                <button className='herodiv-becomememberbtn3 px-4 mt-2' onClick={() => {
                    navigate("/")
                }}>
                    Back to home
                </button>
                <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
                    <h5 className='fw-semibold text-light text-center mt-4'>Follow us on social Media</h5>

                    <div className='d-flex gap-2'>
                        <Image src='img/footer/footer-05.webp' style={{
                            cursor: "pointer"
                        }} width={40} height={40}
                            onClick={() => {
                                window.open("https://www.youtube.com/channel/UCaCDqnK1-i0rb9tFPznvI4w")
                            }}
                        />
                        <Image src='img/footer/footer-06.webp' style={{
                            cursor: "pointer"
                        }} width={40} height={40}
                            onClick={() => {
                                window.open("https://www.instagram.com/silvamethodofficial/")
                            }}
                        />
                        <Image src='img/footer/footer-07.webp' style={{
                            cursor: "pointer"
                        }} width={40} height={40}
                            onClick={() => {
                                window.open("https://www.facebook.com/SilvaInternationalInc")
                            }}
                        />
                        <Image src='img/footer/footer-08.webp' width={40} height={40} style={{
                            cursor: "pointer"
                        }}
                            onClick={() => {
                                window.open("https://x.com/SilvaHomeOffice?mx=2")
                            }}
                        />

                    </div>
                </div>
            </div>
            <Confetti
                width={width}
                gravity={0.2}
                initialVelocityX={10}
                initialVelocityY={10}
                height={height}
            />
        </>
    )
}

export default ThankYouPageForm