import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import SliderCard from '../ComboCourse2/SliderCard';
import { reqData } from '../../../utils/baseUrl';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from "react-icons/md";
import HeadingNew from '../../AboutUs/HeadingNew';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';

function SliderReviews() {
   

    const {isMobile} = useContext(AuthContext)

    const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
        const { className, onClick } = props;

        return (
            <div {...props} className="custom-prevArrow" onClick={onClick}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                </svg>
            </div>
        );
    };
    const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
        const { className, onClick } = props;

        return (
            <div {...props} className="custom-nextArrow" onClick={onClick}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
                </svg>
            </div>
        );
    };

    const settings = {
        className: "center",
        centerMode: isMobile?false:true,
        infinite: true,
        centerPadding: "25%",
        slidesToShow: 1,
        speed: 500,
        dots: true,
        autoplay:true,
        arrows:false,
        // adaptiveHeight: true

      
    };
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);

    const getReviews = async () => {
        setLoading(true);
        const res = await reqData("/testimonialList", "POST", {
            start_index: 0,
            no_of_records: 100,
        });

        // console.log(res,'response...')
        if (res.error === false) {
            setReviews(res?.data?.filter((el, i) => i !== 8));
        }
        setLoading(false);
    };

    useEffect(() => {
        getReviews();
    }, []);
    return (
       <>
       <HeadingNew small title="The Silva Mind Control Method" />
       <HeadingNew title="Reviews and Ratings" />

        <div className="reviews-carousel">
            <Slider {...settings}>
                {reviews.map((review, i) => (
                    <div key={i}>
                        <SliderCard data={review} />
                    </div>
                ))}
            </Slider>
        </div>
       <div className='text-center'>
       <div className='d-inline-flex justify-content-center align-items-center gap-2 px-5 py-3 border border-warning rounded-3 fw-bold'>
       <Link to={"/testimonials"}>View More Reviews</Link>
       <span><MdKeyboardArrowRight color='black' size={20} /></span>
       </div>
       </div>
       </>
    )
}

export default SliderReviews