import React from 'react';
import './FreeContentGrid.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { Badge } from 'react-bootstrap';

const FreeContentGrid = () => {

  const [contents, setContents] = useState([]);

  const getContents = async () => {
    try {

      const res = await axios.get("https://silvacasesonline.com:5000/api/v1/admin/private/meditation-content")
      if (res && res.data) {
        setContents(res.data)
      }

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getContents()
  }, [])

  const navigation = useNavigate()


  if (!contents || contents.length === 0) {
    return <div className="fcg_empty">No content available</div>;
  }

  return (
    <div className="fcg_wrapper my-5" style={{
      cursor: "pointer"
    }}>
      <h2 className="fcg_heading">Silva Method Free Meditations</h2>
      <div className="fcg_layout">
        {contents.map((content, index) => (
          <div key={index} className="fcg_item" onClick={() => {
            navigation("/silva-for-all/" + content._id)
          }}>
            <div className="fcg_media">
              <img
                src={content.thumbnail}
                alt={content.title}
                className="fcg_thumb"
              />
              <span className={`fcg_badge fcg_badge--${content.type}`}>
                {content.type}
              </span>
            </div>
            <div className="fcg_content">


           
              <div className='d-flex gap-2 mb-3 robotofont' style={{
                borderRadius:"15px",
              }}>
                {
                  content.tags.map((val) => (
                    <Badge bg="success" className='py-2 px-3'>{val.name}</Badge>
                  ))
                }
              </div>

              <h3 className="fcg_title">{content.title}</h3>
              <p className="fcg_desc">{content.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FreeContentGrid;