import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import jose from "../../../images/Home Page - What is Silva Method copy.webp";
import { Link } from 'react-router-dom';
import HomeHeading from './HomeHeading';


function HowSilvaWorks() {


    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 599 });


    return (
        <div className="container mb-3 pt-5">
            <div className="container">


                {/* <h2
                    style={{
                        fontWeight: "800",
                        fontSize: isDesktopOrLaptop ? "60px" : isSmallDesktop ? "50px" : isTablet ? "40px" : "30px",
                        margin: "0 0 0 20px",
                        textAlign: "center"
                    }}
                >

                    What is <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Silva</span>{" "}   Method?
                </h2> */}
                {/* <h2
                    style={{
                        fontWeight: "800",
                        fontSize: isDesktopOrLaptop ? "60px" : "30px",
                        textAlign: "center"
                    }}
                >
                    What is <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Silva</span> Method?
                </h2> */}

                <HomeHeading title='What is The Silva' colored='Method?'/>

                <Row className='justify-content-start align-items-center revcol'>
                    <Col xs={12} sm={12} md={12} lg={5} className='px-2 pb-3'>
                        {
                            isMobile ? (
                                <h4 className='text-start mt-1'>
                                Have you ever contemplated a life different from your own?
                            </h4>
                               
                            ) : (
                                <h2 className='text-start mt-3'>
                                Have you ever contemplated a life different from your own?
                            </h2>
                            )
                        }

                        <div className="line-1"></div>

                        <div style={{ paddingRight: "0px", marginTop: "20px" }}>
                            <p style={{ fontSize: "1rem" }}>
                                How often have you found yourself comparing your circumstances to those who may not possess great wealth, yet radiate genuine happiness? It is intriguing to delve into the reasons why some people find contentment and joy with little, while others, despite seemingly having everything to be happy about, perpetually remain dissatisfied.
                            </p>
                            <div className='d-flex align-items-center gap-3'>
                                {/* <img
                                src="https://storyblok-cdn.The Silva Method.com/f/60579/96x96/c079e2a762/mvcom_hp_method_tammy-mansfield.jpg?trans?quality=40&auto=webp&dpr=1&width=auto"
                                alt=""
                                style={{width:"60px",height:"60px",borderRadius:"10px",objectFit:"cover"}}
                                />
                                <div className='d-flex align-items-center flex-column'>
                                    <span style={{textAlign:"start",fontWeight:"bold",color:"black",fontSize:"1.1rem"}}>Tammy Mansfield</span>
                                    <span style={{textAlign:"start",fontWeight:"bold",color:"black",fontSize:"1rem"}}>Automotive Sales</span>
                                </div> */}
                                <Link to={'/what-is-silva-method'}>
                                    <button className='styledbtn23'>Read More</button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7}>
                        <img src={jose} className='revcolimg mt-3' style={{ width: "100%" }} />
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default HowSilvaWorks