import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import SpanishHeroSection from './SpanishHeroSection'
import Featured from '../../../components/featured/Featured'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'
import SecondHeroSection from '../../Home/Home/Homestudycourses/SecondHeroSection'
import ContactSectionNew from '../../payment/ContactNewSection'
import PurpleCta from './PurpleCTA'
import TrustedBy from '../../Draganpages/TrustedBy'
import ProblemsSection from './ProblemsSection'
import TextBannerSection from './TextBannerSection'
import SpanishForm from './SpanishForm'
import SpanishLearnSection from './SpanishLearnSection'
import SpanishLearnSection2 from './SpnishLearnSection2'
import SpanishBenefits from './SpanishBenefits'
import RightLeftSection from '../../LoveMind/Two/RightLeftSection'
import LeftRightSection from '../../LoveMind/Two/LeftRightSection'
import LeftRightSection2 from './LeftRightSection2'
import PaymentSection from '../../newbooking/PaymentSection'
import PaymentSectionTwo from '../../newbooking/PaymentSectionTwo'
import PaymentSectionSpanish from './PaymentSectionSpanish'
import ContactSection from '../../CoursePage/ContactSection'


function SpanishPaymentPage() {
    return (
        <div className='parallexnew22'>
            <HomeHeader />
            <SpanishHeroSection />
            <TrustedBy text='El Método Silva fue destacado en' />

            <div className='container pb-5'>

                <SecondHeroSection />
                <PurpleCta />
            </div>
            {/* <RightLeftSection />
            <LeftRightSection />
            <PopularSilvaTechniques />
            <PaymentSectionTwo />
            <div className='bg-slider'>
                <div className='container py-5'>
                    <TestiMonialsSectionLM1 />
                </div>
            </div>
            <div className='container my-4'>
                <HeadingNew small title={"Frequenly Asked Questions"} start />
                <SilvaLifeFAQ />


            </div> */}
            <ProblemsSection />
            <div className='container py-5'>
                <TextBannerSection />
                {/* <SpanishForm /> */}
            </div>

            <SpanishLearnSection2 />
            <SpanishBenefits />
            <LeftRightSection2 />
            <PaymentSectionSpanish />
            <ContactSection />
            <CustomFooter />
        </div>
    )
}

export default SpanishPaymentPage