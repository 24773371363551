import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { AiOutlineClose } from "react-icons/ai"
import { allcodes } from '../../utils/allCountryCodes';
import { AuthContext } from "../../context/AllContext"
import { requestData } from "../../utils/baseUrl"
import { useNavigate } from 'react-router-dom';

function BookingModal(props) {
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);

    const { userData } = useContext(AuthContext)
    const [values, setValues] = useState({
        email: "",
        name: "",
        country_code: "",
        phone: "",

    })
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {

            event.stopPropagation();
        }



        setValidated(true);

        const { email, name, country_code, phone } = values;

        if (!email || !name
        ) {
            toast.error("Please enter all required fields");
            return
        }

        const val = {
            email,
            name
        }

        if (country_code) {
            val.country_code = country_code
        }

        if (phone) {
            val.phone = phone
        }

        try {
            setLoader(true)
            const res = await requestData("applyIntroductorySeminars", "POST", values);
            setLoader(false)

            if (res && res.error === false) {
                toast.success("Form has been submitted");

                setValues({
                    email: "",
                    name: "",
                    country_code: "",
                    phone: "",

                })
                setValidated(false)
                props.onHide()
                navigate("/thank-you")
            } else {
                toast.error(res.messages)
            }

        } catch (err) {
            toast.error("Something went wrong please try again later");
        }

    };


    const handleChange = (e) => {
        if (e.target.value) {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            })
        } else {
            setValues({
                ...values,
                [e.target.name]: ""
            })
        }
    }

    const handleWhatsapp = () => {
        if (values.phone) {
            setValues({
                ...values,
                whatsapp_number: values.phone
            })
        } else {
            toast.error("Please enter your phone number first")
        }

    }

    useEffect(() => {

        if (userData) {
            const { country_code, first_name, last_name, email, phone } = userData;

            const updatedValues = {
                ...values,
                email: email || '',
                name: (first_name && last_name) ? `${first_name} ${last_name}` : values.name,
                phone: phone || '',
                country_code: country_code || values.country_code
            };

            setValues(updatedValues);
        }



    }, [userData])







    return (
        <Modal show={props.show} size='md' onHide={() => {
            props.onHide()
            setValidated(false);
        }} keyboard={false}
            backdrop="static"
            centered

        >



            <Modal.Body style={{
                background: `rgb(1, 6, 8) linear-gradient(65deg, rgb(21, 23, 24) 21%, rgb(81, 2, 134) 70%)`,
                border: "1px solid black",
                padding: "50px 50px",
                position: "relative"



            }}>


                <span style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px"
                }}

                >
                    <AiOutlineClose size={30} color='white' onClick={props.onHide} />
                </span>

                <h6 style={{
                    color: "white",
                    fontSize: "1.1rem",
                    marginBottom: "25px",
                    lineHeight: "25px",
                }}>

                    Submit The Form to Join The Free Silva Method Introductory Seminar

                </h6>
                <hr />
                <Form noValidate validated={validated} onSubmit={handleSubmit}

                >
                    <Row className='gap-2'>
                        <Col>
                            <Form.Group controlId="name" className='mb-3'>
                                <Form.Label className='text-light'>Name <span className='text-danger'>*</span></Form.Label>
                                <Form.Control type="text" required
                                    name='name'
                                    onChange={handleChange}
                                    value={values.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="countryCode">
                                <Form.Label className='text-light'>Select Country </Form.Label>
                                <Form.Control as="select" name='country_code'
                                    onChange={handleChange}
                                    value={values.country_code}
                                >
                                    <option value="">Select</option>
                                    {
                                        allcodes.map((c, i) => (
                                            <option key={i} value={c.dial_code}>{c.name}</option>
                                        ))
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select a country code.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* Email */}
                    <div className="">
                        <Form.Group controlId="email" className='row mb-3'>
                            <Form.Label className='text-light'>Email <span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="email" required
                                name='email'
                                onChange={handleChange}
                                value={values.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid email address.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Phone */}
                        <Form.Group controlId="phone" className='row mb-3'>
                            <Form.Label className='text-light'>Phone</Form.Label>
                            <Form.Control type="text"
                                name='phone' onChange={handleChange}
                                value={values.phone}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter your phone number.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className='row'>
                            <Button type="submit" variant='warning' disabled={loader}>
                                {loader === true ? (
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : "Submit"}
                            </Button>
                            <Button type="button" variant='secondary mt-2' onClick={() => {
                                setValidated(false);
                                props.onHide()
                            }}>Cancel</Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default BookingModal;
