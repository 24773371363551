import React, { useContext } from 'react'
import ReactPlayer from 'react-player'
import { useMediaQuery } from 'react-responsive'
import silvaimg from "../../images/newimgs/silva-collage.webp";
import ultramindimg from "../../images/newimgs/ultra-mind-Copy.webp"
import silvaintuitionimg from "../../images/newimgs/intution-system-Copy.webp"
import { AuthContext } from '../../context/AllContext';
import HeadingNew from '../AboutUs/HeadingNew';

function LifeSystemExtraBanners({ title1 = "", text1 = "", text2 = "", img1, hasButton1 = false, title2 = "", text3 = "", text4 = "", img2, hasButton2 = false , reverse= false}) {
    const { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isTablet, isMobile, } = useContext(AuthContext);


    return (
        <div className='container' style={{ paddingTop: "20px" }}>
            <div className={`row justify-content-center align-items-center gap-3 my-3 ${reverse?"flex-row-reverse":""}`}>

                {
                    (isDesktopOrLaptop || isBigScreen || isTablet) ? (
                        <>
                            <div className={"col-sm-12 col-md-5 p-2"}>
                                <div className='text-start'>
                                    {/* <h3>
                                        {title1}
                                    </h3> */}
                                    <HeadingNew title={title1} small start={true} />
                                    <p style={{ fontSize: "1.1rem", color: "black" }} className='mt-3'>
                                        {text1}
                                    </p>
                                    <p style={{ fontSize: "1.1rem", color: "black" }}>
                                        {text2}
                                    </p>
                                    {
                                        hasButton1 && (
                                            <button className='primary_btn2'>
                                                LEARN MORE
                                            </button>
                                        )
                                    }

                                </div>

                            </div>
                            <div className="col-sm-12 col-md-5">
                                <img src={img1}
                                    style={{
                                        // width: "100%",
                                        height: "auto",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }}
                                />
                                {/* <ReactPlayer width={"100%"} height={"auto"} controls url='https://www.youtube.com/watch?v=zkHbJhsaNTI&feature=youtu.be' /> */}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-sm-12 col-md-5">
                                <img src={img1}
                                    style={{
                                        // width: "100%",
                                        height: "auto",
                                        objectFit: "cover",
                                        borderRadius: "10px"
                                    }}
                                />
                                {/* <ReactPlayer width={"100%"} height={"auto"} controls url='https://www.youtube.com/watch?v=zkHbJhsaNTI&feature=youtu.be' /> */}
                            </div>
                            <div className="col-sm-12 col-md-5 p-2">
                                <div className='text-start'>
                                    {/* <h3>
                                        {title1}
                                    </h3> */}
                                    <HeadingNew title={title1} small start={true} />

                                    <p style={{ fontSize: "1.1rem", color: "black" }} className='mt-3'>
                                        {text1}
                                    </p>
                                    <p style={{ fontSize: "1.1rem", color: "black" }}>
                                        {text2}
                                    </p>
                                    {
                                        hasButton1 && (
                                            <button className='primary_btn2'>
                                                LEARN MORE
                                            </button>
                                        )
                                    }
                                </div>

                            </div>

                        </>
                    )
                }
            </div>
            {
                img2 && title2 && text3 && text4 && (
                    <div className="row justify-content-center align-items-center gap-3">
                        <div className="col-sm-12 col-md-5">
                            <img src={img2}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                    borderRadius: "10px"
                                }}
                            />
                            {/* <ReactPlayer width={"100%"} height={"auto"} controls url='https://www.youtube.com/watch?v=J4XNaRIhFxU' /> */}
                        </div>
                        <div className={(isDesktopOrLaptop || isBigScreen || isTablet) ? "col-sm-12 col-md-5 mt-5" : "col-sm-12 col-md-5"}>
                            <div className='text-start p-2'>
                                {/* <h3>
                            {title2}
                        </h3> */}
                                <HeadingNew title={title2} small start={true} />

                                <p style={{ fontSize: "1.1rem", color: "black" }}>
                                    {text3}
                                </p>
                                <p style={{ fontSize: "1.1rem" }}>
                                    {text4}
                                </p>

                                {
                                    hasButton2 && (
                                        <button className='primary_btn2'>
                                            LEARN MORE
                                        </button>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                )
            }
        </div>
    )
}

export default LifeSystemExtraBanners