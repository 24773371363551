import React from "react";
import { useContext } from "react";
import { Container, Form, Image, InputGroup } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import { AuthContext } from "../../../context/AllContext";
import { GoMail } from "react-icons/go";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill'

function NewComboHero() {
  const { isMobile, isTablet } = useContext(AuthContext);

  // function scrollToPayment() {
    

  //   const doc = document.querySelector(".scrollnewsection")
  //   setTimeout(() => {

  //     scrollIntoView(doc, {
  //       behavior: "smooth",
  //       block: "end", inline: "nearest"
  //     });
  //   }, 300)
  // }

  const handleScroll= ()=>{
    polyfill()
    const item= document.querySelector('.scrollnewsection');

    scrollIntoView(item, {
      behavior: "smooth",
      block: "end", inline: "nearest"
    });
  }

  return (
    <div className="theme-dark">
      <Container className="pt-5 d-flex flex-column align-items-center justify-content-center">
        <p
          className="text-white fw-normal my-3"
          style={{
            fontSize: isMobile ? "30px" : isTablet ? "35px" : "42px",
            lineHeight: isMobile ? "2rem" : isTablet ? "2.5rem" : "",
            textAlign: isMobile || isTablet ? "center" : "",
            maxWidth: isMobile ? "80%" : "",
          }}
        >
          Silva Method Habit Control Technique{" "}
        </p>
        <div>
          <h2 className="comboneweffecttext my-2">
            <TypeAnimation
              sequence={["Break Chains of Addictions",1000,"Take Control of your Habits",1000]}
              wrapper={isMobile ? "h3" : "h2"}
              cursor={true}
              repeat={Infinity}
              style={{ color: "#f1c40f", fontWeight: "bolder" }}
            />
          </h2>
        </div>
        {/* <p
          style={{
            maxWidth: isMobile ? "80%" : "470px",
            color: "white",
            marginTop: "10px",
            marginBottom: "30px",
            fontSize: "16px",
            textAlign: isMobile ? "center" : "",
          }}
        >
          Want to get rid of addictions and poor habits but nothing works? No
          Worries! The Silva Method has got your back!
        </p>
        <div>
          <InputGroup
            style={{
              borderRadius: "40px",
              backgroundColor: "#000",
              width: isMobile ? "100%" : "500px",
              height: "60px",
            }}
          >
            <InputGroup.Text
              style={{
                borderTopLeftRadius: "40px",
                borderBottomLeftRadius: "40px",
                backgroundColor: "#000",
                border: "none",
                textAlign: "center",
              }}
            >
              <GoMail style={{ color: "gray" }} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Your Email Address:"
              aria-label="email"
              aria-describedby="email"
              style={{
                backgroundColor: "#000",
                border: "none",
                boxShadow: "none",
                color: "white",
              }}
              type="email"
            />
            <InputGroup.Text
              style={{
                backgroundColor: "#000",
                border: "none",
                borderTopRightRadius: "40px",
                borderBottomRightRadius: "40px",
              }}
            >
              <BsFillArrowRightCircleFill
                style={{ color: "#f1c40f" }}
                size={40}
                className="inputiconcombo"
              />
            </InputGroup.Text>
          </InputGroup>
        </div>
        <p className="text-white h6 my-3">
          Have any queries?{" "}
          <a href="mailto:digital@silvamethod.com" className="text-primary">
            Get in touch!
          </a>
        </p> */}
        <div>
          <Image src="/img/courseimgs/combo/header-image-3.webp" />
          <div className="d-flex justify-content-center align-items-center pt-4">
          <button className="herodiv-becomememberbtn3 px-5" onClick={handleScroll}>{"Get Started Now" }</button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default NewComboHero;
