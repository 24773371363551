import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";
import { Col, Image, Row } from "react-bootstrap";


import { useNavigate } from "react-router-dom";
import { requestData } from "../../utils/baseUrl";
import { useEffect } from "react";
import { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import Button from "../LoveMind/One/Button";
import { handleScrollCombo } from "../../utils/handleScrollEmotions";
import HeadingNewBlackFriday from "../AboutUs/HeadingNewBlackFriday";

function BecomeMemberSection2({ click = true, cta = false, onclick }) {
  const { isDesktopOrLaptop, isBigScreen, isTablet } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const getCourses = async () => {
    setLoading(true);
    const res = await requestData("latestCourseList", "POST", {
      start_index: 0,
      no_of_records: 20,
    });
    // console.log(res.data)
    setLoading(false);
    if (res && res.error === false) {
      const filtered = res.data.filter((el, i) => {
        // console.log(el.course_id,"element")
        return (
          el.course_id !==
          "silva-method-complete-course-with-live-interactive-program"
        );
      });

      const sortedCourses = filtered.sort((a, b) => {
        const isComboA = a.course_id.toLowerCase().includes("combo");
        const isComboB = b.course_id.toLowerCase().includes("combo");

        if (isComboA && !isComboB) return -1;
        if (!isComboA && isComboB) return 1;

        // If both or neither are combo courses, sort by precedence
        return Number(a.precedence) - Number(b.precedence);
      });
      setCourses(sortedCourses);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleNavigate = async (course_id) => {
    if (
      course_id === "silva-method-complete-course-with-live-interactive-program"
    ) {
      navigate("/store/course/combo-plan");
    } else {
      navigate("/store/course/" + course_id);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  return (

 <div className="container d-flex flex-column justify-content-center align-items-center align-content-center py-5">
     
     <HeadingNewBlackFriday title={"Get Full Access"} small />
     <HeadingNewBlackFriday title={"Silva Method "} colored={"Home Study Courses"} />

   

     <p
       className="text-center mt-2"
       style={{
         fontSize: isDesktopOrLaptop ? "1.5rem" : "1.1rem",
         fontWeight: "700",
         color: "black",
       }}
     >
       America’s 1st Personal Growth Program Since 1966: Discover the New You!
     </p>

     <div className=" mt-3 mb-5">
       <Row className="justify-content-center align-items-center gy-2 gy-md-4">
         {courses
           .filter(
             (course, i) =>
               course?.course_id !== "top-mental-training" &&
               course?.course_id !==
                 "silva-method-complete-course-with-live-interactive-program"
           )
           .map((val, i) => (
             <Col xs={5} sm={5} md={4} key={i}>
               <div className="card-image-container2">
                 <Image
                   className="imghover"
                   src={
                     val.web_image ||
                     "https://silvamethod.com/manage/assets/uploads/noimg.jpg"
                   }
                   fluid
                   rounded
                   style={{
                     width: "95%",
                   }}
                   onClick={() => {
                     if (click) {
                       handleNavigate(val.course_id);
                     } else {
                       return;
                     }
                   }}
                 />
               </div>
             </Col>
           ))}
       </Row>
     </div>
     {cta && (
       <Button
         text={"Get access to all courses"}
         onClick={() => (onclick ? onclick() : handleScrollCombo())}
         bgcolor="#f1c40f"
         btnTextColor={"black"}
       />
     )}
   </div>

   
  );
}

export default BecomeMemberSection2;
