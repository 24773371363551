import React from 'react'
import { useContext } from 'react'
import { Badge, Card } from 'react-bootstrap'
import { AuthContext } from '../../../../context/AllContext'
import ParaComp from '../../../SilvaManifestationProgram/ParaComp'
import "./silvabox.css"
import HeadingNew from '../../../AboutUs/HeadingNew'
function TheSilvaMethodBox() {
    const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } = useContext(AuthContext)

    return (
        <>
            <div className='container d-flex justify-content-center align-items-center'>
                <Card className='cardbdy2' style={{ width: "100%", borderRadius: "20px" }}>
                    <Card.Body style={{ width: "100%" }} className={isMobile ? 'px-3 py-3' : 'px-5 py-5'}>
                        {/* <div className='d-flex justify-content-center mb-3'>
                            <h2 className={isMobile ? 'text-center white-color mt-1' : "text-center white-color"} style={{ fontSize: isBigScreen ? "50px" : isDesktopOrLaptop ? "40px" : isTablet ? "30px" : "25px", fontWeight: "700" }}>
                                The Silva
                            </h2>
                            <button className={isMobile ? 'styledbtn3' : 'styledbtn3 mt-2'}>
                                Method
                            </button>
                        </div> */}

                        <HeadingNew title={"Renowned Personalities Lending their Support to"} boxed boxedtext='The Silva Method' white small/>
                        {/* <h2>
                          Renowned Personalities Lending their Support to <span style={{fontSize:'20px', fontWeight: 800,color:'white'}}>Silva Method</span>
                        </h2> */}

                        <ParaComp
                            margin={false}
                            data={[
                                "Backed by luminaries in personal growth like Jack Canfield, Dr. Wayne Dyer, bestselling author Richard Bach and award-winning neuroscientist- Mark Robert Waldman, and many more. Our curriculum defines The Silva Method by intertwining Alpha and Theta meditation and mind control training courses, visualization techniques, habit control, and positive programming.",
                                "A dynamic community of over 12 million students, combined with our certified instructors, creates the perfect setting for transforming minds. If you are someone who seeks inner transformation, curious about how to control your mind and thoughts using The Silva Method and guided meditation, Silva Method could be your ideal choice."
                            ]}
                        />


                    </Card.Body>
                </Card>
            </div>
        
                <div className='container' style={{ position: "relative" }}>
                <Card className='absolutequote' style={{ width: isMobile ? "90%" : "50%", borderRadius: "15px" }}>
                    <Card.Body className='d-flex justify-content-center align-items-center flex-column px-5 py-3'>
                        {/* <blockquote style={{fontSize:"1rem",color:"black",fontWeight:"500"}}>
                        "The greatest discovery you will ever make , is the potential of your own mind." <small style={{marginLeft:"10px",fontSize:"1.2rem"}}><i>-Jose Silva</i></small>
                    </blockquote> */}

                        <blockquote class="wp-block-quote"><p>The greatest discovery you will ever make , is the potential of your own mind.
                        </p><cite>-Jose Silva</cite></blockquote>



                    </Card.Body>
                </Card>

            </div>
           
        </>
    )
}

export default TheSilvaMethodBox