import React from 'react'

function LoggedInHeroSection({text}) {
  return (
    <div className='container my-5'>
        <h3>{text}</h3>
    </div>
  )
}

export default LoggedInHeroSection