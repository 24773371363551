import React, { useContext, useMemo } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { AuthContext } from '../../../context/AllContext'
import { Card, Col, Row } from 'react-bootstrap';
import Button from '../One/Button';

function PerkSectionLoveMind() {
    const {isMobile} = useContext(AuthContext);


    const handleScroll = ()=>{
        const doc = document.querySelector(".lovemindformscroll");
        if(doc){
            doc.scrollIntoView({
                behavior:"smooth"
            })
        }
    }
    const data = useMemo(()=>{
        return [
            {
                title:"Self-esteem",
                text:"Understanding and cultivating a positive sense of self-worth and confidence.",
                img:"/img/lovemind/icons/20.png"
            },
            {
                title:"Relationships",
                text:"Learning about the dynamics and principles that govern healthy, fulfilling relationships, both romantic and platonic.",
                img:"/img/lovemind/icons/21.png"
            },
            {
                title:"Emotion expression",
                text:"Developing the ability to identify, understand, and express emotions in a constructive and healthy manner.",
                img:"/img/lovemind/icons/22.png"
            },
            {
                title:"Communication skills",
                text:" Enhancing communication techniques to effectively convey thoughts, feelings, and needs, while also fostering active listening skills.",
                img:"/img/lovemind/icons/23.png"
            },
            {
                title:"Relationship problem-solving",
                text:"Acquiring strategies and tools to address conflicts, challenges, and obstacles that arise within relationships.",
                img:"/img/lovemind/icons/24.png"

            },
            {
                title:"Gratitude",
                text:"Cultivating an attitude of gratitude and appreciation towards oneself, others, and the world around us, which can enhance overall well-being and satisfaction.",
                img:"/img/lovemind/icons/25.png"

            },
            {
                title:"Respect",
                text:"Learning the importance of mutual respect in relationships, including boundaries, autonomy, and honoring the dignity of others.",
                img:"/img/lovemind/icons/26.png"

            },
            {
                title:"Universal connection:",
                text:"Exploring the interconnectedness of all beings and fostering a sense of compassion, empathy, and understanding towards others and the world at large.",
                img:"/img/lovemind/icons/27.png"


            }
        ]

    },[])

  return (
    <div className='container py-5'>
        <HeadingNew title={"Why This"} colored={"Masterclass"} />
        <div className='d-flex justify-content-center align-items-center'>

        <p style={{
            color:"black",
            fontWeight:"500",
            fontSize:"1.1rem",
            width:isMobile?"auto":"50%"

        }}
        className='my-4 text-center'
        >
        In this free masterclass, you'll acquire deep insights into resolving any relationship challenge, know how to build meaningful relationships, and live a life full of <b>harmony, love, gratitude, and respect</b>.

        </p>
      
        </div>
        <Row className='justify-content-center align-items-center gy-3'>
            {
                data.map((val,i)=>(
                    <Col xs={12} md={4}>
                    <div className='d-flex justify-content-center align-items-center'>
                    <Card style={{
                        width:isMobile?"90%":"95%",
                        padding:"20px",
                        borderRadius:"15px",
                        height:isMobile?"auto":"250px",
                        margin:"0 auto"
                    }}>
                        <Card.Body className='d-flex justify-content-center align-items-center gap-3'>
                            <img src={val.img} width={100} height={100} />
                            <div>
                            <h6 style={{
                                color:"black",
                                fontWeight:"600",
                                fontSize:"1.1rem"
                            }}>
                            {val.title}
                            </h6>
                            <p style={{
                                color:"black"
                            }}>
                                {val.text}
                            </p>
                            </div>
    
                            
                        </Card.Body>
    
                    </Card>
                    </div>
                </Col>
                ))
            }
         
         
        </Row>
        <div className='d-flex justify-content-center align-items-center mt-4'>
        <Button text={"Join for free"} onClick={handleScroll} />


        </div>
    </div>
  )
}

export default PerkSectionLoveMind