import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AllContext';
import { requestData2 } from '../../utils/baseUrl';
import { ClockLoader } from 'react-spinners';

function Timer() {
    const navigate = useNavigate();
    const [seconds,setSeconds] = useState(60);

    const {userData,setUserData} = useContext(AuthContext);

    useEffect(()=>{
      const timer = setInterval(async()=>{
         if(seconds===0){
            clearInterval(timer)
            if(userData){
              navigateFunc()
            }else{
            navigate("/login")
            }
         }else{
            setSeconds(seconds-1)
         }
      },1000)

      return ()=>{
        clearInterval(timer)
      }
    },[seconds])


    const navigateFunc = async()=>{
      const res = await requestData2("getStudentProfile", "POST", {});
      if (res && res.error === false) {
        if(res.data.enrollment_status==="Enrolled"){
        setUserData({
          ...res.data,
          total_course:res.total_course
        });
        
        navigate("/")
        }else{
          navigate("/today")
        }
      }
    }


  
  return (
    <div style={{width:"100%",height:"100vh"}} className='d-flex justify-content-center align-items-center flex-column'>
        <h4 className='text-center'>Please wait for {seconds} seconds while we are updating your profile</h4>
        <ClockLoader color="#36d7b7" />
    </div>
  )
}

export default Timer