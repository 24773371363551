import React from 'react'
import "./researchCard.css";
import { Image } from 'react-bootstrap';
import {FaQuoteLeft} from "react-icons/fa";

function ResearchCard({quote,quoteowner,para,banner,img}) {
    return (
        <div class="box">
        <div className='firstcontainer'>
            <Image src={img} className='imgresearch' />
            <div className='firstcontainerinnerdiv'>
               <span className='iconquote'>
                    <FaQuoteLeft color='#1dd1a1' />
                </span>
                <h5 className='quote'>
                  {quote}
                </h5>
                <small className='quoteowner'>
                   {quoteowner}
                </small>
            </div>
        </div>
        <p className='researchcardpara'>
         {
            para
         }
        </p>
        <div class="ribbon-2">
            <h5 className='white-color mt-1'>{banner}</h5>
        </div>
      </div>
    )
}

export default ResearchCard